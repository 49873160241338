class Party {
  partyType: string;
  partyName: string;
  division: any;
  regions: string;
  project: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  address: string;
}
class UploadDocs {
  partyName: string;
  topic: string;
  description: string;
  docs: Array<any>;
}

class CaseRemarks {
  status: string;
  remarks: string;
}

export class User {
  _id: number;
  firstParty:Party;
  secondParty:Party;
  descriptionDispute: string;
  billingType: string;
  legalNotice: any;
  mutualCaseType: any;
  departmentName: any;
  advocateAssigned: any;
  retainerAssigned:any
  inHouseAdvocate: any;
  caseDescription: string;
  settlementTerms: string;
  uploadDocuments:UploadDocs;
  futureUpdatesBrief: string;
  caseStatus: string;
  lastHearingDate: string;
  nextHearingDate: string;
  remark: string;
  caseStatusRemarks:CaseRemarks[];
  internalSharing:any[];
  externalSharing:any[];

  createdBy:any;
  updatedBy:any;

  constructor(customer) {
    this._id = customer._id;
    this.firstParty = customer.firstParty;
    this.secondParty = customer.secondParty;
    this.descriptionDispute = customer.descriptionDispute;
    this.legalNotice = customer.legalNotice;
    this.mutualCaseType = customer.mutualCaseType;
    this.departmentName = customer.departmentName;
    this.caseDescription = customer.caseDescription;
    this.billingType = customer.billingType;
    this.settlementTerms = customer.settlementTerms;
    this.uploadDocuments = customer.uploadDocuments;
    this.futureUpdatesBrief = customer.futureUpdatesBrief;
    this.caseStatus = customer.caseStatus;
    this.remark = customer.remark;
    this.caseStatusRemarks = customer.caseStatusRemarks;
    this.lastHearingDate = customer.lastHearingDate;
    this.nextHearingDate = customer.nextHearingDate;
    this.advocateAssigned = customer.advocateAssigned;
    this.retainerAssigned = customer.retainerAssigned;
    this.inHouseAdvocate = customer.inHouseAdvocate;
    this.internalSharing = customer.internalSharing;
    this.externalSharing = customer.externalSharing;

    this.createdBy = customer.createdBy;
    this.updatedBy = customer.updatedBy;
  }

  get firstPartyName() {
    return `${this.firstParty.partyName}`;
  }

  get firstPartyDivisionName() {
    return `${this.firstParty.division.name}`;
  }

  get firstPartyProjectName() {
    return `${this.firstParty.project}`;
  }

  get secondPartyName() {
    return `${this.secondParty.partyName}`;
  }

  get secondPartyDivisionName() {
    return `${this.secondParty.division.name}`;
  }

  get secondPartyProjectName() {
    return `${this.secondParty.project}`;
  }

  get docs() {
    return this.uploadDocuments.docs;
  }

  get _departmentName() {
    if ( this.departmentName ) {
      return this.departmentName.name;
    } else {
      return "N/A";
    }
  }
  
  get _mutualCaseType() {
    if ( this.mutualCaseType ) {
      return this.mutualCaseType.caseType;
    } else {
      return "N/A";
    }
  }

  get _advocateAssigned() {
    let name = "N/A";

    if ( this.advocateAssigned ) {
      
      if ( this.advocateAssigned.userType == "firm" ) {
        name = this.advocateAssigned.firmName;
      } else {
        name = this.advocateAssigned.firstName + " " + this.advocateAssigned.lastName;
      }
    }
    
    return name;
  }

  get _inHouseUserAssigned() {
    let name = "N/A";
    
    if ( this.inHouseAdvocate ) {
      name = this.inHouseAdvocate.firstName + " " + this.inHouseAdvocate.lastName;
    }

    return name;
  }

}
