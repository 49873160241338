import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';
// import { CourtcaseService } from './courtcase.service';
import { MatSelectionList, MatSelectionListChange, MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';
import icCloud from '@iconify/icons-ic/twotone-close';
// import { CourtcaseService } from '../addcourtcase/courtcase.service';
import { AutocourtcaseDetalComponent } from '../autocourtcase-detal/autocourtcase-detal.component';
import { MatDialog } from '@angular/material/dialog';
import icCheck from '@iconify/icons-ic/check'
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CourtcaseService } from '../addcourtcase/courtcase.service';
// import { CourtcaseService } from '../addcourtcase/courtcase.service';
// import { CourtCasesService } from '../addcase/court-cases.service';
import {Injectable, NgModule} from '@angular/core';
import {MatPaginatorIntl, MatPaginatorModule} from '@angular/material/paginator';
import {Subject} from 'rxjs';
import data from '@iconify/icons-ic/twotone-notifications';
@Injectable()
@Component({
  selector: 'vex-supremecourt',
  templateUrl: './supremecourt.component.html',
  styleUrls: ['./supremecourt.component.scss']
})
export class SupremecourtComponent implements OnInit  {
  icCheck = icCheck;
  private socketObject;
  form: FormGroup;
  categorydata :any;
  yeardata: any;
  columns: any;
  partylist: any;
  selecteddata :any
  loading = false;
  selectalls = false;
  msg='';
  dataSource = [];
  globalcheckbox = false;
  casenumbermsg = '';
  partymsg='';
  diarymsg=''
  loadingdata = false;
  icCloud= icCloud;
  partydata: any[];
  datasaved = false;
  checked = false;
  disabled = true;

  public totalRecords = 0;
  public totalpages = 0
  Records =0;
  partylistdata=[];
  pageIndex = 0 ;
  pageSize = environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;
  // @ViewChild('shoess',{static:true})shoess: ElementRef;
  // @ViewChild('shoess', {static: true}) private allSelected: MatSelectionList;
  @ViewChild('grid',{static: false})
  public grid; 
  // public grid;
  public data: Object[];
  public initialPage: Object;
  public editSettings;
  public toolbar;
  @ViewChild('allSelected', { static: true })
  private allsSelected: MatSelectionList;
  typesOfShoes: string[] = [
    'Boots',
    'Clogs',
    'Loafers',
    'Moccasins',
    'Sneakers',
  ];
  diarylist: any;

  changes = new Subject<void>();

  // For internationalization, the `$localize` function from
  // the `@angular/localize` package can be used.
  

  // You can set labels to an arbitrary string too, or dynamically compute
  // it through other third-party internationalization libraries.
  nextPageLabel = 'Next page';
  previousPageLabel = 'Previous page';
  defaultPageSizeOptions: number;
  // globalcheckbox: any;

  getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0) {
      return `Page 1 of 1`;
    }
    const amountPages = Math.ceil(length / pageSize);
    return `Page ${page + 1} of ${amountPages}`;
  }
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('allSelected',{ static: true }) selectionList: MatSelectionList;
  isChecked: boolean;
 
  constructor( private fb: FormBuilder, private court :CourtcaseService,private router: Router,  private snackBar: MatSnackBar,  private socketService: SocketService, private commonServices: CommonService,private dialog: MatDialog,) { 

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  casecategory=[{
    id:"Any",
    name:'Any',
    
  },{
    id:"Petitiner",
    name:'Petitiner',
   
  },{
    id:"Respondent",
    name:'Respondent',
   
  }]

  ngOnInit() {
    // this.data = data.slice(0, 10);
    this.initialPage = { pageSizes: true, pageCount: 4 };
this.getcategory();
this.getyear();
    this.form = this.fb.group({
      case_type: [ ''],
      case_no: [ ""],
      case_year: [""],
  
     
  
    });

    const toSelect = this.casecategory.find(c => c.id == "Any");
    console.log(toSelect,"tosee")
    this.keywordForm.get('case_category').setValue("Any");
    this.keywordForm.get('case_status').setValue("P");
  }

  myForm = new FormGroup({
    case_type: new FormControl('',Validators.required),
    case_no: new FormControl('',Validators.required),
    case_year: new FormControl('',Validators.required),
    flag: new FormControl("0"),
    
  });

  diaryForm = new FormGroup({
  
    diary_no: new FormControl('',Validators.required),
    diary_year: new FormControl('',Validators.required),
    flag: new FormControl("1"),
    
  });

  keywordForm = new FormGroup({
    case_category: new FormControl('',Validators.required),
    search_keyword: new FormControl('',Validators.required),
    case_year: new FormControl('',Validators.required),
    case_status: new FormControl('',Validators.required),
    
  });


  getcategory(){
    let obj = {
      "court_type_id": "1"
    }
    // this.myForm.value
    this.court.post('ws/courtCategories',obj).subscribe((res2: any) => {
      this.categorydata = res2.caseCourtCategory
console.log(res2)

    })
    // ws/courtCategories

    
  }
  getyear(){
  
    // this.myForm.value
    this.court.postyear('ws/caseYears').subscribe((res2: any) => {
      this.yeardata = res2.caseYear
      console.log(this.yeardata)
      this.myForm.get('case_year').setValue(this.yeardata[0]['year'])
      this.diaryForm.get('diary_year').setValue(this.yeardata[0]['year'])
      this.keywordForm.get('case_year').setValue(this.yeardata[0]['year']);


    })
    // ws/courtCategories

    
  }



  toggleCheckBoxes() {
    if(this.globalcheckbox) {
      this.globalcheckbox = false;
    } else {
      this.globalcheckbox = true;
    }
  }


  caseInfo
  save(){
    // https://api.mysita.com/public/ws/courtCase
    this.casenumbermsg =''
if(this.myForm.valid){
  this.loading = true;
  // console.log(this.myForm.value)
  this.court.post('ws/courtCase',this.myForm.value).subscribe((res2: any) => {
  console.log(res2,"by case number ")
  if(res2.status){
    // let fetchdata 
    // let newdata
   
    this.caseInfo=res2.caseInfo
    // let obj ={
    //   "diary_no": res2.caseInfo.diary_only,
    //   "diary_year": res2.caseInfo.diary_year,
    //   "flag":"1"
    // }
    let tmpObj = this
    tmpObj.getlistofcase(this.caseInfo)
    // this.court.post('ws/autoUpdateCourtCase',obj).subscribe((res2: any) => {
    //  console.log(res2)
    //   if(res2&&res2.autoUpdateCourtCase)
    //   {
    //     newdata = res2.autoUpdateCourtCase[0] 
    //     if (tmpObj.socketService.isSocketConnected()) {
    //     tmpObj.socketObject.emit(environment.socketEvents.findCourtCases,res2.autoUpdateCourtCase[0],
        
    //       function (socketResponse) {
            
    //         if (socketResponse.status == 'OK') {
    //           if(socketResponse.data.length!=0){
    //             console.log(socketResponse.data,"by api ")
               
    //             fetchdata = socketResponse.data[0]
    //             tmpObj.getlistofcase(fetchdata)
    //             console.log(this.columns,"by api ")
    //             // this.datasaved = true
              
                
    //           }else{
    //             tmpObj.getlistofcase(newdata)
    //           }
            
            
      
      
    //         } else {
             
    //         }
    //       });
    //   }
    // }else{
    //     this.casenumbermsg = "Facing Some issue From Server plases try After Some time"
    //   }
    // })

  // 
// findCourtCases









  }else{
    this.columns = {}
    this.casenumbermsg = res2.message

    // setTimeout(() => {
    //   this.msg = ''
    // }, 1000);
  }
  
  
  this.loading = false;
})


}else{
  this.snackBar.open('Please provide all required information.', 'CLOSE', {
    duration: 3000,
    horizontalPosition: 'center'
  });


}







      
  }
  getlistofcase(data){
    this.columns = data 

  }
  savecase(){
console.log(this.columns,"this.columns")
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createAutoCourtCase, this.columns, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
            duration: 4000
          });
          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }

  }

  savediarycase(){
    // console.log(this.columns,"this.columns")
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.createAutoCourtCase, this.diarylist, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
                duration: 4000
              });
              // tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackBar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
    
      }


  casedetail(data){
    data['code']=1

    this.dialog.open(AutocourtcaseDetalComponent,{
      height: '800px',
      width: '950px',
      autoFocus: false,
      data:data
      });
  }

  diarycasedetail(data){
    data['code']=2

        this.dialog.open(AutocourtcaseDetalComponent,{
          height: '800px',
          width: '950px',
          autoFocus: false,
          data:data
          });
      }

  casedetailbyid(data){
    console.log(data,"kkkkk")
    let obj={
      diary_no:data.diary_only,
      diary_year:data.diary_year,
      flag:"1",
      code:3
    }

    this.loading = false;
    this.dialog.open(AutocourtcaseDetalComponent,{
   height: '800px',
    width: '950px',
    autoFocus: false,
     data:obj
  });
  
   
//     this.loading = true;

//     let obj={
//       diary_no:data.diary_no,
//       diary_year:data.caseYear
    
//     }
    
//     console.log(obj,"obj")
//     let detaildata 
//     this.court.post('ws/courtCase',obj).subscribe((res2: any) => {
//       console.log(res2.caseInfo)
// detaildata = res2.caseInfo

// if(detaildata){
//   this.loading = false;
//   this.dialog.open(CourtCasesdetailComponent,{
//  height: '500px',
//   width: '800px',
//   autoFocus: false,
//   // data:{caseId:detaildata.id}
// });

}

    
    // })
    // console.log(detaildata,"after api")

  


    // console.log(this.columns.id,"this.columns.id")
        // this.router.navigate(["/app/cases-management/case-detail"], { queryParams: { case_id: data } });
      // }


  partysave(){
    this.partymsg =''
    if(this.keywordForm.valid){
    this.loading = true;
    this.court.post('ws/searchParty',this.keywordForm.value).subscribe((res2: any) => {
    
      if(res2.status){
        this.partylist = res2.partyName

      
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.createTemproryCourtCase, this.partylist, function (socketResponse) {
          // this.refreshData(this)
          tmpObj.refreshData(tmpObj)
            });
          }
      

      
      }else{
        this.partydata = []
        this.Records=0
        console.log(this.partydata,"party")
        this.partymsg = res2.message
        
        // setTimeout(() => {
        //   this.partymsg = ''
        // }, 1000);
      }

this.loading = false;
    })}else{

      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    }
  }

saveparty(data){
  
  console.log(data._value,"this.shoess.nativeElement.value")
  data._value.forEach(element => {
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createAutoCourtCase, element, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
            duration: 4000
          });
          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }

    
  });
  // this.shoess.nativeElement.value
}


back() {
  window.history.back();
  // window.history.back();
}
// toggleAllSelection() {
//   if (this.allSelected.selected) {
//     this.searchUserForm.controls.userType
//       .patchValue([...this.userTypeFilters.map(item => item.key), 0]);
//   } else {
//     this.searchUserForm.controls.userType.patchValue([]);
//   }
// }


selectAll() {

  if( this.selectalls = true ){
    this.allsSelected.selectAll();
  } else{
    this.allsSelected.deselectAll()
  }
  
  // this.selectalls = true 
  
  // this.allsSelected.selectAll();

};


// deselctall(){
//   this.selectalls = false
//   this.allsSelected.deselectAll()
// }

diarysave(){
  this.diarymsg ='';
  if(this.diaryForm.valid){
    this.loading = true;
    console.log(this.diaryForm.value,"res2 daisry")
    this.court.post('ws/courtCase',this.diaryForm.value).subscribe((res: any) => {
      console.log(res,"res")
      this.loading = false;
      if(res.status){
    this.court.post('ws/autoUpdateCourtCase',this.diaryForm.value).subscribe((res2: any) => {
  console.log(res2,"res2")
    if(res2.status){
     
      let fetchdata
      let newdata 
      if(res2.autoUpdateCourtCase){
        newdata = res2.autoUpdateCourtCase[0]
        console.log(newdata,"res2 daisry")
      
     

      let tmpObj = this

      if (tmpObj.socketService.isSocketConnected()) {
        tmpObj.socketObject.emit(environment.socketEvents.findCourtCases,res2.autoUpdateCourtCase[0],
        
          function (socketResponse) {
            
            if (socketResponse.status == 'OK') {
              if(socketResponse.data.length!=0){
                console.log(socketResponse.data,"by api ")
               
                fetchdata = socketResponse.data[0]
                tmpObj.getlistofdiary(fetchdata)
                // console.log(this.columns,"by api ")
                // this.datasaved = true
              
                
              }else{
                tmpObj.getlistofdiary(newdata)
              }
            
            
      
      
            } else {
             
            }
          });
      }
    }else{
      this.diarymsg = "Facing Some Issue Form Server side please Try after some time "
    
      // setTimeout(() => {
      //   this.msg = ''
      // }, 1000);
    }



    }else{
      this.diarylist ={}
      this.diarymsg = res2.message
    
        // setTimeout(() => {
        //   this.msg = ''
        // }, 1000);
    }
    
    
    this.loading = false;
  })
}else{
  this.diarylist ={}
  this.diarymsg = res.message?res.message:"Not Found Data"
}
})
  
  
  }else{
    this.snackBar.open('Please provide all required information.', 'CLOSE', {
      duration: 3000,
      horizontalPosition: 'center'
    });
  
  }

}

getlistofdiary(data){
  this.diarylist = data 
  console.log(this.diarylist,"getdiarylist ")

}


refreshData(tmpObj) {
  console.log(tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:0,"tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:0")
  console.log(this.paginator,"this.paginator")
  if (tmpObj.socketService.isSocketConnected()) {
    tmpObj.socketObject.emit(environment.socketEvents.listTemproryCourtCase,{  pageSize: tmpObj.pageSize,
      skip: tmpObj.pageIndex?tmpObj.pageIndex * tmpObj.pageSize:0

      //  skip: 0
    
    },
    
      function (socketResponse) {
        console.log(socketResponse,"by api ")
        if (socketResponse.status == 'OK') {
          tmpObj.partylistdata= socketResponse.data
          // console.log(this.caselistdata,"this party list 1 ")
          tmpObj.totalRecords = socketResponse.totalRecords;
         
          this.partylistdata = tmpObj.partylistdata
         this.totalRecords = tmpObj.totalRecords
          console.log(this.partylistdata,"this party list 1 ")
          tmpObj.getparty()
        


        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
  }

  
}
savesparty(data){
  
   
  // this.loading = true
 
  data._value.forEach(element => {
    let obj={
      diary_no:element.diary_only,
      diary_year:element.diary_year,
      flag:"1"
    
    }
    console.log(obj,"check respon")
    this.court.post('ws/courtCase',obj).subscribe((res: any) => {
      if(res)
   { this.court.post('ws/autoUpdateCourtCase',obj).subscribe((res2: any) => {
      console.log(res2,"check response")
      if(res2.status){
        let detaildata
        if(res2.autoUpdateCourtCase){
          detaildata = res2.autoUpdateCourtCase[0]
        }
       
        this.savepartdetail(detaildata);
      }
    })}
  })
  });
  // this.shoess.nativeElement.value
}

savepartdetail(data){
  console.log(data,"data of party")
  if (this.socketService.isSocketConnected()) {
    let tmpObj = this;
    this.socketObject.emit(environment.socketEvents.createAutoCourtCase, data, function (socketResponse) {
      if (socketResponse.status == 'OK') {
        tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
          duration: 4000
        });
        // tmpObj.refreshData(tmpObj);
      } else {
        tmpObj.snackBar.open(socketResponse.message, 'Okay', {
          duration: 4000
        });
      }
    });
  }
}
getparty(){
  this.partydata = this.partylistdata
  this.Records = this.totalRecords

  let record = this.totalRecords/10
  let secrecord = (record+ "").split(".")[1]
 
if(Number(secrecord)){

  this.totalpages = parseInt((this.totalRecords/10)+"")
  this.totalpages = Number(this.totalpages)+1

}else{
  this.totalpages = (this.totalRecords/10)
}
}



pageChangeHandler(eventDetails) {
  console.log(eventDetails)
  if (eventDetails && eventDetails.pageSize) {
    this.pageSize = eventDetails.pageSize;
    this.pageIndex = eventDetails.pageIndex
  }
  // if(this.pageSize <= this.defaultPageSizeOptions ){
  //   this.columns.allowPaging = false
  //   console.log(this.partydata.length,"this.partydata.length")
  // }
  // else {
  //   this.columns.allowPaging = true
  // }
  
  this.refreshData(this);
}

getNext() {

  if(this.pageSize <= 10){
    this.columns.allowPaging = true
  }
  else {
    this.columns.allowPaging = true
  }
  
  this.refreshData(this);

  // if(this.grid.getRows().length > 10){
  //   this.columns.allowPaging = true
  //   console.log(this.partydata.length,"this.partydata.length")
  // }
  // else {
  //   this.columns.allowPaging = false
  // }
}

}
