import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icBarCode from '@iconify/icons-ic/twotone-barcode';
import icSupervised from '@iconify/icons-ic/twotone-supervised-user-circle';
import icPermIdentity from '@iconify/icons-ic/twotone-perm-identity';
import icPhoneInTalk from '@iconify/icons-ic/twotone-phone-in-talk';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icGroupWork from '@iconify/icons-ic/twotone-group-work';
import icBuild from '@iconify/icons-ic/twotone-build';
import { MatSnackBar } from '@angular/material';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/services/core/validation.service';
import { CustomValidators } from 'src/app/custom_validator/validator';

@Component({
  selector: 'vex-users-change-password',
  templateUrl: './users-change-password.component.html',
  styleUrls: ['./users-change-password.component.scss'],
  providers: [CustomValidators],
})
export class UsersChangePasswordComponent implements OnInit {

  private socketObject;

  public invalidPassword: boolean;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icBarCode = icBarCode;
  icSupervised = icSupervised;
  icPermIdentity = icPermIdentity;
  icPhoneInTalk = icPhoneInTalk;
  icPhone = icPhone;
  icGroupWork = icGroupWork;
  icBuild = icBuild;

  icPerson = icPerson;
  icEditLocation = icEditLocation;

  public roles = [
    { name: "Admin", value: "admin" },
    { name: "Management", value: "management" },
    { name: "User", value: "user" },
  ];

  constructor(private dialogRef: MatDialogRef<UsersChangePasswordComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private socketService: SocketService,
    private customValidators: CustomValidators,
    private validationService: ValidationService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {
    this.form = this.fb.group({
      oldpassword: [null, [Validators.required, Validators.composeAsync([this.validationService.oldPasswordValidation()])]],
      password: [null, [Validators.required, this.customValidators.validate]],
      confirmpassword: [null, [Validators.required]]
    }, {
      validator: this.customValidators.ConfirmedValidator('password', 'confirmpassword')
    }
    );

  }



  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

}
