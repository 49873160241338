import { Component, Inject, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { NcltCourtService } from '../nclt-court/nclt-court.service';
import { MatSnackBar } from "@angular/material";
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from "src/environments/environment";

@Component({
  selector: 'vex-nclt-court-detail',
  templateUrl: './nclt-court-detail.component.html',
  styleUrls: ['./nclt-court-detail.component.scss']
})
export class NcltCourtDetailComponent implements OnInit {
  msg
  constructor(public dialogRef: MatDialogRef<NcltCourtDetailComponent>, private commonServices: CommonService,private socketService: SocketService,@Inject(MAT_DIALOG_DATA) public data: any,private ncltCourtService:NcltCourtService, public dialog: MatDialog,private snackBar: MatSnackBar,) { 
    console.log(data,"hooooo")
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  private socketObject;

  ngOnInit() {
    this.getCaseDetils()
  }
  MatSprnnerOfSection=false

  showServerErrOfapplicantDetail = ''
  showServerErrOfhistoryListing=''
  showServerErrOfiaMaListing=''
  showServerErrOfconnectedMattersListing=''

  showServerErrOffirstHearingDetail=''
  showServerErrOfLastHearingDetail=''
  showServerErrOfNextHearingDetail=''
  showServerErrOfCaseHistory=''
  showServerErrOforderHistory=''
  showServerErrOfIasOtherApplication=''
  showServerErrOfConnectedCases=''

  handleMatSprnner = true;
  showHeader = false;
  showErrMsg = false;
  t1;t2;
  filingNo;
  caesDetailData;
  casedetailid
  getCaseDetils() {
    this.showHeader = false;
    let obj = {};
    console.log(this.data)
    obj["case_cino"] = this.data.case_cino;
    this.ncltCourtService
      .postData('caseDetails', obj)
      .subscribe((res) => {
        console.log(res,"getting response of case detail")
        this.casedetailid = res.caseDetails.id
        let caseTitle = this.data.case_name.split("VS");
        this.t1 = caseTitle[0];
        this.t2 = caseTitle[1];
        this.filingNo = this.data.filing_no;

        if (res.status == true) {
          this.caesDetailData = res.caseDetails;
          this.handleMatSprnner = false;
          this.showHeader = true;
          this.partyDetail()
          this.historyListing()
          this.iaMaListing()
          this.connectedMatterListing()
          // this.getNextHearingDetail()
        } else {
          this.msg = res.message;
          this.handleMatSprnner = false;
          this.showErrMsg = true;
        }
      },(err)=>{
        this.dialogRef.close();
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
      }
      );
  } 

  saveData(){
    console.log(this.data,"nclt cases")
    this.caesDetailData['bench']=this.data.bench;
    this.caesDetailData['case_year']=this.data.case_year;
    this.caesDetailData['case_number']=this.data.case_number;
    this.caesDetailData['case_type']=this.data.case_type;
    // this.caesDetailData['filing_date']=this.data.filing_date;
    // this.caesDetailData['party_name']=this.data.party_name;
    // this.caesDetailData['respondent_advocates']=this.data.respondent_advocates;
    // this.caesDetailData['petitioner_advocates']=this.data.petitioner_advocates;
    // this.caesDetailData['registered_on']=this.data.registered_on;
    // this.caesDetailData['case_status']=this.data.case_status;
    // this.caesDetailData['next_listing_date']=this.data.next_listing_date;
    // this.caesDetailData['last_listed']=this.data.last_listed;

console.log(this.caesDetailData,"this.caesDetailData")
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createNclt,  this.caesDetailData , function (socketResponse) {
        console.log(socketResponse.status,"socketResponse.status")
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created Nclt court cases', 'Okay', {
            duration: 4000
          });
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
  }



  // countofparty=0
  allparties  
  partyDetail(){
    this.MatSprnnerOfSection=true
    this.showHeader = true;
    let obj = {};
    obj["court_case_id"] = this.casedetailid;
    this.ncltCourtService
      .postData('allParties', obj)
      .subscribe((res) => {
        console.log(res,"getting response of party detail")
        this.allparties = res.allParties
        this.MatSprnnerOfSection=false
      },(err)=>{
        this.showServerErrOfapplicantDetail = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
      }
      );
  }

historyData
nodatafound = true
message
historyListing(){
  this.MatSprnnerOfSection=true
  this.showHeader = true;
  let obj ={}
  obj["court_case_id"] = this.casedetailid;
  this.ncltCourtService.postData('listingHistory',obj).subscribe((res)=>{
    if(res.listingHistory){
    this.historyData = res.listingHistory
    this.MatSprnnerOfSection=false
    }
    else{
      this.MatSprnnerOfSection=false
      this.nodatafound= false
      this.message= "No Data Found!"
    }
  },(err)=>{
    this.showServerErrOfhistoryListing = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
  })


}

iaMaData
iaMaListing(){
  this.MatSprnnerOfSection=true
  this.showHeader = true;
  let obj ={}
  obj["court_case_id"] = this.casedetailid;
  this.ncltCourtService.postData('iaAndMa',obj).subscribe((res)=>{
    console.log(res,"getting response of iaMaListing")
    if(res.iaAndMa){
      this.iaMaData = res.iaAndMa
      this.MatSprnnerOfSection=false
      }
      else{
        this.MatSprnnerOfSection=false
        this.nodatafound= false
        this.message= "No Data Found!"
      }
  },(err)=>{
    this.showServerErrOfiaMaListing = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
  })
}


connectedMatterData
connectedMatterListing(){
  this.MatSprnnerOfSection=true
  this.showHeader = true;
  let obj ={}
  obj["court_case_id"] = this.casedetailid;
  this.ncltCourtService.postData('connectedMatters',obj).subscribe((res)=>{
    console.log(res,"getting response of iaMaListing")
    if(res.connectedMatters){
      this.connectedMatterData = res.connectedMatters
      this.MatSprnnerOfSection=false
      }
      else{
        this.MatSprnnerOfSection=false
        this.nodatafound= false
        this.message= "No Data Found!"
      }
  },(err)=>{
    this.showServerErrOfconnectedMattersListing = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
  })
}


goToLink(url: string) {
  if (url != null) {
    window.open(url, "_blank");
  } else {
    alert("No data Found");
  }
}




  step = 0;
  setStep(index: number) {
    this.step = index;
  }


  nextStep() {
    this.step++;
    if(this.step==1){
      this.partyDetail()
    }
    if(this.step==2){
      this.historyListing()
    }
    if(this.step==3){
      this.iaMaListing()
    }
    if(this.step==4){
      this.connectedMatterListing()
    }
  }


  prevStep() {
    this.step--;
    if(this.step==1){
      this.partyDetail()
    }
    if(this.step==2){
      this.historyListing()
    }
    if(this.step==3){
      this.iaMaListing()
    }
    if(this.step==4){
      this.connectedMatterListing()
    }
  }

}
