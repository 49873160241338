import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class HighcourtService {
  // High Court micro service URL
  public eCurl = environment.socketConfiguration.msHighCourtURL;


  constructor(private router: Router, private location: Location,public http: HttpClient) { }

  postecourt(urlT: any, data: any) {
    const updateUrl = this.eCurl + urlT;
  
   
    return this.http.post(updateUrl, data);
  }
  
  postyear(urlT: any){
    const updateUrl = this.eCurl + urlT;
  let obj={
  
  }
   
    return this.http.post(updateUrl,obj);
  
  }

}
