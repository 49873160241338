import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-appointments-create-update',
  templateUrl: './appointments-create-update.component.html',
  styleUrls: ['./appointments-create-update.component.scss']
})
export class AppointmentsCreateUpdateComponent implements OnInit {

  private socketObject;

  public courtCasesList = [];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;

  icPerson = icPerson;
  icEditLocation = icEditLocation;

  constructor(@Inject(MAT_DIALOG_DATA) public userDetails: any,
    private dialogRef: MatDialogRef<AppointmentsCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if(this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit( environment.socketEvents.courtCasesForList, {}, function(socketResponse) {
        if(socketResponse.status == 'OK') {
          tmpObj.courtCasesList = socketResponse.data;
        }
      });
    }

    if (this.userDetails) {
      this.mode = 'update';
    } else {
      this.userDetails = {} as User;
    }

    this.form = this.fb.group({
      _id: this.userDetails._id || '',
      courtCase: [this.userDetails.courtCase?this.userDetails.courtCase._id:'', Validators.required],
      topic: [this.userDetails.topic || '', Validators.required],
      appointmentDate: [this.userDetails.appointmentDate || '', Validators.required]
    });
  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    if (!customer.imageSrc) {
      customer.imageSrc = 'assets/img/avatars/1.jpg';
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    customer.id = this.userDetails.id;

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }
}
