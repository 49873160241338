import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from '../@vex/layout/layout.component';
import { BeforeLoginGuard } from './guards/before-login.guard';
import { AfterLoginGuard } from './guards/after-login.guard';
// import { CompanyProfileComponent } from './pages/company-profile/company-profile.component';
const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  scrollOffset: [0, 64],
};

const routes: Routes = [
  {
    path: "",
    loadChildren: () => import('./pages/auth/landing/landing.module').then(m => m.LandingModule),
    canActivate: [BeforeLoginGuard]
  },
  // {
  //   path: "",
  //   pathMatch: "full",
  //   redirectTo: "/login"
  // },
  {
    path: "login",
    loadChildren: () => import('./pages/auth/login/login.module').then(m => m.LoginModule),
    canActivate: [BeforeLoginGuard]
  },
  {
    path: "login-inward-notice",
    loadChildren: () => import('./pages/auth/login-otp/login-otp.module').then(m => m.LoginOtpModule),
    canActivate: [BeforeLoginGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [BeforeLoginGuard]
  },
  {
    path: 'forgot-password/:uniqueId',
    loadChildren: () => import('./pages/auth/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    canActivate: [BeforeLoginGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/auth/register/register.module').then(m => m.RegisterModule),
    canActivate: [BeforeLoginGuard]
  },
  {
    path: 'external-sharing',
    loadChildren: () => import('./pages/external-share-case-details/external-share-case-details.module').then(m => m.ExternalShareCaseDetailsModule),
    canActivate: []
  },
  {
    path: 'app',
    component: LayoutComponent,
    canActivateChild: [AfterLoginGuard],
    children: [
      {
        path: "",
        pathMatch: "full",
        redirectTo: "/app/dashboard"
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./pages/dashboards/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
        data: { key: "dashboard" }
      },
      {
        path: 'legal-notices',
        loadChildren: () => import('./pages/legal-notices/legal-notices.module').then(m => m.LegalNoticesModule),
        data: { key: "legal_notices" }
      },
      {
        path: 'inward-notices-dropbox',
        loadChildren: () => import('./pages/inward-notice-dropbox/inward-notice-dropbox.module').then(m => m.InwardNoticeDropboxModule),
        data: { key: "inward_notice_dropbox" }
      },
      {
        path: 'user-profile',
        loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfileModule),
        data: { key: "user_profile" }
      },
      {
        path: 'error-500',
        loadChildren: () => import('./pages/errors/error-500/error-500.module').then(m => m.Error500Module),
        data: { key: "dashboard" }
      },
      {
        path: 'users-management',
        loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule),
        data: { key: "users_management" }
      },
      {
        path: 'tasks-management',
        loadChildren: () => import('./pages/tasks/tasks.module').then(m => m.TasksModule),
        data: { key: "tasks_management" }
      },
      {
        path: 'roles-management',
        loadChildren: () => import('./pages/roles/roles.module').then(m => m.RolesModule),
        data: { key: "roles_management" }
      },
      {
        path: 'billing-management',
        loadChildren: () => import('./pages/billing/billing.module').then(m => m.BillingModule),
        data: { key: "billing" }
      },
      {
        path: 'advocate-firm-management',
        loadChildren: () => import('./pages/advocate-firm/advocate-firm.module').then(m => m.AdvocateFirmModule),
        data: { key: "advocate_firms" }
      },
      {
        path: 'Reports-management',
        loadChildren: () => import('./pages/reports/reports-list/reports-list.module').then(m => m.ReportsListModule),
        data: { key: "reports_management" }
      },
      {
        path: 'Reports-config',
        loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule),
        data: { key: "reports_config_management" }
      },
      {
        path: 'calendar-view',
        loadChildren: () => import('./pages/calendar/calendar.module').then(m => m.CalendarModule),
        data: { key: "calendar_view" }
      },
      {
        path: 'cases-management',
        loadChildren: () => import('./pages/cases/cases.module').then(m => m.CasesModule),
        data: { key: "cases" },

      },
      {
        path: 'discussion-board',
        loadChildren: () => import('./pages/cases/scrumboard/scrumboard.module').then(m => m.ScrumboardModule),
        data: { key: "discussion_board" }
      },
      {
        path: 'case-abbreviations-management',
        loadChildren: () => import('./pages/case-abbreviations/case-abbreviations.module').then(m => m.CaseAbbreviationsModule),
        data: { key: "case_abbreviation_management" }
      },
      {
        path: 'CompanyProfile',
        loadChildren: () => import('./pages/companyprofile/companyprofile.module').then(m => m.CompanyprofileModule),
        data: { key: "update_company_profile" }
      },
      // CompanyProfileModule
      {
        path: 'appointments-management',
        loadChildren: () => import('./pages/appointments/appointments.module').then(m => m.AppointmentsModule),
        data: { key: "appointments_management" }
      },
      {
        path: 'hearings-management',
        loadChildren: () => import('./pages/hearings/hearings.module').then(m => m.HearingsModule),
        data: { key: "hearings_management" }
      },
      {
        path: 'legal-docs-management',
        loadChildren: () => import('./pages/legal-docs/legal-docs.module').then(m => m.LegalDocsModule),
        data: { key: "legal_docs" }
      },
      {
        path: 'court-types-management',
        loadChildren: () => import('./pages/court-types/court-types.module').then(m => m.CourtTypesModule),
        data: { key: "court_types_management" }
      },
      {
        path: 'courts-management',
        loadChildren: () => import('./pages/courts/courts.module').then(m => m.CourtsModule),
        data: { key: "court_management" }
      },
      {
        path: 'bare-act-types-management',
        loadChildren: () => import('./pages/bare-act-types/bare-act-types.module').then(m => m.BareActTypesModule),
        data: { key: "dashboard" }
      },
      {
        path: 'bare-acts-management',
        loadChildren: () => import('./pages/bare-acts/bare-acts.module').then(m => m.BareActsModule),
        data: { key: "bare_acts_management" }
      },
      {
        path: 'jugement-types-management',
        loadChildren: () => import('./pages/judgement-types/judgement-types.module').then(m => m.JudgementTypesModule),
        data: { key: "judgement_area_management" }
      },
      {
        path: 'jugements-management',
        loadChildren: () => import('./pages/jugements/jugements.module').then(m => m.JugementsModule),
        data: { key: "judgements_management" }
      },
      {
        path: 'government-notification-management',
        loadChildren: () => import('./pages/government-notification/government-notification.module').then(m => m.GovernmentNotificationModule),
        data: { key: "government_notification_management" }
      },
      {
        path: 'divisions-management',
        loadChildren: () => import('./pages/divisions/divisions.module').then(m => m.DivisionsModule),
        data: { key: "division_management" }
      },
      {
        path: 'departments-management',
        loadChildren: () => import('./pages/departments/departments.module').then(m => m.DepartmentsModule),
        data: { key: "department_management" }
      },
      {
        path: 'designations-management',
        loadChildren: () => import('./pages/designations/designations.module').then(m => m.DesignationsModule),
        data: { key: "designation_management" }
      },
      {
        path: 'project-sites-management',
        loadChildren: () => import('./pages/project-sites/project-sites.module').then(m => m.ProjectSitesModule),
        data: { key: "project_sites_management" }
      },
      {
        path: '**',
        loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module),
        canActivateChild: [AfterLoginGuard]
      }
    ],
  },
  {
    path: '**',
    loadChildren: () => import('./pages/errors/error-404/error-404.module').then(m => m.Error404Module)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)
    // preloadingStrategy: PreloadAllModules,
    // scrollPositionRestoration: 'enabled',
    // relativeLinkResolution: 'corrected',
    // anchorScrolling: 'enabled'
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
