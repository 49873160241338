import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from './interfaces/users.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '../../../@vex/interfaces/table-column.interface';
import { mutualCasesDummyData, aioTableLabels } from '../../../static-data/aio-table-data';
import { CasesCreateUpdateComponent } from './cases-create-update/cases-create-update.component';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import { SelectionModel } from '@angular/cdk/collections';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import { fadeInUp400ms } from '../../../@vex/animations/fade-in-up.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { stagger40ms } from '../../../@vex/animations/stagger.animation';
import { FormControl } from '@angular/forms';
import theme from '../../../@vex/utils/tailwindcss';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icDetails from '@iconify/icons-ic/twotone-details';
import icTrackChanges from '@iconify/icons-ic/twotone-track-changes';
import icGroupWork from '@iconify/icons-ic/twotone-group-work';
import icNoteAdd from '@iconify/icons-ic/twotone-note-add';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icWarning from '@iconify/icons-ic/twotone-warning';
import icShare from '@iconify/icons-ic/twotone-share';
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { CaseStatusChangeComponent } from './case-status-change/case-status-change.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { appModulesList } from 'src/environments/appModulesList';
import { MutualCaseInternalSharingComponent } from './mutual-case-internal-sharing/mutual-case-internal-sharing.component';
import { MutualCaseSharesListComponent } from './mutual-case-shares-list/mutual-case-shares-list.component';
import { SharesListExternalUsersComponent } from './shares-list-external-users/shares-list-external-users.component';
import { ExternalSharingComponent } from './external-sharing/external-sharing.component';

@Component({
  selector: 'vex-cases',
  templateUrl: './cases.component.html',
  styleUrls: ['./cases.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class CasesComponent implements OnInit, AfterViewInit, OnDestroy {

  layoutCtrl = new FormControl('fullwidth');
  private socketObject;

  public totalRecords = 0;
  public totalpages:Number = 0

  public moduleDetails = appModulesList.mutualCaseManagement;

  public caseStatus = [
    { name: 'open' },
    { name: 'close' },
    { name: 'reopen' }
  ]


  @Input()
  columns: TableColumn<User>[] = [
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
    //{ label: 'Case Type', property: '_mutualCaseType', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'First Party Name', property: 'firstPartyName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Second Party Name', property: 'secondPartyName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Department Name', property: '_departmentName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Advocate Assigned', property: '_advocateAssigned', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'In House User', property: '_inHouseUserAssigned', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Case Description', property: 'caseDescription', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Future Updates Brief', property: 'futureUpdatesBrief', type: 'richText', visible: false, cssClasses: ['font-medium'] },
    //{ label: 'Documents', property: 'docs', type: 'button', visible: false },
    //{ label: 'Status', property: 'caseStatus', type: 'text', visible: false, cssClasses: ['font-medium'] },
  ];
  pageIndex = environment.pagination.defaultpageIndex
  pageSize = environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;
  dataSource: MatTableDataSource<User> | null = new MatTableDataSource();
  selection = new SelectionModel<User>(true, []);
  searchCtrl = new FormControl();

  labels = aioTableLabels;

  icPhone = icPhone;
  icGroupWork = icGroupWork;
  icNoteAdd = icNoteAdd;
  icSecurity = icSecurity;
  icWarning = icWarning;
  icShare = icShare;
  icMail = icMail;
  icMap = icMap;
  icDetails = icDetails;
  icTrackChanges = icTrackChanges;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;

  theme = theme;

  public userDetails;
  private userDetailUpdateSubscriber;
  public selectedcaseStatus: string = "";

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  search_value: any;

  constructor(private dialog: MatDialog,
    private router: Router,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackbar: MatSnackBar) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    return of(mutualCasesDummyData.map(user => new User(user)));
  }

  ngOnInit() {
    this.refreshData(this);

    let tmpObj = this;
    this.userDetails = this.commonServices.getLoggedInUserDetails();
    this.userDetailUpdateSubscriber = this.commonServices.getUserDetailsUpdateObserver().subscribe((userDetails) => {
      tmpObj.userDetails = userDetails;
    });
  }

  refreshData(tmpObj) {
    if(tmpObj.searchCtrl.value){
      this.search_value = tmpObj.searchCtrl.value
    }
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listMutualCasesPagination, { 

        filterText: tmpObj.searchCtrl.value,
         pageSize: tmpObj.pageSize,
          skip: tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:tmpObj.pageIndex* tmpObj.pageSize,
           caseStatus: tmpObj.selectedcaseStatus
           }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          {tmpObj.users = socketResponse.data;
          let usersList: User[] = [];
          // socketResponse.data.length=0
          for (let i = 0; i < socketResponse.data.length; i++) {
            usersList.push(new User(socketResponse.data[i]));
          }
          tmpObj.dataSource = new MatTableDataSource(usersList);
          tmpObj.totalRecords = socketResponse.totalRecords;
          let record = tmpObj.totalRecords/10
          let secrecord = (record+ "").split(".")[1]
         
    if(Number(secrecord)){
     
      tmpObj.totalpages = parseInt((tmpObj.totalRecords/10)+"")
      tmpObj.totalpages = Number(tmpObj.totalpages)+1
     
    }else{
      tmpObj.totalpages = (tmpObj.totalRecords/10)
    }
        
        
        }



          if(socketResponse.data.length==0&&tmpObj.searchCtrl.value){
           
            tmpObj.msg = socketResponse.msg
            // console.log(tmpObj.dataSource.filteredData,"tmpObj.dataSource")
            // tmpObj.dataSource = null
            tmpObj.dataSource.filteredData.length = 0
            console.log(tmpObj.msg,"this.msg")
                        }else{
            
                          tmpObj.msg = "No results containing In Our Record Please ADD ."
                        }
          }else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  createCustomer() {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToCreateMutualCase(this.moduleDetails)) {
        this.dialog.open(CasesCreateUpdateComponent, {
          panelClass: 'mutual-case-dialog-container',
          disableClose: true
        }).afterClosed().subscribe((user: User) => {
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.createMutualCase, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Created Mutal Case.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  updateCustomer(user: User) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        this.dialog.open(CasesCreateUpdateComponent, {
          data: user,
          panelClass: 'mutual-case-dialog-container',
          disableClose: true
        }).afterClosed().subscribe(updatedCustomer => {
          if (updatedCustomer) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.updateMutualCase, updatedCustomer, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Updated Mutual Case.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteCustomer(customer: User) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToDeleteMutualCase(customer, this.moduleDetails)) {
        if (confirm("Do you really want to delete this case?") && this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.deleteMutualCase, customer, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Deleted Mediations.', 'Okay', {
                duration: 4000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteusers(users: User[]) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    users.forEach(c => this.deleteCustomer(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();

    if (value.length >= 3 || value.length == 0) {
      this.refreshData(this);
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  ngOnDestroy() {
    if (this.userDetailUpdateSubscriber) {
      this.userDetailUpdateSubscriber.unsubscribe();
    }
  }

  goToDiscussionBoard(event, caseDetails: User) {
    event.stopPropagation();
    if (environment.roleDetails) {
      let subModulesKey = [];
      if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
        subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
      }

      if ((environment.roleDetails.name == 'super_admin' && environment.roleDetails.is_custom_role == false) || (subModulesKey.indexOf(this.moduleDetails.subModules.discussionBoard) != -1)) {
        this.router.navigate(["/app/discussion-board", caseDetails._id, environment.constants.mutualCase]);
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  changeCaseStatus(caseDetails) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(caseDetails, this.moduleDetails)) {
        this.dialog.open(CaseStatusChangeComponent, {
          data: caseDetails,
          disableClose: true
        }).afterClosed().subscribe((user: User) => {
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.changeMutualCaseStatus, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Changed Case Status.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  goToCaseDetailsPage(caseDetails: User) {
    this.router.navigate(["/app/cases-management/case-details", caseDetails._id, environment.constants.mutualCase]);
  }

  pageChangeHandler(eventDetails) {
    if (eventDetails && eventDetails.pageSize) {
      this.pageSize = eventDetails.pageSize;
      this.pageIndex = eventDetails.pageIndex
    }
    this.refreshData(this);
  }

  shareWithInternalUser(mutualCaseDetails) {
    if (mutualCaseDetails) {
      this.dialog.open(MutualCaseInternalSharingComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          mutualCase: mutualCaseDetails,
          userPermissions: null
        },
        disableClose: true
      }).afterClosed().subscribe((internalSharingDetails) => {
        if (internalSharingDetails) {
          internalSharingDetails.mutualCaseId = mutualCaseDetails._id;
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.shareMutualCaseInternalUser, internalSharingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Shared with application user.', 'Okay', {
                  duration: 4000
                });
                tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }

  showInternalShareList(mutualCaseDetails) {
    if (mutualCaseDetails) {
      this.dialog.open(MutualCaseSharesListComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          mutualCase: mutualCaseDetails,
          userPermissions: {}
        },
        disableClose: true
      }).afterClosed().subscribe((internalSharingDetails) => {
      });
    }
  }

  shareWithExternalUser(mutualCaseDetails) {
    if (mutualCaseDetails) {
      this.dialog.open(ExternalSharingComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          mutualCase: mutualCaseDetails,
          userPermissions: null
        },
        disableClose: true
      }).afterClosed().subscribe((externalSharingDetails) => {
        if (externalSharingDetails) {
          externalSharingDetails.mutualCaseId = mutualCaseDetails._id;
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.shareMutualCaseExternalUser, externalSharingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Shared with External User.', 'Okay', {
                  duration: 4000
                });
                tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }

  showExternalShareList(mutualCaseDetails) {
    if (mutualCaseDetails) {
      this.dialog.open(SharesListExternalUsersComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          mutualCase: mutualCaseDetails,
          userPermissions: {}
        },
        disableClose: true
      }).afterClosed().subscribe((externalSharingDetails) => {
      });
    }
  }

  caseStatusChangeHandler() {
    this.refreshData(this);
  }

}
