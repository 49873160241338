import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DistrictService } from '../district.service';

@Component({
  selector: 'vex-historydetail',
  templateUrl: './historydetail.component.html',
  styleUrls: ['./historydetail.component.scss']
})
export class HistorydetailComponent implements OnInit {
  datadetail: any;
  historydata: any;

  constructor(@Inject(MAT_DIALOG_DATA) data,private districtcourt: DistrictService,) {
    if(data){
      this.datadetail = data
      // this.caseid=data.caseId;
     }
   }

   ngOnInit() {

    let obj={
      // this.datadetail.court_case_id
      // this.datadetail.id
      "court_case_id": '1',
      "case_history_id": '1'
    }
    console.log(obj,"obj")

    this.districtcourt.postecourt('ws/eGetCaseHistoryDetail',obj).subscribe((res2: any) => {
console.log(res2,"res2")
this.historydata = res2.caseHistoryDetailInfo[0]

    })
  }


}
