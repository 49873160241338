import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MatSnackBar } from '@angular/material';
import icHorizontalSplit from '@iconify/icons-ic/twotone-horizontal-split';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-case-status-change',
  templateUrl: './case-status-change.component.html',
  styleUrls: ['./case-status-change.component.scss']
})
export class CaseStatusChangeComponent implements OnInit {
  private socketObject;

  public courtCasesList = [];
  public courtsList = [];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icHorizontalSplit = icHorizontalSplit;
  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;

  icPerson = icPerson;
  icEditLocation = icEditLocation;

  constructor(@Inject(MAT_DIALOG_DATA) public userDetails: any,
    private dialogRef: MatDialogRef<CaseStatusChangeComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if (this.userDetails) {
      this.mode = 'update';
    } else {
      this.userDetails = {} as User;
    }

    let status;
    if ( this.userDetails.caseStatus ) {
      status = this.userDetails.caseStatus;
    } else if ( this.userDetails.case_status ) {
      status = this.userDetails.case_status;
    } else {
      status = "";
    }
    console.log(this.userDetails,"this.userDetails")


    this.form = this.fb.group({
      _id: this.userDetails._id || '',
      court_no:[this.userDetails.courtDetails.courtNumber?this.userDetails.courtDetails.courtNumber : '', Validators.required],
      status: [status, Validators.required],
      remark: [this.userDetails.remark?this.userDetails.remark : '', Validators.required],
    });
  }

  save() {
    this.createCustomer();
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

}
