import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { TribunalService } from '../tribunal-court/tribunal.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-tribunal-case-history-detail',
  templateUrl: './tribunal-case-history-detail.component.html',
  styleUrls: ['./tribunal-case-history-detail.component.scss']
})
export class TribunalCaseHistoryDetailComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<TribunalCaseHistoryDetailComponent>, @Inject(MAT_DIALOG_DATA) public data: any,private tribunalService:TribunalService,private snackBar: MatSnackBar,) { }

  caseHistoryDetails
  // coram;
  t1;t2
  showData=false;
  handleMatSprnner=true
  showErrMsg=false
  msg;
  ngOnInit() {
    this.tribunalService.postData('caseHistoryDetails',this.data).subscribe((res)=>{
      if(res.status==true){
        this.caseHistoryDetails = res.caseHistoryDetails[0]
        // this.coram = this.caseHistoryDetails.coram.split(',')
        this.t1 = this.data.t1;
        this.t2 = this.data.t2;
        this.showData=true
        this.handleMatSprnner=false
      }else{
        this.msg = res.message;
        this.handleMatSprnner=false
        this.showErrMsg=true
      }
    },(err)=>{
      console.log(err)
      this.handleMatSprnner = false;
      this.dialogRef.close();
      this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    }
    )
  }

}
