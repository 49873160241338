export const appModulesList = {
    "userManagement": {
        "name": "Users",
        "key": "users_management",
        "route": "/app/users-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "taskManagement": {
        "name": "Users",
        "key": "tasks_management",
        "route": "/app/tasks-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
            "markCompleted": "mark_completed",
        }
    },
    "rolesManagement": {
        "name": "Roles",
        "key": "roles_management",
        "route": "/app/roles-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "advocateFirmManagement": {
        "name": "Advocates/Firms",
        "key": "advocate_firms",
        "route": "/app/advocate-firm-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "reportsManagement": {
        "name": "Reports",
        "key": "reports_managment",
        "route": "/app/reports-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "inwardNoticeDropbox": {
        "name": "Inward Notice Dropbox",
        "key": "inward_notice_dropbox",
        "route": "/app/inward-notices-dropbox",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "legalNoticeManagement": {
        "name": "Legal/Departmental Notices",
        "key": "legal_notices",
        "route": "/app/legal-notices",
        "allList": "allList",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "billingManagement": {
        "name": "Billing",
        "key": "billing",
        "route": "/app/billing-management",
        "subModules": {
            "checkInvoices": "check_invoices",
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
            "markPaid": "mark_paid",
        }
    },
    "courtCaseManagement": {
        "name": "Court Cases",
        "key": "court_cases",
        "route": "/app/cases-management/court-cases",
        "subModules": {
            "discussionBoard": "discussion_board",
            "allList": "allList",
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "arbitrationManagement": {
        "name": "Arbitration",
        "key": "arbitration",
        "route": "/app/cases-management/Arbitration",
        "subModules": {
            "discussionBoard": "discussion_board",
            "allList": "allList",
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "arbitrationHearingMangagement":{
        "name": "ArbitrationHearing",
        "key": "arbitrationhearing",
        "route": "/app/cases-management/Arbitration",
        "subModules": {
            
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }

    },
    "mutualCaseManagement": {
        "name": "Out of Court Cases",
        "key": "mutual_cases",
        "route": "/app/cases-management/mutual-cases",
        "subModules": {
            "discussionBoard": "discussion_board",
            "allList": "allList",
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "legalDocsManagement": {
        "name": "Legal Docs",
        "key": "legal_docs",
        "route": "",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
            "updateDocs": {
                name: "Update Documents",
                route: "/app/legal-docs-management/doc-edit"
            }
        }
    },
    "appointmentManagement": {
        "name": "Appointments",
        "key": "appointments_management",
        "route": "/app/appointments-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "hearingManagement": {
        "name": "Hearings",
        "key": "hearings_management",
        "route": "/app/hearings-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "calendarView": {
        "name": "Calendar View",
        "key": "calendar_view",
        "route": "/app/calendar-view",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "judgementManagement": {
        "name": "Judgements",
        "key": "judgements_management",
        "route": "/app/jugements-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "governmentNotificationManagement": {
        "name": "Government Notifications",
        "key": "government_notification_management",
        "route": "/app/government-notification-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "bareActsManagement": {
        "name": "Bare Acts",
        "key": "bare_acts_management",
        "route": "/app/bare-acts-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "caseAbbreviationManagement": {
        "name": "Case Abbreviations",
        "key": "case_abbreviation_management",
        "route": "/app/case-abbreviations-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "courtTypeManagement": {
        "name": "Court Types",
        "key": "court_types_management",
        "route": "/app/court-types-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "courtManagement": {
        "name": "Court",
        "key": "court_management",
        "route": "/app/courts-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "judgementAreaManagement": {
        "name": "Judgement Areas",
        "key": "judgement_area_management",
        "route": "/app/jugement-types-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "divisionManagement": {
        "name": "Divisions",
        "key": "division_management",
        "route": "/app/divisions-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "departmentManagement": {
        "name": "Departments",
        "key": "department_management",
        "route": "/app/departments-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "designationManagement": {
        "name": "Designation",
        "key": "designation_management",
        "route": "/app/designations-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
    "projectSitesManagement": {
        "name": "Branch/Office",
        "key": "project_sites_management",
        "route": "/app/project-sites-management",
        "subModules": {
            "view": "view",
            "create": "create",
            "update": "update",
            "delete": "delete",
        }
    },
};