import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'relativeDateTime'
})
export class RelativeDateTimePipe implements PipeTransform {

  transform(value: string | null, ...args: any[]): any {

    let date;
    if(value) {
      date = DateTime.fromISO(value)
    }
    return date ? date.toRelative() : null;
  }

}
