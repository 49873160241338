import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'

// import { environment } from '../../environments/environment'


@Injectable({
  providedIn: 'root'
})
export class CourtcaseService {

  private history: string[] = []
  // Supreme Court micro service URL
  public url = environment.socketConfiguration.msSupremeCourtURL;
  
  
  constructor(private router: Router, private location: Location,public http: HttpClient) { }

  public startSaveHistory():void{
    this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.history.push(event.urlAfterRedirects)
        }
      })
  }
  
  public getHistory(): string[] {
    return this.history;
  }
  
  public goBack(): void {
    // this.history.pop();
    // this.location.back()
    // this.router.navigate(history.state);
    
  
    // if (this.history.length > 0) {
    //   this.location.back()
    // } else {
    //   this.router.navigateByUrl("/")
    // }
  }
  
  public getPreviousUrl(): string {
    if (this.history.length > 0) {
        return this.history[this.history.length - 2];
    }
  
    return '';
  }
  
  
  post(urlT: any, data: any) {
    const updateUrl = this.url + urlT;
  
   
    return this.http.post(updateUrl, data);
  }

 
  
  postyear(urlT: any){
    const updateUrl = this.url + urlT;
  let obj={
  
  }
   
    return this.http.post(updateUrl,obj);
  
  }
  
  

}


