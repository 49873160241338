import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export class CustomValidators {
    nameValidator(control: FormControl) {
        const nameRegexp: RegExp = /[^[a-zA-Z](?:[ '.\-a-zA-Z]*[a-zA-Z])?$]/;
        if (control.value && nameRegexp.test(control.value)) {
            return { invalidName: true };
        }

    }
    panvalidator(control: FormControl){
       
        const nameRegexp: RegExp = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
        console.log(nameRegexp.test(control.value),"pancard working ")
        if (control.value && !nameRegexp.test(control.value)) {
            return { invalidPan: true };
        }
    }

    addressValidator(control: FormControl) {
        const nameRegexp: RegExp = /[^[a-zA-Z0-9\s,'-]*$]/;
        if (control.value && nameRegexp.test(control.value)) {
            return { invalidAddress: true };
        }

    }

    zipValidator(control: FormControl) {
       
        const nameRegexp: RegExp = /^(?=.*\d.*)[A-Za-z0-9]{3,10}$/;
        console.log("zip validator work ",nameRegexp.test(control.value))
        if (control.value && !nameRegexp.test(control.value)) {
            return { invalidZip: true };
        }

    }


    checkPasswords: ValidatorFn = (group: AbstractControl): ValidationErrors | null => {
        let pass = group.get('password').value;
        let confirmPass = group.get('confirmpassword').value
        return pass === confirmPass ? null : { notSame: true }
    }

    // import { FormGroup } from '@angular/forms';



    ConfirmedValidator(password: string, confirmpassword: string) {

        return (formGroup: FormGroup) => {

            const control = formGroup.controls[password];

            const matchingControl = formGroup.controls[confirmpassword];

            if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {

                return;

            }

            if (control.value !== matchingControl.value) {

                matchingControl.setErrors({ confirmedValidator: true });

            } else {

                matchingControl.setErrors(null);

            }

        }

    }




    validate(ctrl: AbstractControl): { [key: string]: any } {
        let password = ctrl.value;
        let hasLower = false;
        let hasUpper = false;
        let hasNum = false;
        let hasSpecial = false;

        const lowercaseRegex = new RegExp("(?=.*[a-z])");// has at least one lower case letter
        if (lowercaseRegex.test(password)) {
            hasLower = true;
        }

        const uppercaseRegex = new RegExp("(?=.*[A-Z])"); //has at least one upper case letter
        if (uppercaseRegex.test(password)) {
            hasUpper = true;
        }

        const numRegex = new RegExp("(?=.*\\d)"); // has at least one number
        if (numRegex.test(password)) {
            hasNum = true;
        }

        const specialcharRegex = new RegExp("[!@#$%^&*(),.?\":{}|<>]");
        if (specialcharRegex.test(password)) {
            hasSpecial = true;
        }

        let counter = 0;
        let checks = [hasLower, hasUpper, hasNum, hasSpecial];
        for (let i = 0; i < checks.length; i++) {
            if (checks[i]) {
                counter += 1;
            }
        }

        if (counter < 4) {
            return { invalidPassword: true }
        } else {
            return null;
        }



    }




}