import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConsumerCourtService {

  cosumerForum = environment.socketConfiguration.consumerForum

  constructor(private http:HttpClient) { }

  getData(url):Observable<any>{
    return this.http.get(this.cosumerForum + url)
  }

  postData(url,data):Observable<any>{
    return this.http.post(this.cosumerForum + url,data)
  }
}
