import { Component, OnInit } from "@angular/core";
// import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import icCloud from "@iconify/icons-ic/twotone-close";
// import { CourtcaseService } from '../addcourtcase/courtcase.service';
import { SocketService } from "src/app/services/core/socket.service";
import { CommonService } from "src/app/services/core/common.service";
import { environment } from "src/environments/environment";
import { MatSnackBar } from "@angular/material";
// import { DistrictService } from './district.service';
import { MatDialog } from "@angular/material/dialog";
// import { DistrictcourtdetailComponent } from "../districtcourt/districtcourtdetail/districtcourtdetail.component";
// import { DistrictService } from '../districtcourt/district.service';
import { HighcourtService } from "./highcourt.service";
import { HighcourtdetailComponent } from "./highcourtdetail/highcourtdetail.component";
import { CourtcaseService } from "../addcourtcase/courtcase.service";
import { DistrictService } from "../districtcourt/district.service";
import { DelhihighcourtService } from "./delhihighcourt.service";
import { DelhihighcourtComponent } from "../delhihighcourt/delhihighcourt.component";
import { BombayhighcourtService } from "./bombayhighcourt.service";
import { BombayhighcourtdetailComponent } from "../bombayhighcourtdetail/bombayhighcourtdetail.component";
import { AllahabadhighcourtService } from "./allahabadhighcourt.service";
import { AllahabadDetailComponent } from "../allahabad-detail/allahabad-detail.component";
import { HimachalhighcourtService } from "./himachalhighcourt.service";
import { HimachalDetailComponent } from "../himachal-detail/himachal-detail.component";
import { PunjabService } from "./punjab.service";
import { PunjabDetailComponent } from "../punjab-detail/punjab-detail.component";
// import { CourtCasesService } from '../addcase/court-cases.service';
// import { DistrictService } from '../districtcourt/districtcourt.service';
// import { DistrictcourtdetailComponent } from './districtcourtdetail/districtcourtdetail.component';

@Component({
  selector: "vex-highcourt",
  templateUrl: "./highcourt.component.html",
  styleUrls: ["./highcourt.component.scss"],
})
export class HighcourtComponent implements OnInit {
  defaultRecords: any = 10;
  private socketObject;
  icCloud = icCloud;
  state: any;
  selectedvalue: any;
  // selectedservervalue = 1;

  valueOfEcourt = true;
  valueOfDelhi = false;
  valueOfBombay = false;
  valueOfAllahabad = false;
  valueOfHimachalPradesh=false;
  valueOfPunjab=false;

  distselectedvalue: any;
  fourmselectedvalue: any;
  districtlist: any;
  fourmlist: any;
  districtcode: any;
  yeardata: any;
  delhi_highcourtcasetype: any;
  casetype: any;
  delhihighcourtcasetype: any;
  msg = "";
  casenumbermsg = "";
  partymsg = "";
  courtcaselist: any;
  loading = false;
  partylist: any;
  partylistdata = [];
  partydata: any[];
  public totalRecords = 0;
  public totalpages = 0;
  Records = 0;
  pageIndex = 0;
  pageSize = environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;

  serverType:any;

  serverlist = [];
  delhihighcourtcaselist: any;
  delhihighcourtcasepartylist: any;
  delhihighcourtcaseadvocatelist: any;
  delhihighcourtcaseadiarylist: any;
  delhi_highcourt_bycaseno: string;
  delhi_highcourt_byparty: string;
  delhi_highcourt_byadvocate: string;
  delhi_highcourt_bydiary: string;

  courtBenchCategoryData: any;
  caseSideCategoryData: any;

  districtOfAllahabadhighcourtData:any
  partyTypeOfAllahabadhighcourtData:any
  caseTypeOfAllahabadhighcourtData:any
  courtBenchOfAllahabadhighcourtData:any

  constructor(
    private court: CourtcaseService,
    private districtcourt: DistrictService,
    private highcourt: HighcourtService,
    private router: Router,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private dialog: MatDialog,
    private delhihighcourtservice: DelhihighcourtService,
    private bombayhighcourtservice: BombayhighcourtService,
    private allahabadhighcourtservice: AllahabadhighcourtService,
    private himalchalhighcourtservice: HimachalhighcourtService,
    private punjabservice:PunjabService
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  //    {
  //     "case_no": "1",
  //     "state_code": "26",
  //     "court_code": "1",
  //     "case_year": "2022",
  //     "case_type_category_id": "92",
  //     "saved_by": "1"
  // }

  mydistrictForm = new FormGroup({
    state_code: new FormControl("", Validators.required),

    court_code: new FormControl("", Validators.required),
    case_type_category_id: new FormControl("", Validators.required),
    case_no: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    saved_by: new FormControl("1", Validators.required),
  });

  // {
  // "search_by": "1",
  // "case_type": "CRL.A.",
  // "case_no": "1",
  // "diary_no": "",
  // "party_name": "",
  // "advocate_name": "",
  // "case_year": "2023"
  // }
  mydelhihighcourtForm = new FormGroup({
    search_by: new FormControl("", Validators.required),

    case_type: new FormControl("", Validators.required),
    case_no: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    party_name: new FormControl("", Validators.required),
    advocate_name: new FormControl("", Validators.required),
    diary_no: new FormControl("", Validators.required),
  });

  mydelhihighcourtFormByCaseNumber = new FormGroup({
    search_by: new FormControl("", Validators.required),

    case_type: new FormControl("", Validators.required),
    case_no: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_name: new FormControl(""),
    advocate_name: new FormControl(""),
    diary_no: new FormControl(""),
  });

  mydelhihighcourtFormByDiaryNumber = new FormGroup({
    search_by: new FormControl("", Validators.required),

    case_type: new FormControl(""),
    case_no: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_name: new FormControl(""),
    advocate_name: new FormControl(""),
    diary_no: new FormControl("", Validators.required),
  });
  mydelhihighcourtFormByPartyName = new FormGroup({
    search_by: new FormControl("", Validators.required),

    case_type: new FormControl(""),
    case_no: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_name: new FormControl("", Validators.required),
    advocate_name: new FormControl(""),
    diary_no: new FormControl(""),
  });
  mydelhihighcourtFormByAdvocateName = new FormGroup({
    search_by: new FormControl("", Validators.required),

    case_type: new FormControl(""),
    case_no: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_name: new FormControl(""),
    advocate_name: new FormControl("", Validators.required),
    diary_no: new FormControl(""),
  });

  mypartyForm = new FormGroup({
    state_code: new FormControl("", Validators.required),

    court_code: new FormControl("", Validators.required),
    case_type_category_id: new FormControl("", Validators.required),
    search_keyword: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    saved_by: new FormControl("2", Validators.required),
  });

  caseNumberFormBombay = new FormGroup({
    court_code: new FormControl("", Validators.required),
    case_side: new FormControl("", Validators.required),
    case_category: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    case_no: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
  });

  partyNameFormBombay = new FormGroup({
    search_by: new FormControl("1"),
    court_code: new FormControl("", Validators.required),
    case_side: new FormControl("", Validators.required),
    party_type: new FormControl("", Validators.required),
    party_name: new FormControl("", Validators.required),
    advocate_name: new FormControl(""),
    case_year: new FormControl("", Validators.required),
  });

  advocateFormBombay = new FormGroup({
    search_by: new FormControl("2"),
    court_code: new FormControl("", Validators.required),
    case_side: new FormControl("", Validators.required),
    party_type: new FormControl("", Validators.required),
    party_name: new FormControl(""),
    advocate_name: new FormControl("", Validators.required),
    case_year: new FormControl(""),
  });

  caseNumberFormAllahabad = new FormGroup({
    search_by: new FormControl("1"),
    court_bench: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    case_no: new FormControl("", Validators.required),
    party_type: new FormControl(""),
    party_name: new FormControl(""),
    district: new FormControl(""),
    case_year: new FormControl("",Validators.required)
  });

  partyNameFormAllahabad = new FormGroup({
    search_by: new FormControl("2"),
    court_bench: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_no: new FormControl(""),
    party_type: new FormControl("",Validators.required),
    party_name: new FormControl("",Validators.required),
    district: new FormControl(""),
    case_year: new FormControl("",Validators.required)
  });

  HimachalHighCourtCaseForm = new FormGroup({
    case_category: new FormControl("",Validators.required),
    case_type: new FormControl("",Validators.required),
    case_no: new FormControl("",Validators.required),
    case_year: new FormControl("",Validators.required)
  });
  HimachalHighCourtPartyForm = new FormGroup({
    search_by: new FormControl("1"),
    case_year: new FormControl("",Validators.required),
    party_type: new FormControl("",Validators.required),
    party_name: new FormControl("",Validators.required),
    case_status: new FormControl("",Validators.required),
  });
  HimachalHighCourtAdvocateForm = new FormGroup({
    search_by: new FormControl("2"),
    case_year: new FormControl("",Validators.required),
    case_status: new FormControl("",Validators.required),
    advocate_type: new FormControl("",Validators.required),
    advocate_name: new FormControl("",Validators.required),
  });
  PunjabCaseNoForm = new FormGroup({
    search_by: new FormControl("1"),
    case_type: new FormControl("",Validators.required),
    case_no: new FormControl("",Validators.required),
    case_year: new FormControl("",Validators.required)
  });
  PartyTypePunjabForm = new FormGroup({
    search_by: new FormControl("2"),
    search_type: new FormControl("",Validators.required),
    search_keyword: new FormControl("",Validators.required),
    case_type: new FormControl(""),
    case_year: new FormControl("",Validators.required),
    district: new FormControl("")
  });
  AdvocatePunjabForm = new FormGroup({
    search_by: new FormControl("3"),
    search_type: new FormControl("",Validators.required),
    search_keyword: new FormControl("",Validators.required),
    case_type: new FormControl(""),
    case_year: new FormControl("",Validators.required),
    district: new FormControl("")
  });

  ngOnInit() {
    this.getstate();
    this.getyear();
    this.courtBenchCategory();
    this.gethighcourtcasetype();
    this.caseTypeOfDelhiHighCourt();
    this.caseCategory();
    this.partyTypeCategory();

    this.mypartyForm.get("case_type_category_id").setValue("Pending");
  }

  refreshData(tmpObj) {
    console.log(
      tmpObj.paginator ? tmpObj.paginator.pageIndex * tmpObj.pageSize : 0,
      "tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:0"
    );
    // console.log(this.paginator,"this.paginator")
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(
        environment.socketEvents.listTemproryhighCourtCase,
        {
          pageSize: tmpObj.pageSize,
          skip: tmpObj.pageIndex ? tmpObj.pageIndex * tmpObj.pageSize : 0,

          //  skip: 0
        },

        function (socketResponse) {
          console.log(socketResponse, "by api ");
          if (socketResponse.status == "OK") {
            tmpObj.partylistdata = socketResponse.data;
            // console.log(this.caselistdata,"this party list 1 ")
            tmpObj.totalRecords = socketResponse.totalRecords;

            this.partylistdata = tmpObj.partylistdata;

            this.totalRecords = tmpObj.totalRecords;
            console.log(this.partylistdata, "this party list 1 ");
            tmpObj.getparty();
          } else {
            tmpObj.snackbar.open(socketResponse.message, "Okay", {
              duration: 4000,
            });
          }
        }
      );
    }
  }

  getparty() {
    this.partydata = this.partylistdata;
    this.Records = this.totalRecords;

    let record = this.totalRecords / 10;
    let secrecord = (record + "").split(".")[1];

    if (Number(secrecord)) {
      this.totalpages = parseInt(this.totalRecords / 10 + "");
      this.totalpages = Number(this.totalpages) + 1;
    } else {
      this.totalpages = this.totalRecords / 10;
    }
  }

  getstate() {
    let obj = {};
    this.highcourt
      .postecourt("ws/eGetCourtState", obj)
      .subscribe((res2: any) => {
        // console.log(res2, "res2 of state");

        this.state = res2.courtStateInfo;
        console.log(this.state)
      });
  }

  getyear() {
    // this.myForm.value
    this.court.postyear("ws/caseYears").subscribe((res2: any) => {
      this.yeardata = res2.caseYear;

      if(this.yeardata){
        this.mypartyForm.get("case_year").setValue(this.yeardata[0]['year']);
        this.mydistrictForm.get("case_year").setValue(this.yeardata[0]['year']);
        this.mydelhihighcourtFormByCaseNumber.get("case_year").setValue(this.yeardata[0]['year']);
        this.mydelhihighcourtFormByDiaryNumber.get("case_year").setValue(this.yeardata[0]['year']);
        this.mydelhihighcourtFormByPartyName.get("case_year").setValue(this.yeardata[0]['year']);
        this.mydelhihighcourtFormByAdvocateName.get("case_year").setValue(this.yeardata[0]['year']);

        this.caseNumberFormBombay.get("case_year").setValue(this.yeardata[0]['year']);
        this.partyNameFormBombay.get("case_year").setValue(this.yeardata[0]['year']);
        this.advocateFormBombay.get("case_year").setValue(this.yeardata[0]['year']);

        this.caseNumberFormAllahabad.get("case_year").setValue(this.yeardata[0]['year']);
        this.partyNameFormAllahabad.get("case_year").setValue(this.yeardata[0]['year']);
        this.HimachalHighCourtCaseForm.get("case_year").setValue(this.yeardata[0]['year']);
        this.HimachalHighCourtPartyForm.get("case_year").setValue(this.yeardata[0]['year']);
        this.HimachalHighCourtAdvocateForm.get("case_year").setValue(this.yeardata[0]['year']);

        this.PunjabCaseNoForm.get("case_year").setValue(this.yeardata[0]['year']);
        this.PartyTypePunjabForm.get("case_year").setValue(this.yeardata[0]['year']);
        this.AdvocatePunjabForm.get("case_year").setValue(this.yeardata[0]['year']);
      }
    });
    // ws/courtCategories
  }

  stateCode;
  getvalue(data) {
    // console.log(this.state);
    // let state_server = this.state.map((el) => el.state_code).indexOf(data);
    // this.serveresd = this.state[state_server];
    // if (this.serveresd && this.serveresd.individual_server) {
    //   if (this.serverlist.length != 2) {
    //     let objectvlaue = { id: 0, name: "HighCourt Server" };
    //     this.serverlist.push(objectvlaue);
    //   }

    //   console.log(this.serverlist, "this.serverlist");
    // } else {
    //   if (this.serverlist.length == 2) {
    //     this.serverlist.pop();
    //   }
    // }

    this.serverlist = [];

    // if (this.serverlist.length == 0) {
    //   this.valueOfEcourt = true;
    //   this.valueOfDelhi = false;
    //   this.valueOfBombay = false;
    //   this.valueOfAllahabad = false;
    //   this.valueOfHimachalPradesh = false;
    // }

    this.stateCode = data;

    this.getServerName(this.stateCode);

    let obj = {
      state_code: data,
    };

    this.highcourt
      .postecourt("ws/eCourtStateBench", obj)
      .subscribe((res2: any) => {
        console.log(res2, "res2 of state");

        this.fourmlist = res2.courtStateDistricts;
      });
  }

  getServerName(val) {
    let obj = {
      state_code: val,
    };

    this.highcourt.postecourt("ws/serverName", obj).subscribe((res) => {
      if (res["status"] == true) {
        console.log(this.serverlist);
        this.serverlist.push(...res["courtInfo"]);
        console.log(this.serverlist);
      }
    });
  }

  getServerValue(data) {
    this.valueOfEcourt = false;
    this.valueOfBombay = false;
    this.valueOfAllahabad = false;
    this.valueOfHimachalPradesh = false;
    this.valueOfDelhi = false;
    this.valueOfPunjab=false;

    if (this.stateCode == 26 && data == "High Court Server") {
      this.valueOfDelhi = true;
      this.serverType='High Court Server'
    } else if (this.stateCode == 1 && data == "High Court Server") {
      this.valueOfBombay = true;
      this.serverType='High Court Server'
    } else if (this.stateCode == 13 && data == "High Court Server") {
      this.valueOfAllahabad = true;
      this.serverType='High Court Server'

      this.courtBenchOfAllahabadhighcourt();
      this.caseTypeOfAllahabadhighcourt();
      this.partyTypeOfAllahabadhighcourt();
      this.districtOfAllahabadhighcourt();
    } else if (this.stateCode == 5 && data == "High Court Server") {
      this.valueOfHimachalPradesh = true;
      this.serverType='High Court Server'

      this.caseCategoryOfHimachalPradesh();
      this.caseTypeCategoryOfHimachalPradesh();
      this.partyTypeCategoryHimachalPradesh();
      this.statusCategoryHimachalPradesh();
      this.advocateTypeCategoryHimachalPradesh();
    }else if (this.stateCode == 22 && data == "High Court Server") {
      this.valueOfPunjab=true;
      this.serverType='High Court Server'
      this.caseTypeofPunjab();
      this.partyTypePunjab();
      this.districtCategoryPunjab();
      // this.caseCategoryOfHimachalPradesh();
      // this.caseTypeCategoryOfHimachalPradesh();
      // this.partyTypeCategoryHimachalPradesh();
      // this.statusCategoryHimachalPradesh();
      // this.advocateTypeCategoryHimachalPradesh();
    }
    else {
      this.valueOfEcourt = true;
      this.serverType='e-Court Server'
    }

    // if(this.serveresd.state_name == "Maharashtra" && data == 0){
    //   this.toggleOnMaharastraForm = true
    // }else{
    //   this.toggleOnMaharastraForm = false
    // }
  }

  getvaluedistrict(data) {
    console.log(data, "after select data of district");

    this.districtcode = data.code;

    let obj = {
      // "district_court_id":"107"
      district_court_id: data.id,
    };

    this.districtcourt
      .postecourt("ws/eGetDistrictCourtComplex", obj)
      .subscribe((res2: any) => {
        console.log(res2, "res2 of district");

        // this.fourmlist = res2.caseDistrictCourtComplex
      });
  }

  getcasetype(data) {
    console.log(data, "id");
    this.fourmselectedvalue = data;
    const myid = data.split("|");
    let obj = {
      bench_id: myid[1],
      // "district_court_id":data.id
    };

    this.highcourt
      .postecourt("ws/eHighCourtCaseTypeCategories", obj)
      .subscribe((res2: any) => {
        console.log(res2, "res2 of case type");

        this.casetype = res2.caseTypeCategoryInfo;
      });
  }

  getcasedetail() {
    this.casenumbermsg = "";
    const myform = this.fourmselectedvalue.split("|");

    this.mydistrictForm.controls["state_code"].setValue(this.selectedvalue);
    // this.mydistrictForm.controls['dist_code'].setValue(this.districtcode);
    this.mydistrictForm.controls["court_code"].setValue(myform[0]);

    console.log("get form value", this.mydistrictForm.value);
    if (this.mydistrictForm.valid) {
      this.loading = true;
      this.highcourt
        .postecourt(
          "ws/eHighCourtKeywordSearchParty",
          this.mydistrictForm.value
        )
        .subscribe(
          (res2: any) => {
            console.log(res2, "res2 of court case");

            if (res2) {
              if (res2.partyName) {
                let courtcaselist = res2.partyName[0];
                if (courtcaselist) {
                  let obj = {
                    state_code: courtcaselist.state_code,
                    court_code: courtcaselist.court_code,
                    case_no_year: courtcaselist.case_no_year,
                    case_cino: courtcaselist.case_cino,
                  };
                  //   let obj ={
                  //     "state_code": "26",
                  //     "court_code": "1",
                  //     "case_no_year": "208300000012022",
                  //     "case_cino": "DLHC010401012022"
                  // }
                  this.highcourt.postecourt("ws/eHighCourtCase", obj).subscribe(
                    (res: any) => {
                      if (res && res.status) {
                        console.log(res, obj, "res of court");
                        this.highcourt
                          .postecourt("ws/eAutoUpdateHighCourtCase", obj)
                          .subscribe((res2: any) => {
                            console.log(res2);
                            this.loading = false;
                            if (res2.autoUpdateHighCourtCase) {
                              this.courtcaselist =
                                res2.autoUpdateHighCourtCase[0];
                              console.log(
                                this.courtcaselist,
                                "this.courtcaselist"
                              );
                            }
                            // this.courtcaselist = res2.autoUpdateCourtCase[0]
                          });
                      } else {
                        this.casenumbermsg = res.message;
                        this.courtcaselist = {};
                        this.loading = false;
                      }
                    },
                    (err) => {
                      this.loading = false;
                      this.courtcaselist = {};
                      this.casenumbermsg =
                        "Facing Some Issue From Server Side Please Try after some time ";
                      console.log("throw error", err);
                    }
                  );
                }
              } else {
                console.log(res2.message, "res2.message");
                // getpartydetail
                this.casenumbermsg = res2.message;
                this.courtcaselist = {};
                this.loading = false;
                // setTimeout(() => {
                //   this.msg = ''
                // }, 1000);
              }
            } else {
              this.casenumbermsg = "Data not Found";

              this.loading = false;
              // setTimeout(() => {
              //   this.msg = ''
              // }, 1000);
            }
          },
          (err) => {
            this.loading = false;
            // this.columns = {}
            this.courtcaselist = {};
            this.casenumbermsg = `Facing Some Issue From Server Side Please Try after some time`;
            console.log("throw", err);

            // ${this.userwantsavedata()
          }
        );
    } else {
      // this.msg =  ""
      this.snackBar.open("Please provide all required information.", "CLOSE", {
        duration: 3000,
        horizontalPosition: "center",
      });
    }
  }
  gethighcourtcasetype() {
    this.delhihighcourtcasetype = this.delhihighcourtservice.getcasetype();
    this.delhihighcourtcasetype.subscribe((res) => {
      console.log(res, "log");
      this.delhi_highcourtcasetype = res.partyName;
      console.log(res, "this.delhihighcourtcasetype");
    });
  }

  totalDelhihighcourtcaselist;
  handleByCaseNumber;

  totalDelhihighcourtcaseadiarylist;
  handleByDiaryNumber;

  totalDelhihighcourtcasepartylist;
  handleByPartyName;

  totalDelhihighcourtcaseadvocatelist;
  handleByAdvocateName;

  pageIndexOfCaseNumber = 0;
  totalpagesOfCaseNumber;
  pageIndexOfDiaryNumber = 0;
  totalpagesOfDiaryNumber;
  pageIndexOfPartyNumber = 0;
  totalpagesOfPartyNumber;
  pageIndexOfAdvocateNumber = 0;
  totalpagesOfAdvocateNumber;

  formValue;
  gethighcourtdetail(data) {
    // console.log(this.mydelhihighcourtForm.value)

    if (data == "1") {
      this.mydelhihighcourtFormByCaseNumber.get("search_by").setValue(data);

      this.formValue = this.mydelhihighcourtFormByCaseNumber.value;
    } else if (data == "2") {
      this.mydelhihighcourtFormByPartyName.get("search_by").setValue(data);

      this.formValue = this.mydelhihighcourtFormByPartyName.value;
    } else if (data == "3") {
      this.mydelhihighcourtFormByAdvocateName.get("search_by").setValue(data);

      this.formValue = this.mydelhihighcourtFormByAdvocateName.value;
    } else if (data == "4") {
      this.mydelhihighcourtFormByDiaryNumber.get("search_by").setValue(data);

      this.formValue = this.mydelhihighcourtFormByDiaryNumber.value;
    }

    this.loading = true;
    console.log(this.formValue);
    this.delhihighcourtservice.postecourt("caseList", this.formValue).subscribe(
      (res: any) => {
        console.log(res, "highcourt working after");
        this.loading = false;
        if (data == "1") {
          if (res && res.partyName) {
            this.totalDelhihighcourtcaselist = res.partyName;
            console.log(this.totalDelhihighcourtcaselist);

            this.totalpagesOfCaseNumber = Math.ceil(
              this.totalDelhihighcourtcaselist.length / this.defaultRecords
            );

            this.delhihighcourtcaselist =
              this.totalDelhihighcourtcaselist.slice(0, this.defaultRecords);

            this.handleByCaseNumber = true;
            this.delhi_highcourt_bycaseno = "";
          } else {
            this.handleByCaseNumber = false;
            this.delhi_highcourt_bycaseno = "Data Not Found";
          }
        } else if (data == "2") {
          if (res && res.partyName) {
            this.totalDelhihighcourtcasepartylist = res.partyName;
            this.totalpagesOfPartyNumber = Math.ceil(
              this.totalDelhihighcourtcasepartylist.length / this.defaultRecords
            );

            this.delhihighcourtcasepartylist =
              this.totalDelhihighcourtcasepartylist.slice(
                0,
                this.defaultRecords
              );
            this.handleByPartyName = true;
            this.delhi_highcourt_byparty = "";
          } else {
            this.handleByPartyName = false;
            this.delhi_highcourt_byparty = "Data Not Found";
          }
        } else if (data == "3") {
          if (res && res.partyName) {
            this.totalDelhihighcourtcaseadvocatelist = res.partyName;
            this.totalpagesOfAdvocateNumber = Math.ceil(
              this.totalDelhihighcourtcaseadvocatelist.length /
                this.defaultRecords
            );

            this.delhihighcourtcaseadvocatelist =
              this.totalDelhihighcourtcaseadvocatelist.slice(
                0,
                this.defaultRecords
              );
            this.handleByAdvocateName = true;
            this.delhi_highcourt_byadvocate = "";
          } else {
            this.handleByAdvocateName = false;
            this.delhi_highcourt_byadvocate = "Data Not Found";
          }
        } else if (data == "4") {
          if (res && res.partyName) {
            this.totalDelhihighcourtcaseadiarylist = res.partyName;
            this.totalpagesOfDiaryNumber = Math.ceil(
              this.totalDelhihighcourtcaseadiarylist.length /
                this.defaultRecords
            );

            console.log(this.totalDelhihighcourtcaseadiarylist);
            this.delhihighcourtcaseadiarylist =
              this.totalDelhihighcourtcaseadiarylist.slice(
                0,
                this.defaultRecords
              );
            this.handleByDiaryNumber = true;
            this.delhi_highcourt_bydiary = "";
          } else {
            this.handleByDiaryNumber = false;
            this.delhi_highcourt_bydiary = "Data Not Found";
          }
        }
      },
      (err) => {
        console.log(err);
        this.loading = false;

        if (data == "1") {
          this.delhihighcourtcaselist = [];
          this.delhi_highcourt_bycaseno =
            "Facing Some Server Issue Please Try After Some Time";
        } else if (data == "2") {
          this.delhi_highcourt_byparty =
            "Facing Some Server Issue Please Try After Some Time";
          this.delhihighcourtcasepartylist = [];
        } else if (data == "3") {
          this.delhi_highcourt_byadvocate =
            "Facing Some Server Issue Please Try After Some Time";
          this.delhihighcourtcaseadvocatelist = [];
        } else if (data == "4") {
          this.delhi_highcourt_bydiary =
            "Facing Some Server Issue Please Try After Some Time";
          this.delhihighcourtcaseadiarylist = [];
        }
      }
    );
  }

  onPageChange($event, val) {
    if (val == "caseNumber") {
      this.totalpagesOfCaseNumber = Math.ceil(
        this.totalDelhihighcourtcaselist.length / $event.pageSize
      );

      this.pageIndexOfCaseNumber = $event.pageIndex;
      this.delhihighcourtcaselist = this.totalDelhihighcourtcaselist.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    } else if (val == "diaryNumber") {
      this.totalpagesOfDiaryNumber = Math.ceil(
        this.totalDelhihighcourtcaseadiarylist.length / $event.pageSize
      );

      this.pageIndexOfDiaryNumber = $event.pageIndex;
      this.delhihighcourtcaseadiarylist =
        this.totalDelhihighcourtcaseadiarylist.slice(
          $event.pageIndex * $event.pageSize,
          $event.pageIndex * $event.pageSize + $event.pageSize
        );
    } else if (val == "partyName") {
      this.totalpagesOfPartyNumber = Math.ceil(
        this.totalDelhihighcourtcasepartylist.length / $event.pageSize
      );

      this.pageIndexOfPartyNumber = $event.pageIndex;
      this.delhihighcourtcasepartylist =
        this.totalDelhihighcourtcasepartylist.slice(
          $event.pageIndex * $event.pageSize,
          $event.pageIndex * $event.pageSize + $event.pageSize
        );
    } else if (val == "advocateName") {
      this.totalpagesOfAdvocateNumber = Math.ceil(
        this.totalDelhihighcourtcaseadvocatelist.length / $event.pageSize
      );

      this.pageIndexOfAdvocateNumber = $event.pageIndex;
      this.delhihighcourtcaseadvocatelist =
        this.totalDelhihighcourtcaseadvocatelist.slice(
          $event.pageIndex * $event.pageSize,
          $event.pageIndex * $event.pageSize + $event.pageSize
        );
    }
  }

  getpartydetail() {
    this.partymsg = "";
    const myform = this.fourmselectedvalue.split("|");
    this.mypartyForm.controls["state_code"].setValue(this.selectedvalue);
    // this.mydistrictForm.controls['dist_code'].setValue(this.districtcode);
    this.mypartyForm.controls["court_code"].setValue(myform[0]);

    if (this.mypartyForm.valid) {
      this.loading = true;
      this.highcourt
        .postecourt("ws/eHighCourtKeywordSearchParty", this.mypartyForm.value)
        .subscribe(
          (res2: any) => {
            console.log(res2, "res2 of court case");
            if (res2.partyName) {
              console.log(" if conditionres2 of court case");
              this.partylist = res2.partyName;
              this.loading = false;

              if (this.socketService.isSocketConnected()) {
                let tmpObj = this;
                this.socketObject.emit(
                  environment.socketEvents.createhighcourtTemproryCourtCase,
                  this.partylist,
                  function (socketResponse) {
                    tmpObj.refreshData(tmpObj);
                    // listTemproryDistrictCourtCase
                    // tmpObj.refreshData(tmpObj)
                  }
                );
              }
            } else {
              this.partydata = [];
              this.Records = 0;
              this.partymsg = res2.message;

              this.loading = false;
              // setTimeout(() => {
              //   this.msg = ''
              // }, 1000);
            }
          },
          (err) => {
            this.loading = false;
            // this.columns = {}
            this.partydata = [];
            this.Records = 0;
            this.partymsg = `Facing Some Issue From Server Side Please Try after some time`;
            console.log("throw", err);

            // ${this.userwantsavedata()
          }
        );
    } else {
      // this.msg =  ""
      this.snackBar.open("Please provide all required information.", "CLOSE", {
        duration: 3000,
        horizontalPosition: "center",
      });
    }
  }

  casedetailbyid(data) {
    data['serverType']=this.serverType; 
    data['state_code']=this.stateCode;
    this.dialog.open(HighcourtdetailComponent, {
      height: "100%",
      width: "62%",
      autoFocus: false,
      data: data,
    });

    // this.router.navigate(["/app/cases-management/districtcourtdetail"], { queryParams: { case_id: data } });
  }

  savecase() {
    console.log(this.courtcaselist, "this.columns");
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.courtcaselist.fourm = "highcourt";
      console.log(this.courtcaselist, "this.columns 9999");
      this.socketObject.emit(
        environment.socketEvents.createHighCourtCase,
        this.courtcaselist,
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.snackBar.open("Successfully Created Court Case.", "Okay", {
              duration: 4000,
            });

            // tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackBar.open(socketResponse.message, "Okay", {
              duration: 4000,
            });
          }
        }
      );
    }
  }

  savesparty(data) {
    data._value.forEach((element) => {
      let obj = {
        state_code: element.state_code,
        court_code: element.court_code,
        case_no_year: element.case_no_year,
        case_cino: element.case_cino,
      };
      console.log(obj, "check respon");
      this.highcourt
        .postecourt("ws/eHighCourtCase", obj)
        .subscribe((res: any) => {
          if (res) {
            this.highcourt
              .postecourt("ws/eAutoUpdateHighCourtCase", obj)
              .subscribe((res2: any) => {
                console.log(res2, "check response");
                if (res2.status) {
                  let detaildata;
                  if (res2.autoUpdateHighCourtCase) {
                    detaildata = res2.autoUpdateHighCourtCase[0];
                  }

                  this.savepartdetail(detaildata);
                }
              });
          }
        });
    });
    // this.shoess.nativeElement.value
  }

  pageChangeHandler(eventDetails) {
    if (eventDetails && eventDetails.pageSize) {
      this.pageSize = eventDetails.pageSize;
      this.pageIndex = eventDetails.pageIndex;
    }
    this.refreshData(this);
  }

  savepartdetail(data) {
    console.log(data, "data of party");
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(
        environment.socketEvents.createHighCourtCase,
        data,
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.snackBar.open("Successfully Created Court Case.", "Okay", {
              duration: 4000,
            });
            // tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackBar.open(socketResponse.message, "Okay", {
              duration: 4000,
            });
          }
        }
      );
    }
  }

  back() {
    window.history.back();
  }
  intilizeform() {
    this.mydelhihighcourtForm = new FormGroup({
      search_by: new FormControl("", Validators.required),

      case_type: new FormControl("", Validators.required),
      case_no: new FormControl("", Validators.required),
      case_year: new FormControl("", Validators.required),
      diary_no: new FormControl("", Validators.required),
      party_name: new FormControl("", Validators.required),
      advocate_name: new FormControl("", Validators.required),
    });
  }

  onTabChanged(data) {
    console.log("highcourt tab change ");
    // this.intilizeform()
    // this.delhihighcourtcaselist=[]
  }

  gotodetaldelhihighcourtpage(data) {
    data['case_detail']=data.case_detail
    data['caseName']=`${data.petitioner} vs. ${data.respondent}`
    data['serverType']=this.serverType;
    data['state_code']=this.stateCode;
    data['case_year']=data.case_year;
    this.dialog.open(DelhihighcourtComponent, {
      height: "600px",
      width: "850px",
      autoFocus: false,
      data: data,
    });
  }

  // function to get data of caseTypeCategory of delhi high court
  caseTypeOfDelhiHighCourtData;
  caseTypeOfDelhiHighCourt() {
    this.delhihighcourtservice.getData("caseTypeCategory").subscribe(
      (res) => {
        if (res["status"] == true) {
          this.caseTypeOfDelhiHighCourtData = res["partyName"];
          console.log(this.caseTypeOfDelhiHighCourtData);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  // function for bombay high court start here
  showMsgOfCaseNumber: String;
  showMsgOfPartyName: String;
  showMsgOfAdvocateName: String;

  courtBenchCategory() {
    this.bombayhighcourtservice.getData("courtBenchCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.courtBenchCategoryData = res.courtBenchCategory;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  singleCourtBenchData = [];
  caseSideCategoryData2;
  caseSideCategoryData3;
  getCourtBenchIdAndFindCaseSide(data) {
    this.singleCourtBenchData = [];
    this.singleCourtBenchData.push(data.value);
    let obj = {
      court_bench_category_id: this.singleCourtBenchData[0].id,
    };
    console.log(obj);
    this.bombayhighcourtservice.postData("caseSideCategory", obj).subscribe(
      (res) => {
        if (res.status == true) {
          this.caseSideCategoryData = res.caseSideCategory;
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getCourtBenchIdAndFindCaseSide2(data) {
    this.singleCourtBenchData = [];
    this.singleCourtBenchData.push(data.value);
    let obj = {
      court_bench_category_id: this.singleCourtBenchData[0].id,
    };
    console.log(obj);
    this.bombayhighcourtservice.postData("caseSideCategory", obj).subscribe(
      (res) => {
        if (res.status == true) {
          this.caseSideCategoryData2 = res.caseSideCategory;
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  getCourtBenchIdAndFindCaseSide3(data) {
    this.singleCourtBenchData = [];
    this.singleCourtBenchData.push(data.value);
    let obj = {
      court_bench_category_id: this.singleCourtBenchData[0].id,
    };
    console.log(obj);
    this.bombayhighcourtservice.postData("caseSideCategory", obj).subscribe(
      (res) => {
        if (res.status == true) {
          this.caseSideCategoryData3 = res.caseSideCategory;
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  caseCategoryData;
  caseCategory() {
    this.bombayhighcourtservice.getData("caseCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.caseCategoryData = res.caseCategory;
          console.log(this.caseCategoryData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  caseTypeCategoryData;
  getSingleJuridictionData = [];
  getJuridictionIdAndFindCaseType(data) {
    this.getSingleJuridictionData = [];
    this.getSingleJuridictionData.push(data.value);

    let obj = {
      case_side_category_id: this.getSingleJuridictionData[0].id,
    };
    console.log(obj);
    this.bombayhighcourtservice.postData("caseTypeCategory", obj).subscribe(
      (res) => {
        console.log(res);
        if (res.status == true) {
          this.caseTypeCategoryData = res.caseTypeCategory;
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  caseDetailsData = [];
  caseDetails() {
    this.caseDetailsData = [];
    if (
      this.singleCourtBenchData.length > 0 &&
      this.getSingleJuridictionData.length > 0
    ) {
      this.caseNumberFormBombay.value.court_code =
        this.singleCourtBenchData[0].court_bench_id;
      this.caseNumberFormBombay.value.case_side =
        this.getSingleJuridictionData[0].case_side_id;
    }

    if (this.caseNumberFormBombay.valid) {
      this.loading = true;
      console.log(this.caseNumberFormBombay.value);
      this.bombayhighcourtservice
        .postData("caseDetails", this.caseNumberFormBombay.value)
        .subscribe(
          (res) => {
            console.log(res);
            if (res.status == true) {
              this.caseDetailsData.push(...res.caseDetails);

              console.log(this.caseDetailsData);
              this.showMsgOfCaseNumber = "";
              this.loading = false;
            } else {
              this.caseDetailsData = [];
              this.loading = false;
              this.showMsgOfCaseNumber = res.message;
            }
          },
          (err) => {
            console.log(err);
            this.loading = false;
            this.showMsgOfCaseNumber =
              "Facing Some Server Issue Please Try After Some Time";
          }
        );
    }
  }

  partyTypeCategoryData;
  partyTypeCategory() {
    this.bombayhighcourtservice.getData("partyTypeCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.partyTypeCategoryData = res.partyTypeCategory;
          console.log(this.partyTypeCategoryData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  totalCaseListPartyData = [];
  pageIndexOfpartylist = 0;
  totalpagesOfpartylist;
  caseListPartyData;
  caseListOfParty() {
    this.totalCaseListPartyData = [];
    if (
      this.singleCourtBenchData.length > 0 &&
      this.getSingleJuridictionData.length > 0
    ) {
      this.partyNameFormBombay.value.court_code =
        this.singleCourtBenchData[0].court_bench_id;
      this.partyNameFormBombay.value.case_side =
        this.getSingleJuridictionData[0].case_side_id;
    }

    console.log(this.partyNameFormBombay.value);

    if (this.partyNameFormBombay.valid) {
      this.loading = true;
      this.bombayhighcourtservice
        .postData("caseList", this.partyNameFormBombay.value)
        .subscribe(
          (res) => {
            console.log(res);
            if (res.status == true) {
              this.totalCaseListPartyData.push(...res.caseList);

              this.totalpagesOfpartylist = Math.ceil(
                this.totalCaseListPartyData.length / this.defaultRecords
              );

              this.caseListPartyData = this.totalCaseListPartyData.slice(
                0,
                this.defaultRecords
              );
              this.showMsgOfPartyName = "";
              this.loading = false;
            } else {
              this.totalCaseListPartyData = [];
              this.loading = false;
              this.showMsgOfPartyName = res.message;
            }
          },
          (err) => {
            console.log(err);
            this.loading = false;
            this.showMsgOfPartyName =
              "Facing Some Server Issue Please Try After Some Time";
          }
        );
    }
  }

  totalCaseListadvocateData = [];
  pageIndexOfadvocateData = 0;
  totalpagesOfadvocateData;
  caseListAdvocateData;
  caseListOfAdvocate() {
    this.totalCaseListadvocateData = [];
    if (
      this.singleCourtBenchData.length > 0 &&
      this.getSingleJuridictionData.length > 0
    ) {
      this.advocateFormBombay.value.court_code =
        this.singleCourtBenchData[0].court_bench_id;
      this.advocateFormBombay.value.case_side =
        this.getSingleJuridictionData[0].case_side_id;
    }

    console.log(this.advocateFormBombay.value);

    if (this.advocateFormBombay.valid) {
      this.loading = true;
      this.bombayhighcourtservice
        .postData("caseList", this.advocateFormBombay.value)
        .subscribe(
          (res) => {
            if (res.status == true) {
              this.totalCaseListadvocateData.push(...res.caseList);

              this.totalpagesOfadvocateData = Math.ceil(
                this.totalCaseListadvocateData.length / this.defaultRecords
              );

              this.caseListAdvocateData = this.totalCaseListadvocateData.slice(
                0,
                this.defaultRecords
              );
              console.log(this.caseListAdvocateData);
              this.showMsgOfAdvocateName = "";
              this.loading = false;
            } else {
              this.totalCaseListadvocateData = [];
              this.loading = false;
              this.showMsgOfAdvocateName = res.message;
            }
          },
          (err) => {
            console.log(err);
            this.loading = false;
            this.showMsgOfAdvocateName =
              "Facing Some Server Issue Please Try After Some Time";
          }
        );
    }
  }

  onPageChange2($event, val) {
    if (val == "partyname") {
      this.totalpagesOfpartylist = Math.ceil(
        this.totalCaseListPartyData.length / $event.pageSize
      );

      this.pageIndexOfpartylist = $event.pageIndex;
      this.caseListPartyData = this.totalCaseListPartyData.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    } else if (val == "advocatename") {
      this.totalpagesOfadvocateData = Math.ceil(
        this.totalCaseListadvocateData.length / $event.pageSize
      );

      this.pageIndexOfadvocateData = $event.pageIndex;
      this.caseListAdvocateData = this.totalCaseListadvocateData.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    }
  }

  getdetailofbombayhighcourt(data, val) {
    data['case_detail']=data.reg_no1
    data['caseName']=`${data.petitioner} vs. ${data.respondent}`
    data["court_bench_name"] = this.singleCourtBenchData[0]["court_bench_name"];
    data['serverType']=this.serverType;
    data['state_code']=this.stateCode;
    data['code']=val;
    console.log(data,1329)

    this.dialog.open(BombayhighcourtdetailComponent, {
      height: "700px",
      width: "900px",
      autoFocus: false,
      data: data,
    });
    // this.router.navigate(["/app/cases-management/districtcourtdetail"], { queryParams: { case_id: data } });
  }

  // allahabad high court start here
  courtBenchOfAllahabadhighcourt() {
    this.allahabadhighcourtservice.getData("benchCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.courtBenchOfAllahabadhighcourtData = res.benchCategory;
          console.log(this.courtBenchOfAllahabadhighcourtData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  caseTypeOfAllahabadhighcourt() {
    this.allahabadhighcourtservice.getData("caseTypeCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.caseTypeOfAllahabadhighcourtData = res.caseTypeCategory;
          console.log(this.caseTypeOfAllahabadhighcourtData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  partyTypeOfAllahabadhighcourt() {
    this.allahabadhighcourtservice.getData("partyTypeCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.partyTypeOfAllahabadhighcourtData = res.partyTypeCategory;
          console.log(this.partyTypeOfAllahabadhighcourtData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  districtOfAllahabadhighcourt() {
    this.allahabadhighcourtservice.getData("districtNameCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.districtOfAllahabadhighcourtData = res.districtNameCategory;
          console.log(this.districtOfAllahabadhighcourtData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  caseListOfCaseNo;
  totalcaseListOfCaseNo=[]
  msgOfNodataFoundByCaseNo='';
  serverErrMsgByCaseNo=''
  pageIndexOfcaseno = 0;
  totalpagesOfcaseno;
  caseListOfAllahabadhighcourtByCaseNumber(){
    this.msgOfNodataFoundByCaseNo='';
    this.serverErrMsgByCaseNo='';
    this.totalcaseListOfCaseNo=[]
    if(this.caseNumberFormAllahabad.valid){
      this.loading = true;
      console.log(this.caseNumberFormAllahabad.value)
  
      this.allahabadhighcourtservice.postData("caseList", this.caseNumberFormAllahabad.value).subscribe(
        (res) => {
          if (res.status == true) {
            this.totalcaseListOfCaseNo.push(...res.caseList);

            this.totalpagesOfcaseno = Math.ceil(
              this.totalcaseListOfCaseNo.length / this.defaultRecords
            );

            this.caseListOfCaseNo = this.totalcaseListOfCaseNo.slice(0,this.defaultRecords);
            console.log(this.caseListOfCaseNo)

            this.loading = false;
          } else {
            this.loading = false;
            this.msgOfNodataFoundByCaseNo=res.message
          }
        },
        (err) => {
          this.loading = false;
          this.serverErrMsgByCaseNo = 'Facing Some Server Issue Please Try After Some Time'
        }
      );
    }
  }

  caseListOfPartyType;
  msgOfNodataFoundByPartyName='';
  serverErrMsgByPartyName='';
  totalcaseListOfPartyType=[]
  pageIndexOfpartytype = 0;
  totalpagesOfpartytype;
  caseListOfAllahabadhighcourtByPartyName(){
    this.msgOfNodataFoundByPartyName='';
    this.serverErrMsgByPartyName=''
    this.totalcaseListOfPartyType=[]
    if(this.partyNameFormAllahabad.valid){
      this.loading = true;
      console.log(this.partyNameFormAllahabad.value)
  
      this.allahabadhighcourtservice.postData("caseList", this.partyNameFormAllahabad.value).subscribe(
        (res) => {
          if (res.status == true) {
            this.totalcaseListOfPartyType.push(...res.caseList);
            console.log(this.totalcaseListOfPartyType)

            this.totalpagesOfpartytype = Math.ceil(
              this.totalcaseListOfPartyType.length / this.defaultRecords
            );

            this.caseListOfPartyType = this.totalcaseListOfPartyType.slice(0,this.defaultRecords);

            this.loading = false;
          } else {
            this.loading = false;
            this.msgOfNodataFoundByPartyName=res.message
          }
        },
        (err) => {
          this.loading = false;
          this.serverErrMsgByPartyName = 'Facing Some Server Issue Please Try After Some Time'
        }
      );
    }
  }


  onPageChangeOnAllahabad($event, val) {
    if (val == "partyname") {
      this.totalpagesOfpartytype = Math.ceil(
        this.totalcaseListOfPartyType.length / $event.pageSize
      );

      this.pageIndexOfpartytype = $event.pageIndex;
      this.caseListOfPartyType = this.totalcaseListOfPartyType.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    } else if (val == "casenumber") {
      this.totalpagesOfcaseno = Math.ceil(
        this.totalcaseListOfCaseNo.length / $event.pageSize
      );

      this.pageIndexOfcaseno = $event.pageIndex;
      this.caseListOfCaseNo = this.totalcaseListOfCaseNo.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    }
  }

  getdetailofallahabadhighcourt(data){
    let obj = {
      search_by:data.search_by,
      court_bench:data.court_bench,
      case_cino:data.case_cino,
      case_year:data.case_year
    }
    obj['case_detail']=data.case_detail
    obj['serverType']=this.serverType;
    obj['state_code']=this.stateCode;
    obj['caseName']=`${data.petitioner} vs. ${data.respondent}`
    obj['case_number']=data.case_number
    obj['case_type']=data.input_case_type
    obj['petitioner']=data.petitioner
    obj['respondent']=data.respondent

    this.dialog.open(AllahabadDetailComponent, {
      height: "700px",
      width: "900px",
      autoFocus: false,
      data: obj,
    });
  }
  // allahabad high court end here

  // Himachal Pradesh high court start here
  caseCategoryOfHimachal;
  caseCategoryOfHimachalPradesh(){
    this.himalchalhighcourtservice.getData("caseCategory").subscribe((res)=>{
      if(res.status){
        this.caseCategoryOfHimachal = res.caseCategory;
        this.HimachalHighCourtCaseForm.get('case_category').setValue(this.caseCategoryOfHimachal[0]['case_category_id'])
      }
    },(err)=>{
      console.log(err)
    })
  }
  caseTypeCategoryOfHimachal;
  caseTypeCategoryOfHimachalPradesh(){
    this.himalchalhighcourtservice.getData("caseTypeCategory").subscribe((res)=>{
      if(res.status){
        this.caseTypeCategoryOfHimachal = res.caseTypeCategory;
      }
    },(err)=>{
      console.log(err)
    })
  }
  showmsgcaseDetails=''
  caseDetailsOfHimachal;
  caseDetailsOfHimachalArr=[];
  caseDetailsOfHimachalPradesh(){
    this.caseDetailsOfHimachalArr=[]
    
    if(this.HimachalHighCourtCaseForm.valid){
      this.loading = true;
      this.himalchalhighcourtservice.postData("caseDetails",this.HimachalHighCourtCaseForm.value).subscribe((res)=>{
        if(res.status){
          this.caseDetailsOfHimachalArr.push(res.caseDetails)
          this.caseDetailsOfHimachal = res.caseDetails;
          this.loading = false;
          this.showmsgcaseDetails=''
        }else{
          this.showmsgcaseDetails=res.message;
          this.loading = false;
        }
      },(err)=>{
        this.showmsgcaseDetails=''
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.loading = false;
      })
    }
  }
  partyTypeCategoryHimachal;
  partyTypeCategoryHimachalPradesh(){
    this.himalchalhighcourtservice.getData("partyTypeCategory").subscribe((res)=>{
      if(res.status){
        this.partyTypeCategoryHimachal = res.partyTypeCategory;
        this.HimachalHighCourtPartyForm.get('party_type').setValue(this.partyTypeCategoryHimachal[0]['party_type_id'])
      }
    },(err)=>{
      console.log(err)
    })
  }
  statusCategoryHimachal;
  statusCategoryHimachalPradesh(){
    this.himalchalhighcourtservice.getData("statusCategory").subscribe((res)=>{
      if(res.status){
        this.statusCategoryHimachal = res.statusCategory;
        this.HimachalHighCourtPartyForm.get('case_status').setValue(this.statusCategoryHimachal[0]['case_status_id'])
        this.HimachalHighCourtAdvocateForm.get('case_status').setValue(this.statusCategoryHimachal[0]['case_status_id'])
      }
    },(err)=>{
      console.log(err)
    })
  }

  totalCaseListPartyHimachal = [];
  pageIndexOfpartylistHimachal = 0;
  totalpagesOfpartylistHimachal;
  partyListOfhimachal;
  showmsgcasepartyList=''
  partyListOfHimachal(){
    this.totalCaseListPartyHimachal = [];

    if(this.HimachalHighCourtPartyForm.valid){
      this.loading = true;
      this.himalchalhighcourtservice.postData("caseList",this.HimachalHighCourtPartyForm.value).subscribe((res)=>{
        if(res.status){
          this.totalCaseListPartyHimachal.push(...res.caseList);
          this.totalpagesOfpartylistHimachal = Math.ceil(this.totalCaseListPartyHimachal.length / this.defaultRecords);
          this.partyListOfhimachal = this.totalCaseListPartyHimachal.slice(0,this.defaultRecords);
          this.loading = false;
          this.showmsgcasepartyList=''
        }else{
          this.showmsgcasepartyList=res.message;
          this.loading = false;
        }
      },(err)=>{
        this.showmsgcasepartyList=''
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.loading = false;
      })
    }
  }

  advocateTypeCategoryHimachal;
  advocateTypeCategoryHimachalPradesh(){
    this.himalchalhighcourtservice.getData("advocateTypeCategory").subscribe((res)=>{
      if(res.status){
        this.advocateTypeCategoryHimachal = res.advocateTypeCategory;
        this.HimachalHighCourtAdvocateForm.get('advocate_type').setValue(this.advocateTypeCategoryHimachal[0]['advocate_type_id'])
      }
    },(err)=>{
      console.log(err)
    })
  }

  totalCaseListadvocateHimachal = [];
  pageIndexOfadvocatelistHimachal = 0;
  totalpagesOfadvocatelistHimachal;
  advocateListOfhimachal;
  showmsgcaseadvocate=''
  advocateListOfHimachal(){
    this.totalCaseListadvocateHimachal = [];

    if(this.HimachalHighCourtAdvocateForm.valid){
      this.loading = true;
      this.himalchalhighcourtservice.postData("caseList",this.HimachalHighCourtAdvocateForm.value).subscribe((res)=>{
        if(res.status){
          console.log(res)

          this.totalCaseListadvocateHimachal.push(...res.caseList);
          this.totalpagesOfadvocatelistHimachal = Math.ceil(this.totalCaseListadvocateHimachal.length / this.defaultRecords);
          this.advocateListOfhimachal = this.totalCaseListadvocateHimachal.slice(0,this.defaultRecords);
          this.loading = false;
          this.showmsgcaseadvocate=''
        }else{
          this.showmsgcaseadvocate=res.message;
          this.loading = false;
        }
      },(err)=>{
        this.showmsgcaseadvocate=''
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.loading = false;
      })
    }
  }

  onPageChangeOnHimachal($event, val){
    if (val == "partyname") {
      this.totalpagesOfpartylistHimachal = Math.ceil(
        this.totalCaseListPartyHimachal.length / $event.pageSize
      );

      this.pageIndexOfpartylistHimachal = $event.pageIndex;
      this.partyListOfhimachal = this.totalCaseListPartyHimachal.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    }
    if (val == "advocatename") {
      this.totalpagesOfadvocatelistHimachal = Math.ceil(
        this.totalCaseListadvocateHimachal.length / $event.pageSize
      );

      this.pageIndexOfadvocatelistHimachal = $event.pageIndex;
      this.advocateListOfhimachal = this.totalCaseListadvocateHimachal.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    }
  }

  gotohimachalhihighcourtpage(data,searchcode) {
    data['case_detail']=data.registration_no
    data['searchcode']=searchcode;
    data['state_code']=this.stateCode;
    data['serverType']=this.serverType;
    this.dialog.open(HimachalDetailComponent, {
      height: "600px",
      width: "850px",
      autoFocus: false,
      data: data,
    });
  }
  // Himachal Pradesh high court end here

  // Punjab high court API Start here
  casetypeofpunjab;
  caseTypeofPunjab(){
    this.punjabservice.getData("caseTypeCategory").subscribe((res)=>{
      if(res.status){
        this.casetypeofpunjab = res.caseTypeCategory;
      }
    },(err)=>{
      console.log(err)
    })
  }
  partyTypepunjab;
  partyTypePunjab(){
    this.punjabservice.getData("searchTypeCategory").subscribe((res)=>{
      if(res.status){
        this.partyTypepunjab = res.searchTypeCategory;
        this.AdvocatePunjabForm.get('search_type').setValue(this.partyTypepunjab[1]['search_type_id'])
        this.PartyTypePunjabForm.get('search_type').setValue(this.partyTypepunjab[1]['search_type_id'])
      }
    },(err)=>{
      console.log(err)
    })
  }
  districtCategorypunjab;
  districtCategoryPunjab(){
    this.punjabservice.getData("districtCategory").subscribe((res)=>{
      if(res.status){
        this.districtCategorypunjab = res.districtCategory;
      }
    },(err)=>{
      console.log(err)
    })
  }
  
  casedetailpunjab;
  casedetailpunjabArr=[];
  pageIndexPunjab = 0;
  totalpagespunjab;
  punCaseInfo;
  casedetailPunjab(){
    this.casedetailpunjabArr=[]
    
    if(this.PunjabCaseNoForm.valid){
      console.log(this.PunjabCaseNoForm.value)
      this.loading = true;
      this.punjabservice.postData("caseList",this.PunjabCaseNoForm.value).subscribe((res)=>{
        if(res.status){
          console.log(res)
          this.casedetailpunjabArr.push(...res.caseList)
          this.totalpagespunjab = Math.ceil(this.casedetailpunjabArr.length / this.defaultRecords);
          this.casedetailpunjab = this.casedetailpunjabArr.slice(0,this.defaultRecords);
          this.loading = false;
          this.punCaseInfo=''
        }else{
          this.punCaseInfo=res.message;
          this.loading = false;
        }
      },(err)=>{
        this.punCaseInfo=''
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.loading = false;
      })
    }
  }
  
  partypunjab;
  partypunjabArr=[];
  pageIndexpartyPunjab = 0;
  totalpagespartypunjab;
  punpartyInfo;
  partytypePunjab(){
    this.partypunjabArr=[]
    if(this.PartyTypePunjabForm.valid){
      console.log(this.PartyTypePunjabForm.value)
      this.loading = true;
      this.punjabservice.postData("caseList",this.PartyTypePunjabForm.value).subscribe((res)=>{
        if(res.status){ 
          this.partypunjabArr.push(...res.caseList)
          this.totalpagespartypunjab = Math.ceil(this.partypunjabArr.length / this.defaultRecords);
          this.partypunjab = this.partypunjabArr.slice(0,this.defaultRecords);
          this.loading = false;
          this.punpartyInfo=''
        }else{
          this.punpartyInfo=res.message;
          this.loading = false;
        }
      },(err)=>{
        this.punpartyInfo=''
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.loading = false;
      })
    }
  }
  advocatepunjab;
  advocatepunjabArr=[];
  pageIndexadvocatePunjab = 0;
  totalpagesadvocatepunjab;
  punadvocateInfo;
  advocatetypePunjab(){
    this.advocatepunjabArr=[]
    console.log(this.AdvocatePunjabForm.value)
    if(this.AdvocatePunjabForm.valid){
      this.loading = true;
      this.punjabservice.postData("caseList",this.AdvocatePunjabForm.value).subscribe((res)=>{
        console.log(res)
        if(res.status){ 
          this.advocatepunjabArr.push(...res.caseList)
          this.totalpagesadvocatepunjab = Math.ceil(this.advocatepunjabArr.length / this.defaultRecords);
          this.advocatepunjab = this.advocatepunjabArr.slice(0,this.defaultRecords);
          this.loading = false;
          this.punadvocateInfo=''
        }else{
          this.punadvocateInfo=res.message;
          this.loading = false;
        }
      },(err)=>{
        this.punadvocateInfo=''
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        this.loading = false;
      })
    }
  }

  onPageChangeOnPunjab($event, val) {
    if (val == 1) {
      this.totalpagespunjab = Math.ceil(
        this.casedetailpunjabArr.length / $event.pageSize
      );

      this.pageIndexPunjab = $event.pageIndex;
      this.casedetailpunjab = this.casedetailpunjabArr.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    } else if (val == 2) {
      this.totalpagespartypunjab = Math.ceil(
        this.partypunjabArr.length / $event.pageSize
      );

      this.pageIndexpartyPunjab = $event.pageIndex;
      this.partypunjab = this.partypunjabArr.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    }else if (val == 3) {
      this.totalpagesadvocatepunjab = Math.ceil(
        this.advocatepunjabArr.length / $event.pageSize
      );

      this.pageIndexadvocatePunjab = $event.pageIndex;
      this.advocatepunjab = this.advocatepunjabArr.slice(
        $event.pageIndex * $event.pageSize,
        $event.pageIndex * $event.pageSize + $event.pageSize
      );
    }
  }

  gotoPunjabDetailPage(data) {
    console.log(data)
    data['state_code']=this.stateCode;
    data['serverType']=this.serverType;
    this.dialog.open(PunjabDetailComponent, {
      height: "600px",
      width: "850px",
      autoFocus: false,
      data: data,
    });
  }
   // Punjab high court API End here
}
