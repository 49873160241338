class Petitioner {
  name: string;
  address: string;
  country: string;
  state: string;
  city: string;
  zip: string;
}

class Responder {
  name: string;
  address: string;
  country: string;
  state: string;
  city: string;
  zip: string;
}

class ContactPerson {
  name: string;
  designation: string;
  contactNumber: string;
  email: string;
  contactPersonParty: string;
}

class Court {
  courtName: string;
  courtType: string;
  state: string;
  district: string;
  courtNumber: string;
  judgeName: string;
  department: string;
}

class PeitionDocs {
  description: string;
  docs: any[];
}
 class suportingdocuments{
  documentname: string;
  docs: any[];
}

class SupportingDocs {
  description: string;
  docs: any[];
}

export class User {
  _id: number;
  petition_docs: PeitionDocs;
  supporting_docs: SupportingDocs;
  suporting_documents:suportingdocuments;
  ordersDocs: any;
  miscellaneousDocs: any;

  courtCaseType: string;
  billingType: string;
  legalNotice: any;
  division: any;
  regions: string;
  project: string;
  caseName: string;
  casetitle: string;
  name_of_arbitrator: string;
  appointing_authority: string;
  arbitration_tribunal: string;
  seat_of_arbitration: string;
  appointing_date: string;
  arbitratorDescription: string;
  support_documentname: string;

  court_bench:string
  court_bench_id:Number
  case_category:String
  court_code:String
  case_side:String
  location:String
  commission_type:String


  disputePropertyDescription: string;
  caseDescription: string;
  fillingDate: string;
  caseNumber: string;
  case_name: string;
  caseType: string;
  caseYear: string;
  caseStage: string;
  petitioner: Petitioner[];
  petitioners: string;
  respondants: string;
  petitioner_versus_respondent:string;
  responder: Responder[];
  contactPerson: ContactPerson[];
  advocateAssigned: any;
  retainerAssigned:any;
  subAdvocateAssigned: string;
  inHouseAdvocate: any[];
  courtDetails: Court;
  lastHearingDate: string;
  nextHearingDate: string;
  case_status: string;
  remark: string;
  caseNature: any;
  firNumber: string;
  purpose: string;
  arbitratorAssigned: string;
  arbitratorNumber: string;
  totalAmount: string;
  department: string;
  hasLegalNotice: boolean;
  internalSharing: any[];
  externalSharing: any[];
  createdBy: any;
  updatedBy: any;
  next_hearing_date:string;
  // lastHearingDate:string;

  static regionsProjectSiteModified = {};

  constructor(customer) {
    this._id = customer._id;
    this.petition_docs = customer.petition_docs;
    this.supporting_docs = customer.supporting_docs;
    this.suporting_documents = customer.suporting_documents
    this.courtCaseType = customer.courtCaseType;
    this.billingType = customer.billingType;
    this.legalNotice = customer.legalNotice;
    this.division = customer.division;
    this.project = customer.project;
    this.regions = customer.regions;
    this.totalAmount = customer.totalAmount;
    this.department = customer.department;
    this.caseName = customer.caseName;
    this.case_name= customer.case_name;
    this.petitioner_versus_respondent =customer.petitioner_versus_respondent;
    this.disputePropertyDescription = customer.disputePropertyDescription;
    this.caseDescription = customer.caseDescription;
    this.fillingDate = customer.fillingDate;
    this.caseNumber = customer.caseNumber;
    this.caseType = customer.caseType;
    this.caseYear = customer.caseYear;
    this.casetitle= customer.casetitle;
    this.support_documentname = customer.support_documentname
    this.name_of_arbitrator= customer.name_of_arbitrator;
    this.appointing_authority= customer.appointing_authority;
    this.arbitration_tribunal= customer.arbitration_tribunal;
    this.seat_of_arbitration=customer.seat_of_arbitration;
    this.appointing_date= customer.appointing_date;
    this.arbitratorDescription= customer.arbitratorDescription;
    this.petitioner = customer.petitioner;
    this.petitioners = customer.petitioners;
    this.respondants = customer.respondants;
    this.responder = customer.responder;
    this.ordersDocs = customer.ordersDocs;
    this.miscellaneousDocs = customer.miscellaneousDocs;
    this.contactPerson = customer.contactPerson;
    this.advocateAssigned = customer.advocateAssigned;
    this.retainerAssigned = customer.retainerAssigned;
    this.subAdvocateAssigned = customer.subAdvocateAssigned;
    this.inHouseAdvocate = customer.inHouseAdvocate;
    this.courtDetails = customer.courtDetails;
    this.lastHearingDate = customer.lastHearingDate;
    this.nextHearingDate = customer.nextHearingDate;
    this.next_hearing_date = customer.next_hearing_date;
    this.caseStage = customer.caseStage;
    this.hasLegalNotice = customer.hasLegalNotice;
    this.internalSharing = customer.internalSharing;
    this.externalSharing = customer.externalSharing;

    this.court_bench = customer.court_bench;
    this.case_category = customer.case_category;
    this.court_code = customer.court_code;
    this.case_side = customer.case_side;
    this.location = customer.location;
    this.commission_type = customer.commission_type;

    this.caseNature = customer.caseNature;
    this.firNumber = customer.firNumber;
    this.purpose = customer.purpose;
    this.arbitratorAssigned = customer.arbitratorAssigned;
    this.arbitratorNumber = customer.arbitratorNumber;

    this.case_status = customer.case_status;
    this.remark = customer.remark;

    this.createdBy = customer.createdBy;
    this.updatedBy = customer.updatedBy;
  }

  get petitionerName() {
    let petitioner = "N/A";
    if (this.petitioner && this.petitioner.length > 0) {
      petitioner = this.petitioner[0].name;
    }
    return petitioner;
  }

  get courtCaseTypeAlias() {
    let caseType = "N/A";
    if (this.courtCaseType == "Cases Filed Against") {
      caseType = "Cases Filed Against";
    } else {
      caseType = "Cases Filed By";
    }
    return caseType;
  }

  get responderName() {
    let responder = "N/A";
    if (this.responder && this.responder.length > 0) {
      responder = this.responder[0].name;
    }
    return responder;
  }

  get divisionName() {
    if (this.division) {
      return this.division.name;
    }
    return "N/A";
  }

  get projectName() {
    if (this.project && User.regionsProjectSiteModified && User.regionsProjectSiteModified[this.project]) {
      return User.regionsProjectSiteModified[this.project].ProjectName;
    }
    return "N/A";
  }

  get regionName() {
    if (this.regions && User.regionsProjectSiteModified && User.regionsProjectSiteModified[this.regions]) {
      return User.regionsProjectSiteModified[this.regions].RegionName;
    }
    return "N/A";
  }

  get advocateName() {
    if (this.advocateAssigned) {
      if (this.advocateAssigned.userType == 'firm') {
        return this.advocateAssigned.firmName;
      } else {
        return this.advocateAssigned.firstName + " " + this.advocateAssigned.lastName;
      }
    }
    return "N/A";
  }

  get inHouseUserName() {
    let names: any = "N/A";
    if (this.inHouseAdvocate && typeof this.inHouseAdvocate == "object" && this.inHouseAdvocate.length > 0) {
      names = [];
      for (let i = 0; i < this.inHouseAdvocate.length; i++) {
        names.push(this.inHouseAdvocate[i].firstName + " " + this.inHouseAdvocate[i].lastName);
      }
      names = names.join(",");
    }
    return names;
  }

  get modifiedCaseName() {
    // console.log("this.petitioners 9999")
    // console.log(this.petitioner,"this.petitioners")
    let finalStr = "";
    if (this.petitioner && this.responder) {
      let petitionerStr = "";
      for (let i = 0; i < this.petitioner.length; i++) {
        if (i == this.petitioner.length - 1) {
          petitionerStr += this.petitioner[i].name;
        } else {
          petitionerStr += this.petitioner[i].name + ",";
        }
      }

      let respondentStr = "";
      for (let i = 0; i < this.responder.length; i++) {
        if (i == this.responder.length - 1) {
          respondentStr += this.responder[i].name;
        } else {
          respondentStr += this.responder[i].name + ",";
        }
      }

      finalStr = petitionerStr + " Vs " + respondentStr;
    }

    if(this.petitioners && this.respondants){
      finalStr = this.petitioners + " Vs " + this.respondants;
    }

    if(this.case_name){
      finalStr = this.case_name
    }

    if(this.petitioner_versus_respondent){
      finalStr = this.petitioner_versus_respondent
    }
    return finalStr;
  }

  get _createdBy() {
    let name = "";

    if (this.createdBy) {
      name = this.createdBy.firstName + " " + this.createdBy.lastName;
    }

    return name;
  }

}
