import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { BombayhighcourtService } from '../highcourt/bombayhighcourt.service';

@Component({
  selector: 'vex-bombayhighcourtdetail',
  templateUrl: './bombayhighcourtdetail.component.html',
  styleUrls: ['./bombayhighcourtdetail.component.scss']
})
export class BombayhighcourtdetailComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BombayhighcourtdetailComponent>,@Inject(MAT_DIALOG_DATA) public data: any,private bombayhighcourtservice: BombayhighcourtService,private bombayhighcourtService: BombayhighcourtService,private snackBar: MatSnackBar,private socketService: SocketService,private commonServices: CommonService) { 
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  private socketObject;
  MatSprnnerOfSection=false

  caseDetail;
  handleMatSprnner = true;
  showData=false;
  showNotFoundMsg=false;
  showServerErrOfofficeInformation = ''
  showServerErrOfmiscInformation=''
  showServerErrOfobjections=''
  showServerErrOfcasePaperIndex=''
  showServerErrOfconnectedMatters=''
  showServerErrOfapplicationCases=''
  showServerErrOflistingDates=''

  ngOnInit() {
    this.caseDetail = this.data;

    if(this.data.code===1){
      if(this.data.call=='mycases'){
        this.bombayhighcourtservice.postData("caseDetails", this.data).subscribe((res)=>{
          this.caseDetail=res.caseDetails[0]
            
          this.handleMatSprnner = false
          this.showData = true
        });
      }else{
        this.handleMatSprnner = false
        this.showData = true
      }
    }else if(this.data.code === 2){
      let obj = {
        court_code:this.caseDetail.court_code,
        case_side:this.caseDetail.case_side,
        case_category:this.caseDetail.case_category,
        case_type:this.caseDetail.case_type,
        case_no:this.caseDetail.case_no,
        case_year:this.caseDetail.case_year
      }
      this.bombayhighcourtService.postData('caseDetails',obj).subscribe((res)=>{
        if(res.status==true){
          console.log(res.caseDetails)
          this.caseDetail = res.caseDetails[0]

          this.handleMatSprnner = false
          this.showData = true

        }else{
          this.handleMatSprnner = false;
          this.showNotFoundMsg = true;
        }
      },(err)=>{
        this.dialogRef.close();
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
      })
    }
  }


  countofoffice=0
  officeInformationData=[];
  officeInformation(){
    if(this.countofoffice==0){
      this.MatSprnnerOfSection=true
      let obj = {
        id: this.caseDetail.id
      }
      
      this.bombayhighcourtService.postData('officeInformation',obj).subscribe((res)=>{
        if(res.status==true){
          this.officeInformationData.push(...res.officeInformation)
          this.MatSprnnerOfSection=false
          this.countofoffice=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofoffice=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofoffice=1
        this.showServerErrOfofficeInformation = 'Facing Some Server Issue in officeInformation Please Try After Some Time'
      })
    }
  }

  countofmisc=0
  subject_category:any
  miscInformationData=[];
  miscInformation(){
    if(this.countofmisc==0){
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.caseDetail.id
      }
  
      this.bombayhighcourtService.postData('miscInformation',obj).subscribe((res)=>{
        if(res.status==true){
          this.miscInformationData.push(...res.miscInformation)

          if(this.miscInformationData[0].subject_category != null || this.miscInformationData[0].subject_category != ''){
            this.subject_category=this.miscInformationData[0].subject_category 
          }
          this.MatSprnnerOfSection=false
          this.countofmisc=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofmisc=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofmisc=1
        this.showServerErrOfmiscInformation = 'Facing Some Server Issue in miscInformation Please Try After Some Time'
      })
    }
  }

  countofobjection=0
  objectionsData=[];
  objections(){
    if(this.countofobjection==0){
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.caseDetail.id
      }
  
      this.bombayhighcourtService.postData('objections',obj).subscribe((res)=>{
        if(res.status==true){
          this.objectionsData.push(...res.objections)
          this.MatSprnnerOfSection=false
          this.countofobjection=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofobjection=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofobjection=1
        this.showServerErrOfobjections = 'Facing Some Server Issue in objections Please Try After Some Time'
      })
    }
  }

  countofpaper=0
  casePaperIndexData=[];
  casePaperIndex(){
    if(this.countofpaper==0){
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.caseDetail.id
      }
  
      this.bombayhighcourtService.postData('casePaperIndex',obj).subscribe((res)=>{
        if(res.status==true){
          this.casePaperIndexData.push(...res.casePaperIndex)
          this.MatSprnnerOfSection=false
          this.countofpaper=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofpaper=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofpaper=1
        this.showServerErrOfcasePaperIndex = 'Facing Some Server Issue in casePaperIndex Please Try After Some Time'
      })
    }
  }

  countofConnected=0
  connectedMattersData=[];
  connectedMatters(){
    if(this.countofConnected==0){
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.caseDetail.id
      }
  
      this.bombayhighcourtService.postData('connectedMatters',obj).subscribe((res)=>{
        if(res.status==true){
          this.connectedMattersData.push(...res.connectedMatters)
          this.MatSprnnerOfSection=false
          this.countofConnected=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofConnected=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofConnected=1
        this.showServerErrOfconnectedMatters = 'Facing Some Server Issue in connectedMatters Please Try After Some Time'
      })
    }
  }

  countofapplication=0
  applicationCasesData=[];
  applicationCases(){
    if(this.countofapplication==0){
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.caseDetail.id
      }
  
      this.bombayhighcourtService.postData('applicationCases',obj).subscribe((res)=>{
        if(res.status==true){
          this.applicationCasesData.push(...res.applicationCases)
          this.MatSprnnerOfSection=false
          this.countofapplication=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofapplication=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofapplication=1
        this.showServerErrOfapplicationCases = 'Facing Some Server Issue in applicationCases Please Try After Some Time'
      })
    }
  }

  countoflisting=0
  listingDatesData=[];
  listingDates(){
    if(this.countoflisting==0){
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.caseDetail.id
      }
  
      this.bombayhighcourtService.postData('listingDates',obj).subscribe((res)=>{
        if(res.status==true){
          this.listingDatesData.push(...res.listingDates)
          this.MatSprnnerOfSection=false
          this.countoflisting=1
        }else{
          this.MatSprnnerOfSection=false
          this.countoflisting=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countoflisting=1
        this.showServerErrOflistingDates = 'Facing Some Server Issue in listingDates Please Try After Some Time'
      })
    }
  }

  countofLowerCourt=0
  LowerCourt(){
    if(this.countofLowerCourt==0){
      this.MatSprnnerOfSection=true
      this.lowerCourtDetails()
      this.appealCourtDetails()
    }
  }

  lowerCourtDetailsData=[];
  lowerCourtDetails(){
    let obj = {
      court_case_id: this.caseDetail.id
    }

    this.bombayhighcourtService.postData('lowerCourtDetails',obj).subscribe((res)=>{
      if(res.status==true){
        this.lowerCourtDetailsData.push(...res.lowerCourtDetails)
      }
    })
  }

  appealCourtDetailsData=[];
  appealCourtDetails(){
    let obj = {
      court_case_id: this.caseDetail.id
    }

    this.bombayhighcourtService.postData('appealCourtDetails',obj).subscribe((res)=>{
      if(res.status==true){
        this.appealCourtDetailsData.push(...res.appealCourtDetails) 
        this.MatSprnnerOfSection=false
        this.countofLowerCourt=1
      }else{
        this.MatSprnnerOfSection=false
        this.countofLowerCourt=1
      }
    },(err)=>{
      this.MatSprnnerOfSection=false
      this.countofLowerCourt=1
    })
  }


  caesDetailData={}
  saveData(){

    if(this.data.code===1){
      console.log(this.data)
      this.caesDetailData['serverType']=this.data.serverType;
      this.caesDetailData['state_code']=this.data.state_code;
      this.caesDetailData['caseName']=this.data.caseName;
      this.caesDetailData['next_hearing_date']=this.data.next_date;

      this.caesDetailData['case_category']=this.caseDetail.case_category;
      this.caesDetailData['case_side']=this.caseDetail.case_side;
      this.caesDetailData['case_type']=this.caseDetail.case_type;
      this.caesDetailData['case_year']=this.caseDetail.case_year;
      this.caesDetailData['case_no']=this.caseDetail.case_no;
      this.caesDetailData['court_code']=this.caseDetail.court_code;
      this.caesDetailData['court_case_id']=this.caseDetail.id;
      this.caesDetailData['code']=this.data.code;
      this.caesDetailData['cnr_number']=this.caseDetail.cnr_no;
      this.caesDetailData['petitioner']=this.caseDetail.petitioner;
      this.caesDetailData['respondent']=this.caseDetail.respondent;
  
  
      if (this.socketService.isSocketConnected()) {
        console.log('socket is connected now...')
        let tmpObj = this;
        this.socketObject.emit(environment.socketEvents.createbombayhighcourt,  this.caesDetailData , function (socketResponse) {
          console.log(socketResponse)
          if (socketResponse.status == 'OK') {
            tmpObj.snackBar.open('Successfully Created high court cases', 'Okay', {
              duration: 4000
            });
            // tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackBar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
            // this.dialogRef.close();
          }
        });
        // this.dialogRef.close();
      }
    }else if(this.data.code===2){
      this.caesDetailData['serverType']=this.data.serverType;
      this.caesDetailData['state_code']=this.data.state_code;
      this.caesDetailData['caseName']=this.data.caseName;

      this.caesDetailData['case_category']=this.caseDetail.case_category;
      this.caesDetailData['case_side']=this.caseDetail.case_side;
      this.caesDetailData['case_type']=this.caseDetail.case_type;
      this.caesDetailData['case_year']=this.caseDetail.case_year;
      this.caesDetailData['case_no']=this.caseDetail.case_no;
      this.caesDetailData['court_code']=this.caseDetail.court_code;
      this.caesDetailData['court_case_id']=this.caseDetail.id;
      this.caesDetailData['code']=this.data.code;
      this.caesDetailData['cnr_number']=this.caseDetail.cnr_no;

      console.log(this.caesDetailData)
  
      if (this.socketService.isSocketConnected()) {
        console.log('socket is connected now...')
        let tmpObj = this;
        this.socketObject.emit(environment.socketEvents.createbombayhighcourt,  this.caesDetailData , function (socketResponse) {
          console.log(socketResponse)
          if (socketResponse.status == 'OK') {
            tmpObj.snackBar.open('Successfully Created high court cases', 'Okay', {
              duration: 4000
            });
            // tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackBar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
            // this.dialogRef.close();
          }
        });
        // this.dialogRef.close();
      }
    }

  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
    if(this.step==1){
      this.officeInformation()
    }
    if(this.step==2){
      this.miscInformation()
    }
    if(this.step==3){
      this.objections()
    }
    if(this.step==4){
      this.casePaperIndex()
    }
    if(this.step==5){
      this.connectedMatters()
    }
    if(this.step==6){
      this.applicationCases()
    }
    if(this.step==7){
      this.listingDates()
    }
    if(this.step==8){
      this.LowerCourt()
    }
  }

  prevStep() {
    this.step--;
    if(this.step==1){
      this.officeInformation()
    }
    if(this.step==2){
      this.miscInformation()
    }
    if(this.step==3){
      this.objections()
    }
    if(this.step==4){
      this.casePaperIndex()
    }
    if(this.step==5){
      this.connectedMatters()
    }
    if(this.step==6){
      this.applicationCases()
    }
    if(this.step==7){
      this.listingDates()
    }
    if(this.step==8){
      this.LowerCourt()
    }
  }

  goToLink(url: string){
    if(url!=null){
      window.open(url, "_blank");
    }else{
      alert('No data Found')
    }
  }
}
