import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CommonService } from '../services/core/common.service';

@Injectable({
  providedIn: 'root'
})
export class BeforeLoginGuard implements CanActivate, CanLoad {

  constructor(private commonService: CommonService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Observable((subscriber: Subscriber<boolean>) => {
      if (this.commonService.getAccessToken()) {
        this.commonService.initLoggedInUserDetails().then(() => {
          let userDetails = this.commonService.getLoggedInUserDetails();
          if (userDetails && userDetails.role._id == environment.inwardNoticeRoleId) {
            this.router.navigate(["/app/inward-notices-dropbox"]);
          } else {
            this.router.navigate(["/app/dashboard"]);
          }
        }).catch((err) => {
          this.router.navigate(["/app/dashboard"]);
        });
      } else {
        subscriber.next(true);
      }
    });
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
