import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CasesRoutingModule } from './cases-routing.module';
import { CasesComponent } from './cases.component';
import { PageLayoutModule } from '../../../@vex/components/page-layout/page-layout.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { BreadcrumbsModule } from '../../../@vex/components/breadcrumbs/breadcrumbs.module';
import { CasesCreateUpdateModule } from './cases-create-update/cases-create-update.module';
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { IconModule } from '@visurel/iconify-angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ContainerModule } from '../../../@vex/directives/container/container.module';
import { MatSelectModule } from '@angular/material/select';
import { ColorFadeModule } from '../../../@vex/pipes/color/color-fade.module';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { CourtCasesComponent } from './court-cases/court-cases.component';
import { CaseDiscussionComponent } from './case-discussion/case-discussion.component';
import { SecondaryToolbarModule } from 'src/@vex/components/secondary-toolbar/secondary-toolbar.module';
import { MatFormFieldModule, MatInputModule, MatRadioModule } from '@angular/material';
import { CaseStatusChangeModule } from './case-status-change/case-status-change.module';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { HearingsCreateUpdateModule } from '../hearings/hearings-create-update/hearings-create-update.module';
import { CourtCaseInternalSharingModule } from './court-case-internal-sharing/court-case-internal-sharing.module';
import { MutualCaseInternalSharingModule } from './mutual-case-internal-sharing/mutual-case-internal-sharing.module';
import { CourtCaseSharesListModule } from './court-case-shares-list/court-case-shares-list.module';
import { MutualCaseSharesListModule } from './mutual-case-shares-list/mutual-case-shares-list.module';
import { ExternalSharingModule } from './external-sharing/external-sharing.module';
import { SharesListExternalUsersModule } from './shares-list-external-users/shares-list-external-users.module';
import { MatCardModule } from '@angular/material/card';
import { AppointmentsCreateUpdateModule } from '../appointments/appointments-create-update/appointments-create-update.module';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { CourtcasedirectcreateComponent } from './courtcasedirectcreate/courtcasedirectcreate.component';
import {MatListModule} from '@angular/material/list';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import { AddcourtcaseComponent } from './addcourtcase/addcourtcase.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { AutocourtcaseDetalComponent } from './autocourtcase-detal/autocourtcase-detal.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MycasesComponent } from './mycases/mycases.component';
import { DistrictcourtComponent } from './districtcourt/districtcourt.component';
import { SupremecourtComponent } from './supremecourt/supremecourt.component';
import { DistrictcourtdetailComponent } from './districtcourt/districtcourtdetail/districtcourtdetail.component';
import {MatDialogModule} from '@angular/material/dialog';
import { ArbitrationComponent } from './arbitration/arbitration.component';
import { ArbitrationCreateUpdateComponent } from './arbitration/arbitration-create-update/arbitration-create-update.component';
import { ArbitrationDetailComponent } from './arbitration/arbitration-detail/arbitration-detail.component';
import { ArbitrationCreteComponent } from './arbitration-crete/arbitration-crete.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { ArbitrationhearingComponent } from './arbitrationhearing/arbitrationhearing.component';
import { ArbitrationAppointmentComponent } from './arbitration/arbitration-appointment/arbitration-appointment.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HighcourtComponent } from './highcourt/highcourt.component';
import { HighcourtdetailComponent } from './highcourt/highcourtdetail/highcourtdetail.component';
import { ArbitrationsharingComponent } from './arbitrationsharing/arbitrationsharing.component';
import { ArbitrationmeetingstatusComponent } from './arbitrationmeetingstatus/arbitrationmeetingstatus.component';
import { UpdatearbitrationhearingComponent } from './updatearbitrationhearing/updatearbitrationhearing.component';
import { HistorydetailComponent } from './districtcourt/historydetail/historydetail.component';
import { HighcourthistroydetailComponent } from './highcourt/highcourthistroydetail/highcourthistroydetail.component';
import { DelhihighcourtComponent } from './delhihighcourt/delhihighcourt.component';
import { ConsumerCourtComponent } from './consumer-court/consumer-court.component';
import { TribunalCourtComponent } from './tribunal-court/tribunal-court.component';
import { TribunalCourtDetailComponent } from './tribunal-court-detail/tribunal-court-detail.component';
import { TribunalCaseHistoryDetailComponent } from './tribunal-case-history-detail/tribunal-case-history-detail.component';
import { ConsumerCourtDetailComponent } from './consumer-court-detail/consumer-court-detail.component';
import { BombayhighcourtdetailComponent } from './bombayhighcourtdetail/bombayhighcourtdetail.component';
import { AllahabadDetailComponent } from './allahabad-detail/allahabad-detail.component';
import { HimachalDetailComponent } from './himachal-detail/himachal-detail.component';
import { PunjabDetailComponent } from './punjab-detail/punjab-detail.component';
import { CopypetiondetailComponent } from './copypetiondetail/copypetiondetail.component';
import { NcltCourtComponent } from './nclt-court/nclt-court.component';
import { NcltCourtDetailComponent } from './nclt-court-detail/nclt-court-detail.component';
// import { AdvocateFirmCreateUpdateComponent } from '../advocate-firm/advocate-firm-create-update/advocate-firm-create-update.component';
// import {ClipboardModule} from '@angular/cdk/clipboard';
@NgModule({
  declarations: [CasesComponent, CourtCasesComponent, CaseDiscussionComponent, CaseDetailsComponent, CourtcasedirectcreateComponent, AddcourtcaseComponent, AutocourtcaseDetalComponent, MycasesComponent, DistrictcourtComponent, SupremecourtComponent, DistrictcourtdetailComponent,ConsumerCourtComponent,TribunalCourtComponent, ArbitrationComponent, ArbitrationCreateUpdateComponent, ArbitrationDetailComponent, ArbitrationCreteComponent, ArbitrationhearingComponent, ArbitrationAppointmentComponent, HighcourtComponent, HighcourtdetailComponent, ArbitrationsharingComponent, ArbitrationmeetingstatusComponent, UpdatearbitrationhearingComponent, HistorydetailComponent, HighcourthistroydetailComponent, DelhihighcourtComponent, TribunalCourtDetailComponent, TribunalCaseHistoryDetailComponent, ConsumerCourtDetailComponent, BombayhighcourtdetailComponent, AllahabadDetailComponent, HimachalDetailComponent, PunjabDetailComponent, CopypetiondetailComponent, NcltCourtComponent, NcltCourtDetailComponent],
  imports: [
    CommonModule,
    CasesRoutingModule,
    PageLayoutModule,
    FlexLayoutModule,
    BreadcrumbsModule,
    CasesCreateUpdateModule,
    CaseStatusChangeModule,
    MatPaginatorModule,
    MatTableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    // BrowserAnimationsModule,
    MatSortModule,
    MatCardModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatMenuModule,
    MatListModule,
    IconModule,
    FormsModule,
    MatTooltipModule,
    MatSnackBarModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    ContainerModule,
    SecondaryToolbarModule,
    MatSelectModule,
    MatExpansionModule,
    ColorFadeModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatInputModule,
    HearingsCreateUpdateModule,
    CourtCaseInternalSharingModule,
    MutualCaseInternalSharingModule,
    CourtCaseSharesListModule,
    MutualCaseSharesListModule,
    ExternalSharingModule,
    SharesListExternalUsersModule,
    AppointmentsCreateUpdateModule,
    MatRadioModule,
    MatTabsModule,
    MatDialogModule,
    // ClipboardModule,
    NgxDaterangepickerMd.forRoot()
  ],
  entryComponents: [ArbitrationhearingComponent,ArbitrationAppointmentComponent,HighcourtdetailComponent,ArbitrationsharingComponent,ArbitrationmeetingstatusComponent,UpdatearbitrationhearingComponent,HistorydetailComponent,HighcourthistroydetailComponent,TribunalCourtDetailComponent,NcltCourtDetailComponent,TribunalCaseHistoryDetailComponent,ConsumerCourtDetailComponent,BombayhighcourtdetailComponent,AllahabadDetailComponent,HimachalDetailComponent,PunjabDetailComponent,CopypetiondetailComponent],
})
export class CasesModule {
}