import { Injectable } from '@angular/core';
import { HttpClient, HttpParams,HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common'
import { Router, NavigationEnd } from '@angular/router'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DelhihighcourtService {

  public eCurl = environment.socketConfiguration.msdelhihighcourtURL;

  constructor(private router: Router, private location: Location,public http: HttpClient) { }

  postecourt(urlT: any, data: any) {
    const updateUrl = this.eCurl + urlT;
  
   
    return this.http.post(updateUrl, data);
  }
  getcasetype() {
    const updateUrl = "http://192.168.0.161:9006/microservice/hci/delhi/api/asgi/caseTypeCategory";
  
   
    return this.http.get(updateUrl);
  }
  
  postyear(urlT: any){
    const updateUrl = this.eCurl + urlT;
  let obj={
  
  }
   
    return this.http.post(updateUrl,obj);
  
  }

  getData(url:any){
    return this.http.get(this.eCurl + url);
  }
}
