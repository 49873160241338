import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icNotes from '@iconify/icons-ic/twotone-notes';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';

@Component({
  selector: 'vex-external-sharing',
  templateUrl: './external-sharing.component.html',
  styleUrls: ['./external-sharing.component.scss']
})
export class ExternalSharingComponent implements OnInit {

  private socketObject;
  selectedFileName;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  public sectionNames = {
    "petition_docs": "Petition Docs",
    "supporting_docs": "Supporting Docs",

    //Mutual Cases
    "uploadDocuments": "Upload Docs"
  };

  public frmDocDescription: FormGroup;
  @ViewChild("docDescriptionDialogRef", { static: false }) docDescriptionDialogTempRef: TemplateRef<any>;
  public descDescriptionDiaglogRef: MatDialogRef<any>;
  private selectedDocIndex = -1;

  public permissionLevels = environment.permissionLevelsExternalUser;

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icEdit = icEdit;
  icNotes = icNotes;

  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icDeleteForever = icDeleteForever;

  usersList = [];

  alreadySharedUsers = {};

  constructor(@Inject(MAT_DIALOG_DATA) public details: any,
    private dialogRef: MatDialogRef<ExternalSharingComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _layoutService: LayoutService,
    private commonServices: CommonService,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if (this.details && this.details.userPermissions) {
      this.mode = 'update';
    } else {
      this.details.userPermissions = {};
    }

    this.form = this.fb.group({
      _id: this.details.userPermissions._id || '',
      name: [this.details.userPermissions.name ? this.details.userPermissions.name : "", Validators.required],
      permissionLevel: [this.details.userPermissions.permissionLevel || '', Validators.required],
      remarks: [this.details.userPermissions.remarks || '', Validators.required],
      email: [this.details.userPermissions.email || '', [Validators.email]],
      mobile: [this.details.userPermissions.mobile || ''],
      filePermissions: [this.details.userPermissions.filePermissions || []],
    });

    if (this.isCreateMode()) {

      //Initializing file permission array with the avaialable file in case
      if (this.details.courtCase) {
        this.initFilesForCourtCase(this.details.courtCase);
      } else if (this.details.mutualCase) {
        this.initFilesForMutualCase(this.details.mutualCase);
      }

    } else {
    }
    this.form.get("filePermissions").setValue(this.form.value.filePermissions);

  }

  //Initializing file permission array with the avaialable file in court case
  initFilesForCourtCase(courtCaseDetails) {
    if (courtCaseDetails) {
      if (this.isCreateMode()) {
        this.form.value.filePermissions = [];
        if (courtCaseDetails) {
          if (courtCaseDetails.petition_docs && courtCaseDetails.petition_docs.docs) {
            for (let i = 0; i < courtCaseDetails.petition_docs.docs.length; i++) {
              this.form.value.filePermissions.push({
                name: courtCaseDetails.petition_docs.docs[i].name,
                filePath: courtCaseDetails.petition_docs.docs[i].filePath,
                sectionKey: "petition_docs",
                permissions: {
                  view: false,
                  modify: false
                }
              });
            }
          }
          if (courtCaseDetails.supporting_docs && courtCaseDetails.supporting_docs.docs) {
            for (let i = 0; i < courtCaseDetails.supporting_docs.docs.length; i++) {
              this.form.value.filePermissions.push({
                name: courtCaseDetails.supporting_docs.docs[i].name,
                filePath: courtCaseDetails.supporting_docs.docs[i].filePath,
                sectionKey: "supporting_docs",
                permissions: {
                  view: false,
                  modify: false
                }
              });
            }
          }
        }
      }
    }
  }

  //Initializing file permission array with the avaialable file in mutual case
  initFilesForMutualCase(mutualCaseDetails) {
    if (mutualCaseDetails) {
      if (this.isCreateMode()) {
        this.form.value.filePermissions = [];
        if (mutualCaseDetails.uploadDocuments && mutualCaseDetails.uploadDocuments.docs) {
          for (let i = 0; i < mutualCaseDetails.uploadDocuments.docs.length; i++) {
            this.form.value.filePermissions.push({
              name: mutualCaseDetails.uploadDocuments.docs[i].name,
              filePath: mutualCaseDetails.uploadDocuments.docs[i].filePath,
              sectionKey: "uploadDocuments",
              permissions: {
                view: false,
                modify: false
              }
            });
          }
        }
      }
    }
  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    } else {
      if ( !this.form.value.email && !this.form.value.mobile ) {
        this.snackBar.open('Please provide either Email or Mobile, or both.', 'CLOSE', {
          duration: 4000,
          horizontalPosition: 'center'
        });
        return;
      }
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  getObjectKeys(object) {
    let keys = [];

    if (object) {
      keys = Object.keys(object);
    }

    return keys;
  }
}
