import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { VexRoutes } from '../../../@vex/interfaces/vex-route.interface';
import { CasesComponent } from './cases.component';
import { CourtCasesComponent } from './court-cases/court-cases.component';
import { CaseDiscussionComponent } from './case-discussion/case-discussion.component';
import { CaseDetailsComponent } from './case-details/case-details.component';
import { CourtcasedirectcreateComponent } from './courtcasedirectcreate/courtcasedirectcreate.component';
import { AddcourtcaseComponent } from './addcourtcase/addcourtcase.component';
import { AutocourtcaseDetalComponent } from './autocourtcase-detal/autocourtcase-detal.component';
import { MycasesComponent } from './mycases/mycases.component';
import { DistrictcourtComponent } from './districtcourt/districtcourt.component';
import { SupremecourtComponent } from './supremecourt/supremecourt.component';

import { DistrictcourtdetailComponent } from './districtcourt/districtcourtdetail/districtcourtdetail.component';
import { ArbitrationComponent } from './arbitration/arbitration.component';
import { ArbitrationDetailComponent } from './arbitration/arbitration-detail/arbitration-detail.component';
import { HighcourtComponent } from './highcourt/highcourt.component';
import { DelhihighcourtComponent } from './delhihighcourt/delhihighcourt.component';
import { ConsumerCourtComponent } from './consumer-court/consumer-court.component';
import { TribunalCourtComponent } from './tribunal-court/tribunal-court.component';
import { NcltCourtComponent } from './nclt-court/nclt-court.component';


const routes: VexRoutes = [
  {
    path: 'mutual-cases',
    component: CasesComponent,
    data: {
      toolbarShadowEnabled: true,
      key: "mutual_cases"
    }
  },
  {
    path: 'court-cases',
    component: CourtCasesComponent,
    data: {
      toolbarShadowEnabled: true,
      key: "court_cases"
    }
  },
  // {
  //   path: 'case-discussion/:id',
  //   component: CaseDiscussionComponent,
  //   data: {
  //     toolbarShadowEnabled: true
  //   }
  // },
  {
    path: 'case-details/:caseId/:caseType',
    component: CaseDetailsComponent,
    data: {
      toolbarShadowEnabled: true
    },
    
  },
  {
    path: 'add-case',
    component: CourtcasedirectcreateComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'add-supreme',
    component: AddcourtcaseComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'case-detail',
    component: AutocourtcaseDetalComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'mycases',
    component: MycasesComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'district',
    component: DistrictcourtComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'highcourt',
    component: HighcourtComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'supremecourt',
    component: SupremecourtComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'consumerforum',
    component: ConsumerCourtComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'Tribunals',
    component: TribunalCourtComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
{
  path:'NCLT',
  component:NcltCourtComponent,
  data:{
    toolbarShadowEnabled:true
  }
},
  {
    path: 'delhihighcourt',
    component: DelhihighcourtComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'districtcourtdetail',
    component: DistrictcourtdetailComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'Arbitration',
    component: ArbitrationComponent,
    data: {
      toolbarShadowEnabled: true
    }
  },
  {
    path: 'arbitration/:caseId',
    component: ArbitrationDetailComponent,
    data: {
      toolbarShadowEnabled: true
    }
  }
  // ArbitrationDetailComponent
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CasesRoutingModule {
}
