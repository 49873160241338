import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef,MatDialog } from '@angular/material/dialog';
// import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { LegalNotice } from '../interfaces/legal-notice.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icadd from '@iconify/icons-ic/add';
import icPageView from '@iconify/icons-ic/twotone-pageview';
import icEmail from '@iconify/icons-ic/twotone-email';
import icPlus from '@iconify/icons-ic/twotone-plus';
import icDescription from '@iconify/icons-ic/twotone-description';
import icDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icCalendarViewDay from '@iconify/icons-ic/twotone-calendar-view-day';
import { MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import * as numberToWordConverter from 'number-to-words';
import * as converter from 'number-to-words';
import { isNumber } from 'util';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { CustomValidators } from 'src/app/custom_validator/validator';
import { AdvocateFirmCreateUpdateComponent } from '../../advocate-firm/advocate-firm-create-update/advocate-firm-create-update.component';
import { appModulesList } from 'src/environments/appModulesList';
import { User } from '../../advocate-firm/interfaces/users.model';

@Component({
  selector: 'vex-legal-notices-create-update',
  templateUrl: './legal-notices-create-update.component.html',
  styleUrls: ['./legal-notices-create-update.component.scss'],
  providers: [CustomValidators],
})
export class LegalNoticesCreateUpdateComponent implements OnInit {
  public moduleadvocateDetails = appModulesList.advocateFirmManagement;
  selectedNoticeFileName;
  selectedOtherFileName;
  private socketObject;

  public allProjectSitesList = [];
  public projectSitesList = [];
  public regionsList = [];
  public caseTypeList = [];
  public advocatesList = [];
  public retainerList = [];
  public inHouseUsersList = [];

  public countriesList = [];
  public statesList = [
    []
  ];
  public citiesList = [
    []
  ];
  public invalid: boolean = false

  public selectedTabIndex: number = 0;
  private totalTabGroups: number = 5;

  public billingTypeOptions = [
    { name: "Monthly", value: "Monthly" },
    { name: "Lumpsum", value: "Lumpsum" },
    { name: "Hearing", value: "Hearing" },
  ];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icadd= icadd;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icPageView = icPageView;
  icPlus = icPlus;
  icDeleteForever = icDeleteForever;
  icDescription = icDescription;

  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icCalendarViewDay = icCalendarViewDay;

  public daysDropdown: number[];

  public partyTypeOptions = [
    { name: "Noticee", value: "Noticee" },
    { name: "Sender", value: "Noticer" },
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public legalNoticeDetails: any,
    private dialogRef: MatDialogRef<LegalNoticesCreateUpdateComponent>,
    private fb: FormBuilder,
    private commonServices: CommonService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _layoutService: LayoutService,
    private customValidators: CustomValidators,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    this.daysDropdown = [];
    for (let i = 0; i <= 30; i++) {
      this.daysDropdown.push(i);
    }
  }

  ngOnInit() {
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listCaseAbbreviation, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.caseTypeList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.getApiRegionsProjects, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.regionsList = socketResponse.regionsList;
          tmpObj.allProjectSitesList = socketResponse.projectSiteList;

          if (tmpObj.isUpdateMode()) {
            tmpObj.regionsChangeHandler();
          }
        }
      });

      this.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.advocatesList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listofretanership, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.retainerList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listUsersRoleUser, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.inHouseUsersList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.getCountries, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.countriesList = socketResponse.data;
        }
      });
    }

    if (this.legalNoticeDetails && this.legalNoticeDetails._id) {
      this.mode = 'update';
    } else {
      if (!this.legalNoticeDetails) {
        this.legalNoticeDetails = {} as LegalNotice;
      }
    }

    this.form = this.fb.group({
      _id: [this.legalNoticeDetails._id || null],
      petitioner: this.fb.array([]),
      legal_notice_docs: this.fb.group({
        notice_docs: [this.legalNoticeDetails.legal_notice_docs ? this.legalNoticeDetails.legal_notice_docs.notice_docs : null, Validators.required],
        other_docs: [this.legalNoticeDetails.legal_notice_docs ? this.legalNoticeDetails.legal_notice_docs.other_docs : null],
        reply_to_notice: [this.legalNoticeDetails.legal_notice_docs ? this.legalNoticeDetails.legal_notice_docs.reply_to_notice : null],
        reply_description: [this.legalNoticeDetails.legal_notice_docs ? this.legalNoticeDetails.legal_notice_docs.reply_description : null],
      }),
      case_details: this.fb.group({
        topic: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.topic : null, Validators.required],
        dispute_amount: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.dispute_amount : null],
        regions: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.regions : null],
        project_site: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.project_site : null],
        property_name: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.property_name : null, this.customValidators.addressValidator],
       
        name_of_hod: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.name_of_hod : null],
        // case_type: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.case_type : null],
        notice_date: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.notice_date : null, Validators.required],
        notice_arrival_date: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.notice_arrival_date : null, Validators.required],
        time_limit_days: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.time_limit_days : null, Validators.required],
        time_limit: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.time_limit : null],
        advocate: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.advocate : null],
        retainer: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.retainer : null],
        in_house_advocate: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.in_house_advocate : null],
        billingType: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.billingType : null],
        totalAmount: [this.legalNoticeDetails.case_details ? this.legalNoticeDetails.case_details.totalAmount : null],
      }),
    });

    if (this.legalNoticeDetails && this.legalNoticeDetails.petitioner && this.legalNoticeDetails.petitioner.length > 0) {
      for (let i = 0; i < this.legalNoticeDetails.petitioner.length; i++) {
        this.addPetitionerArrUnit(this.legalNoticeDetails.petitioner[i]);
        this.countryChangeHandler(i);
        this.stateChangeHandler(i);
      }
    } else {
      this.addPetitionerArrUnit({});
    }

    if (this.isUpdateMode()) {
      this.assignAdvocateChangeHandler();
    }
  }


  save() {
    this.trimFileObjs();
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      let invalidFields = this.commonServices.findInvalidControls(this.form);
      let isFormInvalid = true;
      if (invalidFields && invalidFields.length == 1) {
        if (invalidFields[0] == "legal_notice_docs"
          && this.form.value.legal_notice_docs
          && this.form.value.legal_notice_docs.notice_docs
          && this.form.value.legal_notice_docs.notice_docs.length > 0) {
          isFormInvalid = false;
        }
      }
      if (this.form.value.legal_notice_docs.notice_docs == null) {

        this.invalid = true;
      }


      if (isFormInvalid) {
        this.snackBar.open('Please provide all required information.', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        return;
      }
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  async noticeFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedNoticeFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.legal_notice_docs.notice_docs = this.form.value.legal_notice_docs.notice_docs ? this.form.value.legal_notice_docs.notice_docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadNoticeDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
              this.invalid = false;
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadNoticeDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadNoticeDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadNoticeDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadNoticeDocumentEnd, (filePath) => {
          tmpObj.form.value.legal_notice_docs.notice_docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;

          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("legal_notice_docs.notice_docs").setValue(tmpObj.form.value.legal_notice_docs.notice_docs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  addadvocate(){

    if (environment.roleDetails) {
      let subModulesKey = [];
      if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleadvocateDetails.key]) {
        subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleadvocateDetails.key].subModules);
      }

      if ((environment.roleDetails.name == 'super_admin' && environment.roleDetails.is_custom_role == false) || (subModulesKey.indexOf(this.moduleadvocateDetails.subModules.create) != -1)) {
        this.dialog.open(AdvocateFirmCreateUpdateComponent, {
          disableClose: true
        }).afterClosed().subscribe((user: User) => {
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.createAdvocateFirm, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
                    if (socketResponse.status == 'OK') {
                      tmpObj.advocatesList = socketResponse.data;
            console.log(tmpObj.advocatesList,"tmpObj.advocatesList")
                      // if (tmpObj.isUpdateMode()) {
                      //   // tmpObj.advocateChangeHandler();
                      // }
                    }
                  })
                  // tmpObj.snackbar.open('Successfully Created Advocate/Firm.', 'Okay', {
                  //   duration: 4000
                  // });
                  // tmpObj.refreshData(tmpObj);
                } else {
                  // tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  //   duration: 4000
                  // });
                }
              });
            }
          }
        });
      } else {
        // this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
        //   duration: 4000
        // });
      }
    }

  }

  async otherFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.legal_notice_docs.other_docs = this.form.value.legal_notice_docs.other_docs ? this.form.value.legal_notice_docs.other_docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadOtherDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadOtherDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadOtherDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadOtherDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadOtherDocumentEnd, (filePath) => {
          tmpObj.form.value.legal_notice_docs.other_docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;

          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("legal_notice_docs.other_docs").setValue(tmpObj.form.value.legal_notice_docs.other_docs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async replyToNoticeFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.legal_notice_docs.reply_to_notice = this.form.value.legal_notice_docs.reply_to_notice ? this.form.value.legal_notice_docs.reply_to_notice : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadReplyDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadReplyDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadReplyDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadReplyDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadReplyDocumentEnd, (filePath) => {
          tmpObj.form.value.legal_notice_docs.reply_to_notice.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;

          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("legal_notice_docs.reply_to_notice").setValue(tmpObj.form.value.legal_notice_docs.reply_to_notice);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  nextTab() {
    if (this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex += 1;
    }
  }

  previousTab() {
    if (this.selectedTabIndex > 0 && this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex -= 1;
    }
  }

  tabChanged(tabEvents: MatTabChangeEvent) {
    this.selectedTabIndex = tabEvents.index;
  }

  getAdvocateName(advocateFirmDetails) {
    let name = "N/A";
    if (advocateFirmDetails) {
      if (advocateFirmDetails.userType == "firm") {
        name = advocateFirmDetails.firmName;
      } else {
        name = advocateFirmDetails.firstName + " " + advocateFirmDetails.lastName;
      }
    }
    return name;
  }

  // add new petitioner
  public addPetitionerArrUnit(petitionerDetails) {
    const control = <FormArray>this.form.controls.petitioner;
    control.push(this.getPetitionerArrayUnit(petitionerDetails));
  }

  // remove petitioner
  public removePetitionerArrUnit(i: number) {
    const control = <FormArray>this.form.controls.petitioner;
    control.removeAt(i);
  }

  public getPetitionerArrayUnit(petitionerDetails) {
    return this.fb.group({
      name: [petitionerDetails.name || '', [Validators.required, this.customValidators.nameValidator]],
      partyType: [petitionerDetails.partyType || '', Validators.required],
      address: [petitionerDetails.address || '', Validators.required],
      country: [petitionerDetails.country || '', Validators.required],
      state: [petitionerDetails.state || '', Validators.required],
      city: [petitionerDetails.city || '', Validators.required],
      zip: [petitionerDetails.zip || '', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.customValidators.zipValidator]],
    });
  }

  public removeLegalNoticeDoc(legalNoticeDocIndex) {
    if (this.form.value.legal_notice_docs.notice_docs && legalNoticeDocIndex >= 0 && confirm("Do you really want to delete this?")) {
      this.form.value.legal_notice_docs.notice_docs.splice(legalNoticeDocIndex, 1);
    }
  }

  public removeOtherDoc(otherDocIndex) {
    if (this.form.value.legal_notice_docs.other_docs && otherDocIndex >= 0 && confirm("Do you really want to delete this?")) {
      this.form.value.legal_notice_docs.other_docs.splice(otherDocIndex, 1);
    }
  }

  public removeReplyToNoticeDoc(otherDocIndex) {
    if (this.form.value.legal_notice_docs.reply_to_notice && otherDocIndex >= 0 && confirm("Do you really want to delete this?")) {
      this.form.value.legal_notice_docs.reply_to_notice.splice(otherDocIndex, 1);
    }
  }

  downloadFile(docDetails) {
    if (docDetails) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      if (docDetails.fileObj) {
        link.setAttribute('href', URL.createObjectURL(docDetails.fileObj));
      } else {
        let accessToken = this.commonServices.getAccessToken();
        link.setAttribute('href', environment.serviceConfiguration.url + docDetails.filePath.substring(1) + "?access_token=" + accessToken);
      }

      link.setAttribute('download', docDetails.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  trimFileObjs() {
    if (this.form.value.legal_notice_docs.reply_to_notice && this.form.value.legal_notice_docs.reply_to_notice.length > 0) {
      for (let i = 0; i < this.form.value.legal_notice_docs.reply_to_notice.length; i++) {
        if (this.form.value.legal_notice_docs.reply_to_notice[i].fileObj) {
          delete this.form.value.legal_notice_docs.reply_to_notice[i]['fileObj'];
        }
      }
    }

    if (this.form.value.legal_notice_docs.other_docs && this.form.value.legal_notice_docs.other_docs.length > 0) {
      for (let i = 0; i < this.form.value.legal_notice_docs.other_docs.length; i++) {
        if (this.form.value.legal_notice_docs.other_docs[i].fileObj) {
          delete this.form.value.legal_notice_docs.other_docs[i]['fileObj'];
        }
      }
    }

    if (this.form.value.legal_notice_docs.notice_docs && this.form.value.legal_notice_docs.notice_docs.length > 0) {
      for (let i = 0; i < this.form.value.legal_notice_docs.notice_docs.length; i++) {
        if (this.form.value.legal_notice_docs.notice_docs[i].fileObj) {
          delete this.form.value.legal_notice_docs.notice_docs[i]['fileObj'];
        }
      }
    }
  }

  regionsChangeHandler() {
    if (this.form.value.case_details && this.form.value.case_details.regions) {
      this.projectSitesList = [];
      this.projectSitesList = this.projectSitesList.concat(this.allProjectSitesList[this.form.value.case_details.regions])
    }
  }

  countryChangeHandler(index) {
    if (this.form.value.petitioner[index].country) {
      let tmpObj = this;
      this.citiesList[index] = [];
      this.socketObject.emit(environment.socketEvents.getStates, {
        country: this.form.value.petitioner[index].country
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.statesList[index] = socketResponse.data;
        }
      });
    }
  }

  stateChangeHandler(index) {
    if (this.form.value.petitioner[index].state) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getCities, {
        state: this.form.value.petitioner[index].state
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.citiesList[index] = socketResponse.data;
        }
      });
    }
  }

  assignAdvocateChangeHandler() {
    if (this.form.value.case_details && this.form.value.case_details.advocate) {
      this.form.get("case_details.billingType").setValidators([Validators.required]);
      this.form.get("case_details.billingType").updateValueAndValidity();
    } else {
      this.form.get("case_details.billingType").clearValidators();
      this.form.get("case_details.billingType").updateValueAndValidity();
    }
  }

  getWordStringFor(number: number) {
    let numWords = "N/A";

    if (isNumber(number)) {
      numWords = converter.toWords(number);
    }

    return numWords;
  }

  previewPdfFile(docDetails) {
    if (docDetails) {
      if (docDetails.type == "application/pdf") {
        let filePath: string = docDetails.filePath;
        filePath = filePath.replace("./", environment.serviceConfiguration.url + "/viewPDF/");
        let accessToken = this.commonServices.getAccessToken();
        window.open(filePath + "?access_token=" + accessToken,
          'Legal App PDF View',
          'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=' + screen.availWidth + ',height=' + screen.availHeight);
      } else {
        this.snackBar.open("Oops! You can't preview this file.", "Okay", {
          duration: 2000
        });
      }
    }
  }

  inhouseChangeHandler(){
    console.log(this.form.value.case_details.in_house_advocate)
  }
}
