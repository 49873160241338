import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { aioTableLabels } from '../../../../static-data/aio-table-data';
import icPrint from '@iconify/icons-ic/twotone-print';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { HearingsCreateUpdateComponent } from '../../hearings/hearings-create-update/hearings-create-update.component';
import { AppointmentsCreateUpdateComponent } from '../../appointments/appointments-create-update/appointments-create-update.component';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { FormControl } from '@angular/forms';
import theme from '../../../../@vex/utils/tailwindcss';
import { Router, ActivatedRoute } from '@angular/router';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { appModulesList } from 'src/environments/appModulesList';
import icAdd from '@iconify/icons-ic/twotone-add';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icremove from '@iconify/icons-ic/twotone-remove';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { CourtCasesCreateUpdateComponent } from '../court-cases-create-update/court-cases-create-update.component';
import { CasesCreateUpdateComponent } from '../cases-create-update/cases-create-update.component';
import { ViewportScroller } from '@angular/common';
import { first } from 'rxjs/operators';
import { User } from '../interfaces/court-cases.model';
import { TribunalService } from '../tribunal-court/tribunal.service';

import { Thickness } from "@syncfusion/ej2-charts";
import { TribunalCaseHistoryDetailComponent } from "../tribunal-case-history-detail/tribunal-case-history-detail.component";
import { HighcourtService } from '../highcourt/highcourt.service';
import { DelhihighcourtService } from '../highcourt/delhihighcourt.service';
import { BombayhighcourtService } from '../highcourt/bombayhighcourt.service';
import { AllahabadhighcourtService } from '../highcourt/allahabadhighcourt.service';
import { DistrictService } from '../districtcourt/district.service';
import { ConsumerCourtService } from '../consumer-court/consumer-court.service';
import { CourtcaseService } from '../addcourtcase/courtcase.service';
import { HistorydetailComponent } from '../districtcourt/historydetail/historydetail.component';
import { HimachalhighcourtService } from '../highcourt/himachalhighcourt.service';
import { NcltCourtService } from '../nclt-court/nclt-court.service';

@Component({
  selector: 'vex-case-details',
  templateUrl: './case-details.component.html',
  styleUrls: ['./case-details.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]
})
export class CaseDetailsComponent implements OnInit, AfterViewInit, OnDestroy, AfterContentInit {

  public moduleDetails = appModulesList.courtCaseManagement;
  layoutCtrl = new FormControl('fullwidth');
  private socketObject;

  permissionLevels = environment.permissionLevels;

  labels = aioTableLabels;

  theme = theme;

  icAdd = icAdd;
  icremove = icremove;
  icEdit = icEdit;
  icPrint = icPrint;

  public caseId: string;
  public caseType: string;

  public caseDetails;

  public appointments = [];
  public hearings = [];
  public linkedDoc = [];

  casedetails: any;
  iadetail=[]
  casestatusdetails=[]
  casepetionordetails: any;
  caseresponedentdetails: any;
  caseactdetails=[];
  casehistorydetails=[];
  caseordersdetails=[];

  caseConversionInfo=[];
  caseMainMatterInfo=[];
  caseSubMatterInfo=[];
  caseLinkedInfo=[];
  caseObjectionInfo=[];
  caseDocumentInfo=[];
  caseFIRInfo=[];
  caseCategoryInfo=[];
  caseActsInfo=[];
  courtcaselist: any;

  public citiesStatesCountriesList = {
    cities: {},
    countries: {},
    states: {}
  };

  public courtsAndCourtTypeAlias = {
    courtsAlias: {},
    courtTypesAlias: {}
  };

  public regionsProjectSiteModified = {};

  public loggedInUserDetails;
  public sharingDetails;
  public viewportScroller: ViewportScroller;

  private crumbDetail = {
    name: "Case Managment",
    url: "/app/cases-management/court-cases"
  }
  private crumbDetails = {
    name: "Case Managment",
    url: "/app/cases-management/mutual-cases"
  }

  private fragment: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService,
    private commonServices: CommonService,
    private _layoutService: LayoutService,
    private tribunalService: TribunalService,
    private highcourt: HighcourtService,
    private delhihighcourtservice: DelhihighcourtService,
    private bombayhighcourtservice: BombayhighcourtService,
    private allahabadhighcourtservice: AllahabadhighcourtService,
    private districtcourt: DistrictService,
    private consumerCourtService : ConsumerCourtService,
    private court: CourtcaseService,
    private snackbar: MatSnackBar,
    private himalchalhighcourtservice : HimachalhighcourtService,
  private ncltCourtService:NcltCourtService

    ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    let tmpObj = this;
  
    this.route.paramMap.subscribe((params) => {
    
      tmpObj.caseId = params.get("caseId");
     
      tmpObj.caseType = params.get("caseType");
     
      tmpObj.refreshData(tmpObj);
    });

  }

  initSharingDetails(tmpObj: CaseDetailsComponent) {
  
    tmpObj.loggedInUserDetails = tmpObj.commonServices.getLoggedInUserDetails();
    // console.log(tmpObj.commonServices.getLoggedInUserDetails(),"routr after iiiiiiiiiii")
    // console.log(tmpObj.loggedInUserDetails,"tmpObj.loggedInUserDetails")
    if (tmpObj.caseDetails.internalSharing) {
      for (let i = 0; i < tmpObj.caseDetails.internalSharing.length; i++) {
        if (tmpObj.caseDetails.internalSharing[i]&&tmpObj.caseDetails.internalSharing[i].userId == tmpObj.loggedInUserDetails._id) {
          tmpObj.sharingDetails = tmpObj.caseDetails.internalSharing[i];
          break;
        }
      }
    }
  }


  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });



  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  ngAfterContentInit() {

  }
  getInhouseUsersNames() {
    let names = "";
    if (this.caseDetails && this.caseDetails.inHouseAdvocate) {
      for (let i = 0; i < this.caseDetails.inHouseAdvocate.length; i++) {
        names += this.caseDetails.inHouseAdvocate[i].firstName + " " + this.caseDetails.inHouseAdvocate[i].lastName;
        if (i != this.caseDetails.inHouseAdvocate.length - 1) {
          names += ", ";
        }
      }
    }

    return names;
  }
  refreshData(tmpObj) {
  console.log(tmpObj,"hello user")
    if (tmpObj.socketService.isSocketConnected()) {
    
      if (tmpObj.caseType == environment.constants.courtCase) {
      
        tmpObj.socketObject.emit(environment.socketEvents.getCourtCaseDetails, { _id: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            
            tmpObj.caseDetails = socketResponse.data;
            if(tmpObj.caseDetails.courtDetails.courtType == '8'){
              let obj = {};
              obj["location"] = tmpObj.caseDetails.location;
              obj["filing_no"] = tmpObj.caseDetails.filing_no;
              tmpObj.getCaseDetils(obj);
            }else if(tmpObj.caseDetails.courtDetails.courtType == '2' && tmpObj.caseDetails.courtDetails.courtName=='e-Court Server'){
              let obj = {};

              obj['state_code']=tmpObj.caseDetails.courtDetails.state
              obj['court_code']=tmpObj.caseDetails.courtDetails.benchNumber
              obj['case_no_year']=tmpObj.caseDetails.case_no_year
              obj['case_cino']=tmpObj.caseDetails.case_cino

              tmpObj.getcasedetail(obj)
            }else if(tmpObj.caseDetails.courtDetails.courtType == '2' && tmpObj.caseDetails.courtDetails.courtName == 'High Court Server' && tmpObj.caseDetails.courtDetails.state =='26'){
              let obj = {};

              obj['pno_no']=tmpObj.caseDetails.pno_no

              tmpObj.courtCase(obj)
            }else if(tmpObj.caseDetails.courtDetails.courtType == '2' && tmpObj.caseDetails.courtDetails.courtName == 'High Court Server' && tmpObj.caseDetails.courtDetails.state =='1'){
              let obj = {};

              obj['court_code']=tmpObj.caseDetails.court_code
              obj['case_side']=tmpObj.caseDetails.case_side
              obj['case_category']=tmpObj.caseDetails.case_category
              obj['case_type']=tmpObj.caseDetails.case_type
              obj['case_no']=tmpObj.caseDetails.case_no
              obj['case_year']=tmpObj.caseDetails.case_year

              tmpObj.casedateailOfBonbayHighCourt(obj)
            }else if(tmpObj.caseDetails.courtDetails.courtType == '2' && tmpObj.caseDetails.courtDetails.courtName == 'High Court Server' && tmpObj.caseDetails.courtDetails.state =='13'){
              let obj = {};

              obj['case_cino']=tmpObj.caseDetails.case_cino
              obj['court_bench']=tmpObj.caseDetails.court_bench
              obj['search_by']="1"

              tmpObj.casedateailOfAllahabadHighCourt(obj)

            }else if(tmpObj.caseDetails.courtDetails.courtType == '2' && tmpObj.caseDetails.courtDetails.courtName == 'High Court Server' && tmpObj.caseDetails.courtDetails.state =='5'){
              let obj = {};

              obj['case_category']=tmpObj.caseDetails.case_category
              obj['case_type']=tmpObj.caseDetails.caseType
              obj['case_no']=tmpObj.caseDetails.caseNumber
              obj['case_year']=tmpObj.caseDetails.caseYear

              tmpObj.himachalcasedetail(obj)
            }
            else if(tmpObj.caseDetails.courtDetails.courtType == '3'){
              let obj = {};

              obj['state_code']=tmpObj.caseDetails.courtDetails.state
              obj['district_code']=tmpObj.caseDetails.courtDetails.district
              obj['forum_id']=tmpObj.caseDetails.courtDetails.complexName
              obj['case_no_year']=tmpObj.caseDetails.case_no_year
              obj['case_cino']=tmpObj.caseDetails.case_cino

              tmpObj.casedateailOfDistirctCourt(obj)
            }else if(tmpObj.caseDetails.courtDetails.courtType == '4'){
              let obj = {};

              if(tmpObj.caseDetails.commission_type=="B"){
                obj['case_no']=tmpObj.caseDetails.caseNumber
                obj['case_type']=tmpObj.caseDetails.caseType
                obj['case_year']=tmpObj.caseDetails.case_year
                obj['commission_type']=tmpObj.caseDetails.commission_type
                obj['district_code']="0"
                obj['state_code']="0"
              }else if(tmpObj.caseDetails.commission_type=="C"){
                obj['case_no']=tmpObj.caseDetails.caseNumber
                obj['case_type']=tmpObj.caseDetails.caseType
                obj['case_year']=tmpObj.caseDetails.case_year
                obj['commission_type']=tmpObj.caseDetails.commission_type
                obj['district_code']="0"
                obj['state_code']=tmpObj.caseDetails.courtDetails.state
              }else if(tmpObj.caseDetails.commission_type=="E"){
                obj['case_no']=tmpObj.caseDetails.caseNumber
                obj['case_type']=tmpObj.caseDetails.caseType
                obj['case_year']=tmpObj.caseDetails.case_year
                obj['commission_type']=tmpObj.caseDetails.commission_type
                obj['district_code']=tmpObj.caseDetails.courtDetails.district
                obj['state_code']=tmpObj.caseDetails.courtDetails.state
              }

              tmpObj.casedateailOfConsumerForum(obj)
            }else if(tmpObj.caseDetails.courtDetails.courtType == '1'){
            }else if(tmpObj.caseDetails.courtDetails.courtType == '9'){
              let obj = {};
              obj["case_cino"] = tmpObj.caseDetails.case_cino;
              console.log(obj,"check obj")
              tmpObj.getCaseDetilss(obj);
            }
           
            tmpObj.initSharingDetails(tmpObj);

          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });

        tmpObj.socketObject.emit(environment.socketEvents.getAppointmentsForCase, { caseId: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.appointments = socketResponse.data;
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });

        tmpObj.socketObject.emit(environment.socketEvents.getHearingsForCase, { caseId: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.hearings = socketResponse.data;
            console.log(tmpObj.hearings)
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });

        tmpObj.socketObject.emit(environment.socketEvents.listCourtTypeAndCourtsAlias, {}, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.courtsAndCourtTypeAlias.courtsAlias = socketResponse.data.courtsAlias;
            tmpObj.courtsAndCourtTypeAlias.courtTypesAlias = socketResponse.data.courtTypesAlias;
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 2000
            });
          }
        });

      } else {
        tmpObj.socketObject.emit(environment.socketEvents.getMutualCaseDetails, { _id: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.caseDetails = socketResponse.data;
            tmpObj.initSharingDetails(tmpObj);
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
      }


      tmpObj.socketObject.emit(environment.socketEvents.getCountiesStatesCities, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.citiesStatesCountriesList = socketResponse.finalData;
        }
      });

      tmpObj.socketObject.emit(environment.socketEvents.getModifiedApiRegionsProjects, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.regionsProjectSiteModified = socketResponse.regionsProjectSitesList;
        }
      });
      tmpObj.socketObject.emit(environment.socketEvents.getLinkedDoc, { _id: tmpObj.caseId }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.linkedDoc = socketResponse.data;

        }
      });
    }
  }


  ngOnDestroy() {
  }




  downloadFile(fileData) {
    if (fileData) {
      let accessToken = this.commonServices.getAccessToken();
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', environment.serviceConfiguration.url + fileData.filePath.substring(1) + "?access_token=" + accessToken);
      link.setAttribute('download', fileData.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  back() {
    window.history.back();
  }

  modifiedCourtCaseName(courtCaseDetails) {
    let finalStr = "";
    if (courtCaseDetails.petitioner && courtCaseDetails.responder) {
      let petitionerStr = "";
      for (let i = 0; i < courtCaseDetails.petitioner.length; i++) {
        if (i == courtCaseDetails.petitioner.length - 1) {
          petitionerStr += courtCaseDetails.petitioner[i].name;
        } else {
          petitionerStr += courtCaseDetails.petitioner[i].name + ",";
        }
      }

      let respondentStr = "";
      for (let i = 0; i < courtCaseDetails.responder.length; i++) {
        if (i == courtCaseDetails.responder.length - 1) {
          respondentStr += courtCaseDetails.responder[i].name;
        } else {
          respondentStr += courtCaseDetails.responder[i].name + ",";
        }
      }

      finalStr = petitionerStr + " Vs " + respondentStr;
    }
    

    if(courtCaseDetails.petitioner_versus_respondent){
      finalStr = courtCaseDetails.petitioner_versus_respondent

    }
    if(courtCaseDetails.petitioners&&courtCaseDetails.respondants){
      finalStr = courtCaseDetails.petitioners + " Vs " +courtCaseDetails.respondants

    }
    if(courtCaseDetails.case_name){
      finalStr = courtCaseDetails.case_name
    }
    return finalStr;
  }

  getPngFor(fileName) {
    let imgUrl = "assets/doc_icons/unknown.png";
    if (fileName) {
      let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      switch (ext.toLowerCase()) {
        case "doc":
        case "docx":
          imgUrl = "assets/doc_icons/doc.png";
          break;
        case "pdf":
          imgUrl = "assets/doc_icons/pdf.png";
          break;
        case "xls":
        case "xlsx":
          imgUrl = "assets/doc_icons/xls.png";
          break;
        case "ppt":
        case "pptx":
          imgUrl = "assets/doc_icons/ppt.png";
          break;
      }
    }
    return imgUrl;
  }

  print() {
    window.print();
  }


  isViewAllowed() {
    let isAllowed = false;
    // console.log(this.loggedInUserDetails,"isallowed runing")
    if (this.caseDetails && environment.roleDetails && this.loggedInUserDetails && this.moduleDetails) {

     
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
       
        if (this.caseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }

        } else {
          // console.log(typeof(this.caseDetails.inHouseAdvocate.indexOf(el=>{console.log("run")}),typeof(this.loggedInUserDetails._id),"isallowed run") //If not the user who created this court case
          // this.caseDetails.inHouseAdvocate.indexOf(el=>{console.log("run")})
          let pos 
          if(this.caseDetails.inHouseAdvocate){
            pos = this.caseDetails.inHouseAdvocate.map(e => e._id).indexOf(this.loggedInUserDetails._id);
            console.log(pos)
          }
       
          if (this.caseDetails.inHouseAdvocate
            && ((this.caseDetails.inHouseAdvocate.indexOf && pos != -1)
              || (this.caseDetails.inHouseAdvocate[pos]&&this.caseDetails.inHouseAdvocate[pos]._id == this.loggedInUserDetails._id))) {
            isAllowed = true;

          } else if (environment.roleDetails['allowedAccesses']
            && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]
            && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules
            && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules["allList"]) { //if user has access to complete list
            isAllowed = true;

          } else { //if user allowed by sharing the details internally
            if (this.sharingDetails) {
              if (this.sharingDetails.permissionLevel == environment.permissionLevels.editable.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnly.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnlyWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  isFileViewAllowed() {
    let isAllowed = false;
    if (this.caseDetails && environment.roleDetails && this.loggedInUserDetails && this.moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (this.caseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }

        } else if (environment.roleDetails['allowedAccesses']
          && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]
          && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules
          && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules["allList"]) { //if user has access to complete list
          isAllowed = true;

        } 
        else { //If not the user who created this court case
          if (this.caseDetails.inHouseAdvocate
            && ((this.caseDetails.inHouseAdvocate.indexOf && this.caseDetails.inHouseAdvocate.indexOf(this.loggedInUserDetails._id) != -1)
              || this.caseDetails.inHouseAdvocate == this.loggedInUserDetails._id)) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally

            if (this.sharingDetails) {
              if (this.sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnlyWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  isUserAllowedForFile(fileDetails) {
    let isAllowed = true;
    //let is_deleted= true;

    if (fileDetails.is_deleted == true) {
      isAllowed = false;
    }


    if (this.caseDetails.inHouseAdvocate
      && ((this.caseDetails.inHouseAdvocate.indexOf && this.caseDetails.inHouseAdvocate.indexOf(this.loggedInUserDetails._id) != -1)
        || this.caseDetails.inHouseAdvocate == this.loggedInUserDetails._id)) {
      isAllowed = true;
    } else if (fileDetails && this.sharingDetails && this.sharingDetails.filePermissions) {
      if (fileDetails.filePath) {
        for (let i = 0; i < this.sharingDetails.filePermissions.length; i++) {
          if (this.sharingDetails.filePermissions[i] && this.sharingDetails.filePermissions[i].filePath == fileDetails.filePath) {
            if (this.sharingDetails.filePermissions[i].permissions.view == true) {
              isAllowed = true;
            }
            break;
          }
        }
      }
    }



    return isAllowed;
  }

  getCurrentDate() {
    return new Date();
  }

  previewPdfFile(docDetails) {
    if (docDetails) {
      if (docDetails.type == "application/pdf") {
        let filePath: string = docDetails.filePath;
        filePath = filePath.replace("./", environment.serviceConfiguration.url + "/viewPDF/");
        let accessToken = this.commonServices.getAccessToken();
        window.open(filePath + "?access_token=" + accessToken,
          'Legal App PDF View',
          'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=' + screen.availWidth + ',height=' + screen.availHeight);
      } else {
        this.downloadFile(docDetails);
        this.snackbar.open(" You can't preview this file.", "Okay", {
          duration: 2000
        });
      }
    }
  }

  letterDateFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        let uploadDoc = [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadMutualDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadMutualDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadMutualDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadMutualDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadMutualDocumentEnd, (filePath) => {
          uploadDoc.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


            tmpObj.socketObject.emit(environment.socketEvents.saveUploadDocument, {
              uploadDoc: uploadDoc,
              _id: tmpObj.caseId

            }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
            tmpObj._layoutService.hideLoader()


            tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'

            })

          }
        });
      }
    }
  }

  deleteDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id
      this.socketObject.emit(environment.socketEvents.deleteDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deletePetitionDocument(fileObj: any, index: any) {

    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletePetitionDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }

  }


  petitionFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let uploadDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadPetitionDocumentSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentEnd, (filePath) => {
              uploadDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);

                tmpObj.socketObject.emit(environment.socketEvents.savePetitionDocument, {
                  uploadDoc: uploadDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 1", "Okay", {
          duration: 4000
        });
      }
    }
  }

  SupportingFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let uploadDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
              uploadDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);
                tmpObj.socketObject.emit(environment.socketEvents.saveSupportingDocument, {
                  uploadDoc: uploadDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 2", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteSupportingDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id
      this.socketObject.emit(environment.socketEvents.deleteSupportingDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  writenArgumentRespondentFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let writenArgumentRespondentDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
              writenArgumentRespondentDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveWritenArgumentRespondentDocument, {
                  writenArgumentRespondentDoc: writenArgumentRespondentDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 3", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteSupportingDocsWrittenArgumentsDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSupportingDocsWrittenArgumentsDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  writenArgumentChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let writenArgumentDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadWrittenArgumentDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadWrittenArgumentDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadWrittenArgumentDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadWrittenArgumentDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadWrittenArgumentDocEnd, (filePath) => {
              writenArgumentDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savewritenArgumentDocument, {
                  writenArgumentDoc: writenArgumentDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }

      } else {
        this.snackbar.open("You are not authorize to perform this operation. 4", "Okay", {
          duration: 4000
        });
      }
    }
  }

  rejoinerFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let rejoinerDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadRejoinerDocumentSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadRejoinerDocumentNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadRejoinerDocumentNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadRejoinerDocumentEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadRejoinerDocumentEnd, (filePath) => {
              rejoinerDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveRejoinerDocument, {
                  rejoinerDoc: rejoinerDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 5", "Okay", {
          duration: 4000
        });
      }
    }
  }

  surrejoinerFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let surrejoinerDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadSurrejoinerDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadSurrejoinerDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadSurrejoinerDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadSurrejoinerDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadSurrejoinerDocEnd, (filePath) => {
              surrejoinerDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveSurrejoinerDocument, {
                  surrejoinerDoc: surrejoinerDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 6", "Okay", {
          duration: 4000
        });
      }
    }
  }

  admissionDenialFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let admissionDenialDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadAdmissionDenialDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocEnd, (filePath) => {
              admissionDenialDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveAdmissionDenialDocument, {
                  admissionDenialDoc: admissionDenialDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 7", "Okay", {
          duration: 4000
        });
      }
    }
  }

  admissionDenialFileControlRespondentChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let admissionDenialReDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadAdmissionDenialDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocEnd, (filePath) => {
              admissionDenialReDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveAdmissionDenialRespondentDocument, {
                  admissionDenialDoc: admissionDenialReDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation 8.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  evidenceAffidavitFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let evidenceAffidaviteDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
              evidenceAffidaviteDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveEvidenceDocument, {
                  evidenceAffidaviteDoc: evidenceAffidaviteDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 9", "Okay", {
          duration: 4000
        });
      }
    }
  }

  evidenceAffidavitRespondentFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let evidenceAffidaviteRespondentDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
              evidenceAffidaviteRespondentDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.saveEvidenceRespondentDocument, {
                  evidenceAffidaviteRespondentDoc: evidenceAffidaviteRespondentDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 10", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteWrittenArgumentsDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteWrittenArgumentsDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }

  }


  deleteLetterDateDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteLetterDateDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteRejoinderDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteRejoinderDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteSurrejoinederDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSurrejoinederDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteSupportingDocsDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSupportingDocsDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteAdmissionDenialDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteAdmissionDenialDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }

  }


  deleteSupportingDocAdmissionDenialDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSupportingDocAdmissionDenialDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteEvidenceAffidavitDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteEvidenceAffidavitDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteSupportingDocEvidenceAffidavitDocument(fileObj: any, index: any) {

    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSupportingDocEvidenceAffidavitDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }





  deleteLegalNoticeDocuments(user: User, fileObj: any, index: any) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {


        if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
          let tmpObj = this;
          let _id = this.caseDetails._id

          this.socketObject.emit(environment.socketEvents.deleteLegalNotice, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          })
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 11", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteLinkedDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteLinkedDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  openAddHearingModal() {
    this.dialog.open(HearingsCreateUpdateComponent, {
      panelClass: 'hearings-dialog-container',
      disableClose: true,
      data: {
        courtCase: this.caseDetails
      }
    }).afterClosed().subscribe((user) => {
      if (user) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.createHearing, user, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Hearing.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          });
        }
      }
    });
  }

  openAddAppointment() {
    this.dialog.open(AppointmentsCreateUpdateComponent, {
      disableClose: true,
      data: {
        courtCase: this.caseDetails
      }
    }).afterClosed().subscribe((user) => {
      if (user) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.createAppointment, user, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Appointment.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          });
        }
      }
    });
  }

  


  AssignedAdvocate() {

  }

  editCase(user: User) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditCourtCase(user, this.moduleDetails)) {
        if (this.caseType == environment.constants.courtCase) {
          this.dialog.open(CourtCasesCreateUpdateComponent, {
            data: this.caseDetails,
            panelClass: 'court-case-dialog-container',
            disableClose: true
          }).afterClosed().subscribe(updatedCustomer => {
            if (updatedCustomer) {
              if (this.socketService.isSocketConnected()) {
                let tmpObj = this;
                this.socketObject.emit(environment.socketEvents.updateCourtCase, updatedCustomer, function (socketResponse) {
                  if (socketResponse.status == 'OK') {
                    tmpObj.snackbar.open('Successfully Updated Court Case.', 'Okay', {
                      duration: 2000
                    });
                    tmpObj.refreshData(tmpObj);
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                      duration: 2000
                    });
                  }
                });
              }
            }
          });
        } else {
          this.dialog.open(CasesCreateUpdateComponent, {
            data: this.caseDetails,
            panelClass: 'mutual-case-dialog-container',
            disableClose: true
          }).afterClosed().subscribe(updatedCustomer => {
            if (updatedCustomer) {
              if (this.socketService.isSocketConnected()) {
                let tmpObj = this;
                this.socketObject.emit(environment.socketEvents.updateMutualCase, updatedCustomer, function (socketResponse) {
                  if (socketResponse.status == 'OK') {
                    tmpObj.snackbar.open('Successfully Updated Mutual Case.', 'Okay', {
                      duration: 2000
                    });
                    tmpObj.refreshData(tmpObj);
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                      duration: 2000
                    });
                  }
                });
              }
            }
          });
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 12", "Okay", {
          duration: 4000
        });
      }
    }

  }



  // new code start

  MatSprnnerOfSection=false;
  MatSprnnerOfDetail=false

  showServerErrOfapplicantDetail = ''
  showServerErrOfrespondentDetail=''
  showServerErrOfapplicantLegalRepresentative=''
  showServerErrOfrespondentLegalRepresentative=''
  showServerErrOffirstHearingDetail=''
  showServerErrOfLastHearingDetail=''
  showServerErrOfNextHearingDetail=''
  showServerErrOfCaseHistory=''
  showServerErrOforderHistory=''
  showServerErrOfIasOtherApplication=''
  showServerErrOfConnectedCases=''

  caesDetailData;
  t1;t2;
  getCaseDetils(data) {
    console.log(data,"daat")
    this.MatSprnnerOfDetail=true
    this.tribunalService
      .postData('caseDetails', data)
      .subscribe((res) => {
        if (res.status == true) {
          this.caesDetailData = res.caseDetails[0];
          let caseTitle = this.caseDetails.caseName.split("VS");
          this.t1 = caseTitle[0];
          this.t2 = caseTitle[1];
          this.MatSprnnerOfDetail=false

          this.partyDetail();
        }
      },(err)=>{
      }
      );
  }


  getCaseDetilss(data) {
    this.ncltCourtService
      .postData('caseDetails', data)
      .subscribe((res) => {
        console.log(res,"getcaseDetail data")
        this.caesDetailData = res.caseDetails;
        // this.partyDetailofNclt(this.caesDetailData)
        // this.historyListing(this.caesDetailData)
        // this.iaMaListing(this.caesDetailData)
        // this.connectedMatterListing(this.caesDetailData)
        let caseTitle = this.caesDetailData.party_name.split("VS");
        this.t1 = caseTitle[0];
        this.t2 = caseTitle[1];
        this.MatSprnnerOfDetail=false
      },(err)=>{
      }
      );
  } 

  applicant_nameArr_Nclt = []
  // partyDetailofNclt(val){

  //   this.applicant_nameArr_Nclt = [];
  //   let obj = {
  //     court_case_id:val.id
  //   }
  //   this.ncltCourtService.postData('allParties', obj).subscribe((res) => {
  //     console.log(res.allParties)
  //     this.applicant_nameArr_Nclt.push(res.allParties)
  //     console.log(this.applicant_nameArr_Nclt,"this.applicant_nameArr_Nclt")
  //   })
  // }

  // allparties  
  partyDetail1(){
    this.MatSprnnerOfSection=true
    this.applicant_nameArr_Nclt = [];
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.ncltCourtService
      .postData('allParties', obj)
      .subscribe((res) => {
        console.log(res,"getting response of party detail")
        if(res.status){
        this.applicant_nameArr_Nclt.push(res.allParties)
        this.MatSprnnerOfSection=false
        }else{
          this.msgfornodata='data not found'
        }
      },(err)=>{
        this.showServerErrOfapplicantDetail = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
      }
      );
  }


  

  historyData = []
  message
  // historyListing(val){
  //   let obj = {
  //     court_case_id:val.id
  //   }
  //   this.ncltCourtService.postData('listingHistory',obj).subscribe((res)=>{
  //     if(res.listingHistory){
  //     this.historyData = res.listingHistory
  //     }
  //     else{
  //       this.message= "No Data Found!"
  //     }
  //   },(err)=>{
  //   })
  
  
  // }


  historyListing(){
    this.MatSprnnerOfSection=true
    // this.showHeader = true;
    let obj ={}
    obj["court_case_id"] =  this.caesDetailData.id;
    this.ncltCourtService.postData('listingHistory',obj).subscribe((res)=>{
      if(res.listingHistory){
      this.historyData = res.listingHistory
      this.MatSprnnerOfSection=false
      }
      else{
        this.MatSprnnerOfSection=false
        // this.nodatafound= false
        this.msgfornodata= "No Data Found!"
      }
    },(err)=>{
      // this.showServerErrOfhistoryListing = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
    })
  
  
  }







  iaMaData= []
  // iaMaListing(val){
  //   let obj = {
  //     court_case_id:val.id
  //   }
  //   this.ncltCourtService.postData('iaAndMa',obj).subscribe((res)=>{
  
  //     if(res.iaAndMa){
  //       this.iaMaData = res.iaAndMa
  //       }
  //       else{
  //         this.message= "No Data Found!"
  //       }
  //   },(err)=>{
  //   })
  // }


  iaMaListing(){
    this.MatSprnnerOfSection=true
    // this.showHeader = true;
    let obj ={}
    obj["court_case_id"] = this.caesDetailData.id;
    this.ncltCourtService.postData('iaAndMa',obj).subscribe((res)=>{
      console.log(res,"getting response of iaMaListing")
      if(res.iaAndMa){
        this.iaMaData = res.iaAndMa
        this.MatSprnnerOfSection=false
        }
        else{
          this.MatSprnnerOfSection=false
          this.msgfornodata= "No Data Found!"
        }
    },(err)=>{
      // this.showServerErrOfiaMaListing = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
    })
  }






// connectedMatterListing(val){
//   let obj = {
//     court_case_id:val.id
//   }
//   this.ncltCourtService.postData('connectedMatters',obj).subscribe((res)=>{
//     console.log(res,"getting response of iaMaListing")
//     if(res.connectedMatters){
//       this.connectedMatterData = res.connectedMatters
//       }
//       else{
//         this.message= "No Data Found!"
//       }
//   },(err)=>{
//   })
// }

// connectedMatterData
connectedMatterData = []

connectedMatterListing(){
  this.MatSprnnerOfSection=true
  // this.showHeader = true;
  let obj ={}
  obj["court_case_id"] = this.caesDetailData.id;
  this.ncltCourtService.postData('connectedMatters',obj).subscribe((res)=>{
    console.log(res,"getting response of iaMaListing")
    if(res.connectedMatters){
      this.connectedMatterData = res.connectedMatters
      this.MatSprnnerOfSection=false
      }
      else{
        this.MatSprnnerOfSection=false
        // this.nodatafound= false
        this.msgfornodata= "No Data Found!"
      }
  },(err)=>{
    // this.showServerErrOfconnectedMattersListing = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
  })
}



  countofparty=0
  partyDetail(){
    // this.toggle2=false;
    if(this.countofparty==0){
      this.MatSprnnerOfSection=true
      this.applicantDetail();
      this.respondentDetail();
    }
  }

  applicant_nameArr = [];
  applicantDetail() { 
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.tribunalService
      .postData('applicantName', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.applicant_nameArr.push(...res.applicantName);
        }
      },(err)=>{
        this.showServerErrOfapplicantDetail = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
      });
  }

  Respodent_nameArr = [];
  respondentDetail() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;

    this.tribunalService
      .postData('respondantName', obj)
      .subscribe((res) => {
        console.log(res)
        if (res.status == true) {
          this.Respodent_nameArr.push(...res.respondantName);
          
          this.MatSprnnerOfSection=false
          this.countofparty=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofparty=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofparty=1
        this.showServerErrOfrespondentDetail = 'Facing Some Server Issue in respondent Name Please Try After Some Time'
      });
  }

  countoflegal=0
  legalRepresentative(){
    if(this.countoflegal==0){
      this.MatSprnnerOfSection=true
      this.applicantLegalRepresentative()
      this.respondentLegalRepresentative()
    }
  }

  applicant_legal_representative_name = [];
  applicantLegalRepresentative() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.tribunalService
      .postData('applicantLegalRepresentative', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.applicant_legal_representative_name.push(
            ...res.applicantLegalRepresentative
          );

        }
      },(err)=>{
        this.showServerErrOfapplicantLegalRepresentative = 'Facing Some Server Issue in applicantLegalRepresentative Name Please Try After Some Time'
      });
  }

  respondent_legal_representative_name = [];
  respondentLegalRepresentative() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.tribunalService
      .postData('respondentLegalRepresentative', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.respondent_legal_representative_name.push(
            ...res.respondentLegalRepresentative
          );
          this.MatSprnnerOfSection=false
          this.countoflegal=1

        }else{
          this.MatSprnnerOfSection=false
          this.countoflegal=1

        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countoflegal=1

        this.showServerErrOfrespondentLegalRepresentative = 'Facing Some Server Issue in respondentLegalRepresentative Name Please Try After Some Time'
      });
  }

  countoffirst=0
  firstHearingDetail = {};
  getfirstHearingDetail() {
    if(this.countoffirst == 0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('firstHearingDetails', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.firstHearingDetail = { ...res.firstHearingDetails[0] };

            this.MatSprnnerOfSection=false
            this.countoffirst=1

          }else{
            this.MatSprnnerOfSection=false
            this.countoffirst=1

          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countoffirst=1
          this.showServerErrOffirstHearingDetail = 'Facing Some Server Issue in firstHearingDetail Please Try After Some Time'
        });
    }
  }

  countoflast=0
  lastHearingDetail = {};
  getLastHearingDetail() {
    if(this.countoflast == 0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('lastHearingDetails', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.lastHearingDetail = { ...res.lastHearingDetails[0] };
            this.MatSprnnerOfSection=false
            this.countoflast=1
          }else{
            this.MatSprnnerOfSection=false
            this.countoflast=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countoflast=1
          this.showServerErrOfLastHearingDetail = 'Facing Some Server Issue in LastHearingDetail Please Try After Some Time'
        });
    }
  }

  countofnext=0
  nextHearingDetail = {};
  getNextHearingDetail() {
    if(this.countofnext == 0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('nextHearingDetails', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.nextHearingDetail = { ...res.nextHearingDetails[0] };
            console.log(this.nextHearingDetail)
            this.MatSprnnerOfSection=false
            this.countofnext=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofnext=1 
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofnext=1 
          this.showServerErrOfNextHearingDetail = 'Facing Some Server Issue in NextHearingDetail Please Try After Some Time'
        });
    }
  }

  countofcasehistory=0
  caseHistory = [];
  getCaseHistory() {
    if(this.countofcasehistory==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('caseHistory', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.caseHistory.push(...res.caseHistory);
            this.MatSprnnerOfSection=false
            this.countofcasehistory=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofcasehistory=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofcasehistory=1
          this.showServerErrOfCaseHistory = 'Facing Some Server Issue in CaseHistory Please Try After Some Time'
        });
    }
  }

  countoforderhistory=0
  orderHistory = [];
  getorderHistory() {
    if(this.countoforderhistory==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('orderHistory', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.orderHistory.push(...res.orderHistory);
            this.MatSprnnerOfSection=false
            this.countoforderhistory=1
          }else{
            this.MatSprnnerOfSection=false
            this.countoforderhistory=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countoforderhistory=1
          this.showServerErrOforderHistory = 'Facing Some Server Issue in orderHistory Please Try After Some Time'
        });
    }
  }

  countofias=0
  iasOtherApplication = [];
  getIasOtherApplication() {
    if(this.countofias==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('iasOtherApplication', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.iasOtherApplication.push(...res.iasOtherApplication);
            this.MatSprnnerOfSection=false
            this.countofias=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofias=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofias=1
          this.showServerErrOfIasOtherApplication = 'Facing Some Server Issue in IasOtherApplication Please Try After Some Time'
        });
    }
  }

  countofconnected=0
  connectedCases = [];
  getConnectedCases() {
    if(this.countofconnected==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('connectedCases', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.connectedCases.push(...res.connectedCases);
            this.MatSprnnerOfSection=false
            this.countofconnected=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofconnected=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofconnected=1
          this.showServerErrOfConnectedCases = 'Facing Some Server Issue in ConnectedCases Please Try After Some Time'
        });
    }
  }

  viewDetailOfOrderHistyory(data) {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    obj["case_history_id"] = data.id;
    obj["t1"] = this.t1;
    obj["t2"] = this.t2;

    this.dialog.open(TribunalCaseHistoryDetailComponent, {
      height: "500px",
      width: "900px",
      autoFocus: false,
      data: obj,
    });
  }

  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert("No data Found");
    }
  }

  step = 0;


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  // E-court api start here
  caseid
  getcasedetail(data){
    console.log(data)
    this.highcourt.postecourt('ws/eHighCourtCase',data).subscribe((res)=>{
      if(res['status']){
        let detaildata = res['courtInfo']
        this.caseid = detaildata.id
      }
    })
  }

  msgfornodata
  getcasestatusdetais(){
    this.casestatusdetails=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseStatus',obj).subscribe((res2: any) => {
      if(res2.status){
        this.casestatusdetails=res2.caseStatusInfo
        this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
       
    })
  
  }
  getcaseconversiondetail(){
    this.caseConversionInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighCaseConversionDetail',obj).subscribe((res2: any) => {
      console.log(res2)
      if(res2.status){
       this.caseConversionInfo=res2.caseConversionInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getmainmetterdetail(){
    this.caseMainMatterInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighMainMatterDetails',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseMainMatterInfo=res2.caseMainMatterInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getsubmetterdetail(){
    this.caseSubMatterInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighSubMatterDetails',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseSubMatterInfo=res2.caseSubMatterInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getlinkedcasedetail(){
    this.caseLinkedInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighLinkedDetails',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseLinkedInfo=res2.caseLinkedInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      } 
    })
  }
  getactdetais(){
    this.caseactdetails=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighSubordinateInformation',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseactdetails=res2.caseSubordinateInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  
  }
  getiadetail(){
    this.iadetail=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighIADetails',obj).subscribe((res2: any) => {
      if(res2.status){
      this.iadetail=res2.caseIAInfo
      this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  
  }
  getactdetal(){
    this.caseActsInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseActs',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseActsInfo=res2.caseActsInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getCaseHistorydetais(){
    this.casehistorydetails=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseHistory',obj).subscribe((res2: any) => {
      if(res2.status){
       this.casehistorydetails= res2.caseHistoryInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  
  }
  getdocumentdetail(){
    this.caseDocumentInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighDocumentsDetail',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseDocumentInfo= res2.caseDocumentInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getCaseordersdetais(){
    this.caseordersdetails=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
   
    this.highcourt.postecourt('ws/eHighGetCaseOrders',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseordersdetails= res2.caseOrderInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  
  }
  getfirdetail(){
    this.caseFIRInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighFIRDetails',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseFIRInfo= res2.caseFIRInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getcategorydetail(){
    this.caseCategoryInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighCategoryDetails',obj).subscribe((res2: any) => {
      if(res2.status){
       this.caseCategoryInfo= res2.caseCategoryInfo
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  getobjectiondetail(){
    this.caseObjectionInfo=[]
    this.MatSprnnerOfSection=true
    let obj = {
      "court_case_id":this.caseid 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighObjectionDetails',obj).subscribe((res2: any) => {
      if(res2.status){
      console.log(res2.caseObjectionInfo)
       this.caseObjectionInfo= res2.caseObjectionInfo
       console.log(this.caseObjectionInfo)
       this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  // E-court api end here

  // Delhi high court api start here
  court_case_id
  courtCase(data){

    let obj = {
      pno: data.pno_no
    };

    console.log(obj)
    this.delhihighcourtservice.postecourt("courtCases",obj).subscribe((res)=>{

      console.log(res)
      if(res['status']==true){
        this.court_case_id = res['partyName'][0].id
      }
    })
  }
  caseOrderData=[];
  caseOrders(){
    this.caseOrderData=[]
    this.MatSprnnerOfSection=true

    let obj = {
      court_case_id: this.court_case_id
    };

    this.delhihighcourtservice.postecourt("caseOrders",obj).subscribe((res)=>{

      if(res['status']==true){
        this.caseOrderData = res['partyName']
        this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  caseDetailOrdersData=[];
  caseDetailOrders(){
    this.caseDetailOrdersData=[]
    this.MatSprnnerOfSection=true

    let obj = {
      court_case_id: this.court_case_id
    };

    this.delhihighcourtservice.postecourt("caseDetailOrders",obj).subscribe((res)=>{

      if(res['status']==true){
        this.caseDetailOrdersData = res['partyName']
        this.MatSprnnerOfSection=false
      }else{
        this.MatSprnnerOfSection=false
        this.msgfornodata='data not found'
      }
    })
  }
  // Delhi high court api end here

  // Bombay high court api start here
  casedetailBombay
  casedateailOfBonbayHighCourt(data){
    this.bombayhighcourtservice.postData("caseDetails", data).subscribe((res)=>{
      this.casedetailBombay=res.caseDetails[0]
    })
  }

  officeInformationData=[];
  officeInformation(){
      this.officeInformationData=[]
      this.MatSprnnerOfSection=true
      let obj = {
        id: this.casedetailBombay.id
      }

      this.bombayhighcourtservice.postData('officeInformation',obj).subscribe((res)=>{
        if(res.status){
          this.officeInformationData=res.officeInformation
          this.MatSprnnerOfSection=false
        }else{
          this.MatSprnnerOfSection=false
        }
      })
  }

  subject_category:any
  miscInformationData=[];
  miscInformation(){
      this.miscInformationData=[]
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.casedetailBombay.id
      }
  
      this.bombayhighcourtservice.postData('miscInformation',obj).subscribe((res)=>{
        if(res.status){
          this.miscInformationData=res.miscInformation

          if(this.miscInformationData[0].subject_category != null || this.miscInformationData[0].subject_category != ''){
            this.subject_category=this.miscInformationData[0].subject_category 
          }
          this.MatSprnnerOfSection=false
        }else{
          this.MatSprnnerOfSection=false
        }
      })
  }
  objectionsData=[];
  objections(){
      this.objectionsData=[]
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.casedetailBombay.id
      }
  
      this.bombayhighcourtservice.postData('objections',obj).subscribe((res)=>{
        if(res.status){
          this.objectionsData=res.objections
          this.MatSprnnerOfSection=false
        }else{
          this.MatSprnnerOfSection=false
        }
      })
  }
  casePaperIndexData=[];
  casePaperIndex(){
    this.casePaperIndexData=[];
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.casedetailBombay.id
      }
  
      this.bombayhighcourtservice.postData('casePaperIndex',obj).subscribe((res)=>{
        if(res.status==true){
          this.casePaperIndexData=res.casePaperIndex
          this.MatSprnnerOfSection=false
        }else{
          this.MatSprnnerOfSection=false
        }
      })
  }
  connectedMattersData=[];
  connectedMatters(){
    this.connectedMattersData=[];
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.casedetailBombay.id
      }
  
      this.bombayhighcourtservice.postData('connectedMatters',obj).subscribe((res)=>{
        if(res.status==true){
          this.connectedMattersData.push(...res.connectedMatters)
          this.MatSprnnerOfSection=false
        }else{
          this.MatSprnnerOfSection=false
        }
      })
  }
  applicationCasesData=[];
  applicationCases(){
    this.applicationCasesData=[];
      this.MatSprnnerOfSection=true
      let obj = {
        court_case_id: this.casedetailBombay.id
      }
  
      this.bombayhighcourtservice.postData('applicationCases',obj).subscribe((res)=>{
        if(res.status==true){
          this.applicationCasesData.push(...res.applicationCases)
          this.MatSprnnerOfSection=false
        }else{
          this.MatSprnnerOfSection=false
        }
      })
  }
  listingDatesData=[];
  listingDates(){
      this.listingDatesData=[];
        this.MatSprnnerOfSection=true
        let obj = {
          court_case_id: this.casedetailBombay.id
        }
    
        this.bombayhighcourtservice.postData('listingDates',obj).subscribe((res)=>{
          if(res.status==true){
            this.listingDatesData.push(...res.listingDates)
            this.MatSprnnerOfSection=false
          }else{
            this.MatSprnnerOfSection=false
          }
        })
  }
  LowerCourt(){
    this.MatSprnnerOfSection=true
    this.lowerCourtDetails()
    this.appealCourtDetails()
  }
  lowerCourtDetailsData=[];
  lowerCourtDetails(){
        this.lowerCourtDetailsData=[];
        let obj = {
          court_case_id: this.casedetailBombay.id
        }
    
        this.bombayhighcourtservice.postData('lowerCourtDetails',obj).subscribe((res)=>{
          if(res.status==true){
            this.lowerCourtDetailsData.push(...res.lowerCourtDetails)
          }
        })
  } 
  appealCourtDetailsData=[];
  appealCourtDetails(){
    this.appealCourtDetailsData=[];
        let obj = {
          court_case_id: this.casedetailBombay.id
        }
    
        this.bombayhighcourtservice.postData('appealCourtDetails',obj).subscribe((res)=>{
          if(res.status==true){
            this.appealCourtDetailsData.push(...res.appealCourtDetails) 
            this.MatSprnnerOfSection=false
          }else{
            this.MatSprnnerOfSection=false
          }
        })
  }
  // Bombay high court api end here

  // Allahabad high court api start here
  casedetailAllahabadId
  casedateailOfAllahabadHighCourt(data){
    this.allahabadhighcourtservice.postData("caseDetails", data).subscribe((res)=>{
       this.casedetailAllahabadId = res.caseDetails[0].id
    })
  }
  caseStatusArr=[]
  caseStatusData;
  countOfcaseStatus=0;
  caseStatus(){
      this.MatSprnnerOfSection=true;
      this.caseStatusArr=[]
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("caseStatus", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.caseStatusArr.push(...res.caseStatus);
            this.caseStatusData=res.caseStatus[0];

            this.MatSprnnerOfSection=false;
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        });
  }
  categoryDetailsArr=[];
  categoryDetailsData;
  categoryDetails(){
    this.categoryDetailsArr=[];
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("categoryDetails", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.categoryDetailsArr.push(...res.categoryDetails);
            this.categoryDetailsData=res.categoryDetails[0]

            this.MatSprnnerOfSection=false;
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        }
      );
  }
  iaDetailsData=[];
  iaDetails(){
    this.iaDetailsData=[];
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("iaDetails", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.iaDetailsData.push(...res.iaDetails);

            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        }
      );
  }
  lowerCourtDetailsArr=[]
  lowerCourtDetailsDatas;
  lowerCourtDetail(){
    this.lowerCourtDetailsArr=[]
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("lowerCourtDetails", obj).subscribe(
        (res) => {
        console.log(res)

          if (res.status == true) {
            this.lowerCourtDetailsArr.push(...res.lowerCourtDetails);
            this.lowerCourtDetailsDatas = res.lowerCourtDetails[0]

            this.MatSprnnerOfSection=false;
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        }
      );
  }
  crimeDetailsArr=[]
  crimeDetailsData;
  crimeDetails(){
    this.crimeDetailsArr=[]
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("crimeDetails", obj).subscribe(
        (res) => {

          if (res.status == true) {
            this.crimeDetailsArr.push(...res.crimeDetails);
            this.crimeDetailsData = res.crimeDetails[0];

            this.MatSprnnerOfSection=false;
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        },
      );
  }
  listingHistoryData=[];
  listingHistory(){
    this.listingHistoryData=[];
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("listingHistory", obj).subscribe(
        (res) => {

          if (res.status == true) {
            this.listingHistoryData.push(...res.listingHistory);
            this.MatSprnnerOfSection=false;
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        },
      );
  }
  ordersJudgementData=[];
  ordersJudgement(){
    this.ordersJudgementData=[];
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.casedetailAllahabadId
      }
      this.allahabadhighcourtservice.postData("ordersJudgement", obj).subscribe(
        (res) => {

          if (res.status == true) {
            this.ordersJudgementData.push(...res.ordersJudgement);

            this.MatSprnnerOfSection=false;
          }else{
            this.MatSprnnerOfSection=false;
            this.msgfornodata='data not found'
          }
        },
      );
  }
  // Allahabad high court api end here

  // District Court api start here
  distictCourtId
  casedateailOfDistirctCourt(data){
    this.districtcourt.postecourt('ws/eCourtCase',data).subscribe((res)=>{
      this.distictCourtId = res['courtInfo'].id
      console.log(this.distictCourtId)
    })
  }

  casestatusOfDistrict;
  getcasestatusOfDistrict(){
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseStatus',obj).subscribe((res2: any) => {
      if (res2.status) {
        this.casestatusOfDistrict = res2.caseStatusInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
      }
    })
  }
  actdetailofdistrict=[]
  getactdetailofdistrict(){
    this.actdetailofdistrict=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseActs',obj).subscribe((res2: any) => {
      if(res2.status){
        this.actdetailofdistrict= res2.caseActsInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  
  }
  caseMainMatterInfoofdistrict=[]
  getmainmetterdetailofdistrict(){
    this.caseMainMatterInfoofdistrict=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    this.districtcourt.postecourt('ws/eGetCaseMainMatter',obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseMainMatterInfoofdistrict= res2.caseMainMatterInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  caseSubMatterInfoofdistrict=[]
  getsubmetterdetailofdistrict(){
    this.caseSubMatterInfoofdistrict=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    this.districtcourt.postecourt('ws/eGetCaseSubMatter',obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseSubMatterInfoofdistrict= res2.caseSubMatterInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  caseProcessInfo=[]
  getprocessdetail(){
    this.caseProcessInfo=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    this.districtcourt.postecourt('ws/eGetCaseProcessDetails',obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseProcessInfo= res2.caseProcessInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  caseSubordinateCourtInfo=[]
  getsubordinatedetail(){
    this.caseSubordinateCourtInfo=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    this.districtcourt.postecourt('ws/eGetCaseSubordinateCourtInfo',obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseSubordinateCourtInfo= res2.caseSubordinateCourtInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  casehistorydetail=[]
  getCaseHistorydetail(){
    this.casehistorydetail=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseHistory',obj).subscribe((res2: any) => {
      if(res2.status){
        this.casehistorydetail= res2.caseHistoryInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  casefirDetailInfoofdistrict=[]
  getfirdetailofdistrict(){
    this.casefirDetailInfoofdistrict=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    this.districtcourt.postecourt('ws/eGetCasefirDetails',obj).subscribe((res2: any) => {
      if(res2.status){
        this.casefirDetailInfoofdistrict= res2.casefirDetailInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  caseordersdetail=[]
  getCaseordersdetail(){
    this.caseordersdetail=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
   
    this.districtcourt.postecourt('ws/eGetCaseOrders',obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseordersdetail= res2.caseOrderInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  
  }
  caseTransferInfo=[]
  getcasetransferdetail(){
    this.caseTransferInfo=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      court_case_id:this.distictCourtId 
    }
    console.log(obj)
    this.districtcourt.postecourt('ws/eGetCaseCourtTransfer',obj).subscribe((res2: any) => {
      console.log(res2)
      if(res2.status){
        this.caseTransferInfo= res2.caseTransferInfo
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    })
  }
  

  openhearing(data){
    this.dialog.open(HistorydetailComponent,{
      height: '500px',
      width: '800px',
      autoFocus: false,
      data:data
      });
  }
  
  // District Court api end here

  // consumer forum api start here
  casedetailconsumerForum
  casedateailOfConsumerForum(data){
    this.consumerCourtService.postData('caseStatus', data).subscribe((res)=>{
      this.casedetailconsumerForum=res.caseStatus[0]
    })
  }
  caseHistoryOrdersData=[];
  caseHistoryConsumerForum(){
    this.caseHistoryOrdersData=[];
    this.MatSprnnerOfSection=true;
    this.consumerCourtService.postData('caseHistoryOrders', {id:this.casedetailconsumerForum.id}).subscribe(
      (res) => {
        if(res.status==true){

          for(let data of res.caseHistoryOrders){
            if(data.judgement_url != null){
              let orderData = data.order.split(',')
              data['value1'] = orderData[0]
              data['value2'] = orderData[1]
            }
          }

          this.caseHistoryOrdersData.push(...res.caseHistoryOrders);
          this.MatSprnnerOfSection=false;
        }else{
          this.MatSprnnerOfSection=false;
          this.msgfornodata='data not found'
        }
      },
    );
  }
  // consumer forum api end here

  // Supreme court api start here
  casedetailSupremeCourtId
  casedateailOfSupremeCourt(data){
    this.court.post("ws/courtCase", data).subscribe((res)=>{
      this.casedetailSupremeCourtId=res['caseInfo'].id
    })
  }

  caseIndexingInfo=[]
  getindexingdetail() {
    this.caseIndexingInfo=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseIndexing", obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseIndexingInfo.push(...res2.caseIndexingInfo);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  earlierCourtDetail=[]
  getearlierCourtdetails() {
    this.earlierCourtDetail=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    this.court.post("ws/earlierCourtDetail", obj).subscribe((res2: any) => {
      if(res2.status){
        this.earlierCourtDetail.push(...res2.earlierCourt);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  taggedMatter=[]
  getcourtTaggedMatterdetails() {
    this.taggedMatter=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtTaggedMatter", obj).subscribe((res2: any) => {
      if(res2.status){
        this.taggedMatter.push(...res2.taggedMatter);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  caseListingDates=[]
  getlistingDatedetails() {
    this.caseListingDates=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    this.court.post("ws/listingDate", obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseListingDates.push(...res2.caseListingDates);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  Interlocutory_Application_Documents(){
    this.MatSprnnerOfSection=true;
    this.getinterlocutoryApplicationDocumentdetails()
    this.getApplicationDocumentdetails()
  }
  caseInterlocutoryApplicationDocuments=[]
  getinterlocutoryApplicationDocumentdetails() {
    this.caseInterlocutoryApplicationDocuments=[]
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court
      .post("ws/interlocutoryApplicationDocument", obj)
      .subscribe((res2: any) => {
        if(res2.status){
          this.caseInterlocutoryApplicationDocuments.push(...res2.caseInterlocutoryApplicationDocuments)
        }
      });
  }
  caseOtherApplicationDocuments=[]
  getApplicationDocumentdetails() {
    this.caseOtherApplicationDocuments=[]
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court
      .post("ws/otherApplicationDocument", obj)
      .subscribe((res2: any) => {
        if(res2.status){
          this.caseOtherApplicationDocuments.push(...res2.caseOtherApplicationDocuments);
          this.MatSprnnerOfSection=false;
        }else{
          this.MatSprnnerOfSection=false;
        }
      });
  }
  caseCourtFees=[]
  getcourtFeedetails() {
    this.caseCourtFees=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtFee", obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseCourtFees.push(...res2.caseCourtFees);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  noticedetails=[]
  getnoticedetails() {
    this.noticedetails=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtNotice", obj).subscribe((res2: any) => {
      if(res2.status){
        this.noticedetails.push(...res2.caseCourtNotices);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  caseCaseDefects=[]
  getcaseDefectdetails() {
    this.caseCaseDefects=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseDefect", obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseCaseDefects.push(...res2.caseCaseDefects);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  judgementdetails=[]
  getjudgementdetails() {
    this.judgementdetails=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    this.court.post("ws/judgement", obj).subscribe((res2: any) => {
      if(res2.status){
        this.judgementdetails.push(...res2.caseJudgement);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  courtMentionInfo=[]
  getmentionmemodetail() {
    this.courtMentionInfo=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtMentionMemo", obj).subscribe((res2: any) => {
      if(res2.status){
        this.courtMentionInfo.push(...res2.courtMentionInfo);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  courtRestorationInfo=[]
  getrestorationdetail() {
    this.courtRestorationInfo=[]
    this.MatSprnnerOfSection=true;

    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtRestorationDetail", obj).subscribe((res2: any) => {
      if(res2.status){
        this.courtRestorationInfo.push(...res2.courtRestorationInfo);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  courtDropNoteInfo=[]
  getdropnotedetail() {
    this.courtDropNoteInfo=[]
    this.MatSprnnerOfSection=true;

    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtDropNotes", obj).subscribe((res2: any) => {
      if(res2.status){
        this.courtDropNoteInfo.push(...res2.courtDropNoteInfo);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  caseAppearanceInfo=[]
  getapperencedetail() {
    this.caseAppearanceInfo=[]
    this.MatSprnnerOfSection=true;

    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseAppearance", obj).subscribe((res2: any) => {
      if(res2.status){
        this.caseAppearanceInfo.push(...res2.caseAppearanceInfo);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  officereportdetails=[]
  getofficereportdetail() {
    this.officereportdetails=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/officeReport", obj).subscribe((res2: any) => {
      if(res2.status){
        this.officereportdetails.push(...res2.officeReportInfo);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  courtSimilarities=[]
  getcourtSimilaritydetails() {
    this.courtSimilarities=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtSimilarity", obj).subscribe((res2: any) => {
      if(res2.status){
        this.courtSimilarities.push(...res2.courtSimilarities);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  courtCaveat=[]
  getcourtCaveatsdetails() {
    this.courtCaveat=[]
    this.MatSprnnerOfSection=true;
    let obj = {
      case_id: this.casedetailSupremeCourtId,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtCaveats", obj).subscribe((res2: any) => {
      if(res2.status){
        this.courtCaveat.push(...res2.courtCaveat);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata='data not found'
      }
    });
  }
  // Supreme court api end here

  // himachal high court api start here
  court_case_id_himachal;
  himachalcasedetail(obj){
    this.himalchalhighcourtservice.postData("caseDetails",obj).subscribe((res)=>{
      if(res.status){
        this.court_case_id_himachal = res.caseDetails.id;
      }
    })
  }

  caseStatushimachal={};
  caseStatusHimachal(){
    this.caseStatushimachal={};
    this.MatSprnnerOfSection=true;
      this.himalchalhighcourtservice.postData('caseStatus',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
        if(res.status){
          this.caseStatushimachal=res.caseStatus;
          this.MatSprnnerOfSection=false;
        }else{
          this.MatSprnnerOfSection=false;
          this.msgfornodata=res.message
        }
      },(err)=>{
        console.log(err)
      })
    }
  partyDetailhimachal={};
  partyDetailHimachal(){
    this.partyDetailhimachal={};
    this.MatSprnnerOfSection=true;
      this.himalchalhighcourtservice.postData('partyDetail',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
        if(res.status){
          this.partyDetailhimachal=res.partyDetail;
          this.MatSprnnerOfSection=false;
        }else{
          this.MatSprnnerOfSection=false;
          this.msgfornodata=res.message
        }
      },(err)=>{
        console.log(err)
      })
  }
  actsDetailhimachal=[];
  actsDetailHimachal(){
    this.actsDetailhimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('actsDetails',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.actsDetailhimachal.push(...res.actsDetails);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  mainMattershimachal=[];
  mainMattersHimachal(){
    this.actsDetailhimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('mainMatters',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.mainMattershimachal.push(...res.mainMatters);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  connectedCaseshimachal=[];
  connectedCasesHimachal(){
    this.connectedCaseshimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('connectedCases',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.connectedCaseshimachal.push(...res.connectedCases);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  applicationCaseshimachal=[];
  applicationCasesHimachal(){
    this.applicationCaseshimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('applicationCases',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.applicationCaseshimachal.push(...res.applicationCases);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  listingDateshimachal=[];
  listingDatesHimachal(){
    this.listingDateshimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('listingDates',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.listingDateshimachal.push(...res.listingDates);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  orderJudgementhimachal=[];
  orderJudgementHimachal(){
    this.orderJudgementhimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('orderJudgement',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.orderJudgementhimachal.push(...res.orderJudgement);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  lowerCourtDetailhimachal=[];
  lowerCourtDetailHimachal(){
    this.lowerCourtDetailhimachal=[];
    this.MatSprnnerOfSection=true;
    this.himalchalhighcourtservice.postData('lowerCourtDetail',{court_case_id:this.court_case_id_himachal}).subscribe((res)=>{
      if(res.status){
        this.lowerCourtDetailhimachal.push(...res.lowerCourtDetail);
        this.MatSprnnerOfSection=false;
      }else{
        this.MatSprnnerOfSection=false;
        this.msgfornodata=res.message
      }
    },(err)=>{
      console.log(err)
    })
  }
  // himachal high court api end here
}