import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HimachalhighcourtService {

  public HimachalHighCourtUrl = environment.socketConfiguration.HimachalHighCourtUrl;

  constructor(public http: HttpClient) { }

  postData(url: any, data: any):Observable<any> {  
    return this.http.post(this.HimachalHighCourtUrl + url, data);
  }

  getData(url: any):Observable<any> {  
    return this.http.get(this.HimachalHighCourtUrl + url);
  }
}
