import { Component, Input, OnInit } from '@angular/core';
import { trackByRoute } from '../../utils/track-by';
import { NavigationService } from '../../services/navigation.service';
import icRadioButtonChecked from '@iconify/icons-ic/twotone-radio-button-checked';
import icRadioButtonUnchecked from '@iconify/icons-ic/twotone-radio-button-unchecked';
import { LayoutService } from '../../services/layout.service';
import { environment } from "src/environments/environment";
import { SocketService } from 'src/app/services/core/socket.service';
import { responseMessages } from 'src/app/services/core/service.constants';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  environment= environment;

  items = this.navigationService.items;
  trackByRoute = trackByRoute;
  icRadioButtonChecked = icRadioButtonChecked;
  icRadioButtonUnchecked = icRadioButtonUnchecked;
  private socketObject = null;

  public allowedModuleKeys;
  public roleDetails;
  public companydetail

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private socketService: SocketService,) {
    
    this.socketObject = this.socketService.getSocketObject();
  }

  ngOnInit() {
    let tmpObj = this;
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listRoleModules, {}, function (socketResponse) {
        if (socketResponse.status == responseMessages.status.ok) {
          tmpObj.roleDetails = socketResponse.roleDetails;
          environment.roleDetails = socketResponse.roleDetails;
          if (socketResponse.roleDetails.allowedAccesses) {
            tmpObj.allowedModuleKeys = Object.keys(socketResponse.roleDetails.allowedAccesses);
          } else {
            tmpObj.allowedModuleKeys = [];
          }
          if ( tmpObj.roleDetails.name !== 'super_admin' ) {
            tmpObj.filterSidenav(tmpObj);
          }
        }
      });

      tmpObj.socketObject.emit(environment.socketEvents.getCompanydetail, function (socketResponse) {

        if(socketResponse.status == responseMessages.status.ok){
          tmpObj.companydetail = socketResponse.data[0];

        }
    // companydetail
    
      });
    
    }
  }

  filterSidenav(tmpObj: SidenavComponent) {
    if ( tmpObj.allowedModuleKeys ) {
      let finalItems = [], childrenItems = [];

      for ( let i=0; i < tmpObj.items.length; i++ ) {
        if ( tmpObj.items[i]['key'] == 'dashboard' ) {
          finalItems.push(tmpObj.items[i]);
        } else if ( tmpObj.items[i].type !== 'dropdown' ) {
          if ( tmpObj.allowedModuleKeys.indexOf(tmpObj.items[i]['key']) != -1 ) {
            finalItems.push(tmpObj.items[i]);
          }
        } else {
          childrenItems = [];
          for (let j=0; j < tmpObj.items[i]['children'].length; j++ ) {
            if ( tmpObj.allowedModuleKeys.indexOf(tmpObj.items[i]['children'][j]['key']) != -1 ) {
              childrenItems.push(tmpObj.items[i]['children'][j]);
            }
          }
          if ( childrenItems.length > 0 ) {
            tmpObj.items[i]['children'] = childrenItems;
            finalItems.push(tmpObj.items[i]);
          }
        }
      }
      
      tmpObj.items = finalItems;
    }
  }

  onMouseEnter() {
    this.layoutService.collapseOpenSidenav();
  }

  onMouseLeave() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }
}
