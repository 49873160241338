import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TribunalService {

  tribunalUrl = environment.socketConfiguration.tribunalUrl

  constructor(private http:HttpClient) { }

  getData(url):Observable<any>{
    return this.http.get(this.tribunalUrl + url)
  }

  postData(url,data):Observable<any>{
    return this.http.post(this.tribunalUrl + url,data)
  }
}
