import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icNotes from '@iconify/icons-ic/twotone-notes';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';

@Component({
  selector: 'vex-court-case-internal-sharing',
  templateUrl: './court-case-internal-sharing.component.html',
  styleUrls: ['./court-case-internal-sharing.component.scss']
})
export class CourtCaseInternalSharingComponent implements OnInit {

  private socketObject;
  selectedFileName;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  public sectionNames = {
    "petition_docs": "Petition Docs",
    "supporting_docs": "Supporting Docs",
  };
  
  public frmDocDescription: FormGroup;
  @ViewChild("docDescriptionDialogRef", {static: false}) docDescriptionDialogTempRef:TemplateRef<any>;
  public descDescriptionDiaglogRef: MatDialogRef<any>;
  private selectedDocIndex = -1;

  public permissionLevels = environment.permissionLevels;

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icEdit = icEdit;
  icNotes = icNotes;

  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icDeleteForever = icDeleteForever;

  usersList = [];

  alreadySharedUsers = {};

  constructor(@Inject(MAT_DIALOG_DATA) public details: any,
    private dialogRef: MatDialogRef<CourtCaseInternalSharingComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _layoutService: LayoutService,
    private commonServices: CommonService,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if ( this.details && this.details.userPermissions ) {
      this.mode = 'update';
    } else {
      this.details.userPermissions = {};
    }

    if ( this.details.courtCase && this.details.courtCase.internalSharing ) {
      for ( let i=0; i < this.details.courtCase.internalSharing.length; i++ ) {
        this.alreadySharedUsers[this.details.courtCase.internalSharing[i].userId] = this.details.courtCase.internalSharing[i].userId;
      }
    }

    if(this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listUsersRoleUser, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          console.log(socketResponse.data,"socketResponse.data")
          tmpObj.usersList = [];
          for ( let i=0; i < socketResponse.data.length; i++ ) {
            if ( !tmpObj.alreadySharedUsers[socketResponse.data[i]._id] ) {
              tmpObj.usersList.push(socketResponse.data[i]);
            }
          }
        }
      });
    }
    

    this.form = this.fb.group({
      _id: this.details.userPermissions._id || '',
      userId: [this.details.userPermissions.userId ? this.details.userPermissions.userId : "", Validators.required],
      permissionLevel: [this.details.userPermissions.permissionLevel || '', Validators.required],
      remarks: [this.details.userPermissions.remarks || '', Validators.required],
      filePermissions: [this.details.userPermissions.filePermissions || []],
    });

    let courtCaseDetils = this.details.courtCase;
    if ( this.isCreateMode() ) {
      //Initializing file permission array with the avaialable file in case
      this.form.value.filePermissions = [];
      if ( courtCaseDetils ) {
        if(  courtCaseDetils.petition_docs && courtCaseDetils.petition_docs.docs ) {
          for ( let i=0; i < courtCaseDetils.petition_docs.docs.length; i++ ) {
            this.form.value.filePermissions.push({
              name: courtCaseDetils.petition_docs.docs[i].name,
              filePath: courtCaseDetils.petition_docs.docs[i].filePath,
              sectionKey: "petition_docs",
              permissions: {
                view: false,
                modify: false
              }
            });
          }
        }
        if(  courtCaseDetils.supporting_docs && courtCaseDetils.supporting_docs.docs ) {
          for ( let i=0; i < courtCaseDetils.supporting_docs.docs.length; i++ ) {
            this.form.value.filePermissions.push({
              name: courtCaseDetils.supporting_docs.docs[i].name,
              filePath: courtCaseDetils.supporting_docs.docs[i].filePath,
              sectionKey: "supporting_docs",
              permissions: {
                view: false,
                modify: false
              }
            });
          }
        }
      }
    } else {
      // if ( courtCaseDetils ) {
      //   if(  courtCaseDetils.petition_docs && courtCaseDetils.petition_docs.docs ) {
      //     for ( let i=0; i < courtCaseDetils.petition_docs.docs.length; i++ ) {
      //       this.form.value.filePermissions.push({
      //         name: courtCaseDetils.petition_docs.docs[i].name,
      //         filePath: courtCaseDetils.petition_docs.docs[i].filePath,
      //         sectionKey: "petition_docs",
      //         permissions: {
      //           view: false,
      //           modify: false
      //         }
      //       });
      //     }
      //   }
      //   if(  courtCaseDetils.supporting_docs && courtCaseDetils.supporting_docs.docs ) {
      //     for ( let i=0; i < courtCaseDetils.supporting_docs.docs.length; i++ ) {
      //       this.form.value.filePermissions.push({
      //         name: courtCaseDetils.supporting_docs.docs[i].name,
      //         filePath: courtCaseDetils.supporting_docs.docs[i].filePath,
      //         sectionKey: "supporting_docs",
      //         permissions: {
      //           view: false,
      //           modify: false
      //         }
      //       });
      //     }
      //   }
      // }
    }
    this.form.get("filePermissions").setValue(this.form.value.filePermissions);

  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      let invalidFields = this.commonServices.findInvalidControls(this.form);
      let isFormInvalid = true;
      if ( invalidFields && invalidFields.length == 1 ) {
        if ( invalidFields[0] == "docs" && this.form.value.docs && this.form.value.docs.length > 0 ) {
          isFormInvalid = false;
        }
      }
      
      if ( isFormInvalid ) {
        this.snackBar.open('Please provide all required information.', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        return;
      }
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  getObjectKeys(object) {
    let keys = [];

    if ( object ) {
      keys = Object.keys(object);
    }

    return keys;
  }
}
