import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NcltCourtService {
  ncltCourtUrl = environment.socketConfiguration.ncltCourt
  
  constructor(private http:HttpClient) { }

  getval(url):Observable<any>{
    return this.http.get(this.ncltCourtUrl + url)
  }

  postData(url,data):Observable<any>{
    return this.http.post(this.ncltCourtUrl + url,data)
  }

}
