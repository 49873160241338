import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icPhoneInTalk from '@iconify/icons-ic/twotone-phone-in-talk';
import icLocationCity from '@iconify/icons-ic/twotone-location-city';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icMoney from '@iconify/icons-ic/twotone-money';
import icCalendarToday from '@iconify/icons-ic/twotone-calendar-today';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MatSnackBar } from '@angular/material';
import icadd_box from '@iconify/icons-ic/twotone-add-box';
import icdelete_forever from '@iconify/icons-ic/twotone-delete-forever';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';
import { ValidationService } from 'src/app/services/core/validation.service';
import { CustomValidators } from 'src/app/custom_validator/validator';

@Component({
  selector: 'vex-advocate-firm-create-update',
  templateUrl: './advocate-firm-create-update.component.html',
  styleUrls: ['./advocate-firm-create-update.component.scss'],
  providers: [CustomValidators],
})
export class AdvocateFirmCreateUpdateComponent implements OnInit {

  private socketObject;

  public billingTypeOptions = [
    { name: "Monthly", value: "Monthly" },
    { name: "Lumpsum", value: "Lumpsum" },
    { name: "Hearing", value: "Hearing" },
  ];

  icadd_box = icadd_box;
  icdelete_forever = icdelete_forever;

  public userTypes = [
    { name: "Individual", value: "individual" },
    { name: "Firm", value: "firm" }
  ];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icPhone = icPhone;
  icPhoneInTalk = icPhoneInTalk;
  icLocationCity = icLocationCity;
  icCalendarToday = icCalendarToday;
  icMoney = icMoney;


  icPerson = icPerson;
  icEditLocation = icEditLocation;

  public designationsList = [];
  public courtsList = [];
  public yearOptions = [];

  public countriesList = []
  // { _id: "33ca048a106e0e8a6a9413", name: "india" };
  public statesList = [];
  public citiesList = [];
  public rolesList = [];

  constructor(@Inject(MAT_DIALOG_DATA) public advocateFirmDetails: any,
    private dialogRef: MatDialogRef<AdvocateFirmCreateUpdateComponent>,
    private fb: FormBuilder,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackBar: MatSnackBar,
    private customValidators: CustomValidators,
    private validationService: ValidationService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    this.yearOptions = this.commonServices.getYearsDropdown();
  }

  ngOnInit() {
    if (this.advocateFirmDetails) {
      this.mode = 'update';
    } else {
      this.advocateFirmDetails = {} as User;
    }

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listsDesignations, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.designationsList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listCourt, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtsList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.getCountries, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.countriesList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listRoles, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.rolesList = socketResponse.data;
        }
      });
    }

    this.form = this.fb.group({
      _id: [this.advocateFirmDetails._id || ''],
      userType: [this.advocateFirmDetails.userType || '', Validators.required],
      firstName: [this.advocateFirmDetails.firstName || '', [Validators.required, this.customValidators.nameValidator]],

      barCouncilNo: [this.advocateFirmDetails.barCouncilNo || ''],
      bankName: [this.advocateFirmDetails.bankName || '', this.customValidators.nameValidator],
      bankBranch: [this.advocateFirmDetails.bankBranch || ''],
      bankAccNumber: [this.advocateFirmDetails.bankAccNumber || ''],
      ifscCode: [this.advocateFirmDetails.ifscCode || ''],
      panNumber: [this.advocateFirmDetails.panNumber || '',this.customValidators.panvalidator],
      gstin: [this.advocateFirmDetails.gstin || ''],
      // , Validators.pattern('^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]1}[1-9A-Z]{1}Z[0-9A-Z]{1}$')

      lastName: [this.advocateFirmDetails.lastName || '', Validators.required],
      primaryMobile: [this.advocateFirmDetails.primaryMobile || '', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      secondaryMobile: [this.advocateFirmDetails.secondaryMobile || ''],
      whatsappNumber: [this.advocateFirmDetails.whatsappNumber || '', Validators.pattern('[6-9]\\d{9}')],
      email: [this.advocateFirmDetails.email || '', [Validators.required, Validators.email], Validators.composeAsync([this.validationService.existingEmailValidation()])],
      password: [this.advocateFirmDetails.password || ''],
      country: [this.advocateFirmDetails.country || null, Validators.required],
      state: [this.advocateFirmDetails.state || null, Validators.required],
      city: [this.advocateFirmDetails.city || null, Validators.required],
      zip: [this.advocateFirmDetails.zip || '', [Validators.required, Validators.minLength(6), Validators.maxLength(6), this.customValidators.zipValidator]],
      firmName: [this.advocateFirmDetails.firmName || '', Validators.required],
      firmAddress: [this.advocateFirmDetails.firmAddress || '', Validators.required],
      practiceCourt: [this.advocateFirmDetails.practiceCourt ? this.advocateFirmDetails.practiceCourt._id : null, Validators.required],
      firmAdvocates: this.fb.array([]),
      billingType: [this.advocateFirmDetails.billingType || '', Validators.required],
      appointmentYear: [parseInt(this.advocateFirmDetails.appointmentYear) || '', Validators.required],
      role: [this.advocateFirmDetails.role || '', Validators.required],
    });


    if (this.isUpdateMode()) {
      this.form.get("appointmentYear").updateValueAndValidity();
      this.form.get("email").disable({ onlySelf: true });
      this.form.get("email").clearValidators();
      this.form.get("email").updateValueAndValidity();
    }

    if (this.isUpdateMode()) {
      if (this.advocateFirmDetails.firmAdvocates) {
        for (let i = 0; i < this.advocateFirmDetails.firmAdvocates.length; i++) {
          this.addFirmAvocateControls(this.advocateFirmDetails.firmAdvocates[i]);
        }
      }
    }

    this.userTypeHandler(true);


    this.countryChangeHandler();
    this.stateChangeHandler();
  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    if (!customer.imageSrc) {
      customer.imageSrc = 'assets/img/avatars/1.jpg';
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {

    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }
    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  private getFirmAvocateControls(advocateDetails) {
    return this.fb.group({
      '_id': [advocateDetails._id || null],
      'firstName': [advocateDetails.firstName || null, Validators.compose([Validators.required, this.customValidators.nameValidator])],
      'lastName': [advocateDetails.lastName || null, [Validators.required, this.customValidators.nameValidator]],
      'designation': [advocateDetails.designation || null, Validators.required],
      'email': [advocateDetails.email || null, [Validators.required, Validators.email]],
      'mobileNumber': [advocateDetails.mobileNumber || null, [Validators.required, Validators.pattern('[6-9]\\d{9}')]]
    });
  }

  // add new row
  private addFirmAvocateControls(advocateDetails) {
    const control = <FormArray>this.form.controls['firmAdvocates'];
    control.push(this.getFirmAvocateControls(advocateDetails));
  }

  // remove row
  private removeFirmAvocateControls(i: number) {
    const control = <FormArray>this.form.controls['firmAdvocates'];
    control.removeAt(i);
  }

  public userTypeHandler(isManualTrigger) {
    if (this.form.value.userType == "firm") {
      if (isManualTrigger == false) {
        const control = <FormArray>this.form.controls['firmAdvocates'];
        control.push(this.getFirmAvocateControls({}));
      }

      this.removeIndividualControlsValidation(); //to dynamically remove individual validations
      this.addFirmControlsValidation(); //to dynamically add firm validations
    } else {

      this.removeFirmControlsValidation(); // to dynamically remove firm validations
      this.addIndividualControlsValidation(); // to dynamically add individual validations
      const control = <FormArray>this.form.controls['firmAdvocates'];
      for (let i = 0; i < control.length; i++) {
        control.removeAt(i);
      }
    }
  }

  /* To Remove Validations from firm controls */
  removeFirmControlsValidation() {
    this.form.get("firmName").clearValidators();
    this.form.get("firmName").updateValueAndValidity();

    this.form.get("firmAddress").clearValidators();
    this.form.get("firmAddress").updateValueAndValidity();

    this.form.get("practiceCourt").clearValidators();
    this.form.get("practiceCourt").updateValueAndValidity();
  }

  /* To Add Validations from firm controls */
  addFirmControlsValidation() {
    this.form.get("firmName").setValidators([Validators.required, this.customValidators.nameValidator]);
    this.form.get("firmName").updateValueAndValidity();

    this.form.get("firmAddress").setValidators([Validators.required, this.customValidators.addressValidator]);
    this.form.get("firmAddress").updateValueAndValidity();

    this.form.get("practiceCourt").setValidators([Validators.required]);
    this.form.get("practiceCourt").updateValueAndValidity();
  }

  /* To Remove Validations from Individual controls */
  removeIndividualControlsValidation() {
    this.form.get("firstName").clearValidators();
    this.form.get("firstName").updateValueAndValidity();

    this.form.get("lastName").clearValidators();
    this.form.get("lastName").updateValueAndValidity();
  }

  /* To Add Validations from Individual controls */
  addIndividualControlsValidation() {
    this.form.get("firstName").setValidators([Validators.required, this.customValidators.nameValidator]);
    this.form.get("firstName").updateValueAndValidity();

    this.form.get("lastName").setValidators([Validators.required, this.customValidators.nameValidator]);
    this.form.get("lastName").updateValueAndValidity();
  }

  countryChangeHandler() {
    if (this.form.value.country) {
      let tmpObj = this;
      this.citiesList = [];
      this.socketObject.emit(environment.socketEvents.getStates, {
        country: this.form.value.country
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.statesList = socketResponse.data;
        }
      });
    }
  }

  stateChangeHandler() {
    if (this.form.value.state) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getCities, {
        state: this.form.value.state
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.citiesList = socketResponse.data;
        }
      });
    }
  }
}
