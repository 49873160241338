import { Component, Inject, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MatSnackBar } from '@angular/material';
import icHorizontalSplit from '@iconify/icons-ic/twotone-horizontal-split';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { CustomValidators } from 'src/app/custom_validator/validator';

@Component({
  selector: 'vex-hearings-create-update',
  templateUrl: './hearings-create-update.component.html',
  styleUrls: ['./hearings-create-update.component.scss'],
  providers: [CustomValidators]
})
export class HearingsCreateUpdateComponent implements OnInit {
  private socketObject;

  public courtCasesList = [];
  public courtsList = [];
  public advocatesList = [];
  public courtDetail;

  @Input() courtCaseDetails;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icHorizontalSplit = icHorizontalSplit;
  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;

  icPerson = icPerson;
  icEditLocation = icEditLocation;

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#ffffff',
      buttonColor: '#5C77FF'
    },
    dial: {
      dialBackgroundColor: '#5C77FF',
    },
    clockFace: {
      clockFaceBackgroundColor: '#5C77FF',
      clockHandColor: '	#D3D3D3',
      clockFaceTimeInactiveColor: '#fff',

    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public userDetails: any,
    private dialogRef: MatDialogRef<HearingsCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private customValidators: CustomValidators,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {
  
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.courtCasesForList, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtCasesList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listCourt, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtsList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.advocatesList = socketResponse.data;
        }
      });


    }

   

    if (this.userDetails && this.userDetails._id) {
      this.mode = 'update';

    } else {
     
      if (this.userDetails && this.userDetails.courtCase) {
      
       
        this.userDetails.attorneyDetails = this.userDetails.courtCase.advocateAssigned?this.userDetails.courtCase.advocateAssigned:'';
      if(this.userDetails.courtCase.courtDetails){
        this.userDetails.court = this.userDetails.courtCase.courtDetails.courtNumber?this.userDetails.courtCase.courtDetails.courtNumber:this.userDetails.courtCase.courtDetails.courtNumber;
        this.userDetails.judgeName = this.userDetails.courtCase.courtDetails.judgeName?this.userDetails.courtCase.courtDetails.judgeName:this.userDetails.courtCase.courtDetails.judgeName;
      }
     
        console.log(this.userDetails,"userDetails")
       
      } else {
        this.userDetails = {};
      }
    }
   
    // console.log(this.form.value,"this.form")
   

    this.form = this.fb.group({
      _id: this.userDetails._id || '',
      courtCase: [this.userDetails.courtCase ? this.userDetails.courtCase._id : '', Validators.required],
      attorneyDetails: [this.userDetails.attorneyDetails ? this.userDetails.attorneyDetails._id : null, Validators.required],
      topic: [this.userDetails.topic ? this.userDetails.topic : '', Validators.required],
      court: [this.userDetails.court ? this.userDetails.court : '', Validators.required],
      judgeName: [this.userDetails.judgeName || '', [Validators.required, this.customValidators.nameValidator]],
      nextHearingDate: [this.userDetails.nextHearingDate || new Date(), Validators.required],
      nextHearingTime: [this.userDetails.nextHearingDate || ''],
      briefNote: [this.userDetails.briefNote || '', Validators.required]
    });

   

  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    if (!customer.imageSrc) {
      customer.imageSrc = 'assets/img/avatars/1.jpg';
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    customer.id = this.userDetails.id;

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  getAdvocateName(advocateFirmDetails) {
    let name = "N/A";
    if (advocateFirmDetails) {
      if (advocateFirmDetails.userType == "firm") {
        name = advocateFirmDetails.firmName;
      } else {
        name = advocateFirmDetails.firstName + " " + advocateFirmDetails.lastName;
      }
    }
    return name;
  }

  caseNumberChangeHandler() {
    if (this.form.value.courtCase) {
      let tmpObj = this;
      tmpObj.socketObject.emit(environment.socketEvents.courtCasesForList, {
        court: this.form.value.courtCase
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtDetail = socketResponse.data;
          tmpObj.form.get('court').setValue(tmpObj.courtDetail[0].courtDetails.courtNumber);
          tmpObj.form.get('judgeName').setValue(tmpObj.courtDetail[0].courtDetails.judgeName);
          tmpObj.form.get('attorneyDetails').setValue(tmpObj.courtDetail[0].advocateAssigned._id)


        }
      });




    }
  }
}
