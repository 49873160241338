import { Injectable } from '@angular/core';
import { SocketService } from './socket.service';
import { environment } from 'src/environments/environment';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private socketObject;
  
  constructor( private socketService: SocketService, private commonServices:CommonService ) { }

  public isAuthenticated(currentRouteName, routeKey): Promise<any> {
    const token = this.commonServices.getAccessToken();
    if(token) {
      if( !this.socketService.isSocketConnected() ) {
          this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
    let tmpObj = this;
    var promise = new Promise((resolve, reject) => {
      if(tmpObj.socketService.isSocketConnected()) {
        tmpObj.socketObject.emit( environment.socketEvents.authenticateUser, { 'currentRouteName':currentRouteName, 'routeKey': routeKey }, function(socketResponse) {
          if(socketResponse.status == 'OK') {
            resolve(true);
          } else if ( socketResponse.status == "UNAUTHORIZED" ) {
            tmpObj.commonServices.logoutUser();
            location.reload();
          } else {
            resolve(false);
          }
        });
      }
    });
    return promise;
  }

}
