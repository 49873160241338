import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CourtcaseService } from '../../addcourtcase/courtcase.service';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icPrint from '@iconify/icons-ic/twotone-print';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/core/common.service';
import { MatSnackBar } from '@angular/material';
import { DistrictService } from '../district.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { HistorydetailComponent } from '../historydetail/historydetail.component';
// import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'vex-districtcourtdetail',
  templateUrl: './districtcourtdetail.component.html',
  styleUrls: ['./districtcourtdetail.component.scss']
})
export class DistrictcourtdetailComponent implements OnInit {
  private socketObject;
  caseid = ''
  casedetails: any;
  casestatusdetails: any;
  casepetionordetails: any;
  caseresponedentdetails: any;
  caseactdetails: any;
  casehistorydetails: any;
  caseordersdetails: any;

  icEdit = icEdit;
  icPrint = icPrint;
  datadetail: any;
  titlename: any;
  caseTransferInfo: any;
  caseSubordinateCourtInfo: any;
  casefirDetailInfo: any;
  caseSubMatterInfo: any;
  caseMainMatterInfo: any;
  caseProcessInfo: any;
  detaildata: any;
  case_type_case_number_case_year:any;

  constructor(private route: ActivatedRoute,private court :CourtcaseService,@Inject(MAT_DIALOG_DATA) data,private districtcourt: DistrictService, private socketService: SocketService,private commonServices: CommonService,private snackBar: MatSnackBar,private dialog: MatDialog) {
    if(data){
      this.datadetail = data
      // this.caseid=data.caseId;
     }
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
   }

  ngOnInit() {

  //   this.route.queryParamMap.subscribe((params:any )=> {console.log(params)
  //     this.caseid = params.params.case_id
  //     console.log(this.caseid,"after route")
  // })
  // "state_code": "26",
// "district_code": "10",
// "forum_id": "2",
// "case_no_year": "207600000102022",
// "case_cino": "DLST020000562022"
console.log(this.datadetail.district_code2)

  let obj={
    state_code:this.datadetail.state_code,
    district_code:this.datadetail.district_code,
    forum_id:this.datadetail.forum_id,
    case_no_year:this.datadetail.case_no_year,
    case_cino:this.datadetail.case_cino,
    court_status:String(this.datadetail.court_status)
    
  
  }
  
  console.log(obj,"obj")
  let detaildata 
  this.districtcourt.postecourt('ws/eCourtCase',obj).subscribe((res2: any) => {
    console.log(res2,"rsffff")
    if(res2.status){
      detaildata = res2.courtInfo
      this.case_type_case_number_case_year=detaildata.case_type_case_number_case_year;
      this.titlename = detaildata.establishment_name


      console.log(detaildata,'detaildata')
      this.caseid = detaildata.id
      //this.caseid ='5'
      if(this.caseid){
        this.getcasedetais();
        this.getcasestatusdetais();
        this.getpetinordetais();
        this.getrepondentdetais();
        this.getactdetais();
        this.getCaseHistorydetais();
        this.getCaseordersdetais();
        this.getfirdetail();
        this.getsubordinatedetail();
        this.getcasetransferdetail();
        this.getprocessdetail();
        this.getsubmetterdetail();
        this.getmainmetterdetail();

        this.districtcourt.postecourt('ws/eAutoUpdateCourtCase',obj).subscribe((res2: any) => {
                   console.log(res2,"check response")
                 if(res2.status){
                   // let detaildata
                   if(res2.autoUpdateHighCourtCase){
                    this.detaildata = res2.autoUpdateHighCourtCase

                   }
                   }
               })
      }
     
    }
  })




 
}

getcasedetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.districtcourt.postecourt('ws/eCaseDetail',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casedetails= res2.caseDetailsInfo
     this.casedetails['case_type_case_number_case_year']=this.case_type_case_number_case_year
  })

}


getcasestatusdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  console.log(obj,"obj")
  // https://api.mysita.com/public/ws/courtNotice
  this.districtcourt.postecourt('ws/eGetCaseStatus',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casestatusdetails= res2.caseStatusInfo

  })

}


getpetinordetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.districtcourt.postecourt('ws/eGetCasePetitionerAdv',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casepetionordetails= res2.casePetitionerAdvInfo
  })

}


getrepondentdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.districtcourt.postecourt('ws/eGetCaseRespondentAdv',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseresponedentdetails= res2.caseRespondentAdvInfo
  })

}

getactdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.districtcourt.postecourt('ws/eGetCaseActs',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseactdetails= res2.caseActsInfo
  })

}


getcasetransferdetail(){

  let obj = {
    "court_case_id":this.caseid 
  }
  this.districtcourt.postecourt('ws/eGetCaseCourtTransfer',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseTransferInfo= res2.caseTransferInfo
  })
}


getsubmetterdetail(){

  let obj = {
    "court_case_id":this.caseid 
  }
  this.districtcourt.postecourt('ws/eGetCaseSubMatter',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseSubMatterInfo= res2.caseSubMatterInfo
  })
}


getmainmetterdetail(){

  let obj = {
    "court_case_id":this.caseid 
  }
  this.districtcourt.postecourt('ws/eGetCaseMainMatter',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseMainMatterInfo= res2.caseMainMatterInfo
  })
}

getsubordinatedetail(){

  let obj = {
    "court_case_id":this.caseid 
  }
  this.districtcourt.postecourt('ws/eGetCaseSubordinateCourtInfo',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseSubordinateCourtInfo= res2.caseSubordinateCourtInfo
  })
}


getfirdetail(){

  let obj = {
    "court_case_id":this.caseid 
  }
  this.districtcourt.postecourt('ws/eGetCasefirDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casefirDetailInfo= res2.casefirDetailInfo
  })
}

getprocessdetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  this.districtcourt.postecourt('ws/eGetCaseProcessDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseProcessInfo= res2.caseProcessInfo
  })
}

getCaseHistorydetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.districtcourt.postecourt('ws/eGetCaseHistory',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casehistorydetails= res2.caseHistoryInfo
  })

}

getCaseordersdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
 
  this.districtcourt.postecourt('ws/eGetCaseOrders',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseordersdetails= res2.caseOrderInfo
  })

}



savecase(){
  // console.log(this.courtcaselist,"this.columns")
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.casedetails.fourm = "districtcourt"
      this.detaildata['court_status']=this.datadetail.court_status
      this.detaildata['district_code']=this.datadetail.district_code2

      this.detaildata['state_code']=this.datadetail.state_code2
      this.detaildata['case_type_id']=this.datadetail.case_type_category_id
      console.log(this.detaildata,'this.detaildata')
      // this.detaildata['district_code']=this.datadetail.district_code

      this.socketObject.emit(environment.socketEvents.createDistrictCourtCase, this.detaildata, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
            duration: 4000
          });

        

          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
}


openhearing(data){
  this.dialog.open(HistorydetailComponent,{
    height: '500px',
    width: '800px',
    autoFocus: false,
    data:data
    });
}


}
