import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Route, UrlSegment, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { Observable, Subscriber } from 'rxjs';
import { AuthService } from '../services/core/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AfterLoginGuard implements CanActivate, CanLoad, CanActivateChild {

  constructor(private authService: AuthService, private router: Router) {

  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!sessionStorage.getItem('access_token')) {
      this.router.navigate(["/login"]);
    }
    return new Observable((subscriber: Subscriber<boolean>) => {
      this.authService.isAuthenticated(state.url, childRoute.data.key).then((isAuthorized) => {
        subscriber.next(isAuthorized);
      })
        .catch((err) => {
          subscriber.next(false);
        });
      // console.log("---------------",childRoute.url, state.url, this.authService.isAuthenticated);
    });
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return true;
  }

  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }
}
