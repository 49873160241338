export const responseMessages = {
    'invalidParams' : ()=>{
        return {'status':'INVALID', 'message':'Invalid Params','reason':'Wrong details provided.'};
    },
    'rpcDetailsNotFound' : ()=>{ 
        return {'status':'ERROR', 'message':'Oops! Something went wrong', 'reason':'RPC details not found'};
    },
    'error' : ()=>{
        return {'status':'ERROR', 'message':'Oops! Something went wrong', 'reason':''};
    },
    'ok' : ()=>{
        return {'status':'OK', 'message': 'Successfully Executed'};
    },
    'notFound': ()=>{
        return { 'status':'NOT_FOUND', 'message':'Not found' };
    },
    'userExist': ()=>{
        return { 'status':'USER_EXIST', 'message':'User Already Exist' };
    },
    'status':{
        'ok':'OK',
        'error':'ERROR',
        'invalidParams':'INVALID',
        'notFound':'NOT_FOUND',
        'userExist':'USER_EXIST'
    }
};