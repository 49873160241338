import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { responseMessages } from './service.constants';
import { HttpHeaders } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { ScrumboardComponent } from 'src/app/pages/cases/scrumboard/scrumboard.component';
import icLayers from '@iconify/icons-ic/twotone-layers';
import icUserLayers from '@iconify/icons-ic/twotone-verified-user';
import icGroupLayers from '@iconify/icons-ic/twotone-group';
import icBusinessLayers from '@iconify/icons-ic/twotone-business';
import icCalendarLayers from '@iconify/icons-ic/twotone-calendar-today';
import icNoteAddLayers from '@iconify/icons-ic/twotone-note-add';
import icWarningLayers from '@iconify/icons-ic/twotone-warning';
import icBookLayers from '@iconify/icons-ic/twotone-book';
import icSecurityLayers from '@iconify/icons-ic/twotone-security';
import icActionLayers from '@iconify/icons-ic/twotone-call-to-action';
import icPagesLayers from '@iconify/icons-ic/twotone-pages';
import icPayment from '@iconify/icons-ic/twotone-payment';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  private baseUrl: string = environment.serviceConfiguration.url;
  private services = environment.serviceConfiguration.servicesEndPoints;

  private scrumboardComponentInstance: ScrumboardComponent;

  private loggedInUserDetails;

  private userDetailsUpdated = new BehaviorSubject<any>({});

  constructor(private http: HttpClient, private socketService: SocketService) { }

  /**
   * executeRequest
   */

  private async executeRequest(serviceUrl, reqMethod, data, authCode) {
    let response = responseMessages.invalidParams();
    const headers = new HttpHeaders()
      .set("Content-Type", "application/json");
    if (authCode) {
      headers.set('Authorization', authCode);
    }

    await this.http.request(reqMethod, this.baseUrl + serviceUrl, {
      body: data,
      headers: headers
    }).toPromise().then(function (serviceResponse: any) {
      response = serviceResponse;
    }).catch(function (err) {
      response = responseMessages.error();
      response.reason = err.message;
    });
    return response;
  }

  public async loginUser(userDetails) {
    let response: any = responseMessages.invalidParams();
    if (userDetails) {
      let serviceDetails = this.services.loginUser;
      await this.executeRequest(serviceDetails.url, serviceDetails.method, userDetails, '').then(function (loginUserResponse: any) {
        response = loginUserResponse;
      });
    }
    return response;
  }

  public async getOtpToLogin(userDetails) {
    let response: any = responseMessages.invalidParams();
    if (userDetails) {
      let serviceDetails = this.services.getOtpForLogin;
      await this.executeRequest(serviceDetails.url, serviceDetails.method, userDetails, '').then(function (getOtpResponse: any) {
        response = getOtpResponse;
      });
    }
    return response;
  }

  public async loginUserUsingOtp(userDetails) {
    let response: any = responseMessages.invalidParams();
    if (userDetails) {
      let serviceDetails = this.services.loginUserUsingOtp;
      await this.executeRequest(serviceDetails.url, serviceDetails.method, userDetails, '').then(function (loginUserResponse: any) {
        response = loginUserResponse;
      });
    }
    return response;
  }

  public async getExternalShareCaseDetail(accessToken, caseType) {
    let response: any = responseMessages.invalidParams();
    if (accessToken && caseType) {
      let serviceDetails = this.services.externalSharingCaseDetail;
      await this.executeRequest(serviceDetails.url, serviceDetails.method, {
        accessToken: accessToken,
        caseType: caseType
      }, '').then(function (externalShareCaseDetailResponse: any) {
        response = externalShareCaseDetailResponse;
      });
    }
    return response;
  }

  public async forgotPassword(emailAddress) {
    let response: any = responseMessages.invalidParams();
    if (emailAddress) {
      let serviceDetails = this.services.forgotPassword;
      await this.executeRequest(serviceDetails.url, serviceDetails.method, { email: emailAddress }, '').then(function (forgotPasswordResponse: any) {
        if (forgotPasswordResponse.status == responseMessages.status.ok) {
          response = responseMessages.ok();
        } else {
          response = forgotPasswordResponse;
        }
      });
    }
    return response;
  }

  public async changePassword(newPassword, uniqueCode) {
    let response: any = responseMessages.invalidParams();
    if (newPassword && uniqueCode) {
      let serviceDetails = this.services.changePassword;
      await this.executeRequest(serviceDetails.url, serviceDetails.method, { newPassword: newPassword, uniqueCode }, '').then(function (changePasswordResp: any) {
        response = changePasswordResp;
      });
    }
    return response;
  }

  public getAccessToken() {
    // return localStorage.getItem('access_token');
    return sessionStorage.getItem('access_token')
  }

 
  public setAccessToken(accessToken) {
    // return localStorage.setItem('access_token', accessToken);
    return sessionStorage.setItem('access_token', accessToken)
  }

  public findInvalidControls(form: FormGroup) {
    const invalid = [];
    if (form) {
      const controls = form.controls;
      for (const name in controls) {
        if (controls[name].invalid) {
          invalid.push(name);
        }
      }
    }
    return invalid;
  }

  public setScrumboardComponentInstance(scrumboardComponentInstance) {
    this.scrumboardComponentInstance = scrumboardComponentInstance;
  }

  public getScrumboardComponentInstance() {
    return this.scrumboardComponentInstance;
  }

  public setLoggedInUserDetails(loggedInUserDetails) {
    this.loggedInUserDetails = loggedInUserDetails;
    this.userDetailsUpdated.next(loggedInUserDetails);
  }

  public getUserDetailsUpdateObserver() {
    return this.userDetailsUpdated;
  }

  public async initLoggedInUserDetails() {
    let tmpObj = this;
    await new Promise((resolve, reject) => {
      const token = tmpObj.getAccessToken();
      let socketObj;
      if (token) {
        if (!this.socketService.isSocketConnected()) {
          this.socketService.initSocket(token);
        }
        socketObj = this.socketService.getSocketObject();
      }
      if (socketObj) {
        socketObj.emit(environment.socketEvents.getLoggedInUserDetails, {}, function (socketResponse) {
          resolve(socketResponse)
        });
      } else {
        reject({})
      }
    }).then((socketResponse: any) => {
      if (socketResponse.status == 'OK') {
        environment.currentUserRole = socketResponse.data.role;
        tmpObj.setLoggedInUserDetails(socketResponse.data);
      }
    }).catch((err) => {
    });
  }

  public getLoggedInUserDetails() {
    return this.loggedInUserDetails;
  }

  public logoutUser() {
    // localStorage.removeItem("access_token");
    sessionStorage.removeItem("access_token");

  }

  public getYearsDropdown() {
    let currentYear = (new Date()).getFullYear();
    let fromYear = 1990;
    let yearsDropdown = [];

    for (let i = currentYear; i >= fromYear; i--) {
      yearsDropdown.push(i);
    }

    return yearsDropdown;
  }

  isCourtInHouseUserAssigned(inHouseUsersList, userId) {
    let status = false;

    if (inHouseUsersList && userId) {
      for (let i = 0; i < inHouseUsersList.length; i++) {
        if (inHouseUsersList[i]._id == userId) {
          status = true;
          break;
        }
      }
    }

    return status;
  }

  //Check wheather editing for this court case is allowed for current logged in user or not
  public isUserAllowedToEditCourtCase(courtCaseDetails, moduleDetails) {
    let isAllowed = false;
    console.log("this part is running",courtCaseDetails,moduleDetails)

    if (courtCaseDetails && environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
     
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (courtCaseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(moduleDetails.subModules.update) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
        
          if (this.isCourtInHouseUserAssigned(courtCaseDetails.inHouseAdvocate, this.loggedInUserDetails._id)) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally
            let sharingDetails;
            if (courtCaseDetails.internalSharing) {
              for (let i = 0; i < courtCaseDetails.internalSharing.length; i++) {
                if (courtCaseDetails.internalSharing[i].userId == this.loggedInUserDetails._id) {
                  sharingDetails = courtCaseDetails.internalSharing[i];
                  break;
                }
              }
            }

            if (sharingDetails) {
              if (sharingDetails.permissionLevel == environment.permissionLevels.editable.key || sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }


  public isUserAllowedToEditArbitration(courtCaseDetails, moduleDetails) {
    let isAllowed = false;
    if (environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        let subModulesKey = [];
       
        if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
          subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          console.log(subModulesKey,"moduleDetails")
        }
        if (subModulesKey.indexOf(moduleDetails.subModules.update) != -1) {
          isAllowed = true;
        }
      }
    }

    return isAllowed;
  }

  public isUserAllowedToaddhearingArbitrationCase(moduleDetails){
   
    let isAllowed = false;
    if (environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        console.log(environment.roleDetails['allowedAccesses'],"moduleDetails of hearing")
        console.log(moduleDetails.key,"environment.roleDetails['allowedAccesses'][moduleDetails.key]")
        let subModulesKey = [];
       
        if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
          subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          console.log(subModulesKey,"moduleDetails")
        }
        if (subModulesKey.indexOf(moduleDetails.subModules.create) != -1) {
          isAllowed = true;
        }
      }
    }

    return isAllowed;
  }

  //Check wheather delete operation for this court case is allowed for current logged in user or not
  public isUserAllowedToDeleteCourtCase(courtCaseDetails, moduleDetails) {
    let isAllowed = false;

    if (courtCaseDetails && environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (courtCaseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(moduleDetails.subModules.delete) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
          if (this.isCourtInHouseUserAssigned(courtCaseDetails.inHouseAdvocate, this.loggedInUserDetails._id)) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally
            let sharingDetails;
            for (let i = 0; i < courtCaseDetails.internalSharing.length; i++) {
              if (courtCaseDetails.internalSharing[i].userId == this.loggedInUserDetails._id) {
                sharingDetails = courtCaseDetails.internalSharing[i];
                break;
              }
            }
            if (sharingDetails) {
              if (sharingDetails.permissionLevel == environment.permissionLevels.editable.key || sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  //Check wheather create operation for this court case is allowed for current logged in user or not
  public isUserAllowedToCreateCourtCase(moduleDetails) {
   
    let isAllowed = false;

    if (environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        let subModulesKey = [];
       
        if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
          subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          console.log(subModulesKey,"moduleDetails")
        }
        if (subModulesKey.indexOf(moduleDetails.subModules.create) != -1) {
          isAllowed = true;
        }
      }
    }

    return isAllowed;
  }

  public isUserAllowedToCreateArbitration(moduleDetails) {
   
    let isAllowed = false;

    if (environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        let subModulesKey = [];
       
        if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
          subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          console.log(subModulesKey,"moduleDetails")
        }
        if (subModulesKey.indexOf(moduleDetails.subModules.create) != -1) {
          isAllowed = true;
        }
      }
    }

    return isAllowed;
  }

  //Check wheather editing for this mutual case is allowed for current logged in user or not
  public isUserAllowedToEditMutualCase(mutualCaseDetails, moduleDetails) {
    let isAllowed = false;

    if (mutualCaseDetails && environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (mutualCaseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(moduleDetails.subModules.update) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
          if (mutualCaseDetails.inHouseAdvocate && mutualCaseDetails.inHouseAdvocate._id == this.loggedInUserDetails._id) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally
            let sharingDetails;
            for (let i = 0; i < mutualCaseDetails.internalSharing.length; i++) {
              if (mutualCaseDetails.internalSharing[i].userId == this.loggedInUserDetails._id) {
                sharingDetails = mutualCaseDetails.internalSharing[i];
                break;
              }
            }

            if (sharingDetails) {
              if (sharingDetails.permissionLevel == environment.permissionLevels.editable.key || sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  //Check wheather delete operation for this mutual case is allowed for current logged in user or not
  public isUserAllowedToDeleteMutualCase(mutualCaseDetails, moduleDetails) {
    let isAllowed = false;

    if (mutualCaseDetails && environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (mutualCaseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(moduleDetails.subModules.delete) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
          if (mutualCaseDetails.inHouseAdvocate && mutualCaseDetails.inHouseAdvocate._id == this.loggedInUserDetails._id) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally
            let sharingDetails;
            for (let i = 0; i < mutualCaseDetails.internalSharing.length; i++) {
              if (mutualCaseDetails.internalSharing[i].userId == this.loggedInUserDetails._id) {
                sharingDetails = mutualCaseDetails.internalSharing[i];
                break;
              }
            }
            if (sharingDetails) {
              if (sharingDetails.permissionLevel == environment.permissionLevels.editable.key || sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  //Check wheather create operation for this court case is allowed for current logged in user or not
  public isUserAllowedToCreateMutualCase(moduleDetails) {
    let isAllowed = false;

    if (environment.roleDetails && this.loggedInUserDetails && moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        let subModulesKey = [];
        if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][moduleDetails.key]) {
          subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][moduleDetails.key].subModules);
        }
        if (subModulesKey.indexOf(moduleDetails.subModules.create) != -1) {
          isAllowed = true;
        }
      }
    }

    return isAllowed;
  }

  public getIconForModule(moduleKey) {
    let icon = icBusinessLayers;

    switch (moduleKey) {
      case "dashboard":
        icon = icLayers;
        break;
      case "users_management":
        icon = icUserLayers;
        break;
      case "tasks_management":
        icon = icUserLayers;
        break;
      case "roles_management":
        icon = icUserLayers;
        break;
      case "advocate_firms":
        icon = icBusinessLayers;
        break;
      case "inward_notice_dropbox":
        icon = icUserLayers;
        break;
      case "legal_notices":
        icon = icUserLayers;
        break;
      case "billing":
        icon = icPayment;
        break;
      case "court_cases":
        icon = icPagesLayers;
        break;
      case "mutual_cases":
        icon = icPagesLayers;
        break;
      case "legal_docs":
        icon = icBookLayers;
        break;
      case "appointments_management":
        icon = icNoteAddLayers;
        break;
      case "hearings_management":
        icon = icWarningLayers;
        break;
      case "calendar_view":
        icon = icCalendarLayers;
        break;
      case "judgements_management":
        icon = icSecurityLayers;
        break;
      case "bare_acts_management":
        icon = icActionLayers;
        break;
      case "case_abbreviation_management":
        icon = icPagesLayers;
        break;
      case "court_types_management":
        icon = icBookLayers;
        break;
      case "court_management":
        icon = icBookLayers;
        break;
      case "judgement_area_management":
        icon = icSecurityLayers;
        break;
      case "division_management":
        icon = icActionLayers;
        break;
      case "department_management":
        icon = icActionLayers;
        break;
      case "designation_management":
        icon = icActionLayers;
        break;
      case "project_sites_management":
        icon = icActionLayers;
        break;
    }

    return icon;
  }

  //is Civil Matter Case
  isCivilMatterCase(val) {
    let is = false;

    if (val == environment.constants.natureOfCase.civilMatter.value) {
      is = true;
    }

    return is;
  }

  //is Criminal Case
  isCriminalCase(val) {
    // console.log(val,"value of env")
   
    let is = false;

    if (val == environment.constants.natureOfCase.criminal.value) {
      is = true;
      // console.log(environment.constants.natureOfCase.criminal.name,"envriment value")
    }

    return is;
  }

  //is Arbitration Case
  isArbitrationCase(val) {
    let is = false;

    if (val == environment.constants.natureOfCase.arbitration.value) {
      is = true;
    }

    return is;
  }

}
