import { Component, OnInit, Input } from '@angular/core';
import { Notification } from '../interfaces/notification.interface';
import { DateTime } from 'luxon';
import { trackById } from '../../../utils/track-by';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import icClearAll from '@iconify/icons-ic/twotone-clear-all';
import icShoppingBasket from '@iconify/icons-ic/twotone-shopping-basket';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icInsertChart from '@iconify/icons-ic/twotone-insert-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icDescription from '@iconify/icons-ic/twotone-description';
import icFeedback from '@iconify/icons-ic/twotone-feedback';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icFileCopy from '@iconify/icons-ic/twotone-file-copy';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { PopoverRef } from '../../popover/popover-ref';
import { ToolbarNotificationsComponent } from '../toolbar-notifications.component';

@Component({
  selector: 'vex-toolbar-notifications-dropdown',
  templateUrl: './toolbar-notifications-dropdown.component.html',
  styleUrls: ['./toolbar-notifications-dropdown.component.scss']
})
export class ToolbarNotificationsDropdownComponent implements OnInit {

  static popoverRef: PopoverRef;
  static toolbarNotificationComponentRef: ToolbarNotificationsComponent;

  notifications: Notification[] = [];

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;
  
  private socketObject;

  constructor(
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackbar: MatSnackBar,
    private router: Router,
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
    
  }

  ngOnInit() {
    this.refreshNotificaion(this);
  }

  refreshNotificaion(tmpObj) {
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listNotifications, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.notifications = [];
          let tmp;
          for ( let i = 0; i < socketResponse.data.length; i++ ) {
            tmp = socketResponse.data[i];
            tmp.icon = icCheckCircle;
            tmpObj.notifications.push(tmp);
          }
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
  }

  markAllNotificationRead() {
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.markAllNotificationRead, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          ToolbarNotificationsDropdownComponent.toolbarNotificationComponentRef.refreshNotificaion(ToolbarNotificationsDropdownComponent.toolbarNotificationComponentRef);
          ToolbarNotificationsDropdownComponent.popoverRef.close();
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
  }


  notificationRedirect(notificationDetails:Notification) {
    if ( notificationDetails && this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.markSpecificNotificationRead, notificationDetails, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          ToolbarNotificationsDropdownComponent.toolbarNotificationComponentRef.refreshNotificaion(ToolbarNotificationsDropdownComponent.toolbarNotificationComponentRef);
          ToolbarNotificationsDropdownComponent.popoverRef.close();
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });

      this.router.navigate([notificationDetails.redirectsTo]);
    }
  }

}
