import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { CustomValidators } from 'src/app/custom_validator/validator';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import icClose from '@iconify/icons-ic/twotone-close';
import { MatSnackBar, MatTabChangeEvent } from '@angular/material';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icPlus from '@iconify/icons-ic/twotone-plus';
import icconfirmation_number from '@iconify/icons-ic/twotone-confirmation-number';
import icPageView from '@iconify/icons-ic/twotone-pageview';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icnote_add from '@iconify/icons-ic/twotone-note-add';
import icaccount_balance from '@iconify/icons-ic/twotone-account-balance';
import iclocation_city from '@iconify/icons-ic/twotone-location-city';
import icgavel from '@iconify/icons-ic/twotone-gavel';
import icassignment_ind from '@iconify/icons-ic/twotone-assignment-ind';
import icphone from '@iconify/icons-ic/twotone-phone';
import icperson_pin from '@iconify/icons-ic/twotone-person-pin';
import icplace from '@iconify/icons-ic/twotone-place';
import icmoney from '@iconify/icons-ic/twotone-money';
import iccalendar_today from '@iconify/icons-ic/twotone-calendar-today';
import icdetails from '@iconify/icons-ic/twotone-details';
import icscatter_plot from '@iconify/icons-ic/twotone-scatter-plot';
import iccategory from '@iconify/icons-ic/twotone-category';
import icdonut_small from '@iconify/icons-ic/twotone-donut-small';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import icDescription from '@iconify/icons-ic/twotone-description';
// import { MatSnackBar, MatTabChangeEvent } from '@angular/material';
// import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { appModulesList } from 'src/environments/appModulesList';
import {
  map,
  startWith
} from 'rxjs/operators';
import { CustomValidators } from 'src/app/custom_validator/validator';
import { User } from '../../interfaces/court-cases.model';


@Component({
  selector: 'vex-arbitration-create-update',
  templateUrl: './arbitration-create-update.component.html',
  styleUrls: ['./arbitration-create-update.component.scss',

 
 ],
  animations: [fadeInUp400ms],
  providers: [CustomValidators]
})
export class ArbitrationCreateUpdateComponent implements OnInit {
  public moduleDetails = appModulesList.courtCaseManagement;
  selectedFileName;
  private socketObject;

  public selectedTabIndex: number = 0;
  private selectedDocIndex = -1;
  public frmOrderDate: FormGroup;
  public orderDateDiaglogRef;
  @ViewChild("orderDocDateTemplateRef", { static: false })
  orderDocDateTemplateRef;
  private totalTabGroups: number = 9;

  public isFirmSelected$ = new BehaviorSubject<boolean>(false);

  public subAdvocatesList = [];
  public caseTypeList = [];
  public divisionsList = [];
  public designationsList = [];
  public allProjectSitesList = [];
  public projectSitesList = [];
  public regionsList = [];
  public courtsList = [];

  public courtTypesList = [];
  public courtTypesAliasList = {};

  public advocatesList = [];
  public legalNoticesList = [];
  public judgementTypeList = [];
  public inHouseUsersList = [];
  public yearOptions = [];
  public departmentsList = [];

  public tmpInHouseUsersList = [];

  public countriesList = [];
  public indiaStateList = [];
  public petitionerStatesList = [
    []
  ];
  public petitionerCitiesList = [
    []
  ];
  public courtCitiesList = [
    []
  ];
  public responderStatesList = [
    []
  ];
  public responderCitiesList = [
    []
  ];

  public petitionerName = "Petitioner";
  public responderName = "Respondent";

  isCaseNatureCriminal = false;

  public billingTypeOptions = [
    { name: "Monthly", value: "Monthly" },
    { name: "Lumpsum", value: "Lumpsum" },
    { name: "Hearing", value: "Hearing" },
  ];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;
  icnote_add = icnote_add;

  icPrint = icPrint;
  icDownload = icDownload;
  icDeleteForever = icDeleteForever;
  icDescription = icDescription;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icPlus = icPlus;
  icPageView = icPageView;
  icconfirmation_number = icconfirmation_number;
  icaccount_balance = icaccount_balance;
  iclocation_city = iclocation_city;
  icgavel = icgavel;
  icassignment_ind = icassignment_ind;
  icphone = icphone;
  icperson_pin = icperson_pin;
  icplace = icplace;
  icmoney = icmoney;
  iccalendar_today = iccalendar_today;
  icdetails = icdetails;
  icscatter_plot = icscatter_plot;
  iccategory = iccategory;
  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icdonut_small = icdonut_small;

  public courtNameState;
  public courtNameCity;

  public userDetails;

  public sharingDetails;

  public isCourtNameVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isComplexNameVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCustomCourtNameVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isBenchNumberVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public isCourtNumberVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  petitionerAutoOptions: Observable<any[]>;
  petitionerNameSuggestions: any[] = [];
  petitionerNameSuggestionsAlias: any = {};
  tmpPetitionerNameControl = new FormControl();

  constructor(@Inject(MAT_DIALOG_DATA) public courtCaseDetails: any,
    private dialogRef: MatDialogRef<ArbitrationCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _layoutService: LayoutService,
    private commonServices: CommonService,
    private customValidators: CustomValidators,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    this.yearOptions = this.commonServices.getYearsDropdown();

    this.userDetails = this.commonServices.getLoggedInUserDetails();
  }

  ngOnInit() {

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;

      this.socketObject.emit(environment.socketEvents.listCaseAbbreviation, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.caseTypeList = socketResponse.data;
        }
      });
      this.socketObject.emit(environment.socketEvents.listsDesignations, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.designationsList = socketResponse.data;
        }
      });
      this.socketObject.emit(environment.socketEvents.listsDivisions, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.divisionsList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.getApiRegionsProjects, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.regionsList = socketResponse.regionsList;
          tmpObj.allProjectSitesList = socketResponse.projectSiteList;

          if (tmpObj.isUpdateMode()) {
            tmpObj.regionsChangeHandler();
          }
        }
      });

      this.socketObject.emit(environment.socketEvents.listCourtType, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtTypesList = socketResponse.data;
          for (let i = 0; i < tmpObj.courtTypesList.length; i++) {
            tmpObj.courtTypesAliasList[tmpObj.courtTypesList[i]._id] = tmpObj.courtTypesList[i].name;
          }
        }
      });

      this.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.advocatesList = socketResponse.data;

          if (tmpObj.isUpdateMode()) {
            // tmpObj.advocateChangeHandler();
          }
        }
      });

      tmpObj.socketObject.emit(environment.socketEvents.listLegalNotice, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.legalNoticesList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listJudgementType, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.judgementTypeList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listUsersRoleUser, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.inHouseUsersList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.getCountries, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.countriesList = socketResponse.data;
        }
      });



      this.socketObject.emit(environment.socketEvents.getIndiaStates, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.indiaStateList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listDepartments, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.departmentsList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listCourtCasesPeitionerAutoComplete, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.petitionerNameSuggestions = socketResponse.data;
          tmpObj.petitionerNameSuggestionsAlias = socketResponse.aliases;
        }
      });
    }

    if (this.courtCaseDetails) {
      this.mode = 'update';
    } else {
      this.courtCaseDetails = {} as User;
    }

    let tmpInHouseAdvocate = [];
    this.tmpInHouseUsersList = []
    if (this.courtCaseDetails.inHouseAdvocate && typeof this.courtCaseDetails.inHouseAdvocate == 'object') {
      for (let i = 0; i < this.courtCaseDetails.inHouseAdvocate.length; i++) {
        tmpInHouseAdvocate.push(this.courtCaseDetails.inHouseAdvocate[i]._id);
        this.tmpInHouseUsersList.push(this.courtCaseDetails.inHouseAdvocate[i]._id);
      }
    }

    this.form = this.fb.group({
      _id: this.courtCaseDetails._id || null,
      courtCaseType: [this.courtCaseDetails.courtCaseType || "", Validators.required],
      hasLegalNotice: [this.courtCaseDetails.hasLegalNotice || false, Validators.required],
      legalNotice: [this.courtCaseDetails.legalNotice || null],
      typeOfCreditors: [this.courtCaseDetails.typeOfCreditors || null],
      caseNature: [this.courtCaseDetails.caseNature ? this.courtCaseDetails.caseNature._id : null],

      firNumber: [this.courtCaseDetails.firNumber || null],
      firFillingDate: [this.courtCaseDetails.firFillingDate || null],
      policeStation: [this.courtCaseDetails.policeStation || null],
      investigationOfficerName: [this.courtCaseDetails.investigationOfficerName || null, this.customValidators.nameValidator],
      nameOfComplainant: [this.courtCaseDetails.nameOfComplainant || null, this.customValidators.nameValidator],
      nameOfAccusedPeople: [this.courtCaseDetails.nameOfAccusedPeople || null, this.customValidators.nameValidator],
      sectionDescription: [this.courtCaseDetails.sectionDescription || null],
      firUnderSection: [this.courtCaseDetails.firUnderSection || null],
      prosecutorName: [this.courtCaseDetails.prosecutorName || null, this.customValidators.nameValidator],

      purpose: [this.courtCaseDetails.purpose || null],
      arbitratorAssigned: [this.courtCaseDetails.arbitratorAssigned || null],
      arbitratorDescription: [this.courtCaseDetails.arbitratorDescription || null],
      caseDescription: [this.courtCaseDetails.caseDescription || null, Validators.required],
      fillingDate: [this.courtCaseDetails.fillingDate || null, Validators.required],
      caseNumber: [this.courtCaseDetails.caseNumber || null, Validators.required],
      caseType: [this.courtCaseDetails.caseType ? (this.courtCaseDetails.caseType._id ? this.courtCaseDetails.caseType._id : this.courtCaseDetails.caseType) : null, Validators.required],
      caseYear: [parseInt(this.courtCaseDetails.caseYear) || null, Validators.required],
      petitioner: this.fb.array([]),
      responder: this.fb.array([]),
      contactPerson: this.fb.array([]),
      advocateAssigned: [this.courtCaseDetails.advocateAssigned ? this.courtCaseDetails.advocateAssigned._id : ""],
      // inHouseAdvocate: [tmpInHouseAdvocate],
      billingType: [this.courtCaseDetails.billingType || '', Validators.required],
      totalAmount: [this.courtCaseDetails.totalAmount || ''],
      courtDetails: this.fb.group({
        courtName: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.courtName || null : null],
        complexName: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.complexName || null : null, this.customValidators.nameValidator],
        customCourtName: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.customCourtName || null : null, this.customValidators.nameValidator],
        courtType: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.courtType || null : null],
        state: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.state || null : null,],
        district: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.district || null : null],
        courtNumber: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.courtNumber || null : null],
        benchNumber: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.benchNumber || null : null],
        judgeName: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.judgeName || null : null, this.customValidators.nameValidator],
        forums: [this.courtCaseDetails.courtDetails ? this.courtCaseDetails.courtDetails.forumsName || null : null],

      }),

      /* Upload Document: Start */
      pleadingDocs: [this.courtCaseDetails.pleadingDocs ? this.courtCaseDetails.pleadingDocs : []],
      evidenceDocs: [this.courtCaseDetails.evidenceDocs ? this.courtCaseDetails.evidenceDocs : []],
      plaintiffOrDefendantDocs: [this.courtCaseDetails.plaintiffOrDefendantDocs ? this.courtCaseDetails.plaintiffOrDefendantDocs : []],
      applicationStayInjunctionDocs: [this.courtCaseDetails.applicationStayInjunctionDocs ? this.courtCaseDetails.applicationStayInjunctionDocs : []],
      ordersDocs: [this.courtCaseDetails.ordersDocs ? this.courtCaseDetails.ordersDocs : []],
      miscellaneousDocs: [this.courtCaseDetails.miscellaneousDocs ? this.courtCaseDetails.miscellaneousDocs : []],
      claimantRespondentDocs: [this.courtCaseDetails.claimantRespondentDocs ? this.courtCaseDetails.claimantRespondentDocs : []],
      /* Upload Document: End */

      petition_docs: this.fb.group({
        docs: [this.courtCaseDetails.petition_docs ? this.courtCaseDetails.petition_docs.docs || [] : null],
        description: [this.courtCaseDetails.petition_docs ? this.courtCaseDetails.petition_docs.description || null : null],

        claimentOrClaimDocs: [(this.courtCaseDetails.petition_docs && this.courtCaseDetails.petition_docs.claimentOrClaimDocs) ? this.courtCaseDetails.petition_docs.claimentOrClaimDocs : []],
        rejoinerDocs: [(this.courtCaseDetails.petition_docs && this.courtCaseDetails.petition_docs.rejoinerDocs) ? this.courtCaseDetails.petition_docs.rejoinerDocs : []],
        admissionDenialDocs: [(this.courtCaseDetails.petition_docs && this.courtCaseDetails.petition_docs.admissionDenialDocs) ? this.courtCaseDetails.petition_docs.admissionDenialDocs : []],
        evidenceDocs: [(this.courtCaseDetails.petition_docs && this.courtCaseDetails.petition_docs.evidenceDocs) ? this.courtCaseDetails.petition_docs.evidenceDocs : []],
        writtenArgumentDocs: [(this.courtCaseDetails.petition_docs && this.courtCaseDetails.petition_docs.writtenArgumentDocs) ? this.courtCaseDetails.petition_docs.writtenArgumentDocs : []],

        crimimalCaseStages: this.fb.array([]),
      }),
      supporting_docs: this.fb.group({
        docs: [this.courtCaseDetails.supporting_docs ? this.courtCaseDetails.supporting_docs.docs || [] : null],
        description: [this.courtCaseDetails.supporting_docs ? this.courtCaseDetails.supporting_docs.description || null : null],

        respondentOrCounterClaimDocs: [(this.courtCaseDetails.supporting_docs && this.courtCaseDetails.supporting_docs.respondentOrCounterClaimDocs) ? this.courtCaseDetails.supporting_docs.respondentOrCounterClaimDocs : []],
        surrejoinerDocs: [(this.courtCaseDetails.supporting_docs && this.courtCaseDetails.supporting_docs.surrejoinerDocs) ? this.courtCaseDetails.supporting_docs.surrejoinerDocs : []],
        admissionDenialDocs: [(this.courtCaseDetails.supporting_docs && this.courtCaseDetails.supporting_docs.admissionDenialDocs) ? this.courtCaseDetails.supporting_docs.admissionDenialDocs : []],
        evidenceDocs: [(this.courtCaseDetails.supporting_docs && this.courtCaseDetails.supporting_docs.evidenceDocs) ? this.courtCaseDetails.supporting_docs.evidenceDocs : []],
        writtenArgumentDocs: [(this.courtCaseDetails.supporting_docs && this.courtCaseDetails.supporting_docs.writtenArgumentDocs) ? this.courtCaseDetails.supporting_docs.writtenArgumentDocs : []],

        crimimalCaseStages: this.fb.array([]),
      })
    });

    this.hasLegalNoticeChangeHandler();

    /* Populate Contact Person Data: Start */
    if (this.courtCaseDetails && this.courtCaseDetails.contactPerson && this.courtCaseDetails.contactPerson.length > 0) {
      for (let i = 0; i < this.courtCaseDetails.contactPerson.length; i++) {
        this.addContactPersonArrUnit(this.courtCaseDetails.contactPerson[i]);
      }
    } else {
      this.addContactPersonArrUnit({});
    }
    /* Populate Contact Person Data: End */

    /* Populate Petitioner Data: Start */
    if (this.courtCaseDetails && this.courtCaseDetails.petitioner && this.courtCaseDetails.petitioner.length > 0) {
      for (let i = 0; i < this.courtCaseDetails.petitioner.length; i++) {
        this.addPetitionerArrUnit(this.courtCaseDetails.petitioner[i]);
        this.petitionerCountryChangeHandler(i);
        this.petitionerStateChangeHandler(i);
      }
    } else {
      this.addPetitionerArrUnit({});
    }
    /* Populate Petitioner Data: End */

    /* Populate Responder Data: Start */
    if (this.courtCaseDetails && this.courtCaseDetails.responder && this.courtCaseDetails.responder.length > 0) {
      for (let i = 0; i < this.courtCaseDetails.responder.length; i++) {
        this.addResponderArrUnit(this.courtCaseDetails.responder[i]);
        this.responderCountryChangeHandler(i);
        this.responderStateChangeHandler(i);
      }
    } else {
      this.addResponderArrUnit({});
    }
    /* Populate Responder Data: End */


    /* Auto complete for Petitioner's name: Start */
    this.petitionerAutoOptions = this.tmpPetitionerNameControl.valueChanges
      .pipe(
        startWith(''),
        map(value => this._filterPettionerSuggestions(value))
      );
    /* Auto complete for Petitioner's name: End */

    this.initSharingDetails(this);
  }

  private _filterPettionerSuggestions(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.petitionerNameSuggestions.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  save() {
    this.trimFileObjs();
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    customer.id = this.courtCaseDetails.id;

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  nextTab() {
    if (this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex += 1;
    }
  }

  previousTab() {
    if (this.selectedTabIndex > 0 && this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex -= 1;
    }
  }

  tabChanged(tabEvents: MatTabChangeEvent) {
    this.selectedTabIndex = tabEvents.index;
  }

  getAdvocateName(advocateFirmDetails) {
    let name = "N/A";
    if (advocateFirmDetails) {
      if (advocateFirmDetails.userType == "firm") {
        name = advocateFirmDetails.firmName;
      } else {
        name = advocateFirmDetails.firstName + " " + advocateFirmDetails.lastName;
      }
    }
    return name;
  }

  /* Petitioner Multiple Field: Start */
  // add new petitioner
  public addPetitionerArrUnit(petitionerDetails) {
    const control = <FormArray>this.form.controls.petitioner;
    control.push(this.getPetitionerArrayUnit(petitionerDetails));
  }

  // remove petitioner
  public removePetitionerArrUnit(i: number) {
    const control = <FormArray>this.form.controls.petitioner;
    control.removeAt(i);
  }

  // remove all petitioner
  public removeAllPetitionerArrUnit() {
    const control = <FormArray>this.form.controls.petitioner;
    control.clear();
  }

  public getPetitionerArrayUnit(petitionerDetails) {
    return this.fb.group({
      name: [petitionerDetails.name || '', [Validators.required, this.customValidators.nameValidator]],
      address: [petitionerDetails.address || '', [Validators.required, this.customValidators.addressValidator]],
      country: [petitionerDetails.country || '', Validators.required],
      state: [petitionerDetails.state || '', Validators.required],
      city: [petitionerDetails.city || '', Validators.required],
      zip: [petitionerDetails.zip || '', [Validators.required, this.customValidators.zipValidator]],
    });
  }
  /* Petitioner Multiple Field: End */

  /* Contact Person Multiple Field: Start */
  // add new Contact Person
  public addContactPersonArrUnit(contactPersonDetails) {
    const control = <FormArray>this.form.controls.contactPerson;
    control.push(this.getContactPersonArrayUnit(contactPersonDetails));
  }

  // remove Contact Person
  public removeContactPersonArrUnit(i: number) {
    const control = <FormArray>this.form.controls.contactPerson;
    control.removeAt(i);
  }

  public getContactPersonArrayUnit(contactPersonDetails) {
    return this.fb.group({
      name: [contactPersonDetails.name || '', [Validators.required, this.customValidators.nameValidator]],
      designation: [contactPersonDetails.designation || '', Validators.required],
      contactNumber: [contactPersonDetails.contactNumber || '', [Validators.required, Validators.pattern('[6-9]\\d{9}')]],
      email: [contactPersonDetails.email || '', Validators.required],
      contactPersonParty: [contactPersonDetails.contactPersonParty || '', Validators.required],
    });
  }
  /* PContact Person Multiple Field: End */

  /* Responder Multiple Field: Start */
  // add new Responder
  public addResponderArrUnit(responderDetails) {
    const control = <FormArray>this.form.controls.responder;
    control.push(this.getResponderArrayUnit(responderDetails));
  }

  // remove Responder
  public removeResponderArrUnit(i: number) {
    const control = <FormArray>this.form.controls.responder;
    control.removeAt(i);
  }

  // remove all Responder
  public removeAllResponderArrUnit() {
    const control = <FormArray>this.form.controls.responder;
    control.clear();
  }

  public getResponderArrayUnit(responderDetails) {
    return this.fb.group({
      name: [responderDetails.name || '', [Validators.required, this.customValidators.nameValidator]],
      address: [responderDetails.address || '', [Validators.required, this.customValidators.addressValidator]],
      country: [responderDetails.country || '', Validators.required],
      state: [responderDetails.state || '', Validators.required],
      city: [responderDetails.city || '', Validators.required],
      zip: [responderDetails.zip || '', [Validators.required, this.customValidators.zipValidator]],
    });
  }
  /* Responder Multiple Field: End */

  /* Removing or adding constraints based on Contain Legal Notice or Not: Start */
  public hasLegalNoticeChangeHandler() {
    if (this.form.value.hasLegalNotice == true) {

      this.form.get("caseNature").clearValidators();
      this.form.get("caseNature").updateValueAndValidity();

      this.form.get("legalNotice").setValidators([Validators.required]);
      this.form.get("legalNotice").updateValueAndValidity();
    } else {
      this.form.get("legalNotice").clearValidators();
      this.form.get("legalNotice").updateValueAndValidity();

      this.form.get("caseNature").setValidators([Validators.required]);
      this.form.get("caseNature").updateValueAndValidity();

    }
  }
  /* Removing or adding constraints based on Contain Legal Notice or Not: End */

  /* Handler for Legal Notice Change and get that Legal Notice Details: Start */
  public legalNoticeChangeHandler() {
    if (this.form.value.legalNotice) {
      let legalNoticeDetails;
      for (let i = 0; i < this.legalNoticesList.length; i++) {
        if (this.form.value.legalNotice == this.legalNoticesList[i]._id) {
          legalNoticeDetails = this.legalNoticesList[i];
          break;
        }
      }

      this.autoFillDetailsFromLS(legalNoticeDetails);
    }
  }
  /* Handler for Legal Notice Change and get that Legal Notice Details: Start */

  /* auto fill details from selected Legal Notice : Start*/
  private autoFillDetailsFromLS(legalNoticeDetails) {
    if (legalNoticeDetails) {

      if (legalNoticeDetails.petitioner) {
        let hasAnyResponder = false, hasAnyPetitioner = false;

        this.removeAllPetitionerArrUnit();
        this.removeAllResponderArrUnit();

        for (let i = 0; i < legalNoticeDetails.petitioner.length; i++) {

          if (legalNoticeDetails.petitioner[i].partyType == "Noticer") {
            hasAnyPetitioner = true;
            this.addPetitionerArrUnit(legalNoticeDetails.petitioner[i]);
            this.petitionerCountryChangeHandler((<FormArray>this.form.controls.petitioner).length - 1);
            this.petitionerStateChangeHandler((<FormArray>this.form.controls.petitioner).length - 1);
          } else {
            hasAnyResponder = true;
            this.addResponderArrUnit(legalNoticeDetails.petitioner[i]);
            this.responderCountryChangeHandler((<FormArray>this.form.controls.responder).length - 1);
            this.responderStateChangeHandler((<FormArray>this.form.controls.responder).length - 1);
          }
        }

        if (!hasAnyPetitioner) {
          this.addPetitionerArrUnit({});
        }
        if (!hasAnyResponder) {
          this.addResponderArrUnit({});
        }
      }

      if (legalNoticeDetails.case_details && legalNoticeDetails.case_details.regions) {
        this.form.value.regions = legalNoticeDetails.case_details.regions;
        this.form.get("regions").setValue(legalNoticeDetails.case_details.regions);
        this.regionsChangeHandler();
      }
      if (legalNoticeDetails.case_details && legalNoticeDetails.case_details.project_site) {
        this.form.value.project = legalNoticeDetails.case_details.project_site;
        this.form.get("project").setValue(legalNoticeDetails.case_details.project_site);
      }
    }
  }
  /* auto fill details from selected Legal Notice : End*/

  regionsChangeHandler() {
    if (this.form.value.regions) {
      this.projectSitesList = [];
      if (this.allProjectSitesList[this.form.value.regions]) {
        this.projectSitesList = this.projectSitesList.concat(this.allProjectSitesList[this.form.value.regions])
      }
    }
  }

  petitionerCountryChangeHandler(index) {
    if (this.form.value.petitioner[index].country) {
      let tmpObj = this;
      this.petitionerCitiesList[index] = [];
      this.socketObject.emit(environment.socketEvents.getStates, {
        country: this.form.value.petitioner[index].country
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.petitionerStatesList[index] = socketResponse.data;
        }
      });
    }
  }

  petitionerStateChangeHandler(index) {
    if (this.form.value.petitioner[index].state) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getCities, {
        state: this.form.value.petitioner[index].state
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.petitionerCitiesList[index] = socketResponse.data;
        }
      });
    }
  }

  responderCountryChangeHandler(index) {
    if (this.form.value.responder[index].country) {
      let tmpObj = this;
      this.responderCitiesList[index] = [];
      this.socketObject.emit(environment.socketEvents.getStates, {
        country: this.form.value.responder[index].country
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.responderStatesList[index] = socketResponse.data;
        }
      });
    }
  }

  responderStateChangeHandler(index) {
    if (this.form.value.responder[index].state) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getCities, {
        state: this.form.value.responder[index].state
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.responderCitiesList[index] = socketResponse.data;
        }
      });
    }
  }

  downloadFile(docDetails) {
    if (docDetails) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      if (docDetails.fileObj) {
        link.setAttribute('href', URL.createObjectURL(docDetails.fileObj));
      } else {
        let accessToken = this.commonServices.getAccessToken();
        link.setAttribute('href', environment.serviceConfiguration.url + docDetails.filePath.substring(1) + "?access_token=" + accessToken);
      }

      link.setAttribute('download', docDetails.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  public removeSupportingDoc(supportDocIndex) {
    if (this.form.value.supporting_docs.docs && supportDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.supporting_docs.docs.splice(supportDocIndex, 1);
        this.form.value.supporting_docs.docs[supportDocIndex].is_deleted = true;
        this.form.value.supporting_docs.docs[supportDocIndex].deletedBy = this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  public removePetitionDoc(petitionDocIndex) {
    if (this.form.value.petition_docs.docs && petitionDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.petition_docs.docs.splice(petitionDocIndex, 1);
        this.form.value.petition_docs.docs[petitionDocIndex].is_deleted = true;
        this.form.value.petition_docs.docs[petitionDocIndex].deletedBy = this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  async petitionFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.docs = this.form.value.petition_docs.docs ? this.form.value.petition_docs.docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadPetitionDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentEnd, (filePath) => {
          tmpObj.form.value.petition_docs.docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.docs").setValue(tmpObj.form.value.petition_docs.docs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async supportingFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.docs = this.form.value.supporting_docs.docs ? this.form.value.supporting_docs.docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.docs").setValue(tmpObj.form.value.supporting_docs.docs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async claimDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.claimentOrClaimDocs = this.form.value.petition_docs.claimentOrClaimDocs ? this.form.value.petition_docs.claimentOrClaimDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadClaimDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadClaimDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadClaimDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadClaimDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadClaimDocumentEnd, (filePath) => {
          tmpObj.form.value.petition_docs.claimentOrClaimDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.claimentOrClaimDocs").setValue(tmpObj.form.value.petition_docs.claimentOrClaimDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async rejoinerDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.rejoinerDocs = this.form.value.petition_docs.rejoinerDocs ? this.form.value.petition_docs.rejoinerDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadRejoinerDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadRejoinerDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadRejoinerDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadRejoinerDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadRejoinerDocumentEnd, (filePath) => {
          tmpObj.form.value.petition_docs.rejoinerDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.rejoinerDocs").setValue(tmpObj.form.value.petition_docs.rejoinerDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async admissionDenialDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.admissionDenialDocs = this.form.value.petition_docs.admissionDenialDocs ? this.form.value.petition_docs.admissionDenialDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadAdmissionDenialDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocEnd, (filePath) => {
          tmpObj.form.value.petition_docs.admissionDenialDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.admissionDenialDocs").setValue(tmpObj.form.value.petition_docs.admissionDenialDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async evidenceDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.evidenceDocs = this.form.value.petition_docs.evidenceDocs ? this.form.value.petition_docs.evidenceDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
          tmpObj.form.value.petition_docs.evidenceDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.evidenceDocs").setValue(tmpObj.form.value.petition_docs.evidenceDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async writtenArgumentDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.writtenArgumentDocs = this.form.value.petition_docs.writtenArgumentDocs ? this.form.value.petition_docs.writtenArgumentDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadWrittenArgumentDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadWrittenArgumentDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadWrittenArgumentDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadWrittenArgumentDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadWrittenArgumentDocEnd, (filePath) => {
          tmpObj.form.value.petition_docs.writtenArgumentDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.writtenArgumentDocs").setValue(tmpObj.form.value.petition_docs.writtenArgumentDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async resCounterClaimDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.respondentOrCounterClaimDocs = this.form.value.supporting_docs.respondentOrCounterClaimDocs ? this.form.value.supporting_docs.respondentOrCounterClaimDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadRespClaimDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadRespClaimDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespClaimDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadRespClaimDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespClaimDocEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.respondentOrCounterClaimDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.respondentOrCounterClaimDocs").setValue(tmpObj.form.value.supporting_docs.respondentOrCounterClaimDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async surrejoinerDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.surrejoinerDocs = this.form.value.supporting_docs.surrejoinerDocs ? this.form.value.supporting_docs.surrejoinerDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadSurrejoinerDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadSurrejoinerDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadSurrejoinerDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadSurrejoinerDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadSurrejoinerDocEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.surrejoinerDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.surrejoinerDocs").setValue(tmpObj.form.value.supporting_docs.surrejoinerDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async resAdmissionDenialDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.admissionDenialDocs = this.form.value.supporting_docs.admissionDenialDocs ? this.form.value.supporting_docs.admissionDenialDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadRespAdmissionDenialDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadRespAdminssionDenialDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespAdminssionDenialDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadRespAdminssionDenialDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespAdminssionDenialDocEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.admissionDenialDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.admissionDenialDocs").setValue(tmpObj.form.value.supporting_docs.admissionDenialDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async resEvidenceDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.evidenceDocs = this.form.value.supporting_docs.evidenceDocs ? this.form.value.supporting_docs.evidenceDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadRespEvidenceDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadRespEvidenceDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespEvidenceDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadRespEvidenceDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespEvidenceDocEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.evidenceDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.evidenceDocs").setValue(tmpObj.form.value.supporting_docs.evidenceDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async respWrittenArgsDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.writtenArgumentDocs = this.form.value.supporting_docs.writtenArgumentDocs ? this.form.value.supporting_docs.writtenArgumentDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadRespWrittenArgDocSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadRespWrittenArgDocNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespWrittenArgDocNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadRespWrittenArgDocEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadRespWrittenArgDocEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.writtenArgumentDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.writtenArgumentDocs").setValue(tmpObj.form.value.supporting_docs.writtenArgumentDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  // async supportingFileControlChangeHandler(fileObj) {
  //   if (fileObj.files[0] && fileObj.files[0].name) {
  //     // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
  //     if (fileObj.files.length > 0) {

  //       this._layoutService.showLoader("Starting File Upload");
  //       this.form.value.supporting_docs.docs = this.form.value.supporting_docs.docs ? this.form.value.supporting_docs.docs : [];
  //       let reader;

  //       reader = new FileReader();
  //       let currentFileIndex = 0;
  //       let tmpObj = this;
  //       let totalNumberOfFiles = fileObj.files.length;
  //       let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //       let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //       reader.readAsArrayBuffer(slice);

  //       reader.onload = () => {
  //         // var arrayBuffer = reader.result;
  //         tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           data: reader.result
  //         }, (socketResponse) => {
  //           if (socketResponse.status == responseMessages.status.ok) {
  //           } else {
  //             tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
  //               duration: 3000,
  //               horizontalPosition: 'center'
  //             });
  //             tmpObj._layoutService.hideLoader();
  //           }
  //         });
  //       };

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
  //         tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
  //         let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
  //         reader.readAsArrayBuffer(slice);
  //       });

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
  //         tmpObj.form.value.supporting_docs.docs.push({
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           filePath: filePath
  //         });

  //         currentFileIndex++;
  //         if (currentFileIndex < totalNumberOfFiles) {
  //           fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //           slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //           reader.readAsArrayBuffer(slice);
  //         } else {
  //           tmpObj.form.get("supporting_docs.docs").setValue(tmpObj.form.value.supporting_docs.docs);
  //           tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
  //             duration: 3000,
  //             horizontalPosition: 'center'
  //           });
  //           tmpObj._layoutService.hideLoader();
  //         }
  //       });
  //     }
  //   }
  // }

  // async resCriminalStageFileControlChangeHandler(fileObj, currentIndex) {
  //   if (fileObj.files[0] && fileObj.files[0].name) {
  //     //this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
  //     if (fileObj.files.length > 0) {

  //       this._layoutService.showLoader("Starting File Upload");
  //       this.form.value.supporting_docs.crimimalCaseStages[currentIndex] = this.form.value.supporting_docs.crimimalCaseStages[currentIndex] ? this.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs : [];
  //       let reader;
  //       reader = new FileReader();
  //       let currentFileIndex = 0;
  //       let tmpObj = this;
  //       let totalNumberOfFiles = fileObj.files.length;
  //       let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //       let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //       reader.readAsArrayBuffer(slice);

  //       reader.onload = () => {
  //         //var arrayBuffer = reader.result;
  //         tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           data: reader.result
  //         }, (socketResponse) => {
  //           if (socketResponse.status == responseMessages.status.ok) {
  //           } else {
  //             tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
  //               duration: 3000,
  //               horizontalPosition: 'center'
  //             });
  //             tmpObj._layoutService.hideLoader();
  //           }
  //         });
  //       };

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
  //         tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
  //         let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
  //         reader.readAsArrayBuffer(slice);
  //       });

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
  //         tmpObj.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs.push({
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           filePath: filePath
  //         });
  //         currentFileIndex++;
  //         if (currentFileIndex < totalNumberOfFiles) {
  //           fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //           slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //           reader.readAsArrayBuffer(slice);
  //         } else {
  //           tmpObj.form.get("supporting_docs.crimimalCaseStages").setValue(tmpObj.form.value.supporting_docs.crimimalCaseStages);
  //           tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
  //             duration: 3000,
  //             horizontalPosition: 'center'
  //           });
  //           tmpObj._layoutService.hideLoader();
  //         }
  //       });
  //     }
  //   }
  // }

  trimFileObjs() {
    if (this.form.value.supporting_docs.docs && this.form.value.supporting_docs.docs.length > 0) {
      for (let i = 0; i < this.form.value.supporting_docs.docs.length; i++) {
        if (this.form.value.supporting_docs.docs[i].fileObj) {
          delete this.form.value.supporting_docs.docs[i]['fileObj'];
        }
      }
    }

    if (this.form.value.petition_docs.docs && this.form.value.petition_docs.docs.length > 0) {
      for (let i = 0; i < this.form.value.petition_docs.docs.length; i++) {
        if (this.form.value.petition_docs.docs[i].fileObj) {
          delete this.form.value.petition_docs.docs[i]['fileObj'];
        }
      }
    }

    if (this.form.value.ordersDocs && this.form.value.ordersDocs.length > 0) {
      for (let i = 0; i < this.form.value.ordersDocs.length; i++) {
        if (this.form.value.ordersDocs[i].fileObj) {
          delete this.form.value.ordersDocs[i]['fileObj'];
        }
      }
    }

    if (this.form.value.miscellaneousDocs && this.form.value.miscellaneousDocs.length > 0) {
      for (let i = 0; i < this.form.value.miscellaneousDocs.length; i++) {
        if (this.form.value.miscellaneousDocs[i].fileObj) {
          delete this.form.value.miscellaneousDocs[i]['fileObj'];
        }
      }
    }

  }

  // advocateChangeHandler() {
  //   if (this.form.value.advocateAssigned) {
  //     let subAdvocates;
  //     for (let i = 0; i < this.advocatesList.length; i++) {
  //       if (this.advocatesList[i]._id == this.form.value.advocateAssigned
  //         && this.advocatesList[i].userType == 'firm') {

  //         subAdvocates = this.advocatesList[i].firmAdvocates;
  //         break;
  //       }
  //     }

  //     if (subAdvocates) {
  //       this.subAdvocatesList = subAdvocates;
  //       this.isFirmSelected$.next(true);
  //     } else {
  //       this.subAdvocatesList = [];
  //       this.isFirmSelected$.next(false);
  //       this.form.get("subAdvocateAssigned").setValue(this.form.value.advocateAssigned);
  //     }
  //   } else {
  //     this.subAdvocatesList = [];
  //     this.isFirmSelected$.next(false);
  //     this.form.get("subAdvocateAssigned").setValue(this.form.value.advocateAssigned);
  //   }
  // }

  isFileViewAllowed() {
    let isAllowed = false;

    if (this.courtCaseDetails && environment.roleDetails && this.userDetails && this.moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (this.courtCaseDetails.createdBy == this.userDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
          if (this.tmpInHouseUsersList && this.tmpInHouseUsersList.indexOf(this.userDetails._id) != -1) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally

            if (this.sharingDetails) {
              if (this.sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnlyWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  initSharingDetails(tmpObj: ArbitrationCreateUpdateComponent) {
    if (tmpObj.courtCaseDetails && tmpObj.courtCaseDetails.internalSharing) {
      for (let i = 0; i < tmpObj.courtCaseDetails.internalSharing.length; i++) {
        if (tmpObj.courtCaseDetails.internalSharing[i].userId == tmpObj.userDetails._id) {
          tmpObj.sharingDetails = tmpObj.courtCaseDetails.internalSharing[i];
          break;
        }
      }
    }
  }

  isUserAllowedForFile(fileDetails) {
    let isAllowed = false;

    if (environment.roleDetails && environment.roleDetails.name == "super_admin") {
      isAllowed = true;
    } else if (this.tmpInHouseUsersList && this.tmpInHouseUsersList.indexOf(this.userDetails._id) != -1) {
      isAllowed = true;
    } else if (fileDetails && this.sharingDetails && this.sharingDetails.filePermissions) {
      if (fileDetails.filePath) {
        for (let i = 0; i < this.sharingDetails.filePermissions.length; i++) {
          if (this.sharingDetails.filePermissions[i] && this.sharingDetails.filePermissions[i].filePath == fileDetails.filePath) {
            if (this.sharingDetails.filePermissions[i].permissions.view == true) {
              isAllowed = true;
            }
            break;
          }
        }
      }
    }

    return isAllowed;
  }

  previewPdfFile(docDetails) {
    if (docDetails) {
      if (docDetails.type == "application/pdf") {
        let filePath: string = docDetails.filePath;
        filePath = filePath.replace("./", environment.serviceConfiguration.url + "/viewPDF/");
        let accessToken = this.commonServices.getAccessToken();
        window.open(filePath + "?access_token=" + accessToken,
          'Legal App PDF View',
          'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=' + screen.availWidth + ',height=' + screen.availHeight);
      } else {
        this.snackBar.open("Oops! You can't preview this file.", "Okay", {
          duration: 2000
        });
      }
    }
  }

  courtTypeChangeHandler() {
    if (this.form.value.courtDetails && this.form.value.courtDetails.courtType) {
      this.form.value.courtDetails.courtName = "";
      if (this.courtTypesAliasList[this.form.value.courtDetails.courtType]) {
        if (/supreme court/i.test(this.courtTypesAliasList[this.form.value.courtDetails.courtType].toLowerCase())) {
          this.isCourtNameVisible.next(false);
          this.isCustomCourtNameVisible.next(false);
          this.isBenchNumberVisible.next(false);
          this.isComplexNameVisible.next(false);
          this.isCourtNumberVisible.next(true);
          this.form.get('courtDetails.state').setValue("5f33cb6b8a106e0e8a6a947b");
          this.courtStateChangeHandler();
          this.form.get('courtDetails.district').setValue("5f33d0048a106e0e8a6a9841");

        } else if (/district/i.test(this.courtTypesAliasList[this.form.value.courtDetails.courtType].toLowerCase())) {
          this.isCourtNameVisible.next(false);
          this.isCustomCourtNameVisible.next(true);
          this.isBenchNumberVisible.next(false);
          this.isComplexNameVisible.next(true);
          this.isCourtNumberVisible.next(true);
        } else if (/NCLT\/NCLAT/i.test(this.courtTypesAliasList[this.form.value.courtDetails.courtType].toLowerCase())
          || /NCLT\/ NCLAT/i.test(this.courtTypesAliasList[this.form.value.courtDetails.courtType].toLowerCase())) {
          this.isCourtNameVisible.next(false);
          this.isCustomCourtNameVisible.next(false);
          this.isBenchNumberVisible.next(true);
          this.isComplexNameVisible.next(false);
          this.isCourtNumberVisible.next(false);
        } else {
          this.isCourtNameVisible.next(true);
          this.isCustomCourtNameVisible.next(false);
          this.isBenchNumberVisible.next(false);
          this.isComplexNameVisible.next(false);
          this.isCourtNumberVisible.next(true);
        }
      }
      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;

        this.socketObject.emit(environment.socketEvents.listCourtsForCourtType, { courtType: tmpObj.form.value.courtDetails.courtType }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.courtsList = socketResponse.data;
          }
        });
      }
    }
  }

  courtStateChangeHandler() {
    if (this.form.value.courtDetails.state) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getCities, {
        state: this.form.value.courtDetails.state
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtCitiesList = socketResponse.data;
        }
      });

    }
  }

  // petitionerCountryChangeHandler(index) {
  //   if (this.form.value.petitioner[index].country) {
  //     let tmpObj = this;
  //     this.petitionerCitiesList[index] = [];
  //     this.socketObject.emit(environment.socketEvents.getStates, {
  //       country: this.form.value.petitioner[index].country
  //     }, function (socketResponse) {
  //       if (socketResponse.status == 'OK') {
  //         tmpObj.petitionerStatesList[index] = socketResponse.data;
  //       }
  //     });
  //   }
  // }

  //Petitioner change handler
  petitionerNameChangeHander(event) {
    this.tmpPetitionerNameControl.setValue(event.target.value);
  }

  peitionerAutoCompleteOptionSelected(selectedOption, petitionerControlsIndex) {
    if (selectedOption && this.petitionerNameSuggestionsAlias[selectedOption]) {
      this.form.controls.petitioner['controls'][petitionerControlsIndex].get('name').setValue(this.petitionerNameSuggestionsAlias[selectedOption].name);
      this.form.controls.petitioner['controls'][petitionerControlsIndex].get('address').setValue(this.petitionerNameSuggestionsAlias[selectedOption].address);
      this.form.controls.petitioner['controls'][petitionerControlsIndex].get('country').setValue(this.petitionerNameSuggestionsAlias[selectedOption].country);
      this.form.controls.petitioner['controls'][petitionerControlsIndex].get('state').setValue(this.petitionerNameSuggestionsAlias[selectedOption].state);
      this.form.controls.petitioner['controls'][petitionerControlsIndex].get('city').setValue(this.petitionerNameSuggestionsAlias[selectedOption].city);
      this.form.controls.petitioner['controls'][petitionerControlsIndex].get('zip').setValue(this.petitionerNameSuggestionsAlias[selectedOption].zip);
      this.petitionerCountryChangeHandler(petitionerControlsIndex);
      this.petitionerStateChangeHandler(petitionerControlsIndex);
    }
  }

  openOrderDateDialog(docIndex) {
    let docDetails: any = {};
    if (docIndex >= 0) {
      this.selectedDocIndex = docIndex;
      docDetails = this.form.value.docs[docIndex][0] ? this.form.value.docs[docIndex][0] : this.form.value.docs[docIndex];
    } else {
      this.selectedDocIndex = -1;
    }
    this.frmOrderDate = this.fb.group({
      orderDate: [docDetails.orderDate || '', Validators.required],
    });
    this.orderDateDiaglogRef = this.dialog.open(this.orderDocDateTemplateRef, {
      panelClass: 'judgement-dialog-container',
      disableClose: true
    });
  }


  async ordersDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.ordersDocs = this.form.value.ordersDocs ? this.form.value.ordersDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadOrdersDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadOrdersDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadOrdersDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadOrdersDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadOrdersDocumentEnd, (filePath) => {
          tmpObj.form.value.ordersDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("ordersDocs").setValue(tmpObj.form.value.ordersDocs);
            tmpObj.openOrderDateDialog(-1);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  async miscellaneousDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.miscellaneousDocs = this.form.value.miscellaneousDocs ? this.form.value.miscellaneousDocs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadMiscellaneousDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadMiscellaneousDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadMiscellaneousDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadMiscellaneousDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadMiscellaneousDocumentEnd, (filePath) => {
          tmpObj.form.value.miscellaneousDocs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("miscellaneousDocs").setValue(tmpObj.form.value.miscellaneousDocs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  saveOrderDateHandler() {
    if (this.frmOrderDate.invalid) {
      this.snackBar.open("Please provide description for document", "Okay", {
        duration: 2000
      });
      return;
    }

    if (this.selectedDocIndex >= 0) {
      if (this.form.value.ordersDocs[this.selectedDocIndex][0]) {
        this.form.value.ordersDocs[this.selectedDocIndex][0].orderDate = this.frmOrderDate.value.orderDate;
      } else {
        this.form.value.ordersDocs[this.selectedDocIndex].orderDate = this.frmOrderDate.value.orderDate;
      }
    } else {
      this.form.value.ordersDocs[this.form.value.ordersDocs.length - 1].orderDate = this.frmOrderDate.value.orderDate;
    }

    this.orderDateDiaglogRef.close();
  }









  public removeOrdersDoc(petitionDocIndex) {
    if (this.form.value.ordersDocs && petitionDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.ordersDocs.splice(petitionDocIndex, 1);
        this.form.value.ordersDocs[petitionDocIndex].is_deleted = true;
        this.form.value.ordersDocs[petitionDocIndex].deletedBy = this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  public removeMiscellaneousDoc(petitionDocIndex) {
    if (this.form.value.miscellaneousDocs && petitionDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.miscellaneousDocs.splice(petitionDocIndex, 1);
        this.form.value.miscellaneousDocs[petitionDocIndex].is_deleted = true;
        this.form.value.miscellaneousDocs[petitionDocIndex].deletedBy = this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  // add new Criminal Case Stage
  public addCrimimalCaseStagesArrUnit(crimimalCaseStagesDetails) {
    const control = <FormArray>this.form.get('petition_docs.crimimalCaseStages');
    control.push(this.getCrimimalCaseStagesArrayUnit(crimimalCaseStagesDetails));
  }

  // remove Criminal Case Stage
  public removeCrimimalCaseStagesArrUnit(i: number) {
    const control = <FormArray>this.form.get('petition_docs.crimimalCaseStages');
    control.removeAt(i);
  }

  public getCrimimalCaseStagesArrayUnit(crimimalCaseStagesDetails) {
    return this.fb.group({
      stage: [crimimalCaseStagesDetails.stage || '', Validators.required],
      docs: [crimimalCaseStagesDetails.docs || []],
    });
  }
  /* Criminal Cases Stage  Field: End */

  // add new Criminal Case Stage
  public addResCrimimalCaseStagesArrUnit(crimimalCaseStagesDetails) {
    const control = <FormArray>this.form.get('supporting_docs.crimimalCaseStages');
    control.push(this.getCrimimalCaseStagesArrayUnit(crimimalCaseStagesDetails));
  }

  // remove Criminal Case Stage
  public removeResCrimimalCaseStagesArrUnit(i: number) {
    const control = <FormArray>this.form.get('supporting_docs.crimimalCaseStages');
    control.removeAt(i);
  }
  /* Criminal Cases Stage Field for Respondent: End */

  changePetitionerName(caseNature) {
    this.petitionerName = "Petitioner";
console.log(caseNature,"caseNature")
    if (caseNature) {
      if (this.commonServices.isCriminalCase(caseNature)) {
        this.petitionerName = "Complainant";
      } else if (this.commonServices.isCivilMatterCase(caseNature)) {
        this.petitionerName = "Plaintiff";
      } else if (this.commonServices.isArbitrationCase(caseNature)) {
        this.petitionerName = "Claimant";
      }
    }

  }

  changeResponderName(caseNature) {
    this.responderName = "Respondent";

    if (caseNature) {
      if (this.commonServices.isCriminalCase(caseNature)) {
        this.responderName = "Accused";
      } else if (this.commonServices.isCivilMatterCase(caseNature)) {
        this.responderName = "Defendant";
      } else if (this.commonServices.isArbitrationCase(caseNature)) {
        this.responderName = "Respondent";
      }
    }

  }


  caseNatureChangeHandler(event) {
    this.changePetitionerName(this.form.value.caseNature);
    this.changeResponderName(this.form.value.caseNature);

    this.form.value.petition_docs.crimimalCaseStages = [];
    this.form.value.supporting_docs.crimimalCaseStages = [];

    if (this.commonServices.isCriminalCase(this.form.value.caseNature)) {
      this.isCaseNatureCriminal = true;
      if (this.courtCaseDetails.petition_docs && this.courtCaseDetails.petition_docs.crimimalCaseStages && this.courtCaseDetails.petition_docs.crimimalCaseStages.length > 0) {

        for (let i = 0; i < this.courtCaseDetails.petition_docs.crimimalCaseStages.length; i++) {
          this.addCrimimalCaseStagesArrUnit(this.courtCaseDetails.petition_docs.crimimalCaseStages[i]);
        }
      } else {
        this.addCrimimalCaseStagesArrUnit({});
      }
      if (this.courtCaseDetails.supporting_docs && this.courtCaseDetails.supporting_docs.crimimalCaseStages && this.courtCaseDetails.supporting_docs.crimimalCaseStages.length > 0) {
        for (let i = 0; i < this.courtCaseDetails.supporting_docs.crimimalCaseStages.length; i++) {
          this.addResCrimimalCaseStagesArrUnit(this.courtCaseDetails.supporting_docs.crimimalCaseStages[i]);
        }
      } else {
        this.addResCrimimalCaseStagesArrUnit({});
      }
    }
  }

  async criminalCaseStageFileControlChangeHandler(fileObj, currentIndex) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.crimimalCaseStages[currentIndex].docs = this.form.value.petition_docs.crimimalCaseStages[currentIndex].docs ? this.form.value.petition_docs.crimimalCaseStages[currentIndex].docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadPetitionDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentEnd, (filePath) => {
          tmpObj.form.value.petition_docs.crimimalCaseStages[currentIndex].docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("petition_docs.crimimalCaseStages").setValue(tmpObj.form.value.petition_docs.crimimalCaseStages);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }


  async resCriminalStageFileControlChangeHandler(fileObj, currentIndex) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      //this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs = this.form.value.supporting_docs.crimimalCaseStages[currentIndex] ? this.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs : [];
        let reader;
        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          //var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
          tmpObj.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("supporting_docs.crimimalCaseStages").setValue(tmpObj.form.value.supporting_docs.crimimalCaseStages);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  courtNameChangeHandler() {
    if (this.form.value.courtDetails.courtName) {
      let tmpObj = this;

      tmpObj.socketObject.emit(environment.socketEvents.getCourtStates, {
        court: this.form.value.courtDetails.courtName
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtNameState = socketResponse.data;
          tmpObj.form.get('courtDetails.state').setValue(tmpObj.courtNameState._id);
          tmpObj.courtStateChangeHandler();

        }
      });
      tmpObj.socketObject.emit(environment.socketEvents.getCourtCity, {
        court: this.form.value.courtDetails.courtName
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtNameCity = socketResponse.data;
          tmpObj.form.get('courtDetails.district').setValue(tmpObj.courtNameCity._id);
        }
      });
    }
  }
}
