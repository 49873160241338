import { Component, Inject, OnInit, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icnote_add from '@iconify/icons-ic/twotone-note-add';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import { MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from '@angular/material';
import icHorizontalSplit from '@iconify/icons-ic/twotone-horizontal-split';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { environment } from 'src/environments/environment';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { CustomValidators } from 'src/app/custom_validator/validator';
import { LayoutService } from 'src/@vex/services/layout.service';

@Component({
  selector: 'vex-updatearbitrationhearing',
  templateUrl: './updatearbitrationhearing.component.html',
  styleUrls: ['./updatearbitrationhearing.component.scss'],
  providers: [CustomValidators]
})
export class UpdatearbitrationhearingComponent implements OnInit {

  private socketObject;

  public courtCasesList = [];
  public courtsList = [];
  public advocatesList = [];
  public courtDetail;

  @Input() courtCaseDetails;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icHorizontalSplit = icHorizontalSplit;
  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icnote_add = icnote_add;

  icPerson = icPerson;
  icEditLocation = icEditLocation;

  darkTheme: NgxMaterialTimepickerTheme = {
    container: {
      bodyBackgroundColor: '#ffffff',
      buttonColor: '#5C77FF'
    },
    dial: {
      dialBackgroundColor: '#5C77FF',
    },
    clockFace: {
      clockFaceBackgroundColor: '#5C77FF',
      clockHandColor: '	#D3D3D3',
      clockFaceTimeInactiveColor: '#fff',

    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) public userDetails: any,
    private dialogRef: MatDialogRef<UpdatearbitrationhearingComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private customValidators: CustomValidators,
    private socketService: SocketService,
    private _layoutService: LayoutService,) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {
  
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listarbitration, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtCasesList = socketResponse.data;
          console.log(socketResponse,"on hearing ")
        }
      });

      // this.socketObject.emit(environment.socketEvents.listCourt, {}, function (socketResponse) {
      //   if (socketResponse.status == 'OK') {
      //     tmpObj.courtsList = socketResponse.data;
      //   }
      // });

      // this.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
      //   if (socketResponse.status == 'OK') {
      //     tmpObj.advocatesList = socketResponse.data;
      //   }
      // });


    }

   

    if (this.userDetails && this.userDetails._id) {
      this.mode = 'update';

    } else {
     
      if (this.userDetails && this.userDetails.courtCase) {
      
       
        this.userDetails.attorneyDetails = this.userDetails.courtCase.advocateAssigned?this.userDetails.courtCase.advocateAssigned:'';
      if(this.userDetails.courtCase.courtDetails){
        this.userDetails.court = this.userDetails.courtCase.courtDetails.courtNumber?this.userDetails.courtCase.courtDetails.courtNumber:this.userDetails.courtCase.courtDetails.courtNumber;
        this.userDetails.judgeName = this.userDetails.courtCase.courtDetails.judgeName?this.userDetails.courtCase.courtDetails.judgeName:this.userDetails.courtCase.courtDetails.judgeName;
      }
     
        console.log(this.userDetails,"userDetails")
       
      } else {
        this.userDetails = {};
      }
    }
   
    // console.log(this.form.value,"this.form")
    //   docs: [this.courtCaseDetails.supporting_otherdocument ? this.courtCaseDetails.supporting_otherdocument.docs || [] : null],

    //  ordersheet: this.fb.group({
      // docs: [this.courtCaseDetails.supporting_otherdocument ? this.courtCaseDetails.supporting_otherdocument.docs || [] : null],
   
  // })

  console.log(this.userDetails.courtCase,"this.userDetails of form")
    this.form = this.fb.group({
      _id: this.userDetails.courtCase._id || '',
      casetitle: [this.userDetails.courtCase ? this.userDetails.courtCase.casetitle : '', Validators.required],
      meetinglink: [this.userDetails.courtCase ? this.userDetails.courtCase.meetinglink : '', Validators.required],
      proceedingrecord: [this.userDetails.courtCase.proceedingrecord ? this.userDetails.courtCase.proceedingrecord : null, Validators.required],
      ndoh: [this.userDetails.courtCase.ndoh ? this.userDetails.courtCase.ndoh : '', Validators.required],
        ordersheet: this.fb.group({
      docs: [this.userDetails.courtCase.ordersheet ? this.userDetails.courtCase.ordersheet.docs || [] : null],
   
  }),
     
      nextHearingDate: [this.userDetails.courtCase.nextHearingDate || new Date(), Validators.required],
      HearingDate: [this.userDetails.courtCase.HearingDate || new Date(), Validators.required],
      // nextHearingTime: [this.userDetails.nextHearingDate || ''],
      name_of_arbitrator: [this.userDetails.courtCase.name_of_arbitrator || '']
    });

   

  }

  save() {
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    console.log(this.form.value,"this.form.value")
    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    if (!customer.imageSrc) {
      customer.imageSrc = 'assets/img/avatars/1.jpg';
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    customer.id = this.userDetails.id;

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  getAdvocateName(advocateFirmDetails) {
    let name = "N/A";
    if (advocateFirmDetails) {
      if (advocateFirmDetails.userType == "firm") {
        name = advocateFirmDetails.firmName;
      } else {
        name = advocateFirmDetails.firstName + " " + advocateFirmDetails.lastName;
      }
    }
    return name;
  }

  caseNumberChangeHandler() {
    if (this.form.value.courtCase) {
      let tmpObj = this;
      tmpObj.socketObject.emit(environment.socketEvents.courtCasesForList, {
        court: this.form.value.courtCase
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.courtDetail = socketResponse.data;
          tmpObj.form.get('court').setValue(tmpObj.courtDetail[0].courtDetails.courtNumber);
          tmpObj.form.get('judgeName').setValue(tmpObj.courtDetail[0].courtDetails.judgeName);
          tmpObj.form.get('attorneyDetails').setValue(tmpObj.courtDetail[0].advocateAssigned._id)


        }
      });




    }
  }

  ordersheetFileControlChangeHandler(fileObj){
    console.log("file upload run")
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.ordersheet.docs = this.form.value.ordersheet.docs ? this.form.value.ordersheet.docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
          tmpObj.form.value.ordersheet.docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("ordersheet.docs").setValue(tmpObj.form.value.ordersheet.docs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }
}
