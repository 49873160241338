import { Component, OnInit } from '@angular/core';
import { DateTime } from 'luxon';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/@vex/services/layout.service';

@Component({
  selector: 'vex-quickpanel',
  templateUrl: './quickpanel.component.html',
  styleUrls: ['./quickpanel.component.scss']
})
export class QuickpanelComponent implements OnInit {

  date = DateTime.local().toFormat('DD');
  dayName = DateTime.local().toFormat('EEEE');

  private socketObject;

  public todayAppointments = [];
  public todayHearings = [];

  constructor(
    private socketService: SocketService,
    private commonServices: CommonService,
    private layoutService: LayoutService
  ) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {
    this.refreshData()
  }

  refreshData() {
    console.log(this.layoutService.today," today section")
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getToolbarStats, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.todayAppointments = socketResponse.appointments;
          tmpObj.todayHearings = socketResponse.hearings;
        }
      });
    }
  }
  markendSeen(todayHearings) {
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;


      this.socketObject.emit(environment.socketEvents.markedSeen, { _id: todayHearings._id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.refreshData()
        }
      });
    }
  }

  getCourtCaseName(caseDetails) {
    let caseName = "N/A";

    if (caseDetails) {
      if(caseDetails.caseName){
        caseName = caseDetails.caseName + "[" + caseDetails.caseNumber + "]";
      }else if(caseDetails.case_name){
        caseName = caseDetails.case_name + "[" + caseDetails.caseNumber + "]";
      }else{
        caseName =  "[" + caseDetails.caseNumber + "]";
      }
     
    }

    return caseName;
  }

}
