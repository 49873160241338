import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { MenuItem } from '../interfaces/menu-item.interface';
import { trackById } from '../../../utils/track-by';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSettings from '@iconify/icons-ic/twotone-settings';
import icAccountCircle from '@iconify/icons-ic/twotone-account-circle';
import icMoveToInbox from '@iconify/icons-ic/twotone-move-to-inbox';
import icListAlt from '@iconify/icons-ic/twotone-list-alt';
import icTableChart from '@iconify/icons-ic/twotone-table-chart';
import icCheckCircle from '@iconify/icons-ic/twotone-check-circle';
import icAccessTime from '@iconify/icons-ic/twotone-access-time';
import icDoNotDisturb from '@iconify/icons-ic/twotone-do-not-disturb';
import icOfflineBolt from '@iconify/icons-ic/twotone-offline-bolt';
import icChevronRight from '@iconify/icons-ic/twotone-chevron-right';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import icBusiness from '@iconify/icons-ic/twotone-business';
import icVerifiedUser from '@iconify/icons-ic/twotone-verified-user';
import icLock from '@iconify/icons-ic/twotone-lock';
import icNotificationsOff from '@iconify/icons-ic/twotone-notifications-off';
import { Icon } from '@visurel/iconify-angular';
import { PopoverRef } from '../../popover/popover-ref';
import { environment } from 'src/environments/environment';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { UsersChangePasswordComponent } from 'src/app/pages/users/users-change-password/users-change-password.component';

export interface OnlineStatus {
  id: 'online' | 'away' | 'dnd' | 'offline';
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: 'vex-toolbar-user-dropdown',
  templateUrl: './toolbar-user-dropdown.component.html',
  styleUrls: ['./toolbar-user-dropdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserDropdownComponent implements OnInit {

  


  items: MenuItem[] = [
    // {
    //   id: '1',
    //   icon: icAccountCircle,
    //   label: 'My Profile',
    //   description: 'Personal Information',
    //   colorClass: 'text-teal-500',
    //   route: environment.routes.userProfile
    // },
    // {
    //   id: '2',
    //   icon: icMoveToInbox,
    //   label: 'My Inbox',
    //   description: 'Messages & Latest News',
    //   colorClass: 'text-primary-500',
    //   route: '/apps/chat'
    // },
    // {
    //   id: '3',
    //   icon: icListAlt,
    //   label: 'My Projects',
    //   description: 'Tasks & Active Projects',
    //   colorClass: 'text-amber-500',
    //   route: '/apps/scrumboard'
    // },
    // {
    //   id: '4',
    //   icon: icTableChart,
    //   label: 'Billing Information',
    //   description: 'Pricing & Current Plan',
    //   colorClass: 'text-purple-500',
    //   route: '/pages/pricing'
    // }
  ];

  statuses: OnlineStatus[] = [
    {
      id: 'online',
      label: 'Online',
      icon: icCheckCircle,
      colorClass: 'text-green-500'
    },
    {
      id: 'away',
      label: 'Away',
      icon: icAccessTime,
      colorClass: 'text-orange-500'
    },
    {
      id: 'dnd',
      label: 'Do not disturb',
      icon: icDoNotDisturb,
      colorClass: 'text-red-500'
    },
    {
      id: 'offline',
      label: 'Offline',
      icon: icOfflineBolt,
      colorClass: 'text-gray-500'
    }
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  private socketObject;
  public userDetails;

  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;

  constructor(private cd: ChangeDetectorRef,
              private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
              private socketService: SocketService,
              private dialog: MatDialog,
              private snackbar: MatSnackBar,
              private commonServices:CommonService) {

    const token = this.commonServices.getAccessToken();
    if(token) {
      if( !this.socketService.isSocketConnected() ) {
          this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {
    if(this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit( environment.socketEvents.getLoggedInUserDetails, {}, function(socketResponse) {
        if(socketResponse.status == 'OK') {
          tmpObj.userDetails = socketResponse.data;
          tmpObj.commonServices.setLoggedInUserDetails(tmpObj.userDetails);
          tmpObj.cd.detectChanges();
        }
      });
    }
  }

  setStatus(status: OnlineStatus) {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  logoutUser() {
    let tmpObj = this;
    this.socketObject.emit( environment.socketEvents.logoutUser, {}, function(socketResponse) {
    });
    // this.popoverRef.close();
  }


  openChangePasswordModal() {
    this.dialog.open(UsersChangePasswordComponent, {
      data: {},
      disableClose: true
    }).afterClosed().subscribe(changePasswordDetails => {
      if (changePasswordDetails) {
        if(this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit( environment.socketEvents.changePassword, changePasswordDetails, function(socketResponse) {
            if(socketResponse=='old Password are Wrong'){
              tmpObj.snackbar.open('old Password are Wrong', 'Okay', {
                duration: 4000
              });
            }
            else if(socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Changed the Password.', 'Okay', {
                duration: 4000
              });
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
      }
    });
  }
}
