import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NcltCourtService } from './nclt-court.service';
import {CourtcaseService} from '../addcourtcase/courtcase.service'
import { MatDialog, MatSnackBar } from "@angular/material";
import { NcltCourtDetailComponent } from '../nclt-court-detail/nclt-court-detail.component';

@Component({
  selector: 'vex-nclt-court',
  templateUrl: './nclt-court.component.html',
  styleUrls: ['./nclt-court.component.scss']
})
export class NcltCourtComponent implements OnInit {
  loading = false;
  constructor(private ncltCourtService:NcltCourtService,private CourtcaseService:CourtcaseService,private snackBar: MatSnackBar,public dialog: MatDialog) { }
  defaultRecords: any = 10;
  msg
  ngOnInit() {
    this.locationCategoryDetails()
    this.caseTypeDetails()
    this.caseYearDetails()
    this.partyTypeCategoryDetails()
    this.partyTypeCaseStatus()
  }
  caseNumberForm = new FormGroup({
    search_by: new FormControl("1"),
    bench: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    case_number: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl(""),
    case_status: new FormControl(""),
    party_name: new FormControl(""),
    filing_number: new FormControl(""),
    advocate_name: new FormControl(""),
  });

  fillingNumberForm = new FormGroup({
    search_by: new FormControl("2"),
    bench: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_number: new FormControl(""),
    case_year: new FormControl(""),
    party_type: new FormControl(""),
    case_status: new FormControl(""),
    party_name: new FormControl(""),
    filing_number: new FormControl("", Validators.required),
    advocate_name: new FormControl(""),
  });

  partyNameForm = new FormGroup({
    search_by: new FormControl("3"),
    page: new FormControl("1"),
    bench: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_number: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl("", Validators.required),
    case_status: new FormControl("",Validators.required),
    party_name: new FormControl("", Validators.required),
    filing_number: new FormControl(""),
    advocate_name: new FormControl(""),
  });

  advocateNameForm = new FormGroup({
    search_by: new FormControl("4"),
    page: new FormControl("1"),
    bench: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_number: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl(""),
    case_status: new FormControl(""),
    party_name: new FormControl(""),
    filing_number: new FormControl(""),
    advocate_name: new FormControl("", Validators.required),
  }); 

  back() {
    window.history.back();
  }
  pageIndexOfCaseNumber = 0 ;
  totalpagesOfCaseNumber;
  totalCaseNumberData;
  caseNumberData;
  handleCaseNumberData;
  handleCaseNumberMsg;
  byCaseNumber(){
    if (this.caseNumberForm.valid) {
      this.loading = true;
      console.log(this.caseNumberForm.value)
      this.ncltCourtService
        .postData('caseList', this.caseNumberForm.value)
        .subscribe((res) => {
          console.log(res,"jhfg")
          if (res.status == true) {
            this.totalCaseNumberData = res.caseList;
            console.log(this.totalCaseNumberData,"this.totalCaseNumberData")
            this.totalpagesOfCaseNumber = Math.ceil(this.totalCaseNumberData.length/this.defaultRecords)

            this.caseNumberData = this.totalCaseNumberData.slice(0,this.defaultRecords);
            console.log( this.caseNumberData," this.caseNumberData")
            this.handleCaseNumberData = true;
            this.handleCaseNumberMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handleCaseNumberData = false;
            this.handleCaseNumberMsg = true;
            this.loading = false;
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        });
    }
  }


  pageIndexOfFillingNumber = 0 ;
  totalpagesOfFillingNumber;
  totalFillingNumberData;
  fillingNumberData;
  handlefillingNumberData;
  handlefillingNumberDataMsg;
  byFillingNumber() {
    if (this.fillingNumberForm.valid) {
      console.log(this.fillingNumberForm.value,"jkfghkfjdh")
      this.loading = true;
      this.ncltCourtService
        .postData('caseList', this.fillingNumberForm.value)
        .subscribe((res) => {
          if (res.status == true) {

            this.totalFillingNumberData = res.caseList;
            console.log(this.totalFillingNumberData ,"this.totalFillingNumberData ")
            this.totalpagesOfFillingNumber = Math.ceil(this.totalFillingNumberData.length/this.defaultRecords)

            this.fillingNumberData = this.totalFillingNumberData.slice(0,this.defaultRecords);
            this.handlefillingNumberData = true;
            this.handlefillingNumberDataMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handlefillingNumberData = false;
            this.handlefillingNumberDataMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }


  pageIndexOfPartyName = 0 ;
  totalpagesPartyName;
  totalPartyNameData;
  partyNameData;
  handlepartyNameData;
  handlepartyNameMsg;
  byPartyName() {
    if (this.partyNameForm.valid) {
      this.loading = true;
      console.log(this.partyNameForm.value,"ghfg")
      this.ncltCourtService
        .postData('caseList', this.partyNameForm.value)
        .subscribe((res) => {
          console.log(res,"jkfghdjkfghj")
          if (res.status == true) {

            this.totalPartyNameData = res.caseList;
            this.totalpagesPartyName = Math.ceil(this.totalPartyNameData.length/this.defaultRecords)

            this.partyNameData = this.totalPartyNameData.slice(0,this.defaultRecords);
            this.handlepartyNameData = true;
            this.handlepartyNameMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handlepartyNameData = false;
            this.handlepartyNameMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }



  pageIndexOfAdvocateName = 0 ;
  totalpagesAdvocateName;
  totalAdvocateNameData;
  advocateNameData;
  handleAdvocateNameData;
  handleAdvocateNameMsg;
  byAdvocateName() {
    if (this.advocateNameForm.valid) {
      console.log(this.advocateNameForm.value)
      this.loading = true;
      this.ncltCourtService
        .postData('caseList', this.advocateNameForm.value)
        .subscribe((res) => {
          // console.log(res)
          if (res.status == true) {

            this.totalAdvocateNameData = res.caseList;
            console.log(this.totalAdvocateNameData ,"this.totalAdvocateNameData ")
            this.totalpagesAdvocateName = Math.ceil(this.totalAdvocateNameData.length/this.defaultRecords)

            this.advocateNameData = this.totalAdvocateNameData.slice(0,this.defaultRecords);
            this.handleAdvocateNameData = true;
            this.handleAdvocateNameMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handleAdvocateNameData = false;
            this.handleAdvocateNameMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }



  locationCategory;
  locationCategoryDetails() {
    this.ncltCourtService
      .getval('benchCategory')
      .subscribe((res) => {
        // console.log(res,"res ")
        this.locationCategory = res.benchCategory;
        // this.caseNumberForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        // this.advocateNameForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        // this.partyNameForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        // this.fillingNumberForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        // this.caseTypeForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
      });
  }

  caseTypeCategory;
  caseTypeDetails() {
    this.ncltCourtService
      .getval('caseTypeCategory')
      .subscribe((res) => {
        console.log(res,"res")
        this.caseTypeCategory = res.caseTypeCategory;
      });
  }

  caseYearCategory;
  caseYearDetails() {
    this.CourtcaseService
      .postyear('ws/caseYears')
      .subscribe((res) => {
        this.caseYearCategory = res['caseYear'];

        if(this.caseYearCategory){
          // this.caseNumberForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          // this.partyNameForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          // this.advocateNameForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          // this.caseTypeForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
        }
      });
  }

  partyTypeCategory;
  partyTypeCategoryDetails() {
    this.ncltCourtService
      .getval('partyTypeCategory')
      .subscribe((res) => {
        this.partyTypeCategory = res.partyTypeCategory;
        // this.partyNameForm.get('party_type').setValue(this.partyTypeCategory?this.partyTypeCategory[0]['party_type_id']:'')
      });
  }


  partyTypeCase;
  partyTypeCaseStatus() {
    this.ncltCourtService
      .getval('caseStatusCategory')
      .subscribe((res) => {
        this.partyTypeCase = res.caseStatusCategory;
        // this.partyNameForm.get('party_type').setValue(this.partyTypeCase?this.partyTypeCase[0]['party_type_id']:'')
      });
  }




  casedetailbyid(data){
    console.log(data)
    data['case_year']=data.case_year
    this.dialog.open(NcltCourtDetailComponent, {
      height: '800px',
      width: '950px',
      autoFocus: false,
      data:data
    })
  }

}
