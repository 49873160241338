import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { PopoverService } from '../popover/popover.service';
import { ToolbarNotificationsDropdownComponent } from './toolbar-notifications-dropdown/toolbar-notifications-dropdown.component';
import icNotificationsActive from '@iconify/icons-ic/twotone-notifications-active';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';
import { PopoverRef } from '../popover/popover-ref';

@Component({
  selector: 'vex-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarNotificationsComponent implements OnInit {

  @ViewChild('originRef', { static: true, read: ElementRef }) originRef: ElementRef;

  dropdownOpen: boolean;
  icNotificationsActive = icNotificationsActive;

  private socketObject;

  public notificationsCount = 0;

  public popoverRef: PopoverRef;

  constructor(private popover: PopoverService,
    private cd: ChangeDetectorRef,
    private socketService: SocketService,
    private commonServices: CommonService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
    this.refreshNotificaion(this);
    this.setNotificationBroadcastHandler();
  }

  ngOnInit() {
  }

  showPopover() {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    this.popoverRef = this.popover.open({
      content: ToolbarNotificationsDropdownComponent,
      origin: this.originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    ToolbarNotificationsDropdownComponent.popoverRef = this.popoverRef;
    ToolbarNotificationsDropdownComponent.toolbarNotificationComponentRef = this;

    this.popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }


  refreshNotificaion(tmpObj:ToolbarNotificationsComponent) {
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.notificationsCount, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.notificationsCount = socketResponse.notificationsCount;
          tmpObj.cd.detectChanges();
        } else {
        }
      });
    }
  }

  setNotificationBroadcastHandler() {
    if ( this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.removeListener(environment.socketEvents.broadcastNotificaitonChange);
      this.socketObject.on(environment.socketEvents.broadcastNotificaitonChange, function (socketResponse) {
        tmpObj.refreshNotificaion(tmpObj);
      });
    }
  }
}
