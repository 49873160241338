import { Component, Inject, OnInit } from '@angular/core';
import { MatSnackBar, MAT_DIALOG_DATA ,MatDialog} from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
// import { CourtCasesService } from '../../addcase/court-cases.service';
// import { DistrictService } from '../../districtcourt/districtcourt.service';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icPrint from '@iconify/icons-ic/twotone-print';
import { HighcourtService } from '../highcourt.service';
import { CourtcaseService } from '../../addcourtcase/courtcase.service';
import { DistrictService } from '../../districtcourt/district.service';
import { HighcourthistroydetailComponent } from '../highcourthistroydetail/highcourthistroydetail.component';

@Component({
  selector: 'vex-highcourtdetail',
  templateUrl: './highcourtdetail.component.html',
  styleUrls: ['./highcourtdetail.component.scss']
})
export class HighcourtdetailComponent implements OnInit {

  private socketObject;
  caseid = ''
  casedetails: any;
  casestatusdetails: any;
  casepetionordetails: any;
  caseresponedentdetails: any;
  caseactdetails: any;
  casehistorydetails: any;
  caseordersdetails: any;

  icEdit = icEdit;
  icPrint = icPrint;
  datadetail: any;
  iadetail: any;
  caseConversionInfo: any;
  caseMainMatterInfo: any;
  caseSubMatterInfo: any;
  caseLinkedInfo: any;
  caseObjectionInfo: any;
  caseDocumentInfo: any;
  caseFIRInfo: any;
  caseCategoryInfo: any;
  caseActsInfo: any;
  courtcaselist: any;
  title: any;
  case_type_case_number_case_year:any;

  constructor(private route: ActivatedRoute,private court :CourtcaseService,private highcourt: HighcourtService,@Inject(MAT_DIALOG_DATA) data,private districtcourt: DistrictService, private socketService: SocketService,private commonServices: CommonService,private snackBar: MatSnackBar,private dialog: MatDialog) {
    if(data){
      this.datadetail = data
      // this.caseid=data.caseId;
     }
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
   }

  ngOnInit() {

  //   this.route.queryParamMap.subscribe((params:any )=> {console.log(params)
  //     this.caseid = params.params.case_id
  //     console.log(this.caseid,"after route")
  // })

  let obj={
    state_code:this.datadetail.state_code,
   
    court_code:this.datadetail.court_code,
    case_no_year:this.datadetail.case_no_year,
    case_cino:this.datadetail.case_cino,
   
    
  
  }
  let detaildata 
  console.log(obj,"obj")
  this.highcourt.postecourt('ws/eHighCourtCase',obj).subscribe((res2: any) => {
    // console.log(res2,"obj")
    if(res2.status){
      detaildata = res2.courtInfo
      this.case_type_case_number_case_year=detaildata.case_type_case_number_case_year;
      this.title = detaildata.establishment_name


      this.caseid = detaildata.id

      if(this.caseid){

        this.getcasedetais();
        this.getcasestatusdetais();
        this.getpetinordetais();
        this.getrepondentdetais();
        this.getactdetais();
        this.getCaseHistorydetais();
        this.getCaseordersdetais();
        this.getiadetail();
        this.getcaseconversiondetail();
        this.getmainmetterdetail();
        this.getsubmetterdetail();
        this.getlinkedcasedetail();
        this.getobjectiondetail();
        this.getdocumentdetail();
        this.getfirdetail();
        this.getcategorydetail();
        this.getactdetal();

        this.highcourt.postecourt('ws/eAutoUpdateHighCourtCase',obj).subscribe((res2: any) => {
                   console.log(res2)
                  // this.loading = false; 
                  if(res2.autoUpdateHighCourtCase){
                    this.courtcaselist = res2.autoUpdateHighCourtCase[0]
                 }
                 })
      }
    }
  })
}

getcasedetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighCaseDetail',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casedetails= res2.caseDetailsInfo
     this.casedetails['case_type_case_number_case_year']=this.case_type_case_number_case_year
  })

}


getcaseconversiondetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighCaseConversionDetail',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseConversionInfo= res2.caseConversionInfo
  })
}


getactdetal(){
  // eHighGetCaseActs

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighGetCaseActs',obj).subscribe((res2: any) => {
    console.log(res2 ,"after act detail")
     this.caseActsInfo= res2.caseActsInfo
  })
}

getmainmetterdetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighMainMatterDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseMainMatterInfo= res2.caseMainMatterInfo
  })
}

getsubmetterdetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighSubMatterDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseSubMatterInfo= res2.caseSubMatterInfo
  })
}

getlinkedcasedetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighLinkedDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseLinkedInfo= res2.caseLinkedInfo
  })
}


getobjectiondetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighObjectionDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseObjectionInfo= res2.caseObjectionInfo
  })
}


getdocumentdetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighDocumentsDetail',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseDocumentInfo= res2.caseDocumentInfo
  })
}


getfirdetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighFIRDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseFIRInfo= res2.caseFIRInfo
  })
}


getcategorydetail(){
  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighCategoryDetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseCategoryInfo= res2.caseCategoryInfo
  })
}



getcasestatusdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighGetCaseStatus',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casestatusdetails= res2.caseStatusInfo

  })

}


getpetinordetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighGetCasePetitionerAdv',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casepetionordetails= res2.casePetitionerAdvInfo
  })

}


getrepondentdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighGetCaseRespondentAdv',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseresponedentdetails= res2.caseRespondentAdvInfo
  })

}

getactdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighSubordinateInformation',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseactdetails= res2.caseSubordinateInfo

  })

}

getiadetail(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighIADetails',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
    this.iadetail = res2.caseIAInfo
    //  this.caseactdetails= res2.caseActsInfo
  })

}


getCaseHistorydetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
  // https://api.mysita.com/public/ws/courtNotice
  this.highcourt.postecourt('ws/eHighGetCaseHistory',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.casehistorydetails= res2.caseHistoryInfo
  })

}

getCaseordersdetais(){

  let obj = {
    "court_case_id":this.caseid 
  }
 
  this.highcourt.postecourt('ws/eHighGetCaseOrders',obj).subscribe((res2: any) => {
    console.log(res2 ,"after case detail")
     this.caseordersdetails= res2.caseOrderInfo
  })

}

goToLink(url: string) {
  if (url != null) {
    window.open(url, "_blank");
  } else {
    alert("Orders not uploaded.");
  }
}

savecase(){
  // console.log(this.courtcaselist,"this.columns")
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.casedetails.fourm = "highcourt"
      this.courtcaselist['serverType']=this.datadetail.serverType
      this.courtcaselist['state_code']=this.datadetail.state_code

      console.log(this.courtcaselist,"this.columns 9999")
      this.socketObject.emit(environment.socketEvents.createHighCourtCase, this.courtcaselist, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
            duration: 4000
          });

        

          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
}


openhearing(data){
  this.dialog.open(HighcourthistroydetailComponent,{
    height: '500px',
    width: '800px',
    autoFocus: false,
    data:data
    });
}

}
