import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TribunalService } from "./tribunal.service";
import { environment } from "src/environments/environment";
import { MatDialog, MatSnackBar } from "@angular/material";
import { TribunalCourtDetailComponent } from "../tribunal-court-detail/tribunal-court-detail.component";
import {CourtcaseService} from "../addcourtcase/courtcase.service"

@Component({
  selector: "vex-tribunal-court",
  templateUrl: "./tribunal-court.component.html",
  styleUrls: ["./tribunal-court.component.scss"],
})
export class TribunalCourtComponent implements OnInit {
  loading = false;
  defaultRecords: any = 10;
  msg;

  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;

  constructor(private tribunalService: TribunalService,public dialog: MatDialog,private snackBar: MatSnackBar,private CourtcaseService:CourtcaseService) {}

  ngOnInit() {
    this.locationCategoryDetails();
    this.caseTypeDetails();
    this.caseYearDetails();
    this.caseStatusDetails();
    this.partyTypeCategoryDetails();
  }

  caseNumberForm = new FormGroup({
    search_by: new FormControl("1"),
    location: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    case_number: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl(""),
    case_status: new FormControl(""),
    party_name: new FormControl(""),
    filing_no: new FormControl(""),
    advocate_name: new FormControl(""),
  });

  fillingNumberForm = new FormGroup({
    search_by: new FormControl("2"),
    location: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_number: new FormControl(""),
    case_year: new FormControl(""),
    party_type: new FormControl(""),
    case_status: new FormControl(""),
    party_name: new FormControl(""),
    filing_no: new FormControl("", Validators.required),
    advocate_name: new FormControl(""),
  });

  caseTypeForm = new FormGroup({
    search_by: new FormControl("3"),
    location: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    case_number: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl(""),
    case_status: new FormControl("", Validators.required),
    party_name: new FormControl(""),
    filing_no: new FormControl(""),
    advocate_name: new FormControl(""),
  });

  partyNameForm = new FormGroup({
    search_by: new FormControl("4"),
    location: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_number: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl("", Validators.required),
    case_status: new FormControl(""),
    party_name: new FormControl("", Validators.required),
    filing_no: new FormControl(""),
    advocate_name: new FormControl(""),
  });

  advocateNameForm = new FormGroup({
    search_by: new FormControl("5"),
    location: new FormControl("", Validators.required),
    case_type: new FormControl(""),
    case_number: new FormControl(""),
    case_year: new FormControl("", Validators.required),
    party_type: new FormControl(""),
    case_status: new FormControl(""),
    party_name: new FormControl(""),
    filing_no: new FormControl(""),
    advocate_name: new FormControl("", Validators.required),
  });

  pageIndexOfCaseNumber = 0 ;
  totalpagesOfCaseNumber;
  totalCaseNumberData;
  caseNumberData;
  handleCaseNumberData;
  handleCaseNumberMsg;
  byCaseNumber() {
    if (this.caseNumberForm.valid) {
      this.loading = true;
      this.tribunalService
        .postData('caseList', this.caseNumberForm.value)
        .subscribe((res) => {

          if (res.status == true) {
            this.totalCaseNumberData = res.caseList;
            this.totalpagesOfCaseNumber = Math.ceil(this.totalCaseNumberData.length/this.defaultRecords)

            this.caseNumberData = this.totalCaseNumberData.slice(0,this.defaultRecords);
            console.log(this.caseNumberData)
            this.handleCaseNumberData = true;
            this.handleCaseNumberMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handleCaseNumberData = false;
            this.handleCaseNumberMsg = true;
            this.loading = false;
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        });
    }
  }

  pageIndexOfFillingNumber = 0 ;
  totalpagesOfFillingNumber;
  totalFillingNumberData;
  fillingNumberData;
  handlefillingNumberData;
  handlefillingNumberDataMsg;
  byFillingNumber() {
    if (this.fillingNumberForm.valid) {
      this.loading = true;
      this.tribunalService
        .postData('caseList', this.fillingNumberForm.value)
        .subscribe((res) => {
          if (res.status == true) {

            this.totalFillingNumberData = res.caseList;
            this.totalpagesOfFillingNumber = Math.ceil(this.totalFillingNumberData.length/this.defaultRecords)

            this.fillingNumberData = this.totalFillingNumberData.slice(0,this.defaultRecords);
            this.handlefillingNumberData = true;
            this.handlefillingNumberDataMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handlefillingNumberData = false;
            this.handlefillingNumberDataMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }

  pageIndexOfCaseType = 0 ;
  totalpagesCaseType;
  totalCaseTypeData;
  CaseTypeData;
  handleCaseTypeData;
  handleCaseTypeMsg;
  byCaseType() {
    if (this.caseTypeForm.valid) {
      this.loading = true;
      this.tribunalService
        .postData('caseList', this.caseTypeForm.value)
        .subscribe((res) => {
          if (res.status == true) {

            this.totalCaseTypeData = res.caseList;
            this.totalpagesCaseType = Math.ceil(this.totalCaseTypeData.length/this.defaultRecords)

            this.CaseTypeData = this.totalCaseTypeData.slice(0,this.defaultRecords);
            this.handleCaseTypeData = true;
            this.handleCaseTypeMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handleCaseTypeData = false;
            this.handleCaseTypeMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }

  pageIndexOfPartyName = 0 ;
  totalpagesPartyName;
  totalPartyNameData;
  partyNameData;
  handlepartyNameData;
  handlepartyNameMsg;
  byPartyName() {
    if (this.partyNameForm.valid) {
      this.loading = true;
      console.log(this.partyNameForm.value)
      this.tribunalService
        .postData('caseList', this.partyNameForm.value)
        .subscribe((res) => {
          if (res.status == true) {

            this.totalPartyNameData = res.caseList;
            this.totalpagesPartyName = Math.ceil(this.totalPartyNameData.length/this.defaultRecords)

            this.partyNameData = this.totalPartyNameData.slice(0,this.defaultRecords);
            this.handlepartyNameData = true;
            this.handlepartyNameMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handlepartyNameData = false;
            this.handlepartyNameMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }

  pageIndexOfAdvocateName = 0 ;
  totalpagesAdvocateName;
  totalAdvocateNameData;
  advocateNameData;
  handleAdvocateNameData;
  handleAdvocateNameMsg;
  byAdvocateName() {
    if (this.advocateNameForm.valid) {
      console.log(this.advocateNameForm.value,"this.advocateNameForm.value")
      this.loading = true;
      this.tribunalService
        .postData('caseList', this.advocateNameForm.value)
        .subscribe((res) => {
          if (res.status == true) {

            this.totalAdvocateNameData = res.caseList;
            this.totalpagesAdvocateName = Math.ceil(this.totalAdvocateNameData.length/this.defaultRecords)

            this.advocateNameData = this.totalAdvocateNameData.slice(0,this.defaultRecords);
            this.handleAdvocateNameData = true;
            this.handleAdvocateNameMsg = false;
            this.loading = false;
          }else{
            this.msg = res.message
            this.handleAdvocateNameData = false;
            this.handleAdvocateNameMsg = true;
            this.loading = false
          }
        },(err)=>{
          console.log(err)
          this.loading = false;
          this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
            duration: 3000,
            horizontalPosition: 'center'
          });
        }
        );
    }
  }

  onPageChange($event,val) {
    if(val=='caseNumber'){
      this.totalpagesOfCaseNumber = Math.ceil(this.totalCaseNumberData.length/$event.pageSize)

      this.pageIndexOfCaseNumber=$event.pageIndex
      this.caseNumberData = this.totalCaseNumberData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }else if(val=='fillingNumber'){
      this.totalpagesOfFillingNumber = Math.ceil(this.totalFillingNumberData.length/$event.pageSize)

      this.pageIndexOfFillingNumber=$event.pageIndex
      this.fillingNumberData = this.totalFillingNumberData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }else if(val=="caseType"){
      this.totalpagesCaseType = Math.ceil(this.totalCaseTypeData.length/$event.pageSize)

      this.pageIndexOfCaseType=$event.pageIndex
      this.CaseTypeData = this.totalCaseTypeData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }else if(val=='partyName'){
      this.totalpagesPartyName = Math.ceil(this.totalPartyNameData.length/$event.pageSize)

      this.pageIndexOfPartyName=$event.pageIndex
      this.partyNameData = this.totalPartyNameData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }else if(val=='advocateName'){
      this.totalpagesAdvocateName = Math.ceil(this.totalAdvocateNameData.length/$event.pageSize)

      this.pageIndexOfAdvocateName=$event.pageIndex
      this.advocateNameData = this.totalAdvocateNameData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  back() {
    window.history.back();
  }

  locationCategory;
  locationCategoryDetails() {
    this.tribunalService
      .getData('locationCategory')
      .subscribe((res) => {
        this.locationCategory = res.locationCategory;
        this.caseNumberForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        this.advocateNameForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        this.partyNameForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        this.fillingNumberForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
        this.caseTypeForm.get('location').setValue(this.locationCategory?this.locationCategory[0]['location_id']:'')
      });
  }

  caseTypeCategory;
  caseTypeDetails() {
    this.tribunalService
      .getData('caseTypeCategory')
      .subscribe((res) => {
        this.caseTypeCategory = res.caseTypeCategory;
      });
  }

  caseYearCategory;
  caseYearDetails() {

    this.CourtcaseService
      .postyear('ws/caseYears')
      .subscribe((res) => {
        this.caseYearCategory = res['caseYear'];
        // this.caseYearCategory.unshift({year:"All"})

        if(this.caseYearCategory){
          this.caseNumberForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          this.partyNameForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          this.advocateNameForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          this.caseTypeForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
        }
      });
  }

  caseStatusCategory;
  caseStatusDetails() {
    this.tribunalService
      .getData('caseStatusCategory')
      .subscribe((res) => {
        this.caseStatusCategory = res.caseStatusCategory;
        this.caseTypeForm.get('case_status').setValue(this.caseStatusCategory?this.caseStatusCategory[1]['case_status_id']:'')
      });
  }

  partyTypeCategory;
  partyTypeCategoryDetails() {
    this.tribunalService
      .getData('partyTypeCategory')
      .subscribe((res) => {
        this.partyTypeCategory = res.partyTypeCategory;
        this.partyNameForm.get('party_type').setValue(this.partyTypeCategory?this.partyTypeCategory[0]['party_type_id']:'')
      });
  }


  casedetailbyid(data){
    console.log(data)
    data['case_year']=data.case_year
    this.dialog.open(TribunalCourtDetailComponent, {
      height: '800px',
      width: '950px',
      autoFocus: false,
      data:data
    })
  }
}