import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { User } from '../interfaces/court-cases.model';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { TableColumn } from '../../../../@vex/interfaces/table-column.interface';
import { courtCasesDummyData, aioTableLabels } from '../../../../static-data/aio-table-data';
import { CourtCasesCreateUpdateComponent } from '../court-cases-create-update/court-cases-create-update.component';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icSearch from '@iconify/icons-ic/twotone-search';
import icAdd from '@iconify/icons-ic/twotone-add';
import icCloud from '@iconify/icons-ic/twotone-backup';
import icFilterList from '@iconify/icons-ic/twotone-filter-list';
import { SelectionModel } from '@angular/cdk/collections';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import icFolder from '@iconify/icons-ic/twotone-folder';
import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { FormControl } from '@angular/forms';
import { untilDestroyed } from 'ngx-take-until-destroy';
import theme from '../../../../@vex/utils/tailwindcss';
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';
import icMap from '@iconify/icons-ic/twotone-map';
import icDetails from '@iconify/icons-ic/twotone-details';
import icShare from '@iconify/icons-ic/twotone-share';
import icTrackChanges from '@iconify/icons-ic/twotone-track-changes';
import { Router } from '@angular/router';
import icGroupWork from '@iconify/icons-ic/twotone-group-work';
import icNoteAdd from '@iconify/icons-ic/twotone-note-add';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icWarning from '@iconify/icons-ic/twotone-warning';
import icMeetingRoom from '@iconify/icons-ic/twotone-meeting-room';
import icsort from '@iconify/icons-ic/twotone-sort';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { CaseStatusChangeComponent } from '../case-status-change/case-status-change.component';
import { HearingsCreateUpdateComponent } from '../../hearings/hearings-create-update/hearings-create-update.component';
import { appModulesList } from 'src/environments/appModulesList';
import { CourtCaseInternalSharingComponent } from '../court-case-internal-sharing/court-case-internal-sharing.component';
import { CourtCaseSharesListComponent } from '../court-case-shares-list/court-case-shares-list.component';
import { ExternalSharingComponent } from '../external-sharing/external-sharing.component';
import { SharesListExternalUsersComponent } from '../shares-list-external-users/shares-list-external-users.component';
import { AddcourtcaseComponent } from '../addcourtcase/addcourtcase.component';

@Component({
  selector: 'vex-arbitration',
  templateUrl: './arbitration.component.html',
  styleUrls: ['./arbitration.component.scss'],
  animations: [
    fadeInUp400ms,
    stagger40ms
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'standard'
      } as MatFormFieldDefaultOptions
    }
  ]

})
export class ArbitrationComponent implements OnInit {
  layoutCtrl = new FormControl('fullwidth');
  private socketObject;

  public moduleDetails = appModulesList.arbitrationManagement;

  public totalRecords = 0;
  public startDate;
  public endDate;

  @Input()
  columns: TableColumn<User>[] = [
    { label: 'Actions', property: 'actions', type: 'button', visible: true },
    // { label: 'Case Number', property: 'caseNumber', type: 'text', visible: true, cssClasses: ['font-medium'] },
    // { label: 'Case Name', property: 'modifiedCaseName', type: 'text', visible: true, cssClasses: ['font-medium', "name-col"] },
    //{ label: 'Case Year', property: 'caseYear', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    //{ label: 'Assigned Advocate', property: 'advocateName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'In House User', property: 'inHouseUserName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Last Hearing Date', property: 'lastHearingDate', type: 'date', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Next Hearing Date', property: 'nextHearingDate', type: 'date', visible: true, cssClasses: ['font-medium'] },
    // { label: 'Case Type', property: 'courtCaseTypeAlias', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Division', property: 'divisionName', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Project', property: 'projectName', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Region', property: 'regionName', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Case Description', property: 'caseDescription', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Petitioner Name', property: 'petitionerName', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    // { label: 'Responder Name', property: 'responderName', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    // { label: 'Created By', property: '_createdBy', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    // { label: 'Labels', property: 'labels', type: 'button', visible: true },
  ];
  pageSize = environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;
  dataSource: MatTableDataSource<User> | null = new MatTableDataSource();
  selection = new SelectionModel<User>(true, []);
  searchCtrl = new FormControl();

  labels = aioTableLabels;

  icPhone = icPhone;
  icGroupWork = icGroupWork;
  icNoteAdd = icNoteAdd;
  icSecurity = icSecurity;
  icWarning = icWarning;
  icMeetingRoom = icMeetingRoom;
  icMail = icMail;
  icMap = icMap;
  icDetails = icDetails;
  icShare = icShare;
  icTrackChanges = icTrackChanges;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icCloud= icCloud;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;
  icsort = icsort;

  public userDetails;
  private userDetailUpdateSubscriber;
  public advocatesList = [];
  public isAdvocate: boolean = true;
  public selectedAdovocate: string = "";
  public selectedAdovocate_id: string = "";
  public sortvalue: string;
  public sortValue: string[] = [
    'Date-Wise', 'Name-Wise'
  ]
  theme = theme;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  search_value: any;

  constructor(private dialog: MatDialog,
    private router: Router,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackbar: MatSnackBar) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  get visibleColumns() {
    return this.columns.filter(column => column.visible).map(column => column.property);
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    return of(courtCasesDummyData.map(user => new User(user)));
  }

  ngOnInit() {

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getModifiedApiRegionsProjects, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          User.regionsProjectSiteModified = socketResponse.regionsProjectSitesList;
        }
      });
    }
    this.refreshData(this);

    let tmpObj = this;
    this.userDetails = this.commonServices.getLoggedInUserDetails();
    this.userDetailUpdateSubscriber = this.commonServices.getUserDetailsUpdateObserver().subscribe((userDetails) => {
      tmpObj.userDetails = userDetails;
    });

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.advocatesList = socketResponse.data;

          tmpObj.isAdvocate = socketResponse.isAdvocate;
          if (socketResponse.isAdvocate == true) {
            tmpObj.selectedAdovocate = socketResponse.data[0]._id;
          }
        }
        // tmpObj.refreshData(tmpObj);
      });
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.searchCtrl.valueChanges.pipe(
      untilDestroyed(this)
    ).subscribe(value => this.onFilterChange(value));
  }

  refreshData(tmpObj) {

    if(tmpObj.searchCtrl.value){
      this.search_value = tmpObj.searchCtrl.value
    }
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listarbitrationPagination,
        {
          filterText: tmpObj.searchCtrl.value,
          pageSize: tmpObj.pageSize,
          skip: tmpObj.paginator.pageIndex * tmpObj.pageSize,
          sortvalue: this.sortvalue,
          startDate: this.startDate,
          endDate: this.endDate,
          advocateId: this.selectedAdovocate_id,
        },
        function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.users = socketResponse.data;
            let usersList: User[] = [];
            for (let i = 0; i < socketResponse.data.length; i++) {
              usersList.push(new User(socketResponse.data[i]));
            }
            tmpObj.dataSource = new MatTableDataSource(usersList);
            tmpObj.totalRecords = socketResponse.totalRecords;

          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
    }
  }

  createCustomer() {
    if (environment.roleDetails) {

      // if (this.commonServices.isUserAllowedToCreateArbitration(this.moduleDetails)) {
        this.dialog.open(AddcourtcaseComponent, {
          panelClass: 'court-case-dialog-container',
          disableClose: true
        }).afterClosed().subscribe((user: User) => {
          console.log(" user after ",user)
          if (user) {
           
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.createarbitrationCase, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Created Court Case.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    // }
  }

  updateCustomer(user) {
    console.log(user,"update of case")
    if (environment.roleDetails) {

      if (this.commonServices.isUserAllowedToEditCourtCase(user, this.moduleDetails)) {
        this.dialog.open(AddcourtcaseComponent, {
          data: user,
          panelClass: 'court-case-dialog-container',
          disableClose: true
        }).afterClosed().subscribe(updatedCustomer => {
          if (updatedCustomer) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.updatearbitraionCase, updatedCustomer, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Updated Court Case.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteCustomer(customer: User) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToDeleteCourtCase(customer, this.moduleDetails)) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.deletearbitration, customer, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Deleted Court Case.', 'Okay', {
                duration: 4000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteusers(users: User[]) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    users.forEach(c => this.deleteCustomer(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();

    if (value.length >= 3 || value.length == 0) {
      this.refreshData(this);
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  ngOnDestroy() {
    if (this.userDetailUpdateSubscriber) {
      this.userDetailUpdateSubscriber.unsubscribe();
    }
  }

  goToDiscussionBoard(event, caseDetails: User) {
    event.stopPropagation();
    if (environment.roleDetails) {
      let subModulesKey = [];
      if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
        subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
      }

      if ((environment.roleDetails.name == 'super_admin' && environment.roleDetails.is_custom_role == false) || (subModulesKey.indexOf(this.moduleDetails.subModules.discussionBoard) != -1)) {
        this.router.navigate(["/app/discussion-board", caseDetails._id, environment.constants.arbitration], { queryParams: { type: 'arbitration' }});
      } else {
        this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  changeCaseStatus(courtCaseDetails) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditCourtCase(courtCaseDetails, this.moduleDetails)) {
        this.dialog.open(CaseStatusChangeComponent, {
          data: courtCaseDetails,
          disableClose: true
        }).afterClosed().subscribe((user: User) => {
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.changearbitrationCaseStatus, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Changed Court Case Status.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      }
    }
  }

  goToCaseDetailsPage(caseDetails: User) {
    // case-details/arbitration/
    console.log(caseDetails,"click")
    this.router.navigate(["/app/cases-management/arbitration", caseDetails._id]);
  }

  pageChangeHandler(eventDetails) {
    if (eventDetails && eventDetails.pageSize) {
      this.pageSize = eventDetails.pageSize;
    }
    this.refreshData(this);
  }

  showAddHearingDialog(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog.open(HearingsCreateUpdateComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: courtCaseDetails
        },
        disableClose: true
      }).afterClosed().subscribe((hearingDetails) => {
        if (hearingDetails) {
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.createHearing, hearingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Created Hearing.', 'Okay', {
                  duration: 4000
                });
                tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }

  shareWithInternalUser(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog.open(CourtCaseInternalSharingComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: courtCaseDetails,
          userPermissions: null
        },
        disableClose: true
      }).afterClosed().subscribe((internalSharingDetails) => {
        if (internalSharingDetails) {
          internalSharingDetails.courtCaseId = courtCaseDetails._id;
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.shareCourtCaseInternalUser, internalSharingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Shared with application user.', 'Okay', {
                  duration: 4000
                });
                tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }

  showInternalShareList(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog.open(CourtCaseSharesListComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: courtCaseDetails,
          userPermissions: {}
        },
        disableClose: true
      }).afterClosed().subscribe((internalSharingDetails) => {
      });
    }
  }

  shareWithExternalUser(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog.open(ExternalSharingComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: courtCaseDetails,
          userPermissions: null
        },
        disableClose: true
      }).afterClosed().subscribe((externalSharingDetails) => {
        if (externalSharingDetails) {
          externalSharingDetails.courtCaseId = courtCaseDetails._id;
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.shareCourtCaseExternalUser, externalSharingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Shared with External User.', 'Okay', {
                  duration: 4000
                });
                tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }

  showExternalShareList(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog.open(SharesListExternalUsersComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: courtCaseDetails,
          userPermissions: {}
        },
        disableClose: true
      }).afterClosed().subscribe((externalSharingDetails) => {
      });
    }
  }

  radioChange(e) {
    this.sortvalue = e.value
    this.refreshData(this);

  }

  datesRangeSelectedHandler(event) {
    this.startDate = event.startDate.format("YYYY-MM-DD")
    this.endDate = event.endDate.format("YYYY-MM-DD")
    this.refreshData(this);
  }

  getAdvocateName(advocateDetails) {
    let name = "N/A";

    if (advocateDetails) {
      if (advocateDetails.userType == "firm") {
        name = advocateDetails.firmName;
      } else {
        name = advocateDetails.firstName + " " + advocateDetails.lastName;
      }
    }

    return name;
  }

  advocateChangeHandler() {
    this.selectedAdovocate_id = this.selectedAdovocate
    this.refreshData(this);
  }

  createCase(){
    this.router.navigate(["/app/cases-management/add-case"]);
  }

}
