import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { CommonService } from "src/app/services/core/common.service";
import { SocketService } from "src/app/services/core/socket.service";
import { environment } from "src/environments/environment";
import { AllahabadhighcourtService } from "../highcourt/allahabadhighcourt.service";

@Component({
  selector: "vex-allahabad-detail",
  templateUrl: "./allahabad-detail.component.html",
  styleUrls: ["./allahabad-detail.component.scss"],
})
export class AllahabadDetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<AllahabadDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private allahabadhighcourtservice: AllahabadhighcourtService,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackBar: MatSnackBar
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  private socketObject;

  getSingleJuridictionData = [];
  caseDetails:any;
  Bench:any;
  handleMatSprnner=true;
  showHeader=false
  msg='';
  serverErrOfCaseStatus='';
  serverErrOfpetAndResAdvData=''
  serverErrOfcategoryDetails=''
  serverErrOfiaDetails=''
  serverErrOflowerCourtDetails=''
  serverErrOfcrimeDetails=''
  serverErrOflistingHistory=''
  serverErrOfordersJudgement=''
  MatSprnnerOfSection=false;

  ngOnInit() {
    if (this.data) {
      this.Bench = this.data.court_bench
      console.log(this.data)
      this.allahabadhighcourtservice.postData("caseDetails", this.data).subscribe(
        (res) => {
          console.log(res)
          if (res.status == true) {
            this.caseDetails = res.caseDetails[0];
            console.log(this.caseDetails)
            this.handleMatSprnner=false
            this.showHeader=true
          } else {
            this.handleMatSprnner=false
            this.showHeader=false
            this.msg='No data found'
          }
        },
        (err) => {
          this.handleMatSprnner=false
          this.showHeader=false
          this.msg='Facing Some Server Issue Please Try After Some Time'
        }
      );
    }
  }


  caseStatusArr=[]
  caseStatusData;
  countOfcaseStatus=0;
  caseStatus(){
    if(this.countOfcaseStatus==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("caseStatus", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.caseStatusArr.push(...res.caseStatus);
            this.caseStatusData=res.caseStatus[0];

            this.countOfcaseStatus=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOfcaseStatus=1;
          }
        },
        (err) => {
          this.countOfcaseStatus=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOfCaseStatus='Facing Some Server Issue in caseStatus Please Try After Some Time'
        }
      );
    }
  }

  petAndResAdvData=[];
  countOfpetAndResAdv=0;
  petAndResAdv(){
    if(this.countOfpetAndResAdv==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("petAndResAdv", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.petAndResAdvData.push(...res.petAndResAdv);
            // this.caseStatusData=res.caseStatus[0];
            console.log(this.petAndResAdvData)

            this.countOfpetAndResAdv=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOfpetAndResAdv=1;
          }
        },
        (err) => {
          this.countOfpetAndResAdv=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOfpetAndResAdvData='Facing Some Server Issue in Petitioner/Respondent and their Advocate(s) Please Try After Some Time'
        }
      );
    }
  }

  categoryDetailsArr=[];
  categoryDetailsData;
  countOfcategoryDetails=0;
  categoryDetails(){
    if(this.countOfcategoryDetails==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("categoryDetails", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.categoryDetailsArr.push(...res.categoryDetails);
            // this.caseStatusData=res.caseStatus[0];
            this.categoryDetailsData=res.categoryDetails[0]

            this.countOfcategoryDetails=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOfcategoryDetails=1;
          }
        },
        (err) => {
          this.countOfcategoryDetails=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOfcategoryDetails='Facing Some Server Issue in categoryDetails Please Try After Some Time'
        }
      );
    }
  }

  iaDetailsData=[];
  countOfiaDetails=0;
  iaDetails(){
    if(this.countOfiaDetails==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("iaDetails", obj).subscribe(
        (res) => {
          if (res.status == true) {
            this.iaDetailsData.push(...res.iaDetails);
            console.log(this.iaDetailsData)

            this.countOfiaDetails=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOfiaDetails=1;
          }
        },
        (err) => {
          this.countOfiaDetails=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOfiaDetails='Facing Some Server Issue in iaDetails Please Try After Some Time'
        }
      );
    }
  }

  lowerCourtDetailsArr=[]
  lowerCourtDetailsData;
  countOflowerCourtDetails=0;
  lowerCourtDetails(){
    if(this.countOflowerCourtDetails==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("lowerCourtDetails", obj).subscribe(
        (res) => {
        console.log(res)

          if (res.status == true) {
            this.lowerCourtDetailsArr.push(...res.lowerCourtDetails);
            this.lowerCourtDetailsData = res.lowerCourtDetails[0]

            this.countOflowerCourtDetails=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOflowerCourtDetails=1;
          }
        },
        (err) => {
          this.countOflowerCourtDetails=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOflowerCourtDetails='Facing Some Server Issue in Petitioner/Respondent and their Advocate(s) Please Try After Some Time'
        }
      );
    }
  }

  crimeDetailsArr=[]
  crimeDetailsData;
  countOfcrimeDetails=0;
  crimeDetails(){
    if(this.countOfcrimeDetails==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("crimeDetails", obj).subscribe(
        (res) => {
        console.log(res)

          if (res.status == true) {
            this.crimeDetailsArr.push(...res.crimeDetails);
            this.crimeDetailsData = res.crimeDetails[0];
            console.log(this.crimeDetailsData)

            this.countOfcrimeDetails=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOfcrimeDetails=1;
          }
        },
        (err) => {
          this.countOfcrimeDetails=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOfcrimeDetails='Facing Some Server Issue in crimeDetails Please Try After Some Time'
        }
      );
    }
  }

  listingHistoryData=[];
  countOflistingHistory=0;
  listingHistory(){
    if(this.countOflistingHistory==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("listingHistory", obj).subscribe(
        (res) => {
        console.log(res)

          if (res.status == true) {
            this.listingHistoryData.push(...res.listingHistory);
            console.log(this.listingHistoryData)

            this.countOflistingHistory=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOflistingHistory=1;
          }
        },
        (err) => {
          this.countOflistingHistory=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOflistingHistory='Facing Some Server Issue Petitioner/Respondent and their Advocate(s) caseStatus Please Try After Some Time'
        }
      );
    }
  }

  ordersJudgementData=[];
  countOfordersJudgement=0;
  ordersJudgement(){
    if(this.countOfordersJudgement==0){
      this.MatSprnnerOfSection=true;
      let obj = {
        court_case_id: this.caseDetails.id
      }
      this.allahabadhighcourtservice.postData("ordersJudgement", obj).subscribe(
        (res) => {
        console.log(res)

          if (res.status == true) {
            this.ordersJudgementData.push(...res.ordersJudgement);
            console.log(this.ordersJudgementData)

            this.countOfordersJudgement=1;
            this.MatSprnnerOfSection=false;
            
          }else{
            this.MatSprnnerOfSection=false;
            this.countOfordersJudgement=1;
          }
        },
        (err) => {
          this.countOfordersJudgement=1;
          this.MatSprnnerOfSection=false;
          this.serverErrOfordersJudgement='Facing Some Server Issue Petitioner/Respondent and their Advocate(s) caseStatus Please Try After Some Time'
        }
      );
    }
  }

  caesDetailData={}
  saveData(){
    this.caesDetailData['serverType']=this.data.serverType;
    this.caesDetailData['caseName']=this.data.caseName;
    this.caesDetailData['state_code']=this.data.state_code;
    this.caesDetailData['case_cino']=this.data.case_cino;
    this.caesDetailData['court_bench']=this.data.court_bench;
    this.caesDetailData['court_case_id']=this.caseDetails.id;
    this.caesDetailData['cnr_number']=this.caseDetails.cnr;
    this.caesDetailData['case_year']=this.data.case_year;
    this.caesDetailData['case_type']=this.data.case_type;
    this.caesDetailData['case_number']=this.data.case_number;
    this.caesDetailData['petitioner']=this.data.petitioner;
    this.caesDetailData['respondent']=this.data.respondent;

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createallahabadhighcourt,  this.caesDetailData , function (socketResponse) {
        console.log(socketResponse)
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created high court cases', 'Okay', {
            duration: 4000
          });
          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
          // this.dialogRef.close();
        }
      });
      // this.dialogRef.close();
    }
  }

  // caseStatusArr=[]
  // caseStatusData;
  // caseStatus(){
  //   let obj = {
  //     court_case_id: this.caseDetails.id
  //   }
  //   this.allahabadhighcourtservice.postData("caseStatus", obj).subscribe(
  //     (res) => {
  //       if (res.status == true) {
  //         this.caseStatusArr.push(...res.caseStatus);
  //         this.caseStatusData=res.caseStatus[0]
  //         console.log(this.caseStatusData)
  //       } else {
  //         this.handleMatSprnner=false
  //         this.showHeader=false
  //         this.msg='No data found'
  //       }
  //     },
  //     (err) => {
  //       this.handleMatSprnner=false
  //       this.showHeader=false
  //       this.msg='Facing Some Server Issue Please Try After Some Time'
  //     }
  //   );
  // }

    // open pdf
    goToLink(url: string) {
      if (url != null) {
        window.open(url, "_blank");
      } else {
        alert("No data Found");
      }
    }
  
    step = 0;
  
  
    setStep(index: number) {
      this.step = index;
    }
  
    nextStep() {
      this.step++;
      if(this.step==1){
        this.caseStatus();
      }
      if(this.step==2){
        this.petAndResAdv();
      }
      if(this.step==3){
        this.categoryDetails();
      }
      if(this.step==4){
        this.iaDetails();
      }
      if(this.step==5){
        this.lowerCourtDetails();
      }
      if(this.step==6){
        this.crimeDetails();
      }
      if(this.step==7){
        this.listingHistory();
      }
      if(this.step==8){
        this.ordersJudgement();
      }
    }
  
    prevStep() {
      this.step--;
      if(this.step==1){
        this.caseStatus();
      }
      if(this.step==2){
        this.petAndResAdv();
      }
      if(this.step==3){
        this.categoryDetails();
      }
      if(this.step==4){
        this.iaDetails();
      }
      if(this.step==5){
        this.lowerCourtDetails();
      }
      if(this.step==6){
        this.crimeDetails();
      }
      if(this.step==7){
        this.listingHistory();
      }
      if(this.step==8){
        this.ordersJudgement();
      }
    }
}
