import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { responseMessages } from './service.constants';
import { FormGroup, AsyncValidatorFn, AbstractControl } from '@angular/forms';
import { Observable, Subscriber, of } from 'rxjs';
import { SocketService } from './socket.service';
import { CommonService } from './common.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    private socketObject = null;
    static updateUserId;

    constructor(private socketService: SocketService,
        private commonServices: CommonService,) {

        const token = this.commonServices.getAccessToken();
        if (token) {
            if (!this.socketService.isSocketConnected()) {
                this.socketService.initSocket(token);
            }
            this.socketObject = this.socketService.getSocketObject();
        }
    }

    existingEmailValidation(): AsyncValidatorFn {
        console.log(" email validation running")
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            if (control.value == "") {
                return of(null);
            } else {
                let tmpObj = this;

                /* Unique Email validation : Start */
                return new Promise((resolve, reject) => {
                    console.log(ValidationService.updateUserId,"ValidationService.updateUserId email")
                    tmpObj.socketObject.emit(environment.socketEvents.uniqueEmailValidation, { _id: ValidationService.updateUserId, email: control.value }, (socketResponse) => {
                        
                        if (socketResponse.status == responseMessages.status.ok) {
                            control.setErrors({ existingEmail: { value: control.value } });
                        } else {
                            control.setErrors(null);
                        }
                    });
                });
                /* Unique Email validation : End */
            }
        };
    }
    notexistingarbitratorValidation(): AsyncValidatorFn {
        console.log(" email validation running")
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            if (control.value == "") {
                return of(null);
            } else {
                let tmpObj = this;

                /* Unique Email validation : Start */
                return new Promise((resolve, reject) => {
                    console.log(ValidationService,"ValidationService.updateUserId email")
                    tmpObj.socketObject.emit(environment.socketEvents.uniquearbitratorValidation, {  name: control.value }, (socketResponse) => {
                        console.log(socketResponse,"ValidationService.updateUserId ")
                        if (socketResponse.status == responseMessages.status.ok) {
                            control.setErrors(null);
                        } else {
                            control.setErrors({ notexistarbitrator: { value: control.value } });
                          
                        }
                    });
                });
                /* Unique Email validation : End */
            }
        };
    }

    existingPhoneValidation(): AsyncValidatorFn {
       
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            if (control.value == "") {
                return of(null);
            } else {
              
                let tmpObj = this;

                /* Unique Phone validation : Start */
                return new Promise((resolve, reject) => {
                    console.log(ValidationService.updateUserId,"this validation running")
                    tmpObj.socketObject.emit(environment.socketEvents.uniquePhoneValidation, { _id: ValidationService.updateUserId, phone: control.value }, (socketResponse) => {
                      
                        if (socketResponse.status == responseMessages.status.ok) {
                            control.setErrors({ existingPhone: { value: control.value } });
                        } else {
                            control.setErrors(null);
                        }
                    });
                });
                /* Unique Email validation : End */
            }
        };
    }

    existingEmpIdValidation(): AsyncValidatorFn {
        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            if (control.value == "") {
                return of(null);
            } else {
                let tmpObj = this;

                /* Unique Email validation : Start */
                return new Promise((resolve, reject) => {
                    tmpObj.socketObject.emit(environment.socketEvents.uniqueEmpIdValidation, { _id: ValidationService.updateUserId, empCode: control.value }, (socketResponse) => {
                        if (socketResponse.status == responseMessages.status.ok) {

                            control.setErrors({ existingEmpId: { value: control.value } });
                        } else {
                            control.setErrors(null);
                        }
                    });
                });
                /* Unique Email validation : End */
            }
        };
    }


    oldPasswordValidation(): AsyncValidatorFn {

        return (
            control: AbstractControl
        ):
            | Promise<{ [key: string]: any } | null>
            | Observable<{ [key: string]: any } | null> => {
            if (control.value == "") {
                return of(null);
            } else {
                let tmpObj = this;

                /* Old Password validation : Start */
                return new Promise((resolve, reject) => {
                    tmpObj.socketObject.emit(environment.socketEvents.oldPasswordValidation, { _id: ValidationService.updateUserId, oldPassword: control.value }, (socketResponse) => {
                        if (socketResponse.status != responseMessages.status.ok) {
                            control.setErrors({ wrongPassword: { value: control.value } });
                        } else {
                            control.setErrors(null);
                        }
                    });
                });
                /* Old Password validation : End */
            }
        };

    }
}