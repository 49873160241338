import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PunjabService } from '../highcourt/punjab.service';
import { MatDialog } from "@angular/material/dialog";
import { CopypetiondetailComponent } from '../copypetiondetail/copypetiondetail.component';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'vex-punjab-detail',
  templateUrl: './punjab-detail.component.html',
  styleUrls: ['./punjab-detail.component.scss']
})
export class PunjabDetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PunjabDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private punjabservice:PunjabService,
    private dialog: MatDialog,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackBar: MatSnackBar
  ) { 
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }
  private socketObject;

  showmsg:String=''
  showHeader:boolean = false;
  handleMatSprnner:boolean = false;
  caseDetail:any;
  MatSprnnerOfSection:boolean=false
  obj={}
  tabCollection=[];
  case_detail:any;
  party_detail:any

  ngOnInit() {
    let obj = {
      case_type:this.data.case_type,
      case_no:this.data.case_no,
      case_year:this.data.case_year
    }
    this.case_detail=`${this.data.case_type}-${this.data.case_no}-${this.data.case_year}`;
    this.party_detail=this.data.case_name;
    this.tabCollection.push({case_name:`${this.data.case_type}-${this.data.case_no}-${this.data.case_year}`,case_type:this.data.case_type,case_no:this.data.case_no,case_year:this.data.case_year})

    this.handleMatSprnner=true;
    this.punjabservice.postData('caseDetails',obj).subscribe((res)=>{
      if(res.status){
        this.showHeader=true
        this.caseDetail=res.caseDetails;
        this.obj = {court_case_id: this.caseDetail.id}
        this.handleMatSprnner=false
      }else{
        this.showmsg=res.message
        this.handleMatSprnner=false
      }
    },(err)=>{
      this.dialogRef.close();
      this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    })
  }

  relatedcases;
  relatedCases(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('relatedCases',this.obj).subscribe((res)=>{
        if(res.status){
          this.relatedcases=res.relatedCases;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  caseListing;
  caseListingDetails(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('caseListingDetails',this.obj).subscribe((res)=>{
        if(res.status){
          this.caseListing=res.caseListingDetails;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  detailOfAppeal;
  detailOfAppeals(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('detailOfAppeals',this.obj).subscribe((res)=>{
        if(res.status){
          this.detailOfAppeal=res.detailOfAppeals;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  impungOrder;
  impungOrderDetail(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('impungOrderDetail',this.obj).subscribe((res)=>{
        if(res.status){
          this.impungOrder=res.detailOfAppeals;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  firDetail;
  firDetails(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('firDetails',this.obj).subscribe((res)=>{
        if(res.status){
          this.firDetail=res.firDetails;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  copypetition;
  copyPetition(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('copyPetition',this.obj).subscribe((res)=>{
        if(res.status){
          this.copypetition=res.copyPetition;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  lowerCourt;
  lowerCourtDetails(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('lowerCourtDetails',this.obj).subscribe((res)=>{
        if(res.status){
          this.lowerCourt=res.lowerCourtDetails;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }
  judgment;
  judgmentDetails(){
      this.MatSprnnerOfSection=true;
      this.punjabservice.postData('judgmentDetails',this.obj).subscribe((res)=>{
        if(res.status){
          this.judgment=res.judgmentDetails;
          this.MatSprnnerOfSection=false
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
      })
  }

  caseDetailPunjab(data,code){
    if(code==1){
      this.step = 0;
      let obj={
        case_type: data.case_type,
        case_no: data.case_no,
        case_year: data.case_year
      }
      this.handleMatSprnner=true;
      this.showHeader=false
      if(this.tabCollection[1]){
        this.tabCollection[1]={case_name:`${data.case_type}-${data.case_no}-${data.case_year}`,case_type:data.case_type,case_no:data.case_no,case_year:data.case_year}
      }else{
        this.tabCollection.push({case_name:`${data.case_type}-${data.case_no}-${data.case_year}`,case_type:data.case_type,case_no:data.case_no,case_year:data.case_year})
      }
  
      this.punjabservice.postData('caseDetails',obj).subscribe((res)=>{
        if(res.status){
          this.showHeader=true
          this.caseDetail=res.caseDetails;
          this.party_detail=this.caseDetail.party_detail
          this.case_detail=`${this.caseDetail.case_type}-${this.caseDetail.case_no}-${this.caseDetail.case_year}`
          this.obj = {court_case_id: this.caseDetail.id}
          this.handleMatSprnner=false;
        }else{
          this.showmsg=res.message
          this.handleMatSprnner=false;
        }
      },(err)=>{
        this.handleMatSprnner=false;
      })
    }else if(code==0){
      this.step = 0;
      let obj={
        case_type: data.case_type,
        case_no: data.case_no,
        case_year: data.case_year
      }
      this.handleMatSprnner=true;
      this.showHeader=false
      this.punjabservice.postData('caseDetails',obj).subscribe((res)=>{
        if(res.status){
          this.showHeader=true
          this.caseDetail=res.caseDetails;
          this.party_detail=this.caseDetail.party_detail
          this.case_detail=`${this.caseDetail.case_type}-${this.caseDetail.case_no}-${this.caseDetail.case_year}`
          this.obj = {court_case_id: this.caseDetail.id}
          this.handleMatSprnner=false;
        }else{
          this.showmsg=res.message
          this.handleMatSprnner=false;
        }
      },(err)=>{
        this.handleMatSprnner=false;
      })
    }else if(code==2){
      this.step = 0;
      let obj={
        case_type: data.case_type,
        case_no: data.case_no,
        case_year: data.case_year
      }
      this.handleMatSprnner=true;
      this.showHeader=false
      if(this.tabCollection[2]){
        this.tabCollection[2]={case_name:`${data.case_type}-${data.case_no}-${data.case_year}`,case_type:data.case_type,case_no:data.case_no,case_year:data.case_year}
      }else{
        this.tabCollection.push({case_name:`${data.case_type}-${data.case_no}-${data.case_year}`,case_type:data.case_type,case_no:data.case_no,case_year:data.case_year})
      }

      this.punjabservice.postData('caseDetails',obj).subscribe((res)=>{
        if(res.status){
          this.showHeader=true
          this.caseDetail=res.caseDetails;
          this.party_detail=this.caseDetail.party_detail
          this.case_detail=`${this.caseDetail.case_type}-${this.caseDetail.case_no}-${this.caseDetail.case_year}`
          this.obj = {court_case_id: this.caseDetail.id}
          this.handleMatSprnner=false;
        }else{
          this.showmsg=res.message
          this.handleMatSprnner=false;
        }
      },(err)=>{
        this.handleMatSprnner=false;
      })
    }else if(code==3){
      this.step = 0;
      let obj={
        case_type: data.case_type,
        case_no: data.case_no,
        case_year: data.case_year
      }
      this.handleMatSprnner=true;
      this.showHeader=false
      if(this.tabCollection[3]){
        this.tabCollection[3]={case_name:`${data.case_type}-${data.case_no}-${data.case_year}`,case_type:data.case_type,case_no:data.case_no,case_year:data.case_year}
      }else{
        this.tabCollection.push({case_name:`${data.case_type}-${data.case_no}-${data.case_year}`,case_type:data.case_type,case_no:data.case_no,case_year:data.case_year})
      }

      this.punjabservice.postData('caseDetails',obj).subscribe((res)=>{
        if(res.status){
          this.showHeader=true
          this.caseDetail=res.caseDetails;
          this.party_detail=this.caseDetail.party_detail
          this.case_detail=`${this.caseDetail.case_type}-${this.caseDetail.case_no}-${this.caseDetail.case_year}`
          this.obj = {court_case_id: this.caseDetail.id}
          this.handleMatSprnner=false;
        }else{
          this.showmsg=res.message
          this.handleMatSprnner=false;
        }
      },(err)=>{
        this.handleMatSprnner=false;
      })
    }
  }
  copyPetitionDetail(data) {
    let obj = {};
    obj["court_case_id"] = data.court_case_id;
    obj["copy_petition_id"] = data.id;

    this.dialog.open(CopypetiondetailComponent, {
      height: "350px",
      width: "700px",
      autoFocus: false,
      data: obj,
    });
  }
  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert("No data Found");
    }
  }

  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
    if(this.step==1){
      this.relatedCases()
    }
    else if(this.step==2){
      this.caseListingDetails()
    }
    else if(this.step==3){
      this.detailOfAppeals()
    }
    else if(this.step==4){
      this.impungOrderDetail()
    }
    else if(this.step==5){
      this.firDetails()
    }
    else if(this.step==6){
      this.copyPetition()
    }
    else if(this.step==7){
      this.lowerCourtDetails()
    }
    else if(this.step==8){
      this.judgmentDetails()
    }
  }

  prevStep() {
    this.step--;
    if(this.step==1){
      this.relatedCases()
    }
    else if(this.step==2){
      this.caseListingDetails()
    }
    else if(this.step==3){
      this.detailOfAppeals()
    }
    else if(this.step==4){
      this.impungOrderDetail()
    }
    else if(this.step==5){
      this.firDetails()
    }
    else if(this.step==6){
      this.copyPetition()
    }
    else if(this.step==7){
      this.lowerCourtDetails()
    }
    else if(this.step==8){
      this.judgmentDetails()
    }
  }

  saveData(){
    let obj = {
    //   registration_no:this.caseDetail.registration_no,
      case_name:this.data.case_name,
      state_code:this.data.state_code,
      serverType:this.data.serverType,
      case_type:this.caseDetail.case_type,
      case_number:this.caseDetail.case_no,
      case_year:this.caseDetail.case_year,
    //   case_category:this.caseDetail.case_category,
      next_hearing_date:this.caseDetail.next_date,
      respondent:this.data.petitioner,
      petitioner:this.data.respondent
    }
    console.log(obj,'2555555555555')
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createpunjabhighcourt, obj , function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created high court cases', 'Okay', {
            duration: 4000
          });
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
  }

}
