import { Component, ElementRef, HostBinding, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { LayoutService } from '../../services/layout.service';
import icBookmarks from '@iconify/icons-ic/twotone-bookmarks';
import emojioneUS from '@iconify/icons-emojione/flag-for-flag-united-states';
import emojioneDE from '@iconify/icons-emojione/flag-for-flag-germany';
import icMenu from '@iconify/icons-ic/twotone-menu';
import { ConfigService } from '../../services/config.service';
import { map } from 'rxjs/operators';
import icPersonAdd from '@iconify/icons-ic/twotone-person-add';
import icAssignmentTurnedIn from '@iconify/icons-ic/twotone-assignment-turned-in';
import icBallot from '@iconify/icons-ic/twotone-ballot';
import icDescription from '@iconify/icons-ic/twotone-description';
import icAssignment from '@iconify/icons-ic/twotone-assignment';
import icReceipt from '@iconify/icons-ic/twotone-receipt';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icDoneAll from '@iconify/icons-ic/twotone-done-all';
import icCalendarToday from '@iconify/icons-ic/twotone-calendar-today';
import icinsert_drive_file from '@iconify/icons-ic/twotone-insert-drive-file';
import icpages from '@iconify/icons-ic/twotone-pages';
import { NavigationService } from '../../services/navigation.service';
import icArrowDropDown from '@iconify/icons-ic/twotone-arrow-drop-down';
import { PopoverService } from '../../components/popover/popover.service';
import { MegaMenuComponent } from '../../components/mega-menu/mega-menu.component';
import icSearch from '@iconify/icons-ic/twotone-search';
import theme from '../../../@vex/utils/tailwindcss';
import { environment } from "src/environments/environment";
import { Router } from '@angular/router';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { HearingsCreateUpdateComponent } from 'src/app/pages/hearings/hearings-create-update/hearings-create-update.component';
import { AppointmentsCreateUpdateComponent } from 'src/app/pages/appointments/appointments-create-update/appointments-create-update.component';
import { LegalNoticesCreateUpdateComponent } from 'src/app/pages/legal-notices/legal-notices-create-update/legal-notices-create-update.component';
import { CourtCasesCreateUpdateComponent } from 'src/app/pages/cases/court-cases-create-update/court-cases-create-update.component';
import { User } from 'src/app/pages/cases/interfaces/court-cases.model';
import { CasesCreateUpdateComponent } from 'src/app/pages/cases/cases-create-update/cases-create-update.component';

@Component({
  selector: 'vex-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public environment = environment;
  @Input() mobileQuery: boolean;
  private socketObject;

  public todayHearings;
  public todayAppointments;
  public todayEvents;

  themeObj = theme;

  @Input()
  @HostBinding('class.shadow-b')
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'horizontal'));
  isVerticalLayout$ = this.configService.config$.pipe(map(config => config.layout === 'vertical'));
  isNavbarInToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'in-toolbar'));
  isNavbarBelowToolbar$ = this.configService.config$.pipe(map(config => config.navbar.position === 'below-toolbar'));

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icSecurity = icSecurity;
  icDoneAll = icDoneAll;
  icCalendarToday = icCalendarToday;
  icArrowDropDown = icArrowDropDown;
  icinsert_drive_file = icinsert_drive_file;
  icpages = icpages;
  public companydetail

  constructor(private layoutService: LayoutService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private socketService: SocketService,
    private commonServices: CommonService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getToolbarStats, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.todayAppointments = socketResponse.appointments;
          tmpObj.todayHearings = socketResponse.hearings;

          tmpObj.todayEvents = tmpObj.todayAppointments.length + tmpObj.todayHearings.length;
          tmpObj.cdr.detectChanges();
        }
      });

      tmpObj.socketObject.emit(environment.socketEvents.getCompanydetail, function (socketResponse) {

        if(socketResponse.status == 'OK'){
          tmpObj.companydetail = socketResponse.data[0];

        }
    // companydetail
    
      });


      
    }
  }

  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }
  openQuickpanels(data) {
    this.layoutService.openQuickpanele(data);
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }
  gotowatchlist(){
    this.router.navigate(["/app/cases-management/mycases"]);
  }

  openMegaMenu(origin: ElementRef | HTMLElement) {
    this.popoverService.open({
      content: MegaMenuComponent,
      origin,
      position: [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });
  }

  openSearch() {
    this.layoutService.openSearch();
  }

  onRoleChange() {
    this.router.navigate(["/app"]);
  }


  showAddLegalNoticeDialog() {
    this.dialog.open(LegalNoticesCreateUpdateComponent, {
      panelClass: 'legal-notice-dialog-container',
      disableClose: true
    }).afterClosed().subscribe((legalNoticeDetails) => {

      if (legalNoticeDetails) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.createLegalNotice, legalNoticeDetails, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Legal Notice.', 'Okay', {
                duration: 4000
              });
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
      }
    });
  }

  showAddAppointmentDialog() {
    this.dialog.open(AppointmentsCreateUpdateComponent, {
      disableClose: true
    }).afterClosed().subscribe((appointmentDetails) => {
      if (appointmentDetails) {
        if(this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit( environment.socketEvents.createAppointment, appointmentDetails, function(socketResponse) {
            if(socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Appointment.', 'Okay', {
                duration: 4000
              });

            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
      }
    });
  }

  showAddHearingDialog() {
    this.dialog.open(HearingsCreateUpdateComponent, {
      panelClass: 'hearings-dialog-container',
      disableClose: true
    }).afterClosed().subscribe((hearingDetails) => {
      if (hearingDetails) {
        if(this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit( environment.socketEvents.createHearing, hearingDetails, function(socketResponse) {
            if(socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Hearing.', 'Okay', {
                duration: 4000
              });
              
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 4000
              });
            }
          });
        }
      }
    });
  }

  showAddOutCourtCasesDialog(){
    // if (environment.roleDetails) {
    //   if (this.commonServices.isUserAllowedToCreateMutualCase(this.moduleDetails)) {
        this.dialog.open(CasesCreateUpdateComponent, {
          panelClass: 'mutual-case-dialog-container',
          disableClose: true
        }).afterClosed().subscribe((user: User) => {
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.createMutualCase, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Created Mutal Case.', 'Okay', {
                    duration: 4000
                  });
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      // } else {
      //   this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
      //     duration: 4000
      //   });
      // }
    //}
  }

  showAddCourtCasesDialog(){
    //if (this.commonServices.isUserAllowedToCreateCourtCase(this.moduleDetails)) {
      this.dialog.open(CourtCasesCreateUpdateComponent, {
        panelClass: 'court-case-dialog-container',
        disableClose: true
      }).afterClosed().subscribe((user: User) => {
        if (user) {
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.createCourtCase, user, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Created Court Case.', 'Okay', {
                  duration: 4000
                });
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    // } else {
    //   this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
    //     duration: 4000
    //   });
    // }
  }

}
