import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import ictitle from '@iconify/icons-ic/twotone-title';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import icPageView from '@iconify/icons-ic/twotone-pageview';
import iclocation_city from '@iconify/icons-ic/twotone-location-city';
import icperson_pin from '@iconify/icons-ic/twotone-person-pin';
import { MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { fadeInUp400ms } from 'src/@vex/animations/fade-in-up.animation';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { appModulesList } from 'src/environments/appModulesList';
import { CustomValidators } from 'src/app/custom_validator/validator';

@Component({
  selector: 'vex-cases-create-update',
  templateUrl: './cases-create-update.component.html',
  styleUrls: [
    './cases-create-update.component.scss',
    '../../../../../node_modules/quill/dist/quill.snow.css',
    '../../../../@vex/styles/partials/plugins/_quill.scss'
  ],
  animations: [fadeInUp400ms],
  providers: [CustomValidators]
})
export class CasesCreateUpdateComponent implements OnInit {

  public moduleDetails = appModulesList.mutualCaseManagement;
  private socketObject;
  selectedFileName;

  public selectedTabIndex: number = 0;
  private totalTabGroups: number = 7;

  public billingTypeOptions = [
    { name: "Monthly", value: "Monthly" },
    { name: "Lumpsum", value: "Lumpsum" },
  ];

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;

  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icDeleteForever = icDeleteForever;
  icPageView = icPageView;
  iclocation_city = iclocation_city;
  icperson_pin = icperson_pin;


  public userDetails;
  public sharingDetails;

  public departmentsList = [];
  public divisionsList = [];
  public caseTypeList = [];
  public allProjectSitesList = [];
  public firstPartyProjectSitesList = [];
  public secondPartyProjectSitesList = [];
  public regionsList = [];
  public legalNoticesList = [];
  public advocatesList = [];
  public retainerlist = [];
  public inHouseUsersList = [];
  public countriesList = [];
  public statesList = {
    "firstParty": [],
    "secondParty": []
  };
  public citiesList = {
    "firstParty": [],
    "secondParty": []
  };

  constructor(@Inject(MAT_DIALOG_DATA) public mutualCaseDetails: any,
    private dialogRef: MatDialogRef<CasesCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    private _layoutService: LayoutService,
    private customValidators: CustomValidators,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    this.userDetails = this.commonServices.getLoggedInUserDetails();

    this.initSharingDetails(this);

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listDepartments, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.departmentsList = socketResponse.data;
        }
      });
      this.socketObject.emit(environment.socketEvents.listCaseAbbreviation, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.caseTypeList = socketResponse.data;
        }
      });
      this.socketObject.emit(environment.socketEvents.listsDivisions, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.divisionsList = socketResponse.data;
        }
      });

      tmpObj.socketObject.emit(environment.socketEvents.listLegalNotice, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.legalNoticesList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listAdvocateFirm, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.advocatesList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.listofretanership, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.retainerlist = socketResponse.data;
        }
      });
      // retainerlist

      this.socketObject.emit(environment.socketEvents.listUsersRoleUser, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.inHouseUsersList = socketResponse.data;
        }
      });

      this.socketObject.emit(environment.socketEvents.getCountries, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.countriesList = socketResponse.data;

        }
      });

      this.socketObject.emit(environment.socketEvents.getApiRegionsProjects, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.regionsList = socketResponse.regionsList;
          tmpObj.allProjectSitesList = socketResponse.projectSiteList;

          if (tmpObj.isUpdateMode()) {
            tmpObj.firstPartyRegionsChangeHandler();
            tmpObj.secondPartyRegionsChangeHandler();
          }
        }
      });
    }

    if (this.mutualCaseDetails) {
      this.mode = 'update';
    } else {
      this.mutualCaseDetails = {} as User;
    }

    this.form = this.fb.group({
      _id: this.mutualCaseDetails._id || null,
      firstParty: this.fb.group({
        partyType: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.partyType || null : null, Validators.required],
        partyName: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.partyName || null : null, [Validators.required, this.customValidators.nameValidator]],
        division: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.division._id || null : null, Validators.required],
        regions: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.regions || null : null, Validators.required],
        project: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.project || null : null, Validators.required],
        country: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.country || null : null, Validators.required],
        state: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.state || null : null, Validators.required],
        city: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.city || null : null, Validators.required],
        // zip: [petitionerDetails.zip || '', [Validators.required, this.customValidators.zipValidator]],
        zip: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.zip || null : null, [Validators.required, this.customValidators.zipValidator]],
        address: [this.mutualCaseDetails.firstParty ? this.mutualCaseDetails.firstParty.address || null : null, [Validators.required, this.customValidators.addressValidator]],
      }),
      secondParty: this.fb.group({
        partyType: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.partyType || null : null, Validators.required],
        partyName: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.partyName || null : null, [Validators.required, this.customValidators.nameValidator]],
        division: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.division._id || null : null, Validators.required],
        regions: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.regions || null : null, Validators.required],
        project: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.project || null : null, Validators.required],
        country: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.country || null : null, Validators.required],
        state: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.state || null : null, Validators.required],
        city: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.city || null : null, Validators.required],
        zip: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.zip || null : null, [Validators.required, this.customValidators.zipValidator]],
        address: [this.mutualCaseDetails.secondParty ? this.mutualCaseDetails.secondParty.address || null : null, [Validators.required, this.customValidators.addressValidator]],
      }),
      legalNotice: [this.mutualCaseDetails.legalNotice || null],
      descriptionDispute: [this.mutualCaseDetails.descriptionDispute || null, Validators.required],
      mutualCaseType: [this.mutualCaseDetails.mutualCaseType ? this.mutualCaseDetails.mutualCaseType._id : null, Validators.required],
      departmentName: [this.mutualCaseDetails.departmentName ? this.mutualCaseDetails.departmentName._id : null,],
      caseDescription: [this.mutualCaseDetails.caseDescription || null],
      advocateAssigned: [this.mutualCaseDetails.advocateAssigned ? this.mutualCaseDetails.advocateAssigned : null],
      retainerAssigned: [this.mutualCaseDetails.retainerAssigned ? this.mutualCaseDetails.retainerAssigned : null],
      inHouseAdvocate: [this.mutualCaseDetails.inHouseAdvocate ? this.mutualCaseDetails.inHouseAdvocate._id : null],
      settlementTerms: [this.mutualCaseDetails.settlementTerms || null],
      billingType: [this.mutualCaseDetails.billingType || null, Validators.required],
      totalAmount: [this.mutualCaseDetails.totalAmount || null],
      uploadDocuments: this.fb.group({
        partyName: [this.mutualCaseDetails.uploadDocuments ? this.mutualCaseDetails.uploadDocuments.partyName || null : null, Validators.required],
        topic: [this.mutualCaseDetails.uploadDocuments ? this.mutualCaseDetails.uploadDocuments.topic || null : null, Validators.required],
        description: [this.mutualCaseDetails.uploadDocuments ? this.mutualCaseDetails.uploadDocuments.description || null : null, Validators.required],
        docs: [this.mutualCaseDetails.uploadDocuments ? this.mutualCaseDetails.uploadDocuments.docs || null : null, Validators.required],
      }),
      futureUpdatesBrief: [this.mutualCaseDetails.futureUpdatesBrief || ''],
      caseStatus: [this.mutualCaseDetails.caseStatus || '', Validators.required],
      remark: [this.mutualCaseDetails.remark || '', Validators.required],
      caseStatusRemarks: this.fb.array([
        // { caseStatus : [this.mutualCaseDetails.caseStatus || ''], remark: [this.mutualCaseDetails.caseStatus || ''] }
      ])
    });

    if (this.isUpdateMode()) {
      this.countryChangeHandler("firstParty");
      this.stateChangeHandler("firstParty");

      this.countryChangeHandler("secondParty");
      this.stateChangeHandler("secondParty");
    }
  }

  save() {
    this.trimFileObjs();
    if (this.mode === 'create') {
      this.createCustomer();
    } else if (this.mode === 'update') {
      this.updateCustomer();
    }
  }

  createCustomer() {
    const customer = this.form.value;
    if (this.form.invalid) {
      let invalidFields = this.commonServices.findInvalidControls(this.form);
      let isFormInvalid = true;
      if (invalidFields && invalidFields.length == 1) {
        if (invalidFields[0] == "uploadDocuments" && this.form.value.uploadDocuments && this.form.value.uploadDocuments.docs && this.form.value.uploadDocuments.docs.length > 0) {
          isFormInvalid = false;
        }
      }

      if (isFormInvalid) {
        this.snackBar.open('Please provide all required information.', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
        return;
      }
    }

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    const customer = this.form.value;

    if (this.form.invalid) {
      this.snackBar.open('Please provide all required information.', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
      return;
    }

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  async docFileControlChangeHandler(fileObj) {
    if (fileObj.files[0].name) {
      // this.selectedFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + "..." : fileObj.files[0].name;
      let uploadedFiles = 0;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        this.form.value.uploadDocuments.docs = this.form.value.uploadDocuments.docs ? this.form.value.uploadDocuments.docs : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadMutualDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadMutualDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadMutualDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadMutualDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadMutualDocumentEnd, (filePath) => {
          tmpObj.form.value.uploadDocuments.docs.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            tmpObj.form.get("uploadDocuments.docs").setValue(tmpObj.form.value.uploadDocuments.docs);
            tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            tmpObj._layoutService.hideLoader();
          }
        });
      }
    }
  }

  nextTab() {
    if (this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex += 1;
    }
  }

  previousTab() {
    if (this.selectedTabIndex > 0 && this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex -= 1;
    }
  }

  tabChanged(tabEvents: MatTabChangeEvent) {
    this.selectedTabIndex = tabEvents.index;
  }

  getAdvocateName(advocateFirmDetails) {
    let name = "N/A";
    if (advocateFirmDetails) {
      if (advocateFirmDetails.userType == "firm") {
        name = advocateFirmDetails.firmName;
      } else {
        name = advocateFirmDetails.firstName + " " + advocateFirmDetails.lastName;
      }
    }
    return name;
  }

  public removeDoc(docIndex) {
    if (this.form.value.uploadDocuments.docs && docIndex >= 0) {
      this.form.value.uploadDocuments.docs.splice(docIndex, 1);
    }
  }

  downloadFile(docDetails) {
    if (docDetails) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');

      if (docDetails.fileObj) {
        link.setAttribute('href', URL.createObjectURL(docDetails.fileObj));
      } else {
        let accessToken = this.commonServices.getAccessToken();
        link.setAttribute('href', environment.serviceConfiguration.url + docDetails.filePath.substring(1) + "?access_token=" + accessToken);
      }

      link.setAttribute('download', docDetails.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  trimFileObjs() {
    if (this.form.value.uploadDocuments.docs && this.form.value.uploadDocuments.docs.length > 0) {
      for (let i = 0; i < this.form.value.uploadDocuments.docs.length; i++) {
        if (this.form.value.uploadDocuments.docs[i].fileObj) {
          delete this.form.value.uploadDocuments.docs[i]['fileObj'];
        }
      }
    }
  }

  countryChangeHandler(partyName) {
    if (this.form.value[partyName].country) {
      let tmpObj = this;
      this.citiesList[partyName] = [];
      this.socketObject.emit(environment.socketEvents.getStates, {
        country: this.form.value[partyName].country
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.statesList[partyName] = socketResponse.data;
        }
      });
    }
  }

  stateChangeHandler(partyName) {
    if (this.form.value[partyName].state) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.getCities, {
        state: this.form.value[partyName].state
      }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.citiesList[partyName] = socketResponse.data;
        }
      });
    }
  }

  firstPartyRegionsChangeHandler() {
    if (this.form.value.firstParty.regions) {
      this.firstPartyProjectSitesList = [];
      if (this.allProjectSitesList[this.form.value.firstParty.regions]) {
        this.firstPartyProjectSitesList = this.firstPartyProjectSitesList.concat(this.allProjectSitesList[this.form.value.firstParty.regions])
      }
    }
  }

  secondPartyRegionsChangeHandler() {
    if (this.form.value.secondParty.regions) {
      this.secondPartyProjectSitesList = [];
      if (this.allProjectSitesList[this.form.value.secondParty.regions]) {
        this.secondPartyProjectSitesList = this.secondPartyProjectSitesList.concat(this.allProjectSitesList[this.form.value.secondParty.regions])
      }
    }
  }

  isFileViewAllowed() {
    let isAllowed = false;

    if (this.mutualCaseDetails && environment.roleDetails && this.userDetails && this.moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (this.mutualCaseDetails.createdBy == this.userDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
          if (this.mutualCaseDetails.inHouseAdvocate && this.mutualCaseDetails.inHouseAdvocate == this.userDetails._id) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally

            if (this.sharingDetails) {
              if (this.sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnlyWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  initSharingDetails(tmpObj: CasesCreateUpdateComponent) {
    if (tmpObj.mutualCaseDetails && tmpObj.mutualCaseDetails.internalSharing) {
      for (let i = 0; i < tmpObj.mutualCaseDetails.internalSharing.length; i++) {
        if (tmpObj.mutualCaseDetails.internalSharing[i].userId == tmpObj.userDetails._id) {
          tmpObj.sharingDetails = tmpObj.mutualCaseDetails.internalSharing[i];
          break;
        }
      }
    }
  }

  isUserAllowedForFile(fileDetails) {
    let isAllowed = false;

    if (environment.roleDetails && environment.roleDetails.name == "super_admin") {
      isAllowed = true;
    } else if (this.mutualCaseDetails.inHouseAdvocate && this.mutualCaseDetails.inHouseAdvocate == this.userDetails._id) {
      isAllowed = true;
    } else if (fileDetails && this.sharingDetails && this.sharingDetails.filePermissions) {
      if (fileDetails.filePath) {
        for (let i = 0; i < this.sharingDetails.filePermissions.length; i++) {
          if (this.sharingDetails.filePermissions[i] && this.sharingDetails.filePermissions[i].filePath == fileDetails.filePath) {
            if (this.sharingDetails.filePermissions[i].permissions.view == true) {
              isAllowed = true;
            }
            break;
          }
        }
      }
    }

    return isAllowed;
  }

  previewPdfFile(docDetails) {
    if (docDetails) {
      if (docDetails.type == "application/pdf") {
        let filePath: string = docDetails.filePath;
        filePath = filePath.replace("./", environment.serviceConfiguration.url + "/viewPDF/");
        let accessToken = this.commonServices.getAccessToken();
        window.open(filePath + "?access_token=" + accessToken,
          'Legal App PDF View',
          'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=' + screen.availWidth + ',height=' + screen.availHeight);
      } else {
        this.snackBar.open("Oops! You can't preview this file.", "Okay", {
          duration: 2000
        });
      }
    }
  }
}
