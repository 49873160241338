import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import icSearch from '@iconify/icons-ic/twotone-search';
import { TableColumn } from 'src/@vex/interfaces/table-column.interface';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import icsort from '@iconify/icons-ic/twotone-sort';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icMoreHoriz from '@iconify/icons-ic/twotone-more-horiz';
import { User } from '../interfaces/court-cases.model';
import icCloud from '@iconify/icons-ic/twotone-backup';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog, MatDialogConfig, MatSelectionList, MatSnackBar, MatTabChangeEvent } from '@angular/material';
import { AutocourtcaseDetalComponent } from '../autocourtcase-detal/autocourtcase-detal.component';
import { DistrictcourtdetailComponent } from '../districtcourt/districtcourtdetail/districtcourtdetail.component';
import { HighcourtdetailComponent } from '../highcourt/highcourtdetail/highcourtdetail.component';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { TribunalCourtDetailComponent } from '../tribunal-court-detail/tribunal-court-detail.component';
import { ConsumerCourtDetailComponent } from '../consumer-court-detail/consumer-court-detail.component';
import { DelhihighcourtComponent } from '../delhihighcourt/delhihighcourt.component';
import { AllahabadDetailComponent } from '../allahabad-detail/allahabad-detail.component';
import { BombayhighcourtdetailComponent } from '../bombayhighcourtdetail/bombayhighcourtdetail.component';
import { BombayhighcourtService } from '../highcourt/bombayhighcourt.service';
import { DistrictService } from '../districtcourt/district.service';

@Component({
  selector: 'vex-mycases',
  templateUrl: './mycases.component.html',
  styleUrls: ['./mycases.component.scss']
})
export class MycasesComponent implements OnInit,AfterViewInit,OnDestroy {
  public totalRecords = 0;
  public districttotalrecord = 0
  public highcourttotalrecord = 0
  public tribunaltotalrecord = 0
  public consumerforumtotalrecord = 0
  public totalpages:Number = 0
  public totalhighcourtpages:Number= 0
  public totaltribunalpages:Number= 0
  public consumerforumpages:Number= 0
  public totaldistriccourtpages:number=0
  Records =0;
  search_value: any;
  pageSize = environment.pagination.defaultPageSize;
  districtpageSize= environment.pagination.defaultPageSize;
  highcourtpageSize= environment.pagination.defaultPageSize;
  tribunalpageSize= environment.pagination.defaultPageSize;
  consumerforumpageSize= environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;
  private socketObject;
  layoutCtrl = new FormControl('fullwidth');
  icSearch = icSearch;
  icsort = icsort;
  icCloud = icCloud;
  icDelete= icDelete;
  icMoreHoriz = icMoreHoriz;
  searchCtrl = new FormControl();
  caselistdata=[];
  public sortvalue: string;
  public userDetails;
  public pageIndex = 0
  public districtpageIndex = 0
  public highcourtpageInedex = 0 
  public tribunalpageInedex = 0 
  public consumerforumpageInedex = 0 
  private userDetailUpdateSubscriber;
  public selectedTabIndex: number = 0;
  districtcaselistdata=[];
  highcourtcaselistdata=[];
  tribunalcaselistdata=[];
  consumerforumcaselistdata=[];
  @Input()
  columns: TableColumn<User>[] = [
    { label: 'Actions', property: 'actions', type: 'button', visible: true },]
    dataSource: MatTableDataSource<User> | null = new MatTableDataSource();
  courtdata: any[];
  districtscourtdata: any[];
  highcourtsdata:any[];
  tribunaldata:any[];
  consumerforumdata:any[];


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
 

  constructor( private socketService: SocketService,private bombayhighcourtservice: BombayhighcourtService, private commonServices: CommonService,private router: Router,private dialog: MatDialog,private snackbar: MatSnackBar,private districtcourt: DistrictService,) { 

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
   
  }

  ngOnInit() {
    this.refreshData(this)
    this.districtcourtdata(this)
    this.getstate()
    this.highcourtdata(this)
    this.tribunalcourtdata(this)
    this.consumerforumcourtdata(this)
    let tmpObj = this;
    this.userDetails = this.commonServices.getLoggedInUserDetails();
    this.userDetailUpdateSubscriber = this.commonServices.getUserDetailsUpdateObserver().subscribe((userDetails) => {
      tmpObj.userDetails = userDetails;
    });
    console.log(this.searchCtrl.valueChanges,"searchCtrl")
    this.searchCtrl.valueChanges.pipe(
    
      untilDestroyed(this)
    ).subscribe(value => {this.onFilterChange(value)
     
    });
  }

  ngAfterViewInit() {
    // this.dataSource.paginator = this.paginator;
    // console.log("value of value chnage "),
  
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();

    if (value.length >= 3 || value.length == 0) {
      console.log(this.selectedTabIndex,"this.selectedTabIndex")
      if(this.selectedTabIndex==0){
        this.refreshData(this);
      }else if(this.selectedTabIndex==1){
        this.districtcourtdata(this)
      }else if(this.selectedTabIndex==2){
        this.highcourtdata(this)
      }else if(this.selectedTabIndex==3){
        this.tribunalcourtdata(this)
      }
    
    }
  }


  refreshData(tmpObj) {
    if(tmpObj.searchCtrl.value){
      this.search_value = tmpObj.searchCtrl.value
    }
  console.log(tmpObj.searchCtrl.value,"tmpObj.paginator hhhhh")
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listmyCourtCasesPagination,{ 
        filterText: tmpObj.searchCtrl.value,
         pageSize: tmpObj.pageSize,
        skip: tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:tmpObj.pageIndex*tmpObj.pageSize

        //  skip: 0
      
      },
      
        function (socketResponse) {
          console.log(socketResponse,"by api ")
          if (socketResponse.status == 'OK') {
            tmpObj.caselistdata= socketResponse.data
            console.log(socketResponse,"this casec list 1 ")
            tmpObj.totalRecords = socketResponse.totalRecords;
           
            this.caselistdata = tmpObj.caselistdata
           this.totalRecords = tmpObj.totalRecords
           tmpObj.getdaar()
           let record = tmpObj.totalRecords/10
           let secrecord = (record+ "").split(".")[1]
          
     if(Number(secrecord)){
      
       tmpObj.totalpages = parseInt((tmpObj.totalRecords/10)+"")
       tmpObj.totalpages = Number(tmpObj.totalpages)+1
      
     }else{
       tmpObj.totalpages = (tmpObj.totalRecords/10)
     }

          //  let secrecord = (record+ "").split(".")[1]
           
           
            // tmpObj.users = socketResponse.data;
            //  let usersList: User[] = [];
            // for (let i = 0; i < socketResponse.data.length; i++) {
            //   tmpObj.caselistdata.push(new User(socketResponse.data[i]));
            // }
            //  tmpObj.dataSource = new MatTableDataSource(usersList);
          
            // console.log(tmpObj.dataSource,"tmpobj")


          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
    }
  
    
  }

  districtcourtdata(tmpObj){

console.log("district court")
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listdistrictCourtCasesPagination,{
        filterText: tmpObj.searchCtrl.value ,
         pageSize: tmpObj.districtpageSize,
        skip: tmpObj.districtpageIndex?tmpObj.districtpageIndex * tmpObj.districtpageSize:0

        //  skip: 0
      
      },
      
        function (socketResponse) {
          console.log(socketResponse,"by api ")
          if (socketResponse.status == 'OK') {
            tmpObj.districtcaselistdata= socketResponse.data
            tmpObj.districttotalrecord = socketResponse.totalRecords;
           
            this.districtcaselistdata = tmpObj.districtcaselistdata
          //  this.totalRecords = tmpObj.totalRecords
            // console.log(socketResponse,"this casec list 1 district data ")
            tmpObj.getdist()
            let record = tmpObj.districttotalrecord/10
            let secrecord = (record+ "").split(".")[1]
           
      if(Number(secrecord)){
       
        tmpObj.totaldistriccourtpages = parseInt((tmpObj.districttotalrecord/10)+"")
        tmpObj.totaldistriccourtpages = Number(tmpObj.totaldistriccourtpages)+1
       
      }else{
        tmpObj.totaldistriccourtpages = (tmpObj.districttotalrecord/10)
      }
            // tmpObj.users = socketResponse.data;
            //  let usersList: User[] = [];
            // for (let i = 0; i < socketResponse.data.length; i++) {
            //   tmpObj.caselistdata.push(new User(socketResponse.data[i]));
            // }
            //  tmpObj.dataSource = new MatTableDataSource(usersList);
          
            // console.log(tmpObj.dataSource,"tmpobj")


          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
    }
    
  }

  // selected='All'
  // serverType:String;
  // state_code:Number;
  // getFilterData(state_code,courtName){
  //   this.state_code=state_code;
  //   this.serverType=courtName;
  //   this.highcourtdata(this);
  // }

  highcourtdata(tmpObj){


    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listhighcourtCasesPagination,{ 
        filterText: tmpObj.searchCtrl.value ,
        pageSize: tmpObj.highcourtpageSize,
        skip: tmpObj.highcourtpageInedex?tmpObj.highcourtpageInedex * tmpObj.highcourtpageSize:0,
        serverType:tmpObj.serverType,
        state_code:tmpObj.state_code,

        //  skip: 0
      
      },
      
        function (socketResponse) {
          console.log(socketResponse,"by api highcourt  ")
          if (socketResponse.status == 'OK') {
            tmpObj.highcourtcaselistdata= socketResponse.data
            tmpObj.highcourttotalrecord = socketResponse.totalRecords;
           
            this.highcourtcaselistdata = tmpObj.highcourtcaselistdata
          //  this.totalRecords = tmpObj.totalRecords
            console.log(this.highcourtcaselistdata,"this casec list 1 ")
            tmpObj.gethigh()
            let record = tmpObj.highcourttotalrecord/10
            let secrecord = (record+ "").split(".")[1]
           
      if(Number(secrecord)){
       
        tmpObj.totalhighcourtpages = parseInt((tmpObj.highcourttotalrecord/10)+"")
        tmpObj.totalhighcourtpages = Number(tmpObj.totalhighcourtpages)+1
       
      }else{
        tmpObj.totalhighcourtpages = (tmpObj.highcourttotalrecord/10)
      }
            // tmpObj.users = socketResponse.data;
            //  let usersList: User[] = [];
            // for (let i = 0; i < socketResponse.data.length; i++) {
            //   tmpObj.caselistdata.push(new User(socketResponse.data[i]));
            // }
            //  tmpObj.dataSource = new MatTableDataSource(usersList);
          
            // console.log(tmpObj.dataSource,"tmpobj")


          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
    }
    
  }

  tribunalcourtdata(tmpObj){


    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listtribunalCasesPagination,{ 
        filterText: tmpObj.searchCtrl.value ,
        pageSize: tmpObj.tribunalpageSize,
        skip: tmpObj.tribunalpageInedex?tmpObj.tribunalpageInedex * tmpObj.tribunalpageSize:0

        //  skip: 0
      
      },
      
        function (socketResponse) {
          console.log(socketResponse,"by api highcourt  ")
          if (socketResponse.status == 'OK') {
            tmpObj.tribunalcaselistdata= socketResponse.data
            tmpObj.tribunaltotalrecord = socketResponse.totalRecords;
           
            this.tribunalcaselistdata = tmpObj.tribunalcaselistdata
          //  this.totalRecords = tmpObj.totalRecords
            console.log(this.tribunalcaselistdata,"this casec list 1 ")
            tmpObj.gettribunal()
            let record = tmpObj.tribunaltotalrecord/10
            let secrecord = (record+ "").split(".")[1]
           
      if(Number(secrecord)){
       
        tmpObj.totaltribunalpages = parseInt((tmpObj.tribunaltotalrecord/10)+"")
        tmpObj.totaltribunalpages = Number(tmpObj.totaltribunalpages)+1
       
      }else{
        tmpObj.totaltribunalpages = (tmpObj.tribunaltotalrecord/10)
      }
            // tmpObj.users = socketResponse.data;
            //  let usersList: User[] = [];
            // for (let i = 0; i < socketResponse.data.length; i++) {
            //   tmpObj.caselistdata.push(new User(socketResponse.data[i]));
            // }
            //  tmpObj.dataSource = new MatTableDataSource(usersList);
          
            // console.log(tmpObj.dataSource,"tmpobj")


          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
    }
    
  }

  consumerforumcourtdata(tmpObj){


    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.consumerforumCasesPagination,{ 
        filterText: tmpObj.searchCtrl.value ,
        pageSize: tmpObj.consumerforumpageSize,
        skip: tmpObj.consumerforumpageInedex?tmpObj.consumerforumpageInedex * tmpObj.consumerforumpageSize:0

        //  skip: 0
      
      },
      
        function (socketResponse) {
          console.log(socketResponse,"by api highcourt  ")
          if (socketResponse.status == 'OK') {
            tmpObj.consumerforumcaselistdata= socketResponse.data
            tmpObj.consumerforumtotalrecord = socketResponse.totalRecords;
           
            this.consumerforumcaselistdata = tmpObj.consumerforumcaselistdata
          //  this.totalRecords = tmpObj.totalRecords
            console.log(this.consumerforumcaselistdata,"this casec list 1 ")
            tmpObj.getconsumerforum()
            let record = tmpObj.consumerforumtotalrecord/10
            let secrecord = (record+ "").split(".")[1]
           
      if(Number(secrecord)){
       
        tmpObj.consumerforumpages = parseInt((tmpObj.consumerforumtotalrecord/10)+"")
        tmpObj.consumerforumpages = Number(tmpObj.consumerforumpages)+1
       
      }else{
        tmpObj.consumerforumpages = (tmpObj.consumerforumtotalrecord/10)
      }
            // tmpObj.users = socketResponse.data;
            //  let usersList: User[] = [];
            // for (let i = 0; i < socketResponse.data.length; i++) {
            //   tmpObj.caselistdata.push(new User(socketResponse.data[i]));
            // }
            //  tmpObj.dataSource = new MatTableDataSource(usersList);
          
            // console.log(tmpObj.dataSource,"tmpobj")


          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
    }
    
  }

getdaar(){
  this.courtdata = this.caselistdata
  this.Records = this.totalRecords
  console.log(this.paginator, this.totalRecords,"this casec list 2 ")
}

getdist(){

  this.districtscourtdata = this.districtcaselistdata
  this.Records = this.totalRecords

}
gethigh(){
  this.highcourtsdata = this.highcourtcaselistdata
}

gettribunal(){
  this.tribunaldata = this.tribunalcaselistdata
}

getconsumerforum(){
  this.consumerforumdata = this.consumerforumcaselistdata
}

getdata(data){
  console.log(data ,"ooooooooooooo")
   // this.router.navigate(["/app/cases-management/case-detail"], { queryParams: { case_id: data.caseid} });
   let obj={
     diary_no:data.diary_only,
     caseYear:data.diary_year
   
   }
 
   // this.loading = false;
   this.dialog.open(AutocourtcaseDetalComponent,{
  height: '500px',
   width: '850px',
   autoFocus: false,
    data:obj
 });
 
 }

 getdistrictdata(data){
console.log(data,"data")
  this.dialog.open(DistrictcourtdetailComponent,{
    height: '500px',
     width: '800px',
     autoFocus: false,
      data:data
   });
 }

 gethighdata(data){
  if(data.courtDetails.courtName=='high-court' && data.courtDetails.state_code==26){
    this.dialog.open(DelhihighcourtComponent,{
      height: '500px',
       width: '800px',
       autoFocus: false,
        data:data
     });
  }else if(data.courtDetails.courtName=='high-court' && data.courtDetails.state_code==1){
    let obj = {
      case_category:data.case_category,
      case_no:data.case_no,
      case_side:data.case_side,
      case_type:data.case_type,
      case_year:data.case_year,
      court_code:data.court_code,
      code:data.code,
      call:'mycases'
    }
    this.dialog.open(BombayhighcourtdetailComponent,{
      height: '500px',
       width: '800px',
       autoFocus: false,
        data:obj
     });
  }
  else if(data.courtDetails.courtName=='high-court' && data.courtDetails.state_code==13){
    this.dialog.open(AllahabadDetailComponent,{
      height: '500px',
       width: '800px',
       autoFocus: false,
        data:data
     });
  }
  else{
    this.dialog.open(HighcourtdetailComponent,{
      height: '500px',
       width: '800px',
       autoFocus: false,
        data:data
     });
  }
 }

 gettribunaldata(data){
  data['case_title']=data.caseName;
  this.dialog.open(TribunalCourtDetailComponent,{
    height: '500px',
     width: '800px',
     autoFocus: false,
      data:data
   });
 }

 getconsumerforumdata(data){
  this.dialog.open(ConsumerCourtDetailComponent,{
    height: '500px',
     width: '800px',
     autoFocus: false,
      data:data
   });
 }

createCase(){
  this.router.navigate(["/app/cases-management/add-case"]);
}
toggleColumnVisibility(column, event) {
  console.log(column,event,"column")
  event.stopPropagation();
  event.stopImmediatePropagation();
  column.visible = !column.visible;
}

deletesupremeCustomer(){
  // console.log(customer,"customer")
  if (environment.roleDetails) {
  
      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;
        this.socketObject.emit(environment.socketEvents.deletesupremeCourtCase,function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.snackbar.open('Successfully Deleted Supreme Court Case.', 'Okay', {
              duration: 4000
            });
            tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
      }
    
  }
}

deleteCustomer(customer: User) {
  console.log(customer,"customer")
  if (environment.roleDetails) {
  
      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;
        this.socketObject.emit(environment.socketEvents.deletedistrictCourtCase, customer, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.snackbar.open('Successfully Deleted District Court Case.', 'Okay', {
              duration: 4000
            });
            tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });
      }
    
  }
}

radioChange(e) {
  this.sortvalue = e.value
  this.refreshData(this);

}
pageChangeHandler(eventDetails) {
  if (eventDetails && eventDetails.pageSize) {
     this.pageSize = eventDetails.pageSize;
     this.pageIndex = eventDetails.pageIndex
  }
  this.refreshData(this);
}

districtpageChangeHandler(eventDetails){
  if (eventDetails && eventDetails.pageSize) {
    this.districtpageSize = eventDetails.pageSize;
    this.districtpageIndex= eventDetails.pageIndex
 }
 this.districtcourtdata(this)
}
highcourtpageChangeHandler(eventDetails){
  if (eventDetails && eventDetails.pageSize) {
    this.highcourtpageSize = eventDetails.pageSize;

    this.highcourtpageInedex= eventDetails.pageIndex
    console.log( this.highcourtpageInedex," this.highcourtpageInedex")
 }
 this.highcourtdata(this)
}
tribunalpageChangeHandler(eventDetails){
  if (eventDetails && eventDetails.pageSize) {
    this.tribunalpageSize = eventDetails.pageSize;

    this.tribunalpageInedex= eventDetails.pageIndex
    console.log( this.tribunalpageInedex," this.tribunalpageInedex")
 }
 this.tribunalcourtdata(this)
}
consumerforumpageChangeHandler(eventDetails){
  if (eventDetails && eventDetails.pageSize) {
    this.consumerforumpageSize = eventDetails.pageSize;

    this.consumerforumpageInedex= eventDetails.pageIndex
    console.log( this.consumerforumpageInedex," this.consumerforumpageInedex")
 }
 this.consumerforumcourtdata(this)
}

ngOnDestroy() {
  if (this.userDetailUpdateSubscriber) {
    this.userDetailUpdateSubscriber.unsubscribe();
  }
}

tabChanged(tabEvents: MatTabChangeEvent) {
  console.log(tabEvents,"tabEvents")
  this.selectedTabIndex = tabEvents.index;
}


// districtcourtlist(){
//   console.log("this part is clck")
// }

totalcountpage(secrecord,totalRecords){
  let totalpages
  if(Number(secrecord)){
       
     let totalpages = parseInt((totalRecords/10)+"")
    totalpages = Number(totalpages)+1
   
  }else{
    totalpages = (totalRecords/10)
  }
  return totalpages
}
state: any;
getstate() {
  let obj = {};
  this.districtcourt
    .postecourt("ws/eGetCourtState", obj)
    .subscribe((res2: any) => {
      console.log(res2, "res2 of state");

      this.state = res2.courtState;
      console.log(this.state)
    });
}

}
