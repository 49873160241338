import { Component, Inject, LOCALE_ID, Renderer2 } from "@angular/core";
import { ConfigName, ConfigService } from "../@vex/services/config.service";
import { MatIconRegistry } from "@angular/material/icon";
import { Settings } from "luxon";
import { DOCUMENT } from "@angular/common";
import { Platform } from "@angular/cdk/platform";
import { NavigationService } from "../@vex/services/navigation.service";
import icLayers from "@iconify/icons-ic/twotone-layers";
import icUserLayers from "@iconify/icons-ic/twotone-verified-user";
import icGroupLayers from "@iconify/icons-ic/twotone-group";
import icBusinessLayers from "@iconify/icons-ic/twotone-business";
import icCalendarLayers from "@iconify/icons-ic/twotone-calendar-today";
import icNoteAddLayers from "@iconify/icons-ic/twotone-note-add";
import icWarningLayers from "@iconify/icons-ic/twotone-warning";
import icBookLayers from "@iconify/icons-ic/twotone-book";
import icSecurityLayers from "@iconify/icons-ic/twotone-security";
import icActionLayers from "@iconify/icons-ic/twotone-call-to-action";
import icPagesLayers from "@iconify/icons-ic/twotone-pages";
import icPayment from "@iconify/icons-ic/twotone-payment";
import icSettings from "@iconify/icons-ic/twotone-settings";
import { LayoutService } from "../@vex/services/layout.service";
import { ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { SplashScreenService } from "../@vex/services/splash-screen.service";
import { Style, StyleService } from "../@vex/services/style.service";
import theme from "src/@vex/utils/tailwindcss";

@Component({
  selector: "vex-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  title = "ACIL Legal App";

  constructor(
    private configService: ConfigService,
    private styleService: StyleService,
    private iconRegistry: MatIconRegistry,
    private renderer: Renderer2,
    private platform: Platform,
    @Inject(DOCUMENT) private document: Document,
    @Inject(LOCALE_ID) private localeId: string,
    private layoutService: LayoutService,
    private route: ActivatedRoute,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService
  ) {
    this.iconRegistry.setDefaultFontSetClass("iconify");
    Settings.defaultLocale = this.localeId;

    if (this.platform.BLINK) {
      this.renderer.addClass(this.document.body, "is-blink");
    }

    this.route.queryParamMap
      .pipe(
        filter(
          (queryParamMap) =>
            queryParamMap.has("rtl") &&
            coerceBooleanProperty(queryParamMap.get("rtl"))
        )
      )
      .subscribe((queryParamMap) => {
        this.document.body.dir = "rtl";
        this.configService.updateConfig({
          rtl: true,
        });
      });

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("layout")))
      .subscribe((queryParamMap) =>
        this.configService.setConfig(queryParamMap.get("layout") as ConfigName)
      );

    this.route.queryParamMap
      .pipe(filter((queryParamMap) => queryParamMap.has("style")))
      .subscribe((queryParamMap) =>
        this.styleService.setStyle(queryParamMap.get("style") as Style)
      );

    this.navigationService.items = [
      {
        type: "link",
        label: "Dashboard",
        key: "dashboard",
        icon: icLayers,
        route: "/app/dashboard",
        allowedRoles: ["admin", "user", "management", "advocate"],
      },
      {
        type: "dropdown",
        label: "Admin",
        icon: icUserLayers,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin"],
        children: [
          {
            type: "link",
            label: "Users",
            key: "users_management",
            route: "/app/users-management",
            icon: icUserLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Roles",
            key: "roles_management",
            route: "/app/roles-management",
            icon: icUserLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Advocate/Firm",
            key: "advocate_firms",
            route: "/app/advocate-firm-management",
            icon: icBusinessLayers,
            allowedRoles: ["admin", "management"],
          },

        ],
      },
      {
        type: "dropdown",
        label: "Reports",
        icon: icBookLayers,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin"],
        children: [

          {
            type: "link",
            label: "Reports",
            key: "reports_management",
            route: "/app/Reports-management",
            icon: icUserLayers,
            allowedRoles: ["admin"],
          },

          {
            type: "link",
            label: "Reports Config",
            key: "reports_config_management",
            route: "/app/Reports-config",
            icon: icUserLayers,
            allowedRoles: ["admin"],
          },

        ]
      },
      {
        type: "dropdown",
        label: "Notices",
        icon: icSecurityLayers,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin", "user", "advocate", "management"],
        children: [
          {
            type: "link",
            label: "Inward Notice Dropbox",
            key: "inward_notice_dropbox",
            route: "/app/inward-notices-dropbox",
            icon: icUserLayers,
            allowedRoles: ["admin", "user", "advocate", "management"],
          },
          {
            type: "link",
            label: "Legal/Departmental Notice",
            key: "legal_notices",
            route: "/app/legal-notices",
            icon: icUserLayers,
            allowedRoles: ["admin", "user", "advocate", "management"],
          },
        ],
      },
      {
        type: "link",
        label: "Billing",
        key: "billing",
        route: "/app/billing-management",
        icon: icPayment,
        allowedRoles: ["admin", "management", "advocate"],
      },
      {
        type: "dropdown",
        label: "Case Management",
        icon: icPagesLayers,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin", "user", "advocate", "management"],
        children: [

          {
            type: "link",
            icon: icPagesLayers,
            label: " Search/Add Live Case",
            key: "court_cases",
            route: "/app/cases-management/add-case",
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            icon: icPagesLayers,
            label: "My Cases",
            key: "court_cases",
            route: "/app/cases-management/court-cases",
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            icon: icPagesLayers,
            label: "Arbitration",
            key: "court_cases",
            route: "/app/cases-management/Arbitration",
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            icon: icPagesLayers,
            label: "Watch list",
            key: "court_cases",
            route: "/app/cases-management/mycases",
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            icon: icPagesLayers,
            label: "Mediations (settled cases)",
            key: "mutual_cases",
            route: "/app/cases-management/mutual-cases",
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
        ],
      },
      {
        type: "dropdown",
        label: "Time  Management",
        icon: icCalendarLayers,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin", "user", "advocate", "management"],
        children: [
          {
            type: "link",
            label: "Calendar View",
            key: "calendar_view",
            route: "/app/calendar-view",
            icon: icCalendarLayers,
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            icon: icPagesLayers,
            label: "Tasks Management",
            key: "tasks_management",
            route: "/app/tasks-management",
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            label: "Appointments",
            key: "appointments_management",
            route: "/app/appointments-management",
            icon: icNoteAddLayers,
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            label: "Hearings",
            key: "hearings_management",
            route: "/app/hearings-management",
            icon: icWarningLayers,
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
        ],
      },
      {
        type: "link",
        label: "My Drive",
        key: "legal_docs",
        route: "/app/legal-docs-management",
        icon: icBookLayers,
        allowedRoles: ["admin", "management", "user"],
      },
      {
        type: "dropdown",
        label: "Resource Center",
        icon: icActionLayers,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin", "user", "advocate", "management"],
        children: [
          {
            type: "link",
            label: "Bare Acts",
            key: "bare_acts_management",
            route: "/app/bare-acts-management",
            icon: icActionLayers,
            allowedRoles: ["admin", "user", "management"],
          },
          {
            type: "link",
            label: "Judgements",
            key: "judgements_management",
            route: "/app/jugements-management",
            icon: icSecurityLayers,
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
          {
            type: "link",
            label: "Government Notifications",
            key: "government_notification_management",
            route: "/app/government-notification-management",
            icon: icSecurityLayers,
            allowedRoles: ["admin", "user", "management", "advocate"],
          },
        ],
      },
      {
        type: "dropdown",
        label: "Setup",
        icon: icSettings,
        badge: {
          value: "",
          background: theme.colors.green["500"],
          color: theme.textColor["green-contrast"]["600"],
        },
        allowedRoles: ["admin", "user", "advocate", "management"],
        children: [
          {
            type: "link",
            label: "Case Abbreviations",
            key: "case_abbreviation_management",
            route: "/app/case-abbreviations-management",
            icon: icPagesLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Company Profile",
            key: "update_company_profile",
            route: "/app/CompanyProfile",
            icon: icPagesLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Court Types",
            key: "court_types_management",
            route: "/app/court-types-management",
            icon: icBookLayers,
            allowedRoles: ["admin", "management"],
          },
          {
            type: "link",
            label: "Court",
            key: "court_management",
            route: "/app/courts-management",
            icon: icBookLayers,
            allowedRoles: ["admin", "management"],
          },
          {
            type: "link",
            label: "Judgement Areas",
            key: "judgement_area_management",
            route: "/app/jugement-types-management",
            icon: icSecurityLayers,
            allowedRoles: ["admin", "management"],
          },
          {
            type: "link",
            label: "Divisions",
            key: "division_management",
            route: "/app/divisions-management",
            icon: icActionLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Departments",
            key: "department_management",
            route: "/app/departments-management",
            icon: icActionLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Designations",
            key: "designation_management",
            route: "/app/designations-management",
            icon: icActionLayers,
            allowedRoles: ["admin"],
          },
          {
            type: "link",
            label: "Branch/Office",
            key: "project_sites_management",
            route: "/app/project-sites-management",
            icon: icActionLayers,
            allowedRoles: ["admin"],
          },
        ],
      },
      {
        type: "link",
        label: "Discussion Board",
        key: "discussion_board",
        route: "/app/discussion-board",
        icon: icSecurityLayers,
        allowedRoles: ["admin", "user", "management", "advocate"],
      },
    ];
  }
}
