import {AfterViewInit,Component,Input,OnDestroy,OnInit,ViewChild,} from "@angular/core";
import { Observable, of, ReplaySubject } from "rxjs";
import { User } from "../interfaces/court-cases.model";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatDialog } from "@angular/material/dialog";
import { TableColumn } from "../../../../@vex/interfaces/table-column.interface";
import {courtCasesDummyData,aioTableLabels,} from "../../../../static-data/aio-table-data";
import { CourtCasesCreateUpdateComponent } from "../court-cases-create-update/court-cases-create-update.component";
import icEdit from "@iconify/icons-ic/twotone-edit";
import icDelete from "@iconify/icons-ic/twotone-delete";
import icSearch from "@iconify/icons-ic/twotone-search";
import icAdd from "@iconify/icons-ic/twotone-add";
import icCloud from "@iconify/icons-ic/twotone-backup";
import icFilterList from "@iconify/icons-ic/twotone-filter-list";
import { SelectionModel } from "@angular/cdk/collections";
import icMoreHoriz from "@iconify/icons-ic/twotone-more-horiz";
import icFolder from "@iconify/icons-ic/twotone-folder";
import { fadeInUp400ms } from "../../../../@vex/animations/fade-in-up.animation";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS,MatFormFieldDefaultOptions,} from "@angular/material/form-field";
import { stagger40ms } from "../../../../@vex/animations/stagger.animation";
import { FormControl, FormGroup } from "@angular/forms";
import { untilDestroyed } from "ngx-take-until-destroy";
import theme from "../../../../@vex/utils/tailwindcss";
import icPhone from "@iconify/icons-ic/twotone-phone";
import icMail from "@iconify/icons-ic/twotone-mail";
import icMap from "@iconify/icons-ic/twotone-map";
import icDetails from "@iconify/icons-ic/twotone-details";
import icShare from "@iconify/icons-ic/twotone-share";
import icTrackChanges from "@iconify/icons-ic/twotone-track-changes";
import { ActivatedRoute, Router } from "@angular/router";
import icGroupWork from "@iconify/icons-ic/twotone-group-work";
import icNoteAdd from "@iconify/icons-ic/twotone-note-add";
import icSecurity from "@iconify/icons-ic/twotone-security";
import icWarning from "@iconify/icons-ic/twotone-warning";
import icMeetingRoom from "@iconify/icons-ic/twotone-meeting-room";
import icsort from "@iconify/icons-ic/twotone-sort";
import { SocketService } from "src/app/services/core/socket.service";
import { CommonService } from "src/app/services/core/common.service";
import { MatSnackBar, MatTabChangeEvent } from "@angular/material";
import { environment } from "src/environments/environment";
import { CaseStatusChangeComponent } from "../case-status-change/case-status-change.component";
import { HearingsCreateUpdateComponent } from "../../hearings/hearings-create-update/hearings-create-update.component";
import { appModulesList } from "src/environments/appModulesList";
import { CourtCaseInternalSharingComponent } from "../court-case-internal-sharing/court-case-internal-sharing.component";
import { CourtCaseSharesListComponent } from "../court-case-shares-list/court-case-shares-list.component";
import { ExternalSharingComponent } from "../external-sharing/external-sharing.component";
import { SharesListExternalUsersComponent } from "../shares-list-external-users/shares-list-external-users.component";
import { DistrictService } from "../districtcourt/district.service";
import { CourtcaseService } from "../addcourtcase/courtcase.service";
import { HighcourtService } from "../highcourt/highcourt.service";
import { TribunalService } from "../tribunal-court/tribunal.service";
import { ConsumerCourtService } from "../consumer-court/consumer-court.service";

@Component({
  selector: "vex-court-cases",
  templateUrl: "./court-cases.component.html",
  styleUrls: ["./court-cases.component.scss"],
  animations: [fadeInUp400ms, stagger40ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "standard",
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class CourtCasesComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() data: string;
  receivedData: string;


  layoutCtrl = new FormControl("fullwidth");
  private socketObject;

  public moduleDetails = appModulesList.courtCaseManagement;
  yeardata: any;

  public totalRecords = 0;
  public totalpages: Number = 0;
  public startDate;
  districtlist: any;
  public endDate;
  public current_date = new Date();
  @Input()
  columns: TableColumn<User>[] = [
    { label: "Actions", property: "actions", type: "button", visible: true },
    // { label: 'Case Number', property: 'caseNumber', type: 'text', visible: true, cssClasses: ['font-medium'] },
    // { label: 'Case Name', property: 'modifiedCaseName', type: 'text', visible: true, cssClasses: ['font-medium', "name-col"] },
    //{ label: 'Case Year', property: 'caseYear', type: 'text', visible: true, cssClasses: ['text-secondary', 'font-medium'] },
    //{ label: 'Assigned Advocate', property: 'advocateName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'In House User', property: 'inHouseUserName', type: 'text', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Last Hearing Date', property: 'lastHearingDate', type: 'date', visible: true, cssClasses: ['font-medium'] },
    //{ label: 'Next Hearing Date', property: 'nextHearingDate', type: 'date', visible: true, cssClasses: ['font-medium'] },
    // { label: 'Case Type', property: 'courtCaseTypeAlias', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Division', property: 'divisionName', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Project', property: 'projectName', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Region', property: 'regionName', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Case Description', property: 'caseDescription', type: 'text', visible: false, cssClasses: ['font-medium'] },
    // { label: 'Petitioner Name', property: 'petitionerName', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    // { label: 'Responder Name', property: 'responderName', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    // { label: 'Created By', property: '_createdBy', type: 'text', visible: false, cssClasses: ['text-secondary', 'font-medium'] },
    // { label: 'Labels', property: 'labels', type: 'button', visible: true },
  ];
  pageSize = environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;
  dataSource: MatTableDataSource<User> | null = new MatTableDataSource();
  selection = new SelectionModel<User>(true, []);
  searchCtrl = new FormControl();

  labels = aioTableLabels;

  icPhone = icPhone;
  icGroupWork = icGroupWork;
  icNoteAdd = icNoteAdd;
  icSecurity = icSecurity;
  icWarning = icWarning;
  icMeetingRoom = icMeetingRoom;
  icMail = icMail;
  icMap = icMap;
  icDetails = icDetails;
  icShare = icShare;
  icTrackChanges = icTrackChanges;
  icEdit = icEdit;
  icSearch = icSearch;
  icDelete = icDelete;
  icAdd = icAdd;
  icCloud = icCloud;
  icFilterList = icFilterList;
  icMoreHoriz = icMoreHoriz;
  icFolder = icFolder;

  icsort = icsort;

  public userDetails;
  private userDetailUpdateSubscriber;
  public advocatesList = [];
  public retainerList = [];
  public isAdvocate: boolean = true;
  public selectedAdovocate: string = "";
  public selectedretainership: string = "";
  public selectedAdovocate_id: string = "";
  public selectedRetainer_id: string = "";
  public sortvalue: string;
  public msg: string;
  public sortValue: string[] = ["Date-Wise", "Name-Wise"];
  theme = theme;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  pageIndex = 0;
  search_value: any;
  state:any;
  selectedOption:string;

  constructor(
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackbar: MatSnackBar,
    private districtcourt: DistrictService,
    private court :CourtcaseService,
    private highcourt: HighcourtService,
    private tribunalService: TribunalService,
    private consumerCourtService: ConsumerCourtService
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  get visibleColumns() {
    return this.columns
      .filter((column) => column.visible)
      .map((column) => column.property);
  }

  /**
   * Example on how to get data and pass it to the table - usually you would want a dedicated service with a HTTP request for this
   * We are simulating this request here.
   */
  getData() {
    return of(courtCasesDummyData.map((user) => new User(user)));
  }

  ngOnInit() {
    // console.log(this.receivedData,"cjeck resting")
    // this.getstateOfHighCourt()
    // this.getstateOfDistrictCourt()
    this.locationCategoryDetails()
    this.caseYearDetails()
    this.getyear();
    this.getStateCommissionState()
    this.getDistrictCommissionState()
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(
        environment.socketEvents.getModifiedApiRegionsProjects,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            User.regionsProjectSiteModified =
              socketResponse.regionsProjectSitesList;
          }
        }
      );
    }

    // this.route.queryParams.subscribe(params => {
    //   if (params['param1']) {
    //     console.log(params['param1']); // Outputs: value1 if present
    //     this.refreshData(this,{});
    //   } else {
    //     console.log('No query parameters provided.');
    //     this.refreshData(this,{courtType:"1",skip:0});
    //   }
    // });

this.route.queryParams.subscribe(params=>{
  if(params['value']){
    this.selectedTabIndex = 6
    this.refreshData(this,{});
  }else{
    this.selectedTabIndex = 0
    this.refreshData(this,{courtType:"1",skip:0});
  }
})

    // this.refreshData(this,{courtType:"1",skip:0});

    let tmpObj = this;
    this.userDetails = this.commonServices.getLoggedInUserDetails();
    this.userDetailUpdateSubscriber = this.commonServices
      .getUserDetailsUpdateObserver()
      .subscribe((userDetails) => {
        tmpObj.userDetails = userDetails;
      });

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(
        environment.socketEvents.listAdvocateFirm,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.advocatesList = socketResponse.data;

            tmpObj.isAdvocate = socketResponse.isAdvocate;
            // console.log(socketResponse.data, "socketResponse.data");
            if (socketResponse.isAdvocate == true) {
              tmpObj.selectedAdovocate = socketResponse.data[0]._id;
            }
          }
          // tmpObj.refreshData(tmpObj);
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listofretanership,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.retainerList = socketResponse.data;
            // console.log(socketResponse, "user retainer ship list");
            // tmpObj.isAdvocate = socketResponse.isAdvocate;
            // if (socketResponse.isAdvocate == true) {
            //   tmpObj.selectedAdovocate = socketResponse.data[0]._id;
            // }
          }
          // tmpObj.refreshData(tmpObj);
        }
      );
    }
  }

  getstateOfHighCourt() {
    let obj = {};
    this.highcourt
      .postecourt("ws/eGetCourtState", obj)
      .subscribe((res2: any) => {
        console.log(res2, "res2 of state");

        this.state = res2.courtStateInfo;
      });
  }

  getstateOfDistrictCourt(){
    let obj ={
  
    }
    this.districtcourt.postecourt('ws/eGetCourtState',obj).subscribe((res2: any) => {
    console.log(res2,"res2 of state")
  
    this.state = res2.courtState
    })
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.searchCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.onFilterChange(value);
        console.log(value, "value of value chnage ");
      });
  }

  tabIndexValueforForm = {}

  refreshData(tmpObj,obj) {
    console.log(tmpObj,obj)
    // console.log(this.startDate,this.courtName, "selectedAdovocate_id");
    // console.log(tmpObj.selectedRetainer_id,"selectedRetainer_id")

    this.tabIndexValueforForm={
      courtType:obj.courtType?obj.courtType:7,
      courtname:this.courtName?this.courtName:"Supreme Court"
    }
    if (tmpObj.searchCtrl.value) {
      this.search_value = tmpObj.searchCtrl.value;
    }

    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(
        environment.socketEvents.listCourtCasesPagination,
        {
          filterText: tmpObj.searchCtrl.value,
          pageSize: tmpObj.pageSize,
          skip: tmpObj.paginator
            ? tmpObj.paginator.pageIndex * tmpObj.pageSize
            : tmpObj.pageIndex * tmpObj.pageSize,
          sortvalue: this.sortvalue,
          startDate: this.startDate,
          endDate: this.endDate,
          advocateId: this.selectedAdovocate_id,
          retainerId: this.selectedRetainer_id,
          // courtCourt:this.courtCourt
          obj
        },
        function (socketResponse) {
          console.log(socketResponse, "socketResponse");
          if (socketResponse.status == "OK") {
            // socketResponse.data.length=0
            if (socketResponse.data.length>0) {
              tmpObj.users = socketResponse.data;
              let usersList: User[] = [];
              // socketResponse.data.length = 0
              for (let i = 0; i < socketResponse.data.length; i++) {
                usersList.push(new User(socketResponse.data[i]));
              }
              // console.log(usersList,'usersList')
              tmpObj.dataSource = new MatTableDataSource(usersList);

              tmpObj.totalRecords = socketResponse.totalRecords;
              // tmpObj.totalRecords = 9
              let record = tmpObj.totalRecords / 10;
              let secrecord = (record + "").split(".")[1];

              if (Number(secrecord)) {
                tmpObj.totalpages = parseInt(tmpObj.totalRecords / 10 + "");
                tmpObj.totalpages = Number(tmpObj.totalpages) + 1;
              } else {
                tmpObj.totalpages = tmpObj.totalRecords / 10;
              }
            } else if (
              socketResponse.data.length == 0 &&
              tmpObj.searchCtrl.value
            ) {
              tmpObj.msg = socketResponse.msg;
              // console.log(tmpObj.dataSource.filteredData,"tmpObj.dataSource")
              // tmpObj.dataSource = null
              tmpObj.dataSource.filteredData.length = 0;
              console.log(tmpObj.msg, "this.msg");
            } else {
              tmpObj.dataSource = new MatTableDataSource(socketResponse.data)

              tmpObj.msg = "No results containing In Our Record Please ADD .";
            }
          } else {
            tmpObj.snackbar.open(socketResponse.message, "Okay", {
              duration: 4000,
            });
          }
        }
      );
    }
  }

  createCustomer() {
    // console.log(this.tabIndexValueforForm,"this.tabIndexValueforForm")
    if (environment.roleDetails) {
      // if (this.commonServices.isUserAllowedToCreateCourtCase(this.moduleDetails)) {
    // console.log(environment.roleDetails,"hello user")

      this.dialog
        .open(CourtCasesCreateUpdateComponent, {
          data:this.tabIndexValueforForm,
          panelClass: "court-case-dialog-container",
          // height: "550px",
          // width: "1200px",
          disableClose: true,
        })
        .afterClosed()
        .subscribe((user: User) => {
          console.log(" user after ", user);
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.createCourtCase,user,function (socketResponse) {
                  if (socketResponse.status == "OK") {
                    tmpObj.snackbar.open(
                      "Successfully Created Court Case.",
                      "Okay",
                      {
                        duration: 4000,
                      }
                    );

                    if(user.courtDetails.courtType=='1'){
                      tmpObj.selectedTabIndex=0;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='2'){
                      tmpObj.selectedTabIndex=1;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='3'){
                      tmpObj.selectedTabIndex=2;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='8'){
                      tmpObj.selectedTabIndex=3;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='4'){
                      tmpObj.selectedTabIndex=4;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else{
                      tmpObj.selectedTabIndex=5;
                      this.refreshData(this,{});
                    }
                    
                  } else {
                    tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType});
                    tmpObj.snackbar.open(socketResponse.message, "Okay", {
                      duration: 4000,
                    });
                  }
                }
              );
            }
          }
        });
        
    } else {
      this.snackbar.open(
        "You are not authorize to perform this operation.",
        "Okay",
        {
          duration: 4000,
        }
      );
      // }
    }
  }

  updateCustomer(user: User) {
    if (environment.roleDetails) {
      console.log(user,'user')
      // if (this.commonServices.isUserAllowedToEditCourtCase(user, this.moduleDetails)) {
      this.dialog
        .open(CourtCasesCreateUpdateComponent, {
          data: user,
          panelClass: "court-case-dialog-container",
          disableClose: true,
        })
        .afterClosed()
        .subscribe((updatedCustomer) => {
          console.log(updatedCustomer,"updatedCustomer")
          if (updatedCustomer) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(
                environment.socketEvents.updateCourtCase,
                updatedCustomer,
                function (socketResponse) {
                  if (socketResponse.status == "OK") {
                    tmpObj.snackbar.open(
                      "Successfully Updated Court Case.",
                      "Okay",
                      {
                        duration: 4000,
                      }
                    );
                    console.log(user.courtDetails.courtType,"user.courtDetails.courtType")
                    if(user.courtDetails.courtType=='1'){
                      tmpObj.selectedTabIndex=0;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='2'){
                      tmpObj.selectedTabIndex=1;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='3'){
                      tmpObj.selectedTabIndex=2;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='8'){
                      tmpObj.selectedTabIndex=3;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='9'){
                      console.log("dhfgdjfghkjfd")
                      tmpObj.selectedTabIndex=6;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else if(user.courtDetails.courtType=='4'){
                      tmpObj.selectedTabIndex=4;
                      tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType})
                    }else{
                      tmpObj.selectedTabIndex=5;
                      this.refreshData(this,{});
                    }

                    tmpObj.refreshData(tmpObj,{courtType:user.courtDetails.courtType});
                    tmpObj.ConsumerForumOfDistrictCommissionForm.reset()
                    tmpObj.ConsumerForumOfStateCommossionForm.reset()
                    tmpObj.ConsumerForumOfNCDRCForm.reset()
                    tmpObj.searchTribunalCourtForm.reset()
                    tmpObj.searchDistrictCourtForm.reset()
                    tmpObj.searchHighCourtForm.reset()
                    tmpObj.searchSupremeCourtForm.reset()
                    tmpObj.selectedOption=""
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, "Okay", {
                      duration: 4000,
                    });
                  }
                }
              );
            }
          }
        });
    } else {
      this.snackbar.open(
        "You are not authorize to perform this operation.",
        "Okay",
        {
          duration: 4000,
        }
      );
      // }
    }
  }

  deleteCustomer(customer: User) {
    if (environment.roleDetails) {
      // if (this.commonServices.isUserAllowedToDeleteCourtCase(customer, this.moduleDetails)) {
      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;
        this.socketObject.emit(
          environment.socketEvents.deleteCourtCase,
          customer,
          function (socketResponse) {
            if (socketResponse.status == "OK") {
              tmpObj.snackbar.open("Successfully Deleted Court Case.", "Okay", {
                duration: 4000,
              });
              tmpObj.refreshData(tmpObj,{courtType:customer.courtDetails.courtType});
            } else {
              tmpObj.snackbar.open(socketResponse.message, "Okay", {
                duration: 4000,
              });
            }
          }
        );
      }
    } else {
      this.snackbar.open(
        "You are not authorize to perform this operation.",
        "Okay",
        {
          duration: 4000,
        }
      );
      // }
    }
  }

  deleteusers(users: User[]) {
    /**
     * Here we are updating our local array.
     * You would probably make an HTTP request here.
     */
    users.forEach((c) => this.deleteCustomer(c));
  }

  onFilterChange(value: string) {
    if (!this.dataSource) {
      return;
    }
    value = value.trim();
    value = value.toLowerCase();

    if (value.length >= 3 || value.length == 0) {
      if(this.selectedTabIndex==0){
        this.refreshData(this,{courtType:"1"});
      }else if(this.selectedTabIndex==1){
        this.refreshData(this,{courtType:"2"});
      }else if(this.selectedTabIndex==2){
        this.refreshData(this,{courtType:"3"});
      }else if(this.selectedTabIndex==3){
        this.refreshData(this,{courtType:"8"});
      }else if(this.selectedTabIndex==4){
        this.refreshData(this,{courtType:"4"});
      }
    }
  }

  toggleColumnVisibility(column, event) {
    event.stopPropagation();
    event.stopImmediatePropagation();
    column.visible = !column.visible;
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  trackByProperty<T>(index: number, column: TableColumn<T>) {
    return column.property;
  }

  ngOnDestroy() {
    if (this.userDetailUpdateSubscriber) {
      this.userDetailUpdateSubscriber.unsubscribe();
    }
  }

  goToDiscussionBoard(event, caseDetails: User) {
    event.stopPropagation();
    if (environment.roleDetails) {
      let subModulesKey = [];
      if (
        environment.roleDetails["allowedAccesses"] &&
        environment.roleDetails["allowedAccesses"][this.moduleDetails.key]
      ) {
        subModulesKey = Object.keys(
          environment.roleDetails["allowedAccesses"][this.moduleDetails.key]
            .subModules
        );
      }

      if (
        (environment.roleDetails.name == "super_admin" &&
          environment.roleDetails.is_custom_role == false) ||
        subModulesKey.indexOf(this.moduleDetails.subModules.discussionBoard) !=
          -1
      ) {
        this.router.navigate([
          "/app/discussion-board",
          caseDetails._id,
          environment.constants.courtCase,
        ]);
      } else {
        this.snackbar.open(
          "You are not authorize to perform this operation.",
          "Okay",
          {
            duration: 4000,
          }
        );
      }
    }
  }

  changeCaseStatus(courtCaseDetails) {
    if (environment.roleDetails) {
      // if (this.commonServices.isUserAllowedToEditCourtCase(courtCaseDetails, this.moduleDetails)) {
      this.dialog
        .open(CaseStatusChangeComponent, {
          data: courtCaseDetails,
          disableClose: true,
        })
        .afterClosed()
        .subscribe((user: User) => {
          if (user) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(
                environment.socketEvents.changeCourtCaseStatus,
                user,
                function (socketResponse) {
                  if (socketResponse.status == "OK") {
                    tmpObj.snackbar.open(
                      "Successfully Changed Court Case Status.",
                      "Okay",
                      {
                        duration: 4000,
                      }
                    );
                    tmpObj.refreshData(tmpObj,{});
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, "Okay", {
                      duration: 4000,
                    });
                  }
                }
              );
            }
          }
        });
    }
    // }
  }

  goToCaseDetailsPage(caseDetails: User) {
    // console.log(caseDetails,",sdfg,khdgf")
    this.router.navigate([
      "/app/cases-management/case-details",
      caseDetails._id,
      environment.constants.courtCase,
    ]);
  }

  pageChangeHandler(eventDetails,code) {
    if(code==1){
      if (eventDetails && eventDetails.pageSize) {
        this.pageIndex = eventDetails.pageIndex;
        this.pageSize = eventDetails.pageSize;
      }
      this.refreshData(this,{courtType:"1"});
    }else if(code==2){
      if (eventDetails && eventDetails.pageSize) {
        this.pageIndex = eventDetails.pageIndex;
        this.pageSize = eventDetails.pageSize;
      }
      this.refreshData(this,{courtType:"2"});
    }else if(code==3){
      if (eventDetails && eventDetails.pageSize) {
        this.pageIndex = eventDetails.pageIndex;
        this.pageSize = eventDetails.pageSize;
      }
      this.refreshData(this,{courtType:"3"});
    }else if(code==4){
      if (eventDetails && eventDetails.pageSize) {
        this.pageIndex = eventDetails.pageIndex;
        this.pageSize = eventDetails.pageSize;
      }
      this.refreshData(this,{courtType:"8"});
    }else if(code==5){
      if (eventDetails && eventDetails.pageSize) {
        this.pageIndex = eventDetails.pageIndex;
        this.pageSize = eventDetails.pageSize;
      }
      this.refreshData(this,{courtType:"4"});
    }
    else if(code==6){
      if (eventDetails && eventDetails.pageSize) {
        this.pageIndex = eventDetails.pageIndex;
        this.pageSize = eventDetails.pageSize;
      }
      this.refreshData(this,{});
    }
  }

  showAddHearingDialog(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog
        .open(HearingsCreateUpdateComponent, {
          panelClass: "hearings-dialog-container",
          data: {
            courtCase: courtCaseDetails,
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((hearingDetails) => {
          if (hearingDetails) {
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(
                environment.socketEvents.createHearing,
                hearingDetails,
                function (socketResponse) {
                  if (socketResponse.status == "OK") {
                    tmpObj.snackbar.open(
                      "Successfully Created Hearing.",
                      "Okay",
                      {
                        duration: 4000,
                      }
                    );
                    tmpObj.refreshData(tmpObj,{});
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, "Okay", {
                      duration: 4000,
                    });
                  }
                }
              );
            }
          }
        });
    }
  }

  shareWithInternalUser(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog
        .open(CourtCaseInternalSharingComponent, {
          panelClass: "hearings-dialog-container",
          data: {
            courtCase: courtCaseDetails,
            userPermissions: null,
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((internalSharingDetails) => {
          if (internalSharingDetails) {
            internalSharingDetails.courtCaseId = courtCaseDetails._id;
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(
                environment.socketEvents.shareCourtCaseInternalUser,
                internalSharingDetails,
                function (socketResponse) {
                  if (socketResponse.status == "OK") {
                    tmpObj.snackbar.open(
                      "Successfully Shared with application user.",
                      "Okay",
                      {
                        duration: 4000,
                      }
                    );
                    tmpObj.refreshData(tmpObj,{});
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, "Okay", {
                      duration: 4000,
                    });
                  }
                }
              );
            }
          }
        });
    }
  }

  showInternalShareList(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog
        .open(CourtCaseSharesListComponent, {
          panelClass: "hearings-dialog-container",
          data: {
            courtCase: courtCaseDetails,
            userPermissions: {},
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((internalSharingDetails) => {});
    }
  }

  shareWithExternalUser(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog
        .open(ExternalSharingComponent, {
          panelClass: "hearings-dialog-container",
          data: {
            courtCase: courtCaseDetails,
            userPermissions: null,
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((externalSharingDetails) => {
          if (externalSharingDetails) {
            externalSharingDetails.courtCaseId = courtCaseDetails._id;
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(
                environment.socketEvents.shareCourtCaseExternalUser,
                externalSharingDetails,
                function (socketResponse) {
                  if (socketResponse.status == "OK") {
                    tmpObj.snackbar.open(
                      "Successfully Shared with External User.",
                      "Okay",
                      {
                        duration: 4000,
                      }
                    );
                    tmpObj.refreshData(tmpObj,{});
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, "Okay", {
                      duration: 4000,
                    });
                  }
                }
              );
            }
          }
        });
    }
  }

  showExternalShareList(courtCaseDetails) {
    if (courtCaseDetails) {
      this.dialog
        .open(SharesListExternalUsersComponent, {
          panelClass: "hearings-dialog-container",
          data: {
            courtCase: courtCaseDetails,
            userPermissions: {},
          },
          disableClose: true,
        })
        .afterClosed()
        .subscribe((externalSharingDetails) => {});
    }
  }

  radioChange(e) {
    this.sortvalue = e.value;
    this.ConsumerForumOfDistrictCommissionForm.reset()
    this.ConsumerForumOfStateCommossionForm.reset()
    this.ConsumerForumOfNCDRCForm.reset()
    this.searchTribunalCourtForm.reset()
    this.searchDistrictCourtForm.reset()
    this.searchHighCourtForm.reset()
    this.searchSupremeCourtForm.reset()
    if(this.selectedTabIndex==0){
      this.refreshData(this,{courtType:"1"});
    }else if(this.selectedTabIndex==1){
      this.refreshData(this,{courtType:"2"});
    }else if(this.selectedTabIndex==2){
      this.refreshData(this,{courtType:"3"});
    }else if(this.selectedTabIndex==3){
      this.refreshData(this,{courtType:"8"});
    }else if(this.selectedTabIndex==4){
      this.refreshData(this,{courtType:"4"});
    }
  }

  datesRangeSelectedHandler(event) {
    this.startDate = event.startDate.format("YYYY-MM-DD");
    this.endDate = event.endDate.format("YYYY-MM-DD");
    this.refreshData(this,{});
  }

  getAdvocateName(advocateDetails) {
    let name = "N/A";

    if (advocateDetails) {
      if (advocateDetails.userType == "firm") {
        name = advocateDetails.firmName;
      } else {
        name = advocateDetails.firstName + " " + advocateDetails.lastName;
      }
    }

    return name;
  }

  advocateChangeHandler() {
    this.selectedretainership = "";
    this.selectedRetainer_id = "";
    this.selectedAdovocate_id = this.selectedAdovocate;
    this.ConsumerForumOfDistrictCommissionForm.reset()
    this.ConsumerForumOfStateCommossionForm.reset()
    this.ConsumerForumOfNCDRCForm.reset()
    this.searchTribunalCourtForm.reset()
    this.searchDistrictCourtForm.reset()
    this.searchHighCourtForm.reset()
    this.searchSupremeCourtForm.reset()
    if(this.selectedTabIndex==0){
      this.refreshData(this,{courtType:"1"});
    }else if(this.selectedTabIndex==1){
      this.refreshData(this,{courtType:"2"});
    }else if(this.selectedTabIndex==2){
      this.refreshData(this,{courtType:"3"});
    }else if(this.selectedTabIndex==3){
      this.refreshData(this,{courtType:"8"});
    }else if(this.selectedTabIndex==4){
      this.refreshData(this,{courtType:"4"});
    }
  }
  retainerchangehandler() {
    this.selectedAdovocate = "";
    this.selectedAdovocate_id = "";
    this.selectedRetainer_id = this.selectedretainership;
    this.refreshData(this,{});
  }

  createCase() {
    this.router.navigate(["/app/cases-management/add-case"]);
  }

  checkdate(date) {
    let newdate = new Date();
    let perviousdate = new Date(date);
    // console.log(typeof(newdate),typeof(perviousdate)," if date")
    if (newdate > perviousdate) {
      return perviousdate;
    } else {
      // console.log(newdate<date," else date")
      return "N/A";
    }
  }

  checknextdate(date) {
    let newdate = new Date();
    let perviousdate = new Date(date);
    if (newdate < perviousdate) {
      return perviousdate;
    } else {
      // console.log(newdate<date," else date")
      return "N/A";
    }
  }

  // ngOnChanges() {
  //   this.receivedData = this.data;
  //   console.log(this.receivedData,'skfghskjgkjhg')
  //   // if(this.receivedData){
  //     this.refreshData(this,{}) 
  //   // }

  // }
courtName
  public selectedTabIndex: number = 0;
  tabChanged(tabEvents: MatTabChangeEvent) {
    console.log(tabEvents)
    this.courtName = tabEvents.tab.textLabel
    console.log(this.courtName,"this.courtName")
    if(tabEvents.index==0){
      this.selectedTabIndex = tabEvents.index;
      this.searchSupremeCourtForm.reset()
      this.refreshData(this,{courtType:"1",skip:0});
    }else if(tabEvents.index==1){
      this.selectedTabIndex = tabEvents.index;
      this.getstateOfHighCourt()
      this.searchHighCourtForm.reset()
      this.refreshData(this,{courtType:"2",skip:0});
    }else if(tabEvents.index==2){
      this.selectedTabIndex = tabEvents.index;
      this.getstateOfDistrictCourt()
      this.searchDistrictCourtForm.reset()
      this.refreshData(this,{courtType:"3",skip:0});
    }else if(tabEvents.index==3){
      this.selectedTabIndex = tabEvents.index;
      this.searchTribunalCourtForm.reset()
      this.refreshData(this,{courtType:"8",skip:0});
    }else if(tabEvents.index==4){
      this.selectedTabIndex = tabEvents.index;
      this.selectedOption=""
      this.refreshData(this,{courtType:"4",skip:0});
    }
    else if(tabEvents.index==5){
      this.selectedTabIndex = tabEvents.index;
      this.selectedOption=""
      this.refreshData(this,{courtType:"9",skip:0});
    }else{
      console.log(tabEvents.index,"tabEvents.index")
      this.selectedTabIndex = tabEvents.index;
      this.selectedOption=""
      this.refreshData(this,{});
    }
  }


  getyear(){
  
    // this.myForm.value
    this.court.postyear('ws/caseYears').subscribe((res2: any) => {
      this.yeardata = res2.caseYear


    })
    // ws/courtCategories

    
  }

  searchHighCourtForm = new FormGroup({
    courtType: new FormControl(''),
    state_code: new FormControl(''),
    courtName: new FormControl(''),
    case_year: new FormControl('')
  })

  searchDistrictCourtForm = new FormGroup({
    courtType: new FormControl(''),
    state_code: new FormControl(''),
    state_code_new: new FormControl(''),
    district: new FormControl(''),
    case_year: new FormControl('')
  })

  searchTribunalCourtForm = new FormGroup({
    courtType: new FormControl(''),
    location: new FormControl(''),
    case_year: new FormControl('')
  })

  searchSupremeCourtForm = new FormGroup({
    courtType: new FormControl(''),
    case_year: new FormControl(''),
  })

  ConsumerForumOfNCDRCForm = new FormGroup({
    courtType: new FormControl(''),
    commission_type: new FormControl(''),
    case_year: new FormControl('')
  })
  ConsumerForumOfStateCommossionForm = new FormGroup({
    courtType: new FormControl(''),
    commission_type: new FormControl(''),
    state_code: new FormControl(''),
    case_year: new FormControl('')
  })
  ConsumerForumOfDistrictCommissionForm = new FormGroup({
    courtType: new FormControl(''),
    commission_type: new FormControl(''),
    state_code: new FormControl(''),
    district: new FormControl(''),
    case_year: new FormControl('')
  })

  toggleNcdrc = false;
  toggleStateCommission = false;
  toggleDistrictCommission = false;
  getValue(val) {
    if (val == "Ncdrc") {
      this.toggleNcdrc = true;
      this.toggleStateCommission = false;
      this.toggleDistrictCommission = false;
      this.ConsumerForumOfNCDRCForm.reset();

      this.ConsumerForumOfNCDRCForm.value.courtType='4'
      this.ConsumerForumOfNCDRCForm.value.commission_type='B'
      this.refreshData(this,this.ConsumerForumOfNCDRCForm.value);
    } else if (val == "stateCommission") {
      this.toggleNcdrc = false;
      this.toggleStateCommission = true;
      this.toggleDistrictCommission = false;
      this.ConsumerForumOfStateCommossionForm.reset()

      this.ConsumerForumOfStateCommossionForm.value.courtType='4'
      this.ConsumerForumOfStateCommossionForm.value.commission_type='C'
      this.refreshData(this,this.ConsumerForumOfStateCommossionForm.value);
    } else if (val == "districtCommission") {
      this.toggleNcdrc = false;
      this.toggleStateCommission = false;
      this.toggleDistrictCommission = true;
      this.ConsumerForumOfDistrictCommissionForm.reset();

      this.ConsumerForumOfDistrictCommissionForm.value.courtType='4'
      this.ConsumerForumOfDistrictCommissionForm.value.commission_type='E'
      this.refreshData(this,this.ConsumerForumOfDistrictCommissionForm.value);
    }
  }

  getFilterData(courtType,code){
    this.serverlist = [];

    if(courtType=='1'){

      this.searchSupremeCourtForm.value.courtType='1'
      this.refreshData(this,this.searchSupremeCourtForm.value);

    }else if(courtType=='2'){

      if(code==1){
        this.searchHighCourtForm.controls['courtName'].reset()
        this.searchHighCourtForm.controls['case_year'].reset()
      }

      if(this.searchHighCourtForm.value.state_code){
        this.getServerName(this.searchHighCourtForm.value.state_code);
      }

      this.searchHighCourtForm.value.courtType='2'
      this.refreshData(this,this.searchHighCourtForm.value);

    }else if(courtType=='3'){

      if(code==1){
        this.searchDistrictCourtForm.controls['district'].reset()
        this.searchDistrictCourtForm.controls['case_year'].reset()
      }

      if(this.searchDistrictCourtForm.value.state_code){

        // this.state.forEach(element => {
        //   if(this.searchDistrictCourtForm.value.state_code==element.state_code){
        //     this.searchDistrictCourtForm.value.state_code_new=element.state_code_new
        //   }
        // });

        let obj ={

          "state_code":this.searchDistrictCourtForm.value.state_code
        }
        console.log(obj)
        this.districtcourt.postecourt('ws/eGetCourtStatesDistrict',obj).subscribe((res2: any) => {
          console.log(res2,"res2 of state")
        
           this.districtlist = res2.courtStateDistricts
          })
      }

      this.searchDistrictCourtForm.value.courtType='3'
      console.log(this.searchDistrictCourtForm.value)
      this.refreshData(this,this.searchDistrictCourtForm.value);

    }else if(courtType=='8'){

      if(code==1){
        this.searchTribunalCourtForm.controls['case_year'].reset()
      }
      
      this.searchTribunalCourtForm.value.courtType='8'
      this.refreshData(this,this.searchTribunalCourtForm.value);
    }else if(courtType=='4'){

      if(code==1){
        this.ConsumerForumOfNCDRCForm.value.courtType='4'
        this.ConsumerForumOfNCDRCForm.value.commission_type='B'
        this.refreshData(this,this.ConsumerForumOfNCDRCForm.value);
      }
      if(code==2){
        this.ConsumerForumOfStateCommossionForm.value.courtType='4'
        this.ConsumerForumOfStateCommossionForm.value.commission_type='C'
        this.refreshData(this,this.ConsumerForumOfStateCommossionForm.value);
      }
      if(code==3){
        for(let data of this.DistrictCommissionState){
          if(this.ConsumerForumOfDistrictCommissionForm.value.state_code==data.district_commission_state_id){
            this.getDistrictCommissionId(data.id)
          }
        }

        

        this.ConsumerForumOfDistrictCommissionForm.value.courtType='4'
        this.ConsumerForumOfDistrictCommissionForm.value.commission_type='E'
        this.refreshData(this,this.ConsumerForumOfDistrictCommissionForm.value);
      }

    }
  }

  serverlist = [];
  getServerName(val) {
    let obj = {
      state_code: val,
    };

    this.highcourt.postecourt("ws/serverName", obj).subscribe((res) => {
      if (res["status"] == true) {
        this.serverlist.push(...res["courtInfo"]);
      }
    });
  }

  locationCategory;
  locationCategoryDetails() {
    this.tribunalService
      .getData('locationCategory')
      .subscribe((res) => {
        this.locationCategory = res.locationCategory;
        // console.log(this.locationCategory)
      });
  }

  caseYearCategory;
  caseYearDetails() {
    this.court
    .postyear('ws/caseYears')
      .subscribe((res) => {
        this.caseYearCategory = res['caseYear'];
        // this.caseYearCategory.unshift({year:"All"})
      });
  }

  StateCommissionState;
  getStateCommissionState() {
    this.consumerCourtService
      .getData('stateCommissionCategory')
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.StateCommissionState = res.stateCommissionCategory;
            // console.log(this.StateCommissionState)
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  DistrictCommissionState;
  getDistrictCommissionState() {
    this.consumerCourtService
      .getData('districtCommissionStatesCategory')
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.DistrictCommissionState = res.districtCommissionStatesCategory;
            // console.log(this.DistrictCommissionState)
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  districtData;
  getDistrictCommissionId(id) {
    let obj = {
      id: id,
    };
    this.consumerCourtService
      .postData('districtCommissionDistrictsCategory', obj)
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.districtData = res.districtCommissionDistrictsCategory;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
}
