import { Component, Inject, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { User } from '../interfaces/users.model';
import icMoreVert from '@iconify/icons-ic/twotone-more-vert';
import icClose from '@iconify/icons-ic/twotone-close';
import icPrint from '@iconify/icons-ic/twotone-print';
import icDownload from '@iconify/icons-ic/twotone-cloud-download';
import icDelete from '@iconify/icons-ic/twotone-delete';
import icPerson from '@iconify/icons-ic/twotone-person';
import icSecurity from '@iconify/icons-ic/twotone-security';
import icEmail from '@iconify/icons-ic/twotone-email';
import icEditLocation from '@iconify/icons-ic/twotone-edit-location';
import icDeleteForever from '@iconify/icons-ic/twotone-delete-forever';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icNotes from '@iconify/icons-ic/twotone-notes';
import { MatSnackBar } from '@angular/material';
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { CourtCaseInternalSharingComponent } from '../court-case-internal-sharing/court-case-internal-sharing.component';
import { MutualCaseInternalSharingComponent } from '../mutual-case-internal-sharing/mutual-case-internal-sharing.component';

@Component({
  selector: 'vex-mutual-case-shares-list',
  templateUrl: './mutual-case-shares-list.component.html',
  styleUrls: ['./mutual-case-shares-list.component.scss']
})
export class MutualCaseSharesListComponent implements OnInit {

  private socketObject;
  selectedFileName;

  public permissionKeyAlias = environment.permissionLevelsKeysName;

  form: FormGroup;
  mode: 'create' | 'update' = 'create';

  public sectionNames = {
    "uploadDocuments": "Upload Docs"
  };
  
  public frmDocDescription: FormGroup;
  public descDescriptionDiaglogRef: MatDialogRef<any>;
  private selectedDocIndex = -1;

  public permissionLevels = environment.permissionLevels;

  icMoreVert = icMoreVert;
  icClose = icClose;

  icPrint = icPrint;
  icDownload = icDownload;
  icDelete = icDelete;
  icEmail = icEmail;
  icSecurity = icSecurity;
  icEdit = icEdit;
  icNotes = icNotes;

  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icDeleteForever = icDeleteForever;

  usersList = {};


  constructor(@Inject(MAT_DIALOG_DATA) public details: any,
    private dialogRef: MatDialogRef<MutualCaseSharesListComponent>,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private _layoutService: LayoutService,
    private commonServices: CommonService,
    private socketService: SocketService) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {

    if(this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.listUsersRoleUser, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          let tmp = {};
          for ( let i=0; i < socketResponse.data.length; i++ ) {
            tmp[socketResponse.data[i]._id] = socketResponse.data[i];
          }
          tmpObj.usersList = tmp;
        }
      });
    }
    
    
    if ( this.details && this.details.userPermissions) {
      this.mode = 'update';
    } else {
      this.details.userPermissions = {};
    }

  }

  isCreateMode() {
    return this.mode === 'create';
  }

  isUpdateMode() {
    return this.mode === 'update';
  }

  getObjectKeys(object) {
    let keys = [];

    if ( object ) {
      keys = Object.keys(object);
    }

    return keys;
  }

  getUserName(internalShareDetails) {
    let name = "N/A";

    if ( internalShareDetails && this.usersList[internalShareDetails.userId] ) {
      name = this.usersList[internalShareDetails.userId].firstName + " " + this.usersList[internalShareDetails.userId].lastName;
    }

    return name;
  }

  getRoleName(internalShareDetails) {
    let name = "N/A";

    if ( internalShareDetails && this.usersList[internalShareDetails.userId] ) {
      name = this.usersList[internalShareDetails.userId].role ? this.usersList[internalShareDetails.userId].role.name: "";
    }

    return name;
  }

  editPermission(eventDetails, permissionIndex, permissionDetails) {
    eventDetails.stopPropagation();
    if (this.details.mutualCase) {
      this.dialog.open(MutualCaseInternalSharingComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          mutualCase: this.details.mutualCase,
          userPermissions: permissionDetails
        },
        disableClose: true
      }).afterClosed().subscribe((internalSharingDetails) => {
        if (internalSharingDetails) {
          internalSharingDetails.mutualCaseId = this.details.mutualCase._id;
          internalSharingDetails.permissionIndex = permissionIndex;
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.updateShareMutualCaseInternalUser, internalSharingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackBar.open('Successfully Updated user permissions.', 'Okay', {
                  duration: 4000
                });
              } else {
                tmpObj.snackBar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }
}
