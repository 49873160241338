import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from "@angular/material";
import { CommonService } from "src/app/services/core/common.service";
import { SocketService } from "src/app/services/core/socket.service";
import { environment } from "src/environments/environment";
import { HimachalhighcourtService } from '../highcourt/himachalhighcourt.service';

@Component({
  selector: 'vex-himachal-detail',
  templateUrl: './himachal-detail.component.html',
  styleUrls: ['./himachal-detail.component.scss']
})
export class HimachalDetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<HimachalDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private himachalhighcourt:HimachalhighcourtService,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackBar: MatSnackBar
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
   }

  showmsg:String=''
  showHeader:boolean = false;
  handleMatSprnner:boolean = false;
  caseDetail:any;
  MatSprnnerOfSection:boolean=false
  obj:any;
   private socketObject;

  ngOnInit() {
    this.handleMatSprnner=true
    if(this.data.searchcode==1){
      this.showHeader=true
      this.caseDetail=this.data
      this.obj = {court_case_id: this.caseDetail.id}
      this.caseStatusHimachal()
      this.partyDetailHimachal()
      this.handleMatSprnner=false
    }else{
      let obj = {
        case_cino: this.data.case_cino,
        case_detail: this.data.case_detail
      }
      this.himachalhighcourt.postData('caseDetails',obj).subscribe((res)=>{
        if(res.status){
          this.showHeader=true
          this.caseDetail=res.caseDetails;
          this.obj = {court_case_id: this.caseDetail.id}
          this.handleMatSprnner=false
        }else{
          this.showmsg=res.message
          this.handleMatSprnner=false
        }
      },(err)=>{
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
      })
    }
  }

  caseStatushimachal;
  countofcasestatus=0
  caseStatusHimachal(){
    if(this.countofcasestatus==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('caseStatus',this.obj).subscribe((res)=>{
        if(res.status){
          this.caseStatushimachal=res.caseStatus;
          this.MatSprnnerOfSection=false
          this.countofcasestatus=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.countofcasestatus=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  partyDetailhimachal;
  countofpartydetail=0;
  partyDetailHimachal(){
    if(this.countofpartydetail==0){
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('partyDetail',this.obj).subscribe((res)=>{
        if(res.status){
          this.partyDetailhimachal=res.partyDetail;
          this.MatSprnnerOfSection=false
          this.countofpartydetail=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.countofpartydetail=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  actsDetailhimachal;
  actdetailcount=0;
  actsDetailHimachal(){
    if(this.actdetailcount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('actsDetails',this.obj).subscribe((res)=>{
        if(res.status){
          this.actsDetailhimachal=res.actsDetails;
          this.MatSprnnerOfSection=false
          this.actdetailcount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.actdetailcount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  mainMattershimachal;
  mainMatterscount=0;
  mainMattersHimachal(){
    if(this.mainMatterscount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('mainMatters',this.obj).subscribe((res)=>{
        if(res.status){
          this.mainMattershimachal=res.mainMatters;
          this.MatSprnnerOfSection=false
          this.mainMatterscount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.mainMatterscount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  connectedCaseshimachal;
  connectedCasescount=0;
  connectedCasesHimachal(){
    if(this.connectedCasescount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('connectedCases',this.obj).subscribe((res)=>{
        if(res.status){
          this.connectedCaseshimachal=res.connectedCases;
          this.MatSprnnerOfSection=false
          this.connectedCasescount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.connectedCasescount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  applicationCaseshimachal;
  applicationCasescount=0;
  applicationCasesHimachal(){
    if(this.applicationCasescount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('applicationCases',this.obj).subscribe((res)=>{
        if(res.status){
          this.applicationCaseshimachal=res.applicationCases;
          this.MatSprnnerOfSection=false
          this.applicationCasescount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.applicationCasescount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  listingDateshimachal;
  listingDatescount=0;
  listingDatesHimachal(){
    if(this.listingDatescount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('listingDates',this.obj).subscribe((res)=>{
        if(res.status){
          this.listingDateshimachal=res.listingDates;
          this.MatSprnnerOfSection=false
          this.listingDatescount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.listingDatescount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  orderJudgementhimachal;
  orderJudgementcount=0;
  orderJudgementHimachal(){
    if(this.orderJudgementcount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('orderJudgement',this.obj).subscribe((res)=>{
        if(res.status){
          this.orderJudgementhimachal=res.orderJudgement;
          this.MatSprnnerOfSection=false
          this.orderJudgementcount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.orderJudgementcount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }
  lowerCourtDetailhimachal;
  lowerCourtDetailcount=0;
  lowerCourtDetailHimachal(){
    if(this.lowerCourtDetailcount==0){ 
      this.MatSprnnerOfSection=true
      this.himachalhighcourt.postData('lowerCourtDetail',this.obj).subscribe((res)=>{
        if(res.status){
          this.lowerCourtDetailhimachal=res.lowerCourtDetail;
          this.MatSprnnerOfSection=false
          this.lowerCourtDetailcount=1
        }else{
          this.showmsg=res.message
          this.MatSprnnerOfSection=false
          this.lowerCourtDetailcount=1
        }
      },(err)=>{
        console.log(err)
        this.MatSprnnerOfSection=false
      })
    }
  }

  saveData(){
    let obj = {
      registration_no:this.caseDetail.registration_no,
      cnr_no:this.caseDetail.cnr_no,
      state_code:this.caseDetail.state_code,
      serverType:this.caseDetail.serverType,
      case_type:this.caseDetail.case_type,
      case_number:this.caseDetail.case_no,
      case_year:this.caseDetail.case_year,
      case_category:this.caseDetail.case_category,
      next_hearing_date:this.caseStatushimachal.next_hearing_date,
      respondent:this.partyDetailhimachal.petitioner_detail,
      petitioner:this.partyDetailhimachal.respondent_detail,
    }
    console.log(obj,'2555555555555')
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createhimachalhighcourt, obj , function (socketResponse) {
        console.log(socketResponse)
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created high court cases', 'Okay', {
            duration: 4000
          });
          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
          // this.dialogRef.close();
        }
      });
      // this.dialogRef.close();
    }
  }

  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert("No data Found");
    }
  }

  step = 0;
  setStep(index: number) {
    this.step = index;
  }
  nextStep() {
    this.step++;
    if(this.step==1){
      this.caseStatusHimachal()
    }
    else if(this.step==2){
      this.partyDetailHimachal()
    }
    else if(this.step==3){
      this.actsDetailHimachal()
    }
    else if(this.step==4){
      this.mainMattersHimachal()
    }
    else if(this.step==5){
      this.connectedCasesHimachal()
    }
    else if(this.step==6){
      this.applicationCasesHimachal()
    }
    else if(this.step==7){
      this.listingDatesHimachal()
    }
    else if(this.step==8){
      this.orderJudgementHimachal()
    }
    else if(this.step==9){
      this.lowerCourtDetailHimachal()
    }
  }

  prevStep() {
    this.step--;
    if(this.step==1){
      this.caseStatusHimachal()
    }
    else if(this.step==2){
      this.partyDetailHimachal()
    }
    else if(this.step==3){
      this.actsDetailHimachal()
    }
    else if(this.step==4){
      this.mainMattersHimachal()
    }
    else if(this.step==5){
      this.connectedCasesHimachal()
    }
    else if(this.step==6){
      this.applicationCasesHimachal()
    }
    else if(this.step==7){
      this.listingDatesHimachal()
    }
    else if(this.step==8){
      this.orderJudgementHimachal()
    }
    else if(this.step==9){
      this.lowerCourtDetailHimachal()
    }
  }

}
