import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild, AfterContentInit } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
// import { aioTableLabels } from '../../../../static-data/aio-table-data';
import icPrint from '@iconify/icons-ic/twotone-print';
// import { fadeInUp400ms } from '../../../../@vex/animations/fade-in-up.animation';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
// import { HearingsCreateUpdateComponent } from '../../hearings/hearings-create-update/hearings-create-update.component';
// import { AppointmentsCreateUpdateComponent } from '../../appointments/appointments-create-update/appointments-create-update.component';
// import { stagger40ms } from '../../../../@vex/animations/stagger.animation';
import { FormControl } from '@angular/forms';
// import theme from '../../../../@vex/utils/tailwindcss';
import { Router, ActivatedRoute } from '@angular/router';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import icTrackChanges from '@iconify/icons-ic/twotone-track-changes';
import { MatSnackBar } from '@angular/material';
import { environment } from 'src/environments/environment';
import { appModulesList } from 'src/environments/appModulesList';
import icAdd from '@iconify/icons-ic/twotone-add';
import icshare from '@iconify/icons-ic/twotone-share';
import icEdit from '@iconify/icons-ic/twotone-edit';
import icremove from '@iconify/icons-ic/twotone-remove';
import { LayoutService } from 'src/@vex/services/layout.service';
import { responseMessages } from 'src/app/services/core/service.constants';
// import { CourtCasesCreateUpdateComponent } from '../court-cases-create-update/court-cases-create-update.component';
// import { CasesCreateUpdateComponent } from '../cases-create-update/cases-create-update.component';
import { ViewportScroller } from '@angular/common';
import { first } from 'rxjs/operators';
import { AddcourtcaseComponent } from '../../addcourtcase/addcourtcase.component';
import { User } from '../../interfaces/court-cases.model';
import { HearingsCreateUpdateComponent } from 'src/app/pages/hearings/hearings-create-update/hearings-create-update.component';
import { AppointmentsCreateUpdateComponent } from 'src/app/pages/appointments/appointments-create-update/appointments-create-update.component';
import { aioTableLabels } from 'src/static-data/aio-table-data';
import theme from '../../../../../@vex/utils/tailwindcss';
import { ArbitrationhearingComponent } from '../../arbitrationhearing/arbitrationhearing.component';
import { ArbitrationAppointmentComponent } from '../arbitration-appointment/arbitration-appointment.component';
import { ArbitrationsharingComponent } from '../../arbitrationsharing/arbitrationsharing.component';
import { ArbitrationmeetingstatusComponent } from '../../arbitrationmeetingstatus/arbitrationmeetingstatus.component';
import { UpdatearbitrationhearingComponent } from '../../updatearbitrationhearing/updatearbitrationhearing.component';
// ../../../../@vex/utils/tailwindcss
// import { User } from '../interfaces/court-cases.model';

@Component({
  selector: 'vex-arbitration-detail',
  templateUrl: './arbitration-detail.component.html',
  styleUrls: ['./arbitration-detail.component.scss']
})
export class ArbitrationDetailComponent implements OnInit {

  public moduleDetails = appModulesList.arbitrationManagement;
  public hearingmoduleDetails = appModulesList.arbitrationHearingMangagement;
  layoutCtrl = new FormControl('fullwidth');
  private socketObject;

  permissionLevels = environment.permissionLevels;

  labels = aioTableLabels;

  theme = theme;

  icAdd = icAdd;
  icshare= icshare;
  icremove = icremove;
  icEdit = icEdit;
  icPrint = icPrint;
  icTrackChanges = icTrackChanges;

  public caseId: string;
  public caseType: string;

  public caseDetails;

  public appointments = [];
  public hearings = [];
  public linkedDoc = [];

  public citiesStatesCountriesList = {
    cities: {},
    countries: {},
    states: {}
  };

  public courtsAndCourtTypeAlias = {
    courtsAlias: {},
    courtTypesAlias: {}
  };

  public regionsProjectSiteModified = {};

  public loggedInUserDetails;
  public sharingDetails;
  public viewportScroller: ViewportScroller;

  private crumbDetail = {
    name: "Case Managment",
    url: "/app/cases-management/court-cases"
  }
  private crumbDetails = {
    name: "Case Managment",
    url: "/app/cases-management/mutual-cases"
  }

  private fragment: string;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private socketService: SocketService,
    private commonServices: CommonService,
    private _layoutService: LayoutService,
    private snackbar: MatSnackBar) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    let tmpObj = this;
    this.route.paramMap.subscribe((params) => {
      tmpObj.caseId = params.get("caseId");
      // tmpObj.caseType = params.get("caseType");
      tmpObj.refreshData(tmpObj);
    });

  }

 


  ngOnInit() {
    this.route.fragment.subscribe(fragment => { this.fragment = fragment; });



  }

  ngAfterViewInit(): void {
    try {
      document.querySelector('#' + this.fragment).scrollIntoView();
    } catch (e) { }
  }

  ngAfterContentInit() {

  }
  getInhouseUsersNames() {
    let names = "N/A";
    if (this.caseDetails && this.caseDetails.inHouseAdvocate) {
      for (let i = 0; i < this.caseDetails.inHouseAdvocate.length; i++) {
        names += this.caseDetails.inHouseAdvocate[i].firstName + " " + this.caseDetails.inHouseAdvocate[i].lastName;
        if (i != this.caseDetails.inHouseAdvocate.length - 1) {
          names += ", ";
        }
      }
    }

    return names;
  }
  refreshData(tmpObj) {
  
    if (tmpObj.socketService.isSocketConnected()) {
     
        tmpObj.socketObject.emit(environment.socketEvents.getarbitrationDetails, { _id: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.caseDetails = socketResponse.data;
            console.log(socketResponse,"socketResponse,data ")
            // tmpObj.initSharingDetails(tmpObj);

          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });

        tmpObj.socketObject.emit(environment.socketEvents.getAppointmentsForarbitration, { caseId: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.appointments = socketResponse.data;
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });

        tmpObj.socketObject.emit(environment.socketEvents.getHearingsForarbitration, { caseId: tmpObj.caseId }, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.hearings = socketResponse.data;
            console.log("hearing data ",socketResponse)
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
          }
        });

        tmpObj.socketObject.emit(environment.socketEvents.listCourtTypeAndCourtsAlias, {}, function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.courtsAndCourtTypeAlias.courtsAlias = socketResponse.data.courtsAlias;
            tmpObj.courtsAndCourtTypeAlias.courtTypesAlias = socketResponse.data.courtTypesAlias;
          } else {
            tmpObj.snackbar.open(socketResponse.message, 'Okay', {
              duration: 2000
            });
          }
        });

      


      tmpObj.socketObject.emit(environment.socketEvents.getCountiesStatesCities, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.citiesStatesCountriesList = socketResponse.finalData;
        }
      });

      tmpObj.socketObject.emit(environment.socketEvents.getModifiedApiRegionsProjects, {}, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.regionsProjectSiteModified = socketResponse.regionsProjectSitesList;
        }
      });
      tmpObj.socketObject.emit(environment.socketEvents.getLinkedDoc, { _id: tmpObj.caseId }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.linkedDoc = socketResponse.data;

        }
      });
    }
  }


  ngOnDestroy() {
  }




  downloadFile(fileData) {
    if (fileData) {
      let accessToken = this.commonServices.getAccessToken();
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('href', environment.serviceConfiguration.url + fileData.filePath.substring(1) + "?access_token=" + accessToken);
      link.setAttribute('download', fileData.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  back() {
    window.history.back();
  }

  modifiedCourtCaseName(courtCaseDetails) {
    let finalStr = "";
    if (courtCaseDetails.petitioner && courtCaseDetails.responder) {
      let petitionerStr = "";
      for (let i = 0; i < courtCaseDetails.petitioner.length; i++) {
        if (i == courtCaseDetails.petitioner.length - 1) {
          petitionerStr += courtCaseDetails.petitioner[i].name;
        } else {
          petitionerStr += courtCaseDetails.petitioner[i].name + ",";
        }
      }

      let respondentStr = "";
      for (let i = 0; i < courtCaseDetails.responder.length; i++) {
        if (i == courtCaseDetails.responder.length - 1) {
          respondentStr += courtCaseDetails.responder[i].name;
        } else {
          respondentStr += courtCaseDetails.responder[i].name + ",";
        }
      }

      finalStr = petitionerStr + " Vs " + respondentStr;
    }
    if(courtCaseDetails.case_name){
      finalStr = courtCaseDetails.case_name
    }
    return finalStr;
  }

  getPngFor(fileName) {
    let imgUrl = "assets/doc_icons/unknown.png";
    if (fileName) {
      let ext = fileName.substring(fileName.lastIndexOf(".") + 1);
      switch (ext.toLowerCase()) {
        case "doc":
        case "docx":
          imgUrl = "assets/doc_icons/doc.png";
          break;
        case "pdf":
          imgUrl = "assets/doc_icons/pdf.png";
          break;
        case "xls":
        case "xlsx":
          imgUrl = "assets/doc_icons/xls.png";
          break;
        case "ppt":
        case "pptx":
          imgUrl = "assets/doc_icons/ppt.png";
          break;
      }
    }
    return imgUrl;
  }

  print() {
    window.print();
  }


  isViewAllowed() {
    let isAllowed = false;

    if (this.caseDetails && environment.roleDetails && this.loggedInUserDetails && this.moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (this.caseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }

        } else { //If not the user who created this court case
          if (this.caseDetails.inHouseAdvocate
            && ((this.caseDetails.inHouseAdvocate.indexOf && this.caseDetails.inHouseAdvocate.indexOf(this.loggedInUserDetails._id) != -1)
              || this.caseDetails.inHouseAdvocate == this.loggedInUserDetails._id)) {
            isAllowed = true;

          } else if (environment.roleDetails['allowedAccesses']
            && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]
            && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules
            && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules["allList"]) { //if user has access to complete list
            isAllowed = true;

          } else { //if user allowed by sharing the details internally
            if (this.sharingDetails) {
              if (this.sharingDetails.permissionLevel == environment.permissionLevels.editable.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnly.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnlyWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  isFileViewAllowed() {
    let isAllowed = false;
    if (this.caseDetails && environment.roleDetails && this.loggedInUserDetails && this.moduleDetails) {
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else {
        if (this.caseDetails.createdBy == this.loggedInUserDetails._id) { //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (environment.roleDetails['allowedAccesses'] && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]) {
            subModulesKey = Object.keys(environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules);
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }

        } else if (environment.roleDetails['allowedAccesses']
          && environment.roleDetails['allowedAccesses'][this.moduleDetails.key]
          && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules
          && environment.roleDetails['allowedAccesses'][this.moduleDetails.key].subModules["allList"]) { //if user has access to complete list
          isAllowed = true;

        } else { //If not the user who created this court case
          if (this.caseDetails.inHouseAdvocate
            && ((this.caseDetails.inHouseAdvocate.indexOf && this.caseDetails.inHouseAdvocate.indexOf(this.loggedInUserDetails._id) != -1)
              || this.caseDetails.inHouseAdvocate == this.loggedInUserDetails._id)) {
            isAllowed = true;
          } else { //if user allowed by sharing the details internally

            if (this.sharingDetails) {
              if (this.sharingDetails.permissionLevel == environment.permissionLevels.editableWithFiles.key
                || this.sharingDetails.permissionLevel == environment.permissionLevels.viewOnlyWithFiles.key) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  isUserAllowedForFile(fileDetails) {
    let isAllowed = true;
    //let is_deleted= true;

    if (fileDetails.is_deleted == true) {
      isAllowed = false;
    }


    if (this.caseDetails.inHouseAdvocate
      && ((this.caseDetails.inHouseAdvocate.indexOf && this.caseDetails.inHouseAdvocate.indexOf(this.loggedInUserDetails._id) != -1)
        || this.caseDetails.inHouseAdvocate == this.loggedInUserDetails._id)) {
      isAllowed = true;
    } else if (fileDetails && this.sharingDetails && this.sharingDetails.filePermissions) {
      if (fileDetails.filePath) {
        for (let i = 0; i < this.sharingDetails.filePermissions.length; i++) {
          if (this.sharingDetails.filePermissions[i] && this.sharingDetails.filePermissions[i].filePath == fileDetails.filePath) {
            if (this.sharingDetails.filePermissions[i].permissions.view == true) {
              isAllowed = true;
            }
            break;
          }
        }
      }
    }



    return isAllowed;
  }

  getCurrentDate() {
    return new Date();
  }

  previewPdfFile(docDetails) {
    if (docDetails) {
      if (docDetails.type == "application/pdf") {
        let filePath: string = docDetails.filePath;
        filePath = filePath.replace("./", environment.serviceConfiguration.url + "/viewPDF/");
        let accessToken = this.commonServices.getAccessToken();
        window.open(filePath + "?access_token=" + accessToken,
          'Legal App PDF View',
          'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=' + screen.availWidth + ',height=' + screen.availHeight);
      } else {
        this.downloadFile(docDetails);
        this.snackbar.open("Oops! You can't preview this file.", "Okay", {
          duration: 2000
        });
      }
    }
  }

  shareWithInternalUser(heraingdetail,courtCaseDetails) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToaddhearingArbitrationCase( this.hearingmoduleDetails)) {
    if (courtCaseDetails) {
      this.dialog.open(ArbitrationsharingComponent, {
        panelClass: 'hearings-dialog-container',
        data: {
          courtCase: heraingdetail,
          userPermissions: null
        },
        disableClose: true
      }).afterClosed().subscribe((internalSharingDetails) => {
        if (internalSharingDetails) {
          internalSharingDetails.courtCaseId = heraingdetail._id;
          if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.sharearbitrationmeetingUser, internalSharingDetails, function (socketResponse) {
              if (socketResponse.status == 'OK') {
                tmpObj.snackbar.open('Successfully Shared with application user.', 'Okay', {
                  duration: 4000
                });
                tmpObj.refreshData(tmpObj);
              } else {
                tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                  duration: 4000
                });
              }
            });
          }
        }
      });
    }
  }else {
    this.snackbar.open("You are not authorize to perform this operation. ", "Okay", {
      duration: 4000
    });
  }
}
  }

  changeCaseStatus(courtCaseDetails) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToaddhearingArbitrationCase( this.hearingmoduleDetails)) {
      if (this.commonServices.isUserAllowedToEditCourtCase(courtCaseDetails, this.moduleDetails)) {
        this.dialog.open(ArbitrationmeetingstatusComponent, {
          data: courtCaseDetails,
          disableClose: true
        }).afterClosed().subscribe((user) => {
          if (user) {
            user.hearingid= courtCaseDetails._id
            if (this.socketService.isSocketConnected()) {
              let tmpObj = this;
              this.socketObject.emit(environment.socketEvents.changearbitrationChearingStatus, user, function (socketResponse) {
                if (socketResponse.status == 'OK') {
                  tmpObj.snackbar.open('Successfully Changed Hearing Status.', 'Okay', {
                    duration: 4000
                  });
                  tmpObj.refreshData(tmpObj);
                } else {
                  tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                    duration: 4000
                  });
                }
              });
            }
          }
        });
      }
    }
    else {
      this.snackbar.open("You are not authorize to perform this operation. ", "Okay", {
        duration: 4000
      });
    }
  }
  }


  letterDateFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {

        this._layoutService.showLoader("Starting File Upload");
        let uploadDoc = [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(environment.socketEvents.uploadMutualDocumentSlice, {
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            data: reader.result
          }, (socketResponse) => {
            if (socketResponse.status == responseMessages.status.ok) {
            } else {
              tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              tmpObj._layoutService.hideLoader();
            }
          });
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadMutualDocumentNext);
        tmpObj.socketObject.on(environment.socketEvents.uploadMutualDocumentNext, (data) => {
          tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
          let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
          reader.readAsArrayBuffer(slice);
        });

        tmpObj.socketObject.off(environment.socketEvents.uploadMutualDocumentEnd);
        tmpObj.socketObject.on(environment.socketEvents.uploadMutualDocumentEnd, (filePath) => {
          uploadDoc.push({
            name: fileObj.files[currentFileIndex].name,
            type: fileObj.files[currentFileIndex].type,
            size: fileObj.files[currentFileIndex].size,
            filePath: filePath
          });

          currentFileIndex++;
          if (currentFileIndex < totalNumberOfFiles) {
            fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);
          } else {
            // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


            tmpObj.socketObject.emit(environment.socketEvents.saveUploadDocument, {
              uploadDoc: uploadDoc,
              _id: tmpObj.caseId

            }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
            tmpObj._layoutService.hideLoader()


            tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
              duration: 3000,
              horizontalPosition: 'center'

            })

          }
        });
      }
    }
  }

  deleteDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id
      this.socketObject.emit(environment.socketEvents.deleteDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deletePetitionDocument(fileObj: any, index: any) {

    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletePetitionarbitrationDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }

  }


  petitionFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditArbitration(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let uploadDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadPetitionDocumentSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadPetitionDocumentEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadPetitionDocumentEnd, (filePath) => {
              uploadDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                console.log(tmpObj.caseDetails,"tmpObj.caseDetails")
                // tmpObj.caseDetails.petition_docs['claimentOrClaimDocs'].push(tmpObj.caseDetails.petition_docs.docs)
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);

                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationDocument, {
                  uploadDoc: uploadDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 1", "Okay", {
          duration: 4000
        });
      }
    }
  }

  SupportingFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let uploadDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
              uploadDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);
                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationSupportingDocument, {
                  uploadDoc: uploadDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 2", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteSupportingDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id
      this.socketObject.emit(environment.socketEvents.deleteSupportingDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  writenArgumentRespondentFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let writenArgumentRespondentDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
              writenArgumentRespondentDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savearbitartionWritenArgumentRespondentDocument, {
                  writenArgumentRespondentDoc: writenArgumentRespondentDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 3", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteSupportingDocsWrittenArgumentsDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationSupportingDocsWrittenArgumentsDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  writenArgumentChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let writenArgumentDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadWrittenArgumentDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadWrittenArgumentDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadWrittenArgumentDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadWrittenArgumentDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadWrittenArgumentDocEnd, (filePath) => {
              writenArgumentDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationwritenArgumentDocument, {
                  writenArgumentDoc: writenArgumentDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }

      } else {
        this.snackbar.open("You are not authorize to perform this operation. 4", "Okay", {
          duration: 4000
        });
      }
    }
  }

  rejoinerFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let rejoinerDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadRejoinerDocumentSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadRejoinerDocumentNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadRejoinerDocumentNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadRejoinerDocumentEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadRejoinerDocumentEnd, (filePath) => {
              rejoinerDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationRejoinerDocument, {
                  rejoinerDoc: rejoinerDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 5", "Okay", {
          duration: 4000
        });
      }
    }
  }

  surrejoinerFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let surrejoinerDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadSurrejoinerDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadSurrejoinerDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadSurrejoinerDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadSurrejoinerDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadSurrejoinerDocEnd, (filePath) => {
              surrejoinerDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationSurrejoinerDocument, {
                  surrejoinerDoc: surrejoinerDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 6", "Okay", {
          duration: 4000
        });
      }
    }
  }

  admissionDenialFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let admissionDenialDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadAdmissionDenialDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocEnd, (filePath) => {
              admissionDenialDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);

console.log(admissionDenialDoc,"admissionDenialDoc")
                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationAdmissionDenialDocument, {
                  admissionDenialDoc: admissionDenialDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 7", "Okay", {
          duration: 4000
        });
      }
    }
  }

  admissionDenialFileControlRespondentChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let admissionDenialReDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadAdmissionDenialDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadAdmissionDenialDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadAdmissionDenialDocEnd, (filePath) => {
              admissionDenialReDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);

console.log(admissionDenialReDoc,"admissionDenialReDoc")
                tmpObj.socketObject.emit(environment.socketEvents.savearbitrtionAdmissionDenialRespondentDocument, {
                  admissionDenialDoc: admissionDenialReDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation 8.", "Okay", {
          duration: 4000
        });
      }
    }
  }

  evidenceAffidavitFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let evidenceAffidaviteDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
              evidenceAffidaviteDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationEvidenceDocument, {
                  evidenceAffidaviteDoc: evidenceAffidaviteDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 9", "Okay", {
          duration: 4000
        });
      }
    }
  }

  evidenceAffidavitRespondentFileControlChangeHandler(user: User, fileObj) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {
        if (fileObj.files[0] && fileObj.files[0].name) {
          // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
          if (fileObj.files.length > 0) {

            this._layoutService.showLoader("Starting File Upload");
            let evidenceAffidaviteRespondentDoc = [];
            let reader;

            reader = new FileReader();
            let currentFileIndex = 0;
            let tmpObj = this;
            let totalNumberOfFiles = fileObj.files.length;
            let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
            let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
            reader.readAsArrayBuffer(slice);

            reader.onload = () => {
              // var arrayBuffer = reader.result;
              tmpObj.socketObject.emit(environment.socketEvents.uploadEvidenceDocSlice, {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                data: reader.result
              }, (socketResponse) => {
                if (socketResponse.status == responseMessages.status.ok) {
                } else {
                  tmpObj.snackbar.open('Oop! Unable to upload file.', 'Okay', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  tmpObj._layoutService.hideLoader();
                }
              });
            };

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocNext, (data) => {
              tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
              let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
              reader.readAsArrayBuffer(slice);
            });

            tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
            tmpObj.socketObject.on(environment.socketEvents.uploadEvidenceDocEnd, (filePath) => {
              evidenceAffidaviteRespondentDoc.push({
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath
              });

              currentFileIndex++;
              if (currentFileIndex < totalNumberOfFiles) {
                fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
                slice = fileObj.files[currentFileIndex].slice(0, 1000000);
                reader.readAsArrayBuffer(slice);
              } else {
                // tmpObj.caseDetails.get("petition_docs.docs").setValue(tmpObj.caseDetails.petition_docs.docs);


                tmpObj.socketObject.emit(environment.socketEvents.savearbitrationEvidenceRespondentDocument, {
                  evidenceAffidaviteRespondentDoc: evidenceAffidaviteRespondentDoc,
                  _id: tmpObj.caseId

                }, (socketResponse) => { tmpObj.refreshData(tmpObj) });
                tmpObj._layoutService.hideLoader()


                tmpObj.snackbar.open('Successfully uploaded file.', 'Okay', {
                  duration: 3000,
                  horizontalPosition: 'center'

                })

              }
            });
          }
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 10", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteWrittenArgumentsDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationWrittenArgumentsDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }

  }


  deleteLetterDateDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteLetterDateDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteRejoinderDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationRejoinderDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteSurrejoinederDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSurrejoinederarbitrationDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteSupportingDocsDocuments(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteSupportingarbitrationDocsDocuments, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteAdmissionDenialDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationAdmissionDenialDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }

  }


  deleteSupportingDocAdmissionDenialDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationSupportingDocAdmissionDenialDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteEvidenceAffidavitDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationEvidenceAffidavitDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  deleteSupportingDocEvidenceAffidavitDocument(fileObj: any, index: any) {

    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deletearbitrationSupportingDocEvidenceAffidavitDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }





  deleteLegalNoticeDocuments(user: User, fileObj: any, index: any) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditMutualCase(user, this.moduleDetails)) {


        if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
          let tmpObj = this;
          let _id = this.caseDetails._id

          this.socketObject.emit(environment.socketEvents.deleteLegalNotice, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          })
        }
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 11", "Okay", {
          duration: 4000
        });
      }
    }
  }

  deleteLinkedDocument(fileObj: any, index: any) {
    if (this.socketService.isSocketConnected() && confirm("Do you realy want to delete this?")) {
      let tmpObj = this;
      let _id = this.caseDetails._id

      this.socketObject.emit(environment.socketEvents.deleteLinkedDocument, { fileObject: fileObj, index: index, _id: _id }, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackbar.open('Successfully Deleted Designation.', 'Okay', {
            duration: 2000
          });
          tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 2000
          });
        }
      })
    }
  }

  openAddHearingModal() {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToaddhearingArbitrationCase( this.hearingmoduleDetails)) {
    this.dialog.open(ArbitrationhearingComponent, {
      panelClass: 'hearings-dialog-container',
      disableClose: true,
      data: {
        courtCase: this.caseDetails
      }
    }).afterClosed().subscribe((user) => {
      console.log(user,"after closed ")
      if (user) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.createarbitrationHearing, user, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Hearing.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          });
        }
      }
    });
  }else {
    this.snackbar.open("You are not authorize to perform this operation. ", "Okay", {
      duration: 4000
    });
  }
  }
  }


  openeditHearingModal(hearingdata) {
    this.dialog.open(UpdatearbitrationhearingComponent, {
      panelClass: 'hearings-dialog-container',
      disableClose: true,
      data: {
        courtCase: hearingdata
      }
    }).afterClosed().subscribe((user) => {
      console.log(user,"after closed ")
      if (user) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.updatearbitrationsHearing, user, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Updated Hearing.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          });
        }
      }
    });
  }

  openAddAppointment() {  

    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToaddhearingArbitrationCase( this.hearingmoduleDetails)) {
    this.dialog.open(ArbitrationAppointmentComponent, {
      disableClose: true,
      data: {
        courtCase: this.caseDetails
      }
    }).afterClosed().subscribe((user) => {
      if (user) {
        if (this.socketService.isSocketConnected()) {
          let tmpObj = this;
          this.socketObject.emit(environment.socketEvents.createarbitrationAppointment, user, function (socketResponse) {
            if (socketResponse.status == 'OK') {
              tmpObj.snackbar.open('Successfully Created Appointment.', 'Okay', {
                duration: 2000
              });
              tmpObj.refreshData(tmpObj);
            } else {
              tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                duration: 2000
              });
            }
          });
        }
      }
    });
  }else {
    this.snackbar.open("You are not authorize to perform this operation. ", "Okay", {
      duration: 4000
    });
  }
}
  }


  AssignedAdvocate() {

  }

  editCase(user: User) {
    if (environment.roleDetails) {
      if (this.commonServices.isUserAllowedToEditArbitration(user, this.moduleDetails)) {
      
          this.dialog.open(AddcourtcaseComponent, {
            data: this.caseDetails,
            panelClass: 'court-case-dialog-container',
            disableClose: true
          }).afterClosed().subscribe(updatedCustomer => {
            if (updatedCustomer) {
              if (this.socketService.isSocketConnected()) {
                let tmpObj = this;
                this.socketObject.emit(environment.socketEvents.updatearbitraionCase, updatedCustomer, function (socketResponse) {
                  if (socketResponse.status == 'OK') {
                    tmpObj.snackbar.open('Successfully Updated Court Case.', 'Okay', {
                      duration: 2000
                    });
                    tmpObj.refreshData(tmpObj);
                  } else {
                    tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                      duration: 2000
                    });
                  }
                });
              }
            }
          });
        
      } else {
        this.snackbar.open("You are not authorize to perform this operation. 12", "Okay", {
          duration: 4000
        });
      }
    }

  }

}
