import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { PunjabService } from '../highcourt/punjab.service';

@Component({
  selector: 'vex-copypetiondetail',
  templateUrl: './copypetiondetail.component.html',
  styleUrls: ['./copypetiondetail.component.scss']
})
export class CopypetiondetailComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CopypetiondetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private punjabService:PunjabService,
    private snackBar: MatSnackBar
  ) { }

  showData=false;
  handleMatSprnner=true
  msg;
  copyPetitionDetail;

  ngOnInit() {
    console.log(this.data)
    this.punjabService.postData('copyPetitionDetail',this.data).subscribe((res)=>{
      console.log(res)
      if(res.status==true){
        this.copyPetitionDetail = res.copyPetitionDetail
        console.log(this.copyPetitionDetail)
        this.showData=true
        this.handleMatSprnner=false
      }else{
        this.msg = res.message;
        this.handleMatSprnner=false
      }
    },(err)=>{
      this.handleMatSprnner = false;
      this.dialogRef.close();
      this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    }
    )
  }

}
