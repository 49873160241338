import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import icCloud from '@iconify/icons-ic/twotone-close';
import { CourtcaseService } from '../addcourtcase/courtcase.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { CommonService } from 'src/app/services/core/common.service';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material';
import { DistrictService } from './district.service';
import { MatDialog } from '@angular/material/dialog';
import { DistrictcourtdetailComponent } from './districtcourtdetail/districtcourtdetail.component';
import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'vex-districtcourt',
  templateUrl: './districtcourt.component.html',
  styleUrls: ['./districtcourt.component.scss']
})
export class DistrictcourtComponent implements OnInit {
  private socketObject;
  icCloud= icCloud;
  state: any;
  selectedvalue: any;
  distselectedvalue:any;
  fourmselectedvalue:any
  districtlist: any;
  fourmlist: any;
  districtcode: any;
  yeardata: any;
  casenumbermsg='';
  partymsg='';
  casetype: any;
  courtcaselist: any;
  loading = false;
  partylist: any;
  partylistdata=[];
  partydata: any[];
  public totalRecords = 0;
  public totalpages= 0;
  Records =0;
  pageIndex = 0;
  pageSize = environment.pagination.defaultPageSize;
  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;


  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  constructor(private court :CourtcaseService,private districtcourt: DistrictService,private router: Router, private socketService: SocketService,private snackBar: MatSnackBar, private commonServices: CommonService,private dialog: MatDialog) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
   }

  //  {
    // "case_no": "10",
    // "state_code": "26",
    // "district_code": "10",
    // "forum_id": "2",
    // "case_year": "2022",
    // "case_type_category_id": "76",
    // "saved_by": "1"
// }


  mydistrictForm = new FormGroup({
    court_status: new FormControl(''),
    state_code: new FormControl('',Validators.required),
    district_code: new FormControl('',Validators.required),
    forum_id: new FormControl('',Validators.required),
    case_type_category_id: new FormControl('',Validators.required),
    case_no: new FormControl('',Validators.required),
    case_year: new FormControl('',Validators.required),
    saved_by: new FormControl('1',Validators.required),
    
  });

  mypartyForm  = new FormGroup({
    court_status: new FormControl(''),
    state_code: new FormControl('',Validators.required),
    district_code: new FormControl('',Validators.required),
    forum_id: new FormControl('1',Validators.required),
   
    search_keyword: new FormControl('',Validators.required),
    case_type_category_id: new FormControl('',Validators.required),
    case_year: new FormControl('',Validators.required),
    saved_by: new FormControl('2',Validators.required),
   
    
  });


  // {
    // "search_keyword": "HERO FINCORP",
    // "state_code": "26",
    // "district_code": "10",
    // "forum_id": "1",
    // "case_year": "2022",
    // "case_type_category_id": "Pending",
    // "saved_by": "2"
// }


  ngOnInit() {

    this.getstate()
    this.getyear();

    this.mypartyForm.get('case_type_category_id').setValue("Pending");
    // this.mypartyForm.get('case_status').setValue("P");
  }


getstate(){
  let obj ={

  }
  this.districtcourt.postecourt('ws/eGetCourtState',obj).subscribe((res2: any) => {
  console.log(res2,"res2 of state")

  this.state = res2.courtState
  })
}


savesparty(data){
  
   
  
 
  data._value.forEach(element => {
    let obj ={
      "state_code": element.state_code,
      "district_code": element.district_code,
"forum_id": element.forum_id,
"case_no_year": element.case_no_year,
"case_cino": element.case_cino
    }
    console.log(obj,"check respon")
    this.districtcourt.postecourt('ws/eCourtCase',obj).subscribe((res: any) => {
      console.log(res)
      if(res)
   { this.districtcourt.postecourt('ws/eAutoUpdateCourtCase',obj).subscribe((res2: any) => {
      console.log(res2,"check response")
      if(res2.status){
        let detaildata
        if(res2.autoUpdateCourtCase){
          detaildata = res2.autoUpdateCourtCase[0]
        }
       
        this.savepartdetail(detaildata);
      }
    })}
  })
  });
  // this.shoess.nativeElement.value
}

savepartdetail(data){
  console.log(data,"data of party")
  if (this.socketService.isSocketConnected()) {
    let tmpObj = this;
    this.socketObject.emit(environment.socketEvents.createDistrictCourtCase, data, function (socketResponse) {
      if (socketResponse.status == 'OK') {
        tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
          duration: 4000
        });
        // tmpObj.refreshData(tmpObj);
      } else {
        tmpObj.snackBar.open(socketResponse.message, 'Okay', {
          duration: 4000
        });
      }
    });
  }

}


getyear(){
  
  // this.myForm.value
  this.court.postyear('ws/caseYears').subscribe((res2: any) => {
    this.yeardata = res2.caseYear

    this.mypartyForm.get('case_year').setValue(this.yeardata[0]['year']);
    this.mydistrictForm.get('case_year').setValue(this.yeardata[0]['year'])
  })
  // ws/courtCategories

  
}


state_code:any
state_code_new:any
getvalue(data){
  console.log(data ,"after change ")

  this.state_code=data.id
  console.log(this.state_code,'this.state_code')
  this.state_code_new=data.id
  // if(data.status==1){
  //   this.state_code=data.state_code_new
  // }else if(data.status==0){
  //   this.state_code=data.state_code
  // }

  let obj ={

    "state_code":data.id
  }

  this.districtcourt.postecourt('ws/eGetCourtStatesDistrict',obj).subscribe((res2: any) => {
    console.log(res2,"res2 of state")
  
     this.districtlist = res2.courtStateDistricts
    })
}
 
districtStatus:any
getvaluedistrict(data){
  
  console.log(data,"after select data of district")

  if(data.status==1){
    this.districtStatus=data.status
    this.districtcode = data.id
  
    let obj ={
  // "district_court_id":"107"
      "district_court_id":data.id
    }
  
  
    this.districtcourt.postecourt('ws/eGetDistrictCourtComplexNew',obj).subscribe((res2: any) => {
      console.log(res2,"res2 of district")
    
        this.fourmlist = res2.caseDistrictCourtComplex
  
      })
  }else if(data.status==0){
    this.districtStatus=data.status
    this.districtcode = data.id
  
    let obj ={
  // "district_court_id":"107"
      "district_court_id":data.id
    }
  
  
    this.districtcourt.postecourt('ws/eGetDistrictCourtComplex',obj).subscribe((res2: any) => {
      console.log(res2,"res2 of district")
    
        this.fourmlist = res2.caseDistrictCourtComplex
  
      })
  }

}


getcasetype(data){
console.log(data,"this part is runninr")


 if(this.districtStatus==0){
  this.fourmselectedvalue = data
  const myid = data.split("|");
    let obj ={
       "district_court_complex_id":myid[0]
          // "district_court_id":data.id
        }
      
      
        this.districtcourt.postecourt('ws/eGetCourtCaseTypeCategory',obj).subscribe((res2: any) => {
          console.log(res2,"res2 of case type")
        
             this.casetype = res2.courtCaseTypeCategory
  
          })
 }else if(this.districtStatus==1){
  this.fourmselectedvalue = data
  const myid = data.split("|");
    let obj ={
       "district_court_complex_id":myid[0]
          // "district_court_id":data.id
        }
      
      
        this.districtcourt.postecourt('ws/eGetCourtCaseTypeCategoryNew',obj).subscribe((res2: any) => {
          console.log(res2,"res2 of case type")
        
             this.casetype = res2.courtCaseTypeCategory
  
          })
 }
}


pageChangeHandler(eventDetails) {
  console.log(eventDetails.pageIndex,'eventDetails')
  if (eventDetails && eventDetails.pageSize) {
     this.pageSize = eventDetails.pageSize;
     this.pageIndex = eventDetails.pageIndex;
  }
  this.refreshData(this);
}


getcasepartydetail(){

  if(this.districtStatus==0){
    this.partymsg = ''
    const complexvalue = this.fourmselectedvalue.split("|");
    this.mypartyForm.controls['court_status'].setValue('0');
    this.mypartyForm.controls['state_code'].setValue(this.state_code);
    this.mypartyForm.controls['district_code'].setValue(this.districtcode);
    this.mypartyForm.controls['forum_id'].setValue(complexvalue[1]);
    console.log(this.mypartyForm.value,"this.mypartyForm.value")
  
    if(this.mypartyForm.valid)
    {
     this.loading = true; 
     this.districtcourt.postecourt('ws/eCourtSearchParty',this.mypartyForm.value).subscribe((res2: any) => {
       console.log(res2,"res2 of court case")
       this.loading = false; 
         
  if(res2.partyName)
       {  
        this.partylist = res2.partyName
         if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.createdistrictTemproryCourtCase, tmpObj.partylist, function (socketResponse) {
  
              console.log(socketResponse,"socketResponse")
              tmpObj.refreshData(tmpObj)
          // listTemproryDistrictCourtCase
          // tmpObj.refreshData(tmpObj)
            });
          }}else{
            this.partydata = []
            this.partymsg = res2.message
          }
       
       
  
  
  
       })}else{
         // this.msg =  ""
         this.snackBar.open('Please provide all required information.', 'CLOSE', {
           duration: 3000,
           horizontalPosition: 'center'
         });
       
       }
  }else if(this.districtStatus==1){
    this.partymsg = ''
    const complexvalue = this.fourmselectedvalue.split("|");
  console.log(this.districtcode,"this.districtcode")
  this.mypartyForm.controls['court_status'].setValue('1');
    this.mypartyForm.controls['state_code'].setValue(this.state_code_new);
    this.mypartyForm.controls['district_code'].setValue(this.districtcode);
    this.mypartyForm.controls['forum_id'].setValue(complexvalue[1]);
    console.log(this.mypartyForm.value,"this.mypartyForm.value")
  
    if(this.mypartyForm.valid)
    {
     this.loading = true; 
     this.districtcourt.postecourt('ws/eCourtSearchParty',this.mypartyForm.value).subscribe((res2: any) => {
       console.log(res2,"res2 of court case")
       this.loading = false; 
         
  if(res2.partyName)
       {  
        this.partylist = res2.partyName
         if (this.socketService.isSocketConnected()) {
            let tmpObj = this;
            this.socketObject.emit(environment.socketEvents.createdistrictTemproryCourtCase, tmpObj.partylist, function (socketResponse) {
  
              console.log(socketResponse,"socketResponse")
              tmpObj.refreshData(tmpObj)
          // listTemproryDistrictCourtCase
          // tmpObj.refreshData(tmpObj)
            });
          }}else{
            this.partydata = []
            this.partymsg = res2.message
          }
       
       
  
  
  
       })}else{
         // this.msg =  ""
         this.snackBar.open('Please provide all required information.', 'CLOSE', {
           duration: 3000,
           horizontalPosition: 'center'
         });
       
       }
  }



}


refreshData(tmpObj) {
  console.log(tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:0,"tmpObj.paginator?tmpObj.paginator.pageIndex * tmpObj.pageSize:0")
  // console.log(this.paginator,"this.paginator")
  if (tmpObj.socketService.isSocketConnected()) {
    tmpObj.socketObject.emit(environment.socketEvents.listTemproryDistrictCourtCase,{  pageSize: tmpObj.pageSize,
      skip: tmpObj.pageIndex?tmpObj.pageIndex * tmpObj.pageSize:0

      //  skip: 0
    
    },
    
      function (socketResponse) {
        console.log(socketResponse,"by api ")
        if (socketResponse.status == 'OK') {
          tmpObj.partylistdata= socketResponse.data
          // console.log(this.caselistdata,"this party list 1 ")
          tmpObj.totalRecords = socketResponse.totalRecords;
         
          this.partylistdata = tmpObj.partylistdata
        
         this.totalRecords = tmpObj.totalRecords
          console.log(this.partylistdata,"this party list 1 ")
          tmpObj.getparty()
        


        } else {
          tmpObj.snackbar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
  }

  
}

getcasedetail(){
  if(this.districtStatus==0){
    this.casenumbermsg = ''
    const complexvalue = this.fourmselectedvalue.split("|");
  //   let obj ={
  //     "search_keyword": "sbi",
  //     "state_code": "26",
  //     "district_code": "10",
  //     "forum_id": "2",
  //     "case_year": "2022",
  //     "case_type_category_id": "Pending",
  //     "saved_by": "2",
  // }
        this.mydistrictForm.controls['court_status'].setValue('0');
        this.mydistrictForm.controls['state_code'].setValue(this.state_code);
        this.mydistrictForm.controls['district_code'].setValue(this.districtcode);
        this.mydistrictForm.controls['forum_id'].setValue(complexvalue[1]);
       
  
      
        console.log("get form value",this.mydistrictForm.value)
        if(this.mydistrictForm.valid)
       {
        this.loading = true; 
        this.districtcourt.postecourt('ws/eCourtSearchParty',this.mydistrictForm.value).subscribe((res2: any) => {
          console.log(res2,"res2 of court case")
          this.loading = false; 
        if(res2&&res2.partyName){
          this.courtcaselist = res2.partyName
        }else{
          this.courtcaselist=[]
          this.casenumbermsg= res2.message
  
        }
             
  
  //           if(courtcaselist){
  //             let obj ={
  //               "state_code": courtcaselist.state_code,
  //               "district_code": courtcaselist.district_code,
  //     "forum_id": courtcaselist.forum_id,
  //     "case_no_year": courtcaselist.case_no_year,
  //     "case_cino": courtcaselist.case_cino
  //             }
  //             this.districtcourt.postecourt('ws/eAutoUpdateCourtCase',obj).subscribe((res2: any) => {
  
  // this.courtcaselist = res2.autoUpdateCourtCase[0]
  
  
  
  //             })
  
  
  //           }
          
           
          })}else{
            // this.msg =  ""
            this.snackBar.open('Please provide all required information.', 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center'
            });
          
          }
  }else if(this.districtStatus==1){
    this.casenumbermsg = ''
    const complexvalue = this.fourmselectedvalue.split("|");
  //   let obj ={
  //     "search_keyword": "sbi",
  //     "state_code": "26",
  //     "district_code": "10",
  //     "forum_id": "2",
  //     "case_year": "2022",
  //     "case_type_category_id": "Pending",
  //     "saved_by": "2"
  // }
        this.mydistrictForm.controls['court_status'].setValue('1');
        this.mydistrictForm.controls['state_code'].setValue(this.state_code_new);
        this.mydistrictForm.controls['district_code'].setValue(this.districtcode);
        this.mydistrictForm.controls['forum_id'].setValue(complexvalue[1]);
       
  
      
        console.log("get form value",this.mydistrictForm.value)
        if(this.mydistrictForm.valid)
       {
        this.loading = true; 
        this.districtcourt.postecourt('ws/eCourtSearchParty',this.mydistrictForm.value).subscribe((res2: any) => {
          console.log(res2,"res2 of court case")
          this.loading = false; 
        if(res2&&res2.partyName){
          this.courtcaselist = res2.partyName
        }else{
          this.courtcaselist=[]
          this.casenumbermsg= res2.message
  
        }
             
  
  //           if(courtcaselist){
  //             let obj ={
  //               "state_code": courtcaselist.state_code,
  //               "district_code": courtcaselist.district_code,
  //     "forum_id": courtcaselist.forum_id,
  //     "case_no_year": courtcaselist.case_no_year,
  //     "case_cino": courtcaselist.case_cino
  //             }
  //             this.districtcourt.postecourt('ws/eAutoUpdateCourtCase',obj).subscribe((res2: any) => {
  
  // this.courtcaselist = res2.autoUpdateCourtCase[0]
  
  
  
  //             })
  
  
  //           }
          
           
          })}else{
            // this.msg =  ""
            this.snackBar.open('Please provide all required information.', 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center'
            });
          
          }
  }

}


getparty(){
  this.partydata = this.partylistdata
  this.Records = this.totalRecords
  let record = this.totalRecords/10
  let secrecord = (record+ "").split(".")[1]
 
if(Number(secrecord)){

  this.totalpages = parseInt((this.totalRecords/10)+"")
  this.totalpages = Number(this.totalpages)+1

}else{
  this.totalpages = (this.totalRecords/10)
}
}

// "state_code": "26",
// "district_code": "10",
// "forum_id": "2",
// "case_no_year": "207600000102022",
// "case_cino": "DLST020000562022"

casedetailbyid(data){
  console.log(data,"data")
  data['court_status']=this.districtStatus
  data['state_code2']=this.state_code
  data['district_code2']=this.districtcode

  console.log(data)

  this.dialog.open(DistrictcourtdetailComponent,{
    height: '500px',
    width: '800px',
    autoFocus: false,
    data:data
    });


 

}

savecase(){
  console.log(this.courtcaselist,"this.columns")
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.courtcaselist.fourm = "districtcourt"
      console.log(this.courtcaselist,"this.columns 9999")
      this.socketObject.emit(environment.socketEvents.createDistrictCourtCase, this.courtcaselist, function (socketResponse) {
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created Court Case.', 'Okay', {
            duration: 4000
          });

        

          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
        }
      });
    }
}
back() {
  window.history.back();
}

//   this.socketObject.emit(environment.socketEvents.createHearing, this.courtcaselist, function (socketResponse) {

// })
}
