import { Component, Inject, OnInit } from "@angular/core";
import { MatSnackBar, MAT_DIALOG_DATA, MatDialog, throwMatDialogContentAlreadyAttachedError } from "@angular/material";
import { ActivatedRoute } from "@angular/router";
import { CommonService } from "src/app/services/core/common.service";
import { SocketService } from "src/app/services/core/socket.service";
import { environment } from "src/environments/environment";
// import { CourtCasesService } from '../../addcase/court-cases.service';
// import { DistrictService } from '../../districtcourt/districtcourt.service';
import icEdit from "@iconify/icons-ic/twotone-edit";
import icPrint from "@iconify/icons-ic/twotone-print";
import { HighcourtService } from "../highcourt/highcourt.service";
import { DistrictService } from "../districtcourt/district.service";
import { DelhihighcourtService } from "../highcourt/delhihighcourt.service";
// import { HighcourtService } from '../highcourt.service';
// import { CourtcaseService } from '../../addcourtcase/courtcase.service';
// import { DistrictService } from '../../districtcourt/district.service';
// import { HighcourthistroydetailComponent } from '../highcourthistroydetail/highcourthistroydetail.component';
@Component({
  selector: "vex-delhihighcourt",
  templateUrl: "./delhihighcourt.component.html",
  styleUrls: ["./delhihighcourt.component.scss"],
})
export class DelhihighcourtComponent implements OnInit {
  private socketObject;
  caseid = "";
  casedetails: any;
  casestatusdetails: any;
  casepetionordetails: any;
  caseresponedentdetails: any;
  caseactdetails: any;
  casehistorydetails: any;
  caseordersdetails: any;
  orderlist = true;
  icEdit = icEdit;
  icPrint = icPrint;
  datadetail: any;
  iadetail: any;
  caseConversionInfo: any;
  caseMainMatterInfo: any;
  caseSubMatterInfo: any;
  caseLinkedInfo: any;
  caseObjectionInfo: any;
  caseDocumentInfo: any;
  caseFIRInfo: any;
  caseCategoryInfo: any;
  caseActsInfo: any;
  courtcaselist: any;
  title: any;
  detaildorderata: any;
  petitioner: any;
  respondent: any;
  msg: any;

  constructor(
    private route: ActivatedRoute,
    private highcourt: HighcourtService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private districtcourt: DistrictService,
    private socketService: SocketService,
    private commonServices: CommonService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private delhihighcourtservice: DelhihighcourtService
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  caseListData;
  handleMatSprnner=true;
  handledata=false
  ngOnInit() {
    this.caseListData = this.data;
    this.courtCase();
  }

  courtCasesData;
  courtCase(){

    let obj = {
      pno: this.caseListData.pno_no
    };

    console.log(obj)

    this.delhihighcourtservice.postecourt("courtCases",obj).subscribe((res)=>{

      console.log(res)
      if(res['status']==true){
        this.courtCasesData = res['partyName'][0]
        console.log(this.courtCasesData)
        this.caseOrders();
      }else{
        this.caseOrders();
      }
    },(err)=>{
      console.log(err)
      this.handleMatSprnner=false;
      this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    })

  }
  
  caseOrderData;
  caseOrders(){
    console.log(this.courtCasesData)
    let obj = {
      court_case_id: this.courtCasesData.id
    };

    this.delhihighcourtservice.postecourt("caseOrders",obj).subscribe((res)=>{

      if(res['status']==true){
        this.caseOrderData = res['partyName']
        this.caseDetailOrders();
      }else{
        this.caseDetailOrders();
      }
    },(err)=>{
      console.log(err)
      this.handleMatSprnner=false;
      this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    })
  }

  caseDetailOrdersData;
  caseDetailOrders(){
    let obj = {
      court_case_id: this.courtCasesData.id
    };
    console.log(obj)

    this.delhihighcourtservice.postecourt("caseDetailOrders",obj).subscribe((res)=>{

      if(res['status']==true){
        this.caseDetailOrdersData = res['partyName']
        console.log(this.caseDetailOrdersData)
        this.handledata=true
        this.handleMatSprnner=false;
      }else{
        this.handledata=true
        this.handleMatSprnner=false;
      }
    },(err)=>{
      console.log(err)
      this.handleMatSprnner=false;
      this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
        duration: 3000,
        horizontalPosition: 'center'
      });
    })
  }

  caesDetailData={}
  saveData(){
    this.caesDetailData['serverType']=this.data.serverType
    this.caesDetailData['state_code']=this.data.state_code
    this.caesDetailData['caseName']=this.data.caseName
    this.caesDetailData['case_year']=this.data.case_year

    this.caesDetailData['case_detail']=this.caseListData.case_detail;
    this.caesDetailData['case_name']=this.caseListData.case_name;
    this.caesDetailData['pno_no']=this.caseListData.pno_no;
    this.caesDetailData['next_hearing_date']=this.caseListData.next_hearing_date;
    this.caesDetailData['case_status']=this.caseListData.case_status;
    this.caesDetailData['listing_date_and_court_no']=this.caseListData.listing_date_and_court_no;
    this.caesDetailData['case_type']=this.caseListData.case_type;
    this.caesDetailData['case_no']=this.caseListData.case_no;
    this.caesDetailData['petitioner']=this.caseListData.petitioner;
    this.caesDetailData['respondent']=this.caseListData.respondent;


    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      this.socketObject.emit(environment.socketEvents.createdelhihighcourt,  this.caesDetailData , function (socketResponse) {
        console.log(socketResponse)
        if (socketResponse.status == 'OK') {
          tmpObj.snackBar.open('Successfully Created high court cases', 'Okay', {
            duration: 4000
          });
          // tmpObj.refreshData(tmpObj);
        } else {
          tmpObj.snackBar.open(socketResponse.message, 'Okay', {
            duration: 4000
          });
          // this.dialogRef.close();
        }
      });
      // this.dialogRef.close();
    }
  }

  // gotoorderdetail() {
  //   let obj = {
  //     court_case_id: "5",
  //   };

  //   this.delhihighcourtservice
  //     .postecourt("caseDetailOrders", obj)
  //     .subscribe((res2: any) => {
  //       console.log(res2, "obj");
  //       if (res2.status) {
  //         this.orderlist = false;
  //         this.detaildorderata = res2.partyName;
  //       }
  //     });
  // }

  gotoback() {
    this.orderlist = true;
  }

  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert("No data Found");
    }
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
