import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'vex-arbitration-crete',
  templateUrl: './arbitration-crete.component.html',
  styleUrls: ['./arbitration-crete.component.scss']
})
export class ArbitrationCreteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
