import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { environment } from "src/environments/environment";
import { ConsumerCourtService } from "../consumer-court/consumer-court.service";
import { MatSnackBar } from '@angular/material';
import { SocketService } from "src/app/services/core/socket.service";
import { CommonService } from "src/app/services/core/common.service";

@Component({
  selector: "vex-consumer-court-detail",
  templateUrl: "./consumer-court-detail.component.html",
  styleUrls: ["./consumer-court-detail.component.scss"],
})
export class ConsumerCourtDetailComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ConsumerCourtDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private consumerService: ConsumerCourtService,private snackBar: MatSnackBar,
    private socketService: SocketService,private commonServices: CommonService
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  private socketObject;

  handleMatSprnner = true;
  msg;
  showData=false
  showServerErrOfcaseHistoryOrders=''

  NcdrcDetail;
  caseHistoryOrdersData=[];
  ngOnInit() {
    this.NcdrcDetail = this.data;
    console.log(this.NcdrcDetail)

    let obj = {
      id: this.NcdrcDetail.id,
    };

    this.consumerService.postData('caseHistoryOrders', obj).subscribe(
      (res) => {
        if(res.status==true){

          for(let data of res.caseHistoryOrders){
            if(data.judgement_url != null){
              let orderData = data.order.split(',')
              data['value1'] = orderData[0]
              data['value2'] = orderData[1]
            }
          }

          this.caseHistoryOrdersData.push(...res.caseHistoryOrders);
          this.handleMatSprnner = false;
          this.showData=true
        }else{
          this.msg = res.message
          this.handleMatSprnner = false
          this.showData=true
        }
      },
      (err) => {
        this.handleMatSprnner = false;
        this.showData=true
        this.showServerErrOfcaseHistoryOrders = 'Facing Some Server Issue in caseHistoryOrders Please Try After Some Time'
      }
    );
  }


  caesDetailData={}
  saveData(){
      this.caesDetailData['case_no']=this.NcdrcDetail.case_no;
      this.caesDetailData['state_code']=this.data.state_code;
      this.caesDetailData['district']=this.data.district;
      this.caesDetailData['case_year']=this.data.case_year;
      this.caesDetailData['commission_type']=this.NcdrcDetail.commission_type;
      this.caesDetailData['case_stage']=this.NcdrcDetail.case_stage;
      this.caesDetailData['id']=this.NcdrcDetail.id;

      this.caesDetailData['filing_date']=this.NcdrcDetail.filing_date,
      this.caesDetailData['filed_in']=this.NcdrcDetail.filed_in,
      this.caesDetailData['next_hearing']=this.NcdrcDetail.next_hearing,
      this.caesDetailData['attached_or_lower_court_cases']=this.NcdrcDetail.attached_or_lower_court_cases,
      this.caesDetailData['applications_filed']=this.NcdrcDetail.applications_filed,
      this.caesDetailData['date_of_destruction']=this.NcdrcDetail.date_of_destruction,
      this.caesDetailData['rbt_details']=this.NcdrcDetail.rbt_details,
      this.caesDetailData['higher_commission_case']=this.NcdrcDetail.higher_commission_case
      this.caesDetailData['petitioner']=this.NcdrcDetail.complainant
      this.caesDetailData['respondent']=this.NcdrcDetail.respondent
      this.caesDetailData['caseName']=this.data.caseName

  
      if (this.socketService.isSocketConnected()) {
        console.log('socket is connected now...')
        let tmpObj = this;
        this.socketObject.emit(environment.socketEvents.createconsumercourt,  this.caesDetailData , function (socketResponse) {
          console.log(socketResponse)
          if (socketResponse.status == 'OK') {
            tmpObj.snackBar.open('Successfully Created Consumer court cases', 'Okay', {
              duration: 4000
            });
            // tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackBar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
            // this.dialogRef.close();
          }
        });
        // this.dialogRef.close();
    }
  }


  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  goToLink(url: string){
    if(url!=null){
      window.open(url, "_blank");
    }else{
      alert('No data Found')
    }
  }
}
