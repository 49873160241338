import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HighcourtService } from '../highcourt.service';

@Component({
  selector: 'vex-highcourthistroydetail',
  templateUrl: './highcourthistroydetail.component.html',
  styleUrls: ['./highcourthistroydetail.component.scss']
})
export class HighcourthistroydetailComponent implements OnInit {
  datadetail: any;
  historydata: any;

  constructor(@Inject(MAT_DIALOG_DATA) data,private highcourt: HighcourtService,) { 
    if(data){
      this.datadetail = data
      console.log(this.datadetail,"this.datadetail")
      // this.caseid=data.caseId;
      // court_case_id
      // id
     }
  }

  ngOnInit() {

    let obj={
      // this.datadetail.court_case_id
      // this.datadetail.id
      "court_case_id": this.datadetail.court_case_id,
      "case_history_id": this.datadetail.id
  
    }
    console.log(obj,"obj")

    this.highcourt.postecourt('ws/eHighCaseHistoryDetail',obj).subscribe((res2: any) => {

this.historydata = res2.caseHistoryDetailsInfo
console.log(this.historydata,"res2")

    })
  }

}
