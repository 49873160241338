import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators, FormArray, FormControl, } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, } from "@angular/material/dialog";
import { User } from "../interfaces/court-cases.model";
import icMoreVert from "@iconify/icons-ic/twotone-more-vert";
import icClose from "@iconify/icons-ic/twotone-close";
import icadd from "@iconify/icons-ic/add";

import icPrint from "@iconify/icons-ic/twotone-print";
import icDownload from "@iconify/icons-ic/twotone-cloud-download";
import icDeleteForever from "@iconify/icons-ic/twotone-delete-forever";
import icDescription from "@iconify/icons-ic/twotone-description";
import icDelete from "@iconify/icons-ic/twotone-delete";
import icPerson from "@iconify/icons-ic/twotone-person";
import icSecurity from "@iconify/icons-ic/twotone-security";
import icEmail from "@iconify/icons-ic/twotone-email";
import search_icon from "@iconify/icons-ic/search";
import icPlus from "@iconify/icons-ic/twotone-plus";
import icconfirmation_number from "@iconify/icons-ic/twotone-confirmation-number";
import icPageView from "@iconify/icons-ic/twotone-pageview";
import icEditLocation from "@iconify/icons-ic/twotone-edit-location";
import icnote_add from "@iconify/icons-ic/twotone-note-add";
import icaccount_balance from "@iconify/icons-ic/twotone-account-balance";
import iclocation_city from "@iconify/icons-ic/twotone-location-city";
import icgavel from "@iconify/icons-ic/twotone-gavel";
import icassignment_ind from "@iconify/icons-ic/twotone-assignment-ind";
import icphone from "@iconify/icons-ic/twotone-phone";
import icperson_pin from "@iconify/icons-ic/twotone-person-pin";
import icplace from "@iconify/icons-ic/twotone-place";
import icmoney from "@iconify/icons-ic/twotone-money";
import iccalendar_today from "@iconify/icons-ic/twotone-calendar-today";
import auto from "@iconify/icons-ic/round-autorenew";
import icdetails from "@iconify/icons-ic/twotone-details";
import icscatter_plot from "@iconify/icons-ic/twotone-scatter-plot";
import iccategory from "@iconify/icons-ic/twotone-category";
import icdonut_small from "@iconify/icons-ic/twotone-donut-small";

import { MatSnackBar, MatTabChangeEvent } from "@angular/material";
import { fadeInUp400ms } from "src/@vex/animations/fade-in-up.animation";
import { CommonService } from "src/app/services/core/common.service";
import { SocketService } from "src/app/services/core/socket.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { LayoutService } from "src/@vex/services/layout.service";
import { responseMessages } from "src/app/services/core/service.constants";
import { appModulesList } from "src/environments/appModulesList";
import { map, startWith } from "rxjs/operators";
import { CustomValidators } from "src/app/custom_validator/validator";
import { CourtcaseService } from "../addcourtcase/courtcase.service";
import { HighcourtService } from "../highcourt/highcourt.service";
import { DistrictService } from "../districtcourt/district.service";
import { AdvocateFirmCreateUpdateComponent } from "../../advocate-firm/advocate-firm-create-update/advocate-firm-create-update.component";
import { TribunalService } from "../tribunal-court/tribunal.service";
import { DelhihighcourtService } from "../highcourt/delhihighcourt.service";
import { AllahabadhighcourtService } from "../highcourt/allahabadhighcourt.service";
import { BombayhighcourtService } from "../highcourt/bombayhighcourt.service";
import { TribunalCaseHistoryDetailComponent } from "../tribunal-case-history-detail/tribunal-case-history-detail.component";
import { ConsumerCourtService } from "../consumer-court/consumer-court.service";
import { HimachalhighcourtService } from "../highcourt/himachalhighcourt.service";
import { PunjabService } from "../highcourt/punjab.service";
import { NcltCourtService } from "../nclt-court/nclt-court.service";

@Component({
  selector: "vex-court-cases-create-update",
  templateUrl: "./court-cases-create-update.component.html",
  // encapsulation: ViewEncapsulation.None,
  styleUrls: [
    "./court-cases-create-update.component.scss",
    "../../../../../node_modules/quill/dist/quill.snow.css",
    "../../../../@vex/styles/partials/plugins/_quill.scss",
  ],
  animations: [fadeInUp400ms],
  providers: [CustomValidators],
})
export class CourtCasesCreateUpdateComponent implements OnInit {
  public moduleDetails = appModulesList.courtCaseManagement;
  public moduleadvocateDetails = appModulesList.advocateFirmManagement;
  selectedFileName;
  private socketObject;
  loading = false;

  public selectedTabIndex: number = 0;
  private selectedDocIndex = -1;
  public frmOrderDate: FormGroup;
  public orderDateDiaglogRef;
  public selectedValue: any;
  countOfTab = 9
  customcourt: boolean = false;
  toggleCourtType: boolean = true;
  customcategorydata: any;
  private totalTabGroups: number = 9;

  @ViewChild("orderDocDateTemplateRef", { static: false })
  orderDocDateTemplateRef;

  public isFirmSelected$ = new BehaviorSubject<boolean>(false);

  serverlist = [];
  public subAdvocatesList = [];
  public retainerList = [];
  public caseTypeList = [];
  public courttpelist = [];
  public divisionsList = [];
  public designationsList = [];
  public allProjectSitesList = [];
  public projectSitesList = [];
  public regionsList = [];
  public courtsList = [];

  public courtTypesList = [];
  public courtTypesListforCustomCourt = [];
  public courtTypesAliasList = {};

  public advocatesList = [];
  public legalNoticesList = [];
  public judgementTypeList = [];
  public inHouseUsersList = [];
  public statelist = [];
  public yearOptions = [];
  public departmentsList = [];
  public categorydata = [];

  public tmpInHouseUsersList = [];

  public countriesList = [];
  public indiaStateList = [];
  public petitionerStatesList = [[]];
  public petitionerCitiesList = [[]];
  public courtCitiesList = [[]];
  public responderStatesList = [[]];
  public responderCitiesList = [[]];

  public petitionerName = "Petitioner";
  public responderName = "Respondent";

  isCaseNatureCriminal = false;

  public billingTypeOptions = [
    { name: "Monthly", value: "Monthly" },
    { name: "Lumpsum", value: "Lumpsum" },
    { name: "Hearing", value: "Hearing" },
  ];

  form: FormGroup;
  mode: "create" | "update" = "create";

  icMoreVert = icMoreVert;
  icClose = icClose;
  icnote_add = icnote_add;

  icPrint = icPrint;
  icadd = icadd;
  icDownload = icDownload;
  icDeleteForever = icDeleteForever;
  icDescription = icDescription;
  icDelete = icDelete;
  icEmail = icEmail;
  search_icon = search_icon;
  icSecurity = icSecurity;
  icPlus = icPlus;
  icPageView = icPageView;
  icconfirmation_number = icconfirmation_number;
  icaccount_balance = icaccount_balance;
  iclocation_city = iclocation_city;
  icgavel = icgavel;
  icassignment_ind = icassignment_ind;
  icphone = icphone;
  icperson_pin = icperson_pin;
  icplace = icplace;
  icmoney = icmoney;
  iccalendar_today = iccalendar_today;
  auto = auto;
  icdetails = icdetails;
  icscatter_plot = icscatter_plot;
  iccategory = iccategory;
  icPerson = icPerson;
  icEditLocation = icEditLocation;
  icdonut_small = icdonut_small;

  // zxvc
  casedetails: any;
  casestatusdetails = []
  casepetionordetails = []
  caseresponedentdetails = []
  caseactdetails = []
  casehistorydetails = []
  caseordersdetails = []
  datadetail: any;
  iadetail = []
  caseConversionInfo = []
  caseMainMatterInfo = []
  caseSubMatterInfo = []
  caseLinkedInfo = []
  caseObjectionInfo = []
  caseDocumentInfo = []
  caseFIRInfo = []
  caseCategoryInfo = []
  caseActsInfo = []
  courtcaselist = []
  title: any;

  judgementdetails = []
  earlierCourtDetail = []
  caseListingDates = []
  officereportdetails = []
  caseIndexingInfo = []
  caseAppearanceInfo = []
  courtDropNoteInfo = []
  courtMentionInfo = []
  courtRestorationInfo = []
  caseInterlocutoryApplicationDocuments = []
  caseOtherApplicationDocuments = []
  caseCourtFees = []
  caseCaseDefects = []
  casedetail: any;
  courtSimilarities = []
  taggedMatter = []
  courtCaveat = []
  caseInfo: any;
  noticedetails = []



  public courtNameState;
  public courtNameCity;

  public userDetails;

  public sharingDetails;

  public isCourtNameVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isComplexNameVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isCustomCourtNameVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isConsumerForumVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isBenchNumberVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isdistrictVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public statevisibel: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isCourtNumberVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isTribunalVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public isNcltVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public isSupremeCourtVisible: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);
  public isHighCourt: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(true);

  petitionerAutoOptions: Observable<any[]>;
  advocateautoption: Observable<any[]>;
  petitionerNameSuggestions: any[] = [];
  petitionerNameSuggestionsAlias: any = {};
  tmpPetitionerNameControl = new FormControl();
  tmpadvocateNameControl = new FormControl();
  fourmlist: any;
  state: any;
  casetype: any;
  districtlist: any;
  invalidmsg = "";
  mathchdata = "";
  notfoundmsg = "";
  advocatevalue: any;
  caseid = "";

  showOther = true;

  constructor(
    @Inject(MAT_DIALOG_DATA) public courtCaseDetails: any,
    private dialogRef: MatDialogRef<CourtCasesCreateUpdateComponent>,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private _layoutService: LayoutService,
    private commonServices: CommonService,
    private customValidators: CustomValidators,
    private socketService: SocketService,
    private court: CourtcaseService,
    private highcourt: HighcourtService,
    private districtcourt: DistrictService,
    private tribunalService: TribunalService,
    private delhihighcourtservice: DelhihighcourtService,
    private allahabadhighcourtservice: AllahabadhighcourtService,
    private bombayhighcourtservice: BombayhighcourtService,
    private consumerCourtService: ConsumerCourtService,
    private himalchalhighcourtservice: HimachalhighcourtService,
    private punjabservice: PunjabService,
    private ncltCourtService: NcltCourtService
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    this.yearOptions = this.commonServices.getYearsDropdown();

    this.userDetails = this.commonServices.getLoggedInUserDetails();
  }

  ngOnInit() {
    this.getcasetypelist();
    // this.caseTypeDetailsofNclt()
    // this.grtformonselectcourt()

    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;

      this.socketObject.emit(
        environment.socketEvents.listCaseAbbreviation,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.caseTypeList = socketResponse.data;
          }
        }
      );
      this.socketObject.emit(
        environment.socketEvents.listsDesignations,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.designationsList = socketResponse.data;
          }
        }
      );
      this.socketObject.emit(
        environment.socketEvents.listsDivisions,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.divisionsList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.getApiRegionsProjects,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.regionsList = socketResponse.regionsList;
            tmpObj.allProjectSitesList = socketResponse.projectSiteList;

            if (tmpObj.isUpdateMode()) {
              tmpObj.regionsChangeHandler();
            }
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listCourtType,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.courtTypesListforCustomCourt = socketResponse.data;
            for (let i = 0; i < tmpObj.courtTypesListforCustomCourt.length; i++) {
              tmpObj.courtTypesAliasList[tmpObj.courtTypesListforCustomCourt[i]._id] =
                tmpObj.courtTypesListforCustomCourt[i].name;
            }
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listAdvocateFirm,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.advocatesList = socketResponse.data;

            if (tmpObj.isUpdateMode()) {
              // tmpObj.advocateChangeHandler();
            }
          }
        }
      );
      this.socketObject.emit(
        environment.socketEvents.listofretanership,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.retainerList = socketResponse.data;
            console.log(tmpObj.retainerList, "socketResponse");
            if (tmpObj.isUpdateMode()) {
              // tmpObj.advocateChangeHandler();
            }
          }
        }
      );

      tmpObj.socketObject.emit(
        environment.socketEvents.listLegalNotice,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.legalNoticesList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listJudgementType,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.judgementTypeList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listUsersRoleUser,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.inHouseUsersList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.getCountries,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.countriesList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.getIndiaStates,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.indiaStateList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listDepartments,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.departmentsList = socketResponse.data;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listCourtCasesPeitionerAutoComplete,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.petitionerNameSuggestions = socketResponse.data;
            tmpObj.petitionerNameSuggestionsAlias = socketResponse.aliases;
          }
        }
      );

      this.socketObject.emit(
        environment.socketEvents.listCaseAbbreviationPagination,
        {},
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            socketResponse.data.forEach(element => {
              element['case_type_name'] = element.caseType
              element['case_type_id'] = element._id
            });
            tmpObj.customcategorydata = socketResponse.data;
            console.log(tmpObj.customcategorydata, 'tmpObj.customcategorydata')
          }
        }
      );
    }
    if (this.courtCaseDetails.courtDetails) {
      this.mode = "update";
    }


    let tmpInHouseAdvocate = [];
    this.tmpInHouseUsersList = [];
    if (
      this.courtCaseDetails.inHouseAdvocate &&
      typeof this.courtCaseDetails.inHouseAdvocate == "object"
    ) {
      for (let i = 0; i < this.courtCaseDetails.inHouseAdvocate.length; i++) {
        tmpInHouseAdvocate.push(this.courtCaseDetails.inHouseAdvocate[i]._id);
        this.tmpInHouseUsersList.push(
          this.courtCaseDetails.inHouseAdvocate[i]._id
        );
      }
    }

    console.log(this.courtCaseDetails, "this.courtCaseDetails")
    this.form = this.fb.group({
      _id: this.courtCaseDetails._id || null,
      courtCaseType: [
        this.courtCaseDetails.courtCaseType || "",
        Validators.required,
      ],
      hasLegalNotice: [
        this.courtCaseDetails.hasLegalNotice || false,
        Validators.required,
      ],
      legalNotice: [this.courtCaseDetails.legalNotice || null],
      typeOfCreditors: [this.courtCaseDetails.typeOfCreditors || null],
      caseNature: [
        this.courtCaseDetails.caseNature
          ? this.courtCaseDetails.caseNature._id
          : null,
      ],
      cnr_number: [this.courtCaseDetails.cnr_number || null],
      diary_number: [this.courtCaseDetails.diary_number || null],
      firNumber: [this.courtCaseDetails.firNumber || null],
      firFillingDate: [this.courtCaseDetails.firFillingDate || null],
      policeStation: [this.courtCaseDetails.policeStation || null],
      investigationOfficerName: [
        this.courtCaseDetails.investigationOfficerName || null,
        this.customValidators.nameValidator,
      ],
      nameOfComplainant: [
        this.courtCaseDetails.nameOfComplainant || null,
        this.customValidators.nameValidator,
      ],
      nameOfAccusedPeople: [
        this.courtCaseDetails.nameOfAccusedPeople || null,
        this.customValidators.nameValidator,
      ],
      sectionDescription: [this.courtCaseDetails.sectionDescription || null],
      firUnderSection: [this.courtCaseDetails.firUnderSection || null],
      prosecutorName: [
        this.courtCaseDetails.prosecutorName || null,
        this.customValidators.nameValidator,
      ],

      purpose: [this.courtCaseDetails.purpose || null],
      arbitratorAssigned: [this.courtCaseDetails.arbitratorAssigned || null],
      arbitratorDescription: [
        this.courtCaseDetails.arbitratorDescription || null,
      ],
      caseDescription: [
        this.courtCaseDetails.caseDescription || null,
      ],
      fillingDate: [
        this.courtCaseDetails.fillingDate || null,
        Validators.required,
      ],
      // bench:[
      //   ''
      // ],
      case_category: [
        this.courtCaseDetails.case_category ? this.courtCaseDetails.case_category : null
      ],
      case_side: [
        this.courtCaseDetails.case_side ? this.courtCaseDetails.case_side : null
      ],
      court_code: [
        this.courtCaseDetails.court_code ? this.courtCaseDetails.court_code : null
      ],
      location: [
        this.courtCaseDetails.location ? this.courtCaseDetails.location : null
      ],
      bench: [
        this.courtCaseDetails.bench ? this.courtCaseDetails.bench : null
      ],
      caseName: [
        ''
      ],
      case_no_year: [
        ''
      ],
      case_cino: [
        ''
      ],
      pno_no: [
        ''
      ],
      case_no: [
        ''
      ],
      case_type: [
        ''
      ],
      filing_no: [
        ''
      ],
      diary_year: [
        ''
      ],
      state_code: [
        ''
      ],
      district_code: [
        ''
      ],
      court_status: [
        ''
      ],
      next_hearing_date: [
        ''
      ],
      registration_no: [
        ''
      ],
      commission_type: [
        this.courtCaseDetails.commission_type || null
      ],
      caseNumber: [
        this.courtCaseDetails.caseNumber || null,
        Validators.required,
      ],
      court_bench: [
        this.courtCaseDetails.court_bench || null,
      ],
      caseType: [
        this.courtCaseDetails.caseType
          ? this.courtCaseDetails.caseType._id
            ? this.courtCaseDetails.caseType._id
            : this.courtCaseDetails.caseType
          : null,
        Validators.required,
      ],
      caseYear: [
        parseInt(this.courtCaseDetails.caseYear) || null,
        Validators.required,
      ],
      petitioner: this.fb.array([]),
      responder: this.fb.array([]),
      contactPerson: this.fb.array([]),
      advocateAssigned: [
        this.courtCaseDetails.advocateAssigned
          ? this.courtCaseDetails.advocateAssigned.firmName
            ? this.courtCaseDetails.advocateAssigned.firmName
            : this.courtCaseDetails.advocateAssigned.firstName
          : "",
      ],
      inHouseAdvocate: [tmpInHouseAdvocate],
      retainerAssigned: [
        this.courtCaseDetails.retainerAssigned
          ? this.courtCaseDetails.retainerAssigned._id
            ? this.courtCaseDetails.retainerAssigned._id
            : this.courtCaseDetails.retainerAssigned
          : null,
      ],
      billingType: [
        this.courtCaseDetails.billingType || "",
        Validators.required,
      ],
      totalAmount: [this.courtCaseDetails.totalAmount || ""],
      courtDetails: this.fb.group({
        courtName: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.courtName || null
            : null,
        ],
        complexName: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.complexName || null
            : null,
          this.customValidators.nameValidator,
        ],
        customCourtName: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.customCourtName || null
            : null,
          this.customValidators.nameValidator,
        ],
        courtType: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.courtType || null
            : null,
        ],
        state: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.state || null
            : null,
        ],
        district: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.district || null
            : null,
        ],
        courtNumber: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.courtNumber || null
            : null,
        ],
        benchNumber: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.benchNumber || null
            : null,
        ],
        judgeName: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.judgeName || null
            : null,
          this.customValidators.nameValidator,
        ],
        forums: [
          this.courtCaseDetails.courtDetails
            ? this.courtCaseDetails.courtDetails.forumsName || null
            : null,
        ],
        serverType: [
          ''
        ]
      }),

      /* Upload Document: Start */
      pleadingDocs: [
        this.courtCaseDetails.pleadingDocs
          ? this.courtCaseDetails.pleadingDocs
          : [],
      ],
      evidenceDocs: [
        this.courtCaseDetails.evidenceDocs
          ? this.courtCaseDetails.evidenceDocs
          : [],
      ],
      plaintiffOrDefendantDocs: [
        this.courtCaseDetails.plaintiffOrDefendantDocs
          ? this.courtCaseDetails.plaintiffOrDefendantDocs
          : [],
      ],
      applicationStayInjunctionDocs: [
        this.courtCaseDetails.applicationStayInjunctionDocs
          ? this.courtCaseDetails.applicationStayInjunctionDocs
          : [],
      ],
      ordersDocs: [
        this.courtCaseDetails.ordersDocs
          ? this.courtCaseDetails.ordersDocs
          : [],
      ],
      miscellaneousDocs: [
        this.courtCaseDetails.miscellaneousDocs
          ? this.courtCaseDetails.miscellaneousDocs
          : [],
      ],
      claimantRespondentDocs: [
        this.courtCaseDetails.claimantRespondentDocs
          ? this.courtCaseDetails.claimantRespondentDocs
          : [],
      ],
      /* Upload Document: End */

      petition_docs: this.fb.group({
        docs: [
          this.courtCaseDetails.petition_docs
            ? this.courtCaseDetails.petition_docs.docs || []
            : null,
        ],
        description: [
          this.courtCaseDetails.petition_docs
            ? this.courtCaseDetails.petition_docs.description || null
            : null,
        ],

        claimentOrClaimDocs: [
          this.courtCaseDetails.petition_docs &&
            this.courtCaseDetails.petition_docs.claimentOrClaimDocs
            ? this.courtCaseDetails.petition_docs.claimentOrClaimDocs
            : [],
        ],
        rejoinerDocs: [
          this.courtCaseDetails.petition_docs &&
            this.courtCaseDetails.petition_docs.rejoinerDocs
            ? this.courtCaseDetails.petition_docs.rejoinerDocs
            : [],
        ],
        admissionDenialDocs: [
          this.courtCaseDetails.petition_docs &&
            this.courtCaseDetails.petition_docs.admissionDenialDocs
            ? this.courtCaseDetails.petition_docs.admissionDenialDocs
            : [],
        ],
        evidenceDocs: [
          this.courtCaseDetails.petition_docs &&
            this.courtCaseDetails.petition_docs.evidenceDocs
            ? this.courtCaseDetails.petition_docs.evidenceDocs
            : [],
        ],
        writtenArgumentDocs: [
          this.courtCaseDetails.petition_docs &&
            this.courtCaseDetails.petition_docs.writtenArgumentDocs
            ? this.courtCaseDetails.petition_docs.writtenArgumentDocs
            : [],
        ],

        crimimalCaseStages: this.fb.array([]),
      }),
      supporting_docs: this.fb.group({
        docs: [
          this.courtCaseDetails.supporting_docs
            ? this.courtCaseDetails.supporting_docs.docs || []
            : null,
        ],
        description: [
          this.courtCaseDetails.supporting_docs
            ? this.courtCaseDetails.supporting_docs.description || null
            : null,
        ],

        respondentOrCounterClaimDocs: [
          this.courtCaseDetails.supporting_docs &&
            this.courtCaseDetails.supporting_docs.respondentOrCounterClaimDocs
            ? this.courtCaseDetails.supporting_docs.respondentOrCounterClaimDocs
            : [],
        ],
        surrejoinerDocs: [
          this.courtCaseDetails.supporting_docs &&
            this.courtCaseDetails.supporting_docs.surrejoinerDocs
            ? this.courtCaseDetails.supporting_docs.surrejoinerDocs
            : [],
        ],
        admissionDenialDocs: [
          this.courtCaseDetails.supporting_docs &&
            this.courtCaseDetails.supporting_docs.admissionDenialDocs
            ? this.courtCaseDetails.supporting_docs.admissionDenialDocs
            : [],
        ],
        evidenceDocs: [
          this.courtCaseDetails.supporting_docs &&
            this.courtCaseDetails.supporting_docs.evidenceDocs
            ? this.courtCaseDetails.supporting_docs.evidenceDocs
            : [],
        ],
        writtenArgumentDocs: [
          this.courtCaseDetails.supporting_docs &&
            this.courtCaseDetails.supporting_docs.writtenArgumentDocs
            ? this.courtCaseDetails.supporting_docs.writtenArgumentDocs
            : [],
        ],

        crimimalCaseStages: this.fb.array([]),
      }),
    });
    if (this.courtCaseDetails && this.courtCaseDetails.advocateAssigned) {
      this.advocatevalue = this.courtCaseDetails.advocateAssigned;
    }


    if (this.mode == 'create') {
      if (this.yearOptions) {
        this.form.get("caseYear").setValue(this.yearOptions[0]);
      }
    }

    this.hasLegalNoticeChangeHandler();

    /* Populate Contact Person Data: Start */
    if (
      this.courtCaseDetails &&
      this.courtCaseDetails.contactPerson &&
      this.courtCaseDetails.contactPerson.length > 0
    ) {
      for (let i = 0; i < this.courtCaseDetails.contactPerson.length; i++) {
        this.addContactPersonArrUnit(this.courtCaseDetails.contactPerson[i]);
      }
    } else {
      this.addContactPersonArrUnit({});
    }
    /* Populate Contact Person Data: End */

    /* Populate Petitioner Data: Start */
    if (
      this.courtCaseDetails &&
      this.courtCaseDetails.petitioner &&
      this.courtCaseDetails.petitioner.length > 0
    ) {
      for (let i = 0; i < this.courtCaseDetails.petitioner.length; i++) {
        this.addPetitionerArrUnit(this.courtCaseDetails.petitioner[i]);
        this.petitionerCountryChangeHandler(i);
        this.petitionerStateChangeHandler(i);
      }
    } else {
      this.addPetitionerArrUnit({});
    }
    /* Populate Petitioner Data: End */

    /* Populate Responder Data: Start */
    if (
      this.courtCaseDetails &&
      this.courtCaseDetails.responder &&
      this.courtCaseDetails.responder.length > 0
    ) {
      for (let i = 0; i < this.courtCaseDetails.responder.length; i++) {
        this.addResponderArrUnit(this.courtCaseDetails.responder[i]);
        this.responderCountryChangeHandler(i);
        this.responderStateChangeHandler(i);
      }
    } else {
      this.addResponderArrUnit({});
    }
    /* Populate Responder Data: End */

    /* Auto complete for Petitioner's name: Start */
    this.petitionerAutoOptions =
      this.tmpPetitionerNameControl.valueChanges.pipe(
        startWith(""),
        map((value) => this._filterPettionerSuggestions(value))
      );

    this.advocateautoption = this.tmpadvocateNameControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filteradvocateSuggestions(value))
    );
    /* Auto complete for Petitioner's name: End */

    this.initSharingDetails(this);

    if (this.courtCaseDetails) {
      console.log(this.courtCaseDetails, "this.courtCaseDetails")
      this.toggleCourtType = true;
      this.customcourt = false;
      if (this.courtCaseDetails.index == 1) {
        this.form
          .get("courtDetails.courtName")
          .setValue("High Court");
      }
      if (this.courtCaseDetails.courtDetails.courtType == 1) {
        if (this.form.value.caseType && this.form.value.caseNumber && this.form.value.caseYear) {
          let obj = {
            case_type: this.form.value.caseType,
            case_no: this.form.value.caseNumber,
            case_year: this.form.value.caseYear,
            flag: "0",
          };
          this.loading = true;
          this.court.post("ws/courtCase", obj).subscribe((res2: any) => {
            // console.log(res2,"by case number ")
            this.loading = false;
            if (res2.status) {
              let fetchdata;
              let newdata;
              this.caseid = res2.caseInfo.id;

              if (this.caseid) {
                this.getnoticedetails();
                this.getjudgementdetails();
                this.getearlierCourtdetails();
                this.getlistingDatedetails();
                this.getinterlocutoryApplicationDocumentdetails();
                this.getApplicationDocumentdetails();
                this.getcourtFeedetails();
                this.getcaseDefectdetails();
                // this.getcase();
                this.getcourtSimilaritydetails();
                this.getcourtTaggedMatterdetails();
                this.getcourtCaveatsdetails();
                // this.getcourtcaseyiddetails();
                this.getofficereportdetail();
                this.getrestorationdetail();
                this.getindexingdetail();
                this.getapperencedetail();
                this.getdropnotedetail();
                this.getmentionmemodetail();

              }

              let obj = {
                diary_no: res2.caseInfo.diary_only,
                diary_year: res2.caseInfo.diary_year,
                flag: 1,
              };

              this.court
                .post("ws/autoUpdateCourtCase", obj)
                .subscribe((res2: any) => {
                  newdata = res2.autoUpdateCourtCase[0];
                  if (newdata) {
                    this.mathchdata = "Case fetched successfully from the Court";

                    this.form
                      .get("courtDetails.courtNumber")
                      .setValue(newdata.court_number);

                    this.form
                      .get("courtDetails.judgeName")
                      .setValue(newdata.judge_name);
                    this.form
                      .get("caseName")
                      .setValue(newdata.case_name);
                    this.form
                      .get("caseDescription")
                      .setValue(newdata.case_description);
                    this.form
                      .get("courtDetails.courtName")
                      .setValue("Supreme Court");
                    this.form.get("diary_number").setValue(newdata.diary_only);
                    let filedate = new Date(parseInt(newdata.case_filing_date));
                    this.form.get("fillingDate").setValue(filedate);

                    this.loading = false;
                    setTimeout(() => {
                      this.totalTabGroups = this.countOfTab
                      this.mathchdata = "";
                    }, 1000);
                  }
                });
            }
          });
        }
      } else if (this.courtCaseDetails.courtDetails.courtType == 2) {
        this.selectedValue = this.courtCaseDetails.courtDetails.courtName

        if (this.selectedValue == 'e-Court Server' && this.form.value.courtDetails.state && this.form.value.courtDetails.benchNumber && this.form.value.caseType && this.form.value.caseNumber && this.form.value.caseYear) {

          this.courtStateChangeHandler();
          this.getServerValue(this.selectedValue)

          this.invalidmsg = ''
          let obj = {
            state_code: this.form.value.courtDetails.state,

            court_code: this.form.value.courtDetails.benchNumber,
            case_type_category_id: this.form.value.caseType,
            case_no: this.form.value.caseNumber,
            case_year: this.form.value.caseYear,
            saved_by: "1",
          };
          this.loading = true;
          this.highcourt
            .postecourt("ws/eHighCourtKeywordSearchParty", obj)
            .subscribe((res2: any) => {
              if (res2) {
                if (res2.partyName) {
                  let courtcaselist = res2.partyName[0];
                  if (courtcaselist) {
                    let obj = {
                      state_code: courtcaselist.state_code,
                      court_code: courtcaselist.court_code,
                      case_no_year: courtcaselist.case_no_year,
                      case_cino: courtcaselist.case_cino,
                    };
                    this.highcourt.postecourt("ws/eHighCourtCase", obj).subscribe((res: any) => {
                      if (res) {

                        // detaildata = res.courtInfo
                        this.title = res.courtInfo.establishment_name


                        this.caseid = res.courtInfo.id

                        if (this.caseid) {

                          // this.getcasedetais();
                          this.getcasestatusdetais();
                          this.getpetinordetais();
                          this.getrepondentdetais();
                          this.getactdetais();
                          this.getCaseHistorydetais();
                          this.getCaseordersdetais();
                          this.getiadetail();
                          this.getcaseconversiondetail();
                          this.getmainmetterdetail();
                          this.getsubmetterdetail();
                          this.getlinkedcasedetail();
                          this.getobjectiondetail();
                          this.getdocumentdetail();
                          this.getfirdetail();
                          this.getcategorydetail();
                          this.getactdetal();
                        }

                        this.highcourt.postecourt("ws/eAutoUpdateHighCourtCase", obj).subscribe((res2: any) => {
                          if (res2.autoUpdateHighCourtCase) {
                            this.mathchdata =
                              "Case fetched successfully from the Court";

                            let courtscaselist =
                              res2.autoUpdateHighCourtCase[0];
                            this.form
                              .get("courtDetails.courtNumber")
                              .setValue(courtscaselist.court_number);
                            this.form
                              .get("courtDetails.judgeName")
                              .setValue(courtscaselist.judge_name);
                            this.form
                              .get("courtDetails.courtName")
                              .setValue(this.serverTypeValue);
                            this.form
                              .get("caseName")
                              .setValue(courtscaselist.case_name);
                            this.form.get("court_code").setValue(courtscaselist.court_code);
                            this.form.get("case_no_year").setValue(courtscaselist.case_no_year);
                            this.form.get("case_cino").setValue(courtscaselist.case_cino);
                            this.form.get("caseDescription").setValue(courtscaselist.case_description ? courtscaselist.case_description : null);

                            this.form
                              .get("cnr_number")
                              .setValue(courtscaselist.cnr_number);
                            let filedate = new Date(parseInt(courtscaselist.filing_date));
                            this.form.get("fillingDate").setValue(filedate);

                            this.loading = false;
                            setTimeout(() => {
                              this.totalTabGroups = this.countOfTab
                              this.mathchdata = "";
                            }, 2000);
                          }
                          // this.courtcaselist = res2.autoUpdateCourtCase[0]
                        });
                      }
                    });
                  }
                }
              } else {
              }
            });
        }

        else if (this.selectedValue == 'High Court Server' && this.form.value.courtDetails.state && this.form.value.caseType && this.form.value.caseNumber && this.form.value.caseYear) {

          this.invalidmsg = ''
          if (this.form.value.courtDetails.state == 26) {

            this.courtStateChangeHandler();
            this.getServerValue(this.selectedValue)

            this.loading = true
            let obj = {
              case_no: this.form.value.caseNumber,
              case_type: this.form.value.caseType,
              case_year: this.form.value.caseYear,
              search_by: "1"

            }
            this.delhihighcourtservice.postecourt("caseList", obj).subscribe((res) => {
              if (res['status']) {
                let pno_no = res['partyName'][0].pno_no
                let caseInfo = res['partyName'][0]

                if (pno_no) {
                  let obj = {
                    pno: pno_no
                  }

                  this.delhihighcourtservice.postecourt("courtCases", obj).subscribe((res2) => {
                    if (res2['status']) {
                      let court_case_id = res2['partyName'][0].id

                      if (court_case_id) {
                        let obj = {
                          court_case_id: court_case_id
                        }

                        this.caseOrders(obj)
                        this.caseDetailOrders(obj)

                        this.form.get('fillingDate').clearValidators();
                        this.form.get('fillingDate').updateValueAndValidity();

                        this.form
                          .get("courtDetails.courtName")
                          .setValue(this.serverTypeValue);
                        this.form.get("pno_no").setValue(caseInfo.pno_no);
                        this.form.get("courtDetails.state").setValue(this.form.value.courtDetails.state);
                        this.form.get("caseName").setValue(`${caseInfo.petitioner} Vs. ${caseInfo.respondent}`);

                        this.mathchdata = "Case fetched successfully from the Court";

                        this.loading = false;
                        setTimeout(() => {
                          this.totalTabGroups = this.countOfTab
                          this.mathchdata = "";
                        }, 1000);
                      }
                    }
                  })
                }
              }
            })
          }
          else if (this.form.value.courtDetails.state == 13) {

            this.courtStateChangeHandler();
            this.getServerValue(this.selectedValue)

            let obj = {
              case_no: this.form.value.caseNumber,
              case_type: this.form.value.caseType,
              case_year: this.form.value.caseYear,
              court_bench: this.form.value.court_bench,
              search_by: "1"
            }

            this.loading = true;
            this.allahabadhighcourtservice.postData("caseList", obj).subscribe(
              (res) => {
                if (res.status) {

                  let caselist = res.caseList[0]
                  let obj2 = {
                    case_cino: caselist.case_cino,
                    court_bench: caselist.court_bench,
                    search_by: "1"
                  }

                  this.allahabadhighcourtservice.postData("caseDetails", obj2).subscribe((res2) => {
                    if (res2.status) {
                      let casedetails = res2.caseDetails[0];

                      let obj3 = {
                        court_case_id: casedetails.id
                      }
                      this.ordersJudgement(obj3)
                      this.listingHistory(obj3)
                      this.crimeDetails(obj3)
                      this.lowerCourtDetails(obj3)
                      this.iaDetails(obj3)
                      this.categoryDetails(obj3)
                      this.petAndResAdv(obj3)
                      this.caseStatus(obj3)

                      let filedate = new Date(parseInt(casedetails.filing_date));
                      this.form.get("fillingDate").setValue(filedate);
                      this.form.get("courtDetails.state").setValue(this.form.value.courtDetails.state);
                      this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);
                      this.form.get("case_cino").setValue(caselist.case_cino);
                      this.form.get("court_bench").setValue(caselist.court_bench);
                      this.form.get("caseName").setValue(caselist.case_name);

                      this.mathchdata = "Case fetched successfully from the Court";

                      this.loading = false;
                      setTimeout(() => {
                        this.totalTabGroups = this.countOfTab;
                        this.mathchdata = "";
                      }, 1000);
                    }
                  })
                }
              }
            );
          }
          else if (this.form.value.courtDetails.state == 1) {

            this.courtStateChangeHandler();
            this.getServerValue(this.selectedValue)

            this.loading = true
            this.form.value.court_code = this.court_bench_id
            this.form.value.case_side = this.case_side_id

            let obj = {
              case_no: this.form.value.caseNumber,
              case_type: this.form.value.caseType,
              case_year: this.form.value.caseYear,
              case_category: this.form.value.case_category,
              court_code: this.courtCaseDetails.court_code,
              case_side: this.courtCaseDetails.case_side
            }

            this.bombayhighcourtservice.postData("caseDetails", obj).subscribe((res) => {

              if (res.status) {
                this.caseDetail = res.caseDetails[0]
                let court_case_id = res.caseDetails[0].id

                if (court_case_id) {
                  this.officeInformation(court_case_id)
                  this.miscInformation(court_case_id)
                  this.objections(court_case_id)
                  this.casePaperIndex(court_case_id)
                  this.connectedMatters(court_case_id)
                  this.applicationCases(court_case_id)
                  this.listingDates(court_case_id)
                  this.LowerCourt(court_case_id)

                  let filedate = new Date(parseInt(this.caseDetail.filing_date));
                  this.form.get("fillingDate").setValue(filedate);
                  this.form.get("courtDetails.state").setValue(this.form.value.courtDetails.state);
                  this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);

                  this.form.get("case_category").setValue(this.caseDetail.case_category);
                  this.form.get("case_no").setValue(this.caseDetail.case_no);
                  this.form.get("case_type").setValue(this.caseDetail.case_type);
                  this.form.get("case_side").setValue(this.caseDetail.case_side);
                  this.form.get("court_code").setValue(this.caseDetail.court_code);

                  this.form.get("caseName").setValue(`${this.caseDetail.petitioner} Vs. ${this.caseDetail.respondent}`);

                  this.mathchdata = "Case fetched successfully from the Court";

                  this.loading = false;
                  setTimeout(() => {
                    this.totalTabGroups = this.countOfTab;
                    this.mathchdata = "";
                  }, 1000);
                }
              }
            })
          }
          else if (this.form.value.courtDetails.state == 5) {

            this.loading = true
            this.courtStateChangeHandler();
            this.getServerValue(this.selectedValue)

            let obj = {
              case_no: this.form.value.caseNumber,
              case_type: this.form.value.caseType,
              case_year: this.form.value.caseYear,
              case_category: this.form.value.case_category,
              court_code: this.courtCaseDetails.court_code,
              case_side: this.courtCaseDetails.case_side
            }

            this.himalchalhighcourtservice.postData("caseDetails", obj).subscribe((res) => {
              if (res.status) {
                this.caseDetail = res.caseDetails;
                let court_case_id = res.caseDetails.id

                if (court_case_id) {
                  this.caseStatusHimachal({ court_case_id: court_case_id })
                  this.partyDetailHimachal({ court_case_id: court_case_id })
                  this.actsDetailHimachal({ court_case_id: court_case_id })
                  this.mainMattersHimachal({ court_case_id: court_case_id })
                  this.connectedCasesHimachal({ court_case_id: court_case_id })
                  this.applicationCasesHimachal({ court_case_id: court_case_id })
                  this.listingDatesHimachal({ court_case_id: court_case_id })
                  this.orderJudgementHimachal({ court_case_id: court_case_id })
                  this.lowerCourtDetailHimachal({ court_case_id: court_case_id })

                  let filedate = new Date(parseInt(this.caseDetail.filing_date));
                  this.form.get("fillingDate").setValue(filedate);
                  this.form.get("courtDetails.state").setValue(this.stateCode);
                  this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);
                  this.form.get("case_category").setValue(this.caseDetail.case_category);
                  this.form.get("next_hearing_date").setValue(this.caseDetail.next_hearing_date);
                  this.form.get("case_cino").setValue(this.caseDetail.cnr_no);
                  this.form.get("registration_no").setValue(this.caseDetail.registration_no);

                  this.mathchdata = "Case fetched successfully from the Court";

                  this.loading = false;
                  setTimeout(() => {
                    this.totalTabGroups = this.countOfTab;
                    this.mathchdata = "";
                  }, 2000);
                } else {
                  this.loading = false
                  this.notfoundmsg = "Case not fetched from the Court";
                  setTimeout(() => {
                    this.notfoundmsg = "";
                  }, 2000);
                }
              }
            })
          }
          else if (this.form.value.courtDetails.state == 22) {
            this.loading = true
            this.courtStateChangeHandler();
            this.getServerValue(this.selectedValue)

            let obj = {
              case_no: this.form.value.caseNumber,
              case_type: this.form.value.caseType,
              case_year: this.form.value.caseYear,
              search_by: "1"
            }

            this.punjabservice.postData("caseList", obj).subscribe((res) => {
              if (res.status) {
                let caseList = res.caseList[0]
                let obj = {
                  case_type: caseList.case_type,
                  case_no: caseList.case_no,
                  case_year: caseList.case_year
                }
                this.punjabservice.postData('caseDetails', obj).subscribe((res) => {
                  if (res.status) {
                    let court_case_id = res.caseDetails.id
                    if (court_case_id) {
                      this.relatedCases({ court_case_id: court_case_id })
                      this.judgmentDetails({ court_case_id: court_case_id })
                      this.lowerCourtDetailsPunjab({ court_case_id: court_case_id })
                      this.copyPetition({ court_case_id: court_case_id })
                      this.firDetails({ court_case_id: court_case_id })
                      this.impungOrderDetail({ court_case_id: court_case_id })
                      this.detailOfAppeals({ court_case_id: court_case_id })
                      this.caseListingDetails({ court_case_id: court_case_id })
                      this.form.get('fillingDate').clearValidators();
                      this.form.get('fillingDate').updateValueAndValidity();
                      this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);
                      this.form.get("caseName").setValue(caseList.case_name);
                      this.mathchdata = "Case fetched successfully from the Court";

                      this.loading = false;
                      setTimeout(() => {
                        this.totalTabGroups = this.countOfTab;
                        this.mathchdata = "";
                      }, 2000);
                    }
                  } else {
                    this.loading = false
                    this.notfoundmsg = "Case not fetched from the Court";
                    setTimeout(() => {
                      this.notfoundmsg = "";
                    }, 2000);
                  }
                }, (err) => {
                  this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
                    duration: 3000,
                    horizontalPosition: 'center'
                  });
                  this.loading = false;
                })
              }
            }, (err) => {
              this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
                duration: 3000,
                horizontalPosition: 'center'
              });
              this.loading = false;
            })
          }
        }
      } else if (this.form.value.courtDetails.courtType == 3) {
        this.loading = true
        this.invalidmsg = ''
        let ob = {
          state_code: this.form.value.courtDetails.state,
          district_code: Number(this.form.value.courtDetails.district),
          forum_id:
            this.form.value.courtDetails.complexName,
          case_type_category_id: Number(this.form.value.caseType),
          case_no: this.form.value.caseNumber,
          case_year: this.form.value.caseYear,
          saved_by: "1",
        };

        this.districtcourt
          .postecourt("ws/eCourtSearchParty", ob)
          .subscribe((res2: any) => {
            if (res2.partyName) {
              let courtcaselist = res2.partyName[0];
              let obes = {
                state_code: courtcaselist.state_code,
                district_code: courtcaselist.district_code,
                forum_id: courtcaselist.forum_id,
                case_no_year: courtcaselist.case_no_year,
                case_cino: courtcaselist.case_cino,
              };

              this.districtcourt
                .postecourt("ws/eCourtCase", obes)
                .subscribe((res: any) => {

                  this.caseid = res.courtInfo.id

                  if (this.caseid) {
                    this.getcasestatusdetail()
                    this.getpetinordetail();
                    this.getrepondentdetail();
                    this.getactdetail();
                    this.getmainmetterdetails()
                    this.getsubmetterdetails()
                    this.getCaseHistorydetail()
                    this.getfirdetails()
                    this.getCaseordersdetail()
                    // this.getCaseHistorydetais();
                    // this.getCaseordersdetais();
                    // this.getfirdetail();
                    this.getsubordinatedetail();
                    this.getcasetransferdetail();
                    this.getprocessdetail();
                    // this.getsubmetterdetail();
                    // this.getmainmetterdetail();

                    this.districtcourt.postecourt("ws/eAutoUpdateCourtCase", obes).subscribe((res3: any) => {
                      if (res3.status) {
                        let detaildata;
                        if (res3.autoUpdateHighCourtCase) {
                          detaildata = res3.autoUpdateHighCourtCase;
                          this.form
                            .get("courtDetails.courtNumber")
                            .setValue(detaildata.court_number);
                          this.form
                            .get("courtDetails.judgeName")
                            .setValue(detaildata.judge_name);
                          this.form
                            .get("courtDetails.courtName")
                            .setValue("District Court");
                          this.form
                            .get("cnr_number")
                            .setValue(detaildata.cnr_number);
                          let filedate = new Date(parseInt(detaildata.filing_date));
                          this.form.get("fillingDate").setValue(filedate);

                          this.form.get("case_no_year").setValue(detaildata.case_no_year);
                          this.form.get("case_cino").setValue(detaildata.case_cino);

                          this.form.get("caseName").setValue(detaildata.case_name);

                          this.mathchdata = "Case fetched successfully from the Court";

                          this.loading = false;
                          setTimeout(() => {
                            this.totalTabGroups = this.countOfTab;
                            this.mathchdata = "";
                          }, 2000);
                        }

                        //  this.savepartdetail(detaildata);
                      }
                    });
                  }
                  // this.loading = false;
                });
            }
          });
      } else if (this.form.value.courtDetails.courtType == 8) {

        this.isTribunalVisible.next(true);
        this.locationCategoryDetail()
        this.caseTypeDetail()

        this.invalidmsg = ''
        let obj = {
          case_number: this.form.value.caseNumber,
          case_type: this.form.value.caseType,
          case_year: this.form.value.caseYear,
          location: this.form.value.location,
          search_by: "1",
          party_type: "",
          case_status: "",
          party_name: "",
          filing_no: "",
          advocate_name: "",
        }

        this.loading = true
        this.tribunalService.postData('caseList', obj).subscribe((res) => {
          // console.log(res.status,"hgfkjsdghkjh")
          if (res.status) {
            let caesList = res.caseList[0];
            let caseTitle = caesList.case_title.split("VS");
            this.t1 = caseTitle[0];
            this.t2 = caseTitle[1];

            let obj = {
              location: caesList.location,
              filing_no: caesList.filing_no
            };

            this.tribunalService.postData('caseDetails', obj).subscribe(res => {
              let court_case_id = res.caseDetails[0].id
              this.caesDetailData = res.caseDetails[0]

              if (court_case_id) {
                this.partyDetail(court_case_id)
                this.legalRepresentative(court_case_id)
                this.getfirstHearingDetail(court_case_id)
                this.getLastHearingDetail(court_case_id)
                this.getNextHearingDetail(court_case_id)
                this.getCaseHistory(court_case_id)
                this.getorderHistory(court_case_id)
                this.getIasOtherApplication(court_case_id)
                this.getConnectedCases(court_case_id)

                let filedate = new Date(parseInt(this.caesDetailData.date_of_filing));
                this.form.get("fillingDate").setValue(filedate);
                this.form.get("courtDetails.courtName").setValue('Tribunal');

                this.form.get("filing_no").setValue(caesList.filing_no);
                this.form.get("location").setValue(caesList.location);
                this.form.get("case_no_year").setValue(caesList.case_year);

                this.form.get("caseName").setValue(caesList.case_title);

                this.mathchdata = "Case fetched successfully from the Court";

                this.loading = false;
                setTimeout(() => {
                  this.totalTabGroups = this.countOfTab;
                  this.mathchdata = "";
                }, 2000);
              }
            })
          } else {
            this.applicant_nameArr = [];
            this.Respodent_nameArr = [];
            this.applicant_legal_representative_name = [];
            this.respondent_legal_representative_name = [];
            this.firstHearingDetail = {};
            this.lastHearingDetail = {};
            this.nextHearingDetail = {};
            this.orderHistory = [];
            this.caseHistory = [];
            this.iasOtherApplication = [];
            this.connectedCases = [];

            this.notfoundmsg = "Case not fetched from the Court";
            this.loading = false
            setTimeout(() => {
              this.notfoundmsg = "";
            }, 2000);
          }
        })
      }
      else if (this.form.value.courtDetails.courtType == 9) {
        this.isNcltVisible.next(true);
        this.locationCategoryDetailsofNclt()
        this.caseTypeDetailsofNclt()
        let obj = {
          bench: this.courtCaseDetails.location,
          filing_number: this.courtCaseDetails.firNumber.toString().slice(0, -4),
          case_year: this.courtCaseDetails.caseYear,
          search_by: "2",
        }
        console.log(this.courtCaseDetails, "update/caseList")
        this.loading = true
        this.ncltCourtService.postData('caseList', obj).subscribe((res) => {
          if (res.status == true) {
            console.log(res)
            let totalCaseNumberData = res.caseList[0];
            let filedate = new Date(parseInt(totalCaseNumberData.filing_date));
            this.form.get("fillingDate").setValue(filedate);
            this.form.get("bench").setValue(totalCaseNumberData.bench);
            let obj = {
              case_cino: totalCaseNumberData.case_cino
            }
            this.ncltCourtService.postData('caseDetails', obj).subscribe((res) => {
              console.log(res.caseDetails.id)
              let courtcaseId = res.caseDetails
              console.log(courtcaseId)
              this.partyDetailofNclt(courtcaseId)
              this.historyListing(courtcaseId)
              this.iaMaListing(courtcaseId)
              this.connectedMatterListing(courtcaseId)




              // this.ncltCourtService.postData('allParties', objj).subscribe((res)=>{
              //   console.log(res.allParties.petitioner)
              //   this.form.get("petitioner").setValue(res.allParties.petitioner);
              //   this.form.get("responder").setValue(res.allParties.respondent);
              // })

            })


            this.loading = false;
          }
        })
      } else if (this.form.value.courtDetails.courtType == 4) {

        this.loading = true
        this.isConsumerForumVisible.next(true)
        this.caseTypeCategoryDetails()

        if (this.form.value.commission_type == 'B') {
          this.ConsumerForumNCDRC = true
          this.ConsumerForumStateCommission = false
          this.ConsumerForumDistrictCommission = false
          this.toggleChecked = true
        }
        if (this.form.value.commission_type == 'C') {
          this.ConsumerForumNCDRC = false
          this.ConsumerForumStateCommission = true
          this.ConsumerForumDistrictCommission = false
          this.toggleChecked2 = true
          this.getStateCommissionState()
          this.form.get('state_code').setValue(this.form.value.courtDetails.state);
        }
        if (this.form.value.commission_type == 'E') {
          this.getDistrictCommissionState()
          this.ConsumerForumNCDRC = false
          this.ConsumerForumStateCommission = false
          this.ConsumerForumDistrictCommission = true
          this.toggleChecked3 = true
        }

        let obj = {
          case_no: this.form.value.caseNumber,
          case_type: this.form.value.caseType,
          case_year: this.form.value.caseYear,
          commission_type: this.commission_type,
        }

        if (this.form.value.commission_type == "B") {
          obj['state_code'] = "0"
          obj['district_code'] = "0"
        } else if (this.form.value.commission_type == "C") {
          obj['district_code'] = "0"
          obj['state_code'] = this.form.value.courtDetails.state
        } else if (this.form.value.commission_type == "E") {
          obj['district_code'] = this.form.value.courtDetails.district
          obj['state_code'] = this.form.value.courtDetails.state
        }

        this.consumerCourtService.postData('caseStatus', obj).subscribe((res) => {
          if (res.status) {
            this.caseStatusOfConsumer = res.caseStatus[0]

            let obj2 = {
              id: this.caseStatusOfConsumer.id,
            };
            this.caseHistoryOrdersData = []
            this.consumerCourtService.postData('caseHistoryOrders', obj2).subscribe(
              (res) => {
                if (res.status) {
                  this.caseHistoryOrdersData.push(...res.caseHistoryOrders);

                  let filedate = new Date(parseInt(this.caseStatusOfConsumer.filing_date));
                  this.form.get("fillingDate").setValue(filedate);

                  this.form.get("caseName").setValue(`${this.caseStatusOfConsumer.complainant} vs. ${this.caseStatusOfConsumer.respondent}`);

                  this.mathchdata = "Case fetched successfully from the Court";

                  this.loading = false;
                  setTimeout(() => {
                    this.totalTabGroups = this.countOfTab + 1;
                    this.mathchdata = "";
                  }, 2000);
                }
              }
            );
          }
        })
      } else {
        this.toggleCourtType = false;
        if (this.courtCaseDetails.courtDetails.courtType == environment.custom_court_id_collection.tribunal_court_custom_ide_court) {
          this.customcourt = true;
          this.isTribunalVisible.next(true);
          this.locationCategoryDetail()
          this.caseTypeDetail()
        } else if (this.courtCaseDetails.courtDetails.courtType == environment.custom_court_id_collection.consumer_forum_court_custom_id) {
          this.customcourt = true;
          this.isConsumerForumVisible.next(true)
          this.caseTypeCategoryDetails()
          if (this.form.value.commission_type == 'B') {
            this.ConsumerForumNCDRC = true
            this.ConsumerForumStateCommission = false
            this.ConsumerForumDistrictCommission = false
            this.toggleChecked = true
          }
          if (this.form.value.commission_type == 'C') {
            this.ConsumerForumNCDRC = false
            this.ConsumerForumStateCommission = true
            this.ConsumerForumDistrictCommission = false
            this.toggleChecked2 = true
            this.getStateCommissionState()
            this.form.get('state_code').setValue(this.form.value.courtDetails.state);
          }
          if (this.form.value.commission_type == 'E') {
            this.getDistrictCommissionState()
            this.ConsumerForumNCDRC = false
            this.ConsumerForumStateCommission = false
            this.ConsumerForumDistrictCommission = true
            this.toggleChecked3 = true
          }
        }
        else if (this.courtCaseDetails.courtDetails.courtType == environment.custom_court_id_collection.district_court_custom_id) {
          this.customcourt = true;
        }
        else if (this.courtCaseDetails.courtDetails.courtType == environment.custom_court_id_collection.high_court_custom_id) {
          this.customcourt = true;
        }
        else if (this.courtCaseDetails.courtDetails.courtType == environment.custom_court_id_collection.supreme_court_custom_id) {
          this.customcourt = true;
        } else {
          this.customcourt = true;
        }
      }
    } else {
    }


  }



  historyData = []
  message
  historyListing(val){
    console.log(val,"val")
    let obj = {
      court_case_id:val.id
    }
    this.ncltCourtService.postData('listingHistory',obj).subscribe((res)=>{
      if(res.listingHistory){
      this.historyData = res.listingHistory
      }
      else{
        this.message= "No Data Found!"
      }
    },(err)=>{
    })
  
  
  }


  iaMaData= []
iaMaListing(val){
  let obj = {
    court_case_id:val.id
  }
  this.ncltCourtService.postData('iaAndMa',obj).subscribe((res)=>{

    if(res.iaAndMa){
      this.iaMaData = res.iaAndMa
      }
      else{
        this.message= "No Data Found!"
      }
  },(err)=>{
  })
}

connectedMatterData = []
connectedMatterListing(val){
  let obj = {
    court_case_id:val.id
  }
  this.ncltCourtService.postData('connectedMatters',obj).subscribe((res)=>{
    console.log(res,"getting response of iaMaListing")
    if(res.connectedMatters){
      this.connectedMatterData = res.connectedMatters
      }
      else{
        this.message= "No Data Found!"
      }
  },(err)=>{
  })
}

  partyDetailofNclt(val) {
    // console.log(val)

    this.applicantDetailofNclt(val);
    this.respondentDetailofNclt(val);

  }

  applicant_nameArr_Nclt = []
  applicantDetailofNclt(val){
    this.applicant_nameArr_Nclt = [];
    let obj = {
      court_case_id:val.id
    }
    this.ncltCourtService.postData('allParties', obj).subscribe((res) => {
console.log(res,".dfjkghksjg")
      let control = this.form.get('petitioner') as FormArray;
      control.controls[0].get('name').setValue(res.allParties.petitioner)

      this.applicant_nameArr_Nclt.push(res.allParties);
      console.log(this.applicant_nameArr_Nclt,"this.applicant_nameArr_Nclt")
    })
  }


  Respodent_nameArr_Nclt = [];
  respondentDetailofNclt(val){
    this.Respodent_nameArr_Nclt = [];
    let obj = {
      court_case_id:val.id
    }
    this.ncltCourtService.postData('allParties', obj).subscribe((res) => {
      let controler = this.form.get('responder') as FormArray;
      controler.controls[0].get('name').setValue(res.allParties.respondent)

      this.Respodent_nameArr_Nclt.push(res.allParties);
    })
  }


  // categorydata;
  caseTypeDetailsofNclt() {
    this.ncltCourtService
      .getval('caseTypeCategory')
      .subscribe((res) => {
        console.log(res, "res")
        this.categorydata = res.caseTypeCategory;
      });
  }


  locationCategorysOfNclt;
  locationCategoryDetailsofNclt() {
    this.ncltCourtService
      .getval('benchCategory')
      .subscribe((res) => {
        this.locationCategorysOfNclt = res.benchCategory;
        console.log(this.locationCategorysOfNclt, "this.locationCategorys")
      });
  }

  getServerName(val) {
    let obj = {
      state_code: val,
    };

    this.highcourt.postecourt("ws/serverName", obj).subscribe((res) => {
      if (res["status"] == true) {
        this.serverlist.push(...res["courtInfo"]);
      }
    });
  }

  getbenchnumber() {
    let obj = {
      state_code: "data",
    };

    this.highcourt
      .postecourt("ws/eCourtStateBench", obj)
      .subscribe((res2: any) => {

        this.fourmlist = res2.courtStateDistricts;
      });
  }

  handleshowcourttype
  getcasetypelist() {
    // console.log("djfn")
    let obj = {};
    this.court.post("ws/courtTypes", obj).subscribe((res: any) => {
      this.courttpelist = res.courtTypeInfo;
      this.handleshowcourttype = this.courtCaseDetails
      // if (this.courttpelist && !this.form.value.courtDetails.courtType) {
      if (this.handleshowcourttype.courtType && !this.form.value.courtDetails.courtType) {
        // this.form
        //   .get("courtDetails.courtType")
        //   .setValue(this.courttpelist[4].id);
        // this.form.get("courtDetails.courtName").setValue("distric Court");
        // this.courtcategorychnagehandler(this.courttpelist[3].id);

        this.form
          .get("courtDetails.courtType")
          .setValue(Number(this.handleshowcourttype.courtType));
        this.form.get("courtDetails.courtName").setValue(this.handleshowcourttype.courtname);
        this.courtcategorychnagehandler(this.handleshowcourttype.courtType);
        this.isCourtNumberVisible.next(true);


        if (this.handleshowcourttype.courtType == 1) {
          this.isCourtNumberVisible.next(true);
          console.log(this.handleshowcourttype.courtType, "Supreme Court")
        }
        if (this.handleshowcourttype.courtType == 2) {
          this.isCourtNumberVisible.next(true);
          this.statevisibel.next(true);
          this.isBenchNumberVisible.next(true);
          this.getstatedetailOfHighCourt();
          // let bench = this.form.value.courtDetails.benchNumber;
          this.getbenchandcasetype();
          console.log(this.handleshowcourttype.courtType, "high Court")
        }
        if (this.handleshowcourttype.courtType == 3) {
          this.getdistrictvalue();
          this.getstatedetailOfDistrictCourt();
          this.statevisibel.next(false);
          this.isdistrictVisible.next(true);
          this.isComplexNameVisible.next(true);
          console.log(this.handleshowcourttype.courtType, "district Court")
        }
        if (this.handleshowcourttype.courtType == 8) {
          this.isTribunalVisible.next(true);
          this.locationCategoryDetail()
          this.caseTypeDetail()
          // console.log(this.handleshowcourttype.courtType,"Tribunal")
        }
        if (this.handleshowcourttype.courtType == 9) {
          console.log("dkfgdfgh")
          this.isNcltVisible.next(true);
          this.locationCategoryDetailsofNclt()
          this.caseTypeDetailsofNclt()
          // console.log(this.handleshowcourttype.courtType,"Nclt")
        }
        if (this.handleshowcourttype.courtType == 4) {
          this.isConsumerForumVisible.next(true)
          this.isCourtNumberVisible.next(true);
          console.log(this.handleshowcourttype.courtType, "Consumer Court")
        }
        if (this.handleshowcourttype.courtType == 7) {
          this.customcourt = true;
          // console.log(this.handleshowcourttype.courtType,"Consumer Court")
        }

      } else if (this.courttpelist && this.form.value.courtDetails.courtType) {
        if (this.form.value.courtDetails.courtType == 1) {
          this.form
            .get("courtDetails.courtType")
            .setValue(this.courttpelist[0].id);
          this.form.get("courtDetails.courtName").setValue("Supreme Court");
          this.courtcategorychnagehandler(this.courttpelist[0].id);
          this.isCourtNumberVisible.next(true);
        }
        if (this.form.value.courtDetails.courtType == 2) {
          this.form
            .get("courtDetails.courtType")
            .setValue(this.courttpelist[1].id);
          this.form.get("courtDetails.courtName").setValue("High Court");
          this.isCourtNumberVisible.next(true);
          this.getstatedetailOfHighCourt();
          this.statevisibel.next(true);
          this.isBenchNumberVisible.next(true);
          let bench = this.form.value.courtDetails.benchNumber;
          this.getbenchandcasetype();
        }
        if (this.form.value.courtDetails.courtType == 3) {
          this.form
            .get("courtDetails.courtType")
            .setValue(this.courttpelist[2].id);
          this.form.get("courtDetails.courtName").setValue("District Court");
          this.getstatedetailOfDistrictCourt();
          this.statevisibel.next(false);
          this.getdistrictvalue();
          this.isdistrictVisible.next(true);
          this.isComplexNameVisible.next(true);
        }
        if (this.form.value.courtDetails.courtType == 8) {
          // console.log(this.handleshowcourttype.courtType,"Tribunal")
          this.form
            .get("courtDetails.courtType")
            .setValue(this.courttpelist[4].id);
          this.form.get("courtDetails.courtName").setValue("Tribunal");
        }
        if (this.form.value.courtDetails.courtType == 9) {
          console.log("jhdfgjkdgjhj")
          this.form
            .get("courtDetails.courtType")
            .setValue(this.courttpelist[5].id);
          this.form.get("courtDetails.courtName").setValue("Nclt");
        }
        if (this.form.value.courtDetails.courtType == 4) {
          this.form
            .get("courtDetails.courtType")
            .setValue(this.courttpelist[3].id);
          this.form.get("courtDetails.courtName").setValue("Consumer Court");
        }
      }
    });
    // this.grtformonselectcourt()


  }

  getdistrictvalue() {
    let ob = {
      state_code: this.form.value.courtDetails.state,
    };

    this.districtcourt
      .postecourt("ws/eGetCourtStatesDistrict", ob)
      .subscribe((res2: any) => {
        this.districtlist = res2.courtStateDistricts;
        let district = this.form.value.courtDetails.district;
        if (this.districtlist) {
          this.districtlist.forEach((el) => {
            if (el.code == district) {
              this.form.get("courtDetails.district").setValue(el);
              let obj = {
                // "district_court_id":"107"
                district_court_id: el.id,
              };

              this.districtcourt
                .postecourt("ws/eGetDistrictCourtComplex", obj)
                .subscribe((res2: any) => {

                  this.fourmlist = res2.caseDistrictCourtComplex;
                  let complexvalue = this.form.value.courtDetails.complexName;
                  if (this.fourmlist) {
                    this.fourmlist.forEach((el) => {
                      if (el.district_court_complex_val == complexvalue) {
                        this.form.get("courtDetails.complexName").setValue(el);

                        let obj = {
                          district_court_complex_id: el.id,
                          // "district_court_id":data.id
                        };

                        this.districtcourt
                          .postecourt("ws/eGetCourtCaseTypeCategory", obj)
                          .subscribe((res2: any) => {
                            this.categorydata = res2.courtCaseTypeCategory;
                            let typecase = this.form.value.caseType;

                            if (this.categorydata) {
                              this.categorydata.forEach((e) => {
                                if (e.case_type_id == typecase) {
                                  this.form
                                    .get("caseType")
                                    .setValue(e.case_type_id);
                                }
                              });
                            }
                          });
                      }
                    });
                  }
                  // this.form.get('courtDetails.complexName').setValue(complex.district_court_complex_val);
                });
            }
          });
        }
      });
  }
  private _filterPettionerSuggestions(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.petitionerNameSuggestions.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filteradvocateSuggestions(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.advocatesList.filter((option) =>
      option.firstName.toLowerCase().includes(filterValue)
    );
  }
  save() {
    this.trimFileObjs();
    if (this.mode === "create") {
      this.createCustomer();
    } else if (this.mode === "update") {
      this.updateCustomer();
    }
  }

  addadvocate() {
    if (environment.roleDetails) {
      let subModulesKey = [];
      if (
        environment.roleDetails["allowedAccesses"] &&
        environment.roleDetails["allowedAccesses"][
        this.moduleadvocateDetails.key
        ]
      ) {
        subModulesKey = Object.keys(
          environment.roleDetails["allowedAccesses"][
            this.moduleadvocateDetails.key
          ].subModules
        );
      }

      if (
        (environment.roleDetails.name == "super_admin" &&
          environment.roleDetails.is_custom_role == false) ||
        subModulesKey.indexOf(this.moduleadvocateDetails.subModules.create) !=
        -1
      ) {
        this.dialog
          .open(AdvocateFirmCreateUpdateComponent, {
            disableClose: true,
          })
          .afterClosed()
          .subscribe((user: User) => {
            if (user) {
              if (this.socketService.isSocketConnected()) {
                let tmpObj = this;
                this.socketObject.emit(
                  environment.socketEvents.createAdvocateFirm,
                  user,
                  function (socketResponse) {
                    if (socketResponse.status == "OK") {
                      tmpObj.socketObject.emit(
                        environment.socketEvents.listAdvocateFirm,
                        {},
                        function (socketResponse) {
                          if (socketResponse.status == "OK") {
                            tmpObj.advocatesList = socketResponse.data;
                            // console.log(tmpObj.advocatesList,"tmpObj.advocatesList")
                            // if (tmpObj.isUpdateMode()) {
                            //   // tmpObj.advocateChangeHandler();
                            // }
                          }
                        }
                      );
                      // tmpObj.snackbar.open('Successfully Created Advocate/Firm.', 'Okay', {
                      //   duration: 4000
                      // });
                      // tmpObj.refreshData(tmpObj);
                    } else {
                      // tmpObj.snackbar.open(socketResponse.message, 'Okay', {
                      //   duration: 4000
                      // });
                    }
                  }
                );
              }
            }
          });
      } else {
        // this.snackbar.open("You are not authorize to perform this operation.", "Okay", {
        //   duration: 4000
        // });
      }
    }
  }

  createCustomer() {
    if (this.form.invalid) {
      this.snackBar.open("Please provide all required information.", "CLOSE", {
        duration: 3000,
        horizontalPosition: "center",
      });
      return;
    }

    this.form.get("advocateAssigned").setValue(this.advocatevalue._id);
    if (this.form.value.courtDetails.benchNumber) {
      let benchnumber = this.form.value.courtDetails.benchNumber;
      this.form
        .get("courtDetails.benchNumber")
        .setValue(benchnumber.court_code);
    }
    if (this.form.value.courtDetails.complexName) {
      let complex = this.form.value.courtDetails.complexName;
      let district = this.form.value.courtDetails.district;
      this.form
        .get("courtDetails.complexName")
        .setValue(complex.district_court_complex_val);
      this.form.get("courtDetails.district").setValue(district.code);
    }

    if (this.form.value.courtDetails.courtName == 'District Court') {
      this.form.value.courtDetails.state = this.statecode
      this.form.value.courtDetails.district = this.districtcode
      this.form.value.courtDetails.court_status = this.districtStatus
    }

    const customer = this.form.value;

    this.dialogRef.close(customer);
  }

  updateCustomer() {
    if (this.form.invalid) {
      this.snackBar.open("Please provide all required information.", "CLOSE", {
        duration: 3000,
        horizontalPosition: "center",
      });
      return;
    }

    this.form.get("advocateAssigned").setValue(this.advocatevalue._id);
    if (this.form.value.courtDetails.benchNumber) {
      let benchnumber = this.form.value.courtDetails.benchNumber;
      this.form
        .get("courtDetails.benchNumber")
        .setValue(benchnumber.court_code);
    }

    if (this.form.value.courtDetails.courtName == 'District Court') {
      this.form.value.courtDetails.complexName = this.form.value.courtDetails.complexName.district_court_complex_val
      this.form.value.courtDetails.district = this.form.value.courtDetails.district.id
      this.form.value.courtDetails.state = this.form.value.courtDetails.state.id
    }

    const customer = this.form.value;

    customer.id = this.courtCaseDetails.id;

    this.dialogRef.close(customer);
  }

  isCreateMode() {
    return this.mode === "create";
  }

  isUpdateMode() {
    return this.mode === "update";
  }

  nextTab() {
    if (this.selectedTabIndex < this.totalTabGroups) {
      this.selectedTabIndex += 1;
    }
  }

  previousTab() {
    if (
      this.selectedTabIndex > 0 &&
      this.selectedTabIndex < this.totalTabGroups
    ) {
      this.selectedTabIndex -= 1;
    }
  }

  tabChanged(tabEvents: MatTabChangeEvent) {
    this.selectedTabIndex = tabEvents.index;
  }

  getAdvocateName(advocateFirmDetails) {
    let name = "N/A";
    if (advocateFirmDetails) {
      if (advocateFirmDetails.userType == "firm") {
        name = advocateFirmDetails.firmName;
      } else {
        name =
          advocateFirmDetails.firstName + " " + advocateFirmDetails.lastName;
      }
    }
    return name;
  }

  /* Petitioner Multiple Field: Start */
  // add new petitioner
  // for (let i = 0; i < this.courtCaseDetails.petitioner.length; i++) {
  //   this.addPetitionerArrUnit(this.courtCaseDetails.petitioner[i]);
  //   this.petitionerCountryChangeHandler(i);
  //   this.petitionerStateChangeHandler(i);
  // }
  public addPetitionerArrUnit(petitionerDetails) {
    const control = <FormArray>this.form.controls.petitioner;
    control.push(this.getPetitionerArrayUnit(petitionerDetails));
  }

  // remove petitioner
  public removePetitionerArrUnit(i: number) {
    const control = <FormArray>this.form.controls.petitioner;
    control.removeAt(i);
  }

  // remove all petitioner
  public removeAllPetitionerArrUnit() {
    const control = <FormArray>this.form.controls.petitioner;
    control.clear();
  }

  public getPetitionerArrayUnit(petitionerDetails) {
    return this.fb.group({
      name: [
        petitionerDetails.name || "",
        [Validators.required, this.customValidators.nameValidator],
      ],
      address: [
        petitionerDetails.address || "",
        [this.customValidators.addressValidator],
      ],
      country: [petitionerDetails.country || ""],
      state: [petitionerDetails.state || ""],
      city: [petitionerDetails.city || ""],
      zip: [
        petitionerDetails.zip || "",
        [this.customValidators.zipValidator],
      ],
    });
  }
  /* Petitioner Multiple Field: End */

  /* Contact Person Multiple Field: Start */
  // add new Contact Person
  public addContactPersonArrUnit(contactPersonDetails) {
    const control = <FormArray>this.form.controls.contactPerson;
    control.push(this.getContactPersonArrayUnit(contactPersonDetails));
  }

  // remove Contact Person
  public removeContactPersonArrUnit(i: number) {
    const control = <FormArray>this.form.controls.contactPerson;
    control.removeAt(i);
  }

  public getContactPersonArrayUnit(contactPersonDetails) {
    return this.fb.group({
      name: [
        contactPersonDetails.name || "",
        [Validators.required, this.customValidators.nameValidator],
      ],
      designation: [
        contactPersonDetails.designation
          ? contactPersonDetails.designation._id
          : "" || "",
        Validators.required,
      ],
      contactNumber: [
        contactPersonDetails.contactNumber || "",
        [Validators.required, Validators.pattern("[6-9]\\d{9}")],
      ],
      email: [contactPersonDetails.email || "", Validators.required],
      contactPersonParty: [
        contactPersonDetails.contactPersonParty || "",
        Validators.required,
      ],
    });
  }
  /* PContact Person Multiple Field: End */

  /* Responder Multiple Field: Start */
  // add new Responder
  public addResponderArrUnit(responderDetails) {
    const control = <FormArray>this.form.controls.responder;
    control.push(this.getResponderArrayUnit(responderDetails));
  }

  // remove Responder
  public removeResponderArrUnit(i: number) {
    const control = <FormArray>this.form.controls.responder;
    control.removeAt(i);
  }

  // remove all Responder
  public removeAllResponderArrUnit() {
    const control = <FormArray>this.form.controls.responder;
    control.clear();
  }

  public getResponderArrayUnit(responderDetails) {
    return this.fb.group({
      name: [
        responderDetails.name || "",
        [Validators.required, this.customValidators.nameValidator],
      ],
      address: [
        responderDetails.address || "",
        [this.customValidators.addressValidator],
      ],
      country: [responderDetails.country || ""],
      state: [responderDetails.state || ""],
      city: [responderDetails.city || ""],
      zip: [
        responderDetails.zip || "",
        [this.customValidators.zipValidator],
      ],
    });
  }
  /* Responder Multiple Field: End */

  /* Removing or adding constraints based on Contain Legal Notice or Not: Start */
  public hasLegalNoticeChangeHandler() {
    if (this.form.value.hasLegalNotice == true) {
      this.form.get("caseNature").clearValidators();
      this.form.get("caseNature").updateValueAndValidity();

      this.form.get("legalNotice").setValidators([Validators.required]);
      this.form.get("legalNotice").updateValueAndValidity();
    } else {
      this.form.get("legalNotice").clearValidators();
      this.form.get("legalNotice").updateValueAndValidity();

      this.form.get("caseNature").setValidators([Validators.required]);
      this.form.get("caseNature").updateValueAndValidity();
    }
  }
  /* Removing or adding constraints based on Contain Legal Notice or Not: End */

  /* Handler for Legal Notice Change and get that Legal Notice Details: Start */
  public legalNoticeChangeHandler() {
    if (this.form.value.legalNotice) {
      let legalNoticeDetails;
      for (let i = 0; i < this.legalNoticesList.length; i++) {
        if (this.form.value.legalNotice == this.legalNoticesList[i]._id) {
          legalNoticeDetails = this.legalNoticesList[i];
          break;
        }
      }

      this.autoFillDetailsFromLS(legalNoticeDetails);
    }
  }
  /* Handler for Legal Notice Change and get that Legal Notice Details: Start */

  /* auto fill details from selected Legal Notice : Start*/
  private autoFillDetailsFromLS(legalNoticeDetails) {
    if (legalNoticeDetails) {
      if (legalNoticeDetails.petitioner) {
        let hasAnyResponder = false,
          hasAnyPetitioner = false;

        this.removeAllPetitionerArrUnit();
        this.removeAllResponderArrUnit();

        for (let i = 0; i < legalNoticeDetails.petitioner.length; i++) {
          if (legalNoticeDetails.petitioner[i].partyType == "Noticer") {
            hasAnyPetitioner = true;
            this.addPetitionerArrUnit(legalNoticeDetails.petitioner[i]);
            this.petitionerCountryChangeHandler(
              (<FormArray>this.form.controls.petitioner).length - 1
            );
            this.petitionerStateChangeHandler(
              (<FormArray>this.form.controls.petitioner).length - 1
            );
          } else {
            hasAnyResponder = true;
            this.addResponderArrUnit(legalNoticeDetails.petitioner[i]);
            this.responderCountryChangeHandler(
              (<FormArray>this.form.controls.responder).length - 1
            );
            this.responderStateChangeHandler(
              (<FormArray>this.form.controls.responder).length - 1
            );
          }
        }

        if (!hasAnyPetitioner) {
          this.addPetitionerArrUnit({});
        }
        if (!hasAnyResponder) {
          this.addResponderArrUnit({});
        }
      }

      if (
        legalNoticeDetails.case_details &&
        legalNoticeDetails.case_details.regions
      ) {
        this.form.value.regions = legalNoticeDetails.case_details.regions;
        this.form
          .get("regions")
          .setValue(legalNoticeDetails.case_details.regions);
        this.regionsChangeHandler();
      }
      if (
        legalNoticeDetails.case_details &&
        legalNoticeDetails.case_details.project_site
      ) {
        this.form.value.project = legalNoticeDetails.case_details.project_site;
        this.form
          .get("project")
          .setValue(legalNoticeDetails.case_details.project_site);
      }
    }
  }
  /* auto fill details from selected Legal Notice : End*/

  regionsChangeHandler() {
    if (this.form.value.regions) {
      this.projectSitesList = [];
      if (this.allProjectSitesList[this.form.value.regions]) {
        this.projectSitesList = this.projectSitesList.concat(
          this.allProjectSitesList[this.form.value.regions]
        );
      }
    }
  }

  petitionerCountryChangeHandler(index) {
    if (this.form.value.petitioner[index].country) {
      let tmpObj = this;
      this.petitionerCitiesList[index] = [];
      this.socketObject.emit(
        environment.socketEvents.getStates,
        {
          country: this.form.value.petitioner[index].country,
        },
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.petitionerStatesList[index] = socketResponse.data;
          }
        }
      );
    }
  }

  petitionerStateChangeHandler(index) {
    if (this.form.value.petitioner[index].state) {
      let tmpObj = this;
      this.socketObject.emit(
        environment.socketEvents.getCities,
        {
          state: this.form.value.petitioner[index].state,
        },
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.petitionerCitiesList[index] = socketResponse.data;
          }
        }
      );
    }
  }

  responderCountryChangeHandler(index) {
    if (this.form.value.responder[index].country) {
      let tmpObj = this;
      this.responderCitiesList[index] = [];
      this.socketObject.emit(
        environment.socketEvents.getStates,
        {
          country: this.form.value.responder[index].country,
        },
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.responderStatesList[index] = socketResponse.data;
          }
        }
      );
    }
  }

  responderStateChangeHandler(index) {
    if (this.form.value.responder[index].state) {
      let tmpObj = this;
      this.socketObject.emit(
        environment.socketEvents.getCities,
        {
          state: this.form.value.responder[index].state,
        },
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.responderCitiesList[index] = socketResponse.data;
          }
        }
      );
    }
  }

  downloadFile(docDetails) {
    if (docDetails) {
      const link = document.createElement("a");
      link.setAttribute("target", "_blank");

      if (docDetails.fileObj) {
        link.setAttribute("href", URL.createObjectURL(docDetails.fileObj));
      } else {
        let accessToken = this.commonServices.getAccessToken();
        link.setAttribute(
          "href",
          environment.serviceConfiguration.url +
          docDetails.filePath.substring(1) +
          "?access_token=" +
          accessToken
        );
      }

      link.setAttribute("download", docDetails.name);
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  public removeSupportingDoc(supportDocIndex) {
    if (this.form.value.supporting_docs.docs && supportDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.supporting_docs.docs.splice(supportDocIndex, 1);
        this.form.value.supporting_docs.docs[supportDocIndex].is_deleted = true;
        this.form.value.supporting_docs.docs[supportDocIndex].deletedBy =
          this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  public removePetitionDoc(petitionDocIndex) {
    if (this.form.value.petition_docs.docs && petitionDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.petition_docs.docs.splice(petitionDocIndex, 1);
        this.form.value.petition_docs.docs[petitionDocIndex].is_deleted = true;
        this.form.value.petition_docs.docs[petitionDocIndex].deletedBy =
          this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  async petitionFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.docs = this.form.value.petition_docs.docs
          ? this.form.value.petition_docs.docs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadPetitionDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadPetitionDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadPetitionDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadPetitionDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadPetitionDocumentEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.docs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.docs")
                .setValue(tmpObj.form.value.petition_docs.docs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async supportingFileControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.docs = this.form.value.supporting_docs
          .docs
          ? this.form.value.supporting_docs.docs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadSupportingDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadSupportingDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadSupportingDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadSupportingDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadSupportingDocumentEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.docs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.docs")
                .setValue(tmpObj.form.value.supporting_docs.docs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async claimDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.claimentOrClaimDocs = this.form.value
          .petition_docs.claimentOrClaimDocs
          ? this.form.value.petition_docs.claimentOrClaimDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadClaimDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadClaimDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadClaimDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadClaimDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadClaimDocumentEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.claimentOrClaimDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.claimentOrClaimDocs")
                .setValue(tmpObj.form.value.petition_docs.claimentOrClaimDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async rejoinerDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.rejoinerDocs = this.form.value
          .petition_docs.rejoinerDocs
          ? this.form.value.petition_docs.rejoinerDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadRejoinerDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRejoinerDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRejoinerDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRejoinerDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRejoinerDocumentEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.rejoinerDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.rejoinerDocs")
                .setValue(tmpObj.form.value.petition_docs.rejoinerDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async admissionDenialDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.admissionDenialDocs = this.form.value
          .petition_docs.admissionDenialDocs
          ? this.form.value.petition_docs.admissionDenialDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadAdmissionDenialDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadAdmissionDenialDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadAdmissionDenialDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadAdmissionDenialDocEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadAdmissionDenialDocEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.admissionDenialDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.admissionDenialDocs")
                .setValue(tmpObj.form.value.petition_docs.admissionDenialDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async evidenceDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.evidenceDocs = this.form.value
          .petition_docs.evidenceDocs
          ? this.form.value.petition_docs.evidenceDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadEvidenceDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocNext);
        tmpObj.socketObject.on(
          environment.socketEvents.uploadEvidenceDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(environment.socketEvents.uploadEvidenceDocEnd);
        tmpObj.socketObject.on(
          environment.socketEvents.uploadEvidenceDocEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.evidenceDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.evidenceDocs")
                .setValue(tmpObj.form.value.petition_docs.evidenceDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async writtenArgumentDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.writtenArgumentDocs = this.form.value
          .petition_docs.writtenArgumentDocs
          ? this.form.value.petition_docs.writtenArgumentDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadWrittenArgumentDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadWrittenArgumentDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadWrittenArgumentDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadWrittenArgumentDocEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadWrittenArgumentDocEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.writtenArgumentDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.writtenArgumentDocs")
                .setValue(tmpObj.form.value.petition_docs.writtenArgumentDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async resCounterClaimDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.respondentOrCounterClaimDocs = this.form
          .value.supporting_docs.respondentOrCounterClaimDocs
          ? this.form.value.supporting_docs.respondentOrCounterClaimDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadRespClaimDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespClaimDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespClaimDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(environment.socketEvents.uploadRespClaimDocEnd);
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespClaimDocEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.respondentOrCounterClaimDocs.push(
              {
                name: fileObj.files[currentFileIndex].name,
                type: fileObj.files[currentFileIndex].type,
                size: fileObj.files[currentFileIndex].size,
                filePath: filePath,
              }
            );

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.respondentOrCounterClaimDocs")
                .setValue(
                  tmpObj.form.value.supporting_docs.respondentOrCounterClaimDocs
                );
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async surrejoinerDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.surrejoinerDocs = this.form.value
          .supporting_docs.surrejoinerDocs
          ? this.form.value.supporting_docs.surrejoinerDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadSurrejoinerDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadSurrejoinerDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadSurrejoinerDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadSurrejoinerDocEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadSurrejoinerDocEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.surrejoinerDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.surrejoinerDocs")
                .setValue(tmpObj.form.value.supporting_docs.surrejoinerDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async resAdmissionDenialDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.admissionDenialDocs = this.form.value
          .supporting_docs.admissionDenialDocs
          ? this.form.value.supporting_docs.admissionDenialDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadRespAdmissionDenialDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespAdminssionDenialDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespAdminssionDenialDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespAdminssionDenialDocEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespAdminssionDenialDocEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.admissionDenialDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.admissionDenialDocs")
                .setValue(
                  tmpObj.form.value.supporting_docs.admissionDenialDocs
                );
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async resEvidenceDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.evidenceDocs = this.form.value
          .supporting_docs.evidenceDocs
          ? this.form.value.supporting_docs.evidenceDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadRespEvidenceDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespEvidenceDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespEvidenceDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespEvidenceDocEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespEvidenceDocEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.evidenceDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.evidenceDocs")
                .setValue(tmpObj.form.value.supporting_docs.evidenceDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async respWrittenArgsDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.writtenArgumentDocs = this.form.value
          .supporting_docs.writtenArgumentDocs
          ? this.form.value.supporting_docs.writtenArgumentDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadRespWrittenArgDocSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespWrittenArgDocNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespWrittenArgDocNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadRespWrittenArgDocEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadRespWrittenArgDocEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.writtenArgumentDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.writtenArgumentDocs")
                .setValue(
                  tmpObj.form.value.supporting_docs.writtenArgumentDocs
                );
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  // async supportingFileControlChangeHandler(fileObj) {
  //   if (fileObj.files[0] && fileObj.files[0].name) {
  //     // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
  //     if (fileObj.files.length > 0) {

  //       this._layoutService.showLoader("Starting File Upload");
  //       this.form.value.supporting_docs.docs = this.form.value.supporting_docs.docs ? this.form.value.supporting_docs.docs : [];
  //       let reader;

  //       reader = new FileReader();
  //       let currentFileIndex = 0;
  //       let tmpObj = this;
  //       let totalNumberOfFiles = fileObj.files.length;
  //       let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //       let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //       reader.readAsArrayBuffer(slice);

  //       reader.onload = () => {
  //         // var arrayBuffer = reader.result;
  //         tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           data: reader.result
  //         }, (socketResponse) => {
  //           if (socketResponse.status == responseMessages.status.ok) {
  //           } else {
  //             tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
  //               duration: 3000,
  //               horizontalPosition: 'center'
  //             });
  //             tmpObj._layoutService.hideLoader();
  //           }
  //         });
  //       };

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
  //         tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
  //         let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
  //         reader.readAsArrayBuffer(slice);
  //       });

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
  //         tmpObj.form.value.supporting_docs.docs.push({
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           filePath: filePath
  //         });

  //         currentFileIndex++;
  //         if (currentFileIndex < totalNumberOfFiles) {
  //           fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //           slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //           reader.readAsArrayBuffer(slice);
  //         } else {
  //           tmpObj.form.get("supporting_docs.docs").setValue(tmpObj.form.value.supporting_docs.docs);
  //           tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
  //             duration: 3000,
  //             horizontalPosition: 'center'
  //           });
  //           tmpObj._layoutService.hideLoader();
  //         }
  //       });
  //     }
  //   }
  // }

  // async resCriminalStageFileControlChangeHandler(fileObj, currentIndex) {
  //   if (fileObj.files[0] && fileObj.files[0].name) {
  //     //this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
  //     if (fileObj.files.length > 0) {

  //       this._layoutService.showLoader("Starting File Upload");
  //       this.form.value.supporting_docs.crimimalCaseStages[currentIndex] = this.form.value.supporting_docs.crimimalCaseStages[currentIndex] ? this.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs : [];
  //       let reader;
  //       reader = new FileReader();
  //       let currentFileIndex = 0;
  //       let tmpObj = this;
  //       let totalNumberOfFiles = fileObj.files.length;
  //       let fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //       let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //       reader.readAsArrayBuffer(slice);

  //       reader.onload = () => {
  //         //var arrayBuffer = reader.result;
  //         tmpObj.socketObject.emit(environment.socketEvents.uploadSupportingDocumentSlice, {
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           data: reader.result
  //         }, (socketResponse) => {
  //           if (socketResponse.status == responseMessages.status.ok) {
  //           } else {
  //             tmpObj.snackBar.open('Oop! Unable to upload file.', 'Okay', {
  //               duration: 3000,
  //               horizontalPosition: 'center'
  //             });
  //             tmpObj._layoutService.hideLoader();
  //           }
  //         });
  //       };

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentNext);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentNext, (data) => {
  //         tmpObj._layoutService.showLoader(data.uploadedPercent + " of " + fileSize + " Uploaded", (currentFileIndex + 1) + " of " + totalNumberOfFiles + " files uploading.");
  //         let place = data.currentSlice * 1000000, slice = fileObj.files[currentFileIndex].slice(place, place + Math.min(1000000, fileObj.files[currentFileIndex].size - place));
  //         reader.readAsArrayBuffer(slice);
  //       });

  //       tmpObj.socketObject.off(environment.socketEvents.uploadSupportingDocumentEnd);
  //       tmpObj.socketObject.on(environment.socketEvents.uploadSupportingDocumentEnd, (filePath) => {
  //         tmpObj.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs.push({
  //           name: fileObj.files[currentFileIndex].name,
  //           type: fileObj.files[currentFileIndex].type,
  //           size: fileObj.files[currentFileIndex].size,
  //           filePath: filePath
  //         });
  //         currentFileIndex++;
  //         if (currentFileIndex < totalNumberOfFiles) {
  //           fileSize = (fileObj.files[currentFileIndex].size / (1024 * 1024)).toFixed(2);
  //           slice = fileObj.files[currentFileIndex].slice(0, 1000000);
  //           reader.readAsArrayBuffer(slice);
  //         } else {
  //           tmpObj.form.get("supporting_docs.crimimalCaseStages").setValue(tmpObj.form.value.supporting_docs.crimimalCaseStages);
  //           tmpObj.snackBar.open('Successfully uploaded file.', 'Okay', {
  //             duration: 3000,
  //             horizontalPosition: 'center'
  //           });
  //           tmpObj._layoutService.hideLoader();
  //         }
  //       });
  //     }
  //   }
  // }

  trimFileObjs() {
    if (
      this.form.value.supporting_docs.docs &&
      this.form.value.supporting_docs.docs.length > 0
    ) {
      for (let i = 0; i < this.form.value.supporting_docs.docs.length; i++) {
        if (this.form.value.supporting_docs.docs[i].fileObj) {
          delete this.form.value.supporting_docs.docs[i]["fileObj"];
        }
      }
    }

    if (
      this.form.value.petition_docs.docs &&
      this.form.value.petition_docs.docs.length > 0
    ) {
      for (let i = 0; i < this.form.value.petition_docs.docs.length; i++) {
        if (this.form.value.petition_docs.docs[i].fileObj) {
          delete this.form.value.petition_docs.docs[i]["fileObj"];
        }
      }
    }

    if (this.form.value.ordersDocs && this.form.value.ordersDocs.length > 0) {
      for (let i = 0; i < this.form.value.ordersDocs.length; i++) {
        if (this.form.value.ordersDocs[i].fileObj) {
          delete this.form.value.ordersDocs[i]["fileObj"];
        }
      }
    }

    if (
      this.form.value.miscellaneousDocs &&
      this.form.value.miscellaneousDocs.length > 0
    ) {
      for (let i = 0; i < this.form.value.miscellaneousDocs.length; i++) {
        if (this.form.value.miscellaneousDocs[i].fileObj) {
          delete this.form.value.miscellaneousDocs[i]["fileObj"];
        }
      }
    }
  }

  // advocateChangeHandler() {
  //   if (this.form.value.advocateAssigned) {
  //     let subAdvocates;
  //     for (let i = 0; i < this.advocatesList.length; i++) {
  //       if (this.advocatesList[i]._id == this.form.value.advocateAssigned
  //         && this.advocatesList[i].userType == 'firm') {

  //         subAdvocates = this.advocatesList[i].firmAdvocates;
  //         break;
  //       }
  //     }

  //     if (subAdvocates) {
  //       this.subAdvocatesList = subAdvocates;
  //       this.isFirmSelected$.next(true);
  //     } else {
  //       this.subAdvocatesList = [];
  //       this.isFirmSelected$.next(false);
  //       this.form.get("subAdvocateAssigned").setValue(this.form.value.advocateAssigned);
  //     }
  //   } else {
  //     this.subAdvocatesList = [];
  //     this.isFirmSelected$.next(false);
  //     this.form.get("subAdvocateAssigned").setValue(this.form.value.advocateAssigned);
  //   }
  // }

  isFileViewAllowed() {
    let isAllowed = false;

    if (
      this.courtCaseDetails &&
      environment.roleDetails &&
      this.userDetails &&
      this.moduleDetails
    ) {
      // console.log(this.mode)
      if (environment.roleDetails.name == "super_admin") {
        isAllowed = true;
      } else if (this.mode == "create") {
        if (
          this.commonServices.isUserAllowedToCreateCourtCase(this.moduleDetails)
        ) {
          isAllowed = true;
        }
      } else if (this.mode == "update") {
        // console.log("update part")
        if (
          this.commonServices.isUserAllowedToEditCourtCase(
            this.courtCaseDetails,
            this.moduleDetails
          )
        ) {
          isAllowed = true;
        }
      } else {
        // console.log(this.courtCaseDetails,this.userDetails._id)
        if (this.courtCaseDetails.createdBy == this.userDetails._id) {
          //If logged in user is the one who created this court case
          let subModulesKey = [];
          if (
            environment.roleDetails["allowedAccesses"] &&
            environment.roleDetails["allowedAccesses"][this.moduleDetails.key]
          ) {
            subModulesKey = Object.keys(
              environment.roleDetails["allowedAccesses"][this.moduleDetails.key]
                .subModules
            );
          }
          if (subModulesKey.indexOf(this.moduleDetails.subModules.view) != -1) {
            isAllowed = true;
          }
        } else {
          //If not the user who created this court case
          if (
            this.tmpInHouseUsersList &&
            this.tmpInHouseUsersList.indexOf(this.userDetails._id) != -1
          ) {
            isAllowed = true;
          } else {
            //if user allowed by sharing the details internally

            if (this.sharingDetails) {
              if (
                this.sharingDetails.permissionLevel ==
                environment.permissionLevels.editableWithFiles.key ||
                this.sharingDetails.permissionLevel ==
                environment.permissionLevels.viewOnlyWithFiles.key
              ) {
                isAllowed = true;
              }
            }
          }
        }
      }
    }

    return isAllowed;
  }

  initSharingDetails(tmpObj: CourtCasesCreateUpdateComponent) {
    if (tmpObj.courtCaseDetails && tmpObj.courtCaseDetails.internalSharing) {
      for (let i = 0; i < tmpObj.courtCaseDetails.internalSharing.length; i++) {
        if (
          tmpObj.courtCaseDetails.internalSharing[i].userId ==
          tmpObj.userDetails._id
        ) {
          tmpObj.sharingDetails = tmpObj.courtCaseDetails.internalSharing[i];
          break;
        }
      }
    }
  }

  isUserAllowedForFile(fileDetails) {
    let isAllowed = false;

    if (
      environment.roleDetails &&
      environment.roleDetails.name == "super_admin"
    ) {
      isAllowed = true;
    } else if (this.mode == "create") {
      if (
        this.commonServices.isUserAllowedToCreateCourtCase(this.moduleDetails)
      ) {
        isAllowed = true;
      }
    } else if (this.mode == "update") {
      if (
        this.commonServices.isUserAllowedToEditCourtCase(
          this.courtCaseDetails,
          this.moduleDetails
        )
      ) {
        isAllowed = true;
      }
    } else if (
      this.tmpInHouseUsersList &&
      this.tmpInHouseUsersList.indexOf(this.userDetails._id) != -1
    ) {
      isAllowed = true;
    } else if (
      fileDetails &&
      this.sharingDetails &&
      this.sharingDetails.filePermissions
    ) {
      if (fileDetails.filePath) {
        for (let i = 0; i < this.sharingDetails.filePermissions.length; i++) {
          if (
            this.sharingDetails.filePermissions[i] &&
            this.sharingDetails.filePermissions[i].filePath ==
            fileDetails.filePath
          ) {
            if (
              this.sharingDetails.filePermissions[i].permissions.view == true
            ) {
              isAllowed = true;
            }
            break;
          }
        }
      }
    }

    return isAllowed;
  }

  previewPdfFile(docDetails) {
    if (docDetails) {
      if (docDetails.type == "application/pdf") {
        let filePath: string = docDetails.filePath;
        filePath = filePath.replace(
          "./",
          environment.serviceConfiguration.url + "/viewPDF/"
        );
        let accessToken = this.commonServices.getAccessToken();
        window.open(
          filePath + "?access_token=" + accessToken,
          "Legal App PDF View",
          "directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=" +
          screen.availWidth +
          ",height=" +
          screen.availHeight
        );
      } else {
        this.snackBar.open("Oops! You can't preview this file.", "Okay", {
          duration: 2000,
        });
      }
    }
  }

  //   grtformonselectcourt(){
  //     console.log("fngkj")
  //     if (this.courtCaseDetails.courtType == 1) {
  //       console.log(this.courtCaseDetails.courtType,"supreme court")
  //     }else if (this.courtCaseDetails.courtType == 2) {
  //       this.isBenchNumberVisible.next(true);
  //       this.statevisibel.next(true);
  //       this.isCourtNumberVisible.next(true);
  //       console.log(this.courtCaseDetails.courtType,"high court")
  //     }else{
  //       console.log("running else block")
  //     }
  //     // if (this.socketService.isSocketConnected()) {
  //     //   let tmpObj = this;

  //     //   this.socketObject.emit(
  //     //     environment.socketEvents.listCourtsForCourtType,
  //     //     { courtType: this.courtCaseDetails.courtType },
  //     //     function (socketResponse) {
  //     //       if (socketResponse.status == "OK") {
  //     //         tmpObj.courtsList = socketResponse.data;
  //     //       }
  //     //     }
  //     //   );
  //     // }

  // }



  courtTypeChangeHandler() {
    // tribunal data empty
    this.applicant_nameArr = [];
    this.Respodent_nameArr = [];
    this.applicant_legal_representative_name = [];
    this.respondent_legal_representative_name = [];
    this.firstHearingDetail = {};
    this.lastHearingDetail = {};
    this.nextHearingDetail = {};
    this.orderHistory = [];
    this.caseHistory = [];
    this.iasOtherApplication = [];
    this.connectedCases = [];

    // supreme court data empty
    this.earlierCourtDetail = []
    this.caseIndexingInfo = []
    this.taggedMatter = []
    this.caseListingDates = []
    this.caseInterlocutoryApplicationDocuments = []
    this.caseOtherApplicationDocuments = []
    this.noticedetails = []
    this.caseCaseDefects = []
    this.judgementdetails = []
    this.courtMentionInfo = []
    this.courtRestorationInfo = []
    this.courtDropNoteInfo = []
    this.caseAppearanceInfo = []
    this.officereportdetails = []
    this.courtSimilarities = []
    this.courtCaveat = []
    this.caseCourtFees = []

    // consumerforum data empty
    this.caseHistoryOrdersData = []

    // high court e-court data empty
    this.casestatusdetails = []
    this.casepetionordetails = []
    this.caseresponedentdetails = []
    this.caseactdetails = []
    this.casehistorydetails = []
    this.caseordersdetails = []
    this.iadetail = []
    this.caseConversionInfo = []
    this.caseMainMatterInfo = []
    this.caseSubMatterInfo = []
    this.caseLinkedInfo = []
    this.caseObjectionInfo = []
    this.caseDocumentInfo = []
    this.caseFIRInfo = []
    this.caseCategoryInfo = []
    this.caseActsInfo = []

    // high court server data empty
    this.caseOrderData = []
    this.caseDetailOrdersData = []

    this.caseStatusArr = []
    this.petAndResAdvData = [];
    this.categoryDetailsArr = [];
    this.iaDetailsData = [];
    this.lowerCourtDetailsArr = []
    this.crimeDetailsArr = []
    this.listingHistoryData = [];
    this.ordersJudgementData = [];

    this.officeInformationData = []
    this.miscInformationData = []
    this.objectionsData = []
    this.casePaperIndexData = []
    this.connectedMattersData = []
    this.applicationCasesData = []
    this.listingDatesData = []
    this.lowerCourtDetailArr = []
    this.appealCourtDetailsArr = []

    this.caseStatushimachal = {};
    this.partyDetailhimachal = {};
    this.actsDetailhimachal = []
    this.mainMattershimachal = []
    this.connectedCaseshimachal = []
    this.applicationCaseshimachal = []
    this.listingDateshimachal = []
    this.orderJudgementhimachal = []
    this.lowerCourtDetailhimachal = []

    // district court data empty
    this.casestatusdetailArr = []
    this.casepetionordetail = []
    this.caseresponedentdetail = []
    this.caseactdetail = []
    this.caseMainMatterInfos = []
    this.caseSubMatterInfos = []
    this.caseProcessInfo = []
    this.caseSubordinateCourtInfo = []
    this.casehistorydetail = []
    this.casefirDetailInfo = []
    this.caseordersdetail = []
    this.caseTransferInfo = []

    this.customcourt = false;
    console.log(this.form.value.courtDetails, this.form.value.courtDetails.courtType, "ldghfklj")
    if (
      this.form.value.courtDetails &&
      this.form.value.courtDetails.courtType
    ) {
      this.form.value.courtDetails.courtName = "";

      this.isBenchNumberVisible.next(false);
      this.isComplexNameVisible.next(false);
      this.statevisibel.next(false);
      this.isdistrictVisible.next(false);
      this.isTribunalVisible.next(false);
      this.isNcltVisible.next(false);
      this.isConsumerForumVisible.next(false)
      this.ConsumerForumStateCommission = false
      this.ConsumerForumDistrictCommission = false
      this.showBenchNumber = false
      this.caseOfMaharasytra = false
      this.caseCategoryOfHimachal = false
      this.showothersearchinput = false





      if (this.form.value.courtDetails.courtType == 1) {
        this.form
          .get("courtDetails.state")
          .setValue("5f33cb6b8a106e0e8a6a947b");
        this.form.get("courtDetails.courtName").setValue("Supreme Court");
        this.courtStateChangeHandler();
        this.courtcategorychnagehandler(this.form.value.courtDetails.courtType);
        this.form
          .get("courtDetails.district")
          .setValue("5f33d0048a106e0e8a6a9841");
      } else if (this.form.value.courtDetails.courtType == 2) {
        this.isBenchNumberVisible.next(true);
        this.statevisibel.next(true);
        this.selectedValue = ''
        this.getstatedetailOfHighCourt()

        this.form.get("courtDetails.courtName").setValue("High Court");
        this.getbenchnumber();
      } else if (this.form.value.courtDetails.courtType == 3) {
        this.isdistrictVisible.next(true);
        this.isComplexNameVisible.next(true);
        this.getstatedetailOfDistrictCourt()
        this.form.get("courtDetails.courtName").setValue("District Court");
      } else if (this.form.value.courtDetails.courtType == 8) {
        this.isTribunalVisible.next(true);
        this.locationCategoryDetail()
        this.caseTypeDetail()
        this.form.get("courtDetails.courtName").setValue("Tribunal");
      } else if (this.form.value.courtDetails.courtType == 4) {
      } else if (this.form.value.courtDetails.courtType == 9) {
        this.isNcltVisible.next(true);
        this.locationCategoryDetailsofNclt()
        this.caseTypeDetailsofNclt() 
        this.form.get("courtDetails.courtName").setValue("Nclt");
      } else if (this.form.value.courtDetails.courtType == 4) {
        this.isCourtNumberVisible.next(true);
        this.isConsumerForumVisible.next(true)
        this.ConsumerForumNCDRC = true
        this.caseTypeCategoryDetails()
        this.form.get("courtDetails.courtName").setValue("Consumer Court");
      } else if (this.form.value.courtDetails.courtType == 7) {
        this.customcourt = true;
        this.form.get("courtDetails.courtName").setValue("Custom Courts");
      }
      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;

        this.socketObject.emit(
          environment.socketEvents.listCourtsForCourtType,
          { courtType: tmpObj.form.value.courtDetails.courtType },
          function (socketResponse) {
            if (socketResponse.status == "OK") {
              tmpObj.courtsList = socketResponse.data;
            }
          }
        );
      }
    }
  }











  courtTypeChangeHandler2() {
    if (
      this.form.value.courtDetails &&
      this.form.value.courtDetails.courtType
    ) {
      this.isBenchNumberVisible.next(false);
      this.isComplexNameVisible.next(false);
      this.statevisibel.next(false);
      this.isdistrictVisible.next(false);
      this.isTribunalVisible.next(false);
      this.isNcltVisible.next(false);
      this.isConsumerForumVisible.next(false)
      this.ConsumerForumStateCommission = false
      this.ConsumerForumDistrictCommission = false
      this.showBenchNumber = false
      this.caseOfMaharasytra = false
      this.caseCategoryOfHimachal = false
      this.showothersearchinput = false
      if (this.form.value.courtDetails.courtType == environment.custom_court_id_collection.supreme_court_custom_id) {
        this.form
          .get("courtDetails.state")
          .setValue("5f33cb6b8a106e0e8a6a947b");
        this.form.get("courtDetails.courtName").setValue("Custom Court");
        this.courtStateChangeHandler();
        this.courtcategorychnagehandler(1);
        this.form
          .get("courtDetails.district")
          .setValue("5f33d0048a106e0e8a6a9841");
      } else if (this.form.value.courtDetails.courtType == environment.custom_court_id_collection.high_court_custom_id) {
        this.isBenchNumberVisible.next(false);
        this.statevisibel.next(true);
        this.selectedValue = ''
        this.showBenchNumber = true
        this.getstatedetailOfHighCourt()

        this.form.get("courtDetails.courtName").setValue("Custom Court");
        this.getbenchnumber()
      } else if (this.form.value.courtDetails.courtType == environment.custom_court_id_collection.district_court_custom_id) {
        this.isdistrictVisible.next(true);
        this.isComplexNameVisible.next(true);
        this.getstatedetailOfDistrictCourt()
        this.form.get("courtDetails.courtName").setValue("Custom Court");
      } else if (this.form.value.courtDetails.courtType == environment.custom_court_id_collection.tribunal_court_custom_ide_court) {
        this.isTribunalVisible.next(true);
        this.locationCategoryDetail()
        this.caseTypeDetail()
        this.form.get("courtDetails.courtName").setValue("Custom Court");
      } else if (this.form.value.courtDetails.courtType == environment.custom_court_id_collection.consumer_forum_court_custom_id) {
        this.isCourtNumberVisible.next(true);
        this.isConsumerForumVisible.next(true)
        this.ConsumerForumNCDRC = true
        this.caseTypeCategoryDetails()
        this.form.get("courtDetails.courtName").setValue("Custom Court");
      } else {
        this.categorydata = this.customcategorydata;
      }
      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;

        this.socketObject.emit(
          environment.socketEvents.listCourtsForCourtType,
          { courtType: tmpObj.form.value.courtDetails.courtType },
          function (socketResponse) {
            if (socketResponse.status == "OK") {
              tmpObj.courtsList = socketResponse.data;
            }
          }
        );
      }
    }
  }

  toggleChecked = true
  toggleChecked2 = false
  toggleChecked3 = false
  ConsumerForumNCDRC = false
  ConsumerForumStateCommission = false
  ConsumerForumDistrictCommission = false
  commission_type = "B"
  getValue(val) {
    if (val == "Ncdrc") {
      this.ConsumerForumNCDRC = true
      this.ConsumerForumStateCommission = false
      this.ConsumerForumDistrictCommission = false
      this.commission_type = "B"
    } else if (val == "stateCommission") {
      this.getStateCommissionState()
      this.ConsumerForumNCDRC = false
      this.ConsumerForumStateCommission = true
      this.ConsumerForumDistrictCommission = false
      this.commission_type = "C"
    } else if (val == "districtCommission") {
      this.getDistrictCommissionState()
      this.ConsumerForumNCDRC = false
      this.ConsumerForumStateCommission = false
      this.ConsumerForumDistrictCommission = true
      this.commission_type = "E"
    }
  }

  StateCommissionState;
  getStateCommissionState() {
    this.consumerCourtService
      .getData('stateCommissionCategory')
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.StateCommissionState = res.stateCommissionCategory;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  DistrictCommissionState;
  getDistrictCommissionState() {
    this.consumerCourtService
      .getData('districtCommissionStatesCategory')
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.DistrictCommissionState = res.districtCommissionStatesCategory;


            if (this.DistrictCommissionState) {
              let DistrictCommissionState = this.form.value.courtDetails.state
              this.DistrictCommissionState.forEach(element => {
                if (element.district_commission_state_id == DistrictCommissionState) {
                  this.form.get('state_code').setValue(element.district_commission_state_id)

                  this.getDistrictCommissionId(element)
                }
              });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  districtData;
  state_code;
  getDistrictCommissionId(data) {
    let obj = {
      id: data.id,
    };
    this.consumerCourtService
      .postData('districtCommissionDistrictsCategory', obj)
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.districtData = res.districtCommissionDistrictsCategory;

            if (this.districtData) {
              let districtData = this.form.value.courtDetails.district
              this.districtData.forEach(element => {
                if (element.district_commission_district_id == districtData) {
                  this.form.get('district_code').setValue(element.district_commission_district_id)
                }
              });
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  arr = []
  caseTypeCategoryDetails() {
    this.arr = []
    this.consumerCourtService
      .getData('caseTypeCategory')
      .subscribe((res) => {

        res['caseTypeCategory'].forEach(element => {
          let obj = {};
          obj['case_type_name'] = element.case_type
          obj['case_type_id'] = element.case_type
          obj['status'] = element.status
          this.arr.push(obj)
        });

        this.categorydata = this.arr
      });
  }


  courtcategorychnagehandler(id) {
    let obj = {
      court_type_id: id,
    };
    this.court.post("ws/courtCategories", obj).subscribe((res2: any) => {
      this.categorydata = res2.caseCourtCategory;
      if (this.categorydata) {
        let casetpe = this.form.value.caseType;
        this.categorydata.forEach((el) => {
          if (el.case_type_id == casetpe) {
            this.form.get("caseType").setValue(el.case_type_id);
          }
        });

      }
    });
  }

  caseDetail
  caesDetailData
  caseStatusOfConsumer
  t1; t2
  caseHistoryOrdersData = []
  autofetch() {
    this.countOfTab = 9;
    if (this.form.value.courtDetails.courtType == 1) {
      if (
        this.form.value.caseType &&
        this.form.value.caseNumber &&
        this.form.value.caseYear
      ) {
        let obj = {
          case_type: this.form.value.caseType,
          case_no: this.form.value.caseNumber,
          case_year: this.form.value.caseYear,
          flag: "0",
        };
        this.loading = true;
        this.court.post("ws/courtCase", obj).subscribe((res2: any) => {
          this.loading = false;
          if (res2.status) {
            let newdata;
            this.caseid = res2.caseInfo.id;

            if (this.caseid) {
              this.getnoticedetails();
              this.getjudgementdetails();
              this.getearlierCourtdetails();
              this.getlistingDatedetails();
              this.getinterlocutoryApplicationDocumentdetails();
              this.getApplicationDocumentdetails();
              this.getcourtFeedetails();
              this.getcaseDefectdetails();
              this.getcourtSimilaritydetails();
              this.getcourtTaggedMatterdetails();
              this.getcourtCaveatsdetails();
              this.getofficereportdetail();
              this.getrestorationdetail();
              this.getindexingdetail();
              this.getapperencedetail();
              this.getdropnotedetail();
              this.getmentionmemodetail();

            }

            let obj = {
              diary_no: res2.caseInfo.diary_only,
              diary_year: res2.caseInfo.diary_year,
              flag: 1,
            };

            let tmpObj = this;

            this.court
              .post("ws/autoUpdateCourtCase", obj)
              .subscribe((res2: any) => {
                newdata = res2.autoUpdateCourtCase[0];
                if (newdata) {
                  this.mathchdata = "Case fetched successfully from the Court";

                  (this.form.get('petitioner') as FormArray).controls.forEach(key => key.get('name').setValue(newdata.petitioner))

                  this.form
                    .get("courtDetails.courtNumber")
                    .setValue(newdata.court_number);

                  (this.form.get('responder') as FormArray).controls.forEach(key => key.get('name').setValue(newdata.respondent))

                  this.form
                    .get("courtDetails.judgeName")
                    .setValue(newdata.judge_name);
                  this.form
                    .get("caseName")
                    .setValue(newdata.case_name);
                  this.form
                    .get("caseDescription")
                    .setValue(newdata.case_description);
                  this.form
                    .get("courtDetails.courtName")
                    .setValue("Supreme Court");
                  this.form.get("diary_number").setValue(newdata.diary_only);
                  this.form.get("diary_year").setValue(newdata.diary_year);
                  let filedate = new Date(parseInt(newdata.case_filing_date));
                  this.form.get("next_hearing_date").setValue(newdata.next_hearing_date);
                  this.form.get("fillingDate").setValue(filedate);

                  this.loading = false;
                  setTimeout(() => {
                    this.totalTabGroups = this.countOfTab
                    console.log(this.totalTabGroups, this.countOfTab)
                    this.mathchdata = "";
                  }, 1000);
                }
              });
          } else {
            this.notfoundmsg = "Case not fetched from the Court";
            setTimeout(() => {
              this.notfoundmsg = "";
            }, 2000);
          }
        });
      } else {
        this.invalidmsg = "Please Provide all information";
        setTimeout(() => {
          this.invalidmsg = "";
        }, 1000);
      }
    } else if (this.form.value.courtDetails.courtType == 2) {
      if (this.serverTypeValue == 'e-Court Server' && this.form.value.courtDetails.state && this.form.value.courtDetails.benchNumber.court_code && this.form.value.caseType && this.form.value.caseNumber && this.form.value.caseYear) {
        this.invalidmsg = ''
        let obj = {
          state_code: this.form.value.courtDetails.state,

          court_code: this.form.value.courtDetails.benchNumber.court_code,
          case_type_category_id: this.form.value.caseType,
          case_no: this.form.value.caseNumber,
          case_year: this.form.value.caseYear,
          saved_by: "1",
        };
        console.log(obj, "res2 of court case");
        this.loading = true;
        this.highcourt
          .postecourt("ws/eHighCourtKeywordSearchParty", obj)
          .subscribe((res2: any) => {
            console.log(res2, "res2 of court case");
            if (res2) {
              if (res2.partyName) {
                let courtcaselist = res2.partyName[0];
                if (courtcaselist) {
                  let obj = {
                    state_code: courtcaselist.state_code,
                    court_code: courtcaselist.court_code,
                    case_no_year: courtcaselist.case_no_year,
                    case_cino: courtcaselist.case_cino,
                  };
                  this.highcourt.postecourt("ws/eHighCourtCase", obj).subscribe((res: any) => {
                    if (res) {

                      this.title = res.courtInfo.establishment_name


                      this.caseid = res.courtInfo.id

                      if (this.caseid) {

                        this.getcasestatusdetais();
                        this.getpetinordetais();
                        this.getrepondentdetais();
                        this.getactdetais();
                        this.getCaseHistorydetais();
                        this.getCaseordersdetais();
                        this.getiadetail();
                        this.getcaseconversiondetail();
                        this.getmainmetterdetail();
                        this.getsubmetterdetail();
                        this.getlinkedcasedetail();
                        this.getobjectiondetail();
                        this.getdocumentdetail();
                        this.getfirdetail();
                        this.getcategorydetail();
                        this.getactdetal();
                      }

                      this.highcourt.postecourt("ws/eAutoUpdateHighCourtCase", obj).subscribe((res2: any) => {
                        if (res2.autoUpdateHighCourtCase) {
                          this.mathchdata =
                            "Case fetched successfully from the Court";

                          let courtscaselist =
                            res2.autoUpdateHighCourtCase[0];
                          this.form
                            .get("courtDetails.courtNumber")
                            .setValue(courtscaselist.court_number);
                          this.form
                            .get("courtDetails.judgeName")
                            .setValue(courtscaselist.judge_name);
                          this.form
                            .get("courtDetails.courtName")
                            .setValue(this.serverTypeValue);
                          this.form
                            .get("caseName")
                            .setValue(courtscaselist.case_name);
                          this.form.get("court_code").setValue(courtscaselist.court_code);
                          this.form.get("case_no_year").setValue(courtscaselist.case_no_year);
                          this.form.get("case_cino").setValue(courtscaselist.case_cino);
                          this.form.get("next_hearing_date").setValue(courtscaselist.next_hearing_date);
                          this.form.get("caseDescription").setValue(courtscaselist.case_description ? courtscaselist.case_description : null);
                          let control = this.form.get('petitioner') as FormArray;
                          control.controls.forEach(key => key.get('name').setValue(courtscaselist.petitioner))
                          let control2 = this.form.get('responder') as FormArray;
                          control2.controls.forEach(key => key.get('name').setValue(courtscaselist.respondent))

                          this.form
                            .get("cnr_number")
                            .setValue(courtscaselist.cnr_number);
                          let filedate = new Date(parseInt(courtscaselist.filing_date));
                          this.form.get("fillingDate").setValue(filedate);

                          this.loading = false;
                          setTimeout(() => {
                            this.totalTabGroups = this.countOfTab
                            this.mathchdata = "";
                          }, 2000);
                        }
                      });
                    }
                  });
                }
              } else {
                this.loading = false
                this.notfoundmsg = "Case not fetched from the Court";
                setTimeout(() => {
                  this.notfoundmsg = "";
                }, 2000);
              }
            } else {
            }
          });
      } else if (this.serverTypeValue == 'High Court Server' && this.form.value.courtDetails.state && this.form.value.caseType && this.form.value.caseNumber && this.form.value.caseYear) {
        this.invalidmsg = ''
        if (this.stateCode == 26) {
          this.loading = true
          let obj = {
            case_no: this.form.value.caseNumber,
            case_type: this.form.value.caseType,
            case_year: this.form.value.caseYear,
            search_by: "1"

          }
          this.delhihighcourtservice.postecourt("caseList", obj).subscribe((res) => {
            if (res['status']) {
              let pno_no = res['partyName'][0].pno_no
              let caseInfo = res['partyName'][0]
              console.log(caseInfo)

              if (pno_no) {
                let obj = {
                  pno: pno_no
                }

                this.delhihighcourtservice.postecourt("courtCases", obj).subscribe((res2) => {
                  if (res2['status']) {
                    let court_case_id = res2['partyName'][0].id

                    if (court_case_id) {
                      let obj = {
                        court_case_id: court_case_id
                      }

                      this.caseOrders(obj)
                      this.caseDetailOrders(obj)
                      this.form.get('fillingDate').clearValidators();
                      this.form.get('fillingDate').updateValueAndValidity();

                      this.form
                        .get("courtDetails.courtName")
                        .setValue(this.serverTypeValue);
                      this.form.get("pno_no").setValue(caseInfo.pno_no);
                      this.form.get("next_hearing_date").setValue(caseInfo.next_hearing_date);
                      this.form.get("courtDetails.state").setValue(this.stateCode);
                      this.form.get("caseName").setValue(`${caseInfo.petitioner} Vs. ${caseInfo.respondent}`);

                      let control = this.form.get('petitioner') as FormArray;
                      control.controls.forEach(key => key.get('name').setValue(caseInfo.petitioner))
                      let control2 = this.form.get('responder') as FormArray;
                      control2.controls.forEach(key => key.get('name').setValue(caseInfo.respondent))

                      this.mathchdata = "Case fetched successfully from the Court";

                      this.loading = false;
                      setTimeout(() => {
                        this.totalTabGroups = this.countOfTab
                        this.mathchdata = "";
                      }, 1000);
                    }
                  }
                })
              }
            } else {
              this.loading = false

              this.notfoundmsg = "Case not fetched from the Court";
              setTimeout(() => {
                this.notfoundmsg = "";
              }, 2000);
            }

          })
        } else if (this.stateCode == 13) {
          let obj = {
            case_no: this.form.value.caseNumber,
            case_type: this.form.value.caseType,
            case_year: this.form.value.caseYear,
            court_bench: this.form.value.court_bench,
            search_by: "1"
          }

          this.loading = true;
          this.allahabadhighcourtservice.postData("caseList", obj).subscribe(
            (res) => {
              if (res.status) {

                let caselist = res.caseList[0]
                let obj2 = {
                  case_cino: caselist.case_cino,
                  court_bench: caselist.court_bench,
                  search_by: "1"
                }

                this.allahabadhighcourtservice.postData("caseDetails", obj2).subscribe((res2) => {
                  if (res2.status) {
                    let casedetails = res2.caseDetails[0];

                    let obj3 = {
                      court_case_id: casedetails.id
                    }
                    this.ordersJudgement(obj3)
                    this.listingHistory(obj3)
                    this.crimeDetails(obj3)
                    this.lowerCourtDetails(obj3)
                    this.iaDetails(obj3)
                    this.categoryDetails(obj3)
                    this.petAndResAdv(obj3)
                    this.caseStatus(obj3)

                    let filedate = new Date(parseInt(casedetails.filing_date));
                    this.form.get("fillingDate").setValue(filedate);
                    this.form.get("courtDetails.state").setValue(this.stateCode);
                    this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);
                    this.form.get("case_cino").setValue(caselist.case_cino);
                    this.form.get("court_bench").setValue(caselist.court_bench);
                    this.form.get("caseName").setValue(caselist.case_name);
                    let control = this.form.get('petitioner') as FormArray;
                    control.controls.forEach(key => key.get('name').setValue(caselist.petitioner))
                    let control2 = this.form.get('responder') as FormArray;
                    control2.controls.forEach(key => key.get('name').setValue(caselist.respondent))

                    this.mathchdata = "Case fetched successfully from the Court";

                    this.loading = false;
                    setTimeout(() => {
                      this.totalTabGroups = this.countOfTab;
                      this.mathchdata = "";
                    }, 1000);
                  }
                })

              } else {
                this.loading = false

                this.notfoundmsg = "Case not fetched from the Court";
                setTimeout(() => {
                  this.notfoundmsg = "";
                }, 2000);
              }
            }
          );
        } else if (this.stateCode == 1) {
          this.loading = true
          this.form.value.court_code = this.court_bench_id
          this.form.value.case_side = this.case_side_id

          let obj = {
            case_no: this.form.value.caseNumber,
            case_type: this.form.value.caseType,
            case_year: this.form.value.caseYear,
            case_category: this.form.value.case_category,
            court_code: this.form.value.court_code,
            case_side: this.form.value.case_side
          }

          this.bombayhighcourtservice.postData("caseDetails", obj).subscribe((res) => {

            if (res.status) {
              this.caseDetail = res.caseDetails[0]
              let court_case_id = res.caseDetails[0].id

              if (court_case_id) {
                this.officeInformation(court_case_id)
                this.miscInformation(court_case_id)
                this.objections(court_case_id)
                this.casePaperIndex(court_case_id)
                this.connectedMatters(court_case_id)
                this.applicationCases(court_case_id)
                this.listingDates(court_case_id)
                this.LowerCourt(court_case_id)

                let filedate = new Date(parseInt(this.caseDetail.filing_date));
                this.form.get("fillingDate").setValue(filedate);
                this.form.get("courtDetails.state").setValue(this.stateCode);
                this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);

                this.form.get("case_category").setValue(this.caseDetail.case_category);
                this.form.get("next_hearing_date").setValue(this.caseDetail.next_date);
                this.form.get("case_no").setValue(this.caseDetail.case_no);
                this.form.get("case_type").setValue(this.caseDetail.case_type);
                this.form.get("case_side").setValue(this.caseDetail.case_side);
                this.form.get("court_code").setValue(this.caseDetail.court_code);

                this.form.get("caseName").setValue(`${this.caseDetail.petitioner} Vs. ${this.caseDetail.respondent}`);
                let control = this.form.get('petitioner') as FormArray;
                control.controls.forEach(key => key.get('name').setValue(this.caseDetail.petitioner))
                let control2 = this.form.get('responder') as FormArray;
                control2.controls.forEach(key => key.get('name').setValue(this.caseDetail.respondent))
                this.mathchdata = "Case fetched successfully from the Court";

                this.loading = false;
                setTimeout(() => {
                  this.totalTabGroups = this.countOfTab;
                  this.mathchdata = "";
                }, 1000);
              }
            } else {
              this.loading = false

              this.notfoundmsg = "Case not fetched from the Court";
              setTimeout(() => {
                this.notfoundmsg = "";
              }, 2000);
            }
          })
        } else if (this.stateCode == 5) {
          this.loading = true

          let obj = {
            case_no: this.form.value.caseNumber,
            case_type: this.form.value.caseType,
            case_year: this.form.value.caseYear,
            case_category: this.form.value.case_category
          }

          this.himalchalhighcourtservice.postData("caseDetails", obj).subscribe((res) => {
            if (res.status) {
              this.caseDetail = res.caseDetails;
              let court_case_id = res.caseDetails.id

              if (court_case_id) {
                this.caseStatusHimachal({ court_case_id: court_case_id })
                this.partyDetailHimachal({ court_case_id: court_case_id })
                this.actsDetailHimachal({ court_case_id: court_case_id })
                this.mainMattersHimachal({ court_case_id: court_case_id })
                this.connectedCasesHimachal({ court_case_id: court_case_id })
                this.applicationCasesHimachal({ court_case_id: court_case_id })
                this.listingDatesHimachal({ court_case_id: court_case_id })
                this.orderJudgementHimachal({ court_case_id: court_case_id })
                this.lowerCourtDetailHimachal({ court_case_id: court_case_id })

                let filedate = new Date(parseInt(this.caseDetail.filing_date));
                this.form.get("fillingDate").setValue(filedate);
                this.form.get("courtDetails.state").setValue(this.stateCode);
                this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);
                this.form.get("case_category").setValue(this.caseDetail.case_category);
                this.form.get("next_hearing_date").setValue(this.caseDetail.next_hearing_date);
                this.form.get("case_cino").setValue(this.caseDetail.cnr_no);
                this.form.get("registration_no").setValue(this.caseDetail.registration_no);

                this.mathchdata = "Case fetched successfully from the Court";

                this.loading = false;
                setTimeout(() => {
                  this.totalTabGroups = this.countOfTab;
                  this.mathchdata = "";
                }, 2000);
              } else {
                this.loading = false
                this.notfoundmsg = "Case not fetched from the Court";
                setTimeout(() => {
                  this.notfoundmsg = "";
                }, 2000);
              }
            }
          })
        } else if (this.stateCode == 22) {
          this.loading = true

          let obj = {
            case_no: this.form.value.caseNumber,
            case_type: this.form.value.caseType,
            case_year: this.form.value.caseYear,
            search_by: "1"
          }

          this.punjabservice.postData("caseList", obj).subscribe((res) => {
            if (res.status) {
              let caseList = res.caseList[0]
              let obj = {
                case_type: caseList.case_type,
                case_no: caseList.case_no,
                case_year: caseList.case_year
              }
              this.punjabservice.postData('caseDetails', obj).subscribe((res) => {
                if (res.status) {
                  let court_case_id = res.caseDetails.id
                  if (court_case_id) {
                    this.relatedCases({ court_case_id: court_case_id })
                    this.judgmentDetails({ court_case_id: court_case_id })
                    this.lowerCourtDetailsPunjab({ court_case_id: court_case_id })
                    this.copyPetition({ court_case_id: court_case_id })
                    this.firDetails({ court_case_id: court_case_id })
                    this.impungOrderDetail({ court_case_id: court_case_id })
                    this.detailOfAppeals({ court_case_id: court_case_id })
                    this.caseListingDetails({ court_case_id: court_case_id })

                    this.form.get('fillingDate').clearValidators();
                    this.form.get('fillingDate').updateValueAndValidity();
                    let control = this.form.get('petitioner') as FormArray;
                    control.controls.forEach(key => key.get('name').setValue(caseList['petitioner']))
                    let control2 = this.form.get('responder') as FormArray;
                    control2.controls.forEach(key => key.get('name').setValue(caseList['respondent']))
                    this.form.get("courtDetails.state").setValue(this.stateCode);
                    this.form.get("courtDetails.courtName").setValue(this.serverTypeValue);
                    this.form.get("caseName").setValue(caseList.case_name);

                    this.mathchdata = "Case fetched successfully from the Court";

                    this.loading = false;
                    setTimeout(() => {
                      this.totalTabGroups = this.countOfTab;
                      this.mathchdata = "";
                    }, 2000);
                  }
                } else {
                  this.loading = false
                  this.notfoundmsg = "Case not fetched from the Court";
                  setTimeout(() => {
                    this.notfoundmsg = "";
                  }, 2000);
                }
              }, (err) => {
                this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
                  duration: 3000,
                  horizontalPosition: 'center'
                });
                this.loading = false;
              })
            }
          }, (err) => {
            this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center'
            });
            this.loading = false;
          })
        }
      }
      else {
        this.invalidmsg = "Please Provide all information";
      }
    } else if (this.form.value.courtDetails.courtType == 3) {
      this.form.value.courtDetails.state = this.statecode
      this.form.value.courtDetails.district = this.districtcode
      this.form.value.courtDetails.complexName = this.district_court_complex_val

      if (
        this.form.value.courtDetails.state &&
        this.form.value.courtDetails.district &&
        this.form.value.courtDetails.complexName &&
        this.form.value.caseType &&
        this.form.value.caseNumber &&
        this.form.value.caseYear
      ) {
        this.invalidmsg = ''
        let ob = {
          state_code: this.form.value.courtDetails.state,
          district_code: this.form.value.courtDetails.district,
          forum_id:
            this.form.value.courtDetails.complexName,
          case_type_category_id: this.form.value.caseType,
          case_no: this.form.value.caseNumber,
          case_year: this.form.value.caseYear,
          saved_by: "1",
        };

        this.loading = true;
        this.districtcourt
          .postecourt("ws/eCourtSearchParty", ob)
          .subscribe((res2: any) => {
            if (res2.partyName) {
              let courtcaselist = res2.partyName[0];

              let obes = {
                state_code: courtcaselist.state_code,
                district_code: courtcaselist.district_code,
                forum_id: courtcaselist.forum_id,
                case_no_year: courtcaselist.case_no_year,
                case_cino: courtcaselist.case_cino,
              };

              this.districtcourt
                .postecourt("ws/eCourtCase", obes)
                .subscribe((res: any) => {

                  this.caseid = res.courtInfo.id

                  if (this.caseid) {
                    this.getcasestatusdetail()
                    this.getpetinordetail();
                    this.getrepondentdetail();
                    this.getactdetail();
                    this.getmainmetterdetails()
                    this.getsubmetterdetails()
                    this.getCaseHistorydetail()
                    this.getfirdetails()
                    this.getCaseordersdetail()
                    this.getsubordinatedetail();
                    this.getcasetransferdetail();
                    this.getprocessdetail();

                    this.districtcourt.postecourt("ws/eAutoUpdateCourtCase", obes).subscribe((res3: any) => {
                      if (res3.status) {
                        let detaildata;
                        if (res3.autoUpdateHighCourtCase) {
                          detaildata = res3.autoUpdateHighCourtCase;
                          console.log(detaildata, 'detaildata')
                          this.form
                            .get("courtDetails.courtNumber")
                            .setValue(detaildata.court_number);
                          this.form
                            .get("courtDetails.judgeName")
                            .setValue(detaildata.judge_name);
                          this.form
                            .get("courtDetails.courtName")
                            .setValue("District Court");
                          this.form
                            .get("cnr_number")
                            .setValue(detaildata.cnr_number);
                          let filedate = new Date(parseInt(detaildata.filing_date));
                          this.form.get("fillingDate").setValue(filedate);

                          this.form.get("case_no_year").setValue(detaildata.case_no_year);
                          this.form.get("case_cino").setValue(detaildata.case_cino);
                          this.form.get("next_hearing_date").setValue(detaildata.next_hearing_date);

                          this.form.get("caseName").setValue(detaildata.case_name);
                          let control = this.form.get('petitioner') as FormArray;
                          control.controls.forEach(key => key.get('name').setValue(detaildata.petitioner))
                          let control2 = this.form.get('responder') as FormArray;
                          control2.controls.forEach(key => key.get('name').setValue(detaildata.respondent))

                          this.mathchdata = "Case fetched successfully from the Court";

                          this.loading = false;
                          setTimeout(() => {
                            this.totalTabGroups = this.countOfTab;
                            this.mathchdata = "";
                          }, 2000);
                        }
                      }
                    });
                  }



                  // this.loading = false;
                });
            } else {
              this.notfoundmsg = "Case not fetched from the Court";
              this.loading = false
              setTimeout(() => {
                this.notfoundmsg = "";
              }, 2000);
            }
          });
      } else {
        this.invalidmsg = "Please Provide all information";
      }
    } else if (this.form.value.courtDetails.courtType == 8) {
      if (this.form.value.caseNumber && this.form.value.caseType && this.form.value.caseYear && this.form.value.location) {
        this.invalidmsg = ''

        let obj = {
          case_number: this.form.value.caseNumber,
          case_type: this.form.value.caseType,
          case_year: this.form.value.caseYear,
          location: this.form.value.location,
          search_by: "1",
          party_type: "",
          case_status: "",
          party_name: "",
          filing_no: "",
          advocate_name: "",
        }

        this.loading = true
        this.tribunalService.postData('caseList', obj).subscribe((res) => {
          if (res.status) {
            let caesList = res.caseList[0];
            let caseTitle = caesList.case_title.split("VS");
            this.t1 = caseTitle[0];
            this.t2 = caseTitle[1];

            let obj = {
              location: caesList.location,
              filing_no: caesList.filing_no
            };

            this.tribunalService.postData('caseDetails', obj).subscribe(res => {
              console.log(res.caseDetails[0], 'caseDetails')
              let court_case_id = res.caseDetails[0].id
              this.caesDetailData = res.caseDetails[0]

              if (court_case_id) {
                this.partyDetail(court_case_id)
                this.legalRepresentative(court_case_id)
                this.getfirstHearingDetail(court_case_id)
                this.getLastHearingDetail(court_case_id)
                this.getNextHearingDetail(court_case_id)
                this.getCaseHistory(court_case_id)
                this.getorderHistory(court_case_id)
                this.getIasOtherApplication(court_case_id)
                this.getConnectedCases(court_case_id)

                let filedate = new Date(parseInt(this.caesDetailData.date_of_filing));
                this.form.get("fillingDate").setValue(filedate);
                this.form.get("courtDetails.courtName").setValue('Tribunal');

                this.form.get("filing_no").setValue(caesList.filing_no);
                this.form.get("location").setValue(caesList.location);
                this.form.get("case_no_year").setValue(caesList.case_year);

                this.form.get("caseName").setValue(caesList.case_title);

                this.mathchdata = "Case fetched successfully from the Court";

                this.loading = false;
                setTimeout(() => {
                  this.totalTabGroups = this.countOfTab;
                  console.log(this.totalTabGroups, this.countOfTab)
                  this.mathchdata = "";
                }, 2000);
              }
            })
          } else {
            this.notfoundmsg = "Case not fetched from the Court";
            this.loading = false
            setTimeout(() => {
              this.notfoundmsg = "";
            }, 2000);
          }
        })
      } else {
        this.invalidmsg = "Please Provide all information";
      }
    } else if (this.form.value.courtDetails.courtType == 4) {
      if (this.form.value.caseNumber && this.form.value.caseType && this.form.value.caseYear) {
        this.loading = true
        let obj = {
          case_no: this.form.value.caseNumber,
          case_type: this.form.value.caseType,
          case_year: this.form.value.caseYear,
          commission_type: this.commission_type,
        }

        if (obj.commission_type == "B") {
          obj['state_code'] = "0"
          obj['district_code'] = "0"
        } else if (obj.commission_type == "C") {
          obj['district_code'] = "0"
          obj['state_code'] = this.form.value.state_code
        } else if (obj.commission_type == "E") {
          obj['district_code'] = this.form.value.district_code
          obj['state_code'] = this.form.value.state_code
        }

        console.log(obj)
        this.consumerCourtService.postData('caseStatus', obj).subscribe((res) => {
          if (res.status) {
            this.caseStatusOfConsumer = res.caseStatus[0]
            console.log(this.caseStatusOfConsumer)

            let obj2 = {
              id: this.caseStatusOfConsumer.id,
            };
            this.caseHistoryOrdersData = []
            this.consumerCourtService.postData('caseHistoryOrders', obj2).subscribe(

              (res) => {
                if (res.status) {
                  this.caseHistoryOrdersData.push(...res.caseHistoryOrders);

                  let filedate = new Date(parseInt(this.caseStatusOfConsumer.filing_date));
                  this.form.get("fillingDate").setValue(filedate);

                  let control = this.form.get('petitioner') as FormArray;
                  control.controls.forEach(key => key.get('name').setValue(this.caseStatusOfConsumer.complainant))
                  let control2 = this.form.get('responder') as FormArray;
                  control2.controls.forEach(key => key.get('name').setValue(this.caseStatusOfConsumer.respondent))

                  this.form.get("case_no").setValue(this.caseStatusOfConsumer.case_no);
                  this.form.get("commission_type").setValue(this.caseStatusOfConsumer.commission_type);
                  this.form.get("caseName").setValue(`${this.caseStatusOfConsumer.complainant} vs. ${this.caseStatusOfConsumer.respondent}`);
                  this.form.get("next_hearing_date").setValue(this.caseStatusOfConsumer.next_hearing);

                  this.mathchdata = "Case fetched successfully from the Court";

                  this.loading = false;
                  setTimeout(() => {
                    this.totalTabGroups = this.countOfTab + 1;
                    this.mathchdata = "";
                  }, 2000);
                }
              }
            );
          }
        })
      }
    }
  }


  viewDetailOfOrderHistyory(data) {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    obj["case_history_id"] = data.id;
    obj["t1"] = this.t1;
    obj["t2"] = this.t2;

    this.dialog.open(TribunalCaseHistoryDetailComponent, {
      height: "480px",
      width: "700px",
      autoFocus: false,
      data: obj,
    });
  }


  partyDetail(court_case_id) {
    this.applicantDetail(court_case_id);
    this.respondentDetail(court_case_id);
  }

  applicant_nameArr = [];
  count2 = 0;
  countHandler2 = 0;
  applicantDetail(court_case_id) {
    this.applicant_nameArr = [];
    this.tribunalService
      .postData('applicantName', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.applicant_nameArr.push(...res.applicantName);

          if (this.countHandler2 == 0) {
            this.countOfTab = this.countOfTab + 1;
            this.countHandler2 += 1;
          }

          if (this.count2 == 0 && this.mode == 'create') {
            if (this.applicant_nameArr.length > 1) {
              let control = this.form.get('petitioner') as FormArray;
              control.controls[0].get('name').setValue(this.applicant_nameArr[0]['applicant_name'])

              for (let i = 1; i < this.applicant_nameArr.length; i++) {
                this.addPetitionerArrUnit(this.applicant_nameArr[i]);

                const control = <FormArray>this.form.controls.petitioner;
                control.controls[i].get('name').setValue(this.applicant_nameArr[i]['applicant_name'])
              }
            } else {
              let control = this.form.get('petitioner') as FormArray;
              control.controls.forEach(key => key.get('name').setValue(this.applicant_nameArr[0]['applicant_name']))
            }
            this.count2 += 1;
          }
        }
      });
  }

  Respodent_nameArr = [];
  count1 = 0;
  respondentDetail(court_case_id) {
    this.Respodent_nameArr = [];
    this.tribunalService
      .postData('respondantName', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.Respodent_nameArr.push(...res.respondantName);

          if (this.countHandler2 == 0) {
            this.countOfTab = this.countOfTab + 1;
            this.countHandler2 += 1;
          }

          if (this.count1 == 0 && this.mode == 'create') {
            if (this.Respodent_nameArr.length > 1) {
              let control = this.form.get('responder') as FormArray;
              control.controls[0].get('name').setValue(this.Respodent_nameArr[0]['respondant_name'])

              for (let i = 1; i < this.Respodent_nameArr.length; i++) {
                this.addResponderArrUnit(this.Respodent_nameArr[i]);

                const control = <FormArray>this.form.controls.responder;
                control.controls[i].get('name').setValue(this.Respodent_nameArr[i]['respondant_name'])
              }
            } else {
              let control = this.form.get('responder') as FormArray;
              control.controls.forEach(key => key.get('name').setValue(this.Respodent_nameArr[0]['respondant_name']))
            }
            this.count1 += 1;
          }
        }
      });
  }

  countHandler3 = 0;
  legalRepresentative(court_case_id) {
    this.applicantLegalRepresentative(court_case_id)
    this.respondentLegalRepresentative(court_case_id)
  }

  applicant_legal_representative_name = [];
  applicantLegalRepresentative(court_case_id) {
    this.applicant_legal_representative_name = [];
    this.tribunalService
      .postData('applicantLegalRepresentative', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.applicant_legal_representative_name.push(
            ...res.applicantLegalRepresentative
          );
          if (this.countHandler3 == 0) {
            this.countOfTab = this.countOfTab + 1;
            this.countHandler3 += 1;
          }
        }
      });
  }

  respondent_legal_representative_name = [];
  respondentLegalRepresentative(court_case_id) {
    this.respondent_legal_representative_name = [];
    this.tribunalService
      .postData('respondentLegalRepresentative', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.respondent_legal_representative_name.push(
            ...res.respondentLegalRepresentative
          );
          if (this.countHandler3 == 0) {
            this.countOfTab = this.countOfTab + 1;
            this.countHandler3 += 1;
          }
        }
      });
  }


  firstHearingDetail = {};
  getfirstHearingDetail(court_case_id) {
    this.firstHearingDetail = {};
    this.tribunalService
      .postData('firstHearingDetails', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.firstHearingDetail = { ...res.firstHearingDetails[0] };
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }

  lastHearingDetail = {};
  getLastHearingDetail(court_case_id) {
    this.lastHearingDetail = {};
    this.tribunalService
      .postData('lastHearingDetails', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.lastHearingDetail = { ...res.lastHearingDetails[0] };
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }

  nextHearingDetail = {};
  getNextHearingDetail(court_case_id) {
    this.nextHearingDetail = {};
    this.tribunalService
      .postData('nextHearingDetails', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.nextHearingDetail = { ...res.nextHearingDetails[0] };
          this.form.get("next_hearing_date").setValue(this.nextHearingDetail['hearing_date']);
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }

  caseHistory = [];
  getCaseHistory(court_case_id) {
    this.caseHistory = [];
    this.tribunalService
      .postData('caseHistory', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.caseHistory.push(...res.caseHistory);
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }

  orderHistory = [];
  getorderHistory(court_case_id) {
    this.orderHistory = [];
    this.tribunalService
      .postData('orderHistory', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.orderHistory.push(...res.orderHistory);
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }

  iasOtherApplication = [];
  getIasOtherApplication(court_case_id) {
    this.iasOtherApplication = [];
    this.tribunalService
      .postData('iasOtherApplication', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.iasOtherApplication.push(...res.iasOtherApplication);
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }

  connectedCases = [];
  getConnectedCases(court_case_id) {
    this.connectedCases = [];
    this.tribunalService
      .postData('connectedCases', { court_case_id: court_case_id })
      .subscribe((res) => {
        if (res.status) {
          this.connectedCases.push(...res.connectedCases);
          this.countOfTab = this.countOfTab + 1;
        }
      });
  }



  officeInformationData = [];
  officeInformation(court_case_id) {
    this.officeInformationData = []
    let obj = {
      id: court_case_id
    }

    this.bombayhighcourtservice.postData('officeInformation', obj).subscribe((res) => {
      if (res.status) {
        this.officeInformationData.push(...res.officeInformation)
        this.countOfTab = this.countOfTab + 1;
      }
    })
  }

  subject_category: any
  miscInformationData = [];
  miscInformation(court_case_id) {
    this.miscInformationData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('miscInformation', obj).subscribe((res) => {
      if (res.status) {
        this.miscInformationData.push(...res.miscInformation)
        this.countOfTab = this.countOfTab + 1;

        if (this.miscInformationData[0].subject_category != null || this.miscInformationData[0].subject_category != '') {
          this.subject_category = this.miscInformationData[0].subject_category
        }
      }
    })
  }

  objectionsData = [];
  objections(court_case_id) {
    this.objectionsData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('objections', obj).subscribe((res) => {
      if (res.status) {
        this.objectionsData.push(...res.objections)
        this.countOfTab = this.countOfTab + 1;
      }
    })
  }

  casePaperIndexData = [];
  casePaperIndex(court_case_id) {
    this.casePaperIndexData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('casePaperIndex', obj).subscribe((res) => {
      if (res.status) {
        this.casePaperIndexData.push(...res.casePaperIndex)
        this.countOfTab = this.countOfTab + 1;
      }
    })
  }

  connectedMattersData = [];
  connectedMatters(court_case_id) {
    this.connectedMattersData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('connectedMatters', obj).subscribe((res) => {
      if (res.status) {
        this.connectedMattersData.push(...res.connectedMatters)
        this.countOfTab = this.countOfTab + 1;
      }
    })
  }

  applicationCasesData = [];
  applicationCases(court_case_id) {
    this.applicationCasesData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('applicationCases', obj).subscribe((res) => {
      if (res.status) {
        this.applicationCasesData.push(...res.applicationCases)
        this.countOfTab = this.countOfTab + 1;
      }
    })
  }

  listingDatesData = [];
  listingDates(court_case_id) {
    this.listingDatesData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('listingDates', obj).subscribe((res) => {
      if (res.status) {
        this.listingDatesData.push(...res.listingDates)
        this.countOfTab = this.countOfTab + 1;
      }
    })
  }

  countHandler1 = 0;
  LowerCourt(court_case_id) {
    this.lowerCourtDetail(court_case_id)
    this.appealCourtDetails(court_case_id)
  }

  lowerCourtDetailArr = [];
  lowerCourtDetail(court_case_id) {
    this.lowerCourtDetailsData = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('lowerCourtDetails', obj).subscribe((res) => {
      if (res.status == true) {
        this.lowerCourtDetailArr.push(...res.lowerCourtDetails)
        if (this.countHandler1 == 0) {
          this.countOfTab = this.countOfTab + 1;
          this.countHandler1 += 1;
        }
      }
    })
  }

  appealCourtDetailsArr = [];
  appealCourtDetails(court_case_id) {
    this.appealCourtDetailsArr = [];
    let obj = {
      court_case_id: court_case_id
    }

    this.bombayhighcourtservice.postData('appealCourtDetails', obj).subscribe((res) => {
      if (res.status) {
        this.appealCourtDetailsArr.push(...res.appealCourtDetails)
        if (this.countHandler1 == 0) {
          this.countOfTab = this.countOfTab + 1;
          this.countHandler1 += 1;
        }
      }
    })
  }



  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert('No data Found')
    }
  }



  caseOrderData = []
  caseOrders(obj) {
    this.caseOrderData = []
    this.delhihighcourtservice.postecourt("caseOrders", obj).subscribe((res) => {
      if (res['status']) {
        this.caseOrderData.push(...res['partyName'])
        this.countOfTab = this.countOfTab + 1
      }
    })
  }

  caseDetailOrdersData = []
  caseDetailOrders(obj) {
    this.caseDetailOrdersData = []
    this.delhihighcourtservice.postecourt("caseDetailOrders", obj).subscribe((res) => {
      if (res['status']) {
        this.caseDetailOrdersData.push(...res['partyName'])
        this.countOfTab = this.countOfTab + 1
      }
    })
  }



  caseStatusArr = []
  caseStatusData;
  caseStatus(obj) {
    this.caseStatusArr = []
    this.allahabadhighcourtservice.postData("caseStatus", obj).subscribe(
      (res) => {
        if (res.status) {
          this.caseStatusArr.push(...res.caseStatus);
          this.caseStatusData = res.caseStatus[0]
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }

  petAndResAdvData = [];
  petAndResAdv(obj) {
    this.petAndResAdvData = [];
    this.allahabadhighcourtservice.postData("petAndResAdv", obj).subscribe(
      (res) => {
        if (res.status) {
          this.petAndResAdvData.push(...res.petAndResAdv);
          this.countOfTab = this.countOfTab + 1
        }
      }
    );
  }

  categoryDetailsArr = [];
  categoryDetailsData;
  categoryDetails(obj) {
    this.categoryDetailsArr = [];
    this.allahabadhighcourtservice.postData("categoryDetails", obj).subscribe(
      (res) => {
        if (res.status) {
          this.categoryDetailsArr.push(...res.categoryDetails);
          this.categoryDetailsData = res.categoryDetails[0]
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }

  iaDetailsData = [];
  iaDetails(obj) {
    this.iaDetailsData = [];
    this.allahabadhighcourtservice.postData("iaDetails", obj).subscribe(
      (res) => {
        if (res.status) {
          this.iaDetailsData.push(...res.iaDetails);
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }

  lowerCourtDetailsArr = []
  lowerCourtDetailsData
  lowerCourtDetails(obj) {
    this.lowerCourtDetailsArr = []
    this.allahabadhighcourtservice.postData("lowerCourtDetails", obj).subscribe(
      (res) => {
        if (res.status) {
          this.lowerCourtDetailsArr.push(...res.lowerCourtDetails);
          this.lowerCourtDetailsData = res.lowerCourtDetails[0]
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }

  crimeDetailsArr = []
  crimeDetailsData
  crimeDetails(obj) {
    this.crimeDetailsArr = []
    this.allahabadhighcourtservice.postData("crimeDetails", obj).subscribe(
      (res) => {
        if (res.status) {
          this.crimeDetailsArr.push(...res.crimeDetails);
          this.crimeDetailsData = res.crimeDetails[0]
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }

  listingHistoryData = [];
  listingHistory(obj) {
    this.listingHistoryData = [];
    this.allahabadhighcourtservice.postData("listingHistory", obj).subscribe(
      (res) => {
        if (res.status) {
          this.listingHistoryData.push(...res.listingHistory);
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }

  ordersJudgementData = [];
  ordersJudgement(obj) {
    this.ordersJudgementData = [];
    this.allahabadhighcourtservice.postData("ordersJudgement", obj).subscribe(
      (res) => {
        if (res.status) {
          this.ordersJudgementData.push(...res.ordersJudgement);
          this.countOfTab = this.countOfTab + 1
        }
      },
    );
  }



  getnoticedetails() {
    this.noticedetails = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtNotice", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.noticedetails.push(...res2.caseCourtNotices);
      }
    });
  }
  getofficereportdetail() {
    this.officereportdetails = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/officeReport", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.officereportdetails.push(...res2.officeReportInfo);
      }
    });
  }

  getindexingdetail() {
    this.caseIndexingInfo = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseIndexing", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseIndexingInfo.push(...res2.caseIndexingInfo);
      }
    });
  }

  getapperencedetail() {
    this.caseAppearanceInfo = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseAppearance", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseAppearanceInfo.push(...res2.caseAppearanceInfo);
      }
    });
  }

  getdropnotedetail() {
    this.courtDropNoteInfo = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtDropNotes", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.courtDropNoteInfo.push(...res2.courtDropNoteInfo);
      }
    });
  }

  getmentionmemodetail() {
    this.courtMentionInfo = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtMentionMemo", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.courtMentionInfo.push(...res2.courtMentionInfo);
      }
    });
  }

  getrestorationdetail() {
    this.courtRestorationInfo = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtRestorationDetail", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.courtRestorationInfo.push(...res2.courtRestorationInfo);
      }
    });
  }

  getjudgementdetails() {
    this.judgementdetails = []
    let obj = {
      case_id: this.caseid,
    };
    this.court.post("ws/judgement", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.judgementdetails.push(...res2.caseJudgement);
      }
    });
  }

  // petitioner
  // respondent
  // getcase(){
  //   let obj = {
  //     case_id: this.caseid,
  //   };
  //   // https://api.mysita.com/public/ws/courtNotice
  //   this.court.post('ws/caseDetail',obj).subscribe((res2: any) => {
  //     console.log(res2 ,"after case detail")
  //     if(res2.status){
  //       // this.form.controls.name.setValue(response.name)
  //       this.petitioner= res2.caseDetails[0].petitioner
  //       this.respondent = res2.caseDetails[0].respondent
  //     }

  //   })

  // }

  getearlierCourtdetails() {
    this.earlierCourtDetail = []
    let obj = {
      case_id: this.caseid,
    };
    this.court.post("ws/earlierCourtDetail", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.earlierCourtDetail.push(...res2.earlierCourt);
      }
    });
  }

  getlistingDatedetails() {
    this.caseListingDates = []
    let obj = {
      case_id: this.caseid,
    };
    this.court.post("ws/listingDate", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseListingDates.push(...res2.caseListingDates);
      }
    });
  }

  getinterlocutoryApplicationDocumentdetails() {
    this.caseInterlocutoryApplicationDocuments = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court
      .post("ws/interlocutoryApplicationDocument", obj)
      .subscribe((res2: any) => {
        if (res2.status) {
          this.countOfTab = this.countOfTab + 1
          this.caseInterlocutoryApplicationDocuments.push(...res2.caseInterlocutoryApplicationDocuments)
        }
      });
  }

  getApplicationDocumentdetails() {
    this.caseOtherApplicationDocuments = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court
      .post("ws/otherApplicationDocument", obj)
      .subscribe((res2: any) => {
        if (res2.status) {
          this.caseOtherApplicationDocuments.push(...res2.caseOtherApplicationDocuments);
        }
      });
  }

  getcourtFeedetails() {
    this.caseCourtFees = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtFee", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseCourtFees.push(...res2.caseCourtFees);
      }
    });
  }

  getcaseDefectdetails() {
    this.caseCaseDefects = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseDefect", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseCaseDefects.push(...res2.caseCaseDefects);
      }
    });
  }

  getcourtSimilaritydetails() {
    this.courtSimilarities = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtSimilarity", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.courtSimilarities.push(...res2.courtSimilarities);
      }
    });
  }

  getcourtTaggedMatterdetails() {
    this.taggedMatter = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtTaggedMatter", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.taggedMatter.push(...res2.taggedMatter);
      }
    });
  }

  getcourtCaveatsdetails() {
    this.courtCaveat = []
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtCaveats", obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.courtCaveat.push(...res2.courtCaveat);
      }
    });
  }

  // getcourtcaseyiddetails() {
  //   let obj = {
  //     case_id: this.caseid,
  //   };
  //   // https://api.mysita.com/public/ws/courtNotice
  //   this.court.post("ws/courtCaseByID", obj).subscribe((res2: any) => {
  //     console.log(res2, "court gggggggg case detail");
  //     this.caseInfo = res2.caseInfo;
  //   });
  // }

  getstatedetailOfHighCourt() {
    let obj = {};
    this.highcourt
      .postecourt("ws/eGetCourtState", obj)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of state")

        this.statelist = res2.courtStateInfo;
        console.log(this.statelist, 'this.statelist of high court')
        if (this.statelist) {
          let statedetail = this.form.value.courtDetails.state;
          this.statelist.forEach((el) => {
            if (el.state_code == statedetail) {
              this.form.get("courtDetails.state").setValue(el.state_code);
            }
          });
        }
      });
  }

  getstatedetailOfDistrictCourt() {
    let obj = {};
    this.districtcourt
      .postecourt("ws/eGetCourtState", obj)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of state")

        this.statelist = res2.courtState;
        console.log(this.statelist, 'this.statelist of district court')
        if (this.statelist) {
          let statedetail = this.form.value.courtDetails.state;
          this.statelist.forEach((el) => {
            if (el.id == statedetail) {
              this.form.get("courtDetails.state").setValue(el);

              this.getDistrict(el)
            }
          });
        }
        // this.getDistrict(this.form.value.courtDetails.state)
      });
  }

  statecode
  districtcode
  getDistrict(data) {
    console.log(data)

    this.statecode = data.id
    let obj = {

      "state_code": data.id
    }

    this.districtcourt.postecourt('ws/eGetCourtStatesDistrict', obj).subscribe((res2: any) => {
      console.log(res2, "res2 of state")

      this.districtlist = res2.courtStateDistricts

      if (this.districtlist) {
        let districtlist = this.form.value.courtDetails.district;
        this.districtlist.forEach((el) => {
          if (el.id == districtlist) {
            this.form.get("courtDetails.district").setValue(el);

            this.getDistrictForum(el)
          }
        });
      }
    })
  }

  districtStatus
  getDistrictForum(data) {
    this.districtcode = data.id

    if (data.status == 1) {
      this.districtStatus = data.status
      // this.districtcode = data.id

      let obj = {
        // "district_court_id":"107"
        "district_court_id": data.id
      }


      this.districtcourt.postecourt('ws/eGetDistrictCourtComplexNew', obj).subscribe((res2: any) => {
        console.log(res2, "res2 of district")

        this.fourmlist = res2.caseDistrictCourtComplex

        if (this.fourmlist) {
          let fourmlist = this.form.value.courtDetails.complexName;
          this.fourmlist.forEach((el) => {
            if (el.district_court_complex_val == fourmlist) {
              this.form.get("courtDetails.complexName").setValue(el);

              this.courtcomplexChangeHandler(el)
            }
          });
        }

      })
    } else if (data.status == 0) {
      this.districtStatus = data.status
      // this.districtcode = data.id

      let obj = {
        // "district_court_id":"107"
        "district_court_id": data.id
      }


      this.districtcourt.postecourt('ws/eGetDistrictCourtComplex', obj).subscribe((res2: any) => {
        console.log(res2, "res2 of district")

        this.fourmlist = res2.caseDistrictCourtComplex

        if (this.fourmlist) {
          let fourmlist = this.form.value.courtDetails.complexName;
          this.fourmlist.forEach((el) => {
            if (el.district_court_complex_val == fourmlist) {
              this.form.get("courtDetails.complexName").setValue(el);

              this.courtcomplexChangeHandler(el)
            }
          });
        }

      })
    }
  }


  district_court_complex_val
  courtcomplexChangeHandler(data) {

    this.district_court_complex_val = data.district_court_complex_val
    console.log(data)
    // if(this.districtStatus==0){
    //   this.fourmselectedvalue = data
    //   const myid = data.split("|");
    //     let obj ={
    //        "district_court_complex_id":myid[0]
    //           // "district_court_id":data.id
    //         }


    //         this.districtcourt.postecourt('ws/eGetCourtCaseTypeCategory',obj).subscribe((res2: any) => {
    //           console.log(res2,"res2 of case type")

    //              this.casetype = res2.courtCaseTypeCategory

    //           })
    //  }else if(this.districtStatus==1){
    //   this.fourmselectedvalue = data
    //   const myid = data.split("|");
    //     let obj ={
    //        "district_court_complex_id":myid[0]
    //           // "district_court_id":data.id
    //         }


    //         this.districtcourt.postecourt('ws/eGetCourtCaseTypeCategoryNew',obj).subscribe((res2: any) => {
    //           console.log(res2,"res2 of case type")

    //              this.casetype = res2.courtCaseTypeCategory

    //           })
    //  }

    if (this.districtStatus == 0) {

      // this.form.get('courtDetails.complexName').setValue(bench.district_court_complex_val);
      let obj = {
        district_court_complex_id: data.id,
        // "district_court_id":data.id
      };
      console.log(obj)

      this.districtcourt
        .postecourt("ws/eGetCourtCaseTypeCategory", obj)
        .subscribe((res2: any) => {
          //  console.log(res2,"res2 of case type")

          this.categorydata = res2.courtCaseTypeCategory;
          console.log(this.categorydata, 'this.categorydata')

          if (this.categorydata) {
            let casetpe = this.form.value.caseType;
            this.categorydata.forEach((el) => {
              if (el.case_type_id == casetpe) {
                this.form.get("caseType").setValue(el.case_type_id);
              }
            });
          }
        });
    } else if (this.districtStatus == 1) {

      // this.form.get('courtDetails.complexName').setValue(bench.district_court_complex_val);
      let obj = {
        district_court_complex_id: data.id,
        // "district_court_id":data.id
      };
      console.log(obj)

      this.districtcourt
        .postecourt("ws/eGetCourtCaseTypeCategoryNew", obj)
        .subscribe((res2: any) => {
          //  console.log(res2,"res2 of case type")

          this.categorydata = res2.courtCaseTypeCategory;
          console.log(this.categorydata, 'this.categorydata')

          if (this.categorydata) {
            let casetpe = this.form.value.caseType;
            this.categorydata.forEach((el) => {
              if (el.case_type_id == casetpe) {
                this.form.get("caseType").setValue(el.case_type_id);
              }
            });
          }
        });
    }
  }



  showothersearchinput = false
  stateCode: any
  courtStateChangeHandler() {
    console.log(this.form.get('courtDetails.courtType').value)

    this.showBenchNumber = false;
    this.showothersearchinput = false
    this.caseOfMaharasytra = false
    this.caseCategoryOfHimachal = false
    this.serverlist = [];

    if (this.form.get('courtDetails.courtType').value == '5f356be0862bd4617bd3b52a') {
      this.showBenchNumber = true;
    }

    if (this.mode == 'create') {
      this.selectedValue = ''
    }
    // if (this.form.value.courtDetails.state) {
    //   let tmpObj = this;
    //   this.socketObject.emit(environment.socketEvents.getCities, {
    //     state: this.form.value.courtDetails.state
    //   }, function (socketResponse) {
    //     if (socketResponse.status == 'OK') {
    //       tmpObj.courtCitiesList = socketResponse.data;
    //     }
    //   });

    // }
    this.stateCode = this.form.value.courtDetails.state

    let ob = {
      state_code: this.form.value.courtDetails.state,
    };

    this.getServerName(this.form.value.courtDetails.state);

    this.districtcourt
      .postecourt("ws/eGetCourtStatesDistrict", ob)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of state")

        this.districtlist = res2.courtStateDistricts;
      });

    let obj = {
      state_code: this.form.value.courtDetails.state,
    };
    // console.log(obj,"state ")

    this.highcourt
      .postecourt("ws/eCourtStateBench", obj)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of state")

        this.fourmlist = res2.courtStateDistricts;
        let benchdetail = this.form.value.courtDetails.benchNumber;
        //  if(this.fourmlist){
        //   this.fourmlist.forEach(el=>{
        //     if(el.court_code==benchdetail){
        //       this.form.get('courtDetails.benchNumber').setValue(el);

        //         let obj ={
        //           "bench_id":el.id
        //              // "district_court_id":data.id
        //            }

        //     }
        //   })

        //  }
      });
  }


  showBenchNumber = false;
  serverTypeValue = ''
  caseOfMaharasytra = false
  caseCategoryOfHimachal = false
  getServerValue(val) {
    // high court e-court data empty
    this.casestatusdetails = []
    this.casepetionordetails = []
    this.caseresponedentdetails = []
    this.caseactdetails = []
    this.casehistorydetails = []
    this.caseordersdetails = []
    this.iadetail = []
    this.caseConversionInfo = []
    this.caseMainMatterInfo = []
    this.caseSubMatterInfo = []
    this.caseLinkedInfo = []
    this.caseObjectionInfo = []
    this.caseDocumentInfo = []
    this.caseFIRInfo = []
    this.caseCategoryInfo = []
    this.caseActsInfo = []

    // high court server data empty
    this.caseOrderData = []
    this.caseDetailOrdersData = []

    this.caseStatusArr = []
    this.petAndResAdvData = [];
    this.categoryDetailsArr = [];
    this.iaDetailsData = [];
    this.lowerCourtDetailsArr = []
    this.crimeDetailsArr = []
    this.listingHistoryData = [];
    this.ordersJudgementData = [];

    this.officeInformationData = []
    this.miscInformationData = []
    this.objectionsData = []
    this.casePaperIndexData = []
    this.connectedMattersData = []
    this.applicationCasesData = []
    this.listingDatesData = []
    this.lowerCourtDetailArr = []
    this.appealCourtDetailsArr = []
    this.caseStatushimachal = {};
    this.partyDetailhimachal = {};
    this.actsDetailhimachal = []
    this.mainMattershimachal = []
    this.connectedCaseshimachal = []
    this.applicationCaseshimachal = []
    this.listingDateshimachal = []
    this.orderJudgementhimachal = []
    this.lowerCourtDetailhimachal = []

    this.showothersearchinput = false
    this.caseOfMaharasytra = false
    this.caseCategoryOfHimachal = false
    if (val == "e-Court Server") {
      this.showBenchNumber = true
      this.serverTypeValue = val
    } else if (val == 'High Court Server') {
      this.showBenchNumber = false
      this.serverTypeValue = val
      if (this.stateCode == 26) {
        this.caseTypeOfDelhiHighCourt()
      }
      else if (this.stateCode == 13) {
        this.courtBenchOfAllahabadhighcourt()
        this.caseTypeOfAllahabadhighcourt()
        this.showothersearchinput = true
      }
      else if (this.stateCode == 1) {
        this.caseOfMaharasytra = true
        this.courtBenchCategory()
        this.caseCategory()
      }
      else if (this.stateCode == 5) {
        this.caseCategoryOfHimachal = true
        this.caseTypeCategoryOfHimachalPradesh()
        this.caseCategoryOfHimachalPradesh()
      }
      else if (this.stateCode == 22) {
        // this.caseCategoryOfHimachal=true
        this.caseTypeofPunjab()
        // this.caseCategoryOfHimachalPradesh()
      }
    }
  }

  getbenchandcasetype() {
    let obj = {
      state_code: this.form.value.courtDetails.state,
    };

    this.highcourt
      .postecourt("ws/eCourtStateBench", obj)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of state")

        this.fourmlist = res2.courtStateDistricts;
        let benchdetail = this.form.value.courtDetails.benchNumber;
        if (this.fourmlist) {
          this.fourmlist.forEach((el) => {
            if (el.court_code == benchdetail) {
              this.form.get("courtDetails.benchNumber").setValue(el);

              let obj = {
                bench_id: el.id,
                // "district_court_id":data.id
              };

              this.highcourt
                .postecourt("ws/eHighCourtCaseTypeCategories", obj)
                .subscribe((res2: any) => {
                  // console.log(res2,"res2 of caseiiiiiiiii type")

                  this.categorydata = res2.caseTypeCategoryInfo;
                  if (this.categorydata) {
                    let casetype = this.form.value.caseType;
                    this.categorydata.forEach((el) => {
                      if (el.case_type_id == casetype) {
                        this.form.get("caseType").setValue(el.case_type_id);
                      }
                    });
                  }
                });
            }
          });
        }
      });
  }

  courtdistrictChangeHandler(data) {
    // console.log(data,"data")
    let bench = this.form.value.courtDetails.district;

    // this.form.get('courtDetails.district').setValue(bench.code);
    let obj = {
      // "district_court_id":"107"
      district_court_id: data.value.id,
    };

    this.districtcourt
      .postecourt("ws/eGetDistrictCourtComplex", obj)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of district")

        this.fourmlist = res2.caseDistrictCourtComplex;
      });
  }

  courtbencgChangeHandler(data) {
    // console.log(,"after getting bench number ")
    // console.log(data,"data of bech number ")
    let bench = this.form.value.courtDetails.benchNumber;

    //  this.form.get('courtDetails.benchNumber').setValue(bench.court_code);
    //  console.log(this.form.value,"this.form")
    // this.fourmselectedvalue = data.court_code
    let obj = {
      bench_id: bench.id,
      // "district_court_id":data.id
    };

    this.highcourt
      .postecourt("ws/eHighCourtCaseTypeCategories", obj)
      .subscribe((res2: any) => {
        // console.log(res2,"res2 of case type")

        this.categorydata = res2.caseTypeCategoryInfo;
        if (this.categorydata) {
          let casetype = this.form.value.caseType;
          this.categorydata.forEach((el) => {
            if (el.case) {
              if (el.case_type_id == casetype) {
                this.form.get("caseType").setValue(el.case_type_id);
              }
            }
          });
        }
      });
  }



  // petitionerCountryChangeHandler(index) {
  //   if (this.form.value.petitioner[index].country) {
  //     let tmpObj = this;
  //     this.petitionerCitiesList[index] = [];
  //     this.socketObject.emit(environment.socketEvents.getStates, {
  //       country: this.form.value.petitioner[index].country
  //     }, function (socketResponse) {
  //       if (socketResponse.status == 'OK') {
  //         tmpObj.petitionerStatesList[index] = socketResponse.data;
  //       }
  //     });
  //   }
  // }

  //Petitioner change handler
  petitionerNameChangeHander(event) {
    this.tmpPetitionerNameControl.setValue(event.target.value);
  }
  advocateassignedChangeHander(event) {
    this.tmpadvocateNameControl.setValue(event.target.value);
  }

  peitionerAutoCompleteOptionSelected(selectedOption, petitionerControlsIndex) {
    if (selectedOption && this.petitionerNameSuggestionsAlias[selectedOption]) {
      this.form.controls.petitioner["controls"][petitionerControlsIndex]
        .get("name")
        .setValue(this.petitionerNameSuggestionsAlias[selectedOption].name);
      this.form.controls.petitioner["controls"][petitionerControlsIndex]
        .get("address")
        .setValue(this.petitionerNameSuggestionsAlias[selectedOption].address);
      this.form.controls.petitioner["controls"][petitionerControlsIndex]
        .get("country")
        .setValue(this.petitionerNameSuggestionsAlias[selectedOption].country);
      this.form.controls.petitioner["controls"][petitionerControlsIndex]
        .get("state")
        .setValue(this.petitionerNameSuggestionsAlias[selectedOption].state);
      this.form.controls.petitioner["controls"][petitionerControlsIndex]
        .get("city")
        .setValue(this.petitionerNameSuggestionsAlias[selectedOption].city);
      this.form.controls.petitioner["controls"][petitionerControlsIndex]
        .get("zip")
        .setValue(this.petitionerNameSuggestionsAlias[selectedOption].zip);
      this.petitionerCountryChangeHandler(petitionerControlsIndex);
      this.petitionerStateChangeHandler(petitionerControlsIndex);
    }
  }

  advocateassignedCompleteOptionSelected(data) {
    console.log(data, "data");
    this.advocatevalue = data;
    let name = "";
    if (data.firmName) {
      name = data.firmName;
    } else if (data.firstName) {
      name = data.firstName;
    }
    this.form.get("advocateAssigned").setValue(name);
  }

  openOrderDateDialog(docIndex) {
    let docDetails: any = {};
    if (docIndex >= 0) {
      this.selectedDocIndex = docIndex;
      docDetails = this.form.value.docs[docIndex][0]
        ? this.form.value.docs[docIndex][0]
        : this.form.value.docs[docIndex];
    } else {
      this.selectedDocIndex = -1;
    }
    this.frmOrderDate = this.fb.group({
      orderDate: [docDetails.orderDate || "", Validators.required],
    });
    this.orderDateDiaglogRef = this.dialog.open(this.orderDocDateTemplateRef, {
      panelClass: "judgement-dialog-container",
      disableClose: true,
    });
  }

  async ordersDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.ordersDocs = this.form.value.ordersDocs
          ? this.form.value.ordersDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadOrdersDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadOrdersDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadOrdersDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadOrdersDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadOrdersDocumentEnd,
          (filePath) => {
            tmpObj.form.value.ordersDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("ordersDocs")
                .setValue(tmpObj.form.value.ordersDocs);
              tmpObj.openOrderDateDialog(-1);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async miscellaneousDocControlChangeHandler(fileObj) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.miscellaneousDocs = this.form.value.miscellaneousDocs
          ? this.form.value.miscellaneousDocs
          : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadMiscellaneousDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadMiscellaneousDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadMiscellaneousDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadMiscellaneousDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadMiscellaneousDocumentEnd,
          (filePath) => {
            tmpObj.form.value.miscellaneousDocs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("miscellaneousDocs")
                .setValue(tmpObj.form.value.miscellaneousDocs);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  saveOrderDateHandler() {
    if (this.frmOrderDate.invalid) {
      this.snackBar.open("Please provide description for document", "Okay", {
        duration: 2000,
      });
      return;
    }

    if (this.selectedDocIndex >= 0) {
      if (this.form.value.ordersDocs[this.selectedDocIndex][0]) {
        this.form.value.ordersDocs[this.selectedDocIndex][0].orderDate =
          this.frmOrderDate.value.orderDate;
      } else {
        this.form.value.ordersDocs[this.selectedDocIndex].orderDate =
          this.frmOrderDate.value.orderDate;
      }
    } else {
      this.form.value.ordersDocs[
        this.form.value.ordersDocs.length - 1
      ].orderDate = this.frmOrderDate.value.orderDate;
    }

    this.orderDateDiaglogRef.close();
  }

  public removeOrdersDoc(petitionDocIndex) {
    if (this.form.value.ordersDocs && petitionDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.ordersDocs.splice(petitionDocIndex, 1);
        this.form.value.ordersDocs[petitionDocIndex].is_deleted = true;
        this.form.value.ordersDocs[petitionDocIndex].deletedBy =
          this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  public removeMiscellaneousDoc(petitionDocIndex) {
    if (this.form.value.miscellaneousDocs && petitionDocIndex >= 0) {
      if (confirm("Do you really want to delete it?")) {
        // this.form.value.miscellaneousDocs.splice(petitionDocIndex, 1);
        this.form.value.miscellaneousDocs[petitionDocIndex].is_deleted = true;
        this.form.value.miscellaneousDocs[petitionDocIndex].deletedBy =
          this.commonServices.getLoggedInUserDetails()._id;
      }
    }
  }

  // add new Criminal Case Stage
  public addCrimimalCaseStagesArrUnit(crimimalCaseStagesDetails) {
    const control = <FormArray>(
      this.form.get("petition_docs.crimimalCaseStages")
    );
    control.push(
      this.getCrimimalCaseStagesArrayUnit(crimimalCaseStagesDetails)
    );
  }

  // remove Criminal Case Stage
  public removeCrimimalCaseStagesArrUnit(i: number) {
    const control = <FormArray>(
      this.form.get("petition_docs.crimimalCaseStages")
    );
    control.removeAt(i);
  }

  public getCrimimalCaseStagesArrayUnit(crimimalCaseStagesDetails) {
    return this.fb.group({
      stage: [crimimalCaseStagesDetails.stage || "", Validators.required],
      docs: [crimimalCaseStagesDetails.docs || []],
    });
  }
  /* Criminal Cases Stage  Field: End */

  // add new Criminal Case Stage
  public addResCrimimalCaseStagesArrUnit(crimimalCaseStagesDetails) {
    const control = <FormArray>(
      this.form.get("supporting_docs.crimimalCaseStages")
    );
    control.push(
      this.getCrimimalCaseStagesArrayUnit(crimimalCaseStagesDetails)
    );
  }

  // remove Criminal Case Stage
  public removeResCrimimalCaseStagesArrUnit(i: number) {
    const control = <FormArray>(
      this.form.get("supporting_docs.crimimalCaseStages")
    );
    control.removeAt(i);
  }
  /* Criminal Cases Stage Field for Respondent: End */

  changePetitionerName(caseNature) {
    this.petitionerName = "Petitioner";
    // console.log(caseNature,"caseNature")
    if (caseNature) {
      if (this.commonServices.isCriminalCase(caseNature)) {
        this.petitionerName = "Complainant";
      } else if (this.commonServices.isCivilMatterCase(caseNature)) {
        this.petitionerName = "Plaintiff";
      } else if (this.commonServices.isArbitrationCase(caseNature)) {
        this.petitionerName = "Claimant";
      }
    }
  }

  changeResponderName(caseNature) {
    this.responderName = "Respondent";

    if (caseNature) {
      if (this.commonServices.isCriminalCase(caseNature)) {
        this.responderName = "Accused";
      } else if (this.commonServices.isCivilMatterCase(caseNature)) {
        this.responderName = "Defendant";
      } else if (this.commonServices.isArbitrationCase(caseNature)) {
        this.responderName = "Respondent";
      }
    }
  }

  caseNatureChangeHandler(event) {
    this.changePetitionerName(this.form.value.caseNature);
    this.changeResponderName(this.form.value.caseNature);

    this.form.value.petition_docs.crimimalCaseStages = [];
    this.form.value.supporting_docs.crimimalCaseStages = [];

    if (this.commonServices.isCriminalCase(this.form.value.caseNature)) {
      // console.log("running")
      this.isCaseNatureCriminal = true;
      if (
        this.courtCaseDetails.petition_docs &&
        this.courtCaseDetails.petition_docs.crimimalCaseStages &&
        this.courtCaseDetails.petition_docs.crimimalCaseStages.length > 0
      ) {
        for (
          let i = 0;
          i < this.courtCaseDetails.petition_docs.crimimalCaseStages.length;
          i++
        ) {
          this.addCrimimalCaseStagesArrUnit(
            this.courtCaseDetails.petition_docs.crimimalCaseStages[i]
          );
        }
      } else {
        this.addCrimimalCaseStagesArrUnit({});
      }
      if (
        this.courtCaseDetails.supporting_docs &&
        this.courtCaseDetails.supporting_docs.crimimalCaseStages &&
        this.courtCaseDetails.supporting_docs.crimimalCaseStages.length > 0
      ) {
        for (
          let i = 0;
          i < this.courtCaseDetails.supporting_docs.crimimalCaseStages.length;
          i++
        ) {
          this.addResCrimimalCaseStagesArrUnit(
            this.courtCaseDetails.supporting_docs.crimimalCaseStages[i]
          );
        }
      } else {
        // console.log("run event")
        this.addResCrimimalCaseStagesArrUnit({});
      }
    }
  }

  async criminalCaseStageFileControlChangeHandler(fileObj, currentIndex) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      // this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.petition_docs.crimimalCaseStages[currentIndex].docs =
          this.form.value.petition_docs.crimimalCaseStages[currentIndex].docs
            ? this.form.value.petition_docs.crimimalCaseStages[currentIndex]
              .docs
            : [];
        let reader;

        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          // var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadPetitionDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadPetitionDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadPetitionDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadPetitionDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadPetitionDocumentEnd,
          (filePath) => {
            tmpObj.form.value.petition_docs.crimimalCaseStages[
              currentIndex
            ].docs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("petition_docs.crimimalCaseStages")
                .setValue(tmpObj.form.value.petition_docs.crimimalCaseStages);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  async resCriminalStageFileControlChangeHandler(fileObj, currentIndex) {
    if (fileObj.files[0] && fileObj.files[0].name) {
      //this.selectedOtherFileName = (fileObj.files.length > 1) ? fileObj.files[0].name + ",..." : fileObj.files[0].name;
      if (fileObj.files.length > 0) {
        this._layoutService.showLoader("Starting File Upload");
        this.form.value.supporting_docs.crimimalCaseStages[currentIndex].docs =
          this.form.value.supporting_docs.crimimalCaseStages[currentIndex]
            ? this.form.value.supporting_docs.crimimalCaseStages[currentIndex]
              .docs
            : [];
        let reader;
        reader = new FileReader();
        let currentFileIndex = 0;
        let tmpObj = this;
        let totalNumberOfFiles = fileObj.files.length;
        let fileSize = (
          fileObj.files[currentFileIndex].size /
          (1024 * 1024)
        ).toFixed(2);
        let slice = fileObj.files[currentFileIndex].slice(0, 1000000);
        reader.readAsArrayBuffer(slice);

        reader.onload = () => {
          //var arrayBuffer = reader.result;
          tmpObj.socketObject.emit(
            environment.socketEvents.uploadSupportingDocumentSlice,
            {
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              data: reader.result,
            },
            (socketResponse) => {
              if (socketResponse.status == responseMessages.status.ok) {
              } else {
                tmpObj.snackBar.open("Oop! Unable to upload file.", "Okay", {
                  duration: 3000,
                  horizontalPosition: "center",
                });
                tmpObj._layoutService.hideLoader();
              }
            }
          );
        };

        tmpObj.socketObject.off(
          environment.socketEvents.uploadSupportingDocumentNext
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadSupportingDocumentNext,
          (data) => {
            tmpObj._layoutService.showLoader(
              data.uploadedPercent + " of " + fileSize + " Uploaded",
              currentFileIndex +
              1 +
              " of " +
              totalNumberOfFiles +
              " files uploading."
            );
            let place = data.currentSlice * 1000000,
              slice = fileObj.files[currentFileIndex].slice(
                place,
                place +
                Math.min(
                  1000000,
                  fileObj.files[currentFileIndex].size - place
                )
              );
            reader.readAsArrayBuffer(slice);
          }
        );

        tmpObj.socketObject.off(
          environment.socketEvents.uploadSupportingDocumentEnd
        );
        tmpObj.socketObject.on(
          environment.socketEvents.uploadSupportingDocumentEnd,
          (filePath) => {
            tmpObj.form.value.supporting_docs.crimimalCaseStages[
              currentIndex
            ].docs.push({
              name: fileObj.files[currentFileIndex].name,
              type: fileObj.files[currentFileIndex].type,
              size: fileObj.files[currentFileIndex].size,
              filePath: filePath,
            });

            currentFileIndex++;
            if (currentFileIndex < totalNumberOfFiles) {
              fileSize = (
                fileObj.files[currentFileIndex].size /
                (1024 * 1024)
              ).toFixed(2);
              slice = fileObj.files[currentFileIndex].slice(0, 1000000);
              reader.readAsArrayBuffer(slice);
            } else {
              tmpObj.form
                .get("supporting_docs.crimimalCaseStages")
                .setValue(tmpObj.form.value.supporting_docs.crimimalCaseStages);
              tmpObj.snackBar.open("Successfully uploaded file.", "Okay", {
                duration: 3000,
                horizontalPosition: "center",
              });
              tmpObj._layoutService.hideLoader();
            }
          }
        );
      }
    }
  }

  courtNameChangeHandler() {
    if (this.form.value.courtDetails.courtName) {
      let tmpObj = this;

      tmpObj.socketObject.emit(
        environment.socketEvents.getCourtStates,
        {
          court: this.form.value.courtDetails.courtName,
        },
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.courtNameState = socketResponse.data;
            tmpObj.form
              .get("courtDetails.state")
              .setValue(tmpObj.courtNameState._id);
            tmpObj.courtStateChangeHandler();
          }
        }
      );
      tmpObj.socketObject.emit(
        environment.socketEvents.getCourtCity,
        {
          court: this.form.value.courtDetails.courtName,
        },
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.courtNameCity = socketResponse.data;
            tmpObj.form
              .get("courtDetails.district")
              .setValue(tmpObj.courtNameCity._id);
          }
        }
      );
    }
  }

  // get location
  locationCategory;
  locationCategoryDetails() {
    this.tribunalService.getData("locationCategory").subscribe((res) => {
      this.locationCategory = res.locationCategory;

    });
  }

  // caseTypeCategory;
  // caseTypeDetails() {
  //   this.tribunalService.getData("caseTypeCategory").subscribe((res) => {
  //     this.caseTypeCategory = res.caseTypeCategory;
  //     console.log(this.caseTypeCategory)
  //   });
  // }

  getcaseconversiondetail() {
    this.caseConversionInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighCaseConversionDetail', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseConversionInfo.push(...res2.caseConversionInfo)
      }
    })
  }


  getactdetal() {
    this.caseActsInfo = []

    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseActs', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseActsInfo.push(...res2.caseActsInfo)
      }
    })
  }

  getmainmetterdetail() {
    this.caseMainMatterInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighMainMatterDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseMainMatterInfo.push(...res2.caseMainMatterInfo)
      }
    })
  }

  getsubmetterdetail() {
    this.caseSubMatterInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighSubMatterDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseSubMatterInfo.push(...res2.caseSubMatterInfo)
      }
    })
  }

  getlinkedcasedetail() {
    this.caseLinkedInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighLinkedDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseLinkedInfo.push(...res2.caseLinkedInfo)
      }
    })
  }


  getobjectiondetail() {
    this.caseObjectionInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighObjectionDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseObjectionInfo.push(...res2.caseObjectionInfo)
      }
    })
  }


  getdocumentdetail() {
    this.caseDocumentInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighDocumentsDetail', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseDocumentInfo.push(...res2.caseDocumentInfo)
      }
    })
  }


  getfirdetail() {
    this.caseFIRInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighFIRDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseFIRInfo.push(...res2.caseFIRInfo)
      }
    })
  }


  getcategorydetail() {
    this.caseCategoryInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighCategoryDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseCategoryInfo.push(...res2.caseCategoryInfo)
      }
    })
  }



  getcasestatusdetais() {
    this.casestatusdetails = []

    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseStatus', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casestatusdetails.push(...res2.caseStatusInfo)
      }
    })

  }


  getpetinordetais() {
    this.casepetionordetails = []

    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCasePetitionerAdv', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casepetionordetails.push(...res2.casePetitionerAdvInfo)
      }
    })

  }


  getrepondentdetais() {
    this.caseresponedentdetails = []

    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseRespondentAdv', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseresponedentdetails.push(...res2.caseRespondentAdvInfo)
      }
    })

  }

  getactdetais() {
    this.caseactdetails = []

    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighSubordinateInformation', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseactdetails.push(...res2.caseSubordinateInfo)
      }
    })

  }

  getiadetail() {
    this.iadetail = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighIADetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.iadetail.push(...res2.caseIAInfo)
      }
    })

  }


  getCaseHistorydetais() {
    this.casehistorydetails = []

    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.highcourt.postecourt('ws/eHighGetCaseHistory', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casehistorydetails.push(...res2.caseHistoryInfo)
      }
    })

  }

  getCaseordersdetais() {
    this.caseordersdetails = []
    let obj = {
      "court_case_id": this.caseid
    }

    this.highcourt.postecourt('ws/eHighGetCaseOrders', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseordersdetails.push(...res2.caseOrderInfo)
      }
    })

  }



  // function to get data of caseTypeCategory of delhi high court
  caseTypeOfDelhiHighCourt() {
    this.delhihighcourtservice.getData("caseTypeCategory").subscribe(
      (res) => {
        if (res["status"] == true) {
          this.categorydata = res["partyName"];
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  courtBenchOfAllahabadhighcourtData;
  courtBenchOfAllahabadhighcourt() {
    this.allahabadhighcourtservice.getData("benchCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.courtBenchOfAllahabadhighcourtData = res.benchCategory;
          console.log(this.courtBenchOfAllahabadhighcourtData);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  caseTypeOfAllahabadhighcourt() {
    this.allahabadhighcourtservice.getData("caseTypeCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.categorydata = res.caseTypeCategory;
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  casestatusdetailArr = []
  casestatusdetail;
  getcasestatusdetail() {
    this.casestatusdetailArr = []
    let obj = {
      "court_case_id": this.caseid
    }
    console.log(obj, "obj")
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseStatus', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casestatusdetail = res2.caseStatusInfo
        this.casestatusdetailArr.push(res2.caseStatusInfo)
      }
    })

  }

  casepetionordetail = []
  getpetinordetail() {
    this.casepetionordetail = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCasePetitionerAdv', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casepetionordetail.push(...res2.casePetitionerAdvInfo)
      }
    })

  }

  caseresponedentdetail = []
  getrepondentdetail() {
    this.caseresponedentdetail = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseRespondentAdv', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseresponedentdetail.push(...res2.caseRespondentAdvInfo)
      }
    })

  }

  caseactdetail = []
  getactdetail() {
    this.caseactdetail = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseActs', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseactdetail.push(...res2.caseActsInfo)
      }
    })

  }

  caseMainMatterInfos = []
  getmainmetterdetails() {
    this.caseMainMatterInfos = []
    let obj = {
      "court_case_id": this.caseid
    }
    this.districtcourt.postecourt('ws/eGetCaseMainMatter', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseMainMatterInfos.push(...res2.caseMainMatterInfo)
      }
    })
  }

  caseSubMatterInfos = []
  getsubmetterdetails() {
    this.caseSubMatterInfos = []
    let obj = {
      "court_case_id": this.caseid
    }
    this.districtcourt.postecourt('ws/eGetCaseSubMatter', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseSubMatterInfos.push(...res2.caseSubMatterInfos)
      }
    })
  }

  caseProcessInfo = []
  getprocessdetail() {
    this.caseProcessInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    this.districtcourt.postecourt('ws/eGetCaseProcessDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseProcessInfo.push(...res2.caseProcessInfo)
      }
    })
  }

  caseSubordinateCourtInfo = []
  getsubordinatedetail() {
    this.caseSubordinateCourtInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    this.districtcourt.postecourt('ws/eGetCaseSubordinateCourtInfo', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseSubordinateCourtInfo.push(...res2.caseSubordinateCourtInfo)
      }
    })
  }

  casehistorydetail = []
  getCaseHistorydetail() {
    this.casehistorydetail = []
    let obj = {
      "court_case_id": this.caseid
    }
    // https://api.mysita.com/public/ws/courtNotice
    this.districtcourt.postecourt('ws/eGetCaseHistory', obj).subscribe((res2: any) => {
      console.log(res2, "after case detail")
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casehistorydetail.push(...res2.caseHistoryInfo)
      }
    })

  }


  casefirDetailInfo = []
  getfirdetails() {
    this.casefirDetailInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    this.districtcourt.postecourt('ws/eGetCasefirDetails', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.casefirDetailInfo.push(...res2.casefirDetailInfo)
      }
    })
  }

  caseordersdetail = []
  getCaseordersdetail() {
    this.caseordersdetail = []
    let obj = {
      "court_case_id": this.caseid
    }

    this.districtcourt.postecourt('ws/eGetCaseOrders', obj).subscribe((res2: any) => {
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseordersdetail.push(...res2.caseOrderInfo)
      }
    })
  }

  caseTransferInfo = []
  getcasetransferdetail() {
    this.caseTransferInfo = []
    let obj = {
      "court_case_id": this.caseid
    }
    this.districtcourt.postecourt('ws/eGetCaseTransfer', obj).subscribe((res2: any) => {
      console.log(res2, "after case detail")
      if (res2.status) {
        this.countOfTab = this.countOfTab + 1
        this.caseTransferInfo.push(...res2.caseTransferInfo)
      }
    })
  }

  courtBenchCategoryData;
  courtBenchCategory() {
    this.bombayhighcourtservice.getData("courtBenchCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.courtBenchCategoryData = res.courtBenchCategory;
          console.log(this.courtBenchCategoryData);

          this.courtBenchCategoryData.forEach(element => {
            if (this.courtCaseDetails.court_code == element.court_bench_id) {
              this.getCourtBenchIdAndFindCaseSide({ court_bench_id: element.court_bench_id, id: element.id })
            }
          });

        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }


  caseSideCategory
  court_bench_id;
  getCourtBenchIdAndFindCaseSide(data) {
    console.log(data)
    this.court_bench_id = data.court_bench_id
    let obj = {
      court_bench_category_id: data.id,
    };
    this.bombayhighcourtservice.postData("caseSideCategory", obj).subscribe(
      (res) => {
        if (res.status == true) {
          this.caseSideCategory = res.caseSideCategory;

          this.caseSideCategory.forEach(element => {
            if (this.courtCaseDetails.case_side == element.case_side_id) {
              this.getJuridictionIdAndFindCaseType({ case_side_id: element.case_side_id, id: element.id })
            }
          });
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  case_side_id;
  getJuridictionIdAndFindCaseType(data) {
    this.case_side_id = data.case_side_id
    let obj = {
      case_side_category_id: data.id,
    };
    this.bombayhighcourtservice.postData("caseTypeCategory", obj).subscribe(
      (res) => {
        if (res.status) {
          this.categorydata = res.caseTypeCategory;
        }
      }
    );
  }


  caseCategoryData;
  caseCategory() {
    this.bombayhighcourtservice.getData("caseCategory").subscribe(
      (res) => {
        if (res.status == true) {
          this.caseCategoryData = res.caseCategory;
          console.log(this.caseCategoryData, 7356);
        } else {
          console.log(res.message);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  locationCategorys;
  locationCategoryDetail() {
    this.tribunalService
      .getData('locationCategory')
      .subscribe((res) => {
        this.locationCategorys = res.locationCategory;
        console.log(this.locationCategorys, "this.locationCategorys")
      });
  }


  // caseTypeCategory;
  caseTypeDetail() {
    this.tribunalService
      .getData('caseTypeCategory')
      .subscribe((res) => {
        this.categorydata = res.caseTypeCategory;
        console.log(this.categorydata, "this.categorydata")
      });
  }

  // caseType of Himachal
  caseTypeCategoryOfHimachalPradesh() {
    this.himalchalhighcourtservice.getData("caseTypeCategory").subscribe((res) => {
      if (res.status) {
        this.categorydata = res.caseTypeCategory;
      }
    }, (err) => {
      console.log(err)
    })
  }

  caseCategoryOfHimachalPradesh() {
    this.himalchalhighcourtservice.getData("caseCategory").subscribe((res) => {
      if (res.status) {
        this.caseCategoryData = res.caseCategory;
      }
    }, (err) => {
      console.log(err)
    })
  }

  caseStatushimachal = {};
  caseStatusHimachal(obj) {
    this.caseStatushimachal = {};
    this.himalchalhighcourtservice.postData('caseStatus', obj).subscribe((res) => {
      if (res.status) {
        this.caseStatushimachal = { ...res.caseStatus };
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  partyDetailhimachal = {};
  partyDetailHimachal(obj) {
    this.partyDetailhimachal = {};
    this.himalchalhighcourtservice.postData('partyDetail', obj).subscribe((res) => {
      if (res.status) {
        this.partyDetailhimachal = res.partyDetail;
        this.countOfTab = this.countOfTab + 1;

        let control = this.form.get('petitioner') as FormArray;
        control.controls.forEach(key => key.get('name').setValue(this.partyDetailhimachal['petitioner_detail']))
        let control2 = this.form.get('responder') as FormArray;
        control2.controls.forEach(key => key.get('name').setValue(this.partyDetailhimachal['respondent_detail']))
      }
    }, (err) => {
      console.log(err)
    })
  }
  actsDetailhimachal = [];
  actsDetailHimachal(obj) {
    this.actsDetailhimachal = [];
    this.himalchalhighcourtservice.postData('actsDetails', obj).subscribe((res) => {
      if (res.status) {
        this.actsDetailhimachal.push(...res.actsDetails);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  mainMattershimachal = [];
  mainMattersHimachal(obj) {
    this.actsDetailhimachal = [];
    this.himalchalhighcourtservice.postData('mainMatters', obj).subscribe((res) => {
      if (res.status) {
        this.mainMattershimachal.push(...res.mainMatters);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  connectedCaseshimachal = [];
  connectedCasesHimachal(obj) {
    this.actsDetailhimachal = [];
    this.himalchalhighcourtservice.postData('connectedCases', obj).subscribe((res) => {
      if (res.status) {
        this.connectedCaseshimachal.push(...res.connectedCases);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  applicationCaseshimachal = [];
  applicationCasesHimachal(obj) {
    this.applicationCaseshimachal = [];
    this.himalchalhighcourtservice.postData('applicationCases', obj).subscribe((res) => {
      if (res.status) {
        this.applicationCaseshimachal.push(...res.applicationCases);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  listingDateshimachal = [];
  listingDatesHimachal(obj) {
    this.listingDateshimachal = [];
    this.himalchalhighcourtservice.postData('listingDates', obj).subscribe((res) => {
      if (res.status) {
        this.listingDateshimachal.push(...res.listingDates);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  orderJudgementhimachal = [];
  orderJudgementHimachal(obj) {
    this.orderJudgementhimachal = [];
    this.himalchalhighcourtservice.postData('orderJudgement', obj).subscribe((res) => {
      if (res.status) {
        this.orderJudgementhimachal.push(...res.orderJudgement);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  lowerCourtDetailhimachal = [];
  lowerCourtDetailHimachal(obj) {
    this.lowerCourtDetailhimachal = [];
    this.himalchalhighcourtservice.postData('lowerCourtDetail', obj).subscribe((res) => {
      if (res.status) {
        this.lowerCourtDetailhimachal.push(...res.lowerCourtDetail);
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }

  // punjab high court
  // casetypeofpunjab;
  caseTypeofPunjab() {
    this.punjabservice.getData("caseTypeCategory").subscribe((res) => {
      if (res.status) {
        this.categorydata = res.caseTypeCategory;
      }
    }, (err) => {
      console.log(err)
    })
  }

  relatedcases = [];
  relatedCases(obj) {
    this.relatedcases = [];
    this.punjabservice.postData('relatedCases', obj).subscribe((res) => {
      if (res.status) {
        this.relatedcases = res.relatedCases;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  caseListing = [];
  caseListingDetails(obj) {
    this.caseListing = [];
    this.punjabservice.postData('caseListingDetails', obj).subscribe((res) => {
      if (res.status) {
        this.caseListing = res.caseListingDetails;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  detailOfAppeal = [];
  detailOfAppeals(obj) {
    this.detailOfAppeal = [];
    this.punjabservice.postData('detailOfAppeals', obj).subscribe((res) => {
      if (res.status) {
        this.detailOfAppeal = res.detailOfAppeals;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  impungOrder = [];
  impungOrderDetail(obj) {
    this.impungOrder = [];
    this.punjabservice.postData('impungOrderDetail', obj).subscribe((res) => {
      if (res.status) {
        this.impungOrder = res.detailOfAppeals;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  firDetail = [];
  firDetails(obj) {
    this.firDetail = [];
    this.punjabservice.postData('firDetails', obj).subscribe((res) => {
      if (res.status) {
        this.firDetail = res.firDetails;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  copypetition = [];
  copyPetition(obj) {
    this.copypetition = [];
    this.punjabservice.postData('copyPetition', obj).subscribe((res) => {
      if (res.status) {
        this.copypetition = res.copyPetition;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  lowerCourt = [];
  lowerCourtDetailsPunjab(obj) {
    this.lowerCourt = [];
    this.punjabservice.postData('lowerCourtDetails', obj).subscribe((res) => {
      if (res.status) {
        this.lowerCourt = res.lowerCourtDetails;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
  judgment = [];
  judgmentDetails(obj) {
    this.judgment = [];
    this.punjabservice.postData('judgmentDetails', obj).subscribe((res) => {
      if (res.status) {
        this.judgment = res.judgmentDetails;
        this.countOfTab = this.countOfTab + 1;
      }
    }, (err) => {
      console.log(err)
    })
  }
}