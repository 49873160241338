import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { CasesCreateUpdateComponent } from './cases-create-update.component';
import { MatMenuModule } from '@angular/material/menu';
import { IconModule } from '@visurel/iconify-angular';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule, MatDatepickerModule, MatNativeDateModule, MatCheckboxModule, MatAutocompleteModule } from '@angular/material';
import { RichTextEditorComponent } from '../rich-text-editor/rich-text-editor.component';
import { QuillModule } from 'ngx-quill';
import { CourtCasesCreateUpdateComponent } from '../court-cases-create-update/court-cases-create-update.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { MatCardModule } from '@angular/material';
import { AdvocateFirmCreateUpdateComponent } from '../../advocate-firm/advocate-firm-create-update/advocate-firm-create-update.component';
const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MatDialogModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatMenuModule,
    IconModule,
    MatTabsModule,
    MatCardModule,
    MatDividerModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    NgxMaskModule.forRoot(maskConfig),
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
          ['blockquote', 'code-block'],

          [{ header: 1 }, { header: 2 }],               // custom button values
          [{ list: 'ordered' }, { list: 'bullet' }],
          [{ script: 'sub' }, { script: 'super' }],      // superscript/subscript
          [{ indent: '-1' }, { indent: '+1' }],          // outdent/indent
          [{ direction: 'rtl' }],                         // text direction

          [{ size: ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ header: [1, 2, 3, 4, 5, 6, false] }],

          [{ color: [] }, { background: [] }],          // dropdown with defaults from theme
          [{ align: [] }],

          ['clean'],                                         // remove formatting button

          ['link', 'image', 'video']                         // link and image, video
        ]
      }
    }),
  ],
  declarations: [CasesCreateUpdateComponent,RichTextEditorComponent, CourtCasesCreateUpdateComponent,AdvocateFirmCreateUpdateComponent],
  entryComponents: [CasesCreateUpdateComponent,RichTextEditorComponent,CourtCasesCreateUpdateComponent,AdvocateFirmCreateUpdateComponent],
  exports: [CasesCreateUpdateComponent,RichTextEditorComponent,CourtCasesCreateUpdateComponent]
})
export class CasesCreateUpdateModule {
}
