import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges
} from '@angular/core';
import { NavigationDropdown, NavigationItem, NavigationLink } from '../../interfaces/navigation-item.interface';
import { dropdownAnimation } from '../../animations/dropdown.animation';
import { NavigationEnd, Router } from '@angular/router';
import { untilDestroyed } from 'ngx-take-until-destroy';
import { filter } from 'rxjs/operators';
import { NavigationService } from '../../services/navigation.service';
import icKeyboardArrowRight from '@iconify/icons-ic/twotone-keyboard-arrow-right';
import { environment } from "src/environments/environment";
import { CommonService } from 'src/app/services/core/common.service';
import { SocketService } from 'src/app/services/core/socket.service';
import { responseMessages } from 'src/app/services/core/service.constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'vex-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  animations: [dropdownAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidenavItemComponent implements OnInit, OnChanges, OnDestroy {

  @Input() item: NavigationItem;
  @Input() level: number;
  @Input() currentUserRole: any;
  isOpen: boolean;
  isActive: boolean;
  icKeyboardArrowRight = icKeyboardArrowRight;

  private socketObject = null;

  public allowedModuleKeys;
  public render = new BehaviorSubject<boolean>(false);
  public roleDetails;

  isLink = this.navigationService.isLink;
  isDropdown = this.navigationService.isDropdown;
  isSubheading = this.navigationService.isSubheading;

  constructor(private router: Router,
    private cd: ChangeDetectorRef,
    private navigationService: NavigationService,
    private commonServices: CommonService,
    private socketService: SocketService,
  ) {

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }

    this.refreshUserRole(this);
  }

  @HostBinding('class')
  get levelClass() {
    return `item-level-${this.level}`;
  }

  refreshUserRole(tmpObj: SidenavItemComponent) {
    if (tmpObj.socketService.isSocketConnected()) {
      tmpObj.socketObject.emit(environment.socketEvents.listRoleModules, {}, function (socketResponse) {
        if (socketResponse.status == responseMessages.status.ok) {
          tmpObj.roleDetails = socketResponse.roleDetails;
          environment.roleDetails = socketResponse.roleDetails;
          if (socketResponse.roleDetails.allowedAccesses) {
            tmpObj.allowedModuleKeys = Object.keys(socketResponse.roleDetails.allowedAccesses);
          } else {
            tmpObj.allowedModuleKeys = [];
          }
          tmpObj.render.next(true);
        }
      });
    }
  }

  ngOnInit() {
    this.router.events.pipe(
      filter<NavigationEnd>(event => event instanceof NavigationEnd),
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(() => this.onRouteChange());

    this.navigationService.openChange$.pipe(
      filter(() => this.isDropdown(this.item)),
      untilDestroyed(this)
    ).subscribe(item => this.onOpenChange(item));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.hasOwnProperty('item') && this.isDropdown(this.item)) {
      this.onRouteChange();
    }
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
    this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
    this.cd.markForCheck();
  }

  onOpenChange(item: NavigationDropdown) {
    if (this.isChildrenOf(this.item as NavigationDropdown, item)) {
      return;
    }

    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      return;
    }

    if (this.item !== item) {
      this.isOpen = false;
      this.cd.markForCheck();
    }
  }

  onRouteChange() {
    if (this.hasActiveChilds(this.item as NavigationDropdown)) {
      this.isActive = true;
      this.isOpen = true;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    } else {
      this.isActive = false;
      this.isOpen = false;
      this.navigationService.triggerOpenChange(this.item as NavigationDropdown);
      this.cd.markForCheck();
    }
  }

  isChildrenOf(parent: NavigationDropdown, item: NavigationDropdown) {
    if (parent.children.indexOf(item) !== -1) {
      return true;
    }

    return parent.children
      .filter(child => this.isDropdown(child))
      .some(child => this.isChildrenOf(child as NavigationDropdown, item));
  }

  hasActiveChilds(parent: NavigationDropdown) {
    return parent.children.some(child => {
      if (this.isDropdown(child)) {
        return this.hasActiveChilds(child);
      }

      if (this.isLink(child) && !this.isFunction(child.route)) {
        return this.router.isActive(child.route as string, false);
      }
    });
  }

  isFunction(prop: NavigationLink['route']) {
    return prop instanceof Function;
  }

  ngOnDestroy(): void { }

  isRoleAllowed(navigationDetails) {
    // if (navigationDetails.allowedRoles.indexOf(environment.currentUserRole) == -1) {
    if (this.roleDetails.name == "super_admin") {

      return true;
    } else {

      if (navigationDetails.key == 'dashboard') {

        return true;
      } else if (this.allowedModuleKeys.indexOf(navigationDetails.key) == -1) {
        return true;
      } else {
        return true;
      }
    }
  }
}
