import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { ConsumerCourtService } from "./consumer-court.service";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material";
import { ConsumerCourtDetailComponent } from "../consumer-court-detail/consumer-court-detail.component";
import { MatSnackBar } from '@angular/material';
import {CourtcaseService} from "../addcourtcase/courtcase.service"

@Component({
  selector: "vex-consumer-court",
  templateUrl: "./consumer-court.component.html",
  styleUrls: ["./consumer-court.component.scss"],
})
export class ConsumerCourtComponent implements OnInit {

  pageSizeOptions: number[] = environment.pagination.defaultPageSizeOptions;
  loading = false;
  defaultRecords: any = 10;
  msg;

  selectedOption="option1"

  constructor(private consumerCourtService: ConsumerCourtService, public dialog: MatDialog,private snackBar: MatSnackBar,private CourtcaseService:CourtcaseService) {}

  ngOnInit() {
    this.caseYearDetails();
    this.caseTypeCategoryDetails();
    this.getStateCommissionState();
    this.getDistrictCommissionState();
  }

  NcdrcForm = new FormGroup({
    state_code: new FormControl("0"),
    district_code: new FormControl("0"),
    case_no: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    commission_type: new FormControl("B"),
  });

  StateCommissionForm = new FormGroup({
    state_code: new FormControl("", Validators.required),
    district_code: new FormControl("0"),
    case_no: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    commission_type: new FormControl("C"),
  });

  DistrictCommissionForm = new FormGroup({
    state_code: new FormControl("", Validators.required),
    district_code: new FormControl("", Validators.required),
    case_no: new FormControl("", Validators.required),
    case_year: new FormControl("", Validators.required),
    case_type: new FormControl("", Validators.required),
    commission_type: new FormControl("E"),
  });

  back() {
    window.history.back();
  }

  toggleNcdrc = true;
  toggleStateCommission = false;
  toggleDistrictCommission = false;
  getValue(val) {
    if (val == "Ncdrc") {
      this.toggleNcdrc = true;
      this.toggleStateCommission = false;
      this.toggleDistrictCommission = false;
    } else if (val == "stateCommission") {
      this.toggleNcdrc = false;
      this.toggleStateCommission = true;
      this.toggleDistrictCommission = false;
    } else if (val == "districtCommission") {
      this.toggleNcdrc = false;
      this.toggleStateCommission = false;
      this.toggleDistrictCommission = true;
    }
  }

  StateCommissionState;
  getStateCommissionState() {
    this.consumerCourtService
      .getData('stateCommissionCategory')
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.StateCommissionState = res.stateCommissionCategory;
            console.log(this.StateCommissionState)
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  DistrictCommissionState;
  getDistrictCommissionState() {
    this.consumerCourtService
      .getData('districtCommissionStatesCategory')
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.DistrictCommissionState = res.districtCommissionStatesCategory;
            console.log(this.DistrictCommissionState)
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  districtData;
  state_code;
  getDistrictCommissionId(event) {
    console.log(event)
    this.state_code = event.value.district_commission_state_id;
    let obj = {
      id: event.value.id,
    };
    this.consumerCourtService
      .postData('districtCommissionDistrictsCategory', obj)
      .subscribe(
        (res) => {
          if (res.status == true) {
            this.districtData = res.districtCommissionDistrictsCategory;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }

  caseYearCategory;
  caseYearDetails() {
    this.CourtcaseService
      .postyear('ws/caseYears')
      .subscribe((res) => {
        this.caseYearCategory = res['caseYear'];

        if(this.caseYearCategory){
          this.NcdrcForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          this.DistrictCommissionForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
          this.StateCommissionForm.get('case_year').setValue(this.caseYearCategory[0]['year'])
        }
      });
  }

  caseTypeCategory;
  caseTypeCategoryDetails() {
    this.consumerCourtService
      .getData('caseTypeCategory')
      .subscribe((res) => {
        this.caseTypeCategory = res['caseTypeCategory'];
      });
  }

  totalNcdrcData;
  NcdrcData;
  handleNcdrc=false
  handleNcdrcMsg=false
  getNcdrData() {
    if (this.NcdrcForm.valid) {
      this.loading = true
      console.log(this.NcdrcForm.value)
      this.consumerCourtService
        .postData('caseStatus', this.NcdrcForm.value)
        .subscribe(
          (res) => {
            console.log(res)
            if(res.status==true){
              this.totalNcdrcData = res.caseStatus
              this.NcdrcData = this.totalNcdrcData.slice(0,this.defaultRecords);
              this.handleNcdrc = true
              this.handleNcdrcMsg = false
              this.loading = false
            }else{
              this.msg = res.message
              this.handleNcdrcMsg = true
              this.handleNcdrc = false
              this.loading = false
            }
          },
          (err) => {
            console.log(err)
            this.loading = false;
            this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center'
            });
          }
        );
    }
  }

  totalStateCommissionData;
  handleStateCommission=false;
  handleStateCommissionMsg=false;
  stateCommissionData;
  getStateCommissionData() {
    if (this.StateCommissionForm.valid) {
      this.loading = true
      this.consumerCourtService
        .postData('caseStatus', this.StateCommissionForm.value)
        .subscribe(
          (res) => {
            if(res.status==true){
              this.totalStateCommissionData = res.caseStatus
              this.stateCommissionData = this.totalStateCommissionData.slice(0,this.defaultRecords);
              this.handleStateCommission=true 
              this.handleStateCommissionMsg = false
              this.loading = false
            }else{
              this.msg = res.message
              this.handleStateCommissionMsg = true
              this.handleStateCommission = false;
              this.loading = false
            }
          },
          (err) => {
            console.log(err);
            this.loading = false;
            this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center'
            });
          }
        );
    }
  }

  totalDistrictCommissionData;
  handleDistrictCommission=false;
  handleDistrictCommissionMsg=false;
  districtCommissionData;
  getDistrictCommissionData() {
    this.DistrictCommissionForm.value.state_code = this.state_code;

    if (this.DistrictCommissionForm.valid) {
      this.loading = true
      console.log(this.DistrictCommissionForm.value)
      this.consumerCourtService
        .postData('caseStatus', this.DistrictCommissionForm.value)
        .subscribe(
          (res) => {
            if(res.status==true){
              this.totalDistrictCommissionData = res.caseStatus
              this.districtCommissionData = this.totalDistrictCommissionData.slice(0,this.defaultRecords);
              this.handleDistrictCommission = true
              this.handleDistrictCommissionMsg = false
              this.loading = false
            }else{
              this.msg = res.message
              this.handleDistrictCommissionMsg = true
              this.handleDistrictCommission = false
              this.loading = false
            }
          },
          (err) => {
            console.log(err);
            this.loading = false;
            this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
              duration: 3000,
              horizontalPosition: 'center'
            });
          }
        );
    }
  }

  onPageChange($event,val) {
    if(val=='ncdrc'){
      this.NcdrcData = this.totalNcdrcData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else if(val=='stateCommission'){
      this.stateCommissionData = this.totalStateCommissionData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
    else if(val=='districtCommission'){
      this.stateCommissionData = this.totalStateCommissionData.slice($event.pageIndex*$event.pageSize, $event.pageIndex*$event.pageSize + $event.pageSize);
    }
  }

  getStateCode(state_code){
    this.state_code=state_code;
  }

  district_code:any
  getDistrict(district_code){
    this.district_code=district_code;
  }

  case_noSplit:any
  casedetailbyid(data){
    this.case_noSplit=data.case_no.split('/');
    data['state_code']=this.state_code;
    data['district']=this.district_code;
    data['caseName']=`${data.complainant} vs. ${data.respondent}`;
    data['case_year']=this.case_noSplit[2];
    this.dialog.open(ConsumerCourtDetailComponent, {
      height: '800px',
      width: '950px',
      autoFocus: false,
      data:data
    })
  }
}
