import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
// import { CourtCasesService } from '../addcase/court-cases.service';
import { SocketService } from "src/app/services/core/socket.service";
import { CommonService } from "src/app/services/core/common.service";
import { environment } from "src/environments/environment";
import { MatSnackBar, MatTabChangeEvent } from "@angular/material";
import {
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialog,
} from "@angular/material/dialog";
import icClose from "@iconify/icons-ic/twotone-close";
import {
  DocumentEditorComponent,
  SfdtExportService,
  SelectionService,
  EditorService,
  EditorHistoryService,
} from "@syncfusion/ej2-angular-documenteditor";
import { CourtcaseService } from "../addcourtcase/courtcase.service";
// import 'rxjs/add/operator/filter';
export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
  { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
  { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
  { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
  { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
  { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
  { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
  { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
  { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
];

@Component({
  selector: "vex-autocourtcase-detal",
  templateUrl: "./autocourtcase-detal.component.html",
  styleUrls: ["./autocourtcase-detal.component.scss"],
  encapsulation: ViewEncapsulation.None,
  //Inject require modules.
  providers: [
    SfdtExportService,
    SelectionService,
    EditorService,
    EditorHistoryService,
  ],
})
export class AutocourtcaseDetalComponent implements OnInit {
  private socketObject;
  displayedColumns: string[] = [
    "demo-position",
    "demo-name",
    "demo-weight",
    "demo-symbol",
  ];
  dataSource = ELEMENT_DATA;

  caseid = "";
  noticedetails: any;
  judgementdetails: any;
  earlierCourtDetail: any;
  caseListingDates: any;
  caseInterlocutoryApplicationDocuments: any;
  caseOtherApplicationDocuments: any;
  caseCourtFees: any;
  caseCaseDefects: any;
  casedetail: any;
  courtSimilarities: any;
  taggedMatter: any;
  courtCaveat: any;
  caseInfo: any;
  datadetail: any;
  errormsg = "";
  detaildata: any;
  officereportdetails: any;
  caseIndexingInfo: any;
  caseAppearanceInfo: any;
  courtDropNoteInfo: any;
  courtMentionInfo: any;
  courtRestorationInfo: any;
  newdata: any;

  constructor(
    private route: ActivatedRoute,
    private court: CourtcaseService,
    private socketService: SocketService,
    private snackBar: MatSnackBar,
    private commonServices: CommonService,
    @Inject(MAT_DIALOG_DATA) data,
    private dialogRef: MatDialogRef<AutocourtcaseDetalComponent>
  ) {
    if (data) {
      this.datadetail = data;
      // this.caseid=data.caseId;
    }
    // console.log(this.datadetail, "calling from supreme court");

    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  ngOnInit() {
    if (this.datadetail.code == 1) {
      let obj = {
        diary_no: this.datadetail.diary_only + "",
        diary_year: this.datadetail.diary_year,
        flag: "1",
      };

      this.caseid = this.datadetail.id;
      if (this.caseid) {
        this.getcase();
        this.getcasedetails();
        this.getjudgementdetails();
        this.getearlierCourtdetails();
        this.getlistingDatedetails();
        this.getinterlocutoryApplicationDocumentdetails();
        this.getApplicationDocumentdetails();
        this.getcourtFeedetails();
        this.getcaseDefectdetails();
        this.getcourtSimilaritydetails();
        this.getcourtTaggedMatterdetails();
        this.getcourtCaveatsdetails();
        this.getcourtcaseyiddetails();
        this.getofficereportdetail();
        this.getrestorationdetail();
        this.getindexingdetail();
        this.getapperencedetail();
        this.getdropnotedetail();
        this.getmentionmemodetail();
        this.court
          .post("ws/autoUpdateCourtCase", obj)
          .subscribe((res2: any) => {
            this.newdata = res2.autoUpdateCourtCase[0];
          });
      } else {
        // console.log("else condition running");
        this.errormsg = "We Could not Found Any case your search criteria";
      }
    } else if (this.datadetail.code == 2) {
      console.log(this.datadetail)
      let obj = {
        diary_no: this.datadetail.diary_only + "",
        diary_year: this.datadetail.diary_year,
        flag: "1",
      };

      this.caseid = this.datadetail.id;
      if (this.caseid) {
        this.getcase();
        this.getcasedetails();
        this.getjudgementdetails();
        this.getearlierCourtdetails();
        this.getlistingDatedetails();
        this.getinterlocutoryApplicationDocumentdetails();
        this.getApplicationDocumentdetails();
        this.getcourtFeedetails();
        this.getcaseDefectdetails();
        this.getcourtSimilaritydetails();
        this.getcourtTaggedMatterdetails();
        this.getcourtCaveatsdetails();
        this.getcourtcaseyiddetails();
        this.getofficereportdetail();
        this.getrestorationdetail();
        this.getindexingdetail();
        this.getapperencedetail();
        this.getdropnotedetail();
        this.getmentionmemodetail();
        this.court
          .post("ws/autoUpdateCourtCase", obj)
          .subscribe((res2: any) => {
            this.newdata = res2.autoUpdateCourtCase[0];
          });
      } else {
        // console.log("else condition running");
        this.errormsg = "We Could not Found Any case your search criteria";
      }
    } else if (this.datadetail.code == 3) {
      let obj = {
        diary_no: this.datadetail.diary_no + "",
        diary_year: this.datadetail.diary_year,
        flag: "1",
      };

      let detaildata;
      this.court.post("ws/courtCase", obj).subscribe((res2: any) => {
        console.log(res2, "rsffff");
        if (res2.status) {
          detaildata = res2.caseInfo;
          // this.detaildata = res2.caseInfo;
          this.caseid = detaildata.id;
        }

        if (this.caseid) {
          this.getcase();
          this.getcasedetails();
          this.getjudgementdetails();
          this.getearlierCourtdetails();
          this.getlistingDatedetails();
          this.getinterlocutoryApplicationDocumentdetails();
          this.getApplicationDocumentdetails();
          this.getcourtFeedetails();
          this.getcaseDefectdetails();
          this.getcourtSimilaritydetails();
          this.getcourtTaggedMatterdetails();
          this.getcourtCaveatsdetails();
          this.getcourtcaseyiddetails();
          this.getofficereportdetail();
          this.getrestorationdetail();
          this.getindexingdetail();
          this.getapperencedetail();
          this.getdropnotedetail();
          this.getmentionmemodetail();
          // this.court
          //   .post("ws/autoUpdateCourtCase", obj)
          //   .subscribe((res2: any) => {
          //     this.newdata = res2.autoUpdateCourtCase[0];
          //     console.log(this.newdata,'this.newdata')
          //   });
        } else {
          // console.log("else condition running");
          this.errormsg = "We Could not Found Any case your search criteria";
        }
      });
    }
  }

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getcasedetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtNotice", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.noticedetails = res2.caseCourtNotices;
    });
  }

  getofficereportdetail() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/officeReport", obj).subscribe((res2: any) => {
      console.log(res2, "after office report detail");
      this.officereportdetails = res2.officeReportInfo;
    });
  }

  getindexingdetail() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseIndexing", obj).subscribe((res2: any) => {
      console.log(res2, "after office report detail");
      this.caseIndexingInfo = res2.caseIndexingInfo;
    });
  }

  getapperencedetail() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseAppearance", obj).subscribe((res2: any) => {
      console.log(res2, "after office report detail");
      this.caseAppearanceInfo = res2.caseAppearanceInfo;
    });
  }

  getdropnotedetail() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtDropNotes", obj).subscribe((res2: any) => {
      console.log(res2, "after office report detail");
      this.courtDropNoteInfo = res2.courtDropNoteInfo;
    });
  }

  getmentionmemodetail() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtMentionMemo", obj).subscribe((res2: any) => {
      console.log(res2, "after office report detail");
      this.courtMentionInfo = res2.courtMentionInfo;
    });
  }

  getrestorationdetail() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtRestorationDetail", obj).subscribe((res2: any) => {
      console.log(res2, "after office report detail");
      this.courtRestorationInfo = res2.courtRestorationInfo;
    });
  }

  getjudgementdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/judgement", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.judgementdetails = res2.caseJudgement;
    });
  }

  getearlierCourtdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/earlierCourtDetail", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.earlierCourtDetail = res2.earlierCourt;
    });
  }

  getlistingDatedetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/listingDate", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.caseListingDates = res2.caseListingDates;
    });
  }

  getinterlocutoryApplicationDocumentdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court
      .post("ws/interlocutoryApplicationDocument", obj)
      .subscribe((res2: any) => {
        console.log(res2, "after case detail");
        this.caseInterlocutoryApplicationDocuments =
          res2.caseInterlocutoryApplicationDocuments;
      });
  }

  getApplicationDocumentdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court
      .post("ws/otherApplicationDocument", obj)
      .subscribe((res2: any) => {
        console.log(res2, "after case detail");
        this.caseOtherApplicationDocuments = res2.caseOtherApplicationDocuments;
      });
  }

  getcourtFeedetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtFee", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.caseCourtFees = res2.caseCourtFees;
    });
  }

  getcaseDefectdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseDefect", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.caseCaseDefects = res2.caseCaseDefects;
    });
  }

  getcase() {
    let obj = {
      case_id: this.caseid,
    };
    console.log(obj)
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/caseDetail", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      if (res2.status) {
        this.casedetail = res2.caseDetails[0];
      }
    });
  }

  getcourtSimilaritydetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtSimilarity", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.courtSimilarities = res2.courtSimilarities;
    });
  }

  getcourtTaggedMatterdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtTaggedMatter", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.taggedMatter = res2.taggedMatter;
    });
  }

  getcourtCaveatsdetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtCaveats", obj).subscribe((res2: any) => {
      console.log(res2, "after case detail");
      this.courtCaveat = res2.courtCaveat;
    });
  }

  getcourtcaseyiddetails() {
    let obj = {
      case_id: this.caseid,
    };
    // https://api.mysita.com/public/ws/courtNotice
    this.court.post("ws/courtCaseByID", obj).subscribe((res2: any) => {
      console.log(res2, "court gggggggg case detail");
      this.caseInfo = res2.caseInfo;
    });
  }

  // ws/courtCaseByID

  back() {
    window.history.back();
  }

  save() {
    if (this.socketService.isSocketConnected()) {
      let tmpObj = this;
      console.log(this.newdata);
      this.newdata["case_no"] = this.datadetail.case_no;
      this.newdata["case_type_id"] = this.datadetail.case_type;
      this.newdata["case_year"] = this.datadetail.case_year;

      console.log(this.newdata);
      this.socketObject.emit(
        environment.socketEvents.createAutoCourtCase,
        this.newdata,
        function (socketResponse) {
          if (socketResponse.status == "OK") {
            tmpObj.snackBar.open("Successfully Created Court Case.", "Okay", {
              duration: 4000,
            });
            // tmpObj.refreshData(tmpObj);
          } else {
            tmpObj.snackBar.open(socketResponse.message, "Okay", {
              duration: 4000,
            });
          }
        }
      );
    }
  }
  close() {
    this.dialogRef.close();
  }

  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert("No data Found");
    }
  }
  // listingDate
}
