import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
@Component({
  selector: 'vex-courtcasedirectcreate',
  templateUrl: './courtcasedirectcreate.component.html',
  styleUrls: ['./courtcasedirectcreate.component.scss']
})
export class CourtcasedirectcreateComponent implements OnInit {
  constructor( private router: Router,) { }
  myData:string
  ngOnInit() {
  }
  createCase(){
    // this.dialogRef.close(customer);

  }

  gotocourtpage(data){
    if(data=='Supreme Court'){
   
      this.router.navigate(["/app/cases-management/supremecourt"]);
    }

    if(data=='District Courts'){
      console.log(data,"data")
 
       this.router.navigate(["/app/cases-management/district"]);
     }

     if(data=='High Courts'){
      console.log(data,"data")
 
       this.router.navigate(["/app/cases-management/highcourt"]);
     }

    if(data=='Consumer Forum'){
      this.router.navigate(["/app/cases-management/consumerforum"]);
    }

    if(data=='Tribunals'){
      this.router.navigate(["/app/cases-management/Tribunals"]);
    }

    if(data == 'NCLT'){
      this.router.navigate(["/app/cases-management/NCLT"])
    }
    if(data == 'Custom'){
      //  this.myData = data
      let includeParams = true
      //  const queryParams = includeParams ? { param1: 'value1'} : {};
      //  this.router.navigate(['/app/cases-management/court-cases'], { queryParams });

      const queryParams = includeParams ? {value:'custom'}:{}
      this.router.navigate(['/app/cases-management/court-cases'], {queryParams})

    }
    // District Courts
  }

  back(){
    this.router.navigate(["/app/cases-management/mycases"]);
  }

}
