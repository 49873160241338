import { Component, OnInit, Inject } from "@angular/core";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { TribunalService } from "../tribunal-court/tribunal.service";
import { environment } from "src/environments/environment";
import { Thickness } from "@syncfusion/ej2-charts";
import { TribunalCaseHistoryDetailComponent } from "../tribunal-case-history-detail/tribunal-case-history-detail.component";
import { MatSnackBar } from "@angular/material";
import { SocketService } from "src/app/services/core/socket.service";
import { CommonService } from "src/app/services/core/common.service";
// import data from '@iconify/icons-ic/twotone-notifications';

@Component({
  selector: "vex-tribunal-court-detail",
  templateUrl: "./tribunal-court-detail.component.html",
  styleUrls: ["./tribunal-court-detail.component.scss"],
})
export class TribunalCourtDetailComponent implements OnInit {
  msg;

  constructor(
    public dialogRef: MatDialogRef<TribunalCourtDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private tribunalService: TribunalService,
    public dialog: MatDialog,private snackBar: MatSnackBar,
    private socketService: SocketService,
    private commonServices: CommonService,
  ) {
    const token = this.commonServices.getAccessToken();
    if (token) {
      if (!this.socketService.isSocketConnected()) {
        this.socketService.initSocket(token);
      }
      this.socketObject = this.socketService.getSocketObject();
    }
  }

  private socketObject;

  ngOnInit() {
    this.getCaseDetils();
  }

  MatSprnnerOfSection=false

  showServerErrOfapplicantDetail = ''
  showServerErrOfrespondentDetail=''
  showServerErrOfapplicantLegalRepresentative=''
  showServerErrOfrespondentLegalRepresentative=''
  showServerErrOffirstHearingDetail=''
  showServerErrOfLastHearingDetail=''
  showServerErrOfNextHearingDetail=''
  showServerErrOfCaseHistory=''
  showServerErrOforderHistory=''
  showServerErrOfIasOtherApplication=''
  showServerErrOfConnectedCases=''

  handleMatSprnner = true;
  showHeader = false;
  showErrMsg = false;
  t1;t2;
  filingNo;
  caesDetailData;



  getCaseDetils() {
    this.showHeader = false;
    let obj = {};
    obj["location"] = this.data.location;
    obj["filing_no"] = this.data.filing_no;

    this.tribunalService
      .postData('caseDetails', obj)
      .subscribe((res) => {
        let caseTitle = this.data.case_title.split("VS");
        this.t1 = caseTitle[0];
        this.t2 = caseTitle[1];
        this.filingNo = this.data.filing_no;

        if (res.status == true) {
          this.caesDetailData = res.caseDetails[0];
          this.partyDetail()
          this.handleMatSprnner = false;
          this.showHeader = true;

          this.getNextHearingDetail()
        } else {
          this.msg = res.message;
          this.handleMatSprnner = false;
          this.showErrMsg = true;
        }
      },(err)=>{
        this.dialogRef.close();
        this.snackBar.open('Facing Some Server Issue Please Try After Some Time', 'CLOSE', {
          duration: 3000,
          horizontalPosition: 'center'
        });
      }
      );
  }


  countofparty=0
  partyDetail(){
    if(this.countofparty==0){
      this.MatSprnnerOfSection=true
      this.applicantDetail();
      this.respondentDetail();
    }
  }

  applicant_nameArr = [];
  applicantDetail() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.tribunalService
      .postData('applicantName', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.applicant_nameArr.push(...res.applicantName);

        }
      },(err)=>{
        this.showServerErrOfapplicantDetail = 'Facing Some Server Issue in applicant Name Please Try After Some Time'
      });
  }

  Respodent_nameArr = [];
  respondentDetail() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;

    this.tribunalService
      .postData('respondantName', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.Respodent_nameArr.push(...res.respondantName);

          this.MatSprnnerOfSection=false
          this.countofparty=1
        }else{
          this.MatSprnnerOfSection=false
          this.countofparty=1
        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countofparty=1
        this.showServerErrOfrespondentDetail = 'Facing Some Server Issue in respondent Name Please Try After Some Time'
      });
  }

  countoflegal=0
  legalRepresentative(){
    if(this.countoflegal==0){
      this.MatSprnnerOfSection=true
      this.applicantLegalRepresentative()
      this.respondentLegalRepresentative()
    }
  }

  applicant_legal_representative_name = [];
  applicantLegalRepresentative() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.tribunalService
      .postData('applicantLegalRepresentative', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.applicant_legal_representative_name.push(
            ...res.applicantLegalRepresentative
          );

        }
      },(err)=>{
        this.showServerErrOfapplicantLegalRepresentative = 'Facing Some Server Issue in applicantLegalRepresentative Name Please Try After Some Time'
      });
  }

  respondent_legal_representative_name = [];
  respondentLegalRepresentative() {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    this.tribunalService
      .postData('respondentLegalRepresentative', obj)
      .subscribe((res) => {
        if (res.status == true) {
          this.respondent_legal_representative_name.push(
            ...res.respondentLegalRepresentative
          );
          this.MatSprnnerOfSection=false
          this.countoflegal=1

        }else{
          this.MatSprnnerOfSection=false
          this.countoflegal=1

        }
      },(err)=>{
        this.MatSprnnerOfSection=false
        this.countoflegal=1

        this.showServerErrOfrespondentLegalRepresentative = 'Facing Some Server Issue in respondentLegalRepresentative Name Please Try After Some Time'
      });
  }

  countoffirst=0
  firstHearingDetail = {};
  getfirstHearingDetail() {
    if(this.countoffirst == 0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('firstHearingDetails', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.firstHearingDetail = { ...res.firstHearingDetails[0] };

            this.MatSprnnerOfSection=false
            this.countoffirst=1

          }else{
            this.MatSprnnerOfSection=false
            this.countoffirst=1

          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countoffirst=1
          this.showServerErrOffirstHearingDetail = 'Facing Some Server Issue in firstHearingDetail Please Try After Some Time'
        });
    }
  }

  countoflast=0
  lastHearingDetail = {};
  getLastHearingDetail() {
    if(this.countoflast == 0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('lastHearingDetails', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.lastHearingDetail = { ...res.lastHearingDetails[0] };
            this.MatSprnnerOfSection=false
            this.countoflast=1
          }else{
            this.MatSprnnerOfSection=false
            this.countoflast=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countoflast=1
          this.showServerErrOfLastHearingDetail = 'Facing Some Server Issue in LastHearingDetail Please Try After Some Time'
        });
    }
  }

  countofnext=0
  nextHearingDetail = {};
  getNextHearingDetail() {
    if(this.countofnext == 0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('nextHearingDetails', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.nextHearingDetail = { ...res.nextHearingDetails[0] };
            this.MatSprnnerOfSection=false
            this.countofnext=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofnext=1 
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofnext=1 
          this.showServerErrOfNextHearingDetail = 'Facing Some Server Issue in NextHearingDetail Please Try After Some Time'
        });
    }
  }

  countofcasehistory=0
  caseHistory = [];
  getCaseHistory() {
    if(this.countofcasehistory==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('caseHistory', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.caseHistory.push(...res.caseHistory);
            this.MatSprnnerOfSection=false
            this.countofcasehistory=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofcasehistory=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofcasehistory=1
          this.showServerErrOfCaseHistory = 'Facing Some Server Issue in CaseHistory Please Try After Some Time'
        });
    }
  }

  countoforderhistory=0
  orderHistory = [];
  getorderHistory() {
    if(this.countoforderhistory==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('orderHistory', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.orderHistory.push(...res.orderHistory);
            this.MatSprnnerOfSection=false
            this.countoforderhistory=1
          }else{
            this.MatSprnnerOfSection=false
            this.countoforderhistory=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countoforderhistory=1
          this.showServerErrOforderHistory = 'Facing Some Server Issue in orderHistory Please Try After Some Time'
        });
    }
  }

  countofias=0
  iasOtherApplication = [];
  getIasOtherApplication() {
    if(this.countofias==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('iasOtherApplication', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.iasOtherApplication.push(...res.iasOtherApplication);
            this.MatSprnnerOfSection=false
            this.countofias=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofias=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofias=1
          this.showServerErrOfIasOtherApplication = 'Facing Some Server Issue in IasOtherApplication Please Try After Some Time'
        });
    }
  }

  countofconnected=0
  connectedCases = [];
  getConnectedCases() {
    if(this.countofconnected==0){
      this.MatSprnnerOfSection=true
      let obj = {};
      obj["court_case_id"] = this.caesDetailData.id;
      this.tribunalService
        .postData('connectedCases', obj)
        .subscribe((res) => {
          if (res.status == true) {
            this.connectedCases.push(...res.connectedCases);
            this.MatSprnnerOfSection=false
            this.countofconnected=1
          }else{
            this.MatSprnnerOfSection=false
            this.countofconnected=1
          }
        },(err)=>{
          this.MatSprnnerOfSection=false
          this.countofconnected=1
          this.showServerErrOfConnectedCases = 'Facing Some Server Issue in ConnectedCases Please Try After Some Time'
        });
    }
  }

    // save data into database function
    saveData(){
      console.log(this.data,"lfhglkdfjh")
      this.caesDetailData['caseName']=this.data.case_title;
      this.caesDetailData['case_type']=this.data.case_type;
      this.caesDetailData['case_number']=this.data.case_number;
      this.caesDetailData['case_year']=this.data.case_year;
      this.caesDetailData['hearing_date']=this.nextHearingDetail['hearing_date'];
      this.caesDetailData['topic']=this.nextHearingDetail['proceedings_summary'];
      this.caesDetailData['court']=this.nextHearingDetail['court_no'];
      this.caesDetailData['petitioner']=this.applicant_nameArr;
      this.caesDetailData['respondent']=this.Respodent_nameArr;

      if (this.socketService.isSocketConnected()) {
        let tmpObj = this;
        this.socketObject.emit(environment.socketEvents.createTribunal,  this.caesDetailData , function (socketResponse) {
          if (socketResponse.status == 'OK') {
            tmpObj.snackBar.open('Successfully Created Tribunal court cases', 'Okay', {
              duration: 4000
            });
            // tmpObj.refreshData(tmpObj); 
          } else {
            tmpObj.snackBar.open(socketResponse.message, 'Okay', {
              duration: 4000
            });
            // this.dialogRef.close();
          }
        });
        // this.dialogRef.close();
      }
    }

  viewDetailOfOrderHistyory(data) {
    let obj = {};
    obj["court_case_id"] = this.caesDetailData.id;
    obj["case_history_id"] = data.id;
    obj["t1"] = this.t1;
    obj["t2"] = this.t2;

    this.dialog.open(TribunalCaseHistoryDetailComponent, {
      height: "480px",
      width: "700px",
      autoFocus: false,
      data: obj,
    });
  }

  goToLink(url: string) {
    if (url != null) {
      window.open(url, "_blank");
    } else {
      alert("No data Found");
    }
  }

  step = 0;


  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
    if(this.step==1){
      this.partyDetail()
    }
    if(this.step==2){
      this.legalRepresentative()
    }
    if(this.step==3){
      this.getfirstHearingDetail()
    }
    if(this.step==4){
      this.getLastHearingDetail()
    }
    if(this.step==5){
      this.getNextHearingDetail()
    }
    if(this.step==6){
      this.getCaseHistory()
    }
    if(this.step==7){
      this.getorderHistory()
    }
    if(this.step==8){
      this.getIasOtherApplication()
    }
    if(this.step==9){
      this.getConnectedCases()
    }
  }

  prevStep() {
    this.step--;
    if(this.step==1){
      this.partyDetail()
    }
    if(this.step==2){
      this.legalRepresentative()
    }
    if(this.step==3){
      this.getfirstHearingDetail()
    }
    if(this.step==4){
      this.getLastHearingDetail()
    }
    if(this.step==5){
      this.getNextHearingDetail()
    }
    if(this.step==6){
      this.getCaseHistory()
    }
    if(this.step==7){
      this.getorderHistory()
    }
    if(this.step==8){
      this.getIasOtherApplication()
    }
    if(this.step==9){
      this.getConnectedCases()
    }
  }
}
