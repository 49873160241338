import Color from 'color';
import theme from '../@vex/utils/tailwindcss';

export const aioTableLabels = [
  {
    text: 'New',
    backgroundColor: Color(theme.colors.green['500']).fade(0.9),
    color: theme.colors.green['500']
  },
  {
    text: 'Lead',
    backgroundColor: Color(theme.colors.cyan['500']).fade(0.9),
    color: theme.colors.cyan['500']
  },
  {
    text: 'In Progress',
    backgroundColor: Color(theme.colors.teal['500']).fade(0.9),
    color: theme.colors.teal['500']
  },
  {
    text: 'Completed',
    backgroundColor: Color(theme.colors.purple['500']).fade(0.9),
    color: theme.colors.purple['500']
  },
];

export const rolesDummyData = [
  {
    id: 0,
    roleName: "admin",
    modulesAllowed: ["usersManagement", "rolesManagement", "departmentsManagement"]
  },
  {
    id: 1,
    roleName: "management",
    modulesAllowed: ["casesManagement", "calendarView", "appointmentsManagement"]
  },
  {
    id: 2,
    roleName: "user",
    modulesAllowed: ["legalDocsManagement", "calendarView", "hearingsManagement"]
  }
];

export const reportsDummyData = [
  {
    id: 0,
    name: "weeklyreport.xlxs",
    type: "weekly report"
    //userAllowed: []
  },
];

export const reportDummyData = [
  {
    id: 0,
    name: "dashbord_report",
    //userAllowed: []
  },
];

export const userDummyData = [
  {
    id: 0,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Dejesus',
    lastName: 'Chang',
    empCode: "EMP-1",
    department: "1",
    designation: "2",
    division: "1",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "dejesus.chang@hiscompany.biz",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 1,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Short',
    lastName: 'Lowe',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "short.lowe@yourcompany.ca",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 2,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 3,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 4,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 5,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 0,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 0,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 6,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 7,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 8,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 9,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 10,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 11,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 12,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
  {
    id: 13,
    profileImage: "assets/users_profile/user-default.png",
    firstName: 'Antoinette',
    lastName: 'Carson',
    empCode: "EMP-1",
    department: "2",
    designation: "2",
    division: "2",
    primaryPhone: "+91 6321101111",
    phone: "+91 6321101111",
    email: "antoinette.carson@yourcompany.net",
    role: "admin",
    notes: "Some Note"
  },
];

export const aioTableData = [
  {
    id: 0,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Dejesus',
    lastName: 'Chang',
    street: '899 Raleigh Place',
    zipcode: 8057,
    city: 'Munjor',
    phoneNumber: '+32 (818) 580-3557',
    mail: 'dejesus.chang@yourcompany.biz',
    labels: [aioTableLabels[0], aioTableLabels[1]]
  },
  {
    id: 1,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Short',
    lastName: 'Lowe',
    street: '548 Cypress Avenue',
    zipcode: 5943,
    city: 'Temperanceville',
    phoneNumber: '+11 (977) 574-3636',
    mail: 'short.lowe@yourcompany.ca',
    labels: [aioTableLabels[1]]
  },
  {
    id: 2,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Antoinette',
    lastName: 'Carson',
    street: '299 Roder Avenue',
    zipcode: 7894,
    city: 'Crayne',
    phoneNumber: '+49 (969) 505-3323',
    mail: 'antoinette.carson@yourcompany.net',
    labels: [aioTableLabels[3]]
  },
  {
    id: 3,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Lynnette',
    lastName: 'Adkins',
    street: '158 Cyrus Avenue',
    zipcode: 4831,
    city: 'Coyote',
    phoneNumber: '+48 (836) 545-3237',
    mail: 'lynnette.adkins@yourcompany.info',
    labels: [aioTableLabels[3]]
  },
  {
    id: 4,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Patrica',
    lastName: 'Good',
    street: '995 Kansas Place',
    zipcode: 4679,
    city: 'Whitmer',
    phoneNumber: '+36 (955) 485-3652',
    mail: 'patrica.good@yourcompany.me',
    labels: [aioTableLabels[0]]
  },
  {
    id: 5,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Kane',
    lastName: 'Koch',
    street: '779 Lynch Street',
    zipcode: 6178,
    city: 'Newcastle',
    phoneNumber: '+35 (983) 587-3423',
    mail: 'kane.koch@yourcompany.org',
    labels: [aioTableLabels[1]]
  },
  {
    id: 6,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Donovan',
    lastName: 'Gonzalez',
    street: '781 Knickerbocker Avenue',
    zipcode: 532,
    city: 'Frizzleburg',
    phoneNumber: '+47 (914) 469-3270',
    mail: 'donovan.gonzalez@yourcompany.tv',
    labels: [aioTableLabels[2]]
  },
  {
    id: 7,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Sabrina',
    lastName: 'Logan',
    street: '112 Glen Street',
    zipcode: 4763,
    city: 'Frystown',
    phoneNumber: '+37 (896) 474-3143',
    mail: 'sabrina.logan@yourcompany.co.uk',
    labels: [aioTableLabels[0], aioTableLabels[1]]
  },
  {
    id: 8,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Estela',
    lastName: 'Jordan',
    street: '626 Stryker Court',
    zipcode: 9966,
    city: 'Blende',
    phoneNumber: '+2 (993) 445-3626',
    mail: 'estela.jordan@yourcompany.name',
    labels: [aioTableLabels[0]]
  },
  {
    id: 9,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Rosanna',
    lastName: 'Cross',
    street: '540 Fiske Place',
    zipcode: 4204,
    city: 'Bellfountain',
    phoneNumber: '+12 (877) 563-2737',
    mail: 'rosanna.cross@yourcompany.biz',
    labels: [aioTableLabels[2]]
  },
  {
    id: 10,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Mary',
    lastName: 'Jane',
    street: '233 Glen Place',
    zipcode: 4221,
    city: 'Louisville',
    phoneNumber: '+15 (877) 334-2231',
    mail: 'Mary.jane@yourcompany.biz',
    labels: [aioTableLabels[1]]
  },
  {
    id: 11,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Lane',
    lastName: 'Delaney',
    street: 'Langham Street',
    zipcode: 6411,
    city: 'Avoca',
    phoneNumber: '+1 (969) 570-2843',
    mail: 'lane.delaney@yourcompany.ca',
    labels: [aioTableLabels[3]]
  },
  {
    id: 12,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Cooper',
    lastName: 'Odom',
    street: 'Shale Street',
    zipcode: 5286,
    city: 'Joes',
    phoneNumber: '+1 (812) 535-2368',
    mail: 'cooper.odom@yourcompany.info',
    labels: [aioTableLabels[3]]
  },
  {
    id: 13,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Kirby',
    lastName: 'Hardin',
    street: 'Rodney Street',
    zipcode: 4864,
    city: 'Finzel',
    phoneNumber: '+1 (838) 519-3416',
    mail: 'kirby.hardin@yourcompany.us',
    labels: [aioTableLabels[3]]
  },
  {
    id: 14,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Marquita',
    lastName: 'Haynes',
    street: 'Townsend Street',
    zipcode: 9000,
    city: 'Walland',
    phoneNumber: '+1 (965) 482-2100',
    mail: 'marquita.haynes@yourcompany.me',
    labels: [aioTableLabels[2]]
  },
  {
    id: 15,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Horton',
    lastName: 'Townsend',
    street: 'Gunnison Court',
    zipcode: 9519,
    city: 'Nettie',
    phoneNumber: '+1 (941) 434-2481',
    mail: 'horton.townsend@yourcompany.biz',
    labels: [aioTableLabels[0]]
  },
  {
    id: 16,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Carrie',
    lastName: 'Bond',
    street: 'Bushwick Court',
    zipcode: 4345,
    city: 'Colton',
    phoneNumber: '+1 (854) 556-2844',
    mail: 'carrie.bond@yourcompany.biz',
    labels: [aioTableLabels[0]]
  },
  {
    id: 17,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Carroll',
    lastName: 'Pugh',
    street: 'Baltic Street',
    zipcode: 8174,
    city: 'Innsbrook',
    phoneNumber: '+1 (989) 561-2440',
    mail: 'carroll.pugh@yourcompany.tv',
    labels: [aioTableLabels[0]]
  },
  {
    id: 18,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Fuller',
    lastName: 'Espinoza',
    street: 'Dooley Street',
    zipcode: 9034,
    city: 'Maybell',
    phoneNumber: '+1 (807) 417-3508',
    mail: 'fuller.espinoza@yourcompany.name',
    labels: [aioTableLabels[1]]
  },
  {
    id: 19,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Lamb',
    lastName: 'Herring',
    street: 'Exeter Street',
    zipcode: 2246,
    city: 'Fowlerville',
    phoneNumber: '+1 (950) 429-3240',
    mail: 'lamb.herring@yourcompany.com',
    labels: [aioTableLabels[2]]
  },
  {
    id: 20,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Liza',
    lastName: 'Price',
    street: 'Homecrest Avenue',
    zipcode: 8843,
    city: 'Idledale',
    phoneNumber: '+1 (989) 483-2305',
    mail: 'liza.price@yourcompany.net',
    labels: [aioTableLabels[1]]
  },
  {
    id: 21,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Monroe',
    lastName: 'Head',
    street: 'Arlington Avenue',
    zipcode: 2792,
    city: 'Garberville',
    phoneNumber: '+1 (921) 598-2475',
    mail: 'monroe.head@yourcompany.io',
    labels: [aioTableLabels[1]]
  },
  {
    id: 22,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Lucile',
    lastName: 'Harding',
    street: 'Division Place',
    zipcode: 8572,
    city: 'Celeryville',
    phoneNumber: '+1 (823) 429-3500',
    mail: 'lucile.harding@yourcompany.org',
    labels: [aioTableLabels[0]]
  },
  {
    id: 23,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Edna',
    lastName: 'Richard',
    street: 'Harbor Lane',
    zipcode: 8323,
    city: 'Lindisfarne',
    phoneNumber: '+1 (970) 580-3162',
    mail: 'edna.richard@yourcompany.ca',
    labels: [aioTableLabels[0]]
  },
  {
    id: 24,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Avila',
    lastName: 'Lancaster',
    street: 'Kay Court',
    zipcode: 9294,
    city: 'Welch',
    phoneNumber: '+1 (817) 412-3752',
    mail: 'avila.lancaster@yourcompany.info',
    labels: [aioTableLabels[0]]
  },
  {
    id: 25,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Carlene',
    lastName: 'Newman',
    street: 'Atlantic Avenue',
    zipcode: 2230,
    city: 'Eagleville',
    phoneNumber: '+1 (953) 483-3110',
    mail: 'carlene.newman@yourcompany.us',
    labels: [aioTableLabels[3]]
  },
  {
    id: 26,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Griffith',
    lastName: 'Wise',
    street: 'Perry Terrace',
    zipcode: 9564,
    city: 'Iola',
    phoneNumber: '+1 (992) 447-3392',
    mail: 'griffith.wise@yourcompany.me',
    labels: [aioTableLabels[0]]
  },
  {
    id: 27,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Schwartz',
    lastName: 'Dodson',
    street: 'Dorset Street',
    zipcode: 4425,
    city: 'Dexter',
    phoneNumber: '+1 (923) 504-2799',
    mail: 'schwartz.dodson@yourcompany.biz',
    labels: [aioTableLabels[1]]
  },
  {
    id: 28,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Susanna',
    lastName: 'Kidd',
    street: 'Loring Avenue',
    zipcode: 6432,
    city: 'Cascades',
    phoneNumber: '+1 (854) 456-2734',
    mail: 'susanna.kidd@yourcompany.biz',
    labels: [aioTableLabels[1]]
  },
  {
    id: 29,
    imageSrc: 'assets/users_profile/user-default.png',
    firstName: 'Deborah',
    lastName: 'Weiss',
    street: 'Haring Street',
    zipcode: 2989,
    city: 'Barstow',
    phoneNumber: '+1 (833) 465-3036',
    mail: 'deborah.weiss@yourcompany.tv',
    labels: [aioTableLabels[2]]
  }
];


export const advocateFirmDummyData = [
  {
    id: 1,
    userType: "firm",
    imgSrc: "assets/users_profile/user-default.png",
    firstName: "",
    lastName: "",
    primaryMobile: "+91 5246666798",
    secondaryMobile: "",
    whatsappNumber: "+91 65465465465",
    email: "newfirm@firm.com",
    country: "India",
    state: "Uttarakhand",
    city: "Dehradun",
    zip: "248001",
    firmName: "New Firm",
    firmAddress: "Dehradun",
    practiceCourt: "N/A",
    firmAdvocates: [
      {
        'firstName': "Saurabh",
        'lastName': "Sharma",
        'designation': "N/A",
        'email': "saurabh.sharma@newfirm.com",
        'mobileNumber': "+91 325558444"
      }
    ],
    billingType: "No Idea",
    appointmentYear: "2018",
    role: 'advocate_firm',
  },
  {
    id: 2,
    imgSrc: "assets/users_profile/user-default.png",
    userType: "individual",
    firstName: "Tom",
    lastName: "Furry",
    primaryMobile: "+91 5246666798",
    secondaryMobile: "",
    whatsappNumber: "+91 65465465465",
    email: "tom.furry@yopmail.com",
    country: "UK",
    state: "N/A",
    city: "N/A",
    zip: "346546",
    firmName: "",
    firmAddress: "",
    practiceCourt: "N/A",
    firmAdvocates: [],
    billingType: "N/A",
    appointmentYear: "2018",
    role: 'advocate_firm',
  }
];

export const caseAbbrevationDummyData = [
  {
    id: 1,
    caseType: "N/A 1",
    description: "Okay lets describe this"
  },
  {
    id: 2,
    caseType: "N/A 2",
    description: "Okay lets describe this"
  }
];

export const appointmentsDummyData = [
  {
    id: 1,
    caseNumber: "46546546",
    topic: "N/A 1",
    appointmentDate: "2020-01-05",
  },
  {
    id: 2,
    caseNumber: "6546879",
    topic: "N/A 2",
    appointmentDate: "2020-04-05",
  }
];

export const hearingsDummyData = [
  {
    id: 1,
    caseNumber: "54654654",
    topic: "N/A 1",
    courtNumber: "21646874",
    judgeName: "Saurabh Sharma",
    nextHearingDate: "2020-05-06",
  },
  {
    id: 2,
    caseNumber: "897946",
    topic: "N/A 2",
    courtNumber: "1451561",
    judgeName: "Saurabh Sharma",
    nextHearingDate: "2020-03-06",
  }
];

export const legalDocsDummyData = [
  {
    id: 1,
    docName: "File Name",
    downloadUrl: "javascript:void(0)"
  },
  {
    id: 2,
    docName: "New Name",
    downloadUrl: "javascript:void(0)"
  }
];

export const judgementsDummyData = [
  {
    id: 1,
    type: "N/A",
    topic: "Topic 1",
    caseType: "N/A",
    court: "Court Name",
    docs: [
      {
        name: "doc1.docx",
        downloadUrl: "javascript:void(0)"
      },
      {
        name: "doc2.pdf",
        downloadUrl: "javascript:void(0)"
      }
    ],
    judgementDate: "2020-06-03",
  },
  {
    id: 2,
    type: "N/A",
    topic: "Topic 2",
    caseType: "N/A",
    court: "Court Name",
    docs: [
      {
        name: "doc1.ppt",
        downloadUrl: "javascript:void(0)"
      },
      {
        name: "doc2.pdf",
        downloadUrl: "javascript:void(0)"
      }
    ],
    judgementDate: "2020-07-09",
  }
];

export const bareActsDummyData = [
  {
    id: 1,
    bareActType: "Type",
    topic: "Bare Act 1",
    caseType: "type",
    court: "Court Name",
    docs: [
      {
        name: "doc1.docx",
        downloadUrl: "javascript:void(0)"
      },
      {
        name: "doc2.ppt",
        downloadUrl: "javascript:void(0)"
      }
    ],
    judgementDate: "2020-05-02",
  },
  {
    id: 2,
    bareActType: "type",
    topic: "Bare Act 2",
    caseType: "type",
    court: "Court Name",
    docs: [
      {
        name: "doc1.docx",
        downloadUrl: "javascript:void(0)"
      },
      {
        name: "doc2.pdf",
        downloadUrl: "javascript:void(0)"
      }
    ],
    judgementDate: "2020-02-05",
  }
];



export const mutualCasesDummyData = [
  {
    firstParty: {
      partyType: "N/A",
      partyName: "Tom",
      division: "N/A",
      project: "N/A",
      country: "N/A",
      state: "N/A",
      city: "N/A",
      zip: "N/A",
      address: "N/A",
    },
    secondParty: {
      partyType: "N/A",
      partyName: "Ricky",
      division: "N/A",
      project: "N/A",
      country: "N/A",
      state: "N/A",
      city: "N/A",
      zip: "N/A",
      address: "N/A",
    },
    descriptionDispute: "N/A",
    mutualCaseType: "N/A",
    departmentName: "N/A",
    caseDescription: "N/A",
    settlementTerms: "N/A",
    lastHearingDate: "2019-02-02 12:00pm",
    nextHearingDate: "2020-02-05 12:00pm",
    uploadDocuments: {
      partyName: "N/A",
      topic: "N/A",
      description: "N/A",
      docs: ["doc1.pdf", "doc2.docx"],
    },
    futureUpdatesBrief: "N/A",
    caseStatus: "open",
    remark: "N/A",
    caseStatusRemarks: [
      { status: "open", remarks: "N/A" },
      { status: "close", remarks: "N/A" },
      { status: "reopen", remarks: "N/A" },
    ]
  }
];


export const courtCasesDummyData = [
  {
    courtCaseType: "Cases Filed Against ",
    division: "N/A",
    project: "N/A",
    caseName: "N/A",
    disputePropertyDescription: "N/A",
    caseDescription: "N/A",
    fillingDate: "N/A",
    caseNumber: "N/A",
    caseType: "N/A",
    caseYear: "N/A",
    petitioner: {
      name: "N/A",
      address: "N/A",
      country: "N/A",
      state: "N/A",
      city: "N/A",
      zip: "N/A",
    },
    responder: {
      name: "N/A",
      address: "N/A",
      country: "N/A",
      state: "N/A",
      city: "N/A",
      zip: "N/A",
    },
    contactPerson: {
      name: "N/A",
      designation: "N/A",
      contactNumber: "N/A",
      email: "N/A"
    },
    lastHearingDate: "2019-02-02 12:00pm",
    nextHearingDate: "2020-02-05 12:00pm",
    advocateAssigned: "N/A",
    courtDetails: {
      courtName: "N/A",
      courtType: "N/A",
      state: "N/A",
      district: "N/A",
      courtNumber: "N/A",
      judgeName: "N/A",
    }
  }
];