import { Injectable } from '@angular/core';
import io from "socket.io-client";
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  private socketUrl = environment.socketConfiguration.url;
  private socketConnection = null;
  isUnloading = false;

  constructor() { }

  initSocket(tokenAddress): boolean {

    let tmpObj = this;
    window.onbeforeunload = function () {
      tmpObj.isUnloading = true;
      return; // don't return a string here or it will pop up asking user if they want to navigate away.
    }
    if (tokenAddress && !this.isSocketConnected()) {
      this.socketConnection = io.connect(this.socketUrl, {
        query: { token: tokenAddress },
        transports: ['websocket']
      });
      let tmpObj = this;
      this.socketConnection.on('disconnect', function (reason) {
        setTimeout(() => {
          if (!tmpObj.isUnloading) {
            // localStorage.removeItem('access_token');
            sessionStorage.removeItem("access_token");
            tmpObj.socketConnection = null;
            location.reload();
          }
        }, 5000);
      });
      return true;
    } else {
      return false;
    }
  }

  isSocketConnected(): boolean {
    if (this.socketConnection != null) {
      return true;
    }
    return false;
  }

  getSocketObject(): any {
    if (this.isSocketConnected()) {
      return this.socketConnection;
    }
    return null;
  }
}
